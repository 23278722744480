/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiscountTypeKindInput = "system" | "user" | "%future added value";
export type DiscountActionsScreen_QueryVariables = {
    after?: string | null;
    before?: string | null;
    filterByDiscountTypeKind?: DiscountTypeKindInput | null;
    filterByActionTitle?: string | null;
    filterByCode?: string | null;
    first?: number | null;
    last?: number | null;
};
export type DiscountActionsScreen_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"DiscountActionsTable_QueryFragment">;
};
export type DiscountActionsScreen_Query = {
    readonly response: DiscountActionsScreen_QueryResponse;
    readonly variables: DiscountActionsScreen_QueryVariables;
};



/*
query DiscountActionsScreen_Query(
  $after: String
  $before: String
  $filterByDiscountTypeKind: DiscountTypeKindInput
  $filterByActionTitle: String
  $filterByCode: String
  $first: Int
  $last: Int
) {
  ...DiscountActionsTable_QueryFragment_4FtDSf
}

fragment ActionActionColumn_DiscountActionFragment on DiscountAction {
  id
}

fragment ActionCalcTypeColumn_DiscountActionFragment on DiscountAction {
  calcType {
    __typename
    ... on DiscountActionPercentageCalcType {
      kind
      percentage
    }
  }
}

fragment ActionIdColumn_DiscountActionFragment on DiscountAction {
  rawId
}

fragment ActionTitleColumn_DiscountActionFragment on DiscountAction {
  title
}

fragment ActionTypeColumn_DiscountActionFragment on DiscountAction {
  discountType {
    __typename
    ... on SystemDiscountType {
      kind
    }
    ... on UserDiscountType {
      kind
    }
  }
}

fragment DiscountActionsTable_DiscountActionFragment on DiscountAction {
  ...ActionIdColumn_DiscountActionFragment
  ...ActionTitleColumn_DiscountActionFragment
  ...ActionTypeColumn_DiscountActionFragment
  ...ActionCalcTypeColumn_DiscountActionFragment
  ...ActionActionColumn_DiscountActionFragment
}

fragment DiscountActionsTable_QueryFragment_4FtDSf on Query {
  Admin {
    Billing {
      DiscountActions(after: $after, before: $before, first: $first, last: $last, filterByDiscountTypeKind: $filterByDiscountTypeKind, filterByActionTitle: $filterByActionTitle, filterByCode: $filterByCode) {
        edges {
          cursor
          node {
            ...DiscountActionsTable_DiscountActionFragment
            id
            __typename
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByActionTitle"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByCode"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByDiscountTypeKind"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "filterByActionTitle",
    "variableName": "filterByActionTitle"
  },
  {
    "kind": "Variable",
    "name": "filterByCode",
    "variableName": "filterByCode"
  },
  {
    "kind": "Variable",
    "name": "filterByDiscountTypeKind",
    "variableName": "filterByDiscountTypeKind"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscountActionsScreen_Query",
    "selections": [
      {
        "args": (v7/*: any*/),
        "kind": "FragmentSpread",
        "name": "DiscountActionsTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "DiscountActionsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "DiscountActionsConnection",
                "kind": "LinkedField",
                "name": "DiscountActions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "discountType",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "SystemDiscountType",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "UserDiscountType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "calcType",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "percentage",
                                    "storageKey": null
                                  }
                                ],
                                "type": "DiscountActionPercentageCalcType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": [
                  "filterByDiscountTypeKind",
                  "filterByActionTitle",
                  "filterByCode"
                ],
                "handle": "connection",
                "key": "DiscountActionsTable_DiscountActions",
                "kind": "LinkedHandle",
                "name": "DiscountActions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c269f4fba8193dce5659fd89dd2cc13",
    "id": null,
    "metadata": {},
    "name": "DiscountActionsScreen_Query",
    "operationKind": "query",
    "text": "query DiscountActionsScreen_Query(\n  $after: String\n  $before: String\n  $filterByDiscountTypeKind: DiscountTypeKindInput\n  $filterByActionTitle: String\n  $filterByCode: String\n  $first: Int\n  $last: Int\n) {\n  ...DiscountActionsTable_QueryFragment_4FtDSf\n}\n\nfragment ActionActionColumn_DiscountActionFragment on DiscountAction {\n  id\n}\n\nfragment ActionCalcTypeColumn_DiscountActionFragment on DiscountAction {\n  calcType {\n    __typename\n    ... on DiscountActionPercentageCalcType {\n      kind\n      percentage\n    }\n  }\n}\n\nfragment ActionIdColumn_DiscountActionFragment on DiscountAction {\n  rawId\n}\n\nfragment ActionTitleColumn_DiscountActionFragment on DiscountAction {\n  title\n}\n\nfragment ActionTypeColumn_DiscountActionFragment on DiscountAction {\n  discountType {\n    __typename\n    ... on SystemDiscountType {\n      kind\n    }\n    ... on UserDiscountType {\n      kind\n    }\n  }\n}\n\nfragment DiscountActionsTable_DiscountActionFragment on DiscountAction {\n  ...ActionIdColumn_DiscountActionFragment\n  ...ActionTitleColumn_DiscountActionFragment\n  ...ActionTypeColumn_DiscountActionFragment\n  ...ActionCalcTypeColumn_DiscountActionFragment\n  ...ActionActionColumn_DiscountActionFragment\n}\n\nfragment DiscountActionsTable_QueryFragment_4FtDSf on Query {\n  Admin {\n    Billing {\n      DiscountActions(after: $after, before: $before, first: $first, last: $last, filterByDiscountTypeKind: $filterByDiscountTypeKind, filterByActionTitle: $filterByActionTitle, filterByCode: $filterByCode) {\n        edges {\n          cursor\n          node {\n            ...DiscountActionsTable_DiscountActionFragment\n            id\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4c46a9808956655e71f45d81d56201d5';
export default node;
