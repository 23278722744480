/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SettingsScreen_QueryVariables = {};
export type SettingsScreen_QueryResponse = {
    readonly Viewer: {
        readonly Settings: {
            readonly AdvertisedCourseWithoutState: {
                readonly rawId: string;
            } | null;
        };
    };
    readonly Admin: {
        readonly Settings: {
            readonly GlobalDiscount: {
                readonly code: string;
                readonly id: string;
            } | null;
        };
    };
    readonly " $fragmentRefs": FragmentRefs<"DiscountSelect_QueryFragment">;
};
export type SettingsScreen_Query = {
    readonly response: SettingsScreen_QueryResponse;
    readonly variables: SettingsScreen_QueryVariables;
};



/*
query SettingsScreen_Query {
  Viewer {
    Settings {
      AdvertisedCourseWithoutState {
        rawId
        id
      }
    }
  }
  Admin {
    Settings {
      GlobalDiscount {
        code
        id
      }
    }
  }
  ...DiscountSelect_QueryFragment_4vS6aX
}

fragment DiscountSelect_QueryFragment_4vS6aX on Query {
  Admin {
    Billing {
      DiscountsMultiselect(ids: []) {
        edges {
          cursor
          node {
            id
            code
            __typename
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "SettingsAdminSchema",
  "kind": "LinkedField",
  "name": "Settings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DiscountCodeV2",
      "kind": "LinkedField",
      "name": "GlobalDiscount",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": ([]/*: any*/)
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingsViewerSchema",
            "kind": "LinkedField",
            "name": "Settings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Course",
                "kind": "LinkedField",
                "name": "AdvertisedCourseWithoutState",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "DiscountSelect_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingsViewerSchema",
            "kind": "LinkedField",
            "name": "Settings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Course",
                "kind": "LinkedField",
                "name": "AdvertisedCourseWithoutState",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "DiscountCodesV2Connection",
                "kind": "LinkedField",
                "name": "DiscountsMultiselect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodeV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "DiscountsMultiselect(ids:[])"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "codeOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "DiscountSelect_DiscountsMultiselect",
                "kind": "LinkedHandle",
                "name": "DiscountsMultiselect"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03172cff66535b38acb14ef222479976",
    "id": null,
    "metadata": {},
    "name": "SettingsScreen_Query",
    "operationKind": "query",
    "text": "query SettingsScreen_Query {\n  Viewer {\n    Settings {\n      AdvertisedCourseWithoutState {\n        rawId\n        id\n      }\n    }\n  }\n  Admin {\n    Settings {\n      GlobalDiscount {\n        code\n        id\n      }\n    }\n  }\n  ...DiscountSelect_QueryFragment_4vS6aX\n}\n\nfragment DiscountSelect_QueryFragment_4vS6aX on Query {\n  Admin {\n    Billing {\n      DiscountsMultiselect(ids: []) {\n        edges {\n          cursor\n          node {\n            id\n            code\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'de7c8b1d9281d0b79ba9cbf623ae455b';
export default node;
