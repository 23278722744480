import graphql from "babel-plugin-relay/macro"
import {Button} from "primereact/button";
import {useMutation} from "react-relay";
import React, {useRef} from "react";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from "primereact/inputtext";
import {
    CreateDiscountActionButton_CreateMutation
} from "../../../../../../__generated__/CreateDiscountActionButton_CreateMutation.graphql";
import {useTranslation} from "react-i18next";
import {Toast} from "primereact/toast";

const CREATE_DISCOUNT_ACTION_MUTATION = graphql`
    mutation CreateDiscountActionButton_CreateMutation($input: CreateDiscountActionInput!, $connections: [ID!]!){
        Admin {
            Billing {
                createDiscountAction(input: $input){
                    data @appendEdge(connections: $connections){
                        node  {
                            ...DiscountActionsTable_DiscountActionFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    className: string
    connectionId: string
}

export const CreateDiscountActionButton = ({connectionId, className}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const createDiscountActionButtonNs = "billing.discount-code.button.create-action"

    const [createDiscountAction, isLoading] = useMutation<CreateDiscountActionButton_CreateMutation>(CREATE_DISCOUNT_ACTION_MUTATION)

    const overlayPanel = useRef<OverlayPanel>(null)
    const nameOfAction = useRef<any>(null)
    const toast = useRef<any>(null)

    return (
        <>
            <Toast ref={toast}/>
            <Button
                label={t(`${createDiscountActionButtonNs}.title`)}
                loading={isLoading}
                disabled={isLoading}
                className={className}
                onClick={(e) => overlayPanel.current?.toggle(e)}
            />
            <OverlayPanel ref={overlayPanel}>
                <span className="p-float-label mb-2 mt-3">
                    <InputText id="nameOfAction" ref={nameOfAction}/>
                    <label htmlFor="nameOfAction">{t(`${createDiscountActionButtonNs}.placeholder`)}</label>
                </span>
                <Button
                    label={t(`${createDiscountActionButtonNs}.submit`)}
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => {
                        createDiscountAction({
                            variables: {
                                input: {
                                    data: {
                                        title: nameOfAction.current.value || "Neu"
                                    }
                                },
                                connections: [connectionId]
                            },
                            onCompleted: () => {
                                toast.current?.show({
                                    severity: "success",
                                    life: 3000,
                                    summary: t(`${createDiscountActionButtonNs}.toast.success.summary`),
                                    detail: t(`${createDiscountActionButtonNs}.toast.success.detail`),
                                })
                            }
                        })
                    }}/>
            </OverlayPanel>
        </>
    )
}
