/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CartHelperScreen_ProductsQueryVariables = {};
export type CartHelperScreen_ProductsQueryResponse = {
    readonly Admin: {
        readonly Billing: {
            readonly Products: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly title: string;
                    };
                } | null> | null;
            };
            readonly DiscountActions: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly discountCodes: {
                            readonly edges: ReadonlyArray<{
                                readonly node: {
                                    readonly code: string;
                                };
                            } | null> | null;
                        };
                    };
                } | null> | null;
            };
        };
    };
    readonly " $fragmentRefs": FragmentRefs<"DiscountsSelect_QueryFragment">;
};
export type CartHelperScreen_ProductsQuery = {
    readonly response: CartHelperScreen_ProductsQueryResponse;
    readonly variables: CartHelperScreen_ProductsQueryVariables;
};



/*
query CartHelperScreen_ProductsQuery {
  Admin {
    Billing {
      Products(includeHidden: false) {
        edges {
          node {
            __typename
            id
            title
          }
        }
      }
      DiscountActions {
        edges {
          node {
            discountCodes {
              edges {
                node {
                  code
                  id
                }
              }
            }
            id
          }
        }
      }
    }
  }
  ...DiscountsSelect_QueryFragment_4vS6aX
}

fragment DiscountsSelect_QueryFragment_4vS6aX on Query {
  Admin {
    Billing {
      DiscountsMultiselect(ids: []) {
        edges {
          cursor
          node {
            id
            code
            __typename
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "includeHidden",
    "value": false
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": ([]/*: any*/)
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CartHelperScreen_ProductsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "ProductsConnection",
                "kind": "LinkedField",
                "name": "Products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "Products(includeHidden:false)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DiscountActionsConnection",
                "kind": "LinkedField",
                "name": "DiscountActions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DiscountCodesV2Connection",
                            "kind": "LinkedField",
                            "name": "discountCodes",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DiscountCodesV2Edge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DiscountCodeV2",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "DiscountsSelect_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CartHelperScreen_ProductsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "ProductsConnection",
                "kind": "LinkedField",
                "name": "Products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "Products(includeHidden:false)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DiscountActionsConnection",
                "kind": "LinkedField",
                "name": "DiscountActions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DiscountCodesV2Connection",
                            "kind": "LinkedField",
                            "name": "discountCodes",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DiscountCodesV2Edge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DiscountCodeV2",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "DiscountCodesV2Connection",
                "kind": "LinkedField",
                "name": "DiscountsMultiselect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodeV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v3/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "DiscountsMultiselect(ids:[])"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "codeOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "DiscountsSelect_DiscountsMultiselect",
                "kind": "LinkedHandle",
                "name": "DiscountsMultiselect"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0116c6191e4ed25f92cb70bee543e21b",
    "id": null,
    "metadata": {},
    "name": "CartHelperScreen_ProductsQuery",
    "operationKind": "query",
    "text": "query CartHelperScreen_ProductsQuery {\n  Admin {\n    Billing {\n      Products(includeHidden: false) {\n        edges {\n          node {\n            __typename\n            id\n            title\n          }\n        }\n      }\n      DiscountActions {\n        edges {\n          node {\n            discountCodes {\n              edges {\n                node {\n                  code\n                  id\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n  ...DiscountsSelect_QueryFragment_4vS6aX\n}\n\nfragment DiscountsSelect_QueryFragment_4vS6aX on Query {\n  Admin {\n    Billing {\n      DiscountsMultiselect(ids: []) {\n        edges {\n          cursor\n          node {\n            id\n            code\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b7c5a2954274c01be7d9cb1435532b7f';
export default node;
