import { EditedCourseState } from "../../edited-course";
import update from "immutability-helper";
import { AddLessonAction } from "../../../actions/lesson/add-lesson";
import { anyIdMatches, extractId } from "../common/helpers";

export const addLesson = (state: EditedCourseState, {lesson, parentModuleId}: AddLessonAction) => {
    const parentModuleIndex = state.editedCourse!.entity.modules.findIndex(m => anyIdMatches(m, parentModuleId))

    return update(state,
        {
            hasUnsavedChanges: {$set: true},
            editedCourse: {
                entity: {
                    modules: {[parentModuleIndex]: {entity: {lessonsRef: {$push: [extractId(lesson)]}}}},
                    lessons: {$push: [lesson]}
                }
            }
        }
    )
};
