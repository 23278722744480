import React, {useCallback, useState} from "react";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {debounce} from "lodash";
import {useTranslation} from "react-i18next";

interface State {
    firstName: string,
    lastName: string,
    orderNumber: string,
}

interface OwnProps {
    refetch: (firstName: string, lastName: string, orderNumber: string) => void
}

export const OrdersTableFiltersV3 = ({refetch}: OwnProps) => {
    const {t} = useTranslation("admin")
    const initialState = {
        firstName : '',
        lastName : '',
        orderNumber : '',
    };
    const [state, setState] = useState<State>(initialState)

    // eslint-disable-next-line
    const delayedRefetch = useCallback(debounce((firstName: string, lastName: string, orderNumber: string) => refetch(firstName, lastName, orderNumber), 500), []);

    return <div className="flex pb-4">
        <span className="p-input-icon-left flex-grow-1">
            <i className={"pi pi-filter"}/>
            <InputText value={state.firstName} placeholder={t("entity.orders.labels.firstname")} className="w-full" onChange={(e) => {
                setState(state => ({...state, firstName: (e.target.value)}))
                delayedRefetch(e.target.value, state.lastName, state.orderNumber)
            }}/>
        </span>
        <span className="p-input-icon-left flex-grow-1">
            <i className={"pi pi-filter"}/>
            <InputText value={state.lastName} placeholder={t("entity.orders.labels.lastname")} className="w-full" onChange={(e) => {
                setState(state => ({...state, lastName: (e.target.value)}))
                delayedRefetch(state.firstName, e.target.value, state.orderNumber)
            }}/>
        </span>
        <span className="p-input-icon-left flex-grow-1">
            <i className={"pi pi-filter"}/>
            <InputText value={state.orderNumber} placeholder={t("entity.orders.labels.id")} className="w-full" onChange={(e) => {
                setState(state => ({...state, orderNumber: (e.target.value)}))
                delayedRefetch(state.firstName, state.lastName, e.target.value)
            }}/>
        </span>
        <Button className="ml-3" type="button" label={t("entity.orders.labels.remove-filter")}
                icon="pi pi-filter-slash"
                iconPos="right" onClick={_ => {
            setState(state => (initialState))
            refetch('', '', '')
        }}/>
    </div>
}
