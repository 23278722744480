import { authModuleEpics$ } from "@thekeytechnology/framework-react";
import { profileModuleEpics$ } from "@thekeytechnology/framework-react";
import { combineEpics } from "redux-observable";
import { invitationEpics$ } from "./invitation";
import { loginEpics$ } from "./login";
import {reloadActions$} from "./reloads";

export const additionalAuthModuleEpics$ = combineEpics(
    invitationEpics$,
    profileModuleEpics$,
    authModuleEpics$,
    loginEpics$,
    reloadActions$
);
