import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectLicensePoolAdmin } from "../../../selectors";
import { Course, ENTITY_TYPE_COURSE } from "../../../../course/model/preparing-aggregated-course";
import { addLicensesToPoolAction } from "../../../actions/license-pool/add-licenses";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { AsyncEntitySelectField } from "../../../../core/components/entity/AsyncEntitySelectField";

interface FormState {
    amount: number
    limitedToCoursesRef: { id: string }[]
}

export const AddLicenses = () => {
    const {t} = useTranslation("admin")

    const licensePoolContainer = useSelector(selectLicensePoolAdmin);

    const dispatch = useDispatch();

    return <Formik<FormState>
        initialValues={{
            amount: 1,
            limitedToCoursesRef: []
        }}
        validationSchema={Yup.object().shape({
            amount: Yup.number().min(1)
        })}
        onSubmit={(values, {setSubmitting}) => {
            const limitedToCoursesRef = values.limitedToCoursesRef.map(c => c.id);
            dispatch(addLicensesToPoolAction(
                licensePoolContainer?.licensePool?.id!,
                values.amount,
                limitedToCoursesRef.length === 0 ? undefined : limitedToCoursesRef
            ));
            setSubmitting(false);
        }}
    >
        {formikState => (
            <Form>
                <div className="form-group row">
                    <ValidatedField
                        label={t("entity.license.labels.amount")}
                        name="amount"
                        type={"number"}
                        min={1}
                        formikState={formikState}
                    />
                </div>
                <div className="form-group row">
                    <ValidatedField
                        label={t("entity.license.labels.limited-to-course")}
                        name="limitedToCoursesRef"
                        component={AsyncEntitySelectField}
                        placeholder={"Limitiert auf Kurse"}
                        listRenderer={(value: EntityWrapper<Course>) => value?.entity?.containerMeta.title}
                        formikState={formikState}
                        shownEntityType={ENTITY_TYPE_COURSE}
                        shownEntityTypeProperties={["entity.containerMeta.title"]}
                        isMulti={true}
                        isClearable={true}
                    />
                </div>
                <button disabled={formikState.isSubmitting} className="btn btn-success">
                    Hinzufügen
                </button>
            </Form>
        )}
    </Formik>
}
