import {
    apiModuleReducer,
    authModuleReducer,
    dialogModuleReducer,
    entityModuleReducer,
    filesModuleReducer,
    i18nModuleReducer,
    integrationsModuleReducer,
    messagesModuleReducer,
    settingsModuleReducer,
    usersModuleReducer,
} from "@thekeytechnology/framework-react";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { coursesModuleReducer } from "./course/reducers";
import { triggersModuleReducer } from "./triggers/reducers";
import { userManagementModuleReducer } from "./admin/reducer";

export type AppState = ReturnType<ReturnType<typeof appReducers>>

export const appReducers = (history: any, apiBase: string) => combineReducers({
    router: connectRouter(history),
    auth: authModuleReducer,
    entities: entityModuleReducer,
    dialog: dialogModuleReducer,
    upload: filesModuleReducer,
    messages: messagesModuleReducer,
    api: apiModuleReducer(apiBase),
    i18n: i18nModuleReducer,
    settings: settingsModuleReducer,
    integrations: integrationsModuleReducer,
    users: usersModuleReducer,
    courses: coursesModuleReducer,
    triggers: triggersModuleReducer,
    userManagement: userManagementModuleReducer
});
