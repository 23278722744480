/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditAdditionalInformationInput = {
    userId: string;
    additionalInformation?: string | null;
    clientMutationId?: string | null;
};
export type EditAdditionalInformation_EditAdditionalInformationMutationVariables = {
    input: EditAdditionalInformationInput;
};
export type EditAdditionalInformation_EditAdditionalInformationMutationResponse = {
    readonly Admin: {
        readonly User: {
            readonly editAdditionalInformation: {
                readonly userMeta: {
                    readonly " $fragmentRefs": FragmentRefs<"EditAdditionalInformation_UserMetaFragment">;
                };
            } | null;
        };
    };
};
export type EditAdditionalInformation_EditAdditionalInformationMutation = {
    readonly response: EditAdditionalInformation_EditAdditionalInformationMutationResponse;
    readonly variables: EditAdditionalInformation_EditAdditionalInformationMutationVariables;
};



/*
mutation EditAdditionalInformation_EditAdditionalInformationMutation(
  $input: EditAdditionalInformationInput!
) {
  Admin {
    User {
      editAdditionalInformation(input: $input) {
        userMeta {
          ...EditAdditionalInformation_UserMetaFragment
          id
        }
      }
    }
  }
}

fragment EditAdditionalInformation_UserMetaFragment on UserMeta {
  additionalInformation
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditAdditionalInformation_EditAdditionalInformationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAdminMutationSchema",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditAdditionalInformationPayload",
                "kind": "LinkedField",
                "name": "editAdditionalInformation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserMeta",
                    "kind": "LinkedField",
                    "name": "userMeta",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EditAdditionalInformation_UserMetaFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditAdditionalInformation_EditAdditionalInformationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAdminMutationSchema",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditAdditionalInformationPayload",
                "kind": "LinkedField",
                "name": "editAdditionalInformation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserMeta",
                    "kind": "LinkedField",
                    "name": "userMeta",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "additionalInformation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1423d1157971ea887f55e7f18f6f3220",
    "id": null,
    "metadata": {},
    "name": "EditAdditionalInformation_EditAdditionalInformationMutation",
    "operationKind": "mutation",
    "text": "mutation EditAdditionalInformation_EditAdditionalInformationMutation(\n  $input: EditAdditionalInformationInput!\n) {\n  Admin {\n    User {\n      editAdditionalInformation(input: $input) {\n        userMeta {\n          ...EditAdditionalInformation_UserMetaFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment EditAdditionalInformation_UserMetaFragment on UserMeta {\n  additionalInformation\n}\n"
  }
};
})();
(node as any).hash = '48b0a29d64f2b16fec567552db65a15d';
export default node;
