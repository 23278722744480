import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { connect } from "react-redux";
import { moveLessonAction } from "../../../actions/lesson/move-lesson";
import { Lesson } from "../../../model/lesson/lesson";
import { extractId } from "../../../reducers/changes/common/helpers";
import { AddLesson } from "./AddLesson";
import { LessonItem } from "./LessonItem";
import { selectLessons } from "../../../selectors";

interface OwnProps {
    moduleIndex: number;
    parentModuleId: string;
    lessonIds: string[]
}

interface DispatchProps {
    moveLesson: typeof moveLessonAction
}

interface StateProps {
    lessons: Array<EntityWrapper<Lesson>>;
}

type Props = OwnProps & StateProps & DispatchProps;

export const LessonListComponent = ({
                                        moduleIndex,
                                        parentModuleId,
                                        lessons,
                                        moveLesson
                                    }: Props) => {
    return <>
        {lessons.map((lesson: EntityWrapper<Lesson>, index) =>
            <LessonItem key={extractId(lesson)}
                        moduleIndex={moduleIndex}
                        parentModuleId={parentModuleId}
                        lessonIndex={index}
                        lesson={lesson}
                        index={index}
                        dragMeta={{
                            sourceModuleId: parentModuleId,
                        }}
                        dropped={(dragIndex, hoverIndex, dropMeta) => {
                            moveLesson(dropMeta.sourceModuleId, parentModuleId, dragIndex, hoverIndex);
                        }}/>
        )}
        <AddLesson parentModuleId={parentModuleId}
                   dropped={(dragIndex, hoverIndex, dropMeta) => {
                       moveLesson(dropMeta.sourceModuleId, parentModuleId, dragIndex, hoverIndex);
                   }}/>
    </>;
};

export const LessonList = connect<StateProps, DispatchProps, OwnProps>(
    (state: any, {lessonIds}: OwnProps) => ({
        lessons: selectLessons(lessonIds)(state)
    }),
    {
        moveLesson: moveLessonAction,
    }
)(LessonListComponent);
