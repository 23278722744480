import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { editLessonAction } from "../../../actions/lesson/edit-lesson";
import { Lesson } from "../../../model/lesson/lesson";
import { EditNormalLessonForm } from "./EditNormalLessonForm";
import { NormalLesson } from "../../../model/lesson/normal-lesson";
import { TestLesson } from "../../../model/lesson/test-lesson";
import { EditTestLessonForm } from "./EditTestLessonForm";
import { LESSON_TYPE_NORMAL, LESSON_TYPE_TEST } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    lesson: EntityWrapper<Lesson>;
    parentModuleId: string;
}

interface DispatchProps {
    editLesson: typeof editLessonAction
}

type Props = OwnProps & DispatchProps & WithTranslation;

const EditLessonComponent = (props: Props) => {
    const {editLesson, lesson, parentModuleId} = props;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSubmit = (entity: EntityWrapper<Lesson>) => {
        editLesson(parentModuleId, entity);
        handleClose();
    };

    return (
        <>
            <Button className="ml-2 btn-secondary" onClick={handleShow}>
                <FontAwesomeIcon icon={faPencilAlt}/>
            </Button>

            {show ? <Modal size="lg" show={show} onHide={handleClose}>
                {lesson.entity.lessonType === LESSON_TYPE_NORMAL ?
                    <EditNormalLessonForm
                        lesson={lesson as EntityWrapper<NormalLesson>}
                        onSubmit={onSubmit}
                        onClose={handleClose}/> : null}
                {lesson.entity.lessonType === LESSON_TYPE_TEST ?
                    <EditTestLessonForm
                        lesson={lesson as EntityWrapper<TestLesson>}
                        onSubmit={onSubmit}
                        onClose={handleClose}/> : null}
            </Modal> : null}
        </>
    );
};

export const EditLesson = connect<{}, DispatchProps, {}>(
    null,
    {
        editLesson: editLessonAction
    }
)(withTranslation("courses")(EditLessonComponent));
