import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useTranslation } from "react-i18next";
import { TriggeredUnlocksTable } from "./TriggeredUnlocksTable";
import { ENTITY_TYPE_TRIGGERED_UNLOCK } from "../model/triggered-unlock";
import { ContentContainer, HeadingContainer, PaginationContainer } from "../../core/components/containers/Container";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

export const TriggeredUnlocksOverview = () => {
    const {t} = useTranslation("unlocks");
    return (
        <>
            <BreadcrumbsItem to={"/unlocks"}>{t("entity.unlock.singular")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.triggered-unlock.singular")}/>
            </HeadingContainer>
            <ContentContainer>
                <TriggeredUnlocksTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_TRIGGERED_UNLOCK}/>
            </PaginationContainer>
        </>
    );
};
