/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiscountActionsTable_DiscountActionFragment = {
    readonly " $fragmentRefs": FragmentRefs<"ActionIdColumn_DiscountActionFragment" | "ActionTitleColumn_DiscountActionFragment" | "ActionTypeColumn_DiscountActionFragment" | "ActionCalcTypeColumn_DiscountActionFragment" | "ActionActionColumn_DiscountActionFragment">;
    readonly " $refType": "DiscountActionsTable_DiscountActionFragment";
};
export type DiscountActionsTable_DiscountActionFragment$data = DiscountActionsTable_DiscountActionFragment;
export type DiscountActionsTable_DiscountActionFragment$key = {
    readonly " $data"?: DiscountActionsTable_DiscountActionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"DiscountActionsTable_DiscountActionFragment">;
};



const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "DiscountActionsTable_DiscountActionFragment"
};
(node as any).hash = 'f31e69a98c4111c48d33e058179ee9bf';
export default node;
