import React from "react";
import {useTranslation} from "react-i18next";
import {useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {ClearAllCachesComponent_ClearAllCachesMutation} from "../../../__generated__/ClearAllCachesComponent_ClearAllCachesMutation.graphql";
import {Button} from "primereact/button";

const CLEAR_ALL_CACHES_MUTATION = graphql`
    mutation ClearAllCachesComponent_ClearAllCachesMutation {
        Admin {
            Cache {
                clearAllCaches(input: {}) {
                    clientMutationId
                }
            }
        }
    }`

export const ClearAllCachesComponent = () => {
    const {t} = useTranslation("settings")

    const [clearAllCaches, isClearingAllCaches] = useMutation<ClearAllCachesComponent_ClearAllCachesMutation>(CLEAR_ALL_CACHES_MUTATION)

    return <div className="mt-4">
        <Button label={t("settings.clear-all-caches.button")} className="inputfield" onClick={() => clearAllCaches({
            variables: {}
        })} loading={isClearingAllCaches} disabled={isClearingAllCaches}/>
    </div>

}
