import graphql from "babel-plugin-relay/macro"
import {useFragment} from "react-relay";
import {useTranslation} from "react-i18next";
import {
    ActionTypeColumn_DiscountActionFragment$key
} from "../../../../../../../__generated__/ActionTypeColumn_DiscountActionFragment.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
    fragment ActionTypeColumn_DiscountActionFragment on DiscountAction {
        discountType {
            ... on SystemDiscountType {
                kind
            }
            ... on UserDiscountType {
                kind
            }
        }
    }
`

interface OwnProps {
    discountActionFragmentRef: ActionTypeColumn_DiscountActionFragment$key
}

export const ActionTypeColumn = ({discountActionFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billingV3")

    const discountAction = useFragment<ActionTypeColumn_DiscountActionFragment$key>(DISCOUNT_ACTION_FRAGMENT, discountActionFragmentRef)

    return <div>{t(`billing.discount-code.action.discount-type.${discountAction.discountType.kind}`)}</div>
}
