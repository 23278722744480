import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ENTITY_TYPE_COURSE } from "../model/preparing-aggregated-course";
import { Filters } from "../../core/components/filters/Filters";
import { TextFilter } from "../../core/components/filters/TextFilter";

const CourseFilterComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <Filters entityType={ENTITY_TYPE_COURSE}
                 filterKeys={["entity.title"]}>
            <TextFilter
                placeholder={t("course-filters.title-filter")}
                entityType={ENTITY_TYPE_COURSE}
                property="entity.title"/>
        </Filters>
    );
};

export const CourseFilters = withTranslation("courses")(CourseFilterComponent);
