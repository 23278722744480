import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { OnboardingTable } from "./OnboardingTable";
import { ENTITY_TYPE_ONBOARDING } from "../model/onboarding";
import { ContentContainer, HeadingContainer, PaginationContainer } from "../../core/components/containers/Container";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const ExpertOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/onboardings"}>{t("entity.onboarding.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.onboarding.plural")}/>
            </HeadingContainer>
            <ContentContainer>
                <OnboardingTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_ONBOARDING}/>
            </PaginationContainer>
        </>
    );
};

export const OnboardingOverview = withTranslation("onboarding")(ExpertOverviewComponent);
