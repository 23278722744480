import { EntityWrapper, TkFile } from "@thekeytechnology/framework-react";

export const ENTITY_TYPE_ONBOARDING = "onboarding"

export const NEW_ONBOARDING_FACTORY = () => {
    return new EntityWrapper(undefined, {
        internalTitle: "Neues Onboarding",
        pages: [],
        accountRef: "",
        useInAccountsRef: [],
        files: [],
        inheritToSlaveAccounts: false
    } as Onboarding)
}

export interface Onboarding {
    internalTitle: string
    accountRef: string
    useInAccountsRef: string[],
    pages: OnboardingPage[]
    inheritToSlaveAccounts: boolean
    files: EntityWrapper<TkFile>[]
}

export interface OnboardingForSaving {
    internalTitle: string
    accountRef: string
    useInAccountsRef: string[]
    pages: OnboardingPage[]
    inheritToSlaveAccounts: boolean
}

export interface OnboardingPage {
    title: string
    mainTitle: string
    content: string
    imageRef?: string
    videoFileRef?: string
    gradient?: Gradient
    hasCloseButton: boolean

}

export interface Gradient {
    from: string
    to: string
}
