import {useFormik} from "formik";
import {Button} from "primereact/button";
import {TaxPriceInput} from "../../../old/v2/components/offer/edit/TaxPriceInput";
import React from "react";
import {CourseSelect} from "./CourseSelect";
import {InputSwitch} from "primereact/inputswitch";
import {ValidatedFieldV2} from "../../../../core/components/form/ValidatedFieldV2";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {DefaultTextFieldComponent} from "../../../../core/components/form/forms/DefaultTextComponent";

export interface PriceV3 {
    netPrice: number
    grossPrice: number
    taxRatePercentage: number
}

export interface ProductFormValues {
    price?: PriceV3
    courseRef?: string
    title?: string
    isHidden?: boolean
}

interface OwnProps {
    loading?: boolean
    initialValues?: ProductFormValues
    onSubmit: (values: ProductFormValues) => void
}

export const EditProductForm = ({
                                    loading,
                                    initialValues,
                                    onSubmit
                                }: OwnProps) => {
    const {t} = useTranslation(["billing", "core"])

    const formik = useFormik<ProductFormValues>({
        initialValues: {
            title: initialValues?.title ?? "Neues Produkt",
            courseRef: initialValues?.courseRef,
            price: initialValues?.price,
            isHidden: initialValues?.isHidden ?? false
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required(t("core:forms.required-field", {fieldName: "Titel"})),
            courseRef: Yup.string().required(t("core:forms.required-field", {fieldName: "Kurs"})),
            price: Yup.object().shape({
                netPrice: Yup.number().min(1, "Netto Preis muss größer 0 sein").required(t("core:forms.required-field", {fieldName: "Netto Preis"})),
                grossPrice: Yup.number().min(1, "Brutto Preis muss größer 0 sein").required(t("core:forms.required-field", {fieldName: "Brutto Preis"})),
            }).required(t("core:forms.required-field", {fieldName: "Preis"})),
        }),
        onSubmit: (values, {setSubmitting}) => {
            onSubmit(values)
            setSubmitting(false)
        }
    })

    return <form onSubmit={formik.handleSubmit} className="p-fluid">
        <ValidatedFieldV2<ProductFormValues, string>
            name={"title"}
            label={"Name"}
            component={DefaultTextFieldComponent}
            formikConfig={formik}
        />

        <ValidatedFieldV2<ProductFormValues, PriceV3>
            name={"price"}
            label={"Preis"}
            component={({fieldValue, updateField}) => {
                return <TaxPriceInput
                    taxRate={0}
                    netValue={fieldValue?.netPrice || 0}
                    grossValue={fieldValue?.grossPrice || 0}
                    onBlur={formik.handleBlur}
                    updatePrice={(netPrice, grossPrice) => updateField(
                        {
                            netPrice: netPrice,
                            grossPrice: grossPrice,
                            taxRatePercentage: 0
                        } as PriceV3
                    )}/>
            }}
            formikConfig={formik}
        />

        <ValidatedFieldV2<ProductFormValues, boolean>
            name={"isHidden"}
            label={"Versteckt"}
            helpText={"Dieses Produkt wird nicht im Upsell-Bereich des Warenkorbs angezeigt."}
            component={({fieldValue, updateField}) => {
                return <div>
                    <InputSwitch name="isHidden" checked={fieldValue}
                                 onChange={(e) =>
                                     updateField(e.value)}/>
                </div>
            }}
            formikConfig={formik}
        />

        <ValidatedFieldV2<ProductFormValues, string | undefined>
            name={"courseRef"}
            label={"Kurs"}
            component={({fieldValue, updateField}) => {
                return <CourseSelect courseRef={fieldValue} onChange={courseRef => {
                    updateField(courseRef)
                }}/>
            }}
            formikConfig={formik}
        />

        <Button
            disabled={loading}
            type="submit"
            label="Speichern"
            className="p-mt-2"/>
    </form>
}
