import {
    API_CREATE_ENTITIES, API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import {
    ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION,
    ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION_FOR_SAVING
} from "../model/CustomerMessage";
import {combineEpics} from "redux-observable";
import { redirectAfterApiSuccesses } from "../../core/epics/redirects";

const redirectOnTriggeredEmailSave$ = redirectAfterApiSuccesses(
    [
        {apiType: API_UPDATE_ENTITIES, callType: ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION_FOR_SAVING},
        {apiType: API_CREATE_ENTITIES, callType: ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION_FOR_SAVING}
    ], "/customer-message"
)

const reloadOnDelete = reloadOnEntityApiOperation(
    API_DELETE_ENTITIES,
    ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION
)

export const customerMessageModuleEpics$ = combineEpics(
    redirectOnTriggeredEmailSave$,
    reloadOnDelete
)
