/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateDiscountActionInput = {
    data: CreateDiscountAction;
    clientMutationId?: string | null;
};
export type CreateDiscountAction = {
    title: string;
};
export type CreateDiscountActionButton_CreateMutationVariables = {
    input: CreateDiscountActionInput;
    connections: Array<string>;
};
export type CreateDiscountActionButton_CreateMutationResponse = {
    readonly Admin: {
        readonly Billing: {
            readonly createDiscountAction: {
                readonly data: {
                    readonly node: {
                        readonly " $fragmentRefs": FragmentRefs<"DiscountActionsTable_DiscountActionFragment">;
                    };
                };
            } | null;
        };
    };
};
export type CreateDiscountActionButton_CreateMutation = {
    readonly response: CreateDiscountActionButton_CreateMutationResponse;
    readonly variables: CreateDiscountActionButton_CreateMutationVariables;
};



/*
mutation CreateDiscountActionButton_CreateMutation(
  $input: CreateDiscountActionInput!
) {
  Admin {
    Billing {
      createDiscountAction(input: $input) {
        data {
          node {
            ...DiscountActionsTable_DiscountActionFragment
            id
          }
        }
      }
    }
  }
}

fragment ActionActionColumn_DiscountActionFragment on DiscountAction {
  id
}

fragment ActionCalcTypeColumn_DiscountActionFragment on DiscountAction {
  calcType {
    __typename
    ... on DiscountActionPercentageCalcType {
      kind
      percentage
    }
  }
}

fragment ActionIdColumn_DiscountActionFragment on DiscountAction {
  rawId
}

fragment ActionTitleColumn_DiscountActionFragment on DiscountAction {
  title
}

fragment ActionTypeColumn_DiscountActionFragment on DiscountAction {
  discountType {
    __typename
    ... on SystemDiscountType {
      kind
    }
    ... on UserDiscountType {
      kind
    }
  }
}

fragment DiscountActionsTable_DiscountActionFragment on DiscountAction {
  ...ActionIdColumn_DiscountActionFragment
  ...ActionTitleColumn_DiscountActionFragment
  ...ActionTypeColumn_DiscountActionFragment
  ...ActionCalcTypeColumn_DiscountActionFragment
  ...ActionActionColumn_DiscountActionFragment
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateDiscountActionButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateDiscountActionPayload",
                "kind": "LinkedField",
                "name": "createDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "DiscountActionsTable_DiscountActionFragment",
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionIdColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionTitleColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionTypeColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionCalcTypeColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionActionColumn_DiscountActionFragment"
                              }
                            ]
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateDiscountActionButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateDiscountActionPayload",
                "kind": "LinkedField",
                "name": "createDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "discountType",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": (v5/*: any*/),
                                "type": "SystemDiscountType",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v5/*: any*/),
                                "type": "UserDiscountType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "calcType",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "percentage",
                                    "storageKey": null
                                  }
                                ],
                                "type": "DiscountActionPercentageCalcType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "256f5cd8d09e5af21d892924cf9883a8",
    "id": null,
    "metadata": {},
    "name": "CreateDiscountActionButton_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation CreateDiscountActionButton_CreateMutation(\n  $input: CreateDiscountActionInput!\n) {\n  Admin {\n    Billing {\n      createDiscountAction(input: $input) {\n        data {\n          node {\n            ...DiscountActionsTable_DiscountActionFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ActionActionColumn_DiscountActionFragment on DiscountAction {\n  id\n}\n\nfragment ActionCalcTypeColumn_DiscountActionFragment on DiscountAction {\n  calcType {\n    __typename\n    ... on DiscountActionPercentageCalcType {\n      kind\n      percentage\n    }\n  }\n}\n\nfragment ActionIdColumn_DiscountActionFragment on DiscountAction {\n  rawId\n}\n\nfragment ActionTitleColumn_DiscountActionFragment on DiscountAction {\n  title\n}\n\nfragment ActionTypeColumn_DiscountActionFragment on DiscountAction {\n  discountType {\n    __typename\n    ... on SystemDiscountType {\n      kind\n    }\n    ... on UserDiscountType {\n      kind\n    }\n  }\n}\n\nfragment DiscountActionsTable_DiscountActionFragment on DiscountAction {\n  ...ActionIdColumn_DiscountActionFragment\n  ...ActionTitleColumn_DiscountActionFragment\n  ...ActionTypeColumn_DiscountActionFragment\n  ...ActionCalcTypeColumn_DiscountActionFragment\n  ...ActionActionColumn_DiscountActionFragment\n}\n"
  }
};
})();
(node as any).hash = '351c190df159a468f9d5ba78822d70b9';
export default node;
