import React, {Suspense, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {ProgressSpinner} from "primereact/progressspinner";
import {
    DiscountActionForm_DiscountActionFragment$key
} from "../../../../../../__generated__/DiscountActionForm_DiscountActionFragment.graphql";
import {
    DiscountActionForm_EditActionMutation, DiscountTypeKindInput, EditDiscountActionData, EditUsageLimited
} from "../../../../../../__generated__/DiscountActionForm_EditActionMutation.graphql";
import {ValidatedFieldV2} from "../../../../../core/components/form/ValidatedFieldV2";
import {DefaultTextFieldComponent} from "../../../../../core/components/form/forms/DefaultTextComponent";
import {DiscountTypeInput} from "./DiscountTypeInput";
import {InputNumber} from "primereact/inputnumber";
import {Toast} from "primereact/toast";
import moment from "moment/moment";
import {Calendar} from "primereact/calendar";
import {UsageLimitationInput} from "./UsageLimitationInput";

const DISCOUNT_ACTION_FRAGMENT = graphql`
    fragment DiscountActionForm_DiscountActionFragment on DiscountAction {
        id
        title
        calcType {
            ... on DiscountActionPercentageCalcType {
                percentage
            }
        }
        validUntil {
            kind
            ... on DiscountActionValidUntilDateTime {
                dateTime
            }

        }
        discountType {
            kind
        }
        usageLimitation {
            kind
            ... on DiscountActionUsageLimited {
                kind
                maxAmountOfUsages {
                    ... on DiscountActionAmountLimited{
                        max
                    }
                }
                maxAmountOfUsagesPerAccount {
                    ... on DiscountActionAmountLimited{
                        max
                    }
                }
                onlyForAccountIds
                onlyForProductIds
            }
        }
    }`

const EDIT_ACTION_MUTATION = graphql`
    mutation DiscountActionForm_EditActionMutation($input: UpdateDiscountActionInput!) {
        Admin {
            Billing {
                updateDiscountAction(input: $input) {
                    data {
                        node {
                            ... DiscountActionForm_DiscountActionFragment
                        }
                    }
                }
            }
        }
    }`


interface DiscountAction {
    id: string
    title: string
    discountType: string
    percentage: number
    validUntil?: string
    usageLimitation?: EditUsageLimited

}

interface OwnProps {
    discountActionFragmentRef: DiscountActionForm_DiscountActionFragment$key
}

export const DiscountActionForm = ({discountActionFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const discountActionFormNs = "billing.discount-code.action.form"

    const toast = useRef<Toast>(null)

    const discountAction = useFragment<DiscountActionForm_DiscountActionFragment$key>(DISCOUNT_ACTION_FRAGMENT, discountActionFragmentRef)

    const [editActionRaw, isLoading] = useMutation<DiscountActionForm_EditActionMutation>(EDIT_ACTION_MUTATION)

    const editAction = (actionInput: EditDiscountActionData) => editActionRaw({
        variables: {
            input: {
                id: discountAction.id,
                data: actionInput
            }
        },
        onCompleted: () => {
            toast.current?.show({
                severity: "success",
                life: 3000,
                summary: "Discount-Aktion",
                detail: "Erfolgreich gespeichert"
            })
        }
    })

    const formik = useFormik<DiscountAction>({
        initialValues: {
            id: discountAction.id,
            title: discountAction.title,
            discountType: discountAction.discountType.kind,
            percentage: discountAction.calcType.percentage || 0,
            validUntil: discountAction.validUntil.dateTime,
            usageLimitation: discountAction.usageLimitation.kind === "limited" ? {
                maxAmountOfUsages: discountAction.usageLimitation.maxAmountOfUsages?.max,
                maxAmountOfUsagesPerAccount: discountAction.usageLimitation.maxAmountOfUsagesPerAccount?.max,
                onlyForProductIds: discountAction.usageLimitation.onlyForProductIds?.map(l => l) || [],
                onlyForAccountIds: discountAction.usageLimitation.onlyForAccountIds?.map(l => l) || []
            } : undefined
        },
        validationSchema: Yup.object().shape({}),
        onSubmit: (values, {setSubmitting}) => {
            editAction({
                newTitle: values.title,
                newDiscountType: values.discountType as DiscountTypeKindInput,
                newPercentage: values.percentage,
                newValidUntil: values.validUntil,
                newUsageLimitation: values.usageLimitation,
            })
            setSubmitting(false)
        }
    })

    return <Card>
        <Toast ref={toast}/>
        <Suspense fallback={<ProgressSpinner/>}>
            {!isLoading ? <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div className="flex flex-column">
                    <Button
                        disabled={false}
                        type="submit"
                        icon="pi pi-save"
                        label={t(`billing.discount-code.button.save`)}
                        className="ml-auto w-8rem"/>
                    <ValidatedFieldV2<DiscountAction, string>
                        name={"title"}
                        label={t(`${discountActionFormNs}.title`)}
                        required={true}
                        component={DefaultTextFieldComponent}
                        formikConfig={formik}
                    />
                    <ValidatedFieldV2<DiscountAction, string>
                        name={"discountType"}
                        label={t(`${discountActionFormNs}.type`)}
                        component={({fieldValue, updateField}) =>
                            <DiscountTypeInput fieldValue={fieldValue}
                                               updateField={updateField}/>}
                        formikConfig={formik}
                    />
                    <ValidatedFieldV2<DiscountAction, number>
                        name={"percentage"}
                        label={t(`${discountActionFormNs}.calc-type`)}
                        component={({fieldValue, updateField}) =>
                            <InputNumber
                                max={100}
                                value={fieldValue}
                                onChange={(e) => updateField(e.value)}/>}
                        formikConfig={formik}
                    />
                    <ValidatedFieldV2<DiscountAction, string>
                        name={"validUntil"}
                        label={t(`${discountActionFormNs}.valid-until-long`)}
                        component={({fieldName, fieldValue, updateField}) =>
                            <Calendar name={fieldName}
                                      dateFormat={"dd.mm.yy"}
                                      showTime
                                      showButtonBar
                                      value={fieldValue ? moment(fieldValue).toDate() : undefined}
                                      onChange={(e) => updateField(e.value ? (moment(e.value as Date).format("YYYY-MM-DDTHH:mm:ss")) : undefined)}/>
                        }
                        formikConfig={formik}
                    />
                    <Card>
                        <ValidatedFieldV2<DiscountAction, EditUsageLimited>
                            name={"usageLimitation"}
                            label={t(`${discountActionFormNs}.usage-limitation.title`)}
                            component={({fieldValue, updateField}) =>
                                <UsageLimitationInput fieldValue={fieldValue} update={updateField}/>
                            }
                            formikConfig={formik}
                        />
                    </Card>
                </div>
            </form> : <ProgressSpinner/>}
        </Suspense>
    </Card>
}
