import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Offer } from "./Offer";

export const ENTITY_TYPE_OFFER_FOR_SAVING = "offer"
export const NEW_OFFER_FACTORY = () => {
    return new EntityWrapper<Offer>(
        undefined,
        {
            title: "",
            price: 1,
            rateCount: 1,
            monthlyRate: 1,
            taxRate: 0,
            courseId: "",
            courseIcon: undefined,
            courseTitle: "",
            isIHK: false
        }
    )
}

export interface OfferForSaving {
    title: string
    price: number
    discountPrice?: number
    monthlyRate: number
    rateCount: number
    taxRate: number
    courseRef: string,
    discountTitle?: string
    discountText?: string
    discountUntil?: string
}
