import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "./save-and-back-buttons.scss"

interface SaveAndBackButtonsProps extends WithTranslation {
    isSubmitting: boolean;
    entity: EntityWrapper<any>;
    backPath: string;
}

const SaveAndBackButtonsComponent = (props: SaveAndBackButtonsProps) => {
    const {isSubmitting, entity, backPath, t} = props;
    return (
        <>
            <button type="submit" disabled={isSubmitting}
                    className="btn btn-primary mr-3">
                {entity.id ?
                    t("save-and-back-buttons.edit") :
                    t("save-and-back-buttons.create")}
            </button>
            <NavLink to={backPath}>
                <button type="button" className="btn btn-secondary">{t("save-and-back-buttons.cancel")}</button>
            </NavLink>
        </>
    );
};

export const SaveAndBackButtons = withTranslation("core")(SaveAndBackButtonsComponent);
