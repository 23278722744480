import graphql from "babel-plugin-relay/macro"
import {useFragment} from "react-relay";
import {useTranslation} from "react-i18next";
import {
    ActionCalcTypeColumn_DiscountActionFragment$key
} from "../../../../../../../__generated__/ActionCalcTypeColumn_DiscountActionFragment.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
    fragment ActionCalcTypeColumn_DiscountActionFragment on DiscountAction {
        calcType {
            ... on DiscountActionPercentageCalcType {
                kind
                percentage
            }
        }
    }
`

interface OwnProps {
    discountActionFragmentRef: ActionCalcTypeColumn_DiscountActionFragment$key
}

export const ActionCalcTypeColumn = ({discountActionFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const discountAction = useFragment<ActionCalcTypeColumn_DiscountActionFragment$key>(DISCOUNT_ACTION_FRAGMENT, discountActionFragmentRef)

    return (
        <div>
            {discountAction.calcType.percentage}
            {t(`billing.discount-code.action.calc-type.${discountAction.calcType.kind}`)}
        </div>
    )
}
