/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UsageOrderColumn_DiscountCodeUsageFragment = {
    readonly order: {
        readonly id: string;
    } | null;
    readonly " $refType": "UsageOrderColumn_DiscountCodeUsageFragment";
};
export type UsageOrderColumn_DiscountCodeUsageFragment$data = UsageOrderColumn_DiscountCodeUsageFragment;
export type UsageOrderColumn_DiscountCodeUsageFragment$key = {
    readonly " $data"?: UsageOrderColumn_DiscountCodeUsageFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"UsageOrderColumn_DiscountCodeUsageFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageOrderColumn_DiscountCodeUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "order",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DiscountCodeUsage",
  "abstractKey": null
};
(node as any).hash = 'a4ad28fff2c428b54c996ff948ef11f0';
export default node;
