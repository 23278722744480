import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import {
    createEntitiesAction,
    EntityWrapper,
    fetchSingleEntityAction,
    selectSingleEntity,
    setContextAction, updateEntitiesAction
} from "@thekeytechnology/framework-react";

interface StateProps<EntityType> {
    entity?: EntityWrapper<EntityType>;
}

interface DispatchProps<EntityType, EntityTypeForSaving> {
    setContext: ReturnType<typeof setContextAction>;
    fetchSingleEntity: (id: string, factory?: (() => EntityWrapper<EntityType>)) => void;
    updateEntities: (entities: EntityWrapper<EntityTypeForSaving>[]) => void;
    createEntities: (entities: EntityWrapper<EntityTypeForSaving>[]) => void;
}

interface UpsertProps<EntityTypeForSaving> {
    upsertEntity: (entity: EntityWrapper<EntityTypeForSaving>) => void;
}

export type WithSingleEntityFromPathProps<EntityType, EntityTypeForSaving> =
    StateProps<EntityType>
    & UpsertProps<EntityTypeForSaving>

type Props<EntityType, EntityTypeForSaving> =
    StateProps<EntityType>
    & DispatchProps<EntityType, EntityTypeForSaving>
    & RouteComponentProps<any>;

export function WithSingleEntityFromPath<PropType extends object, EntityType, EntityTypeForSaving>(
    WrappedComponent: React.ComponentType<PropType & WithSingleEntityFromPathProps<EntityType, EntityTypeForSaving>>,
    entityType: string,
    pathParam: string,
    factory?: (() => EntityWrapper<EntityType>),
    context?: string,
    writeEntityType?: string) {
    const WithSingleEntityComponent = class InnerComponent extends Component<Props<EntityType, EntityTypeForSaving>> {
        public constructor(props: Props<EntityType, EntityTypeForSaving>) {
            super(props);
            this.upsertEntity = this.upsertEntity.bind(this);
        }

        public componentDidMount(): void {
            const {match: {params}} = this.props;
            if (params[pathParam]) {
                if (context) {
                    this.props.setContext(context);
                }
                this.props.fetchSingleEntity(params[pathParam], factory);
            }
        }

        public componentWillUnmount(): void {
            if (context) {
                this.props.setContext(undefined)
            }
        }

        public upsertEntity(entity: EntityWrapper<EntityTypeForSaving>) {
            if (entity.id) {
                this.props.updateEntities([entity])
            } else {
                this.props.createEntities([entity])
            }
        }

        public render() {
            const {
                entity, fetchSingleEntity, createEntities,
                updateEntities, history, location, match, staticContext, ...rest
            } = this.props;
            return (
                <WrappedComponent
                    entity={entity}
                    upsertEntity={this.upsertEntity}
                    {...rest as PropType}
                />
            );
        }
    };

    return withRouter(connect<StateProps<EntityType>, DispatchProps<EntityType, EntityTypeForSaving>, {}>(
        (state: any) => {
            return {
                entity: selectSingleEntity<EntityType>(entityType)(state)
            };
        },
        {
            setContext: setContextAction(entityType),
            fetchSingleEntity: fetchSingleEntityAction<EntityType>(entityType),
            updateEntities: updateEntitiesAction<EntityTypeForSaving>(writeEntityType ? writeEntityType : entityType),
            createEntities: createEntitiesAction<EntityTypeForSaving>(writeEntityType ? writeEntityType : entityType)
        }
    )(WithSingleEntityComponent));
}
