import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_PUBLISH_COURSE = "publish-course";

export interface PublishCoursePayload {
    courseId: string;
}

export const publishCourseAction = (courseId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_PUBLISH_COURSE,
            callType: API_PUBLISH_COURSE
        },
        payload: {
            courseId,
        }
    } as ApiCallAction<PublishCoursePayload>;
};
