import { FieldProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { NotificationDefinition } from "../../model/CustomerMessage";
import { BooleanSelect } from "../../../core/components/form/BooleanSelect";

type Props = FieldProps

export const NotificationDefinitionField = ({field: {name, value}, form}: Props) => {
    const {t} = useTranslation("customerMessages")

    const notificationDefinition = value ? value as NotificationDefinition : undefined

    const toggleNotification = (newState: boolean) => {
        const update = newState ? {
            title: "",
            body: ""
        } as NotificationDefinition : undefined;

        form.setFieldValue(name, update);
    };

    const update = (title: string, body: string) => {
        form.setFieldValue(name, {
            title,
            body
        } as NotificationDefinition)
    }

    return (<div>
        <BooleanSelect
            onChange={newValue => {
                toggleNotification(newValue)
            }}
            value={notificationDefinition !== undefined}
            trueLabel={t("entity.customer-message.notification-definition.true-label")}
            falseLabel={t("entity.customer-message.notification-definition.false-label")}
        />

        {notificationDefinition && <>
            <div className="mb-2">
                <label htmlFor={"notification-title"}>{t("entity.customer-message.notification-definition.title")}*</label>
                <input
                    className="form-control default-input"
                    name="notification-title" type="text" value={notificationDefinition.title}
                    maxLength={65}
                    minLength={10}
                    required={true}
                    onChange={event => update(event.target.value, notificationDefinition?.body)}/>
            </div>
            <div className="mb-2">
                <label htmlFor={"notification-body"}>{t("entity.customer-message.notification-definition.body")}*</label>
                <input
                    className="form-control default-input"
                    name="notification-body" type="text" value={notificationDefinition.body}
                    maxLength={178}
                    minLength={10}
                    required={true}
                    onChange={event => update(notificationDefinition?.title, event.target.value,)}/>
            </div>
        </>}

    </div>);
};

