/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DownloadUsageCsvButton_SearchDiscountUsageResultFragment = {
    readonly csv: string;
    readonly " $refType": "DownloadUsageCsvButton_SearchDiscountUsageResultFragment";
};
export type DownloadUsageCsvButton_SearchDiscountUsageResultFragment$data = DownloadUsageCsvButton_SearchDiscountUsageResultFragment;
export type DownloadUsageCsvButton_SearchDiscountUsageResultFragment$key = {
    readonly " $data"?: DownloadUsageCsvButton_SearchDiscountUsageResultFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"DownloadUsageCsvButton_SearchDiscountUsageResultFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DownloadUsageCsvButton_SearchDiscountUsageResultFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "csv",
      "storageKey": null
    }
  ],
  "type": "SearchDiscountCodeUsagesResult",
  "abstractKey": null
};
(node as any).hash = '02f6de18d0a8e94e52c8c5a9cbb158fd';
export default node;
