import {selectPagination} from "@thekeytechnology/framework-react";
import React from "react";
import {useSelector} from "react-redux";
import "./pagination-info.scss"
import {UnconnectedPaginationInfo} from "./UnconnectedPaginationInfo";

interface OwnProps {
    entityType: string;
    className?: string;
}

export const PaginationInfo = ({entityType, className}: OwnProps) => {
    const pagination = useSelector(selectPagination(entityType));

    return <UnconnectedPaginationInfo pagination={pagination} className={className}/>
};
