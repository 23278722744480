import { ApiCallAction, EntityWrapper } from "@thekeytechnology/framework-react";
import { CourseState } from "@thekeytechnology/thekey-academy-frontend-library";

export const API_UPDATE_COURSE_STATE = "update-course-state";

export interface UpdateCourseState {
    userId: string
    courseState: EntityWrapper<CourseState>
}

export const updateCourseStateAction = (userId: string, courseState: EntityWrapper<CourseState>) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_COURSE_STATE,
            callType: API_UPDATE_COURSE_STATE
        },
        payload: {
            userId,
            courseState
        }
    } as ApiCallAction<UpdateCourseState>;
};
