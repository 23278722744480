import { useDispatch, useSelector } from "react-redux";
import {
    DialogResult,
    selectApiBase,
    selectAuthState,
    showDialogAction,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import { useEffect, useState } from "react";

interface ReturnValue {
    isLockedBySomeone: boolean
}

export const useLocking = (resourceId: string): ReturnValue => {
    const apiBase = useSelector(selectApiBase);
    const authState = useSelector(selectAuthState)
    const dispatch = useDispatch();

    const [isLockedBySomeone, setIsLockedBySomeone] = useState<boolean>();

    const axios = withAuthedAxios(apiBase, authState)

    const tryAcquireLock = () => {
        if (isLockedBySomeone) {
            return;
        }

        axios.get(`/api/admin/v1/locks/${resourceId}`)
            .then(() => {
                setIsLockedBySomeone(false);
            }).catch(reason => {

            if (reason?.response?.status === 423) {
                setIsLockedBySomeone(true)
                dispatch(showDialogAction({
                    title: "Diese Ressource wird gerade von jemand anderem bearbeitet",
                    question: "Möchtest du übernehmen?",
                    explanation: `Um Doppel-Bearbeitung zu verhindern, kannst du diese Ressource nicht bearbeiten. Momentan bearbeitet sie ${reason?.response.data.lockingUserName}. Du kannst übernehmen, dann kann ${reason?.response.data.lockingUserName} nicht weiterarbeiten, aber ACHTUNG - er verliert auch alle ungespeicherten Änderungen!`,
                    affirmativeText: "Bearbeitung Forcieren",
                    negativeText: "Abbrechen",
                }, result => {
                    if (result === DialogResult.ACCEPT) {
                        axios.get(`/api/admin/v1/locks/${resourceId}/force`)
                            .then(() => {
                                setIsLockedBySomeone(false);
                            })
                    }
                }))
            }
        })
    }

    useEffect(() => {
        tryAcquireLock();
        const interval = setInterval(() => {
            if (!isLockedBySomeone) {
                tryAcquireLock()
            }
        }, 5000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [])

    return {isLockedBySomeone: isLockedBySomeone as boolean};
}
