import { Environment, RecordSource, Store as RelayStore } from "relay-runtime";
import { logoutAction, Message, postMessageAction, selectAuthState } from "@thekeytechnology/framework-react";
import {
    errorMiddleware,
    loggerMiddleware,
    perfMiddleware,
    progressMiddleware,
    RelayNetworkLayer,
    retryMiddleware,
    uploadMiddleware,
    urlMiddleware
} from "react-relay-network-modern";
import {ERROR_MESSAGES} from "./_modules/core/error/ERROR_MESSAGES";

const isDev = process.env.NODE_ENV === "development"

const network = (store: any) => new RelayNetworkLayer(
    [
        urlMiddleware({
            url: () => Promise.resolve(`${process.env.REACT_APP_API_BASE}/api/graphql`),
        }),
        isDev ? loggerMiddleware() : null,
        isDev ? errorMiddleware() : null,
        isDev ? perfMiddleware() : null,
        retryMiddleware({
            fetchTimeout: 15000,
            retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100, // or simple array [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600],
            beforeRetry: ({abort, attempt}) => {
                if (attempt > 3) {
                    abort();
                }
            },
            statusCodes: [500, 503, 504],
        }),
        progressMiddleware({
            onProgress: (current, total) => {
                console.log("Downloaded: " + current + " B, total: " + total + " B");
            },
        }),
        uploadMiddleware(),
        // example of the custom inline middleware
        (next) => async (req) => {

            const authModuleState = selectAuthState(store.getState())

            const token = authModuleState ? authModuleState.token : null;
            const accountId = authModuleState ? authModuleState.currentAccount ? authModuleState.currentAccount.accountId : undefined : undefined;

            if (token && accountId) {
                req.fetchOpts.headers["X-Auth-Token"] = token
                req.fetchOpts.headers["X-Auth-Account-Id"] = accountId
            }

            const res = await next(req);
            if (res.errors) {
                res.errors.forEach(error => {
                    store.dispatch(postMessageAction(Message.TYPE_DANGER, ERROR_MESSAGES[error.message]))
                })
            }

            if (res.errors?.find(e => e.message === "token-unknown")) {
                store.dispatch(logoutAction());
            }

            return res;
        },
    ],
);

// Export a singleton instance of Relay Environment configured with our network function:
const relayStore = new RelayStore(new RecordSource());

export const RelayEnvironment = (store: any) => new Environment({
    network: network(store),
    store: relayStore,
});
