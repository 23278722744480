import graphql from 'babel-plugin-relay/macro'
import {useFragment, useMutation} from "react-relay";
import {useFormik} from "formik";
import * as Yup from "yup";
import {ValidatedFieldV2} from "../../../../core/components/form/ValidatedFieldV2";
import {Button} from "primereact/button";
import React from "react";
import {DefaultTextAreaComponent} from "../../../../core/components/form/forms/DefaultTextAreaComponent";
import {EditAdditionalInformation_UserMetaFragment$key} from '../../../../../__generated__/EditAdditionalInformation_UserMetaFragment.graphql';
import {EditAdditionalInformation_EditAdditionalInformationMutation} from "../../../../../__generated__/EditAdditionalInformation_EditAdditionalInformationMutation.graphql";
import {useTranslation} from "react-i18next";

const EDIT_ADDITIONAL_INFORMATION_MUTATION = graphql`
    mutation EditAdditionalInformation_EditAdditionalInformationMutation($input: EditAdditionalInformationInput!) {
        Admin {
            User {
                editAdditionalInformation(input: $input) {
                    userMeta {
                        ...EditAdditionalInformation_UserMetaFragment
                    }
                }
            }
        }
    }
`

const USER_META_FRAGMENT = graphql`
    fragment EditAdditionalInformation_UserMetaFragment on UserMeta {
        additionalInformation
    }
`

export interface OwnProps {
    userId: string
    additionalInformationFragmentRef: EditAdditionalInformation_UserMetaFragment$key
}

interface FormikProps {
    additionalInformation: string | null
}

export const EditAdditionalInformation = ({userId, additionalInformationFragmentRef}: OwnProps) => {
    const {t} = useTranslation("admin")

    const meta = useFragment<EditAdditionalInformation_UserMetaFragment$key>(USER_META_FRAGMENT, additionalInformationFragmentRef)
    const [updateAdditionalInformation, isInFlight] = useMutation<EditAdditionalInformation_EditAdditionalInformationMutation>(EDIT_ADDITIONAL_INFORMATION_MUTATION)

    const formik = useFormik<FormikProps>({
        enableReinitialize: true,
        initialValues: {
            additionalInformation: meta?.additionalInformation
        },
        validationSchema: Yup.object().shape({}),
        onSubmit: (values, {setSubmitting}) => {
            updateAdditionalInformation({
                variables: {
                    input: {
                        userId: userId,
                        additionalInformation: values.additionalInformation
                    }
                }
            })
            setSubmitting(false);
        },
    })

    return <form onSubmit={formik.handleSubmit} className="p-fluid">

        <div className="form-group row">
            <ValidatedFieldV2<FormikProps, string>
                name={"additionalInformation"}
                component={DefaultTextAreaComponent}
                formikConfig={formik}
            />
        </div>
        <Button disabled={isInFlight}
                loading={isInFlight}
                className="w-1"
                label={t("actions.save")}
                type={"button"}
                onClick={() => formik.handleSubmit()}/>
    </form>
}
