/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RearrangeCustomerMessageDefinitionPagesDataInput = {
    rearrangedPageIds: Array<string>;
    clientMutationId?: string | null;
};
export type CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutationVariables = {
    input: RearrangeCustomerMessageDefinitionPagesDataInput;
};
export type CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutationResponse = {
    readonly Admin: {
        readonly CustomerMessageV2: {
            readonly rearrangeCustomerMessageDefinitionPages: {
                readonly customerMessageDefinitionV2: {
                    readonly " $fragmentRefs": FragmentRefs<"CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment">;
                };
            } | null;
        };
    };
};
export type CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutation = {
    readonly response: CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutationResponse;
    readonly variables: CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutationVariables;
};



/*
mutation CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutation(
  $input: RearrangeCustomerMessageDefinitionPagesDataInput!
) {
  Admin {
    CustomerMessageV2 {
      rearrangeCustomerMessageDefinitionPages(input: $input) {
        customerMessageDefinitionV2 {
          ...CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment
          id
        }
      }
    }
  }
}

fragment CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {
  id
  pages {
    edges {
      node {
        id
        internalTitle
        ...EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment on CustomerMessageDefinitionPage {
  id
  internalTitle
  imageOpt {
    fileType
    id
    name
    rawId
    rawUrl
    thumbnailUrl
    uploadDateTime
    url
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RearrangeCustomerMessageDefinitionPagesDataPayload",
                "kind": "LinkedField",
                "name": "rearrangeCustomerMessageDefinitionPages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionV2",
                    "kind": "LinkedField",
                    "name": "customerMessageDefinitionV2",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RearrangeCustomerMessageDefinitionPagesDataPayload",
                "kind": "LinkedField",
                "name": "rearrangeCustomerMessageDefinitionPages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionV2",
                    "kind": "LinkedField",
                    "name": "customerMessageDefinitionV2",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageDefinitionPagesConnection",
                        "kind": "LinkedField",
                        "name": "pages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CustomerMessageDefinitionPagesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CustomerMessageDefinitionPage",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "internalTitle",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "File",
                                    "kind": "LinkedField",
                                    "name": "imageOpt",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "fileType",
                                        "storageKey": null
                                      },
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "rawId",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "rawUrl",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "thumbnailUrl",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "uploadDateTime",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__id",
                                "storageKey": null
                              }
                            ]
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "filters": null,
                        "handle": "connection",
                        "key": "CustomerMessageDefinitionPagesOrderList_pages",
                        "kind": "LinkedHandle",
                        "name": "pages"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03a058a80aadb3144acac65cb45bff6d",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutation(\n  $input: RearrangeCustomerMessageDefinitionPagesDataInput!\n) {\n  Admin {\n    CustomerMessageV2 {\n      rearrangeCustomerMessageDefinitionPages(input: $input) {\n        customerMessageDefinitionV2 {\n          ...CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {\n  id\n  pages {\n    edges {\n      node {\n        id\n        internalTitle\n        ...EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment on CustomerMessageDefinitionPage {\n  id\n  internalTitle\n  imageOpt {\n    fileType\n    id\n    name\n    rawId\n    rawUrl\n    thumbnailUrl\n    uploadDateTime\n    url\n  }\n}\n"
  }
};
})();
(node as any).hash = '7ae422611304a65c0e72042493f450ab';
export default node;
