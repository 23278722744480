export interface ApplicationRule {
    ruleType: string
}

export const APPLICATION_RULE_TYPE_LIMIT_FOR_USERS = "limit-for-users"

export interface LimitForUsersApplicationRule extends ApplicationRule {
    limitToAccountType?: string
    limitToUserRole?: string
    limitAmount: number
    canRequestMore: boolean
}

export const APPLICATION_RULE_LIMIT_FOR_ENTIRE_ACCOUNT = "limit-for-entire-account"

export interface LimitForEntireAccountApplicationRule extends ApplicationRule {
    limitToAccountType: string
    limitAmount: number
}
