import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_USER_ROLES_ADMIN = "fetch-user-roles-admin";

export const fetchUserRolesAdminAction = (userId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_USER_ROLES_ADMIN,
            callType: API_FETCH_USER_ROLES_ADMIN
        },
        payload: userId,
    } as ApiCallAction<string>;
};
