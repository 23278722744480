/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserActions_UserFragment = {
    readonly id: string;
    readonly name: string;
    readonly " $refType": "UserActions_UserFragment";
};
export type UserActions_UserFragment$data = UserActions_UserFragment;
export type UserActions_UserFragment$key = {
    readonly " $data"?: UserActions_UserFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"UserActions_UserFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserActions_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '89128325fdfb872c7cdbb694f1306634';
export default node;
