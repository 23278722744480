/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CodeActionColumn_DiscountCodeFragment = {
    readonly id: string;
    readonly " $refType": "CodeActionColumn_DiscountCodeFragment";
};
export type CodeActionColumn_DiscountCodeFragment$data = CodeActionColumn_DiscountCodeFragment;
export type CodeActionColumn_DiscountCodeFragment$key = {
    readonly " $data"?: CodeActionColumn_DiscountCodeFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CodeActionColumn_DiscountCodeFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodeActionColumn_DiscountCodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DiscountCodeV2",
  "abstractKey": null
};
(node as any).hash = '476e05c1027683e06b76f50269114c63';
export default node;
