import { Parameter } from "./parameter";
import { Condition } from "./condition";
import { TriggerDefinition } from "./definitions/trigger-definition";
import * as Yup from "yup";
import { TFunction } from "i18next";

export interface Trigger {
    key: string
    parameters: Parameter[]
    conditions: Condition[],
    delayInHours?: number,
    repeatable: boolean
}

export const YUP_SCHEMA_TRIGGER = (definitions: TriggerDefinition[], t: TFunction) =>
    Yup.object()
        .required(t("entity.triggered-email.labels.trigger") + " " + t("translation:is a required field"))
        .test("test", "test", function(value) {
            const trigger = value as Trigger;

            const triggerDefinition = definitions.find(d => d.key === trigger.key)

            if (!triggerDefinition) {
                return this.createError({
                    path: "trigger",
                    message: t("trigger-field.errors.unknown-trigger-type", {triggerType: trigger.key})
                });
            }

            for (const parameterDefinition of triggerDefinition.parameters) {
                const parameter = trigger.parameters.find(p => p.key === parameterDefinition.key)
                if (parameter === undefined) {
                    return this.createError({
                        path: "trigger",
                        message: t("trigger-field.errors.parameter-not-set", {parameter: t("trigger-field.trigger-parameters." + parameterDefinition.key)})
                    });
                }
                if (parameter.dataType !== parameterDefinition.dataType) {
                    return this.createError({
                        path: "trigger",
                        message: t("trigger-field.errors.parameter-wrong-data-type", {parameter: t("trigger-field.trigger-parameters." + parameterDefinition.key)})
                    });
                }
            }

            for (const condition of trigger.conditions) {
                const definition = triggerDefinition.permissibleConditions.find(td => td.key === condition.key)

                if (!definition) {
                    return this.createError({
                        path: "trigger",
                        message: t("trigger-field.errors.unknown-condition-definition", {condition: condition.key})
                    });
                }

                for (const parameterDefinition of definition.configurationParameters) {
                    const parameter = condition.parameters.find(p => p.key === parameterDefinition.key)
                    if (parameter === undefined) {
                        return this.createError({
                            path: "trigger",
                            message: t("trigger-field.errors.condition-parameter-not-set", {
                                condition: t("trigger-field.conditions." + condition.key),
                                parameter: t("trigger-field.trigger-parameters." + parameterDefinition.key)
                            })
                        });
                    }
                    if (parameter.dataType !== parameterDefinition.dataType) {
                        return this.createError({
                            path: "trigger",
                            message: t("trigger-field.errors.condition-parameter-wrong-data-type", {
                                condition: t("trigger-field.conditions." + condition.key),
                                parameter: t("trigger-field.trigger-parameters." + parameterDefinition.key)
                            })
                        });
                    }
                }
            }

            return true;
        });
