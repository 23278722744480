import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_MASS_UNLOCK = "mass-unlock";

export interface MassUnlock {
    accountId?: string
    accountName?: string
    courseIds: string[]
    users: UnlockUser[]
}

export interface UnlockUser {
    firstName?: string
    lastName?: string
    email?: string
}


export interface MassUnlockResponse {
    newUsers: number
    existingUsers: number
}

export const massUnlockAction = (unlock: MassUnlock) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_MASS_UNLOCK,
            callType: API_MASS_UNLOCK
        },
        payload: unlock
    } as ApiCallAction<MassUnlock>;
};
