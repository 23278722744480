import React, {useState} from 'react';
import graphql from 'babel-plugin-relay/macro'
import {useRouteMatch} from "react-router";
import {EDIT_DISCOUNT_ACTION} from "../../../BillingModuleRoutes";
import {useLazyLoadQuery} from "react-relay";
import {DiscountActionScreen_Query} from "../../../../../__generated__/DiscountActionScreen_Query.graphql";
import {DiscountActionForm} from "./form/DiscountActionForm";
import {BreadCrumb} from "primereact/breadcrumb";
import {Accordion, AccordionTab} from "primereact/accordion";
import {DiscountCodeTable} from "./table/DiscountCodeTable";
import {useTranslation} from "react-i18next";

export interface Account {
    id: string
    name: string
}


const QUERY = graphql`
    query DiscountActionScreen_Query($id: ID!) {
        node(id: $id) {
            ... on DiscountAction {
                title
                ... DiscountActionForm_DiscountActionFragment
            }
        }
        ...DiscountCodeTable_QueryFragment @arguments(first: 5, after: null, id: $id)
    }
`

export const DiscountActionScreen = () => {
    const {t} = useTranslation("billingV3")
    const [activeIndex, setActiveIndex] =useState(0)

    const route = useRouteMatch<{ discountActionId: string }>(EDIT_DISCOUNT_ACTION)
    const discountActionId = route?.params.discountActionId!

    const query = useLazyLoadQuery<DiscountActionScreen_Query>(QUERY, {
        id: discountActionId
    })


    const items = [
        {label: 'Alle Aktionen', url: `${window.location.origin}/discount-actions`},
        {label: query.node?.title, url: `${window.location.origin}/discount-actions/${discountActionId}/edit`}
    ];

    return ( query.node &&
        <div>
            <BreadCrumb className="mb-5" model={items}/>
            <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <AccordionTab header={t("billing.discount-code.action.accordion.header-one")}>
                    <DiscountActionForm discountActionFragmentRef={query.node}/>
                </AccordionTab>
                <AccordionTab header={t("billing.discount-code.action.accordion.header-two")}>
                    <DiscountCodeTable query={query} discountActionId={discountActionId}/>
                </AccordionTab>
            </Accordion>
        </div>
    )
}
