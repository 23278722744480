import { faHeading } from "@fortawesome/free-solid-svg-icons";
import { EntityWrapper, TkFile, } from "@thekeytechnology/framework-react";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { extractId } from "../../../../reducers/changes/common/helpers";
import { EditElementModalBase } from "../common/EditElementModalBase";
import { CONNECTION_VALIDATION_SCHEME, TITLE_VALIDATION_SCHEME } from "../common/GeneralElementSettingsEditor";
import { connect } from "react-redux";
import { selectImage } from "../../../../selectors";
import { InlineAttachmentField } from "../../../../../core/components/attachment/InlineAttachmentField";
import { ELEMENT_TYPE_IMAGE, ImageElement } from "@thekeytechnology/thekey-academy-frontend-library";
import { ValidatedField } from "../../../../../core/components/form/ValidatedField";

interface OwnProps {
    element: EntityWrapper<ImageElement>;
    handleSubmit: (element: EntityWrapper<ImageElement>) => void;
    handleClose: () => void;
}

interface StateProps {
    image: EntityWrapper<TkFile> | undefined
}

type Props = OwnProps & StateProps;

const EditImageElementFormComponent = ({element, handleSubmit, handleClose, image}: Props) => {
    const {t} = useTranslation(["courses", "core"])
    return (
        <Formik
            initialValues={{
                internalTitle: element.entity.generalSettings.internalTitle,
                points: element.entity.generalSettings.points,
                lengthInSeconds: element.entity.generalSettings.lengthInSeconds,
                connections: element.entity.generalSettings.connections,
                title: element.entity.title,
                image,
            }}
            validationSchema={Yup.object().shape({
                internalTitle: TITLE_VALIDATION_SCHEME(t),
                connections: CONNECTION_VALIDATION_SCHEME(t),
                title: Yup.string().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-element-form.common.fields.title")})),
                image: Yup.object().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-element-form.common.fields.image")})),
            })}
            onSubmit={(values, {setSubmitting}) => {
                handleSubmit(new EntityWrapper<ImageElement>(
                    element.id,
                    {
                        generalSettings: {
                            internalTitle: values.internalTitle,
                            points: values.points,
                            lengthInSeconds: values.lengthInSeconds,
                            connections: values.connections
                        },
                        elementType: ELEMENT_TYPE_IMAGE,
                        elementPath: element.entity.elementPath,
                        title: values.title,
                        imageRef: values.image?.id!,
                        versions: element.entity.versions
                    },
                    element.temporaryId,
                ));
                setSubmitting(false);
            }}
        >
            {formikState => (
                <EditElementModalBase title={t("course-editor.edit-element-form.image.heading")}
                                      currentElementId={extractId(element)}
                                      formikState={formikState}
                                      handleClose={handleClose}>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.common.fields.title")}
                            name="title"
                            formikState={formikState}
                            type={"text"}
                            innerIcon={faHeading}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.common.fields.image")}
                            name="image"
                            formikState={formikState}
                            component={InlineAttachmentField}
                            isSingleSelect={true}
                        />
                    </div>
                </EditElementModalBase>
            )}
        </Formik>
    );
};

export const EditImageElementForm = connect<StateProps, {}, OwnProps>(
    (state: any, {element}: OwnProps) => ({
        image: selectImage(element?.entity.imageRef)(state)
    })
)(EditImageElementFormComponent);
