import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_TRIGGER_DEFINITIONS = "fetch-trigger-definitions";

export const fetchTriggerDefinitionsAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_TRIGGER_DEFINITIONS,
            callType: API_FETCH_TRIGGER_DEFINITIONS
        },
        payload: undefined
    } as ApiCallAction<undefined>;
};
