import React from "react";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {
    CodeNumUsagesColumn_DiscountCodeFragment$key
} from "../../../../../../../__generated__/CodeNumUsagesColumn_DiscountCodeFragment.graphql";

const DISCOUNT_CODE_FRAGMENT = graphql`
    fragment CodeNumUsagesColumn_DiscountCodeFragment on DiscountCodeV2 {
        numUsages
    }
`

interface OwnProps {
    discountCodeFragmentRef: CodeNumUsagesColumn_DiscountCodeFragment$key
}

export const CodeNumUsagesColumn = ({discountCodeFragmentRef}: OwnProps) => {
    const discountCode = useFragment<CodeNumUsagesColumn_DiscountCodeFragment$key>(DISCOUNT_CODE_FRAGMENT, discountCodeFragmentRef)

    return <div>{discountCode.numUsages}</div>
}
