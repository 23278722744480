import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import {useTranslation} from "react-i18next";
import "./qna-table.scss";
import {ENTITY_TYPE_QNA, Qna} from "../model/qna";
import { AboveTableContainer } from "../../core/components/containers/Container";
import { PaginationInfo } from "../../core/components/pagination/PaginationInfo";
import { EntityDeletionButton } from "../../core/components/button/EntityDeletionButton";
import { Table, TableColumn } from "../../core/components/table/Table";
import { EntityTableHeader } from "../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../core/components/table/AsyncEntityTableBody";
import { EntityTableRow } from "../../core/components/table/EntityTableRow";
import { DateTimeDisplay } from "../../core/components/datetime/DateTimeDisplay";
import { ActionTableColumn } from "../../core/components/table/ActionTableColumn";
import { ActionLink } from "../../core/components/icon-link-tooltip/ActionLink";

type Props =
    WithEntityListProps<Qna>
    & WithSelectionsProps<string>;

const QnasTableComponent = ({
                                entities,
                                selectionModel,
                                entityApiState,
                            }: Props) => {

    const {t} = useTranslation(["qna", "courses"]);

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_QNA} className="mr-auto"/>

                <EntityDeletionButton entityType={ENTITY_TYPE_QNA}
                                      selectedEntities={selectionModel.selections}
                                      label={t("entity.qna.singular")}
                                      labelPlural={t("entity.qna.plural")}
                />
            </AboveTableContainer>

            <Table itemCount={entities.length} className="qna-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.qna.labels.created-at")}</TableColumn>
                    <TableColumn>{t("entity.qna.labels.course")}</TableColumn>
                    <TableColumn>{t("entity.qna.labels.lesson")}</TableColumn>
                    <TableColumn>{t("entity.qna.labels.element")}</TableColumn>
                    <TableColumn>{t("entity.qna.labels.question")}</TableColumn>
                    <TableColumn>{t("entity.qna.labels.user")}</TableColumn>
                    <TableColumn>{t("entity.qna.labels.answer")}</TableColumn>
                    <TableColumn>{t("entity.qna.labels.isPublic")}</TableColumn>

                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={qna =>
                        <EntityTableRow
                            key={qna.id}
                            entity={qna} selectionModel={selectionModel}>
                            <TableColumn>
                                <DateTimeDisplay dateTime={qna.entity.createdAt}/>
                            </TableColumn>
                            <TableColumn>
                                {qna.entity.course.entity.containerMeta.title}
                            </TableColumn>
                            <TableColumn>
                                {qna.entity.lesson ?
                                    <>{t("courses:entity.lesson.singular")} {qna.entity.lesson.entity.lessonPath.moduleIndex + 1}.{qna.entity.lesson.entity.lessonPath.lessonIndex + 1}: {qna.entity.lesson.entity.containerMeta.title}</>
                                    : null}
                            </TableColumn>
                            <TableColumn>
                                {qna.entity.element ? qna.entity.element.entity.generalSettings.internalTitle : t("qnas-table.no-element")}
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {qna.entity.question}
                            </TableColumn>
                            <TableColumn>
                                {qna.entity.user.entity.name}
                            </TableColumn>
                            <TableColumn>
                                {qna.entity.answer ? qna.entity.answer : t("qnas-table.not-yet-answered")}
                            </TableColumn>
                            <TableColumn>
                                {qna.entity.isPublic ? t("entity.qna.labels.isPublic-true") : t("entity.qna.labels.isPublic-false")}
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink to={`/qnas/${qna.id}/edit`} icon={faPencilAlt}
                                            tooltip={t("entity.qna.labels.edit-tooltip")}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const QnasTable = WithSelections<any, string>(
    WithEntityList(
        QnasTableComponent,
        ENTITY_TYPE_QNA
    )
);
