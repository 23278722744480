import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../core/components/containers/Container";
import { ENTITY_TYPE_FAQ } from "../model/FAQ";
import { FAQFilters } from "./FAQFilters";
import { FAQTable } from "./FAQTable";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const ExpertOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/faqs"}>{t("entity.faq.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.faq.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <FAQFilters/>
            </BackgroundContainer>
            <ContentContainer>
                <FAQTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_FAQ}/>
            </PaginationContainer>
        </>
    );
};

export const FAQOverview = withTranslation("faq")(ExpertOverviewComponent);
