import { faClock, faDice, faHeading } from "@fortawesome/free-solid-svg-icons";
import { FormikState } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { ConnectionsField } from "./connection/ConnectionsField";
import { Connection, CONNECTION_TYPE_SOURCE } from "@thekeytechnology/thekey-academy-frontend-library";
import { ValidatedField } from "../../../../../core/components/form/ValidatedField";

interface OwnProps {
    currentElementId: string;
    formikState: FormikState<any>;
}

type Props = OwnProps & WithTranslation;

const GeneralElementSettingsComponent = (props: Props) => {
    const {formikState, currentElementId, t} = props;
    return <>
        <div className="form-group row">
            <ValidatedField
                icon={faHeading}
                label={t("course-editor.edit-element-form.common.fields.internal-title")}
                name="internalTitle"
                formikState={formikState}
                type="text"
                placeholder={t("course-editor.edit-element-form.common.fields.title-placeholder")}
                required
            />
        </div>
        <div className="form-group row">
            <ValidatedField
                icon={faDice}
                label={t("course-editor.edit-element-form.common.fields.points")}
                name="points"
                formikState={formikState}
                type="number"
                min={0}
                placeholder={t("course-editor.edit-element-form.common.fields.points-placeholder")}
                required
            />
        </div>
        <div className="form-group row">
            <ValidatedField
                icon={faClock}
                label={t("course-editor.edit-element-form.common.fields.length-in-seconds")}
                helpText={t("course-editor.edit-element-form.common.fields.length-in-seconds-helper")}
                name="lengthInSeconds"
                formikState={formikState}
                type="number"
                min={0}
                required
            />
        </div>

        <div className="form-group row">
            <ValidatedField
                label={t("course-editor.edit-element-form.common.fields.connections")}
                name="connections"
                formikState={formikState}
                component={ConnectionsField}
                currentElementId={currentElementId}
            />
        </div>
    </>;
};

export const GENERAL_FIELDS = ["title", "points", "lengthInSeconds", "connections"];

export const GeneralElementSettingsEditor = withTranslation("courses")(GeneralElementSettingsComponent);

export const CONNECTION_VALIDATION_CONNECTION_SHAPE = (t: TFunction) => Yup.object().shape({
    targetElementRef: Yup.string().required(t("course-editor.edit-element-form.common.fields.connections-error")),
    connectionType: Yup.string().required(t("course-editor.edit-element-form.common.fields.connections-error"))
});

export const CONNECTION_VALIDATION_SCHEME = (t: TFunction) => Yup.array()
    .of(
        CONNECTION_VALIDATION_CONNECTION_SHAPE(t)
    ).typeError(t("course-editor.edit-element-form.common.fields.connections-error"));

export const CONNECTION_VALIDATION_SCHEME_WITH_MANDATORY_SOURCE = (t: TFunction) =>
    Yup.array().min(1, t("course-editor.edit-element-form.common.fields.connections-error-mandatory-source"))
        .of(CONNECTION_VALIDATION_CONNECTION_SHAPE(t))
        .test("test", "test", function(value) {
            const connections = value as Connection[];
            let hasSource = false;
            for (const connection of connections) {
                if (connection.connectionType === CONNECTION_TYPE_SOURCE) {
                    hasSource = true;
                }
            }
            if (!hasSource) {
                return this.createError({
                    path: "connections",
                    message: t("course-editor.edit-element-form.common.fields.connections-error-mandatory-source")
                });
            }
            return true;
        });

export const TITLE_VALIDATION_SCHEME = (t: TFunction) => Yup.string()
    .required(t("core:forms.required-field", {fieldName: t("course-editor.edit-element-form.common.fields.title")}));
