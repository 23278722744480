import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_ORDER_ADMIN = "fetch-order-admin";

export const fetchOrderAdminAction = (orderId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_ORDER_ADMIN,
            callType: API_FETCH_ORDER_ADMIN
        },
        payload: orderId,
    } as ApiCallAction<string>;
};
