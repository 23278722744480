import { EditedCourseState } from "../../edited-course";
import { extractId, } from "../common/helpers";
import { RemoveLessonAction } from "../../../actions/lesson/remove-lesson";
import update from "immutability-helper";

export const removeLesson = (state: EditedCourseState, removeLessonAction: RemoveLessonAction) => {
    const parentModule = state.editedCourse!.entity.modules.find(m => m.id === removeLessonAction.parentModuleId || m.temporaryId === removeLessonAction.parentModuleId)!
    const parentModuleIndex = state.editedCourse!.entity.modules.indexOf(parentModule);
    const lessonInModuleIndex = parentModule.entity.lessonsRef.indexOf(extractId(removeLessonAction.lesson))
    const lessonInLessonListIndex = state.editedCourse!.entity.lessons.indexOf(removeLessonAction.lesson)

    const elementsInCourse = state.editedCourse!.entity.elements;
    const elementsInLesson = removeLessonAction.lesson.entity.elementsRef;

    return update(state,
        {
            hasUnsavedChanges: {$set: true},
            editedCourse: {
                entity: {
                    modules: {[parentModuleIndex]: {entity: {lessonsRef: {$splice: [[lessonInModuleIndex, 1]]}}}},
                    elements: {
                        $set: elementsInCourse.filter(el => !elementsInLesson.includes(extractId(el)))
                    },
                    lessons: {$splice: [[lessonInLessonListIndex, 1]]}
                }
            }
        }
    )
};
