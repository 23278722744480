/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CustomerMessageDefinitionV2OverviewScreen_QueryVariables = {
    numItemsPerPage: number;
};
export type CustomerMessageDefinitionV2OverviewScreen_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"CustomerMessageDefinitionV2OverviewScreen_PaginationFragment">;
};
export type CustomerMessageDefinitionV2OverviewScreen_Query = {
    readonly response: CustomerMessageDefinitionV2OverviewScreen_QueryResponse;
    readonly variables: CustomerMessageDefinitionV2OverviewScreen_QueryVariables;
};



/*
query CustomerMessageDefinitionV2OverviewScreen_Query(
  $numItemsPerPage: Int!
) {
  ...CustomerMessageDefinitionV2OverviewScreen_PaginationFragment_35a4lq
}

fragment CustomerMessageDefinitionV2OverviewScreen_PaginationFragment_35a4lq on Query {
  Admin {
    CustomerMessageV2 {
      CustomerMessageDefinitions(internalTitle: "", first: $numItemsPerPage) {
        edges {
          node {
            id
            internalTitle
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "numItemsPerPage"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "numItemsPerPage"
  },
  {
    "kind": "Literal",
    "name": "internalTitle",
    "value": ""
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageDefinitionV2OverviewScreen_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "CustomerMessageDefinitionV2OverviewScreen_PaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerMessageDefinitionV2OverviewScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CustomerMessageDefinitionsV2Connection",
                "kind": "LinkedField",
                "name": "CustomerMessageDefinitions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionsV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageDefinitionV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "internalTitle"
                ],
                "handle": "connection",
                "key": "CustomerMessageDefinitionOverviewScreen_CustomerMessageDefinitions",
                "kind": "LinkedHandle",
                "name": "CustomerMessageDefinitions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce1aa1e5b7208607f6a9564e1eaa5be4",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageDefinitionV2OverviewScreen_Query",
    "operationKind": "query",
    "text": "query CustomerMessageDefinitionV2OverviewScreen_Query(\n  $numItemsPerPage: Int!\n) {\n  ...CustomerMessageDefinitionV2OverviewScreen_PaginationFragment_35a4lq\n}\n\nfragment CustomerMessageDefinitionV2OverviewScreen_PaginationFragment_35a4lq on Query {\n  Admin {\n    CustomerMessageV2 {\n      CustomerMessageDefinitions(internalTitle: \"\", first: $numItemsPerPage) {\n        edges {\n          node {\n            id\n            internalTitle\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cd60a67b7b9ba8f4bdd8c785231dcb84';
export default node;
