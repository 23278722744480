import { FieldProps } from "formik";
import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";
import { PersologAnswer, PersologQuestion } from "@thekeytechnology/thekey-academy-frontend-library";
import { PersologClassCode } from "@thekeytechnology/thekey-academy-frontend-library/dist/core/model/element/persolog/persolog-class";

type Props = FieldProps;

export const PersologQuestionField = ({field: {name, value}, form}: Props) => {
    const {t} = useTranslation("courses")

    const persologQuestions = value ? value as PersologQuestion[] : [];

    const updateQuestion = (question: PersologQuestion, index: number, updatedQuestion?: string) => {
        const newState = update(persologQuestions, {
            [index]: {
                $set: {
                    question: updatedQuestion,
                    answers: question.answers
                } as PersologQuestion
            }
        })
        form.setFieldValue(name, newState);
    };

    const updateAnswer = (answer: PersologAnswer, question: PersologQuestion, questionIndex: number, answerIndex: number) => {
        const updatedQuestion = update(persologQuestions, {
            [questionIndex]: {
                $set:
                    {
                        question: question.question,
                        answers: update(value[questionIndex].answers, {
                            [answerIndex]: {
                                $set: answer
                            }
                        })
                    } as PersologQuestion
            }
        })

        form.setFieldValue(name, updatedQuestion)
    }

    return (
        <div className="questionnaire-answers d-flex flex-column w-100">
            {persologQuestions.map((persologQuestion: PersologQuestion, index: number) =>
                <Card key={index}>
                    <Card.Body>
                        <h3 className="d-flex align-items-center">
                            {t("course-editor.edit-element-form.persolog.persolog-question", {index: index + 1})}

                        </h3>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.edit-element-form.persolog.question")}*</label>
                            <div className="col-sm-10">
                                <input
                                    className="form-control default-input"
                                    type="text"
                                    placeholder={t("course-editor.edit-element-form.persolog.question-placeholder")}
                                    value={persologQuestion.question}
                                    onChange={(event => updateQuestion(persologQuestion, index, event.target.value))}/>
                            </div>
                        </div>
                        {
                            (["d", "i", "s", "g"] as PersologClassCode[]).map((letter, answerIndex) => {
                                const answer = value[index].answers[answerIndex] as PersologAnswer
                                return (
                                    <div className="questionnaire-answers d-flex flex-column w-100"
                                         key={answerIndex + index}>
                                        <Card key={answerIndex}>
                                            <Card.Body>
                                                <div className="form-group row pl-3">
                                                    <label>{t("course-editor.edit-element-form.persolog.value", {value: answer.classCode})}</label>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="colFormLabelSm"
                                                           className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.edit-element-form.persolog.answer")}*</label>
                                                    <div className="col-sm-10">
                                                        <input
                                                            className="form-control default-input"
                                                            type="answer"
                                                            placeholder={t("course-editor.edit-element-form.persolog.answer-placeholder")}
                                                            value={answer.answer}
                                                            onChange={(event =>
                                                                    updateAnswer(
                                                                        {
                                                                            answer: event.target.value,
                                                                            classCode: letter
                                                                        },
                                                                        persologQuestion,
                                                                        index,
                                                                        answerIndex
                                                                    )
                                                            )}/>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </Card.Body>
                </Card>
            )}
        </div>
    );
};
