import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

import "./pagination.scss"
import {Pagination} from "@thekeytechnology/framework-react";
import {PaginationEllipses} from "./PagninationEllipses";

interface OwnProps {
    pagination: Pagination;
    setPage: (page: number) => void;
}


export const UnconnectedPagination = ({pagination, setPage}: OwnProps) => {

    if (!pagination || pagination.totalPages <= 1) {
        return <></>;
    }

    const leadingAndTrailingPagesLength = 5;

    const BackArrow = pagination.currentPage > 0 ?
        (<li key="back" onClick={() => setPage(pagination.currentPage - 1)}>
            <FontAwesomeIcon icon={faChevronLeft}/>
        </li>)
        : (<li key="back" className="disabled">
            <FontAwesomeIcon icon={faChevronLeft}/>
        </li>);

    const ForwardArrow = pagination.currentPage + 1 < pagination.totalPages ?
        (<li key="forward" onClick={() => setPage(pagination.currentPage + 1)}>
            <FontAwesomeIcon icon={faChevronRight}/>
        </li>)
        : (<li key="forward" className="disabled">
            <FontAwesomeIcon icon={faChevronRight}/>
        </li>);

    const centerBlockPages = [Math.max(0, pagination.currentPage - leadingAndTrailingPagesLength + 1), Math.min(pagination.totalPages - 1, pagination.currentPage + leadingAndTrailingPagesLength - 1)];
    const startBlockPages = [0, Math.min(leadingAndTrailingPagesLength - 1  , centerBlockPages[0] - 1)];
    const endBlockPages = [Math.max(pagination.totalPages - leadingAndTrailingPagesLength, centerBlockPages[1] + 1), pagination.totalPages - 1];

    // getListItemsFromRange(2,5) will create <li> elements for [2,3,4,5]
    const getListItemsFromRange = (first: number, last: number) => {
        const array = Array.from({length: Math.max(0, (last - first + 1))}, (_, i) => first + i);
        return array.map(i =>
            <li key={i}
                onClick={() => setPage(i)}
                className={i === pagination.currentPage ? "active" : ""}>
                {i + 1}
            </li>
        );
    }

    const centerBlock = getListItemsFromRange(centerBlockPages[0], centerBlockPages[1]);
    const startBlock = getListItemsFromRange(startBlockPages[0], startBlockPages[1])
    const endBlock = getListItemsFromRange(endBlockPages[0], endBlockPages[1]);
    const ellipses1Block = getListItemsFromRange(startBlockPages[1] + 1, centerBlockPages[0] - 1);
    const ellipses2Block = getListItemsFromRange(centerBlockPages[1] + 1, endBlockPages[0] - 1);

    return (
        <ul className="pagination mb-0 d-flex justify-content-center">
            {BackArrow}
            {startBlock}
            {ellipses1Block.length >= 1 ? <PaginationEllipses children={ellipses1Block} /> : null}
            {centerBlock}
            {ellipses2Block.length >= 1 ? <PaginationEllipses children={ellipses2Block} /> : null}
            {endBlock}
            {ForwardArrow}
        </ul>
    );
};
