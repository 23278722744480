/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AnonymizeUserInput = {
    userId: string;
    clientMutationId?: string | null;
};
export type UserActions_AnonymizeUserMutationVariables = {
    input: AnonymizeUserInput;
};
export type UserActions_AnonymizeUserMutationResponse = {
    readonly Admin: {
        readonly User: {
            readonly anonymizeUser: {
                readonly user: {
                    readonly " $fragmentRefs": FragmentRefs<"UserActions_UserFragment">;
                };
            } | null;
        };
    };
};
export type UserActions_AnonymizeUserMutation = {
    readonly response: UserActions_AnonymizeUserMutationResponse;
    readonly variables: UserActions_AnonymizeUserMutationVariables;
};



/*
mutation UserActions_AnonymizeUserMutation(
  $input: AnonymizeUserInput!
) {
  Admin {
    User {
      anonymizeUser(input: $input) {
        user {
          ...UserActions_UserFragment
          id
        }
      }
    }
  }
}

fragment UserActions_UserFragment on User {
  id
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserActions_AnonymizeUserMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAdminMutationSchema",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AnonymizeUserPayload",
                "kind": "LinkedField",
                "name": "anonymizeUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserActions_UserFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserActions_AnonymizeUserMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAdminMutationSchema",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AnonymizeUserPayload",
                "kind": "LinkedField",
                "name": "anonymizeUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "833adef95951ed71d89cf62f812ced6f",
    "id": null,
    "metadata": {},
    "name": "UserActions_AnonymizeUserMutation",
    "operationKind": "mutation",
    "text": "mutation UserActions_AnonymizeUserMutation(\n  $input: AnonymizeUserInput!\n) {\n  Admin {\n    User {\n      anonymizeUser(input: $input) {\n        user {\n          ...UserActions_UserFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment UserActions_UserFragment on User {\n  id\n  name\n}\n"
  }
};
})();
(node as any).hash = '0caed5ed1c92395866da377f8d4a7e5a';
export default node;
