import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DialogResult, selectCombinedApiState, showDialogAction } from "@thekeytechnology/framework-react";
import { API_PUBLISH_COURSE, publishCourseAction } from "../actions/publish-course";

interface OwnProps {
    courseId: string;
}

export const PublishCourseButton = ({courseId}: OwnProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(["courses", "core"])
    const apiCallState = useSelector(selectCombinedApiState(API_PUBLISH_COURSE))

    return <button type="button" disabled={apiCallState.inProgress || apiCallState.succeeded}
                   className="btn btn-link mr-2"
                   onClick={() =>
                       dispatch(showDialogAction(
                           {
                               title: t("finalize-course-button.dialog.title"),
                               affirmativeText: t("finalize-course-button.dialog.affirmative-text"),
                               explanation: t("finalize-course-button.dialog.explanation"),
                               negativeText: t("finalize-course-button.dialog.negative-text"),
                               question: t("finalize-course-button.dialog.question")
                           }, result => {
                               if (result === DialogResult.ACCEPT) {
                                   dispatch(publishCourseAction(courseId))
                               }
                           }
                       ))}
    >
        <FontAwesomeIcon icon={faCheck}/>
    </button>;
};
