import update from "immutability-helper";
import { EditedCourseState } from "../../edited-course";
import { MoveElementAction } from "../../../actions/element/move-lesson";
import { anyIdMatches } from "../common/helpers";

export function moveElement(state: EditedCourseState,
                            {sourceLessonId, targetLessonId, sourceIndex, targetIndex}: MoveElementAction
) {
    if (sourceLessonId === targetLessonId && sourceIndex === targetIndex) {
        return state;
    }

    const sourceLessonIndex = state.editedCourse!.entity.lessons.findIndex(l => anyIdMatches(l, sourceLessonId));
    const sourceLesson = state.editedCourse!.entity.lessons[sourceLessonIndex];

    const targetLessonIndex = state.editedCourse!.entity.lessons.findIndex(l => anyIdMatches(l, targetLessonId))

    const elementId = sourceLesson!.entity.elementsRef[sourceIndex];

    if (sourceLessonIndex === targetLessonIndex) {
        return update(state, {
            hasUnsavedChanges: {$set: true},
            editedCourse: {
                entity: {
                    lessons: {
                        [sourceLessonIndex]: {
                            entity: {
                                elementsRef: {
                                    $splice: [
                                        [sourceIndex, 1],
                                        [targetIndex, 0, elementId]
                                    ],
                                }
                            }
                        }
                    }
                }
            }
        });
    } else {
        return update(state, {
            hasUnsavedChanges: {$set: true},
            editedCourse: {
                entity: {
                    lessons: {
                        [sourceLessonIndex]: {
                            entity: {
                                elementsRef: {
                                    $splice: [
                                        [sourceIndex, 1],
                                    ],
                                }
                            }
                        },
                        [targetLessonIndex]: {
                            entity: {
                                elementsRef: {
                                    $splice: [
                                        [targetIndex, 0, elementId]
                                    ]
                                }
                            }
                        }
                    }
                }
            }
        });
    }

}
