import { faAward, faCertificate, faHeading, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { EntityWrapper, } from "@thekeytechnology/framework-react";
import { Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    COURSE_ATTRIBUTE_EARLY_BIRD,
    COURSE_ATTRIBUTE_HIDDEN, COURSE_ATTRIBUTE_NEW,
    COURSE_ATTRIBUTE_PUBLISHED,
    PreparingAggregatedCourse
} from "../../../model/preparing-aggregated-course";
import * as Yup from "yup";
import { EditModalBase } from "../common/modal/EditModalBase";
import { Expert } from "../../../../expert/model/expert";
import { SimpleOptionSelectField } from "./SimpleOptionSelectField";
import { InlineAttachmentField } from "../../../../core/components/attachment/InlineAttachmentField";
import { Offer } from "../../../../billing/old/v1/model/Offer";
import { ENTITY_TYPE_OFFER_V2, OfferV2 } from "@thekeytechnology/thekey-academy-frontend-library";
import { ENTITY_TYPE_PRODUCT, Product } from "../../../model/Product";
import { WysiwygField2 } from "./WysiwygField2";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { BooleanSelectField } from "../../../../core/components/select/BooleanSelectField";
import { WysiwygField } from "../../../../core/components/wysiwyg/WysiwygField";
import { AsyncEntitySelectField } from "../../../../core/components/entity/AsyncEntitySelectField";

interface OwnProps {
    course: EntityWrapper<PreparingAggregatedCourse>;

    onSubmit: (module: EntityWrapper<PreparingAggregatedCourse>) => void;
    onClose: () => void;
}

type Props = OwnProps & WithTranslation;

const EditCourseFormComponent = (props: Props) => {
    const {course, onSubmit, onClose, t} = props;

    return <Formik
        initialValues={{
            title: course.entity.containerMeta.title,
            shortTitle: course.entity.shortTitle,
            description: course.entity.containerMeta.description,
            certificateTitle: course.entity.certificateTitle,
            certificateDescription: course.entity.certificateDescription,
            experts: course.entity.expertsRef ? course.entity.expertsRef.map(exId => ({id: exId})) : [],
            isIHK: course?.entity.isIHK,
            image: course?.entity.image,
            icon: course?.entity.icon,
            mainOffer: course?.entity.mainOffer ? course.entity.mainOffer : undefined,
            mainOfferV2: course?.entity.mainOfferV2 ? course.entity.mainOfferV2 : undefined,
            mainProduct: course?.entity.mainProduct ? course.entity.mainProduct : undefined,
            isFreeCourse: course?.entity.isFreeCourse,
            attributes: course?.entity.attributes ? course.entity.attributes : []
        }}
        validationSchema={Yup.object().shape({
            title: Yup.string().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-course-form.fields.title")})),
            shortTitle: Yup.string().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-course-form.fields.short-title")})),
            certificateTitle: Yup.string().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-course-form.fields.certificate-title")})),
            description: Yup.string().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-course-form.fields.description")})),
        })}
        onSubmit={(values, {setSubmitting}) => {
            onSubmit(new EntityWrapper<PreparingAggregatedCourse>(course.id,
                {
                    containerMeta: {
                        title: values.title,
                        description: values.description,
                        length: course.entity.containerMeta.length,
                        points: course ? course.entity.containerMeta.points : 0
                    },
                    shortTitle: values.shortTitle,
                    language: "de",
                    image: values.image,
                    icon: values.icon,
                    certificateTitle: values.certificateTitle,
                    certificateDescription: values.certificateDescription,
                    isIHK: values.isIHK,
                    childFiles: course.entity.childFiles,
                    expertsRef: (values.experts as any as EntityWrapper<Expert>[]).map(ae => ae.id!),
                    modules: course.entity.modules ? course.entity.modules : [],
                    lessons: course.entity.lessons ? course.entity.lessons : [],
                    elements: course.entity.elements ? course.entity.elements : [],
                    attributes: values.attributes ? values.attributes : [],
                    courseFile: course.entity.courseFile,
                    isFreeCourse: values.isFreeCourse,
                    mainOffer: values.mainOffer as any as EntityWrapper<Offer>,
                    mainOfferV2: values.mainOfferV2 as any as EntityWrapper<OfferV2>,
                    mainProduct: values.mainProduct as any as EntityWrapper<Product>,
                    versions: course.entity.versions
                },
            ));
            setSubmitting(false);
        }}
    >
        {formikState => {
            return (
                <EditModalBase handleClose={onClose}
                               formikState={formikState}
                               title={t("course-editor.edit-course-form.heading")}>
                    <div className="form-group row">
                        <ValidatedField
                            icon={faHeading}
                            label={t("course-editor.edit-course-form.fields.title")}
                            name="title"
                            formikState={formikState}
                            type="text"
                            placeholder={t("course-editor.edit-course-form.fields.title-placeholder")}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            icon={faHeading}
                            label={t("course-editor.edit-course-form.fields.short-title")}
                            name="shortTitle"
                            formikState={formikState}
                            type="text"
                            placeholder={t("course-editor.edit-course-form.fields.short-title-placeholder")}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            icon={faAward}
                            label={t("course-editor.edit-course-form.fields.certificate-title")}
                            name="certificateTitle"
                            formikState={formikState}
                            type="text"
                            placeholder={t("course-editor.edit-course-form.fields.certificate-title-placeholder")}
                            required
                        />
                    </div>

                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-course-form.fields.certificate-description")}
                            name="certificateDescription"
                            component={WysiwygField}
                            formikState={formikState}
                            placeholder={t("course-editor.edit-course-form.fields.certificate-description-placeholder")}
                            required
                        />
                    </div>

                    <div className="form-group row">
                        <ValidatedField
                            icon={faCertificate}
                            label={t("course-editor.edit-course-form.fields.is-ihk.label")}
                            name="isIHK"
                            formikState={formikState}
                            component={BooleanSelectField}
                            trueLabel={t("course-editor.edit-course-form.fields.is-ihk.true")}
                            falseLabel={t("course-editor.edit-course-form.fields.is-ihk.false")}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            icon={faTrophy}
                            label={t("course-editor.edit-course-form.fields.is-free-course.label")}
                            name="isFreeCourse"
                            formikState={formikState}
                            component={BooleanSelectField}
                            trueLabel={t("course-editor.edit-course-form.fields.is-free-course.true")}
                            falseLabel={t("course-editor.edit-course-form.fields.is-free-course.false")}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-course-form.fields.image")}
                            name="image"
                            formikState={formikState}
                            component={InlineAttachmentField}
                            isSingleSelect={true}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-course-form.fields.icon")}
                            name="icon"
                            formikState={formikState}
                            component={InlineAttachmentField}
                            isSingleSelect={true}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-course-form.fields.description")}
                            name="description"
                            component={WysiwygField2}
                            formikState={formikState}
                            required
                        />
                    </div>

                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-course-form.fields.attributes.label")}
                            name="attributes"
                            component={SimpleOptionSelectField}
                            options={[COURSE_ATTRIBUTE_PUBLISHED, COURSE_ATTRIBUTE_HIDDEN, COURSE_ATTRIBUTE_NEW, COURSE_ATTRIBUTE_EARLY_BIRD]}
                            isMulti={true}
                            listRenderer={(value: any) => t("course-editor.edit-course-form.fields.attributes." + value)}
                            formikState={formikState}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-course-form.fields.experts")}
                            name="experts"
                            component={AsyncEntitySelectField}
                            shownEntityType={Expert.TYPE}
                            shownEntityTypeProperties={["entity.name"]}
                            listRenderer={(item: EntityWrapper<Expert>) => item.entity ? item.entity.name : ""}
                            placeholder={t("course-editor.edit-course-form.fields.experts-placeholder")}
                            isMulti={true}
                            isClearable={true}
                            formikState={formikState}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-course-form.fields.main-offer-v2")}
                            name="mainOfferV2"
                            component={AsyncEntitySelectField}
                            shownEntityType={ENTITY_TYPE_OFFER_V2}
                            shownEntityTypeProperties={["entity.title"]}
                            listRenderer={(item: EntityWrapper<OfferV2>) => item.entity ? `${item.entity.internalTitle} (${item.entity.title})` : ""}
                            placeholder={t("course-editor.edit-course-form.fields.main-offer-placeholder")}
                            isClearable={true}
                            formikState={formikState}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-course-form.fields.main-product")}
                            name="mainProduct"
                            component={AsyncEntitySelectField}
                            shownEntityType={ENTITY_TYPE_PRODUCT}
                            shownEntityTypeProperties={["entity.title"]}
                            listRenderer={(item: EntityWrapper<Product>) => item.entity ? `${item.entity.title}` : ""}
                            placeholder={t("course-editor.edit-course-form.fields.main-product-placeholder")}
                            isClearable={true}
                            formikState={formikState}
                        />
                    </div>
                </EditModalBase>
            );
        }}
    </Formik>;
};

export const EditCourseForm = withTranslation(["courses", "core"])(EditCourseFormComponent);
