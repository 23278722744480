import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { editElementAction } from "../../../actions/element/edit-lesson";
import { ElementEditorModal } from "./ElementEditor";
import { CourseElement } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    element: EntityWrapper<CourseElement>;
    parentModuleId: string;
    parentLessonId: string;
}

interface DispatchProps {
    editElement: typeof editElementAction
}

type Props = OwnProps & DispatchProps & WithTranslation;

const EditElementButtonComponent = (props: Props) => {
    const {editElement, element, parentModuleId, parentLessonId} = props;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button className="ml-2 btn-secondary" onClick={handleShow}>
                <FontAwesomeIcon icon={faPencilAlt}/>
            </Button>

            {show ? <ElementEditorModal handleSubmit={(savedElement: EntityWrapper<CourseElement>) => {
                editElement(
                    parentModuleId,
                    parentLessonId,
                    savedElement
                );
                handleClose();
            }} element={element} elementType={element.entity.elementType} show={show}
                                        handleClose={handleClose}/> : null}
        </>
    );
};

export const EditElementButton = connect<{}, DispatchProps, {}>(
    null,
    {
        editElement: editElementAction
    }
)(withTranslation("courses")(EditElementButtonComponent));
