import React from 'react'
import {useRefetchableFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {MultiSelect} from "primereact/multiselect";
import {useTranslation} from "react-i18next";
import {CoursesSelect_QueryFragment$key} from "../../../../__generated__/CoursesSelect_QueryFragment.graphql";
import {
    CoursesSelect_QueryFragmentRefetchQuery
} from "../../../../__generated__/CoursesSelect_QueryFragmentRefetchQuery.graphql";

const NUM_COURSES_PER_PAGE = 20

const QUERY_FRAGMENT = graphql`
    fragment CoursesSelect_QueryFragment on Query @refetchable(queryName: "CoursesSelect_QueryFragmentRefetchQuery") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String", defaultValue: null},
        title: {type: "String", defaultValue: null},
        ids: {type :"[ID!]!"}
    ) {
        Admin {
            Course {
                CoursesMultiselect(first: $first, after: $after, titleOpt: $title, ids: $ids) @connection(key: "CourseSelect_CoursesMultiselect") {
                    edges {
                        cursor
                        node {
                            id
                            ... on Course {
                                containerMeta {
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    }`

export interface Course {
    id: string
    name: string
}

interface OwnProps {
    fieldValue: Course[]
    onChange: (Courses: Course[]) => void
    queryFragmentRef: CoursesSelect_QueryFragment$key
}

export const CoursesSelect = ({
                                  fieldValue,
                                  onChange,
                                  queryFragmentRef
                              }: OwnProps) => {

    const {t} = useTranslation("core")

    const [data, refetch] = useRefetchableFragment<CoursesSelect_QueryFragmentRefetchQuery, CoursesSelect_QueryFragment$key>(QUERY_FRAGMENT, queryFragmentRef)

    const courses = data.Admin.Course.CoursesMultiselect.edges?.filter(e => !!e).map(e => ({
        id: e!.node.id,
        name: e!.node.containerMeta.title
    } as Course)) || []

    const onFilter = (e: { filter: string }) => {
        refetch({title: e.filter, first: NUM_COURSES_PER_PAGE, ids: fieldValue.map(a => a.id)})
    }

    return <MultiSelect value={fieldValue} options={courses}
                     optionLabel="name"
                     onChange={(e) => onChange(e.value)}
                     placeholder={t("entity-selects.placeholder")} filter
                     resetFilterOnHide={true}
                     filterPlaceholder={t("entity-selects.filter-placeholder")}
                     className="multiselect-custom"
                     onFilter={onFilter} showSelectAll={false}
                     emptyFilterMessage={t("entity-selects.no-results")}
        />
}
