import graphql from "babel-plugin-relay/macro"
import {Button} from "primereact/button";
import {useMutation} from "react-relay";
import React from "react";
import {useTranslation} from "react-i18next";
import { DeleteCodeButton_DeleteMutation } from "../../../../../../__generated__/DeleteCodeButton_DeleteMutation.graphql";

const DELETE_DISCOUNT_CODE_MUTATION = graphql`
    mutation DeleteCodeButton_DeleteMutation($input: DeleteDiscountCodeV2Input!, $connections: [ID!]!){
        Admin {
            Billing {
                deleteDiscountCodeV2(input: $input){
                    deletedId @deleteEdge(connections: $connections)
                }
            }
        }
    }
`

interface OwnProps {
    id: string
    className?: string
    connectionId: string
}

export const DeleteCodeButton = ({connectionId, className, id}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const [deleteCode, isLoading] = useMutation<DeleteCodeButton_DeleteMutation>(DELETE_DISCOUNT_CODE_MUTATION)

    return <Button
        icon="pi pi-trash"
        tooltip={t("billing.discount-code.button.delete-action.tooltip")}
        loading={isLoading}
        disabled={isLoading}
        className={className}
        onClick={(e) => {
            deleteCode({
                variables: {
                    input: {
                        id: id
                    },
                    connections: [connectionId]
                }
            })
        }}/>
}
