import update from "immutability-helper";
import { EditedCourseState } from "../../edited-course";
import { AddModuleAction } from "../../../actions/module/add-module";

export const addModule = (state: EditedCourseState, addModuleAction: AddModuleAction) => update(state,
    {
        hasUnsavedChanges: {$set: true},
        editedCourse: {entity: {modules: {$push: [addModuleAction.module]}}}
    }
);
