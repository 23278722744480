import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { moveElementAction } from "../../../actions/element/move-lesson";
import { extractId } from "../../../reducers/changes/common/helpers";
import { AddElement } from "./AddElement";
import { ElementItem } from "./ElementItem";
import { selectElementsByIds } from "../../../selectors";
import { CourseElement } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    elementIds: string[];
    moduleIndex: number;
    lessonIndex: number;

    parentModuleId: string;
    parentLessonId: string;
}

interface DispatchProps {
    moveElement: typeof moveElementAction
}

interface StateProps {
    elements: EntityWrapper<CourseElement>[];
}

type Props = OwnProps & DispatchProps & StateProps;

export const ElementListComponent = ({
                                         moduleIndex,
                                         lessonIndex,
                                         elements,
                                         moveElement,
                                         parentModuleId,
                                         parentLessonId
                                     }: Props) => {

    return <ListGroup>
        {elements.map((element: EntityWrapper<CourseElement>, index: number) =>
            <ElementItem key={extractId(element)}
                         moduleIndex={moduleIndex}
                         parentModuleId={parentModuleId}
                         lessonIndex={lessonIndex}
                         parentLessonId={parentLessonId}
                         elementIndex={index}
                         index={index}
                         dragMeta={{
                             sourceModuleId: parentModuleId,
                             sourceLessonId: parentLessonId
                         }}
                         dropped={(dragIndex, hoverIndex, dropMeta) => {
                             moveElement(
                                 dropMeta.sourceModuleId,
                                 dropMeta.sourceLessonId,
                                 parentModuleId,
                                 parentLessonId,
                                 dragIndex,
                                 hoverIndex
                             );
                         }}
                         element={element}/>
        )}
        <AddElement parentModuleId={parentModuleId}
                    parentLessonId={parentLessonId}
                    dropped={(dragIndex, hoverIndex, dropMeta) => {
                        moveElement(dropMeta.sourceModuleId,
                            dropMeta.sourceLessonId,
                            parentModuleId,
                            parentLessonId,
                            dragIndex,
                            hoverIndex);
                    }}/>
    </ListGroup>;
};

export const ElementList = connect<StateProps, DispatchProps, OwnProps>(
    (state: any, {elementIds}: OwnProps) => ({
        elements: selectElementsByIds(elementIds)(state)
    }),
    {
        moveElement: moveElementAction,
    }
)(ElementListComponent);
