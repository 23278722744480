import {CreateDiscountActionButton} from "../../buttons/CreateDiscountActionButton";
import {DiscountActionFilter} from "../filter/DiscountActionFilter";

interface OwnProps {
    connectionId: string
}

export const DiscountCodeActionsTableHeader = ({connectionId}: OwnProps) => {
    return <div className="w-full flex flex-row justify-content-between">
        <DiscountActionFilter />
        <CreateDiscountActionButton className="align-self-end" connectionId={connectionId} />
    </div>
}
