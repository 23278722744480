import { EntityWrapper, TkFile } from "@thekeytechnology/framework-react";

export const ENTITY_TYPE_RANK = "aggregated-rank"

export interface Rank {
    title: string,
    image?: EntityWrapper<TkFile>,
    levelThreshold: number
    explanation?: string;
    text?: string;
}

export const ENTITY_TYPE_RANK_FOR_SAVING = "rank"

export interface RankForSaving {
    title: string,
    image?: string,
    levelThreshold: number,
    explanation?: string;
    text?: string
}

export const createNewRank = () => new EntityWrapper<Rank>(
    undefined,
    {
        title: "",
        image: undefined,
        levelThreshold: 0
    }
);
