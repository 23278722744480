import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_SEND_TEST_EMAIL = "send-test-email";

export interface TestEmailPayload {
    triggeredEmailId: string;
    recipient: string;
    variables: { [key: string]: string }
}

export const sendTestEmailAction = (triggeredEmailId: string, recipient: string, variables: { [key: string]: string }) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SEND_TEST_EMAIL,
            callType: API_SEND_TEST_EMAIL
        },
        payload: {
            triggeredEmailId,
            recipient,
            variables
        }
    } as ApiCallAction<TestEmailPayload>;
};
