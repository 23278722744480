import {
    EntityWrapper,
    TkFile,
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import {useTranslation} from "react-i18next";
import "./browse-files-modal.scss"
import {FilesTableWithExternalEntitiesAndSelections} from "./FilesTable";
import {FileFilters} from "./FileFilters";
import {Uploader} from "./Uploader";
import { Pagination } from "../pagination/Pagination";
import { ModalHeader } from "../modal/ModalHeader";

interface OwnProps {
    onSelection: (selections: EntityWrapper<TkFile>[] | undefined) => void;
    isSingleSelect: boolean;
    onClose: () => void;
}

type Props = WithSelectionsProps<string> & WithEntityListProps<TkFile> & OwnProps;

const BrowseFilesModalComponent = (props: Props) => {
    const {entities, entityApiState, selectionModel, onSelection, onClose} = props;

    const {t} = useTranslation("files")
    return (
        <div className="modal-content browse-files-modal">
            <div className="modal-header d-flex align-items-center p-2">
                <ModalHeader
                    title={selectionModel.isSingleSelect ? t("browse-file-modal.header.singular") : t("browse-file-modal.header.plural")}
                    onClose={onClose}/>
            </div>
            <div>
                <div className="p-2 bg-white">
                    <FileFilters/>
                </div>
                <div className="p-2 bg-white">
                    <Uploader onUploaded={id => {
                        selectionModel.handleSelect(id)
                    }}/>
                    <FilesTableWithExternalEntitiesAndSelections
                        entities={entities}
                        entityApiState={entityApiState}
                        selectionModel={selectionModel}
                        onSelection={values => {
                            onSelection(
                                entities.filter(file => values.indexOf(file.id as string) > -1)
                            )
                        }}
                    />
                    <Pagination entityType={TkFile.TYPE}/>
                </div>
                <div className="d-flex justify-content-end pl-4 pr-4 pb-4">
                    <button type="button" className="btn btn-primary" onClick={
                        () => onSelection(
                            entities.filter(file => selectionModel.selections.indexOf(file.id as string) > -1)
                        )
                    }>
                        {t("browse-file-modal.submit")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export const FileSelector = WithSelections<any, string>(
    WithEntityList<OwnProps & WithSelectionsProps<string>, TkFile>(
        BrowseFilesModalComponent,
        TkFile.TYPE
    )
);
