import { EntityWrapper, } from "@thekeytechnology/framework-react";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { extractId } from "../../../../reducers/changes/common/helpers";
import { EditElementModalBase } from "../common/EditElementModalBase";
import {
    ELEMENT_TYPE_PERSOLOG,
    PersologAnswer, PersologClass,
    PersologElement, PersologQuestion
} from "@thekeytechnology/thekey-academy-frontend-library";
import { PersologQuestionField } from "./PersologQuestionField"
import { PersologClassesField } from "./PersologClassesField";
import { PersologClassCode } from "@thekeytechnology/thekey-academy-frontend-library/dist/core/model/element/persolog/persolog-class";
import { ValidatedField } from "../../../../../core/components/form/ValidatedField";

interface OwnProps {
    element: EntityWrapper<PersologElement>;
    handleSubmit: (element: EntityWrapper<PersologElement>) => void;
    handleClose: () => void;
}

type Props = OwnProps;

const emptyQuestions: PersologQuestion = {
    question: "",
    answers: (["d","i","s","g"] as PersologClassCode[]).map(letter =>
        ({
            answer: "",
            classCode: letter
        } as PersologAnswer)
    )
}

const emptyClasses = [
    {classCode: "d", title: "D-Klasse", explanation: ""} as PersologClass,
    {classCode: "i", title: "I-Klasse", explanation: ""} as PersologClass,
    {classCode: "s", title: "S-Klasse", explanation: ""} as PersologClass,
    {classCode: "g", title: "G-Klasse", explanation: ""} as PersologClass,
]

export const EditPersologElementForm = ({element, handleSubmit, handleClose}: Props) => {
    const {t} = useTranslation(["courses", "core"])

    return (
        <Formik
            initialValues={{
                internalTitle: element.entity.generalSettings.internalTitle,
                points: element.entity.generalSettings.points,
                lengthInSeconds: element.entity.generalSettings.lengthInSeconds,
                connections: element.entity.generalSettings.connections,
                title: element.entity.title,
                classes: element.entity.classes.length === 0 ? emptyClasses : element.entity.classes,
                questions: element.entity.questions.length === 0 ? new Array(10).fill(emptyQuestions) : element.entity.questions
            }}
            validationSchema={Yup.object().shape({
                title: Yup.string().required()
            })}
            onSubmit={(values, {setSubmitting}) => {
                handleSubmit(new EntityWrapper<PersologElement>(
                    element.id,
                    {
                        generalSettings: {
                            internalTitle: values.internalTitle,
                            points: values.points,
                            lengthInSeconds: values.lengthInSeconds,
                            connections: values.connections
                        },
                        elementType: ELEMENT_TYPE_PERSOLOG,
                        elementPath: element.entity.elementPath,
                        classes: values.classes,
                        title: values.title,
                        questions: values.questions,
                        versions: element.entity.versions
                    },
                    element.temporaryId,
                ));
                setSubmitting(false);
            }}
            validateOnChange={true}
        >
            {formikState => (
                <EditElementModalBase title={t("course-editor.edit-element-form.persolog.heading")}
                                      currentElementId={extractId(element)}
                                      formikState={formikState}
                                      handleClose={handleClose}>
                    <div className="form-group row">
                        <ValidatedField
                            formikState={formikState}
                            placeholder={t("course-editor.edit-element-form.persolog.question")}
                            type="text"
                            name="title"
                            className="form-control default-input"
                            label={t("course-editor.edit-element-form.persolog.title")}
                            required/>
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.questionnaire.fields.classes")}
                            name="classes"
                            formikState={formikState}
                            component={PersologClassesField}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.questionnaire.fields.questions")}
                            name="questions"
                            formikState={formikState}
                            component={PersologQuestionField}
                        />
                    </div>
                </EditElementModalBase>
            )}
        </Formik>
    );
};
