import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import { useTranslation } from "react-i18next";
import "./feedback-table.scss";
import { ENTITY_TYPE_FEEDBACK, Feedback } from "@thekeytechnology/thekey-academy-frontend-library";
import { AboveTableContainer } from "../../core/components/containers/Container";
import { PaginationInfo } from "../../core/components/pagination/PaginationInfo";
import { EntityDeletionButton } from "../../core/components/button/EntityDeletionButton";
import { SelectionTableColumn, Table, TableColumn, TableRow } from "../../core/components/table/Table";
import { EntityTableHeader } from "../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../core/components/table/AsyncEntityTableBody";
import { DateTimeDisplay } from "../../core/components/datetime/DateTimeDisplay";

type Props =
    WithEntityListProps<Feedback>
    & WithSelectionsProps<string>;

const FeedbackTableContent = ({
                                  entities,
                                  selectionModel,
                                  entityApiState,
                              }: Props) => {

    const {t} = useTranslation(["feedback", "courses"]);

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_FEEDBACK} className="mr-auto"/>

                <EntityDeletionButton entityType={ENTITY_TYPE_FEEDBACK}
                                      selectedEntities={selectionModel.selections}
                                      label={t("entity.feedback.singular")}
                                      labelPlural={t("entity.feedback.plural")}
                />
            </AboveTableContainer>

            <Table itemCount={entities.length} className="feedback-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.feedback.labels.date")}</TableColumn>
                    <TableColumn>{t("entity.feedback.labels.course")}</TableColumn>
                    <TableColumn>{t("entity.feedback.labels.lesson")}</TableColumn>
                    <TableColumn>{t("entity.feedback.labels.element")}</TableColumn>
                    <TableColumn>{t("entity.feedback.labels.source")}</TableColumn>
                    <TableColumn>{t("entity.feedback.labels.location")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={feedback =>
                        <TableRow key={feedback.id}>
                            <div className="row-title">
                                <SelectionTableColumn<string> item={feedback.id as string}
                                                              selectionModel={selectionModel}/>
                                <TableColumn>
                                    <DateTimeDisplay dateTime={feedback.entity.createdAt}/>
                                </TableColumn>
                                <TableColumn>
                                    {feedback.entity.course ?
                                        <>{t("courses:entity.course.singular")} {feedback.entity.course?.entity.containerMeta.title}</>
                                        : t("feedback-table.no-course")}
                                </TableColumn>
                                <TableColumn>
                                    {feedback.entity.lesson ?
                                        <>{t("courses:entity.lesson.singular")} {feedback.entity.lesson.entity.lessonPath.moduleIndex + 1}.{feedback.entity.lesson.entity.lessonPath.lessonIndex + 1}: {feedback.entity.lesson.entity.containerMeta.title}</>
                                        : t("feedback-table.no-lesson")}
                                </TableColumn>
                                <TableColumn>
                                    {feedback.entity.element ?
                                        <>{feedback.entity.element.entity.generalSettings.internalTitle}</>
                                        : t("feedback-table.no-element")}
                                </TableColumn>
                                <TableColumn className="bold title-column">
                                    {feedback.entity.source}
                                </TableColumn>
                                <TableColumn>
                                    {feedback.entity.location}
                                </TableColumn>
                            </div>
                            <div className="row-content">
                                <div/>
                                <div>
                                    Feedback: {feedback.entity.feedback}
                                </div>
                            </div>
                        </TableRow>
                    }/>
            </Table>
        </>
    );
};

export const FeedbackTable = WithSelections<any, string>(
    WithEntityList(
        FeedbackTableContent,
        ENTITY_TYPE_FEEDBACK
    )
);
