import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

interface OwnProps {
    // 'Indexer'
    [x: string]: any
}

type Props = OwnProps & WithTranslation;

const BaseAccountScreenComponent = (props: Props) => {
    return <div className="h-100 base-account-screen-wrapper bg-white d-flex justify-content-center align-items-center">
        <div
            className="base-account-screen col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 pl-5 pr-5 pt-4 pb-4 d-flex flex-column">
            {props.children}

            <div className="copyright text-center mt-5">
                ©{new Date().getFullYear()} {props.t("copyright")}
            </div>
        </div>
    </div>;
};

export const BaseAccountScreen = withTranslation("auth")(BaseAccountScreenComponent);
