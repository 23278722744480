import React, {useEffect, useState} from "react";
import {Account, EntityWrapper, selectCombinedApiState, UserWithRoles} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectUsersInAccount} from "../../../selectors";
import {NavLink} from "react-router-dom";
import {API_FETCH_USERS_IN_ACCOUNT_ADMIN, fetchUsersInAccountAction} from "../../../actions/fetch-users-in-account";
import {UnlockModalButton} from "../../unlock/UnlockModalButton";
import Select from "react-select";
import {UserInAccount} from "../../../model/UserInAccount";
import {updateUserRolesAction} from "../../../actions/update-user-roles";
import {LoadingRow} from "../../../../core/components/table/LoadingRow";
import {Table, TableBody, TableColumn, TableHeader, TableRow} from "../../../../core/components/table/Table";

export interface OwnProps {
    account: EntityWrapper<Account>
}

interface State {
    updatedUserRoles: UserInAccount[]
}

export const EditUserInAccount = ({account}: OwnProps) => {
    const {t} = useTranslation(["admin", "core"])
    const dispatch = useDispatch();

    const apiCallState = useSelector(selectCombinedApiState(API_FETCH_USERS_IN_ACCOUNT_ADMIN))
    const usersInAccount = useSelector(selectUsersInAccount);

    const [state, setState] = useState<State>({updatedUserRoles: []})

    const options = [
        {value: UserWithRoles.ROLE_USER, label: "Benutzer"},
        {value: UserWithRoles.ROLE_ADMIN, label: "Admin"}
    ]

    useEffect(() => {
        dispatch(fetchUsersInAccountAction(account.id!))
    }, [dispatch, account])

    useEffect(() => {
        setState({updatedUserRoles: usersInAccount ?? []})
    }, [usersInAccount])

    return apiCallState.inProgress || !usersInAccount ? <LoadingRow/> :
        <>
            <UnlockModalButton accountId={account.id} buttonText={t("edit-user-in-account.add-users-to-account")}/>
            <Table itemCount={usersInAccount.length} className="user-orders-table">
                <TableHeader>
                    <TableColumn>{t("entity.account.labels.name")}</TableColumn>
                    <TableColumn>{t("entity.account.labels.roles")}</TableColumn>
                </TableHeader>
                <TableBody>
                    {usersInAccount.map((r, index) => {
                        return <TableRow key={index}>
                            <TableColumn>
                                <NavLink to={`/users/${btoa("User:" + r.userId)}/edit`}>
                                    {r.userName}
                                </NavLink>
                            </TableColumn>
                            <TableColumn className="w-100">
                                <Select
                                    className="react-select category-select mb-2"
                                    classNamePrefix="react-select"
                                    options={options}
                                    defaultValue={r.roles.map(l => options.find(k => k.value === l))}
                                    onChange={(values) => {
                                        const updatedUser: UserInAccount = {
                                            userName: r.userName,
                                            userId: r.userId,
                                            roles: values ? [values.value] : []
                                        }

                                        setState({
                                            updatedUserRoles: state.updatedUserRoles.map(oldUserRole => {
                                                    return oldUserRole.userId === updatedUser.userId ?
                                                        updatedUser :
                                                        oldUserRole
                                                }
                                            )
                                        })
                                    }}
                                />
                            </TableColumn>
                        </TableRow>
                    })}
                </TableBody>
                <button className="btn btn-success" onClick={() => {
                    dispatch(updateUserRolesAction({
                        account: account.id ?? "",
                        usersInAccount: state.updatedUserRoles
                    }))

                }}>
                    Speichern
                </button>
            </Table>
        </>
}

