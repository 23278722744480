import { UserWithRoles } from "@thekeytechnology/framework-react";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";
import {SettingsScreen} from "./components/SettingsScreen";

export const SettingsModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/settings",
        component: SettingsScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
];
