import { UserWithRoles } from "@thekeytechnology/framework-react";
import { FAQOverview } from "./components/FAQOverview";
import { EditFAQForm } from "./components/EditFAQ";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";

export const FAQModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/faqs",
        component: FAQOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    },
    {
        path: "/faqs/:faqId/edit",
        component: EditFAQForm,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    }
];
