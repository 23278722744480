import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntityWrapper, generateString } from "@thekeytechnology/framework-react";
import React from "react";
import { Button } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addElementAction } from "../../../actions/element/add-element";
import update from "immutability-helper";
import { CourseElement } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    element: EntityWrapper<CourseElement>;
    parentModuleId: string;
    parentLessonId: string;
}

interface DispatchProps {
    addElement: typeof addElementAction
}

type Props = OwnProps & DispatchProps & WithTranslation;

const CopyElementButtonComponent = (props: Props) => {
    const {addElement, element, parentModuleId, parentLessonId} = props;

    return (
        <>
            <Button className="mr-2 btn-secondary" onClick={() => {
                const copiedElement = update(element, {
                    id: {$set: undefined},
                    entity: {generalSettings: {internalTitle: {$set: element.entity.generalSettings.internalTitle + " (Kopie)"}}},
                    temporaryId: {$set: generateString(10)}
                });
                addElement(parentModuleId, parentLessonId, copiedElement);
            }}>
                <FontAwesomeIcon icon={faCopy}/>
            </Button>
        </>
    );
};

export const CopyElementButton = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        addElement: addElementAction
    }
)(withTranslation("courses")(CopyElementButtonComponent));
