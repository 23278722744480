import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { WithEntityList, WithSelections } from "@thekeytechnology/framework-react";
import { WithEntityListProps } from "@thekeytechnology/framework-react";
import React from "react";
import "./triggered-achievement-table.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { WithSelectionsProps } from "@thekeytechnology/framework-react";
import { ENTITY_TYPE_TRIGGERED_ACHIEVEMENT, TriggeredAchievement } from "../model/triggered-achievement";
import { AboveTableContainer } from "../../core/components/containers/Container";
import { PaginationInfo } from "../../core/components/pagination/PaginationInfo";
import { EntityCreateAndDeleteButtons } from "../../core/components/button/EntityCreateAndDeleteButtons";
import { Table, TableColumn } from "../../core/components/table/Table";
import { EntityTableHeader } from "../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../core/components/table/AsyncEntityTableBody";
import { EntityTableRow } from "../../core/components/table/EntityTableRow";
import { ActionLink } from "../../core/components/icon-link-tooltip/ActionLink";

type Props = WithEntityListProps<TriggeredAchievement> & WithTranslation & WithSelectionsProps<string>;

const TriggeredAchievementsTableComponent = ({
                                                 entities,
                                                 entityApiState,
                                                 t,
                                                 selectionModel
                                             }: Props) => {

    return (<>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_TRIGGERED_ACHIEVEMENT} className="mr-auto"/>

                <EntityCreateAndDeleteButtons
                    selectedEntities={selectionModel.selections}
                    entityType={ENTITY_TYPE_TRIGGERED_ACHIEVEMENT}
                    createPath="/achievements/new/edit"
                    label={t("entity.triggered-achievement.singular")}
                    labelPlural={t("entity.triggered-achievement.plural")}
                />
            </AboveTableContainer>
            <Table className="triggered-achievement-table" itemCount={entities.length}>
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <div className="table-column">{t("entity.triggered-achievement.labels.internal-title")}</div>
                    <div className="table-column">{t("entity.triggered-achievement.labels.trigger")}</div>
                    <div className="table-column">{t("triggered-achievement-table.actions")}</div>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={achievement =>
                        <EntityTableRow
                            key={achievement.id}
                            entity={achievement} selectionModel={selectionModel}>
                            <TableColumn className="bold">
                                {achievement.entity.internalTitle}
                            </TableColumn>
                            <TableColumn>
                                {t("triggers:trigger-field.trigger-types." + achievement.entity.trigger?.key)}
                            </TableColumn>
                            <TableColumn>
                                <ActionLink
                                    to={"/achievements/" + achievement.id + "/edit"}
                                    tooltip={t("Edit achievement")}
                                    icon={faPencilAlt}/>
                            </TableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const TriggeredAchievementsTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation(["achievements", "triggers"])(TriggeredAchievementsTableComponent),
        ENTITY_TYPE_TRIGGERED_ACHIEVEMENT
    )
);
