import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import graphql from 'babel-plugin-relay/macro'
import {useFragment, useMutation} from "react-relay";
import {Button} from "primereact/button";
import {ValidatedFieldV2} from "../../../../core/components/form/ValidatedFieldV2";
import {DefaultSwitchFieldComponent} from "../../../../core/components/form/forms/DefaultSwitchComponent";
import {DefaultTextFieldComponent} from "../../../../core/components/form/forms/DefaultTextComponent";
import {EditUserData_EditUserDataMutation} from "../../../../../__generated__/EditUserData_EditUserDataMutation.graphql";
import {EditUserData_UserFragment$key} from "../../../../../__generated__/EditUserData_UserFragment.graphql";
import {useTranslation} from "react-i18next";

interface FormikProps {
    name: string
    email: string
    activated: boolean
}

export interface OwnProps {
    userFragmentRef: EditUserData_UserFragment$key
}

const USER_FRAGMENT = graphql`
    fragment EditUserData_UserFragment on User {
        id
        name
        email
        activated
    }
`

const EDIT_USER_DATA_MUTATION = graphql`
    mutation EditUserData_EditUserDataMutation($input: EditUserDataInput!) {
        Admin {
            User {
                editUserData(input: $input) {
                    user{
                        ...EditUserData_UserFragment
                    }
                }
            }
        }
    }
`

export const EditUserData = ({userFragmentRef}: OwnProps) => {
    const {t} = useTranslation("admin")

    const user = useFragment<EditUserData_UserFragment$key>(USER_FRAGMENT, userFragmentRef)
    const [updateUserData, isInFlight] = useMutation<EditUserData_EditUserDataMutation>(EDIT_USER_DATA_MUTATION)

    const formik = useFormik<FormikProps>({
        initialValues: {
            name: user.name,
            email: user.email,
            activated: user.activated
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().min(3),
            email: Yup.string().email()
        }),
        onSubmit: (values, {setSubmitting}) => {
            updateUserData({
                variables: {
                    input: {
                        userId: user.id,
                        name: values.name,
                        email: values.email,
                        activated: values.activated
                    }
                }
            })
            setSubmitting(false);
        },
    })

    return <form onSubmit={formik.handleSubmit} className="p-fluid">

        <div className="form-group row">
            <ValidatedFieldV2<FormikProps, string>
                name={"name"}
                label={t("entity.user.labels.name")}
                component={DefaultTextFieldComponent}
                formikConfig={formik}
            />
        </div>
        <div className="form-group row">
            <ValidatedFieldV2<FormikProps, string>
                name={"email"}
                label={t("entity.user.labels.email")}
                component={DefaultTextFieldComponent}
                formikConfig={formik}
            />
        </div>
        <div className="form-group row">
            <ValidatedFieldV2<FormikProps, boolean>
                name={"activated"}
                label={t("entity.user.labels.is-activated")}
                component={DefaultSwitchFieldComponent}
                formikConfig={formik}
            />
        </div>
        <Button disabled={isInFlight}
                loading={isInFlight}
                className="w-1"
                label={t("actions.save")}
                type={"button"}
                onClick={() => formik.handleSubmit()}/>
    </form>
}
