import {useSelector} from "react-redux";
import {selectApiBase, selectAuthState} from "@thekeytechnology/framework-react";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

interface OwnProps {
    className?: string;
    fileId: string;
}

export const FileDownloadLink = ({
                                     className,
                                     fileId
                                 }: OwnProps) => {
    const apiBase = useSelector(selectApiBase)
    const token = useSelector(selectAuthState)?.token
    const accountId = useSelector(selectAuthState)?.currentAccount?.accountId

    return <a className={"btn btn-secondary " + className}
              href={`${apiBase}/files/download?authToken=${token}&accountId=${accountId}&fileId=${fileId}`}
              target="_blank"
              rel="noopener noreferrer">
        <FontAwesomeIcon
            icon={faDownload}/>
    </a>
}
