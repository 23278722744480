import React, { useRef } from "react";
import { DragElementWrapper, DragSourceOptions, useDrag, useDrop } from "react-dnd";

export interface DragItem {
    index: number
    type: string
    dragMeta?: any;
}

export interface DroppableProps {
    dropped: (dragIndex: number, dropIndex: number, dropMeta?: any) => void;
}

export interface OwnProps extends DroppableProps {
    index: number;
    dragMeta?: any;
}

export interface WithCourseEditorDraggableProps {
    dropMightHappen: boolean;
    dragHandleRef: DragElementWrapper<DragSourceOptions>
}

export function withCourseEditorDraggable<PropType extends object>(
    acceptedType: string,
    WrappedComponent: React.ComponentType<PropType & WithCourseEditorDraggableProps>
) {
    return function InnerComponent(props: OwnProps) {
        const {index, dropped, dragMeta, ...rest} = props;
        const ref = useRef<HTMLDivElement>(null);
        const [{isOver, canDrop}, drop] = useDrop({
            accept: acceptedType,
            drop(item: DragItem) {
                dropped(item.index, index, item.dragMeta);
            },
            collect: monitor => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        });

        const [{isDragging}, drag] = useDrag({
            type: acceptedType,
            item: {type: acceptedType, dragMeta, index} as DragItem,
            collect: (monitor: any) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const opacity = isDragging ? 0.4 : 1;
        drag(drop(ref));

        const dropMightHappen = canDrop && isOver;

        return (
            <div ref={ref} style={{opacity}}>
                <WrappedComponent
                    dropMightHappen={dropMightHappen}
                    dragHandleRef={drag}
                    {...rest as PropType}
                />
            </div>
        );
    };

}
