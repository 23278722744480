import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { CourseFilters } from "./CourseFilters";
import { CourseTable } from "./CourseTable";
import { ENTITY_TYPE_COURSE } from "../model/preparing-aggregated-course";
import { CoursePublishingLog } from "../../publishing/components/CoursePublishingLog";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../core/components/containers/Container";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const CourseOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/courses"}>{t("entity.course.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.course.plural")}/>
            </HeadingContainer>
            <ContentContainer>
                <CoursePublishingLog/>
            </ContentContainer>
            <BackgroundContainer>
                <CourseFilters/>
            </BackgroundContainer>
            <ContentContainer>
                <CourseTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_COURSE}/>
            </PaginationContainer>
        </>
    );
};

export const CourseOverview = withTranslation("courses")(CourseOverviewComponent);
