import { UserWithRoles } from "@thekeytechnology/framework-react";
import { AdditionalContentOverview } from "./components/AdditionalContentOverview";
import { EditAdditionalContent } from "./components/edit/EditAdditionalContent";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";

export const AdditionalContentModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/additional-content",
        component: AdditionalContentOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    },
    {
        path: "/additional-content/:additionalContentId/edit",
        component: EditAdditionalContent,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    }
];
