import React, { useEffect } from "react";
import { EntityWrapper, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectUserRolesAdmin } from "../../../selectors";
import { User } from "../../../model/User";
import { API_FETCH_USER_ROLES_ADMIN, fetchUserRolesAdminAction } from "../../../actions/fetch-user-roles";
import { NavLink } from "react-router-dom";
import { Table, TableBody, TableColumn, TableHeader, TableRow } from "../../../../core/components/table/Table";
import { LoadingRow } from "../../../../core/components/table/LoadingRow";

export interface OwnProps {
    user: EntityWrapper<User>
}

export const EditUserRoles = ({user}: OwnProps) => {
    const {t} = useTranslation(["admin", "core"])
    const apiCallState = useSelector(selectCombinedApiState(API_FETCH_USER_ROLES_ADMIN))

    const userRoles = useSelector(selectUserRolesAdmin);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserRolesAdminAction(user.id!))
    }, [dispatch, user])

    return apiCallState.inProgress || !userRoles ? <LoadingRow/> :
        <Table itemCount={userRoles.length} className="user-orders-table">
            <TableHeader>
                <TableColumn>{t("entity.account.labels.name")}</TableColumn>
                <TableColumn>{t("entity.account.labels.roles")}</TableColumn>
            </TableHeader>
            <TableBody>
                {userRoles.map((r, index) => {
                    return <TableRow key={index}>
                        <TableColumn>
                            <NavLink to={`/accounts/${r.accountId}/edit`}>
                                {r.accountName}
                            </NavLink>
                        </TableColumn>
                        <TableColumn>
                            {r.roles.map(r => t("core:user-roles." + r)).join(", ")}
                        </TableColumn>
                    </TableRow>
                })}
            </TableBody>
        </Table>
}
