import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { HeadingContainer } from "../containers/Container";

interface SimpleEditHeaderProps extends WithTranslation {
    entity?: EntityWrapper<any>;
    label: string;
}

const LoadingEditHeaderComponent = (props: SimpleEditHeaderProps) => {
    const {entity, label, t} = props;
    return (
        <HeadingContainer>
            <h1 className="mb-0">
                {entity ? (entity.id ?
                    t("loading-edit-header.editing", {label}) :
                    t("loading-edit-header.creating", {label})) :
                    t("loading-edit-header.loading", {label})
                }
            </h1>
        </HeadingContainer>
    );
};

export const LoadingEditHeader = withTranslation(["core"])(LoadingEditHeaderComponent);
