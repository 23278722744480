import React, {useContext} from "react";
import {DiscountActionSearchContext} from "./DiscountActionSearchContext";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {useTranslation} from "react-i18next";
import { DiscountTypeKind } from "../../../../../../../__generated__/ActionTypeColumn_EntityFragment.graphql";
import {InputText} from "primereact/inputtext";

export const DiscountActionFilter = () => {
    const {t} = useTranslation("billingV3")
    const {filters, setFilters, clearFilters} = useContext(DiscountActionSearchContext)

    const options: { label: string, value: DiscountTypeKind }[] = [
        {label: "System", value: "system"},
        {label: "Nutzer", value: "user"}]

    return <div className="flex flex-wrap align-items-center">
        <div className="mr-2">
            <Dropdown
                value={filters.filterByDiscountTypeKind}
                options={options}
                placeholder={t("billing.discount-code.action.table.type")}
                onChange={(e) => setFilters({
                    ...filters,
                    filterByDiscountTypeKind: e.target.value
                })}/>
            <InputText
                value={filters.filterByActionTitle || ""}
                className="ml-2"
                placeholder={t("billing.discount-code.action.table.title")}
                onChange={(e) => setFilters({
                    ...filters,
                    filterByActionTitle: e.target.value
                })}/>
            <InputText
                value={filters.filterByCode || ""}
                className="ml-2"
                placeholder={t("billing.discount-code.action.table.code")}
                onChange={(e) => setFilters({
                    ...filters,
                    filterByCode: e.target.value
                })}/>
        </div>
        <Button
            className="h-2rem"
            tooltip={"Zurücksetzen"}
            icon="pi pi-times"
            onClick={() => clearFilters()}/>
    </div>
}

