import { UserWithRoles } from "@thekeytechnology/framework-react";
import { UsersScreen } from "./users/UsersScreen";
import { SingleUser } from "./users/SingleUser";
import { OrdersOverview } from "./orders/v2/OrdersOverview";
import { SingleOrder } from "./orders/v2/SingleOrder";
import { AccountsOverview } from "./accounts/AccountsOverview";
import { SingleAccount } from "./accounts/SingleAccount";
import { PrivateRouteDefinition } from "../../core/utils/PrivateRouteDefinition";
import { OrdersScreenV3 } from "./orders/v3/OrdersScreenV3";
import { EditOrderScreen } from "./orders/v3/EditOrderScreen";

export const AdminModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/users",
        component: UsersScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/users/:userId/edit",
        component: SingleUser,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/orders",
        component: OrdersOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/orders/:orderId/edit",
        component: SingleOrder,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/accounts",
        component: AccountsOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/accounts/:accountId/edit",
        component: SingleAccount,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/orders-v3",
        component: OrdersScreenV3,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/orders-v3/:orderId/edit",
        component: EditOrderScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
];
