import React from "react";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {
    UsageIdColumn_DiscountCodeUsageFragment$key
} from "../../../../../../../__generated__/UsageIdColumn_DiscountCodeUsageFragment.graphql";

const DISCOUNT_CODE_USAGE_FRAGMENT = graphql`
    fragment UsageIdColumn_DiscountCodeUsageFragment on DiscountCodeUsage {
        rawId
    }
`

interface OwnProps {
    discountCodeUsageFragmentRef: UsageIdColumn_DiscountCodeUsageFragment$key
}

export const UsageIdColumn = ({discountCodeUsageFragmentRef}: OwnProps) => {
    const discountCodeUsage = useFragment<UsageIdColumn_DiscountCodeUsageFragment$key>(DISCOUNT_CODE_USAGE_FRAGMENT, discountCodeUsageFragmentRef)

    return <div
        className="cursor-pointer"
        onClick={() => navigator.clipboard.writeText(discountCodeUsage.rawId)}>
        <b>{discountCodeUsage.rawId}<i className="pi pi-copy"></i></b>
    </div>
}
