import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_ACCOUNT_ADMIN = "fetch-account-admin";

export const fetchAccountAdminAction = (accountId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_ACCOUNT_ADMIN,
            callType: API_FETCH_ACCOUNT_ADMIN
        },
        payload: accountId,
    } as ApiCallAction<string>;
};
