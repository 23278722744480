import { faHeading, faStop } from "@fortawesome/free-solid-svg-icons";
import { EntityWrapper, generateString, } from "@thekeytechnology/framework-react";
import { Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { CourseModule } from "@thekeytechnology/thekey-academy-frontend-library";
import { EditModalBase } from "../common/modal/EditModalBase";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { BooleanSelectField } from "../../../../core/components/select/BooleanSelectField";
import { WysiwygField } from "../../../../core/components/wysiwyg/WysiwygField";

interface OwnProps {
    module?: EntityWrapper<CourseModule>;

    onSubmit: (module: EntityWrapper<CourseModule>) => void;
    onClose: () => void;
}

type Props = OwnProps & WithTranslation;

const EditModuleFormComponent = (props: Props) => {
    const {module, t, onSubmit, onClose} = props;

    return (
        <Formik
            initialValues={{
                title: module ? module.entity.containerMeta.title : "",
                description: module ? module.entity.containerMeta.description : "",
                blockAdvancement: module ? module.entity.blockAdvancement : false
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
                title: Yup.string().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-module-form.fields.title")})),
                description: Yup.string().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-module-form.fields.description")})),
            })}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(false);

                const temporaryId = module && module.temporaryId ? module.temporaryId : generateString(10);

                onSubmit(new EntityWrapper<CourseModule>(
                    module ? module.id : undefined,
                    {
                        containerMeta: {
                            title: values.title,
                            description: values.description,
                            length: module ? module.entity.containerMeta.length : 0,
                            points: module ? module.entity.containerMeta.points : 0
                        },
                        modulePath: {courseRef: "", moduleIndex: -1},
                        lessonsRef: module ? module.entity.lessonsRef : [],
                        blockAdvancement: values.blockAdvancement,
                        versions: module ? module.entity.versions : {releaseVersion: -1, draftVersion: 0},
                        releaseInformation: module ? module.entity.releaseInformation : undefined
                    },
                    temporaryId
                ));
            }}
        >
            {formikState => (
                <EditModalBase handleClose={onClose}
                               formikState={formikState}
                               title={t("course-editor.edit-module-form.heading")}>
                    <div className="form-group row">
                        <ValidatedField
                            icon={faHeading}
                            label={t("course-editor.edit-module-form.fields.title")}
                            name="title"
                            formikState={formikState}
                            type="text"
                            placeholder={t("course-editor.edit-module-form.fields.title-placeholder")}
                            required
                        />
                    </div>

                    <div className="form-group row">
                        <ValidatedField
                            icon={faStop}
                            label={t("course-editor.edit-module-form.fields.block-advancement.label")}
                            trueLabel={t("course-editor.edit-module-form.fields.block-advancement.true")}
                            falseLabel={t("course-editor.edit-module-form.fields.block-advancement.false")}
                            name="blockAdvancement"
                            formikState={formikState}
                            component={BooleanSelectField}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-module-form.fields.description")}
                            name="description"
                            component={WysiwygField}
                            formikState={formikState}
                            config={{
                                extraPlugins: [],
                            }}
                            required
                        />
                    </div>
                </EditModalBase>
            )}
        </Formik>
    );
};

export const EditModuleForm = withTranslation(["courses", "core"])(EditModuleFormComponent);
