import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { selectHasUnsavedChanges } from "../../../selectors";

interface OwnProps {
    className?: string;
    points: number
}

interface StateProps {
    hasUnsavedChanges: boolean;
}

type Props = OwnProps & StateProps & WithTranslation;

const PointsBadgeComponents = ({
                                   points,
                                   hasUnsavedChanges,
                                   className,
                                   t
                               }: Props) => {

    const badgeStatus = hasUnsavedChanges ? "badge-warning" : "badge-info";

    let UnsavedWarning: any = null;

    if (hasUnsavedChanges) {
        UnsavedWarning = <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-top`}>
                    {t("length-badge.warning")}
                </Tooltip>
            }
        >
            <FontAwesomeIcon className="ml-2" icon={faExclamationTriangle}/>
        </OverlayTrigger>;
    }

    return points ? <div className={`badge ${badgeStatus} ${className ? className : ""}`}>
        {points} Punkte
        {UnsavedWarning}
    </div> : null;
};

export const PointsBadge = connect<StateProps, {}, OwnProps>(
    (state: any) => ({
        hasUnsavedChanges: selectHasUnsavedChanges(state)
    })
)(withTranslation("courses")(PointsBadgeComponents));
