import React from "react";
import { useTranslation } from "react-i18next";
import { PaymentProviderData, StripeData } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    isPaid: boolean
    paymentProviderData?: PaymentProviderData
}

const isProduction = process.env.APP_ENVIRONMENT === "prod";

export const PaymentProviderLinks = ({isPaid, paymentProviderData}: OwnProps) => {
    const {t} = useTranslation("billing");

    if (isPaid) {
        const paymentString = t(`payment-methods.${paymentProviderData?.paymentMethod}`)

        switch (paymentProviderData?.dataType) {
            case "stripe":
            case "stripe-sepa":
                if (isProduction) {
                    return <a rel="noopener noreferrer"
                              target="_blank"
                              href={`https://dashboard.stripe.com/payments/${(paymentProviderData as StripeData).paymentIntentId}`}>{paymentString}</a>
                } else {
                    return <a rel="noopener noreferrer"
                              target="_blank"
                              href={`https://dashboard.stripe.com/test/payments/${(paymentProviderData as StripeData).paymentIntentId}`}>{paymentString}</a>
                }
            default:
                return <>{paymentString}</>
        }
    } else {
        return <>Unbezahlt</>
    }
}
