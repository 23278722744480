import React, { useEffect } from "react";
import { EntityWrapper, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectOrdersAdmin } from "../../../../selectors";
import { API_FETCH_USER_ORDERS_ADMIN, fetchUserOrdersAdminAction } from "../../../../actions/fetch-orders";
import "./user-orders-table.scss";
import { OrderPriceDisplay } from "./OrderPriceDisplay";
import { PaymentProviderLinks } from "./PaymentProviderLinks";
import { User } from "../../../../model/User";
import { NavLink } from "react-router-dom";
import { Table, TableColumn, TableHeader, TableRow } from "../../../../../core/components/table/Table";
import { AsyncEntityTableBody } from "../../../../../core/components/table/AsyncEntityTableBody";
import { DateTimeDisplay } from "../../../../../core/components/datetime/DateTimeDisplay";

export interface OwnProps {
    user: EntityWrapper<User>
}

export const UserOrders = ({user}: OwnProps) => {
    const {t} = useTranslation("admin")
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUserOrdersAdminAction(user.id!))
    }, [dispatch, user])

    const entities = useSelector(selectOrdersAdmin)
    const apiCallState = useSelector(selectCombinedApiState(API_FETCH_USER_ORDERS_ADMIN))

    return entities ? <Table itemCount={entities.entities.length} className="user-orders-table">
        <TableHeader>
            <TableColumn>{t("entity.orders.labels.date-time")}</TableColumn>
            <TableColumn>{t("entity.orders.labels.id")}</TableColumn>
            <TableColumn>{t("entity.orders.labels.payment-method")}</TableColumn>
            <TableColumn>{t("entity.orders.labels.amount")}</TableColumn>
        </TableHeader>
        <AsyncEntityTableBody
            apiCallState={apiCallState}
            items={entities.entities}
            renderItem={order =>
                <TableRow key={order.id}>
                    <TableColumn>
                        <DateTimeDisplay dateTime={order.entity.history[0]?.dateTime}/>
                    </TableColumn>
                    <TableColumn className="bold title-column">
                        <NavLink to={`/orders/${order.id}/edit`}>
                            {order.id}
                        </NavLink>
                    </TableColumn>
                    <TableColumn>
                        <PaymentProviderLinks isPaid={order.entity.status === "payment-in-process"}
                                              paymentProviderData={order.entity.paymentProviderData}/>
                    </TableColumn>
                    <TableColumn>
                        <OrderPriceDisplay order={order}/>
                    </TableColumn>
                </TableRow>

            }/>
    </Table> : null;
}
