import { EntityWrapper, } from "@thekeytechnology/framework-react";
import { Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { extractId } from "../../../../reducers/changes/common/helpers";
import { EditElementModalBase } from "../common/EditElementModalBase";
import { CONNECTION_VALIDATION_SCHEME, TITLE_VALIDATION_SCHEME } from "../common/GeneralElementSettingsEditor";
import { ELEMENT_TYPE_TEXT, TextElement } from "@thekeytechnology/thekey-academy-frontend-library";
import { ValidatedField } from "../../../../../core/components/form/ValidatedField";
import { WysiwygFieldWithUpload } from "../../../../../core/components/wysiwyg/WysiwygFieldWithUpload";

interface OwnProps {
    element: EntityWrapper<TextElement>;
    handleSubmit: (element: EntityWrapper<TextElement>) => void;
    handleClose: () => void;
}

type Props = OwnProps & WithTranslation;

const EditTextElementFormComponent = (props: Props) => {
    const {element, t, handleSubmit, handleClose} = props;

    return (
        <Formik
            initialValues={{
                internalTitle: element.entity.generalSettings.internalTitle,
                points: element.entity.generalSettings.points,
                lengthInSeconds: element.entity.generalSettings.lengthInSeconds,
                connections: element.entity.generalSettings.connections,
                title: element.entity.title ? element.entity.title : "",
                text: element.entity.text ? element.entity.text : ""
            }}
            validationSchema={Yup.object().shape({
                internalTitle: TITLE_VALIDATION_SCHEME(t),
                title: TITLE_VALIDATION_SCHEME(t),
                connections: CONNECTION_VALIDATION_SCHEME(t),
            })}
            onSubmit={(values, {setSubmitting}) => {
                handleSubmit(new EntityWrapper<TextElement>(
                    element.id,
                    {
                        generalSettings: {
                            internalTitle: values.internalTitle,
                            points: values.points,
                            lengthInSeconds: values.lengthInSeconds,
                            connections: values.connections
                        },
                        elementType: ELEMENT_TYPE_TEXT,
                        elementPath: element.entity.elementPath,
                        title: values.title,
                        text: values.text,
                        versions: element.entity.versions
                    },
                    element.temporaryId,
                ));
                setSubmitting(false);
            }}
        >
            {formikState => (
                <EditElementModalBase title={t("course-editor.edit-element-form.text.heading")}
                                      currentElementId={extractId(element)}
                                      formikState={formikState}
                                      handleClose={handleClose}>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.common.fields.title")}
                            name="title"
                            formikState={formikState}
                            type={"text"}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.text.fields.text")}
                            name="text"
                            formikState={formikState}
                            component={WysiwygFieldWithUpload}
                            required
                        />
                    </div>
                </EditElementModalBase>
            )}
        </Formik>
    );
};

export const EditTextElementForm = withTranslation(["courses", "core"])(EditTextElementFormComponent);
