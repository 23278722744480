import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { Lesson } from "../../model/lesson/lesson";

export const ADD_LESSON_ACTION = "add-lesson";

export interface AddLessonAction extends Action {
    lesson: EntityWrapper<Lesson>
    parentModuleId: string;
}

export const addLessonAction = (parentModuleId: string, lesson: EntityWrapper<Lesson>) => ({
    type: ADD_LESSON_ACTION,
    parentModuleId,
    lesson
}) as AddLessonAction;
