import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addLessonAction } from "../../../actions/lesson/add-lesson";
import { Lesson } from "../../../model/lesson/lesson";
import { AddButton } from "../common/AddButton";
import { DroppableProps } from "../common/WithCourseEditorDraggable";
import { DRAGGABLE_TYPES } from "../common/draggable-types";
import { EditNormalLessonForm } from "./EditNormalLessonForm";
import { EditTestLessonForm } from "./EditTestLessonForm";

interface OwnProps extends DroppableProps {
    parentModuleId: string;
}

interface DispatchProps {
    addLesson: typeof addLessonAction
}

type Props = OwnProps & DispatchProps & WithTranslation;

const AddLessonComponent = (props: Props) => {
    const {addLesson, parentModuleId, dropped, t} = props;

    return (<>

            <AddButton
                acceptableType={DRAGGABLE_TYPES.LESSON}
                title={t("course-editor.add-lesson.new")}
                dropped={dropped}
                editForm={(onClose => <EditNormalLessonForm onSubmit={(entity: EntityWrapper<Lesson>) => {
                    addLesson(parentModuleId, entity);
                    onClose();
                }} onClose={onClose}/>)}
            />
            <AddButton
                acceptableType={DRAGGABLE_TYPES.LESSON}
                title={t("course-editor.add-lesson.new-test")}
                dropped={dropped}
                editForm={(onClose => <EditTestLessonForm onSubmit={(entity: EntityWrapper<Lesson>) => {
                    addLesson(parentModuleId, entity);
                    onClose();
                }} onClose={onClose}/>)}
            />
        </>
    );
};

export const AddLesson = connect<{}, DispatchProps, {}>(
    null,
    {
        addLesson: addLessonAction
    }
)(withTranslation("courses")(AddLessonComponent));
