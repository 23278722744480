import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { editModuleAction } from "../../../actions/module/edit-module";
import { EditModuleForm } from "./EditModuleForm";
import { CourseModule } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    module: EntityWrapper<CourseModule>;
}

interface DispatchProps {
    editModule: typeof editModuleAction
}

type Props = OwnProps & DispatchProps & WithTranslation;

const EditModuleComponent = (props: Props) => {
    const {editModule, module} = props;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button className="ml-2 btn-secondary" onClick={handleShow}>
                <FontAwesomeIcon icon={faPencilAlt}/>
            </Button>

            {show ? <Modal size="lg" show={show} onHide={handleClose}>
                <EditModuleForm module={module} onSubmit={(entity: EntityWrapper<CourseModule>) => {
                    editModule(entity);
                    handleClose();
                }} onClose={handleClose}/>
            </Modal> : null}
        </>
    );
};

export const EditModule = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        editModule: editModuleAction
    }
)(withTranslation("courses")(EditModuleComponent));
