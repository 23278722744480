import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";

interface OwnProps {
    dateTime?: string;
}

type Props = OwnProps & WithTranslation;

const DateTimeDisplayComponent = (props: Props) => {
    const {dateTime, t} = props;

    if (!dateTime) {
        return null;
    }

    const formattedDateTime = moment(dateTime).format(t("date-time-display.format"));

    return (
        <>
            {formattedDateTime}
        </>
    );
};

export const DateTimeDisplay = withTranslation("core")(DateTimeDisplayComponent);
