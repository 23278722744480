import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { copyCourseAction } from "../actions/copy-course";
import { DialogResult, showDialogAction } from "@thekeytechnology/framework-react";

interface OwnProps {
    courseId: string;
}

interface DispatchProps {
    copyCourse: typeof copyCourseAction,
    showDialog: typeof showDialogAction
}

type Props = OwnProps & DispatchProps & WithTranslation;

const CopyCourseButtonComponent = (props: Props) => {
    const {courseId, copyCourse, showDialog, t} = props;
    return <button type="button"  className="btn btn-link mr-2"
                   onClick={() =>
                       showDialog(
                           {
                               title: t("copy-course-button.dialog.title"),
                               affirmativeText: t("copy-course-button.dialog.affirmative-text"),
                               explanation: t("copy-course-button.dialog.explanation"),
                               negativeText: t("copy-course-button.dialog.negative-text"),
                               question: t("copy-course-button.dialog.question")
                           }, result => {
                               if (result === DialogResult.ACCEPT) {
                                   copyCourse(courseId)
                               }
                           }
                       )}>
        <FontAwesomeIcon icon={faCopy}/>
    </button>;
};

export const CopyCourseButton = connect<{}, DispatchProps, {}>(
    null,
    {
        copyCourse: copyCourseAction,
        showDialog: showDialogAction
    }
)(withTranslation(["courses", "core"])(CopyCourseButtonComponent));
