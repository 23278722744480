import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { EmailConfiguration } from "../model/email-configuration.model";
import { EmailTable } from "./email/EmailTable";
import { TriggeredEmailTable } from "./triggered-email/TriggeredEmailTable";
import { ENTITY_TYPE_TRIGGERED_EMAIL } from "../model/triggered-email";
import { ContentContainer, HeadingContainer } from "../../core/components/containers/Container";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const EmailOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/emails"}>{t("Emails")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("Emails")}/>
            </HeadingContainer>
            <ContentContainer>
                <h2>System-Emails</h2>
                <EmailTable/>
                <Pagination entityType={EmailConfiguration.TYPE}/>
                <h2>Dynamische Emails</h2>
                <TriggeredEmailTable/>
                <Pagination entityType={ENTITY_TYPE_TRIGGERED_EMAIL}/>
            </ContentContainer>
        </>
    );
};

export const EmailOverview = withTranslation("emails")(EmailOverviewComponent);
