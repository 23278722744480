import { Trigger } from "../../core/model/trigger/trigger";
import { EntityWrapper } from "@thekeytechnology/framework-react";

export const ENTITY_TYPE_TRIGGERED_NOTIFICATION = "triggered-notification";

export const NEW_TRIGGERED_NOTIFICATION = () => new EntityWrapper(undefined, {
    title: "Neu",
    subjects: {},
    bodies: {}
} as TriggeredNotification)

export interface TriggeredNotification {
    title: string,
    trigger?: Trigger,
    customerMessageDefinitionRef?: string,
    subjects: { [lang: string]: string },
    bodies: { [lang: string]: string },
}