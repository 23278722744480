import { EntityWrapper } from "@thekeytechnology/framework-react";
import { UserJustName } from "./user-just-name";
import {
    CourseJustMeta,
    ElementJustMetaAndType,
    LessonJustMetaAndPath
} from "@thekeytechnology/thekey-academy-frontend-library";

export const ENTITY_TYPE_QNA = "aggregated-qna"

export interface Qna {
    user: EntityWrapper<UserJustName>,
    language: string,
    question: string,
    answer: string | undefined,
    isPublic: boolean,
    course: EntityWrapper<CourseJustMeta>,
    lesson: EntityWrapper<LessonJustMetaAndPath> | undefined,
    element: EntityWrapper<ElementJustMetaAndType> | undefined,
    createdAt: string | undefined
}
