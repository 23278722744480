import React from "react";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {
    CodeIdColumn_DiscountCodeFragment$key
} from "../../../../../../../__generated__/CodeIdColumn_DiscountCodeFragment.graphql";

const DISCOUNT_CODE_FRAGMENT = graphql`
    fragment CodeIdColumn_DiscountCodeFragment on DiscountCodeV2 {
        rawId
    }
`

interface OwnProps {
    discountCodeFragmentRef: CodeIdColumn_DiscountCodeFragment$key
}

export const CodeIdColumn = ({discountCodeFragmentRef}: OwnProps) => {
    const discountCode = useFragment<CodeIdColumn_DiscountCodeFragment$key>(DISCOUNT_CODE_FRAGMENT, discountCodeFragmentRef)

    return <div
        className="cursor-pointer"
        onClick={() => navigator.clipboard.writeText(discountCode.rawId)}>
        <b>{discountCode.rawId}<i className="pi pi-copy"></i></b>
    </div>
}
