/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrderStatus = "hasBillingDetails" | "hasCart" | "hasPaymentDetails" | "paymentReceived" | "purchased" | "transient" | "%future added value";
export type OrderPriceDisplayV3_OrderFragment = {
    readonly status: OrderStatus;
    readonly cart: {
        readonly cartTotals: {
            readonly totalsIncludingAllDiscounts: {
                readonly netPrice: number;
            } | null;
        };
    };
    readonly selectedPaymentMethod: {
        readonly chosenOption?: {
            readonly monthlyRate: {
                readonly netPrice: number;
            };
        };
    } | null;
    readonly invoiceData: {
        readonly invoiceId: string;
        readonly invoiceNumber: string | null;
    } | null;
    readonly " $refType": "OrderPriceDisplayV3_OrderFragment";
};
export type OrderPriceDisplayV3_OrderFragment$data = OrderPriceDisplayV3_OrderFragment;
export type OrderPriceDisplayV3_OrderFragment$key = {
    readonly " $data"?: OrderPriceDisplayV3_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"OrderPriceDisplayV3_OrderFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderPriceDisplayV3_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "cartTotals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceV3",
              "kind": "LinkedField",
              "name": "totalsIncludingAllDiscounts",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceV3",
                  "kind": "LinkedField",
                  "name": "monthlyRate",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MonthlySelectedPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceData",
      "kind": "LinkedField",
      "name": "invoiceData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();
(node as any).hash = 'bc6b2dca92cf2d9c51fbac845e8e6490';
export default node;
