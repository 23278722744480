import {
    API_CREATE_ENTITIES,
    API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import { combineEpics } from "redux-observable";
import { Expert } from "../model/expert";
import { ExpertForSaving } from "../model/expert-for-saving";
import { redirectAfterEntityApiOperation } from "../../core/epics/redirects";

export const expertModuleEpics$ = combineEpics(
    reloadOnEntityApiOperation(API_DELETE_ENTITIES, Expert.TYPE),
    redirectAfterEntityApiOperation([API_UPDATE_ENTITIES, API_CREATE_ENTITIES], ExpertForSaving.TYPE, "/experts")
);
