import {
    addFiltersAction,
    Filter,
    FilterTerm,
    PropertyFilter,
    reloadEntityListAction,
    removeFiltersAction,
    selectFilter
} from "@thekeytechnology/framework-react";
import React from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { ENTITY_TYPE_QNA} from "../model/qna";

interface StateProps {
    currentFilters?: Filter[];
}

interface DispatchProps {
    addFilters: ReturnType<typeof addFiltersAction>,
    removeFilters: ReturnType<typeof removeFiltersAction>
    reloadEntities: ReturnType<typeof reloadEntityListAction>
}

type Props = StateProps & DispatchProps & WithTranslation;

export const ONLY_PUBLIC_FILTER_KEY = "only-public";

const OnlyPublicFilterComponent = ({addFilters, removeFilters, reloadEntities, currentFilters, t}: Props) => {
    const filter = (isChecked: boolean) => {
        if (isChecked) {
            addFilters(ONLY_PUBLIC_FILTER_KEY, [
                new PropertyFilter("entity.isPublic", new FilterTerm(FilterTerm.TYPE_BOOLEAN, FilterTerm.OPERATION_EQ, true))
            ]);
        } else {
            removeFilters([ONLY_PUBLIC_FILTER_KEY]);
        }
        reloadEntities()
    };

    const isFiltered = currentFilters && currentFilters.length > 0;

    return (
        <div className="form-check flex-grow-1 d-flex align-items-center pl-3 pr-3">
            <input
                className="mr-2"
                type="checkbox"
                checked={isFiltered}
                onChange={change => filter(change.target.checked)}
            />
            <label className="form-check-label pl-4">{t("only-public-filter.text")}</label>
        </div>
    );
};

export const OnlyPublicFilter = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            currentFilters: selectFilter(ENTITY_TYPE_QNA, ONLY_PUBLIC_FILTER_KEY)(state)
        };
    },
    {
        addFilters: addFiltersAction(ENTITY_TYPE_QNA),
        removeFilters: removeFiltersAction(ENTITY_TYPE_QNA),
        reloadEntities: reloadEntityListAction(ENTITY_TYPE_QNA)
    }
)(withTranslation("qna")(OnlyPublicFilterComponent));
