import { generateString } from "@thekeytechnology/framework-react";
import React from "react";
import { Placement } from "react-bootstrap/Overlay";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface OwnProps {
    tooltip: string;
    placement?: Placement;
    children: React.ReactElement;
}

export const OverlayTooltip = (props: OwnProps) => {
    const {placement, children, tooltip} = props;

    return <OverlayTrigger
        placement={placement ? placement : "top"}
        overlay={
            <Tooltip id={generateString(10)}>
                {tooltip}
            </Tooltip>
        }
    >
        {children}
    </OverlayTrigger>;

};
