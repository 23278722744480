import { FieldProps } from "formik";
import React from "react";
import { Editor } from "@tinymce/tinymce-react/lib/es2015/main/ts";

interface OwnProps {
    config: any;
    language?: string;
}

type Props = FieldProps & OwnProps;

export function WysiwygField({
                                 language,
                                 form,
                                 field,
                                 config
                             }: Props) {

    const onChange = (body: string) => {
        const regex = /src=".*fileId=([0-9a-z-]*)"/gm;
        let m: any;
        let str = body;
        // tslint:disable-next-line:no-conditional-assignment
        while ((m = regex.exec(body)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            // The result can be accessed through the `m`-variable.
            for (let groupIndex = 0; groupIndex < m.length; groupIndex++) {
                const match: any = m[groupIndex];
                if (groupIndex === 1) {
                    str = str.replace(m[0], `src="tkfile-${match}"`)
                }
            }
        }

        form.setFieldValue(field.name, str);
    }

    return (
        <div className="wysiwyg">
            <Editor
                value={field.value}
                init={{
                    height: 500,
                    language: language ? language : "de",
                    language_url: `/public/tinymce/${language ? language : "de"}.js`,
                    link_class_list: [
                        {title: "Keine", value: ""},
                        {title: "Button", value: "button"},
                    ],
                    default_link_target: "_blank",
                    plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount"
                    ],
                    toolbar:
                        "undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | link | image | bullist numlist outdent indent | removeformat | help",
                    ...config
                }}
                onEditorChange={onChange}
            />
        </div>
    );
}
