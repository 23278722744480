import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import "./triggered-unlock-table.scss";
import {withTranslation, WithTranslation} from "react-i18next";
import {ENTITY_TYPE_TRIGGERED_UNLOCK, TriggeredUnlock} from "../model/triggered-unlock";
import { AboveTableContainer } from "../../core/components/containers/Container";
import { PaginationInfo } from "../../core/components/pagination/PaginationInfo";
import { EntityCreateAndDeleteButtons } from "../../core/components/button/EntityCreateAndDeleteButtons";
import { Table, TableColumn } from "../../core/components/table/Table";
import { EntityTableHeader } from "../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../core/components/table/AsyncEntityTableBody";
import { EntityTableRow } from "../../core/components/table/EntityTableRow";
import { ActionLink } from "../../core/components/icon-link-tooltip/ActionLink";

type Props = WithEntityListProps<TriggeredUnlock> & WithTranslation & WithSelectionsProps<string>;

const TriggeredAchievementsTableComponent = ({
                                                 entities,
                                                 entityApiState,
                                                 t,
                                                 selectionModel
                                             }: Props) => {

    return (<>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_TRIGGERED_UNLOCK} className="mr-auto"/>

                <EntityCreateAndDeleteButtons
                    selectedEntities={selectionModel.selections}
                    entityType={ENTITY_TYPE_TRIGGERED_UNLOCK}
                    createPath="/unlocks/new/edit"
                    label={t("entity.triggered-unlock.singular")}
                    labelPlural={t("entity.triggered-unlock.plural")}
                />
            </AboveTableContainer>
            <Table className="triggered-unlock-table" itemCount={entities.length}>
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <div className="table-column">{t("entity.triggered-unlock.labels.internal-title")}</div>
                    <div className="table-column">{t("entity.triggered-unlock.labels.trigger")}</div>
                    <div className="table-column">{t("triggered-unlock-table.actions")}</div>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={unlock =>
                        <EntityTableRow
                            key={unlock.id}
                            entity={unlock} selectionModel={selectionModel}>
                            <TableColumn className="bold">
                                {unlock.entity.internalTitle}
                            </TableColumn>
                            <TableColumn>
                                {t("triggers:trigger-field.trigger-types." + unlock.entity.trigger?.key)}
                            </TableColumn>
                            <TableColumn>
                                <ActionLink
                                    to={"/unlocks/" + unlock.id + "/edit"}
                                    tooltip={t("Edit unlock")}
                                    icon={faPencilAlt}/>
                            </TableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const TriggeredUnlocksTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation(["unlocks", "triggers"])(TriggeredAchievementsTableComponent),
        ENTITY_TYPE_TRIGGERED_UNLOCK
    )
);
