/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateSingleCodeButton_DiscountActionFragment = {
    readonly id: string;
    readonly " $refType": "CreateSingleCodeButton_DiscountActionFragment";
};
export type CreateSingleCodeButton_DiscountActionFragment$data = CreateSingleCodeButton_DiscountActionFragment;
export type CreateSingleCodeButton_DiscountActionFragment$key = {
    readonly " $data"?: CreateSingleCodeButton_DiscountActionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CreateSingleCodeButton_DiscountActionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateSingleCodeButton_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};
(node as any).hash = '8947262eaeeebcb7d1341735a24f3f31';
export default node;
