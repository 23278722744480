import { EditedCourseState } from "../../edited-course";
import update from "immutability-helper";
import { EditElementAction } from "../../../actions/element/edit-lesson";
import { equalById } from "../common/helpers";

export function editElement(state: EditedCourseState,
                            {element}: EditElementAction) {
    const editElementIndex = state.editedCourse!.entity.elements.findIndex(e => equalById(e, element));

    return update(state,
        {
            hasUnsavedChanges: {$set: true},
            editedCourse: {entity: {elements: {$splice: [[editElementIndex, 1, element]]}}}
        }
    )
}
