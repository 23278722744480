import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { FeedbackTable } from "./FeedbackTable";
import { ENTITY_TYPE_FEEDBACK } from "@thekeytechnology/thekey-academy-frontend-library";
import { ContentContainer, HeadingContainer, PaginationContainer } from "../../core/components/containers/Container";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const FeedbackOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/feedbacks"}>{t("entity.feedback.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.feedback.plural")}/>
            </HeadingContainer>
            <ContentContainer>
                <FeedbackTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_FEEDBACK}/>
            </PaginationContainer>
        </>
    );
};

export const FeedbackOverview = withTranslation("feedback")(FeedbackOverviewComponent);
