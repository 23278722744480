import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reloadEntityListAction, removeFiltersAction } from "@thekeytechnology/framework-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import "./filters.scss";
import { PaginationInfo } from "../pagination/PaginationInfo";

interface OwnProps {
    entityType: string;
    filterKeys: string[];

    children: any;
}

interface DispatchProps {
    removeFilters: ReturnType<typeof removeFiltersAction>
    reloadEntities: ReturnType<typeof reloadEntityListAction>
}

type Props = OwnProps & DispatchProps;

interface FiltersState {
    isOpen: boolean;
}

export const UnconnectedFilters = ({entityType, removeFilters, reloadEntities, filterKeys, children}: Props) => {
    const {t} = useTranslation("core")
    const resetFilters = () => {
        removeFilters(filterKeys);
        reloadEntities();
    };

    useEffect(() => {
        return () => {
            resetFilters();
        };
        // eslint-disable-next-line
    }, [entityType]);

    const [state, setState] = useState<FiltersState>({
        isOpen: false
    });

    return (
        <div className="filters">
            <button type="button" className="filters-menu-toggle" onClick={() => setState({
                isOpen: !state.isOpen
            })}>
                <FontAwesomeIcon className="filter-icon" icon={faFilter}/>
            </button>

            <div className={"filters-menu" + (state.isOpen ? " open" : "")}>
                {children}

                <button type="button" onClick={() => {
                    setState({
                        isOpen: !state.isOpen
                    });
                    resetFilters();
                }} className="clear-filters">
                    <FontAwesomeIcon icon={faTimes}/> {t("filters.clear")}
                </button>
            </div>

            <PaginationInfo className="ml-auto" entityType={entityType}/>
        </div>
    );
};

export const Filters = connect<{}, DispatchProps, OwnProps>(
    null,
    (dispatch: Dispatch<Action>, ownProps: OwnProps) => {
        return {
            removeFilters: (filterKeys: string[]) => dispatch(removeFiltersAction(ownProps.entityType)(filterKeys)),
            reloadEntities: () => dispatch(reloadEntityListAction(ownProps.entityType)())
        };
    }
)(UnconnectedFilters);
