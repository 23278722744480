import React, {useContext} from 'react';
import graphql from 'babel-plugin-relay/macro'
import {DataTable} from "primereact/datatable";
import {readInlineData, usePaginationFragment} from "react-relay";
import {Column} from "primereact/column";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {UsageIdColumn} from "./columns/UsageIdColumn";
import {UsageUsedAtColumn} from "./columns/UsageUsedAtColumn";
import {UsageInvoiceColumn} from "./columns/UsageInvoiceColumn";
import {UsageOrderColumn} from "./columns/UsageOrderColumn";
import {DiscountCodeUsageTable_Refetch} from "../../../../../../__generated__/DiscountCodeUsageTable_Refetch.graphql";
import {
    DiscountCodeUsageTable_DiscountCodeFragment$key
} from "../../../../../../__generated__/DiscountCodeUsageTable_DiscountCodeFragment.graphql";
import {DiscountCodeUsageTableHeader} from "./header/DiscountCodeUsageTableHeader";
import {useDebounceFilters} from "../../../../../hooks/useDebounceFilters";
import {DiscountUsageFilters, DiscountUsageSearchContext} from "./filter/DiscountUsageSearchContext";

const QUERY_FRAGMENT = graphql`
    fragment DiscountCodeUsageTable_DiscountCodeFragment on Query @refetchable(queryName: "DiscountCodeUsageTable_Refetch") @argumentDefinitions(
        first: {type: "Int!"},
        after: {type: "String"},
        filterByUsedAtFrom: {type: "LocalDateTime"},
        filterByUsedToFrom: {type: "LocalDateTime"},
        id: {type: "ID!"}
    ) {
        node(id: $id) {
            ... on DiscountCodeV2 {
                searchUsages(after: $after, first: $first, filterByUsedAtFrom: $filterByUsedAtFrom, filterByUsedToFrom: $filterByUsedToFrom )  {
                    usages (after: $after, first: $first)@connection(key: "DiscountCodeUsageTable_usages"){
                        edges {
                            node {
                                ... DiscountCodeUsageTable_DiscountCodeUsageFragment
                            }
                        }
                    }
                    ... DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment
                }
            }
        }
    }
`


const DISCOUNT_CODE_USAGE_FRAGMENT = graphql`
    fragment DiscountCodeUsageTable_DiscountCodeUsageFragment on DiscountCodeUsage @inline {
        ... UsageUsedAtColumn_DiscountCodeUsageFragment
        ... UsageOrderColumn_DiscountCodeUsageFragment
        ... UsageIdColumn_DiscountCodeUsageFragment
        ... UsageInvoiceColumn_DiscountCodeUsageFragment
    }
`


interface OwnProps {
    query: DiscountCodeUsageTable_DiscountCodeFragment$key
}

export const DiscountCodeUsageTable = ({query}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const discountCodeUsageNs = 'billing.discount-code.usage'

    const {filters} = useContext(DiscountUsageSearchContext)

    const {
        data: code,
        hasNext,
        loadNext,
        refetch
    } = usePaginationFragment<DiscountCodeUsageTable_Refetch, DiscountCodeUsageTable_DiscountCodeFragment$key>(QUERY_FRAGMENT, query)

    useDebounceFilters<DiscountUsageFilters>(filters, refetch)

    return (
        <>
            <DataTable
                emptyMessage={t(`${discountCodeUsageNs}.table.empty-message`)}
                className="mt-3"
                header={() => <DiscountCodeUsageTableHeader searchDiscountUsageResultFragmentRef={code.node?.searchUsages!}/>}
                value={code.node?.searchUsages?.usages?.edges?.filter((e: any) => !!(e?.node)).map((e: any) => e!.node).map((item: any) => readInlineData(DISCOUNT_CODE_USAGE_FRAGMENT, item)) || []}>
                <Column header={t(`${discountCodeUsageNs}.table.id`)}
                        body={item => <UsageIdColumn discountCodeUsageFragmentRef={item}/>}/>
                <Column header={t(`${discountCodeUsageNs}.table.used-at`)}
                        body={item => <UsageUsedAtColumn discountCodeUsageFragmentRef={item}/>}/>
                <Column header={t(`${discountCodeUsageNs}.table.invoice`)}
                        body={item => <UsageInvoiceColumn discountCodeUsageFragmentRef={item}/>}/><
                Column header={t(`${discountCodeUsageNs}.table.order`)}
                        body={item => <UsageOrderColumn discountCodeUsageFragmentRef={item}/>}/>
            </DataTable>
            <div className="flex justify-content-center align-items-center">
                <Button
                    className="p-button-secondary"
                    disabled={!hasNext}
                    label="Weiter"
                    onClick={_ => loadNext(20)}/>
            </div>
        </>
    )
}
