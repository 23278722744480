import React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import { PaymentProviderLinksV3_OrderFragment$key } from "../../../../../__generated__/PaymentProviderLinksV3_OrderFragment.graphql";
import { graphql } from "babel-plugin-relay/macro";

const ORDER_FRAGMENT = graphql`
    fragment PaymentProviderLinksV3_OrderFragment on Order {
        paymentData {
            paymentMethod
            dataType
            ... on StripeData {
                paymentIntentId
            }
            ... on StripeSepaData {
                paymentIntentId
            }
        }
        status
    }
`;

interface OwnProps {
    orderFragmentRef: PaymentProviderLinksV3_OrderFragment$key
}

const isProduction = process.env.APP_ENVIRONMENT === "prod";

export const PaymentProviderLinksV3 = ({orderFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billing");

    const order = useFragment<PaymentProviderLinksV3_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    if (order.status === "paymentReceived" || order.status === "purchased") {
        const paymentString = t(`payment-methods.${order.paymentData?.paymentMethod}`)

        switch (order.paymentData?.dataType) {
            case "stripe":
            case "stripeSepa":
                if (isProduction) {
                    return <a rel="noopener noreferrer"
                              target="_blank"
                              href={`https://dashboard.stripe.com/payments/${order.paymentData?.paymentIntentId}`}>{paymentString}</a>
                } else {
                    return <a rel="noopener noreferrer"
                              target="_blank"
                              href={`https://dashboard.stripe.com/test/payments/${order.paymentData?.paymentIntentId}`}>{paymentString}</a>
                }
            default:
                return <>{paymentString}</>
        }
    } else {
        return <>Unbezahlt</>
    }
}
