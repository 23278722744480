import { Action } from "redux";

export const MOVE_LESSON_ACTION = "move-lesson";

export interface MoveLessonAction extends Action {
    sourceModuleId: string;
    targetModuleId: string;
    sourceIndex: number;
    targetIndex: number;
}

export const moveLessonAction = (sourceModuleId: string,
                                 targetModuleId: string,
                                 sourceIndex: number,
                                 targetIndex: number) => ({
    type: MOVE_LESSON_ACTION,
    sourceModuleId,
    targetModuleId,
    sourceIndex,
    targetIndex
}) as MoveLessonAction;
