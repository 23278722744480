/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProductsScreen_ProductsQueryVariables = {};
export type ProductsScreen_ProductsQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ProductsTable_ProductsList">;
};
export type ProductsScreen_ProductsQuery = {
    readonly response: ProductsScreen_ProductsQueryResponse;
    readonly variables: ProductsScreen_ProductsQueryVariables;
};



/*
query ProductsScreen_ProductsQuery {
  ...ProductsTable_ProductsList
}

fragment ProductsTable_ProductsList on Query {
  Admin {
    Billing {
      Products(includeHidden: true) {
        pageInfo {
          endCursor
          hasPreviousPage
          hasNextPage
          startCursor
        }
        edges {
          node {
            __typename
            id
            ... on CourseProduct {
              title
              price {
                netPrice
                grossPrice
              }
            }
          }
          cursor
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "includeHidden",
    "value": true
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductsScreen_ProductsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProductsTable_ProductsList"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProductsScreen_ProductsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "ProductsConnection",
                "kind": "LinkedField",
                "name": "Products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PriceV3",
                                "kind": "LinkedField",
                                "name": "price",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "netPrice",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "grossPrice",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "CourseProduct",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": "Products(includeHidden:true)"
              },
              {
                "alias": null,
                "args": (v0/*: any*/),
                "filters": [
                  "includeHidden"
                ],
                "handle": "connection",
                "key": "ProductsTable_Products",
                "kind": "LinkedHandle",
                "name": "Products"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be591029753f6e7844516fd83d838c0b",
    "id": null,
    "metadata": {},
    "name": "ProductsScreen_ProductsQuery",
    "operationKind": "query",
    "text": "query ProductsScreen_ProductsQuery {\n  ...ProductsTable_ProductsList\n}\n\nfragment ProductsTable_ProductsList on Query {\n  Admin {\n    Billing {\n      Products(includeHidden: true) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            __typename\n            id\n            ... on CourseProduct {\n              title\n              price {\n                netPrice\n                grossPrice\n              }\n            }\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ad5002e9c77161f27981bcc04109d611';
export default node;
