import React, {Suspense} from "react";
import {useTranslation} from "react-i18next";
import {ToggleButton} from "primereact/togglebutton";
import {EditUsageLimited} from "../../../../../../__generated__/DiscountActionForm_EditActionMutation.graphql";
import {InputNumber} from "primereact/inputnumber";
import {ProgressSpinner} from "primereact/progressspinner";
import graphql from "babel-plugin-relay/macro"
import {useLazyLoadQuery} from "react-relay";
import {UsageLimitationInput_Query} from "../../../../../../__generated__/UsageLimitationInput_Query.graphql";
import {AccountsSelectWithId} from "../../../../../core/components/entity-selects/AccountsSelectWithId";
import {ProductsSelect} from "../../../../../core/components/entity-selects/ProductsSelect";

interface OwnProps {
    fieldValue?: EditUsageLimited,
    update: (discountTypeKind?: EditUsageLimited) => void
}

const QUERY = graphql`
    query UsageLimitationInput_Query($accountIds: [ID!]!, $productIds: [ID!]!) {
        ...AccountsSelectWithId_QueryFragment @arguments(ids: $accountIds)
        ...ProductsSelect_QueryFragment @arguments(ids: $productIds)
    }
`

export const UsageLimitationInput = ({fieldValue, update}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const usageLimitationFormNs = "billing.discount-code.action.form.usage-limitation"

    const query = useLazyLoadQuery<UsageLimitationInput_Query>(QUERY, {
        accountIds: fieldValue?.onlyForAccountIds || [],
        productIds: fieldValue?.onlyForProductIds || []
    })

    return <div>
        <ToggleButton
            onLabel="Ja"
            offLabel="Nein"
            className="w-8rem"
            checked={fieldValue !== undefined}
            onChange={e => e.value ? update({
                maxAmountOfUsages: undefined,
                maxAmountOfUsagesPerAccount: undefined,
                onlyForProductIds: [],
                onlyForAccountIds: []
            }) : update(undefined)}/>
        {fieldValue &&
            <div className="mt-6">
                <span className="p-float-label">
                    <InputNumber
                        max={1000}
                        min={0}
                        value={fieldValue?.maxAmountOfUsages || undefined}
                        onValueChange={(e) => update({
                            ...fieldValue,
                            maxAmountOfUsages: e.value
                        })}/>
                    <label htmlFor="maxAmountOfUsages">{t(`${usageLimitationFormNs}.max-amount-of-usages`)}</label>
                </span>
                <span className="mt-4 p-float-label">
                    <InputNumber
                        max={1000}
                        min={0}
                        value={fieldValue?.maxAmountOfUsagesPerAccount || undefined}
                        onValueChange={(e) => update({
                            ...fieldValue,
                            maxAmountOfUsagesPerAccount: e.value
                        })}/>
                    <label
                        htmlFor="maxAmountOfUsagesPerAccount">{t(`${usageLimitationFormNs}.max-amount-of-usages-per-account`)}</label>
                </span>
                <span className="mt-4 p-float-label">
                    <Suspense fallback={<ProgressSpinner/>}>
                        <AccountsSelectWithId
                            fieldValue={fieldValue.onlyForAccountIds || []}
                            onChange={accountIds => update({
                                ...fieldValue,
                                onlyForAccountIds: accountIds
                            })}
                            queryFragmentRef={query}/>
                    </Suspense>
                    <label
                        htmlFor="onlyForAccountIds">{t(`${usageLimitationFormNs}.only-for-account-ids`)}</label>
                </span>
                <span className="mt-4 p-float-label">
                    <Suspense fallback={<ProgressSpinner/>}>
                        <ProductsSelect
                            fieldValue={fieldValue.onlyForProductIds || []}
                            onChange={productIds => update({
                                ...fieldValue,
                                onlyForProductIds: productIds
                            })}
                            queryFragmentRef={query}/>
                    </Suspense>
                    <label
                        htmlFor="onlyForProductIds">{t(`${usageLimitationFormNs}.only-for-product-ids`)}</label>
                </span>
            </div>}
    </div>
}
