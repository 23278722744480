import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FieldProps} from "formik";
import React from "react";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import update from "immutability-helper";
import {EntityWrapper, TkFile, WithSelections, WithSelectionsProps} from "@thekeytechnology/framework-react";
import {connect} from "react-redux";
import {OwnProps} from "../../common/WithCourseEditorDraggable";
import {selectChildFiles} from "../../../../selectors";
import {QuestionnaireClass} from "@thekeytechnology/thekey-academy-frontend-library";
import { AttachmentSelect } from "../../../../../core/components/attachment/AttachmentSelect";

interface StateProps {
    childFiles: EntityWrapper<TkFile>[];
}

type Props = FieldProps & WithSelectionsProps<string> & StateProps;

const ClassesFieldComponent = ({field: {name, value}, form, selectionModel, childFiles}: Props) => {
    const {t} = useTranslation("courses")
    const questionnaireClasses = value ? value as QuestionnaireClass[] : [];

    const newClass = () => {
        const newClasses = questionnaireClasses.concat({
            title: "",
            description: "",
            pointsThreshold: 0
        } as QuestionnaireClass);
        form.setFieldValue(name, newClasses);
        form.setFieldTouched(name, true, true);
    };

    const updateTitle = (questionnaireClass: QuestionnaireClass, index: number, updatedTitle: string) => {
        const newState = update(questionnaireClasses, {
            [index]: {
                $set: {
                    title: updatedTitle,
                    description: questionnaireClass.description,
                    pointsThreshold: questionnaireClass.pointsThreshold
                } as QuestionnaireClass
            }
        });
        form.setFieldValue(name, newState);
        form.setFieldTouched(name, true, true);
    };

    const updateDescription = (questionnaireClass: QuestionnaireClass, index: number, description: string) => {
        const newState = update(questionnaireClasses, {
            [index]: {
                $set: {
                    ...questionnaireClass,
                    description,
                } as QuestionnaireClass
            }
        });
        form.setFieldValue(name, newState);
        form.setFieldTouched(name, true, true);
    };

    const updatePointsThreshold = (questionnaireClass: QuestionnaireClass, index: number, pointsThreshold: number) => {
        const newState = update(questionnaireClasses, {
            [index]: {
                $set: {
                    ...questionnaireClass,
                    pointsThreshold
                } as QuestionnaireClass
            }
        });
        form.setFieldValue(name, newState);
        form.setFieldTouched(name, true, true);
    };

    const updateImage = (questionnaireClass: QuestionnaireClass, index: number, image?: string) => {
        const newState = update(questionnaireClasses, {
            [index]: {
                $set: {
                    ...questionnaireClass,
                    imageRef: image
                } as QuestionnaireClass
            }
        });
        form.setFieldValue(name, newState);
        form.setFieldTouched(name, true, true);
    };

    const removeClass = (index: number) => {
        form.setFieldValue(name,
            update(
                questionnaireClasses, {$splice: [[index, 1]]}
            )
        );
        form.setFieldTouched(name, true, true);
    };

    return (
        <div className="questionnaire-classes d-flex flex-column w-100">
            {questionnaireClasses.map((questionnaireClass: QuestionnaireClass, index: number) =>
                <Card key={index}>
                    <Card.Body>
                        <h3 className="d-flex align-items-center">
                            {t("course-editor.questionnaire-classes-field.questionnaire-class", {index: index + 1})}
                            <button onClick={() => removeClass(index)}
                                    type="button"
                                    className="btn btn-link text-danger">
                                <FontAwesomeIcon icon={faTrash}/></button>
                        </h3>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.questionnaire-classes-field.title")}*</label>
                            <div className="col-sm-10">
                                <input
                                    className="form-control default-input"
                                    type="text"
                                    placeholder={t("course-editor.questionnaire-classes-field.title-placeholder")}
                                    value={questionnaireClass.title}
                                    onChange={(event => updateTitle(questionnaireClass, index, event.target.value))}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.questionnaire-classes-field.description")}*</label>
                            <div className="col-sm-10">
                                <input
                                    className="form-control default-input"
                                    type="text"
                                    placeholder={t("course-editor.questionnaire-classes-field.description-placeholder")}
                                    value={questionnaireClass.description}
                                    onChange={(event => updateDescription(questionnaireClass, index, event.target.value))}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.questionnaire-classes-field.image")}</label>
                            <div className="col-sm-10">
                                <AttachmentSelect
                                    selectionModel={selectionModel}
                                    isSingleSelect={true}
                                    value={childFiles.find(file => file.id === questionnaireClass.imageRef)}
                                    changeValue={(newValue => updateImage(questionnaireClass, index, (newValue as EntityWrapper<TkFile> | undefined)?.id))}
                                />
                            </div>
                        </div>


                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.questionnaire-classes-field.points-threshold")}*</label>
                            <div className="col-sm-10">
                                <input
                                    className="form-control default-input"
                                    type="number"
                                    min={0}
                                    placeholder={t("course-editor.questionnaire-classes-field.points-threshold-placeholder")}
                                    value={questionnaireClass.pointsThreshold}
                                    onChange={(event => updatePointsThreshold(questionnaireClass, index, parseInt(event.target.value, 10)))}/>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}
            <Card>
                <Card.Body>
                    <button type="button" className="btn-link text-success" onClick={newClass}>
                        <FontAwesomeIcon className="mr-2"
                                         icon={faPlus}/>{t("course-editor.questionnaire-classes-field.new-class")}
                    </button>
                </Card.Body>
            </Card>
        </div>
    );
};

export const ClassesField = connect<StateProps, {}, OwnProps>(
    (state: any) => ({
        childFiles: selectChildFiles(state)
    })
)(WithSelections(ClassesFieldComponent))
