/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CodeIdColumn_DiscountCodeFragment = {
    readonly rawId: string;
    readonly " $refType": "CodeIdColumn_DiscountCodeFragment";
};
export type CodeIdColumn_DiscountCodeFragment$data = CodeIdColumn_DiscountCodeFragment;
export type CodeIdColumn_DiscountCodeFragment$key = {
    readonly " $data"?: CodeIdColumn_DiscountCodeFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CodeIdColumn_DiscountCodeFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodeIdColumn_DiscountCodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rawId",
      "storageKey": null
    }
  ],
  "type": "DiscountCodeV2",
  "abstractKey": null
};
(node as any).hash = 'a5488640fbe9f75cb3f52a6c31f76e17';
export default node;
