/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SingleUser_QueryVariables = {
    id: string;
};
export type SingleUser_QueryResponse = {
    readonly node: {
        readonly id: string;
        readonly rawId?: string;
        readonly name?: string;
        readonly meta?: {
            readonly " $fragmentRefs": FragmentRefs<"EditAdditionalInformation_UserMetaFragment">;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"UserActions_UserFragment" | "EditUserData_UserFragment">;
    } | null;
};
export type SingleUser_Query = {
    readonly response: SingleUser_QueryResponse;
    readonly variables: SingleUser_QueryVariables;
};



/*
query SingleUser_Query(
  $id: ID!
) {
  node(id: $id) {
    __typename
    id
    ... on User {
      rawId
      name
      meta {
        ...EditAdditionalInformation_UserMetaFragment
        id
      }
      ...UserActions_UserFragment
      ...EditUserData_UserFragment
    }
  }
}

fragment EditAdditionalInformation_UserMetaFragment on UserMeta {
  additionalInformation
}

fragment EditUserData_UserFragment on User {
  id
  name
  email
  activated
}

fragment UserActions_UserFragment on User {
  id
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SingleUser_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditAdditionalInformation_UserMetaFragment"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserActions_UserFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditUserData_UserFragment"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SingleUser_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "additionalInformation",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "activated",
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70786536b6f1b1e98b045ede8b8cc401",
    "id": null,
    "metadata": {},
    "name": "SingleUser_Query",
    "operationKind": "query",
    "text": "query SingleUser_Query(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on User {\n      rawId\n      name\n      meta {\n        ...EditAdditionalInformation_UserMetaFragment\n        id\n      }\n      ...UserActions_UserFragment\n      ...EditUserData_UserFragment\n    }\n  }\n}\n\nfragment EditAdditionalInformation_UserMetaFragment on UserMeta {\n  additionalInformation\n}\n\nfragment EditUserData_UserFragment on User {\n  id\n  name\n  email\n  activated\n}\n\nfragment UserActions_UserFragment on User {\n  id\n  name\n}\n"
  }
};
})();
(node as any).hash = '64bf961749d15bcc00d2ea634010d33e';
export default node;
