import React from "react";
import currency from "currency.js"

interface OwnProps {
    value: number
    className?: string
}

export const CurrencyDisplay = ({value, className}: OwnProps) => {
    return <div className={className}>{currency(value).format({decimal: ",", symbol: ""})} EUR</div>
}
