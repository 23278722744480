import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import React, { useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useDrop } from "react-dnd";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addElementAction } from "../../../actions/element/add-element";
import { DragItem, DroppableProps } from "../common/WithCourseEditorDraggable";
import { DRAGGABLE_TYPES } from "../common/draggable-types";
import Select from "react-select";
import { ElementEditorModal } from "./ElementEditor";
import {
    CourseElement,
    ELEMENT_TYPE_CLOZE,
    ELEMENT_TYPE_IMAGE,
    ELEMENT_TYPE_MULTIPLE_CHOICE,
    ELEMENT_TYPE_ORDER,
    ELEMENT_TYPE_PERSOLOG,
    ELEMENT_TYPE_QUESTIONNAIRE,
    ELEMENT_TYPE_RESOLUTION,
    ELEMENT_TYPE_TEXT,
    ELEMENT_TYPE_VIDEO
} from "@thekeytechnology/thekey-academy-frontend-library";
import { ClearIndicator } from "../../../../core/components/select/ClearIndicator";

interface State {
    selectedType: string;
}

interface OwnProps extends DroppableProps {
    parentModuleId: string;
    parentLessonId: string;
}

interface DispatchProps {
    addElement: typeof addElementAction
}

type Props = OwnProps & DispatchProps & WithTranslation;

const AddElementComponent = (props: Props) => {
    const {addElement, parentModuleId, parentLessonId, dropped, t} = props;

    const ELEMENT_TYPE_OPTIONS = [
        ELEMENT_TYPE_TEXT, ELEMENT_TYPE_MULTIPLE_CHOICE, ELEMENT_TYPE_CLOZE,
        ELEMENT_TYPE_VIDEO, ELEMENT_TYPE_RESOLUTION, ELEMENT_TYPE_ORDER, ELEMENT_TYPE_IMAGE,
        ELEMENT_TYPE_QUESTIONNAIRE, ELEMENT_TYPE_PERSOLOG].map(type => {
        return ({
            value: type,
            label: t(`course-editor.element-types.${type}`)
        })
    });

    const [state, setType] = useState<State>({
        selectedType: ELEMENT_TYPE_TEXT
    });

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const ref = useRef<HTMLDivElement>(null);

    const [{isOver, canDrop}, drop] = useDrop({
        accept: DRAGGABLE_TYPES.ELEMENT,
        drop(item: DragItem) {
            dropped(item.index, 0, item.dragMeta);
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const dropMightHappen = canDrop && isOver;
    drop(ref);

    return (
        <div ref={ref}>
            <Card>
                <Card.Header
                    className={"d-flex flex-row justify-content-center align-items-center" + (dropMightHappen ? " drop-might-happen" : "")}>
                    <Select
                        className="react-select mr-4 w-75"
                        classNamePrefix="react-select"
                        components={{ClearIndicator}}
                        placeholder={""}
                        options={ELEMENT_TYPE_OPTIONS}
                        value={ELEMENT_TYPE_OPTIONS.find(o => o.value === state.selectedType)}
                        onChange={(item: any) => setType({selectedType: item.value})}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={item => item.label}
                    />
                    <button type="button" className="btn-link text-success w-25 text-center" onClick={handleShow}>
                        <FontAwesomeIcon className="mr-2" icon={faPlus}/>{t("course-editor.add-element.new")}
                    </button>
                </Card.Header>
            </Card>

            {show ? <ElementEditorModal
                elementType={state.selectedType}
                show={show}
                handleClose={handleClose}
                handleSubmit={(editedElement: EntityWrapper<CourseElement>) => {
                    addElement(
                        parentModuleId,
                        parentLessonId,
                        editedElement
                    );
                    handleClose();
                }}/> : null}
        </div>
    );
};

export const AddElement = connect<{}, DispatchProps, {}>(
    null,
    {
        addElement: addElementAction
    }
)(withTranslation("courses")(AddElementComponent));
