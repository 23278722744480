import Select from "react-select";
import { replaceInArray } from "../../../../../core/utils/utils";
import { ApplicationRule, LimitForUsersApplicationRule } from "../../../../../licenses/model/ApplicationRule";
import React from "react";
import { useTranslation } from "react-i18next";
import { FieldProps } from "formik";
import { TableColumn, TableRow } from "../../../../../core/components/table/Table";
import { ClearIndicator } from "../../../../../core/components/select/ClearIndicator";

const ACCOUNT_TYPES = ["master", "slaves"]
const USER_ROLES = ["admin", "user"]

interface OwnProps {
    index: number
    items: ApplicationRule[]
    item: LimitForUsersApplicationRule
}

type Props = OwnProps & FieldProps

export const LimitForUserApplicationRuleRow = ({index, item, items, form, field}: Props) => {
    const {t} = useTranslation("admin")

    const ACCOUNT_TYPE_OPTIONS = ACCOUNT_TYPES.map(a => ({
        value: a,
        label: t("entity.license-pool.labels.application-rules-field.account-types." + a)
    }))
    const USER_ROLE_OPTIONS = USER_ROLES.map(a => ({
        value: a,
        label: t("entity.license-pool.labels.application-rules-field.user-roles." + a)
    }))

    return <TableRow className="course-offer-item-row">
        <TableColumn>
            Für Nutzer
        </TableColumn>
        <TableColumn>
            <label className="d-flex flex-column w-100">
                <div>{t("entity.license-pool.labels.application-rules-field.limit-account-type")}</div>
                <Select
                    className="react-select category-select"
                    classNamePrefix="react-select"
                    components={{ClearIndicator}}
                    isClearable={true}
                    placeholder={t("entity.license-pool.labels.application-rules-field.limit-account-type")}
                    options={ACCOUNT_TYPE_OPTIONS}
                    value={ACCOUNT_TYPE_OPTIONS.find(o => o.value === item.limitToAccountType)}
                    onChange={(newValue: any) => {
                        form.setFieldValue(
                            field.name,
                            replaceInArray(
                                items,
                                index,
                                {
                                    ...item,
                                    limitToAccountType: newValue.value
                                } as ApplicationRule)
                        )
                    }}
                    onInputChange={() => {
                        form.setTouched({
                            [field.name]: true
                        })
                    }}
                    onMenuOpen={() => {
                        form.setTouched({
                            [field.name]: true
                        })
                    }}
                    getOptionValue={(opt: any) => opt.value}
                    getOptionLabel={item => item.label}
                    onBlur={field.onBlur}
                />
            </label>
        </TableColumn>
        <TableColumn>
            <label className="d-flex flex-column w-100">
                <div>{t("entity.license-pool.labels.application-rules-field.limit-user-role")}</div>
                <Select
                    className="react-select category-select"
                    classNamePrefix="react-select"
                    components={{ClearIndicator}}
                    isClearable={true}
                    placeholder={t("entity.license-pool.labels.application-rules-field.limit-user-role")}
                    options={USER_ROLE_OPTIONS}
                    value={USER_ROLE_OPTIONS.find(o => o.value === item.limitToUserRole)}
                    onChange={(newValue: any) => {
                        form.setFieldValue(
                            field.name,
                            replaceInArray(
                                items,
                                index,
                                {
                                    ...item,
                                    limitToUserRole: newValue.value
                                } as ApplicationRule)
                        )
                    }}
                    onInputChange={() => {
                        form.setTouched({
                            [field.name]: true
                        })
                    }}
                    onMenuOpen={() => {
                        form.setTouched({
                            [field.name]: true
                        })
                    }}
                    getOptionValue={(opt: any) => opt.value}
                    getOptionLabel={item => item.label}
                    onBlur={field.onBlur}
                />
            </label>
        </TableColumn>
        <TableColumn>
            <label className="d-flex flex-column w-100">
                <div>{t("entity.license-pool.labels.application-rules-field.limit-amount")}</div>
                <input type="number"
                       className="form-control default-input"
                       step={1}
                       min={1}
                       value={item.limitAmount}
                       onBlur={field.onBlur}
                       onChange={event => {
                           form.setFieldValue(
                               field.name,
                               replaceInArray(
                                   items,
                                   index,
                                   {
                                       ...item,
                                       limitAmount: parseFloat(event.target.value)
                                   } as ApplicationRule)
                           )
                       }}/>
            </label>
        </TableColumn>
        <TableColumn>
            <label className="mt-3 d-flex align-items-center">
                <input
                    type="checkbox"
                    className="mr-3 position-relative"
                    checked={item.canRequestMore}
                    onBlur={field.onBlur}
                    onChange={event => {
                        form.setFieldValue(
                            field.name,
                            replaceInArray(
                                items,
                                index,
                                {
                                    ...item,
                                    canRequestMore: event.target.checked
                                } as ApplicationRule)
                        )
                    }}/>
                <span>{t("entity.license-pool.labels.application-rules-field.can-request-more")}</span>
            </label>
        </TableColumn>
        <TableColumn>
            <button type="button" className="btn btn-danger" onClick={() => {
                form.setFieldValue(
                    field.name,
                    items.filter((i, idx) => idx !== index)
                )
            }}>
                {t("entity.license-pool.labels.application-rules-field.remove")}
            </button>
        </TableColumn>
    </TableRow>
}
