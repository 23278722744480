import { EditedCourseState } from "../../edited-course";
import update from "immutability-helper";
import { EditLessonAction } from "../../../actions/lesson/edit-lesson";
import { equalById } from "../common/helpers";

export function editLesson(state: EditedCourseState, {lesson}: EditLessonAction) {
    const editLessonIndex = state.editedCourse!.entity.lessons.findIndex(l => equalById(l, lesson));

    return update(state,
        {
            hasUnsavedChanges: {$set: true},
            editedCourse: {entity: {lessons: {[editLessonIndex]: {$set: lesson}}}}
        }
    )
}
