import { FieldProps } from "formik";
import React from "react";
import { WithTranslation } from "react-i18next";
import { MultipleTexts } from "./MultipleTexts";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface OwnProps {
    innerIcon?: IconDefinition;
    placeholder: string;
    removeTooltip: string;
    addTooltip: string
}

type Props = OwnProps & FieldProps & WithTranslation;

export const MultipleTextField = (props: Props) => {
    const {form, field, removeTooltip, placeholder, addTooltip, innerIcon} = props;

    return (
        <MultipleTexts
            icon={innerIcon}
            currentTexts={field.value ? field.value : []}
            updatedTexts={(updatedTexts?: string[]) => {
                if (updatedTexts) {
                    form.setFieldValue(field.name, updatedTexts);
                } else {
                    form.setFieldValue(field.name, undefined);
                }
            }}
            inputFieldPlaceholder={placeholder}
            removeTooltip={removeTooltip}
            addTooltip={addTooltip}
        />
    );
};
