import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../core/components/containers/Container";
import { Expert } from "../model/expert";
import { ExpertFilters } from "./ExpertFilters";
import { ExpertTable } from "./ExpertTable";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const ExpertOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/experts"}>{t("entity.expert.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.expert.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <ExpertFilters/>
            </BackgroundContainer>
            <ContentContainer>
                <ExpertTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={Expert.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const ExpertOverview = withTranslation("expert")(ExpertOverviewComponent);
