import React from 'react'
import {useRefetchableFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {MultiSelect} from "primereact/multiselect";
import {AccountsSelect_QueryFragment$key} from "../../../../__generated__/AccountsSelect_QueryFragment.graphql";
import {AccountSelect_QueryFragmentRefetchQuery} from "../../../../__generated__/AccountSelect_QueryFragmentRefetchQuery.graphql";
import {useTranslation} from "react-i18next";

const NUM_ACCOUNTS_PER_PAGE = 20

const QUERY_FRAGMENT = graphql`
    fragment AccountsSelect_QueryFragment on Query @refetchable(queryName: "AccountSelect_QueryFragmentRefetchQuery") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String", defaultValue: null},
        name: {type: "String", defaultValue: null},
        ids: {type :"[ID!]!"}
    ) {
        Admin {
            Auth {
                AccountsMultiselect(first: $first, after: $after, nameOpt: $name, ids: $ids) @connection(key: "AccountSelect_AccountsMultiselect") {
                    edges {
                        cursor
                        node {
                            id
                            ... on Account {
                                name
                            }
                        }
                    }
                }
            }
        }
    }`

export interface Account {
    id: string
    name: string
}

interface OwnProps {
    fieldValue: Account[]
    onChange: (accounts: Account[]) => void
    queryFragmentRef: AccountsSelect_QueryFragment$key
}

export const AccountsSelect = ({
                                   fieldValue,
                                   onChange,
                                   queryFragmentRef
                               }: OwnProps) => {

    const {t} = useTranslation("core")

    const [data, refetch] = useRefetchableFragment<AccountSelect_QueryFragmentRefetchQuery, AccountsSelect_QueryFragment$key>(QUERY_FRAGMENT, queryFragmentRef)

    const accounts = data.Admin.Auth.AccountsMultiselect.edges?.filter(e => !!e).map(e => e!.node) || []

    const onFilter = (e: { filter: string }) => {
        refetch({name: e.filter, first: NUM_ACCOUNTS_PER_PAGE, ids: fieldValue.map(a => a.id)})
    }

    return <MultiSelect value={fieldValue} options={accounts}
                        optionLabel="name"
                        onChange={(e) => onChange(e.value)}
                        placeholder={t("entity-selects.placeholder")} filter
                        resetFilterOnHide={true}
                        filterPlaceholder={t("entity-selects.filter-placeholder")}
                        className="multiselect-custom"
                        onFilter={onFilter} showSelectAll={false}
                        emptyFilterMessage={t("entity-selects.no-results")}
    />
}
