import React from "react";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";

interface OwnProps {
    hasNext: boolean
    loadNext: () => void
}

export const PaginationButton = ({hasNext, loadNext}: OwnProps) => {
    const {t} = useTranslation("core")

    return hasNext ? <div className="flex pt-4 justify-content-center">
        <Button label={t("pagination-button.load-more")} onClick={loadNext}/>
    </div> : null
}
