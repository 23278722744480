import {
    ApiCallAction,
    defaultResponseHandling,
    matchesApiCall, selectApiBase,
    selectAuthState,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { combineEpics, StateObservable } from "redux-observable";
import { from, interval, Observable } from "rxjs";
import { debounce, mergeMap, withLatestFrom } from "rxjs/operators";
import { API_FETCH_VIDEO_DATA, FetchVideoDataPayload } from "../actions/element/fetch-video-data";

const fetchVideoData = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    matchesApiCall(API_FETCH_VIDEO_DATA),
    debounce(() => interval(2000)),
    withLatestFrom(state$),
    mergeMap(([action, state]: [ApiCallAction<FetchVideoDataPayload>, any]) =>
        from(withAuthedAxios(
            selectApiBase(state),
            selectAuthState(state)
        ).get(`/courses/video-data/${action.payload.videoId}`))
            .pipe(
                defaultResponseHandling(action.apiQualifier)
            )
    ));

export const videoDataEpics = combineEpics(
    fetchVideoData
)
