import { apiGet } from "@thekeytechnology/framework-react";
import { combineEpics } from "redux-observable";
import { API_FETCH_TRIGGER_DEFINITIONS } from "../actions/fetch-trigger-definitions";

const fetchTriggerDefinitions$ = apiGet(
    {apiType: API_FETCH_TRIGGER_DEFINITIONS},
    "/triggers/definitions"
)

export const triggersModuleEpic$ = combineEpics(
    fetchTriggerDefinitions$,
);
