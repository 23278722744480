import React from "react";
import {useTranslation} from "react-i18next";
import {Column} from "primereact/column";
import {useHistory} from "react-router-dom";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {truncateBeginningOfText} from "../../../core/utils/utils";

interface OwnProps {
    items: {id: string, internalTitle: string}[]
    deleteCustomerMessageDefinitionV2: (internalTitle: string, id: string) => void
}

export const CustomerMessageDefinitionV2Table = ({items, deleteCustomerMessageDefinitionV2}: OwnProps) => {
    const {t: tCMV2} = useTranslation("customerMessagesV2")

    const history = useHistory()

    return <DataTable className="p-mb-2" value={items} emptyMessage={tCMV2("overview.table.no-items")} dataKey="id">
        <Column header={tCMV2("overview.table.internal-title")} field="internalTitle"/>
        <Column header={tCMV2("overview.table.id")} body={item => truncateBeginningOfText(item.id, 30)}/>
        <Column header={tCMV2("overview.table.actions")}
                body={
                    item => <>
                        <Button icon={"pi pi-pencil"} onClick={() => history.push(`/customer-message-v2/${item.id}`)}/>
                        <Button icon={"pi pi-send"} onClick={() => history.push(`/customer-message-v2/${item.id}/send`)}
                                className={"ml-2"}/>
                        <Button onClick={() => deleteCustomerMessageDefinitionV2(item.internalTitle, item.id)}
                                icon={"pi pi-trash"} className={"ml-2"}/>
                    </>
                }/>
    </DataTable>
}