import {ConnectionHandler, useFragment, useLazyLoadQuery, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import {EditProductScreen_CreateMutation} from "../../../../../__generated__/EditProductScreen_CreateMutation.graphql";
import {EditProductForm} from "./EditProductForm";
import {EditProductScreen_ProductFragment$key} from "../../../../../__generated__/EditProductScreen_ProductFragment.graphql";
import {EditProductScreen_UpdatingMutation} from "../../../../../__generated__/EditProductScreen_UpdatingMutation.graphql";
import {useRouteMatch} from "react-router";
import {EditProductScreen_Query} from "../../../../../__generated__/EditProductScreen_Query.graphql";
import {useHistory} from "react-router-dom";

const QUERY = graphql`
    query EditProductScreen_Query($id: ID!, $skip: Boolean!) {
        node(id: $id) @skip(if: $skip){
            ... on Product {
                ...EditProductScreen_ProductFragment
            }
        }
    }
`;

const PRODUCT_FRAGMENT = graphql`
    fragment EditProductScreen_ProductFragment on Product {
        id,
        title,
        isHidden,
        price {
            netPrice,
            grossPrice,
            taxRatePercentage
        },
        ... on CourseProduct {
            courseRef
        }
    }
`;

const CREATE_MUTATION = graphql`
    mutation EditProductScreen_CreateMutation($input: CreateCourseProductInput!, $connections: [ID!]!) {
        Admin {
            Billing {
                createCourseProduct(input: $input) {
                    data @appendEdge(connections: $connections){
                        node  {
                            id
                            ... on CourseProduct {

                                title
                            }
                        }
                    }
                }
            }
        }
    }
`;

const UPDATE_MUTATION = graphql`
    mutation EditProductScreen_UpdatingMutation($input: UpdateCourseProductInput!) {
        Admin {
            Billing {
                updateCourseProduct(input: $input) {
                    data {
                        node {
                            id
                            ... on CourseProduct {
                                title
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const EditProductScreen = () => {
    const {params: {productId}} = useRouteMatch<{ productId: string }>()
    const history = useHistory()

    const query = useLazyLoadQuery<EditProductScreen_Query>(QUERY, {
        id: productId,
        skip: !productId || productId === "new"
    })


    const product = useFragment<EditProductScreen_ProductFragment$key>(PRODUCT_FRAGMENT, query.node || null)
    const [create, isCreating] = useMutation<EditProductScreen_CreateMutation>(CREATE_MUTATION)
    const [update, isUpdating] = useMutation<EditProductScreen_UpdatingMutation>(UPDATE_MUTATION)

    return <div>
        <h1 className="mb-3">Bearbeite Produkt</h1>
        <EditProductForm
            onSubmit={submittedData => {
                if (product) {
                    update({
                        variables: {
                            input: {
                                id: product.id!,
                                data: {
                                    price: {
                                        grossPrice: submittedData.price!.grossPrice,
                                        netPrice: submittedData.price!.netPrice,
                                        taxRatePercentage: submittedData.price!.taxRatePercentage
                                    },
                                    title: submittedData.title!,
                                    courseRef: submittedData.courseRef!,
                                    isHidden: submittedData.isHidden!,
                                    productType: "courseProduct"
                                }

                            }
                        },
                        onCompleted: () => history.push("/products")
                    })
                } else {
                    create({
                        variables: {
                            input: {
                                data: {
                                    price: {
                                        grossPrice: submittedData.price!.grossPrice,
                                        netPrice: submittedData.price!.netPrice,
                                        taxRatePercentage: submittedData.price!.taxRatePercentage
                                    },
                                    title: submittedData.title!,
                                    isHidden: submittedData.isHidden!,
                                    courseRef: submittedData.courseRef!,
                                    productType: "courseProduct"
                                }
                            },
                            connections: [
                                ConnectionHandler.getConnectionID("client:root:Admin:Billing", "ProductsTable_Products")
                            ]
                        },
                        onCompleted: () => history.push("/products")
                    })
                }
            }}
            loading={isCreating || isUpdating}
            initialValues={{
                title: product?.title,
                price: product?.price,
                courseRef: product?.courseRef,
                isHidden: product?.isHidden
            }}
        />
    </div>

}
