import React from "react";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {EditCodeButton} from "../../buttons/EditCodeButton";
import {DeleteCodeButton} from "../../buttons/DeleteCodeButton";
import {
    CodeActionColumn_DiscountCodeFragment$key
} from "../../../../../../../__generated__/CodeActionColumn_DiscountCodeFragment.graphql";

const DISCOUNT_CODE_FRAGMENT = graphql`
    fragment CodeActionColumn_DiscountCodeFragment on DiscountCodeV2 {
        id
    }
`

interface OwnProps {
    discountActionId: string
    connectionId: string
    discountCodeFragmentRef: CodeActionColumn_DiscountCodeFragment$key
}

export const CodeActionColumn = ({discountActionId, connectionId, discountCodeFragmentRef}: OwnProps) => {
    const discountCode = useFragment<CodeActionColumn_DiscountCodeFragment$key>(DISCOUNT_CODE_FRAGMENT, discountCodeFragmentRef)

    return <div>
        <EditCodeButton discountActionId={discountActionId} codeId={discountCode.id}/>
        <DeleteCodeButton className="ml-2" id={discountCode.id} connectionId={connectionId}/>
    </div>
}
