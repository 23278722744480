import React from "react";
import { ENTITY_TYPE_ACCOUNT, Filter } from "@thekeytechnology/framework-react";
import { useTranslation } from "react-i18next";
import { UnconnectedFilters } from "../../../core/components/filters/Filters";
import { UnconnectedTextFilter } from "../../../core/components/filters/TextFilter";

interface OwnProps {
    setFilters: (filters: Filter[]) => void,
    removeFilters: (filterKeys: string[]) => void
    reloadEntities: () => void
    filters: Filter[]
}

export const AccountFilters = ({
                                   removeFilters,
                                   reloadEntities,
                                   setFilters,
                                   filters
                               }: OwnProps) => {
    const {t} = useTranslation("admin")
    return (
        <UnconnectedFilters
            reloadEntities={() => {
                reloadEntities()
                return null as any
            }}
            removeFilters={(filterKeys) => {
                removeFilters(filterKeys)
                return null as any
            }}
            entityType={ENTITY_TYPE_ACCOUNT}
            filterKeys={[`entity.name`]}>
            <UnconnectedTextFilter
                reloadEntities={() => {
                    reloadEntities()
                    return null as any
                }}
                removeFilters={(filterKeys) => {
                    removeFilters(filterKeys)
                    return null as any
                }}
                addFilters={(key, filters) => {
                    setFilters(filters)
                    return null as any
                }}
                currentFilters={filters}
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.accounts.labels.name")})}
                entityType={ENTITY_TYPE_ACCOUNT}
                property={`entity.name`}/>
        </UnconnectedFilters>
    );
};

