import { AuthState, selectApiBase, selectAuthState, withAuthedAxios, } from "@thekeytechnology/framework-react";
import { FieldProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { WysiwygField } from "./WysiwygField";

interface StateProps {
    apiBase: string;
    authState: AuthState;
}

type Props = StateProps & FieldProps & WithTranslation;

const WysiwygFieldWithUploadComponent = ({
                                             form,
                                             field,
                                             apiBase,
                                             authState,
                                             i18n,
                                             meta
                                         }: Props) => {

    // noinspection JSUnusedGlobalSymbols
    return <WysiwygField meta={meta} field={field} form={form} language={i18n.language} config={{
        automatic_uploads: true,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: false,
        images_upload_handler: (blobInfo: { blob: () => string | Blob; fileName: string | undefined; }, success: (arg0: string) => void, failure: (arg0: any) => void, progress: (arg0: number) => void) => {
            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.fileName);

            const axios = withAuthedAxios(apiBase, authState)
            axios.post("/files/upload", formData, {
                onUploadProgress: e => {
                    if (e.lengthComputable) {
                        progress(Math.round((e.loaded * 100) / e.total))
                    }
                }
            }).then(response => {
                success(`${apiBase}/files/download?authToken=${authState.token}&accountId=${authState.currentAccount?.accountId}&fileId=${response.data.fileId}`);
            }).catch(thrown => {
                failure(thrown);
            });
        },
        urlconverter_callback: (url: string) => {
            if (url.startsWith("tkfile-")) {
                const fileId = url.replace("tkfile-", "");
                return `"${apiBase}/files/download?authToken=${authState.token}&accountId=${authState.currentAccount?.accountId}&fileId=${fileId}"`;
            }
            return url;
        }
    }}/>
};

export const WysiwygFieldWithUpload = connect<StateProps, {}, {}>(
    (state: any) => ({
        apiBase: selectApiBase(state),
        authState: selectAuthState(state)
    }))
(withTranslation()(WysiwygFieldWithUploadComponent));
