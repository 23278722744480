import { createSelector } from "reselect";
import { AppState } from "../../reducers";
import { EmailModuleState } from "../reducers";

export const selectTriggersModuleState = (state: AppState) => state.triggers;

export const selectTriggerDefinitions = createSelector(
    selectTriggersModuleState,
    (moduleState: EmailModuleState) =>
        moduleState.triggerDefinitions
);
