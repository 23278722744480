import { SelectionModel } from "@thekeytechnology/framework-react";
import React from "react";

import { SelectInModelCheckbox } from "../selection/SelectInModelCheckbox";

import "./table.scss"

interface TableProps {
    className?: string;
    itemCount: number;
    children: any;
}

export const Table = (props: TableProps) => (
    <div className={"table " + (props.className ? props.className : "") + (props.itemCount === 0 ? " empty" : "")}>
        {props.children}
    </div>
);

export const TableHeader = (props: any) => (
    <div className="table-header">
        <div className="table-row pt-3 pb-3">
            {props.children}
        </div>
    </div>
);

export const TableBody = (props: any) => (
    <div className="table-body">
        {props.children}
    </div>
);

interface TableColumnProps {
    className?: string;
    children: any;
}

export const TableColumn = (props: TableColumnProps) => (
    <div className={"table-column " + (props.className ? props.className : "")}>
        {props.children}
    </div>
);

interface TableRowProps {
    className?: string;
    children: any;
}

export const TableRow = (props: TableRowProps) => (
    <div className={"table-row pt-3 pb-3 " + (props.className ? props.className : "")}>
        {props.children}
    </div>
);

interface SelectionTableColumnProps<ItemType> {
    className?: string;
    item: ItemType;
    selectionModel: SelectionModel<ItemType>;
    disableSelection?: boolean;
}

export function SelectionTableColumn<ItemType>(props: SelectionTableColumnProps<ItemType>) {
    return <TableColumn className={"selection-column" + (props.className ? props.className : "")}>
        {props.disableSelection ? null :
            <SelectInModelCheckbox item={props.item} selectionModel={props.selectionModel}/>
        }
    </TableColumn>;
}
