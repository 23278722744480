import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import {CourseBundleScreen_CourseBundlesQuery} from "../../../__generated__/CourseBundleScreen_CourseBundlesQuery.graphql";
import {CourseBundleTable} from "./CourseBundleTable";

export const CourseBundleScreen = () => {
    const history = useHistory();

    const data = useLazyLoadQuery<CourseBundleScreen_CourseBundlesQuery>(graphql`
        query CourseBundleScreen_CourseBundlesQuery {
            ...CourseBundleTable_CourseBundleList
        }
    `, {}, {fetchPolicy: "network-only"})

    return <div>
        <h1 className="mb-3">Bundles</h1>

        <div className="p-mb-3 p-justify-end p-d-flex">
            <Button onClick={() => history.push({pathname: "/course-bundles/new/edit"})}>
                Neu
            </Button>
        </div>
        <CourseBundleTable courseBundleListFragmentRef={data}/>
    </div>

}
