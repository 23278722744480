import React from "react";

interface SimpleHeaderProps {
    heading: string;
}

export const SimpleHeader = (props: SimpleHeaderProps) => {
    return (
        <div className="overview-header pt-2 pb-2">
            <h1>{props.heading}</h1>
        </div>
    );
};
