import {EntityWrapper, Filter, PropertyFilter} from "@thekeytechnology/framework-react";
import { FieldProps } from "formik";
import React from "react";
import { AsyncEntitySelect, DEFAULT_FETCH_FUNCTION_FACTORY, FetchFunctionFactory } from "./AsyncEntitySelect";

interface EntitySelectFilterProps<T> {
    shownEntityType: string;
    shownEntityTypeProperties: string[];
    customShownEntityTypePropertyFilterCallback: ((prop: string) => PropertyFilter) | undefined;
    additionalFilters?: Filter[];
    shownEntityTypeContext?: string;

    listRenderer: (item: EntityWrapper<T>) => string;
    placeholder: string;
    isMulti?: boolean;
    isClearable?: boolean;
    disabled?: boolean;
    fetchFunctionFactory?: FetchFunctionFactory
}

type Props<T> = EntitySelectFilterProps<T> & FieldProps;

export function AsyncEntitySelectField<T>({
                                              placeholder,
                                              shownEntityTypeProperties,
                                              customShownEntityTypePropertyFilterCallback,
                                              shownEntityType,
                                              listRenderer,
                                              form,
                                              field,
                                              isMulti,
                                              isClearable,
                                              additionalFilters,
                                              disabled,
                                              fetchFunctionFactory = DEFAULT_FETCH_FUNCTION_FACTORY,
                                              shownEntityTypeContext,
                                              ...rest
                                          }: Props<T>) {

    const currentData = field.value ? field.value : undefined;
    return <AsyncEntitySelect<T> shownEntityType={shownEntityType}
                                 shownEntityTypeProperties={shownEntityTypeProperties}
                                 selected={currentData}
                                 additionalFilters={additionalFilters}
                                 select={(newValue: any) => form.setFieldValue(field.name, newValue)}
                                 listRenderer={listRenderer}
                                 placeholder={placeholder}
                                 isMulti={isMulti}
                                 isClearable={isClearable}
                                 onInputChange={() => form.setFieldTouched(field.name, true)}
                                 onBlur={field.onBlur}
                                 onMenuClose={() => form.setFieldTouched(field.name, true)}
                                 disabled={disabled}
                                 fetchFunctionFactory={fetchFunctionFactory}
                                 shownEntityTypeContext={shownEntityTypeContext}
                                 customShownEntityTypePropertyFilterCallback={customShownEntityTypePropertyFilterCallback}
                                 {...rest}
    />;
}
