import { TkFile, WithEntityListProps, WithSelectionsProps } from "@thekeytechnology/framework-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { AboveTableContainer } from "../containers/Container";

import "./files-table.scss"
import { PaginationInfo } from "../pagination/PaginationInfo";
import { EntityDeletionButton } from "../button/EntityDeletionButton";
import { Table, TableColumn } from "../table/Table";
import { EntityTableHeader } from "../table/EntityTableHeader";
import { AsyncEntityTableBody } from "../table/AsyncEntityTableBody";
import { EntityTableRow } from "../table/EntityTableRow";
import { DateTimeDisplay } from "../datetime/DateTimeDisplay";

interface OwnProps {
    onSelection: (values: string[]) => void;
}

type Props = WithEntityListProps<TkFile> & WithSelectionsProps<string> & OwnProps;

export const FilesTableWithExternalEntitiesAndSelections = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        onSelection
    } = props;

    const {t} = useTranslation("files");

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={TkFile.TYPE} className="mr-auto"/>
                <EntityDeletionButton entityType={TkFile.TYPE}
                                      selectedEntities={selectionModel.selections}
                                      label={t("entity.singular")}
                                      labelPlural={t("entity.plural")}
                />
                <button type="button" className="btn btn-primary ml-2" onClick={
                    () => onSelection(selectionModel.selections)
                }>
                    {t("browse-file-modal.submit")}
                </button>
            </AboveTableContainer>
            <Table itemCount={entities.length} className="files-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("files-table.columns.name.heading")}</TableColumn>
                    <TableColumn>{t("files-table.columns.uploaded-at.heading")}</TableColumn>
                    <TableColumn>{t("files-table.columns.type.heading")}</TableColumn>
                    <TableColumn>
                        {t("files-table.columns.preview.heading")}
                    </TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody apiCallState={entityApiState}
                                      items={entities}
                                      renderItem={file =>
                                          <EntityTableRow entity={file} selectionModel={selectionModel} key={file.id}>
                                              <TableColumn className="bold">
                                                  <div className="name-field" title={file.entity.name}>
                                                      {file.entity.name}
                                                  </div>
                                              </TableColumn>
                                              <TableColumn>
                                                  <DateTimeDisplay dateTime={file.entity.uploadDateTime}/>
                                              </TableColumn>
                                              <TableColumn>
                                                  {file.entity.fileType}
                                              </TableColumn>
                                              <TableColumn>
                                                  <a href={file.entity.url} target="_blank" rel="noopener noreferrer">
                                                      {file.entity.thumbnailUrl ?
                                                          <img alt=""
                                                               src={file.entity.thumbnailUrl}/> : <>{t("files-table.columns.preview.no-preview")}</>}
                                                  </a>
                                              </TableColumn>
                                          </EntityTableRow>
                                      }/>
            </Table>
        </>
    );
};
