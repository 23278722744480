import { Parameter } from "../../core/model/trigger/parameter";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { User } from "../../admin/model/User";

export const ENTITY_TYPE_TRIGGER_EXECUTION = "aggregated-trigger-execution"

export interface TriggerExecution {
    user: EntityWrapper<User>,
    triggerKey: string,
    parameters: Parameter[],
    variables: { [key: string]: string },
    customDelayInHours?: number
    wasExecuted: boolean,
    createdAt: string,
    executedAt: string
}
