import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";

interface ModalHeaderProps {
    title: string;
    Lead?: any;
    returnPath?: string;
    onClose?: () => void;
}

export const ModalHeader = (props: ModalHeaderProps) => {
    const {title, Lead, returnPath, onClose} = props;
    return (
        <>
            <div className="d-flex flex-column">
                <h2 className={Lead ? "mb-2" : "mb-0"}>{title}</h2>
                {Lead ? <p className="lead m-0">
                    {Lead}
                </p> : null}
            </div>
            {returnPath ? <NavLink to={returnPath}>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <FontAwesomeIcon icon={faTimes}/>
                </button>
            </NavLink> : null}
            {onClose ?
                <button type="button" onClick={onClose} className="close" data-dismiss="modal" aria-label="Close">
                    <FontAwesomeIcon icon={faTimes}/>
                </button> : null}

        </>
    );
};
