/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateCourseBundleInput = {
    data: EditCourseBundle;
    id: string;
    clientMutationId?: string | null;
};
export type EditCourseBundle = {
    title: string;
    bundleCourses: Array<string>;
    finalCourse: string;
};
export type EditCourseBundleScreen_UpdateMutationVariables = {
    input: UpdateCourseBundleInput;
};
export type EditCourseBundleScreen_UpdateMutationResponse = {
    readonly Admin: {
        readonly Bundle: {
            readonly updateCourseBundle: {
                readonly data: {
                    readonly node: {
                        readonly " $fragmentRefs": FragmentRefs<"EditCourseBundleScreen_CourseBundleFragment">;
                    };
                };
            } | null;
        };
    };
};
export type EditCourseBundleScreen_UpdateMutation = {
    readonly response: EditCourseBundleScreen_UpdateMutationResponse;
    readonly variables: EditCourseBundleScreen_UpdateMutationVariables;
};



/*
mutation EditCourseBundleScreen_UpdateMutation(
  $input: UpdateCourseBundleInput!
) {
  Admin {
    Bundle {
      updateCourseBundle(input: $input) {
        data {
          node {
            ...EditCourseBundleScreen_CourseBundleFragment
            id
          }
        }
      }
    }
  }
}

fragment EditCourseBundleScreen_CourseBundleFragment on CourseBundle {
  id
  title
  bundleCourses {
    id
    containerMeta {
      title
    }
  }
  finalCourseId
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCourseBundleScreen_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BundleAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Bundle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateCourseBundlePayload",
                "kind": "LinkedField",
                "name": "updateCourseBundle",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseBundlesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseBundle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditCourseBundleScreen_CourseBundleFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditCourseBundleScreen_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BundleAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Bundle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateCourseBundlePayload",
                "kind": "LinkedField",
                "name": "updateCourseBundle",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseBundlesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseBundle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Course",
                            "kind": "LinkedField",
                            "name": "bundleCourses",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContainerMeta",
                                "kind": "LinkedField",
                                "name": "containerMeta",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "finalCourseId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bed78a854f3ed9b62afd61cfbc8046e8",
    "id": null,
    "metadata": {},
    "name": "EditCourseBundleScreen_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation EditCourseBundleScreen_UpdateMutation(\n  $input: UpdateCourseBundleInput!\n) {\n  Admin {\n    Bundle {\n      updateCourseBundle(input: $input) {\n        data {\n          node {\n            ...EditCourseBundleScreen_CourseBundleFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditCourseBundleScreen_CourseBundleFragment on CourseBundle {\n  id\n  title\n  bundleCourses {\n    id\n    containerMeta {\n      title\n    }\n  }\n  finalCourseId\n}\n"
  }
};
})();
(node as any).hash = '60996d8e7d2f5d022a2274f437c259dc';
export default node;
