import {
    API_CREATE_ENTITIES,
    API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import { combineEpics } from "redux-observable";
import { ENTITY_TYPE_TRIGGERED_ACHIEVEMENT_FOR_SAVING } from "../model/triggered-achievement";
import { redirectAfterApiSuccesses } from "../../core/epics/redirects";

const redirectOnAchievementSave$ = redirectAfterApiSuccesses(
    [
        {apiType: API_UPDATE_ENTITIES, callType: ENTITY_TYPE_TRIGGERED_ACHIEVEMENT_FOR_SAVING},
        {apiType: API_CREATE_ENTITIES, callType: ENTITY_TYPE_TRIGGERED_ACHIEVEMENT_FOR_SAVING}
    ], "/achievements")

const reloadOnDelete = reloadOnEntityApiOperation(
    API_DELETE_ENTITIES,
    ENTITY_TYPE_TRIGGERED_ACHIEVEMENT_FOR_SAVING
)

export const achievementModuleEpics$ = combineEpics(
    redirectOnAchievementSave$,
    reloadOnDelete,
);
