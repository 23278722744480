/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import ProductsTable_Refetch from "./ProductsTable_Refetch.graphql";
import { FragmentRefs } from "relay-runtime";
export type ProductsTable_ProductsList = {
    readonly Admin: {
        readonly Billing: {
            readonly Products: {
                readonly __id: string;
                readonly pageInfo: {
                    readonly endCursor: string | null;
                    readonly hasPreviousPage: boolean;
                    readonly hasNextPage: boolean;
                    readonly startCursor: string | null;
                };
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly title?: string;
                        readonly price?: {
                            readonly netPrice: number;
                            readonly grossPrice: number;
                        };
                    };
                } | null> | null;
            };
        };
    };
    readonly " $refType": "ProductsTable_ProductsList";
};
export type ProductsTable_ProductsList$data = ProductsTable_ProductsList;
export type ProductsTable_ProductsList$key = {
    readonly " $data"?: ProductsTable_ProductsList$data;
    readonly " $fragmentRefs": FragmentRefs<"ProductsTable_ProductsList">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "Billing",
  "Products"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": ProductsTable_Refetch
    }
  },
  "name": "ProductsTable_ProductsList",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingAdminSchema",
          "kind": "LinkedField",
          "name": "Billing",
          "plural": false,
          "selections": [
            {
              "alias": "Products",
              "args": [
                {
                  "kind": "Literal",
                  "name": "includeHidden",
                  "value": true
                }
              ],
              "concreteType": "ProductsConnection",
              "kind": "LinkedField",
              "name": "__ProductsTable_Products_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PriceV3",
                              "kind": "LinkedField",
                              "name": "price",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "netPrice",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "grossPrice",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "CourseProduct",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": "__ProductsTable_Products_connection(includeHidden:true)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '6a6a774378f308e55e03c6ba99fa78f6';
export default node;
