import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import "./rank-table.scss";
import {ENTITY_TYPE_RANK, Rank} from "../model/rank";
import { AboveTableContainer } from "../../core/components/containers/Container";
import { PaginationInfo } from "../../core/components/pagination/PaginationInfo";
import { EntityCreateAndDeleteButtons } from "../../core/components/button/EntityCreateAndDeleteButtons";
import { Table, TableColumn } from "../../core/components/table/Table";
import { EntityTableHeader } from "../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../core/components/table/AsyncEntityTableBody";
import { EntityTableRow } from "../../core/components/table/EntityTableRow";
import { ActionTableColumn } from "../../core/components/table/ActionTableColumn";
import { ActionLink } from "../../core/components/icon-link-tooltip/ActionLink";

type Props =
    WithEntityListProps<Rank>
    & WithTranslation
    & WithSelectionsProps<string>;

const LevelTableComponent = ({
                                 entities,
                                 selectionModel,
                                 entityApiState,
                                 t
                             }: Props) => {

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_RANK} className="mr-auto"/>

                <EntityCreateAndDeleteButtons
                    entityType={ENTITY_TYPE_RANK}
                    selectedEntities={selectionModel.selections}
                    label={t("entity.rank.singular")}
                    labelPlural={t("entity.rank.plural")}
                    createPath="/ranks/new/edit"
                />
            </AboveTableContainer>

            <Table itemCount={entities.length} className="expert-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.rank.labels.title")}</TableColumn>
                    <TableColumn>{t("entity.rank.labels.point-threshold")}</TableColumn>
                    <TableColumn>{t("entity.rank.labels.actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={rank =>
                        <EntityTableRow
                            key={rank.id}
                            entity={rank} selectionModel={selectionModel}>
                            <TableColumn className="bold title-column">
                                {rank.entity.title}
                            </TableColumn>
                            <TableColumn>
                                {rank.entity.levelThreshold}
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink
                                    to={`/ranks/${rank.id}/edit`}
                                    icon={faPencilAlt}
                                    tooltip={t("entity.rank.labels.edit-tooltip")}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const RankTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation("rank")(LevelTableComponent),
        ENTITY_TYPE_RANK
    )
);
