/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UsageIdColumn_DiscountCodeUsageFragment = {
    readonly rawId: string;
    readonly " $refType": "UsageIdColumn_DiscountCodeUsageFragment";
};
export type UsageIdColumn_DiscountCodeUsageFragment$data = UsageIdColumn_DiscountCodeUsageFragment;
export type UsageIdColumn_DiscountCodeUsageFragment$key = {
    readonly " $data"?: UsageIdColumn_DiscountCodeUsageFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"UsageIdColumn_DiscountCodeUsageFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageIdColumn_DiscountCodeUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rawId",
      "storageKey": null
    }
  ],
  "type": "DiscountCodeUsage",
  "abstractKey": null
};
(node as any).hash = '9bfaacddc695eda18d570b2b1bb4ba64';
export default node;
