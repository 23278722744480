import React from "react";
import { useTranslation } from "react-i18next";
import { ENTITY_TYPE_ORDER_V2 } from "@thekeytechnology/thekey-academy-frontend-library";
import { Course, ENTITY_TYPE_COURSE } from "../../../../course/model/preparing-aggregated-course";
import { AsyncEntitySelectFilter } from "../../../../core/components/entity/AsyncEntitySelectFilter";
import { TextFilter } from "../../../../core/components/filters/TextFilter";
import { Filters } from "../../../../core/components/filters/Filters";

export const OrdersFilter = () => {
    const {t} = useTranslation(["admin", "core"]);
    return (
        <Filters entityType={ENTITY_TYPE_ORDER_V2}
                 filterKeys={[`entity.name`, "entity.email", "entity.priceTable.offerItems.courseRef"]}>
            <TextFilter
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.orders.labels.id")})}
                entityType={ENTITY_TYPE_ORDER_V2}
                property={`id`}/>

            <AsyncEntitySelectFilter<Course> filteredEntityType={ENTITY_TYPE_ORDER_V2}
                                             property={"entity.priceTable.offerItems.courseRef"}
                                             shownEntityType={ENTITY_TYPE_COURSE}
                                             shownEntityTypeProperties={["entity.containerMeta.title"]}
                                             listRenderer={item => item.entity.containerMeta.title}
                                             placeholder={"Kurs filtern"}/>
        </Filters>
    );
};

