import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {
    addFiltersAction,
    FilterTerm,
    PropertyFilter,
    removeFiltersAction,
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React, {useEffect} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import "./orders-table.scss";
import {useDispatch} from "react-redux";
import {
    BusinessBillingDetails,
    ENTITY_TYPE_ORDER_V2,
    OrderV2
} from "@thekeytechnology/thekey-academy-frontend-library";
import {PaymentProviderLinks} from "../../users/orders/v2/PaymentProviderLinks";
import {OrderPriceDisplay} from "../../users/orders/v2/OrderPriceDisplay";
import {NavLink} from "react-router-dom";
import {AboveTableContainer} from "../../../../core/components/containers/Container";
import {PaginationInfo} from "../../../../core/components/pagination/PaginationInfo";
import {Table, TableColumn, TableHeader, TableRow} from "../../../../core/components/table/Table";
import {AsyncEntityTableBody} from "../../../../core/components/table/AsyncEntityTableBody";
import {DateTimeDisplay} from "../../../../core/components/datetime/DateTimeDisplay";
import {ActionTableColumn} from "../../../../core/components/table/ActionTableColumn";
import {ActionLink} from "../../../../core/components/icon-link-tooltip/ActionLink";

type Props =
    WithEntityListProps<OrderV2>
    & WithTranslation
    & WithSelectionsProps<string>;

const OrdersTableComponent = ({
                                  entities,
                                  entityApiState,
                                  t,
                              }: Props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            addFiltersAction(ENTITY_TYPE_ORDER_V2)("no-deleted", [
                new PropertyFilter("entity.name", new FilterTerm(FilterTerm.TYPE_STRING, "neq", "DELETED"))
            ]))
        return () => {
            dispatch(removeFiltersAction(ENTITY_TYPE_ORDER_V2)(["no-deleted"]))
        }
    }, [dispatch])

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_ORDER_V2} className="mr-auto"/>
            </AboveTableContainer>

            <Table itemCount={entities.length} className="orders-table">
                <TableHeader>
                    <TableColumn>{t("entity.orders.labels.date-time")}</TableColumn>
                    <TableColumn>{t("entity.orders.labels.id")}</TableColumn>
                    <TableColumn>{t("entity.orders.labels.customer")}</TableColumn>
                    <TableColumn>{t("entity.orders.labels.payment-method")}</TableColumn>
                    <TableColumn>{t("entity.orders.labels.amount")}</TableColumn>
                    <TableColumn>{t("entity.orders.labels.actions")}</TableColumn>
                </TableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={order =>
                        <TableRow key={order.id}>
                            <TableColumn>
                                <DateTimeDisplay dateTime={order.entity.history[0]?.dateTime}/>
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {order.id}
                            </TableColumn>
                            <TableColumn>
                                <NavLink to={`/users/${btoa("User:" + order.entity.userRef)}/edit`}>
                                    {order.entity.billingDetails.customerType === "business" ?
                                        `Firma ${(order.entity.billingDetails as BusinessBillingDetails).company}` :
                                        `${order.entity.billingDetails.firstName} ${order.entity.billingDetails.lastName}`}
                                </NavLink>
                            </TableColumn>
                            <TableColumn>
                                <PaymentProviderLinks isPaid={order.entity.status === "payment-in-process"}
                                                      paymentProviderData={order.entity.paymentProviderData}/>
                            </TableColumn>
                            <TableColumn>
                                <OrderPriceDisplay order={order}/>
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink to={`/orders/${order.id}/edit`} icon={faSearch}
                                            tooltip={""}/>
                            </ActionTableColumn>
                        </TableRow>
                    }/>
            </Table>
        </>
    );
};

export const OrdersTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation("admin")(OrdersTableComponent),
        ENTITY_TYPE_ORDER_V2
    )
);
