import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { CourseModule } from "@thekeytechnology/thekey-academy-frontend-library";

export const ADD_MODULE_ACTION = "add-module";

export interface AddModuleAction extends Action {
    module: EntityWrapper<CourseModule>
}

export const addModuleAction = (module: EntityWrapper<CourseModule>) => ({
    type: ADD_MODULE_ACTION,
    module
}) as AddModuleAction;
