import {
    API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    ApiCallAction,
    ApiCallSucceededAction,
    apiGet,
    apiPost,
    EntitiesCreatedPayload,
    EntityWrapper,
    fetchSingleEntityAction,
    matchesAnyOfTheseApiSuccesses,
    matchesApiSuccess,
    reloadEntityListAction,
    reloadOnEntityApiOperation,
    updateEntitiesAction
} from "@thekeytechnology/framework-react";
import { push } from "connected-react-router";
import { Action } from "redux";
import { combineEpics, ofType, StateObservable } from "redux-observable";
import { Observable } from "rxjs";
import {filter, map, withLatestFrom} from "rxjs/operators";
import { API_COPY_COURSE, CopyCoursePayload } from "../actions/copy-course";
import { API_CREATE_COURSE } from "../actions/create-course";
import { SAVE_COURSE_ACTION } from "../actions/save-course";
import { CourseForSaving } from "../model/course-for-saving";
import { selectEditedCourse } from "../selectors";
import { videoDataEpics } from "./video-data";
import { API_PUBLISH_COURSE } from "../../publishing/actions/publish-course";
import { ENTITY_TYPE_AGGREGATED_COURSE } from "../model/preparing-aggregated-course";

const saveCourse$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    ofType(SAVE_COURSE_ACTION),
    withLatestFrom(state$),
    filter(([, state]: [Action, any]) => {
        return selectEditedCourse(state) !== undefined;
    }),
    map(([, state]: [Action, any]) => {
        const editedCourse = selectEditedCourse(state)!;
        const courseForSaving = new EntityWrapper<CourseForSaving>(
            editedCourse.id,
            {
                containerMeta: editedCourse.entity.containerMeta,
                shortTitle: editedCourse.entity.shortTitle,
                language: editedCourse.entity.language,
                image: editedCourse.entity.image?.id,
                icon: editedCourse.entity.icon?.id,
                certificateTitle: editedCourse.entity.certificateTitle,
                certificateDescription: editedCourse.entity.certificateDescription,
                isIHK: editedCourse.entity.isIHK,
                experts: editedCourse.entity.expertsRef,
                modules: editedCourse.entity.modules,
                lessons: editedCourse.entity.lessons,
                elements: editedCourse.entity.elements,
                attributes: editedCourse.entity.attributes,
                mainOfferRef: editedCourse.entity.mainOffer ? editedCourse.entity.mainOffer.id : undefined,
                mainOfferV2Ref: editedCourse.entity.mainOfferV2 ? editedCourse.entity.mainOfferV2.id : undefined,
                mainProductRef: editedCourse.entity.mainProduct ? editedCourse.entity.mainProduct.id : undefined,
                isFreeCourse: editedCourse.entity.isFreeCourse,
                courseFile: editedCourse.entity.courseFile?.id,
                versions: editedCourse.entity.versions
            },
            editedCourse.temporaryId
        );

        return updateEntitiesAction<CourseForSaving>(ENTITY_TYPE_AGGREGATED_COURSE)([courseForSaving as EntityWrapper<CourseForSaving>]);
    })
);

const copyCourse$ = apiPost({apiType: API_COPY_COURSE},
    "/courses/copy",
    (action: ApiCallAction<CopyCoursePayload>) => ({
        courseId: action.payload.courseId
    }));

const createCourse$ = apiGet({apiType: API_CREATE_COURSE},
    "/courses/new"
);

const reloadCourse$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses(
        {apiType: API_UPDATE_ENTITIES, callType: ENTITY_TYPE_AGGREGATED_COURSE},
    ),
    map((payload: any) => {
        return fetchSingleEntityAction(ENTITY_TYPE_AGGREGATED_COURSE)(payload.payload.ids[0]);
    })
);

const reloadCourses$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses(
        {apiType: API_COPY_COURSE}, {apiType: API_PUBLISH_COURSE}
    ),
    map(() => {
        return reloadEntityListAction(ENTITY_TYPE_AGGREGATED_COURSE)();
    })
);

const redirectOnCourseCreation$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_CREATE_COURSE),
    map((action: ApiCallSucceededAction<EntitiesCreatedPayload>) => {
        return push(`/courses/${action.payload.ids[0]}/editor`);
    })
);

export const courseModuleEpics$ = combineEpics(
    saveCourse$,
    copyCourse$,
    createCourse$,
    reloadCourses$,
    reloadCourse$,
    redirectOnCourseCreation$,
    reloadOnEntityApiOperation(API_DELETE_ENTITIES, ENTITY_TYPE_AGGREGATED_COURSE),
    videoDataEpics
);
