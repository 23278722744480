import {classNames} from "primereact/utils";
import {RenderConfig} from "../ValidatedFieldV2";
import {InputSwitch} from "primereact/inputswitch";

export function DefaultSwitchFieldComponent({fieldName, fieldValue, updateField, isValid}: RenderConfig<boolean>) {
    return <div>
        <InputSwitch
            id={fieldName}
            name={fieldName}
            checked={fieldValue}
            onChange={e => updateField(e.target.value)}
            className={classNames({"p-invalid": !isValid})}/>
    </div>
}
