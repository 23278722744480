import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { RankFilters } from "./RankFilters";
import { RankTable } from "./RankTable";
import { ENTITY_TYPE_RANK } from "../model/rank";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../core/components/containers/Container";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const LevelOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/levels"}>{t("entity.rank.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.rank.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <RankFilters/>
            </BackgroundContainer>
            <ContentContainer>
                <RankTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_RANK}/>
            </PaginationContainer>
        </>
    );
};

export const RankOverview = withTranslation("rank")(LevelOverviewComponent);
