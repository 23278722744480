/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ProductTypeInput = "courseProduct" | "%future added value";
export type CreateCourseProductInput = {
    data: CourseProductInput;
    clientMutationId?: string | null;
};
export type CourseProductInput = {
    title: string;
    price: PriceInput;
    isHidden: boolean;
    productType: ProductTypeInput;
    courseRef: string;
};
export type PriceInput = {
    netPrice?: number | null;
    grossPrice?: number | null;
    taxRatePercentage?: number | null;
};
export type EditProductScreen_CreateMutationVariables = {
    input: CreateCourseProductInput;
    connections: Array<string>;
};
export type EditProductScreen_CreateMutationResponse = {
    readonly Admin: {
        readonly Billing: {
            readonly createCourseProduct: {
                readonly data: {
                    readonly node: {
                        readonly id: string;
                        readonly title?: string;
                    };
                };
            } | null;
        };
    };
};
export type EditProductScreen_CreateMutation = {
    readonly response: EditProductScreen_CreateMutationResponse;
    readonly variables: EditProductScreen_CreateMutationVariables;
};



/*
mutation EditProductScreen_CreateMutation(
  $input: CreateCourseProductInput!
) {
  Admin {
    Billing {
      createCourseProduct(input: $input) {
        data {
          node {
            __typename
            id
            ... on CourseProduct {
              title
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "CourseProduct",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProductScreen_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCourseProductPayload",
                "kind": "LinkedField",
                "name": "createCourseProduct",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditProductScreen_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCourseProductPayload",
                "kind": "LinkedField",
                "name": "createCourseProduct",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f82d5569cb67842af1c40083901465aa",
    "id": null,
    "metadata": {},
    "name": "EditProductScreen_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation EditProductScreen_CreateMutation(\n  $input: CreateCourseProductInput!\n) {\n  Admin {\n    Billing {\n      createCourseProduct(input: $input) {\n        data {\n          node {\n            __typename\n            id\n            ... on CourseProduct {\n              title\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '230189e93744043bab4198b7ba702b23';
export default node;
