import { EntityWrapper, SelectionModel } from "@thekeytechnology/framework-react";
import React from "react";
import { TableColumn, TableHeader } from "./Table";
import { SelectAllInModelCheckbox } from "../selection/SelectAllInModelCheckbox";

interface EntityTableHeaderProps<T> {
    entities: EntityWrapper<T>[]
    selectionModel: SelectionModel<string>;
    children: any;
}

export function EntityTableHeader<T>(props: EntityTableHeaderProps<T>) {
    const {entities, selectionModel, children} = props;
    return (
        <TableHeader>
            <TableColumn>
                <SelectAllInModelCheckbox items={entities.map(c => c.id as string)} selectionModel={selectionModel}/>
            </TableColumn>
            {children}
        </TableHeader>
    );
}
