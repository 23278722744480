import React from "react";
import { PriceWithTaxDisplay } from "./PriceWithTaxDisplay";
import { MonthlyPriceExplanation } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    className?: string
    monthlyPriceExplanation: MonthlyPriceExplanation
    showNetPrices?: boolean
    monthlyText?: string
    beforeText?: string
}

export const MonthlyPriceDisplay = ({
                                        beforeText,
                                        className,
                                        monthlyPriceExplanation,
                                        showNetPrices,
                                        monthlyText
                                    }: OwnProps) => {
    return <span className={className}>
        {beforeText ? beforeText : null}<strong>{monthlyPriceExplanation.rateCount}</strong> {monthlyText ? monthlyText : "monatlichen"} Raten zu
        je <strong className="monthly-rate"><PriceWithTaxDisplay netPrice={monthlyPriceExplanation.monthlyRateNet}
                                                                 grossPrice={monthlyPriceExplanation.monthlyRateGross}
                                                                 showNetPrice={showNetPrices}/></strong>
    </span>
}
