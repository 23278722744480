import React, {Suspense} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {ValidatedFieldV2} from "../../../core/components/form/ValidatedFieldV2";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {Button} from "primereact/button";
import {EditCustomerMessageDefinitionButtonSubform} from "./EditCustomerMessageDefinitionButtonSubform";
import {EditCustomerMessageDefinitionNotificationDefinitionSubform} from "./EditCustomerMessageDefinitionNotificationDefinitionSubform";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment$key} from "../../../../__generated__/EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment.graphql";
import {Card} from "primereact/card";
import {useHistory} from "react-router-dom";
import {ProgressSpinner} from "primereact/progressspinner";
import {
    CustomerMessageDefinitionButtonInput,
    CustomerMessageDefinitionCoreInput,
    EditCustomerMessageDefinitionV2CoreForm_EditCoreMutation
} from "../../../../__generated__/EditCustomerMessageDefinitionV2CoreForm_EditCoreMutation.graphql";
import {DefaultTextFieldComponent} from "../../../core/components/form/forms/DefaultTextComponent";

const CUSTOMER_MESSAGE_DEFINITION_FRAGMENT = graphql`
    fragment EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {
        id
        internalTitle
        buttonOpt {
            type
            title
            ... on CustomerMessageDefinitionLinkButton {
                link
            }
        }
        showUntilOpt
        notificationDefinitionOpt {
            title
            body
        }
    }`

const EDIT_CORE_MUTATION = graphql`
    mutation EditCustomerMessageDefinitionV2CoreForm_EditCoreMutation($input: UpdateCustomerMessageDefinitionCoreInput!) {
        Admin {
            CustomerMessageV2 {
                updateCustomerMessageDefinitionCore(input: $input) {
                    data {
                        node {
                            ...EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment
                        }
                    }
                }
            }
        }
    }`

type CustomerMessageDefinitionButtonType = "none" | "link" | "confirm"

export interface CustomerMessageDefinitionButton {
    type: CustomerMessageDefinitionButtonType
}

export interface CustomerMessageDefinitionLinkButton extends CustomerMessageDefinitionButton {
    type: "link"
    title: string
    link: string
}

export interface CustomerMessageDefinitionConfirmButton extends CustomerMessageDefinitionButton {
    type: "confirm"
    title: string
}

export interface CustomerMessageDefinitionNoButton extends CustomerMessageDefinitionButton {
    type: "none"
}

export interface CustomerMessageDefinitionNotificationDefinition {
    title: string
    body: string
}

interface CustomerMessageDefinitionCore {
    internalTitle: string
    button: CustomerMessageDefinitionButton
    showUntil?: string
    notificationDefinition?: CustomerMessageDefinitionNotificationDefinition
}

const REGEX_FOR_HTML_LINK = new RegExp("https://(www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)")

interface OwnProps {
    customerMessageDefinitionV2FragmentRef: EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment$key
}

export const EditCustomerMessageDefinitionV2CoreForm = ({
                                                            customerMessageDefinitionV2FragmentRef
                                                        }: OwnProps) => {
    const {t: tCore} = useTranslation("core")
    const {t: tCMV2} = useTranslation("customerMessagesV2")

    const history = useHistory()

    const customerMessageDefinition = useFragment<EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment$key>(CUSTOMER_MESSAGE_DEFINITION_FRAGMENT, customerMessageDefinitionV2FragmentRef)

    const [editCoreRaw, isEditingCore] = useMutation<EditCustomerMessageDefinitionV2CoreForm_EditCoreMutation>(EDIT_CORE_MUTATION)

    const editCore = (customerMessageDefinitionCoreInput: CustomerMessageDefinitionCoreInput) => editCoreRaw({
        variables: {
            input: {
                id: customerMessageDefinition.id,
                data: customerMessageDefinitionCoreInput
            }
        }
    })

    const determineButtonInitialValue = (): CustomerMessageDefinitionButton => {
        if (customerMessageDefinition.buttonOpt) {
            switch (customerMessageDefinition.buttonOpt.type) {
                case "link":
                    return {
                        type: "link",
                        title: customerMessageDefinition.buttonOpt.title,
                        link: customerMessageDefinition.buttonOpt.link
                    } as CustomerMessageDefinitionLinkButton
                case "confirm":
                    return {
                        type: "confirm",
                        title: customerMessageDefinition.buttonOpt.title
                    } as CustomerMessageDefinitionConfirmButton
                default:
                    return {type: "none"} as CustomerMessageDefinitionNoButton
            }
        } else {
            return {type: "none"} as CustomerMessageDefinitionNoButton
        }
    }

    const determineButtonForSubmission = (button: CustomerMessageDefinitionButton): CustomerMessageDefinitionButtonInput | undefined => {
        switch (button.type) {
            case "link":
                return {
                    buttonType: "link",
                    title: (button as CustomerMessageDefinitionLinkButton).title,
                    link: (button as CustomerMessageDefinitionLinkButton).link
                }
            case "confirm":
                return {buttonType: "confirm", title: (button as CustomerMessageDefinitionConfirmButton).title}
            default:
                return undefined
        }
    }

    const formik = useFormik<CustomerMessageDefinitionCore>({
        initialValues: {
            internalTitle: customerMessageDefinition.internalTitle,
            button: determineButtonInitialValue(),
            showUntil: customerMessageDefinition.showUntilOpt ? customerMessageDefinition.showUntilOpt as string : undefined,
            notificationDefinition: customerMessageDefinition.notificationDefinitionOpt ? {
                title: customerMessageDefinition.notificationDefinitionOpt.title,
                body: customerMessageDefinition.notificationDefinitionOpt.body
            } as CustomerMessageDefinitionNotificationDefinition : undefined
        },
        validationSchema: Yup.object().shape({
            internalTitle: Yup.string().required(tCore("forms.required-field", {fieldName: tCMV2("edit-customer-message-definition-v2.core.internal-title")})),
            button: Yup.object().test("", "", function (value) {
                if ((value as CustomerMessageDefinitionButton).type === "link" || (value as CustomerMessageDefinitionButton).type === "confirm") {
                    if (!(value as CustomerMessageDefinitionLinkButton | CustomerMessageDefinitionConfirmButton).title) return this.createError({
                        message: tCore("forms.required-field", {fieldName: tCMV2("edit-customer-message-definition-v2.core.button.text")})
                    })
                }

                if ((value as CustomerMessageDefinitionButton).type === "link") {
                    if (!(value as CustomerMessageDefinitionLinkButton).link?.match(REGEX_FOR_HTML_LINK)) return this.createError({
                        message: tCMV2("edit-customer-message-definition-v2.core.button.error-messages.link"),
                    })
                }

                return true
            }),
            notificationDefinition: Yup.object().test("", "", function (value) {
                const typedValue = value as CustomerMessageDefinitionNotificationDefinition

                if (!typedValue) return true

                if (!typedValue.title || typedValue.title.length < 10 || typedValue.title.length > 65) return this.createError({
                    message: tCMV2("edit-customer-message-definition-v2.core.notification-definition.error-messages.title", {notificationDefinitionTitle: tCMV2("edit-customer-message-definition-v2.core.notification-definition.title")}),
                })

                if (!typedValue.body || typedValue.body.length < 10 || typedValue.body.length > 178) return this.createError({
                    message: tCMV2("edit-customer-message-definition-v2.core.notification-definition.error-messages.body", {notificationDefinitionBody: tCMV2("edit-customer-message-definition-v2.core.notification-definition.body")})
                })

                return true
            })
        }),
        onSubmit: (values, {setSubmitting}) => {
            editCore({
                internalTitle: values.internalTitle,
                buttonOpt: determineButtonForSubmission(values.button),
                showUntilOpt: values.showUntil,
                notificationDefinitionOpt: values.notificationDefinition
            })
            setSubmitting(false)
        }
    })

    return <Card>
        <Suspense fallback={<ProgressSpinner/>}>
            {!isEditingCore ? <form onSubmit={formik.handleSubmit} className="p-fluid">
                <ValidatedFieldV2<CustomerMessageDefinitionCore, string>
                    name={"internalTitle"}
                    label={tCMV2("edit-customer-message-definition-v2.core.internal-title")}
                    required={true}
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}
                />

                <ValidatedFieldV2<CustomerMessageDefinitionCore, CustomerMessageDefinitionButton>
                    name={"button"}
                    label={tCMV2("edit-customer-message-definition-v2.core.button.name")}
                    component={({fieldValue, updateField}) => <EditCustomerMessageDefinitionButtonSubform
                        fieldValue={fieldValue} updateField={updateField}/>}
                    formikConfig={formik}
                />

                <ValidatedFieldV2<CustomerMessageDefinitionCore, string>
                    name={"showUntil"}
                    label={tCMV2("edit-customer-message-definition-v2.core.show-until")}
                    component={({
                                    fieldName,
                                    fieldValue,
                                    updateField
                                }: { fieldName: string, fieldValue: string | undefined, updateField: (showUntil?: string) => void }) => {
                        return <Calendar name={fieldName}
                                         dateFormat={"dd.mm.yy"}
                                         value={fieldValue ? moment(fieldValue).toDate() : undefined}
                                         onChange={(e) => updateField(e.value ? (moment(e.value as Date).format("YYYY-MM-DD")) : undefined)}/>
                    }}
                    formikConfig={formik}
                />

                <ValidatedFieldV2<CustomerMessageDefinitionCore, CustomerMessageDefinitionNotificationDefinition>
                    name={"notificationDefinition"}
                    label={tCMV2("edit-customer-message-definition-v2.core.notification-definition.name")}
                    component={({fieldValue, updateField}) =>
                        <EditCustomerMessageDefinitionNotificationDefinitionSubform
                            fieldValue={fieldValue} updateField={updateField}/>}
                    formikConfig={formik}
                />

                <div className="flex pt-2">
                    <Button
                        disabled={false}
                        type="submit"
                        label={tCMV2("edit-customer-message-definition-v2.core.buttons.save")}
                        className="mr-5"/>
                    <Button
                        disabled={false}
                        onClick={() => history.push("/customer-message-v2")}
                        label={tCMV2("edit-customer-message-definition-v2.core.buttons.close")}
                        className="ml-5 p-button-secondary"/>
                </div>
            </form> : <ProgressSpinner/>}
        </Suspense>
    </Card>
}