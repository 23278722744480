import { FieldProps } from "formik";
import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PersologClass } from "@thekeytechnology/thekey-academy-frontend-library";
import { Editor } from "@tinymce/tinymce-react/lib/es2015/main/ts";
import { EntityWrapper, TkFile, useSelectionModel } from "@thekeytechnology/framework-react";
import { connect } from "react-redux";
import { selectChildFiles } from "../../../../selectors";
import { AttachmentSelect } from "../../../../../core/components/attachment/AttachmentSelect";

interface StateProps {
    childFiles: EntityWrapper<TkFile>[];
}

type Props = StateProps & FieldProps;

const PersologClassesFieldComponent = ({field: {name, value}, form, childFiles}: Props) => {
    const {t} = useTranslation("courses")

    const persologClasses = value ? value as PersologClass[] : [];

    const updateClass = (updatedClass: PersologClass) => {
        form.setFieldValue(name, persologClasses.map(p =>
            p.classCode === updatedClass.classCode ? updatedClass : p
        ))
    }

    const selectionModel = useSelectionModel<string>(true)

    return (
        <div className="questionnaire-answers d-flex flex-column w-100">
            {persologClasses.map((persologClass: PersologClass, index: number) =>
                <Card key={index}>
                    <Card.Body>
                        <h3 className="d-flex align-items-center">
                            {t("course-editor.edit-element-form.persolog.class", {classCode: persologClass.classCode})}
                        </h3>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.edit-element-form.persolog.question")}*</label>
                            <div className="col-sm-10">
                                <input
                                    className="form-control default-input"
                                    type="text"
                                    placeholder={t("course-editor.edit-element-form.persolog.class-title")}
                                    value={persologClass.title}
                                    onChange={(event => updateClass({...persologClass, title: event.target.value}))}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.edit-element-form.persolog.question")}*</label>
                            <div className="col-sm-10">
                                <Editor
                                    initialValue={persologClass.explanation}
                                    init={{
                                        height: 300,
                                        language: "de",
                                        language_url: `/public/tinymce/de.js`,
                                        link_class_list: [
                                            {title: "Keine", value: ""},
                                            {title: "Button", value: "button"},
                                        ],
                                        default_link_target: "_blank",
                                        plugins: [
                                            "advlist autolink lists link image charmap print preview anchor",
                                            "searchreplace visualblocks code fullscreen",
                                            "insertdatetime media table paste code help wordcount"
                                        ],
                                        toolbar:
                                            "undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | link | image | bullist numlist outdent indent | removeformat | help",
                                    }}
                                    onEditorChange={text => updateClass({...persologClass, explanation: text})}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.answers-field.answer-image")}</label>
                            <div className="col-sm-10">
                                <AttachmentSelect
                                    selectionModel={selectionModel}
                                    isSingleSelect={true}
                                    value={childFiles.find(f => f.id === persologClass.imageRef)}
                                    changeValue={newValue => updateClass({
                                        ...persologClass,
                                        imageRef: (newValue as EntityWrapper<TkFile>)?.id
                                    })}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}
        </div>
    );
};

export const PersologClassesField = connect<StateProps, {}, {}>(
    (state: any) => ({
        childFiles: selectChildFiles(state)
    })
)(PersologClassesFieldComponent);
