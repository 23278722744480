import React from "react";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {
    ActionIdColumn_DiscountActionFragment$key
} from "../../../../../../../__generated__/ActionIdColumn_DiscountActionFragment.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
    fragment ActionIdColumn_DiscountActionFragment on DiscountAction {
        rawId
    }
`

interface OwnProps {
    discountActionFragmentRef: ActionIdColumn_DiscountActionFragment$key
}

export const ActionIdColumn = ({discountActionFragmentRef}: OwnProps) => {
    const discountAction = useFragment<ActionIdColumn_DiscountActionFragment$key>(DISCOUNT_ACTION_FRAGMENT, discountActionFragmentRef)

    return (
        <div
            className="cursor-pointer"
            onClick={() => navigator.clipboard.writeText(discountAction.rawId)}>
            <b>{discountAction.rawId}<i className="pi pi-copy"></i></b>
        </div>
    )
}
