import {
    apiModuleEpics$,
    dialogModuleEpics$,
    entityModuleEpics$,
    filesModuleEpics$,
    i18nModuleEpics$,
    settingsModuleEpics$,
    userModuleEpics$
} from "@thekeytechnology/framework-react";

import { combineEpics } from "redux-observable";
import { additionalContentModuleEpics$ } from "./additionalcontent/epics";
import { additionalAuthModuleEpics$ } from "./auth/epics";
import { courseModuleEpics$ } from "./course/epics/course";
import { expertModuleEpics$ } from "./expert/epics";
import { glossaryModuleEpics$ } from "./glossary/epics";
import { qnaModuleEpics$ } from "./qna/epics";
import { rankModuleEpics$ } from "./rank/epics";
import { faqModuleEpics$ } from "./faq/epics";
import { emailModuleEpic$ } from "./emails/epics/email";
import { notificationModuleEpics$ } from "./notifications/epics/notifications";
import { triggersModuleEpic$ } from "./triggers/epics/email";
import { achievementModuleEpics$ } from "./achievements/epics";
import { triggeredUnlockModuleEpics$ } from "./unlocks/epics";
import { billingModuleEpics$ } from "./billing/old/epics";
import { feedbackModuleEpic$ } from "@thekeytechnology/thekey-academy-frontend-library";
import { userManagementModuleEpics$ } from "./admin/epics";
import {customerMessageModuleEpics$} from "./customer-messages/epics/postCustomerMessage"
import { publishCourseEpics$ } from "./publishing/epics";
import { onboardingModuleEpics$ } from "./onboarding/epics";

export const appEpics = combineEpics(
    entityModuleEpics$,
    dialogModuleEpics$,
    filesModuleEpics$,
    apiModuleEpics$,
    i18nModuleEpics$,
    settingsModuleEpics$,
    userModuleEpics$,
    customerMessageModuleEpics$,
    additionalAuthModuleEpics$,
    courseModuleEpics$,
    glossaryModuleEpics$,
    additionalContentModuleEpics$,
    expertModuleEpics$,
    qnaModuleEpics$,
    rankModuleEpics$,
    faqModuleEpics$,
    emailModuleEpic$,
    notificationModuleEpics$,
    triggersModuleEpic$,
    achievementModuleEpics$,
    triggeredUnlockModuleEpics$,
    billingModuleEpics$,
    feedbackModuleEpic$,
    userManagementModuleEpics$,
    publishCourseEpics$,
    onboardingModuleEpics$
);
