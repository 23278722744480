import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import "./voucher-table.scss";
import currency from "currency.js"
import { ENTITY_TYPE_VOUCHER, Voucher } from "@thekeytechnology/thekey-academy-frontend-library";
import { AboveTableContainer } from "../../../../../core/components/containers/Container";
import { PaginationInfo } from "../../../../../core/components/pagination/PaginationInfo";
import { EntityCreateAndDeleteButtons } from "../../../../../core/components/button/EntityCreateAndDeleteButtons";
import { Table, TableColumn } from "../../../../../core/components/table/Table";
import { EntityTableHeader } from "../../../../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../../../../core/components/table/AsyncEntityTableBody";
import { EntityTableRow } from "../../../../../core/components/table/EntityTableRow";
import { ActionTableColumn } from "../../../../../core/components/table/ActionTableColumn";
import { ActionLink } from "../../../../../core/components/icon-link-tooltip/ActionLink";

type Props =
    WithEntityListProps<Voucher>
    & WithTranslation
    & WithSelectionsProps<string>;

const OffersTableComponent = ({
                                  entities,
                                  selectionModel,
                                  entityApiState,
                                  t
                              }: Props) => {

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_VOUCHER} className="mr-auto"/>

                <EntityCreateAndDeleteButtons
                    entityType={ENTITY_TYPE_VOUCHER}
                    selectedEntities={selectionModel.selections}
                    label={t("entity.voucher.singular")}
                    labelPlural={t("entity.voucher.plural")}
                    createPath="/vouchers/new/edit"
                />
            </AboveTableContainer>

            <Table itemCount={entities.length} className="vouchers-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.voucher.labels.code")}</TableColumn>
                    <TableColumn>{t("entity.voucher.labels.price")}</TableColumn>
                    <TableColumn>{t("entity.voucher.labels.uses")}</TableColumn>
                    <TableColumn>{t("entity.voucher.labels.actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={voucher =>
                        <EntityTableRow
                            key={voucher.id}
                            entity={voucher} selectionModel={selectionModel}>
                            <TableColumn>
                                {voucher.entity.code}
                            </TableColumn>
                            <TableColumn>
                                {currency(voucher.entity.price.netPrice).format({
                                    separator: ".",
                                    decimal: ",",
                                    symbol: ""
                                })}€ netto
                                ({currency(voucher.entity.price.grossPrice).format({
                                separator: ".",
                                decimal: ",",
                                symbol: ""
                            })}€
                                brutto)
                            </TableColumn>
                            <TableColumn>
                                {voucher.entity.uses} {voucher.entity.usageLimit ? "/ " + voucher.entity.usageLimit : null}
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink to={`/vouchers/${voucher.id}/edit`} icon={faPencilAlt}
                                            tooltip={t("entity.voucher.labels.edit-tooltip")}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const VoucherTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation("billing")(OffersTableComponent),
        ENTITY_TYPE_VOUCHER
    )
);
