import React from "react";

interface ValueFieldProps {
    label: string;
    value: any;
}


export const ValueField = ({label, value}: ValueFieldProps) => {
    return <div className="form-group row">
        <label className="col-sm-2 col-form-label">{label}</label>
        <input className="col-sm-10 orm-control default-input"
               disabled={true}
               value={value}/>
    </div>
}
