import { EntityWrapper, } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { ADDITIONAL_CONTENT_TYPE_TEXT, TextAdditionalContent } from "@thekeytechnology/thekey-academy-frontend-library";
import { EditHeaderWithBackground } from "../../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../../core/components/containers/Container";
import { ValidatedField } from "../../../core/components/form/ValidatedField";
import { WysiwygFieldWithUpload } from "../../../core/components/wysiwyg/WysiwygFieldWithUpload";

interface OwnProps {
    entity: EntityWrapper<TextAdditionalContent>
    onUpdate: (entity: EntityWrapper<TextAdditionalContent>) => void;
}

type Props = OwnProps
    & WithTranslation;

const EditTextAdditionalContentFormComponent = ({
                                                    entity,
                                                    onUpdate,
                                                    t
                                                }: Props) => {

    return <Formik
        initialValues={{
            title: entity.entity.title,
            text: entity.entity.text
        }}
        validationSchema={Yup.object().shape({
            title: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.additional-content.labels.title")})),
            text: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.additional-content.labels.text")})),
        })}
        onSubmit={(values, {setSubmitting}) => {
            const saving = new EntityWrapper<TextAdditionalContent>(entity.id,
                {
                    contentType: ADDITIONAL_CONTENT_TYPE_TEXT,
                    title: values.title,
                    text: values.text,
                    versions: entity.entity.versions
                }
            );
            onUpdate(saving);
            setSubmitting(false);
        }}
    >
        {formikState => (
            <Form>
                <EditHeaderWithBackground
                    heading={entity.id ?
                        formikState.values.title :
                        t("core:edit-header.heading-empty")}
                >
                    <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                        backPath="/additional-content/"/>
                </EditHeaderWithBackground>
                <ContentContainer>
                    <div className="form-group row">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("entity.additional-content.labels.title")}
                                        name="title"
                                        className="form-control default-input"
                                        label={t("entity.additional-content.labels.title")}
                                        required/>
                    </div>
                    <div className="form-group row">
                        <ValidatedField formikState={formikState}
                                        component={WysiwygFieldWithUpload}
                                        placeholder={t("entity.additional-content.labels.text")}
                                        name="text"
                                        className="form-control default-input"
                                        label={t("entity.additional-content.labels.text")}
                                        required/>
                    </div>
                </ContentContainer>
            </Form>
        )}
    </Formik>;
};

export const EditTextAdditionalContentForm = withTranslation(["additionalContent", "core"])(EditTextAdditionalContentFormComponent);
