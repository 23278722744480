import {EntityWrapper} from "@thekeytechnology/framework-react";
import {OfferV2} from "@thekeytechnology/thekey-academy-frontend-library";
import {TFunction} from "i18next";
import * as Yup from "yup";

export interface ButtonCta {
    dataType: string;
    buttonTitle: string;
}

export class PurchaseButton implements ButtonCta {
    dataType: string = "purchase";
    buttonTitle: string;
    mainOfferV2Ref: EntityWrapper<OfferV2>;

    constructor(mainOfferV2Ref: EntityWrapper<OfferV2>, buttonTitle: string) {
        this.mainOfferV2Ref = mainOfferV2Ref;
        this.buttonTitle = buttonTitle
    }
}

export class PurchaseButtonForSaving implements ButtonCta {
    dataType: string = "purchase";
    buttonTitle: string;
    mainOfferV2Ref: string;

    constructor(mainOfferV2Ref: string, buttonTitle: string) {
        this.mainOfferV2Ref = mainOfferV2Ref;
        this.buttonTitle = buttonTitle
    }
}

export class LinkButton implements ButtonCta {
    dataType: string = "link"
    buttonTitle: string;
    link: string;

    constructor(link: string, buttonTitle: string) {
        this.link = link;
        this.buttonTitle = buttonTitle
    }
}

export class ConfirmButton implements ButtonCta {
    dataType: string = "confirm";
    buttonTitle: string;

    constructor(buttonTitle: string) {
        this.buttonTitle = buttonTitle
    }

}

export const availableButtonCta: ButtonCta[] = [
    new ConfirmButton(""),
    new LinkButton("", ""),
    new PurchaseButton({
        id: undefined,
        temporaryId: undefined,
        entity: {
            internalTitle: "",
            title: "",
            disabledPaymentMethods: [],
            items: [],
            upsellOptions: [],
            forcedCustomerType: undefined,
            limitedToAccountRef: undefined,
            showNetPrices: undefined
        }
    }, "")
]
export const YUP_SCHEMA_BUTTONCTA = (button: any, t: TFunction) =>
    Yup.object()
        .required(t("core:forms.required-field", {fieldName: t("entity.customer-message.labels.buttonCta")}))
        .test("test", "test", function (value) {
            if (button.buttonTitle === undefined) {
                return this.createError({
                    path: "buttonCta",
                    message: t("entity.customer-message.errors.buttonTitle", {
                        buttonTitle: t("entity.customer-message.errors.buttonTitle")
                    })
                })
            }
            switch (button.dataType) {
                 case "link": {
                     const linkExpression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi;
                     const regex = new RegExp(linkExpression);

                     const linkButton = button as LinkButton

                     if (!regex.test(linkButton.link) && !linkButton.link.startsWith("mailto")) {
                         return this.createError({
                             path: "buttonCta",
                             message: t("entity.customer-message.errors.link", {
                                 link: t("entity.customer-message.errors.link")
                             })
                         })
                     }
                     break
                 }
                case "purchase": {
                    const purchaseButton = button as PurchaseButton
                    if (typeof purchaseButton.mainOfferV2Ref == 'undefined') {
                        return this.createError({
                            path: "buttonCta",
                            message: t("entity.customer-message.errors.mainOfferV2Ref", {
                                mainOfferV2Ref: t("entity.customer-message.errors.mainOfferV2Ref")
                            })
                        })
                    }
                    break
                }
                default:
                    break;
            }

            return true
        });


