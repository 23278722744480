import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { WithEntityList, WithSelections } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import "./additional-content-table.scss";
import { AddAdditionalContent } from "./AddAdditionalContent";
import { WithEntityListProps } from "@thekeytechnology/framework-react";
import { WithSelectionsProps } from "@thekeytechnology/framework-react";
import { ADDITIONAL_CONTENT_TYPE, AdditionalContent } from "@thekeytechnology/thekey-academy-frontend-library";
import { AboveTableContainer } from "../../core/components/containers/Container";
import { PaginationInfo } from "../../core/components/pagination/PaginationInfo";
import { EntityDeletionButton } from "../../core/components/button/EntityDeletionButton";
import { Table, TableColumn } from "../../core/components/table/Table";
import { EntityTableHeader } from "../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../core/components/table/AsyncEntityTableBody";
import { EntityTableRow } from "../../core/components/table/EntityTableRow";
import { ActionTableColumn } from "../../core/components/table/ActionTableColumn";
import { ActionLink } from "../../core/components/icon-link-tooltip/ActionLink";

type Props =
    WithEntityListProps<AdditionalContent>
    & WithTranslation
    & WithSelectionsProps<string>;

const AdditionalContentTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ADDITIONAL_CONTENT_TYPE} className="mr-auto"/>

                <AddAdditionalContent/>

                <EntityDeletionButton entityType={ADDITIONAL_CONTENT_TYPE}
                                      selectedEntities={selectionModel.selections}
                                      label={t("entity.additional-content.singular")}
                                      labelPlural={t("entity.additional-content.plural")}
                />
            </AboveTableContainer>

            <Table itemCount={entities.length} className="additional-content-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.additional-content.labels.title")}</TableColumn>
                    <TableColumn>{t("entity.additional-content.labels.type")}</TableColumn>
                    <TableColumn>{t("entity.additional-content.labels.actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={additionalContent =>
                        <EntityTableRow
                            key={additionalContent.id}
                            entity={additionalContent} selectionModel={selectionModel}>
                            <TableColumn className="bold title-column">
                                {additionalContent.entity.title}
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {t(`content-types.${additionalContent.entity.contentType}`)}
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink to={`/additional-content/${additionalContent.id}/edit`} icon={faPencilAlt}
                                            tooltip={t("entity.additional-content.labels.edit-tooltip")}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const AdditionalContentTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation("additionalContent")(AdditionalContentTableComponent),
        ADDITIONAL_CONTENT_TYPE
    )
);
