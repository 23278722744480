/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditProductScreen_QueryVariables = {
    id: string;
    skip: boolean;
};
export type EditProductScreen_QueryResponse = {
    readonly node?: {
        readonly " $fragmentRefs": FragmentRefs<"EditProductScreen_ProductFragment">;
    } | null;
};
export type EditProductScreen_Query = {
    readonly response: EditProductScreen_QueryResponse;
    readonly variables: EditProductScreen_QueryVariables;
};



/*
query EditProductScreen_Query(
  $id: ID!
  $skip: Boolean!
) {
  node(id: $id) @skip(if: $skip) {
    __typename
    ... on Product {
      __isProduct: __typename
      ...EditProductScreen_ProductFragment
    }
    id
  }
}

fragment EditProductScreen_ProductFragment on Product {
  __isProduct: __typename
  id
  title
  isHidden
  price {
    netPrice
    grossPrice
    taxRatePercentage
  }
  ... on CourseProduct {
    courseRef
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProductScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditProductScreen_ProductFragment"
                  }
                ],
                "type": "Product",
                "abstractKey": "__isProduct"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditProductScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isHidden",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceV3",
                    "kind": "LinkedField",
                    "name": "price",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "netPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "grossPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "taxRatePercentage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "courseRef",
                        "storageKey": null
                      }
                    ],
                    "type": "CourseProduct",
                    "abstractKey": null
                  }
                ],
                "type": "Product",
                "abstractKey": "__isProduct"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "1ff1099b67da3ac24e776b5967e49a86",
    "id": null,
    "metadata": {},
    "name": "EditProductScreen_Query",
    "operationKind": "query",
    "text": "query EditProductScreen_Query(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on Product {\n      __isProduct: __typename\n      ...EditProductScreen_ProductFragment\n    }\n    id\n  }\n}\n\nfragment EditProductScreen_ProductFragment on Product {\n  __isProduct: __typename\n  id\n  title\n  isHidden\n  price {\n    netPrice\n    grossPrice\n    taxRatePercentage\n  }\n  ... on CourseProduct {\n    courseRef\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd96defa7535b8b8e687a80601133b3b2';
export default node;
