import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { createNewRank, ENTITY_TYPE_RANK, ENTITY_TYPE_RANK_FOR_SAVING, Rank, RankForSaving } from "../model/rank";
import { faSortNumericUp } from "@fortawesome/free-solid-svg-icons";
import { InlineAttachmentField } from "../../core/components/attachment/InlineAttachmentField";
import { LoadingEditHeader } from "../../core/components/header/LoadingEditHeader";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../core/components/entity/WithSingleEntityFromPath";
import { EditHeaderWithBackground } from "../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../core/components/containers/Container";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { LoadingRow } from "../../core/components/table/LoadingRow";

type Props = WithSingleEntityFromPathProps<Rank, RankForSaving> & WithTranslation;

const EditRankFormComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return entity ? (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.rank.singular")}/>

            <Formik
                initialValues={{
                    title: entity.entity.title,
                    image: entity.entity.image,
                    levelThreshold: entity.entity.levelThreshold,
                    explanation: entity.entity.explanation,
                    text: entity.entity.text
                }}
                validationSchema={Yup.object().shape({
                    title: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.rank.labels.title")})),
                    levelThreshold: Yup.number().min(0).required(t("core:forms.required-field", {fieldName: t("entity.rank.labels.point-threshold")})),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper(entity.id,
                        {
                            title: values.title,
                            image: values.image ? values.image.id : undefined,
                            levelThreshold: values.levelThreshold,
                            explanation: values.explanation,
                            text: values.text
                        } as RankForSaving
                    );
                    upsertEntity(saving);
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                formikState.values.title :
                                t("core:edit-header.heading-empty")}
                        >
                            <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                backPath="/ranks/"/>
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.rank.labels.title")}
                                                type="text"
                                                name="title"
                                                className="form-control default-input"
                                                label={t("entity.rank.labels.title")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.rank.labels.image")}
                                                name="image"
                                                component={InlineAttachmentField}
                                                isSingleSelect={true}
                                                className="form-control default-input"
                                                label={t("entity.rank.labels.image")}/>
                            </div>

                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.rank.labels.explanation")}
                                                type="text"
                                                name="explanation"
                                                className="form-control default-input"
                                                label={t("entity.rank.labels.explanation")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.rank.labels.text")}
                                                type="text"
                                                name="text"
                                                className="form-control default-input"
                                                label={t("entity.rank.labels.text")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    icon={faSortNumericUp}
                                    label={t("entity.rank.labels.point-threshold")}
                                    name="levelThreshold"
                                    formikState={formikState}
                                    type="number"
                                    min={0}
                                    required
                                />
                            </div>
                        </ContentContainer>
                    </Form>
                )}
            </Formik>
        </>
    ) : <LoadingRow/>;
};

export const EditRankForm = WithSingleEntityFromPath<{}, Rank, RankForSaving>(
    withTranslation(["rank", "core"])(EditRankFormComponent),
    ENTITY_TYPE_RANK,
    "rankId",
    createNewRank,
    undefined,
    ENTITY_TYPE_RANK_FOR_SAVING);
