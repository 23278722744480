import React, {useState, Suspense} from "react";
import {useTranslation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery, useMutation, usePaginationFragment} from "react-relay";
import {CustomerMessageTableComponent_Refetch} from "../../../__generated__/CustomerMessageTableComponent_Refetch.graphql";
import {ProgressSpinner} from "primereact/progressspinner";
import {CustomerMessageDefinitionTablePagination} from "./CustomerMessageDefinitionTablePagination";
import {CustomerMessageDefinitionTable} from "./CustomerMessageDefinitionTable";
import {CustomerMessageDefinitionButtons} from "./CustomerMessageDefinitionButtons";
import {CustomerMessageDefinitionOverview_DeleteMutation} from "../../../__generated__/CustomerMessageDefinitionOverview_DeleteMutation.graphql";
import {CustomerMessageDefinitionOverview_PaginationFragment$key} from "../../../__generated__/CustomerMessageDefinitionOverview_PaginationFragment.graphql";
import {CustomerMessageDefinitionOverview_Query} from "../../../__generated__/CustomerMessageDefinitionOverview_Query.graphql";
import {CustomerMessageDefinitionSendMultiple} from "./CustomerMessageDefinitionSendMultiple";
import {CustomerMessageDefinitionTableFilter} from "./CustomerMessageDefinitionTableFilter";
import {Header} from "../../core/components/prime-react/Header";

export enum CustomerMessageVersion {
    V1,
    V2
}

const NUM_ITEMS_PER_PAGE = 5

const QUERY = graphql`
    query CustomerMessageDefinitionOverview_Query($numItemsPerPage: Int!) {
        ...CustomerMessageDefinitionOverview_PaginationFragment @arguments(title: "", first: $numItemsPerPage, after: null)
    }`

const FRAGMENT = graphql`
    fragment CustomerMessageDefinitionOverview_PaginationFragment on Query @refetchable(queryName: "CustomerMessageTableComponent_Refetch") @argumentDefinitions(
        title: {type: "String!"},
        first: {type: "Int!"},
        after: {type: "String"}
    ){
        Admin {
            CustomerMessage {
                CustomerMessageDefinitions(title: $title, first: $first, after: $after) @connection(key: "CustomerMessageTableComponent_CustomerMessageDefinitions") {
                    __id
                    edges {
                        node {
                            id
                            rawId
                            internalTitle
                            title
                        }
                    }
                }
            }
        }
    }`

const DELETE_MUTATION = graphql`
    mutation CustomerMessageDefinitionOverview_DeleteMutation($input: DeleteMultipleCustomerMessageDefinitionInput!, $connections: [ID!]!) {
        Admin {
            CustomerMessage {
                deleteMultipleCustomerMessageDefinition(input: $input) {
                    deletedIds @deleteEdge(connections: $connections)
                }
            }
        }
    }`

export interface CustomerMessageDefinitionTableItem {
    id: string
    rawId: string
    internalTitle: string
    title: string
}

interface State {
    selectedItems: CustomerMessageDefinitionTableItem[]
}

export const CustomerMessageDefinitionOverview = (version: CustomerMessageVersion) => {
    const {t} = useTranslation("customerMessages")

    const query = useLazyLoadQuery<CustomerMessageDefinitionOverview_Query>(QUERY, {numItemsPerPage: NUM_ITEMS_PER_PAGE})

    const {
        data: customerMessageDefinitions,
        hasNext,
        loadNext,
        refetch
    } = usePaginationFragment<CustomerMessageTableComponent_Refetch, CustomerMessageDefinitionOverview_PaginationFragment$key>(FRAGMENT, query)

    const items = customerMessageDefinitions.Admin.CustomerMessage.CustomerMessageDefinitions.edges?.filter(e => !!e).map(e => e!.node) || []

    const [deleteItems] = useMutation<CustomerMessageDefinitionOverview_DeleteMutation>(DELETE_MUTATION)

    const [state, setState] = useState<State>({selectedItems: []})

    return <>
        <Header heading={t('entity.customer-message.plural')}/>

        <CustomerMessageDefinitionSendMultiple selectedItems={state.selectedItems}/>

        <CustomerMessageDefinitionButtons deleteSelectedItems={() => {
            deleteItems({variables: {input: {ids: state.selectedItems.map(si => si.id)}, connections: [customerMessageDefinitions.Admin.CustomerMessage.CustomerMessageDefinitions.__id]}})
            setState(state => ({...state, selectedItems: []}))
        }} numSelectedItems={state.selectedItems.length} version={version}/>

        <CustomerMessageDefinitionTableFilter refetch={(title: string) => refetch({title: title, first: NUM_ITEMS_PER_PAGE})}/>

        <Suspense fallback={<ProgressSpinner/>}>
            <CustomerMessageDefinitionTable items={items} selectedItems={state.selectedItems} selectItems={(items) => setState(state => ({...state, selectedItems: items}))} version={version}/>
        </Suspense>

        <CustomerMessageDefinitionTablePagination hasNext={hasNext} loadNext={() => loadNext(NUM_ITEMS_PER_PAGE)}/>
    </>
};