import React, {useEffect, useState} from "react";

interface OwnProps {
    children: JSX.Element[];
}

export const PaginationEllipses = ({children}: OwnProps) => {
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        setOpen(false);
    }, [children])

    if (children.length < 1) {
        return null;
    }

    return <>
        {open ? children : <li onClick={() => setOpen(true)}>
            ...
        </li>}
    </>
}
