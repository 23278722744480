import React from "react";
import {useTranslation} from "react-i18next";
import {Column} from "primereact/column";
import {NavLink} from "react-router-dom";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {truncateBeginningOfText} from "../../core/utils/utils";
import {CustomerMessageDefinitionTableItem, CustomerMessageVersion} from "./CustomerMessageDefinitionOverview";

interface OwnProps {
    items: CustomerMessageDefinitionTableItem[],
    selectedItems: CustomerMessageDefinitionTableItem[]
    selectItems: (items: CustomerMessageDefinitionTableItem[]) => void
    version: CustomerMessageVersion
}

export const CustomerMessageDefinitionTable = ({items, selectedItems, selectItems, version}: OwnProps) => {
    const {t} = useTranslation("customerMessages")

    return <DataTable className="p-mb-2" value={items} selection={selectedItems} onSelectionChange={e => {
        selectItems(e.value)
    }} dataKey="id">
        <Column selectionMode="multiple"/>
        <Column header={t("entity.customer-message.labels.internal-title")} field="internalTitle"/>
        <Column header={t("entity.customer-message.labels.id")} body={item => truncateBeginningOfText(item.id, 30)}/>
        <Column header={t("entity.customer-message.labels.title")} field="title"/>
        <Column header={t("entity.customer-message.labels.actions")}
                body={
                    item => <NavLink to={"/customer-message" + (version === CustomerMessageVersion.V2 ? "-v2" : "") + `/${item.rawId}`+ (version ===CustomerMessageVersion.V1 ? "/edit" : "")}><Button
                        icon={"pi pi-pencil"}/></NavLink>
                }/>
    </DataTable>
}