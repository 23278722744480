import { Form, FormikState } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { EditModalFooter } from "./EditModalFooter";

interface OwnProps {
    title: string;

    children: React.ReactNode;

    formikState: FormikState<any>;
    handleClose: () => void;
}

const EditModalBaseComponent = (props: OwnProps) => {
    const {title, formikState, handleClose, children} = props;
    return <Form>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {children}
        </Modal.Body>
        <EditModalFooter isSubmitting={formikState.isSubmitting} onClose={handleClose}/>
    </Form>;
};

export const EditModalBase = EditModalBaseComponent;
