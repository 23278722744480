/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CustomerMessageDefinitionOverview_QueryVariables = {
    numItemsPerPage: number;
};
export type CustomerMessageDefinitionOverview_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"CustomerMessageDefinitionOverview_PaginationFragment">;
};
export type CustomerMessageDefinitionOverview_Query = {
    readonly response: CustomerMessageDefinitionOverview_QueryResponse;
    readonly variables: CustomerMessageDefinitionOverview_QueryVariables;
};



/*
query CustomerMessageDefinitionOverview_Query(
  $numItemsPerPage: Int!
) {
  ...CustomerMessageDefinitionOverview_PaginationFragment_2OikB
}

fragment CustomerMessageDefinitionOverview_PaginationFragment_2OikB on Query {
  Admin {
    CustomerMessage {
      CustomerMessageDefinitions(title: "", first: $numItemsPerPage) {
        edges {
          node {
            id
            rawId
            internalTitle
            title
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "numItemsPerPage"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "numItemsPerPage"
  },
  {
    "kind": "Literal",
    "name": "title",
    "value": ""
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageDefinitionOverview_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "CustomerMessageDefinitionOverview_PaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerMessageDefinitionOverview_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageAdminSchema",
            "kind": "LinkedField",
            "name": "CustomerMessage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CustomerMessageDefinitionsConnection",
                "kind": "LinkedField",
                "name": "CustomerMessageDefinitions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "title"
                ],
                "handle": "connection",
                "key": "CustomerMessageTableComponent_CustomerMessageDefinitions",
                "kind": "LinkedHandle",
                "name": "CustomerMessageDefinitions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4137f37576ee5ffa78eef7eae123d17b",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageDefinitionOverview_Query",
    "operationKind": "query",
    "text": "query CustomerMessageDefinitionOverview_Query(\n  $numItemsPerPage: Int!\n) {\n  ...CustomerMessageDefinitionOverview_PaginationFragment_2OikB\n}\n\nfragment CustomerMessageDefinitionOverview_PaginationFragment_2OikB on Query {\n  Admin {\n    CustomerMessage {\n      CustomerMessageDefinitions(title: \"\", first: $numItemsPerPage) {\n        edges {\n          node {\n            id\n            rawId\n            internalTitle\n            title\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fe80f53c82faf71db9bddc7ac57394e5';
export default node;
