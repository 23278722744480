import {FormikState, isObject} from "formik";
import React, {ReactNode} from "react";
import {classNames} from "primereact/utils";

export interface RenderConfig<FieldType> {
    fieldValue: FieldType | undefined,
    updateField: (newValue: FieldType | undefined) => void,
    fieldName: string,
    required: boolean;
    isValid: boolean
}

interface ValidatedFieldPropsV2<State, FieldType> {
    label?: string;
    name: keyof State & string;
    required?: boolean;

    formikConfig: FormikState<State> & {
        setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => any;
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
    }

    helpText?: ReactNode;

    component: (renderConfig: RenderConfig<FieldType>) => ReactNode
}







export function ValidatedFieldV2<State, FieldType>({
                                                       name,
                                                       label,
                                                       formikConfig,
                                                       helpText,
                                                       component,
                                                       required
                                                   }: ValidatedFieldPropsV2<State, FieldType>) {
    const hasError = formikConfig.errors[name] && formikConfig.touched[name];

    const value = formikConfig.values[name] as unknown as FieldType

    const updateValue = (updatedValue: FieldType | undefined) => {
        formikConfig.setFieldTouched(name, true);
        formikConfig.setFieldValue(name, updatedValue);
    }

    let error = undefined;

    if (hasError) {
        if (isObject(formikConfig.errors[name])) {
            error = Object.values(formikConfig.errors[name] as any).join(", ")
        } else {
            error = formikConfig.errors[name]
        }
    }

    return (
        <div className="p-field mb-3">
            <label htmlFor={name}
                   className={classNames({"p-error": hasError})}>{label} {required ? "*" : ""}</label>

            {component({
                fieldValue: value,
                isValid: !hasError,
                fieldName: name,
                updateField: updateValue,
                required: required || false
            })}

            {helpText ? <small>{helpText}</small> : null}
            {hasError ? <div className="p-error">{error}</div> : null}
        </div>
    );
}

