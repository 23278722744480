import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FieldProps} from "formik";
import React from "react";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import update from "immutability-helper";
import {connect} from "react-redux";
import {EntityWrapper, TkFile, WithSelections, WithSelectionsProps} from "@thekeytechnology/framework-react";
import {OwnProps} from "../../common/WithCourseEditorDraggable";
import {selectChildFiles} from "../../../../selectors";
import {Orderable} from "@thekeytechnology/thekey-academy-frontend-library";
import { AttachmentSelect } from "../../../../../core/components/attachment/AttachmentSelect";

interface StateProps {
    childFiles: EntityWrapper<TkFile>[];
}

type Props = FieldProps & WithSelectionsProps<string> & StateProps;

const OrderablesFieldComponent = ({field: {name, value}, childFiles, form, selectionModel}: Props) => {
    const {t} = useTranslation("courses")

    const orderables = value ? value as Orderable[] : [];

    const newOrderable = () => {
        const newAnswers = orderables.concat({imageRef: undefined, text: ""});
        form.setFieldValue(name, newAnswers);
    };

    const updateText = (orderable: Orderable, index: number, text: string) => {
        const newState = update(orderables, {
            [index]: {
                $set:
                    {imageRef: orderable.imageRef, text}
            }
        });
        form.setFieldValue(name, newState);
    };

    const updateImage = (orderable: Orderable, index: number, imageRef: string | undefined) => {
        const newState = update(orderables, {
            [index]: {
                $set: {imageRef, text: orderable.text}
            }
        });
        form.setFieldValue(name, newState);
    };

    const removeOrderable = (index: number) => {
        form.setFieldValue(name,
            update(
                orderables, {$splice: [[index, 1]]}
            )
        );
    };

    return (
        <div className="answer-options d-flex flex-column w-100">
            {orderables.map((orderable: Orderable, index: number) =>
                <Card key={index}>
                    <Card.Body>
                        <h3 className="d-flex align-items-center">
                            {t("course-editor.orderables-field.orderable", {index: index + 1})}
                            <button onClick={() => removeOrderable(index)}
                                    type="button"
                                    className="btn btn-link text-danger">
                                <FontAwesomeIcon icon={faTrash}/></button>
                        </h3>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.orderables-field.orderable-text")}</label>
                            <div className="col-sm-10">
                                <input
                                    value={orderable.text}
                                    className="form-control default-input"
                                    type="text"
                                    onChange={event => updateText(orderable, index, event.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.orderables-field.orderable-image")}</label>
                            <div className="col-sm-10">
                                <AttachmentSelect
                                    selectionModel={selectionModel}
                                    isSingleSelect={true}
                                    value={childFiles.find(file => file.id === orderable.imageRef)}
                                    changeValue={(newValue => updateImage(orderable, index, (newValue as EntityWrapper<TkFile> | undefined)?.id))}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}
            <Card>
                <Card.Body>
                    <button type="button" className="btn-link text-success" onClick={newOrderable}>
                        <FontAwesomeIcon className="mr-2"
                                         icon={faPlus}/>{t("course-editor.orderables-field.new-orderable")}
                    </button>
                </Card.Body>
            </Card>
        </div>
    );
};

export const OrderablesField = connect<StateProps, {}, OwnProps>(
    (state: any) => ({
        childFiles: selectChildFiles(state)
    })
)(WithSelections(OrderablesFieldComponent));
