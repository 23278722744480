/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrdersTableV3_RefetchVariables = {
    after?: string | null;
    first: number;
    firstName?: string | null;
    lastName?: string | null;
    orderNumber?: string | null;
};
export type OrdersTableV3_RefetchResponse = {
    readonly " $fragmentRefs": FragmentRefs<"OrdersTableV3_OrdersList">;
};
export type OrdersTableV3_Refetch = {
    readonly response: OrdersTableV3_RefetchResponse;
    readonly variables: OrdersTableV3_RefetchVariables;
};



/*
query OrdersTableV3_Refetch(
  $after: String
  $first: Int!
  $firstName: String
  $lastName: String
  $orderNumber: String
) {
  ...OrdersTableV3_OrdersList_3HBF5N
}

fragment OrderPriceDisplayV3_OrderFragment on Order {
  status
  cart {
    cartTotals {
      totalsIncludingAllDiscounts {
        netPrice
      }
    }
  }
  selectedPaymentMethod {
    __typename
    ... on MonthlySelectedPaymentMethod {
      chosenOption {
        monthlyRate {
          netPrice
        }
      }
    }
  }
  invoiceData {
    invoiceId
    invoiceNumber
  }
}

fragment OrdersTableV3_OrdersList_3HBF5N on Query {
  Admin {
    Billing {
      Orders(first: $first, after: $after, firstName: $firstName, lastName: $lastName, orderNumber: $orderNumber) {
        edges {
          node {
            id
            createdAt
            status
            ...OrderPriceDisplayV3_OrderFragment
            ...PaymentProviderLinksV3_OrderFragment
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}

fragment PaymentProviderLinksV3_OrderFragment on Order {
  paymentData {
    __typename
    paymentMethod
    dataType
    ... on StripeData {
      paymentIntentId
    }
    ... on StripeSepaData {
      paymentIntentId
    }
  }
  status
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "firstName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lastName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderNumber"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "firstName",
    "variableName": "firstName"
  },
  {
    "kind": "Variable",
    "name": "lastName",
    "variableName": "lastName"
  },
  {
    "kind": "Variable",
    "name": "orderNumber",
    "variableName": "orderNumber"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paymentIntentId",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrdersTableV3_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "OrdersTableV3_OrdersList"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrdersTableV3_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "OrdersConnection",
                "kind": "LinkedField",
                "name": "Orders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrdersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Order",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Cart",
                            "kind": "LinkedField",
                            "name": "cart",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CartTotals",
                                "kind": "LinkedField",
                                "name": "cartTotals",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PriceV3",
                                    "kind": "LinkedField",
                                    "name": "totalsIncludingAllDiscounts",
                                    "plural": false,
                                    "selections": (v2/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "selectedPaymentMethod",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MonthlyOption",
                                    "kind": "LinkedField",
                                    "name": "chosenOption",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PriceV3",
                                        "kind": "LinkedField",
                                        "name": "monthlyRate",
                                        "plural": false,
                                        "selections": (v2/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "MonthlySelectedPaymentMethod",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InvoiceData",
                            "kind": "LinkedField",
                            "name": "invoiceData",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "invoiceId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "invoiceNumber",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "paymentData",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paymentMethod",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dataType",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v4/*: any*/),
                                "type": "StripeData",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v4/*: any*/),
                                "type": "StripeSepaData",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "firstName",
                  "lastName",
                  "orderNumber"
                ],
                "handle": "connection",
                "key": "OrdersTableV3_Orders",
                "kind": "LinkedHandle",
                "name": "Orders"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1de0bc4785f94e5711f46a04802c0c56",
    "id": null,
    "metadata": {},
    "name": "OrdersTableV3_Refetch",
    "operationKind": "query",
    "text": "query OrdersTableV3_Refetch(\n  $after: String\n  $first: Int!\n  $firstName: String\n  $lastName: String\n  $orderNumber: String\n) {\n  ...OrdersTableV3_OrdersList_3HBF5N\n}\n\nfragment OrderPriceDisplayV3_OrderFragment on Order {\n  status\n  cart {\n    cartTotals {\n      totalsIncludingAllDiscounts {\n        netPrice\n      }\n    }\n  }\n  selectedPaymentMethod {\n    __typename\n    ... on MonthlySelectedPaymentMethod {\n      chosenOption {\n        monthlyRate {\n          netPrice\n        }\n      }\n    }\n  }\n  invoiceData {\n    invoiceId\n    invoiceNumber\n  }\n}\n\nfragment OrdersTableV3_OrdersList_3HBF5N on Query {\n  Admin {\n    Billing {\n      Orders(first: $first, after: $after, firstName: $firstName, lastName: $lastName, orderNumber: $orderNumber) {\n        edges {\n          node {\n            id\n            createdAt\n            status\n            ...OrderPriceDisplayV3_OrderFragment\n            ...PaymentProviderLinksV3_OrderFragment\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment PaymentProviderLinksV3_OrderFragment on Order {\n  paymentData {\n    __typename\n    paymentMethod\n    dataType\n    ... on StripeData {\n      paymentIntentId\n    }\n    ... on StripeSepaData {\n      paymentIntentId\n    }\n  }\n  status\n}\n"
  }
};
})();
(node as any).hash = '7e347e5672a194c64342a3a7b45f407a';
export default node;
