import { UserWithRoles } from "@thekeytechnology/framework-react";
import { NotificationOverview } from "./NotificationOverview";
import { EditNotification } from "./notification/EditNotification";
import { EditTriggeredNotifications } from "./triggered-notification/EditTriggeredNotifications";
import { PrivateRouteDefinition } from "../../core/utils/PrivateRouteDefinition";

export const NotificationModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/notifications",
        component: NotificationOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/notifications/:notificationId/edit",
        component: EditNotification,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/triggered-notifications/:notificationId/edit",
        component: EditTriggeredNotifications,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    }
];
