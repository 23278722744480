import {availableButtonCta} from "../../../core/model/cta/button-cta";
import {ButtonSelectField} from "../../../core/components/button/ButtonSelectField";
import React from "react";
import {ENTITY_TYPE_OFFER_V2, OfferV2} from "@thekeytechnology/thekey-academy-frontend-library";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import { ValidatedField } from "../../../core/components/form/ValidatedField";
import { AsyncEntitySelectField } from "../../../core/components/entity/AsyncEntitySelectField";

interface OwnProps {
    formikState: any,
    t: any
}

export const ButtonSelector = ({formikState, t}: OwnProps) => {
    return (
        <>
            <div className="form-group row">
                <ValidatedField label={t("entity.customer-message.labels.button")}
                                name="buttonCta"
                                formikState={formikState}
                                definitions={availableButtonCta}
                                component={ButtonSelectField}
                                required
                />
            </div>
            {
                formikState.values.buttonCta.dataType === 'purchase' ? (
                    <div className="pl-3">
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("entity.customer-message.labels.button-text")}
                                            name="buttonCta.buttonTitle"
                                            className="form-control default-input"
                                            label={t("entity.customer-message.labels.button-text")}
                                            required/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField label={t("entity.customer-message.labels.main-offer-v2")}
                                            name="buttonCta.mainOfferV2Ref"
                                            component={AsyncEntitySelectField}

                                            shownEntityType={ENTITY_TYPE_OFFER_V2}
                                            shownEntityTypeProperties={["entity.title"]}
                                            listRenderer={(item: EntityWrapper<OfferV2>) => item.entity ? `${item.entity.internalTitle} (${item.entity.title})` : ""}
                                            placeholder={t("entity.customer-message.placeholder.main-offer-v2")}
                                            isClearable={true}
                                            formikState={formikState}
                                            required
                            />
                        </div>
                    </div>
                ) : formikState.values.buttonCta.dataType === 'link' ? (
                    <div className="pl-3">
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("entity.customer-message.labels.button-text")}
                                            name="buttonCta.buttonTitle"
                                            className="form-control default-input"
                                            label={t("entity.customer-message.labels.button-text")}
                                            required/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("entity.customer-message.labels.link")}
                                            name="buttonCta.link"
                                            className="form-control default-input"
                                            label={t("entity.customer-message.labels.link")}
                                            required/>
                        </div>
                    </div>
                ) : formikState.values.buttonCta.dataType === 'confirm' ? (
                    <div className="pl-3">
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("entity.customer-message.labels.button-text")}
                                            name="buttonCta.buttonTitle"
                                            className="form-control default-input"
                                            label={t("entity.customer-message.labels.button-text")}
                                            required/>
                        </div>
                    </div>
                ) : null
            }
        </>
    )
}
