/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TargetAudienceInput = {
    selectAccountIds?: Array<string> | null;
    selectUserIds?: Array<string> | null;
    selectHaveBoughtCourses?: Array<string> | null;
    selectHaveNotBoughtCourses?: Array<string> | null;
    selectHaveNotBoughtButStartedCourses?: Array<string> | null;
    selectAllUsers: boolean;
    excludeUserIds?: Array<string> | null;
    excludeHaveBoughtCourses?: Array<string> | null;
    excludeHaveNotBoughtCourses?: Array<string> | null;
    excludeHaveNotBoughtButStartedCourses?: Array<string> | null;
    excludeAdsOptIn?: boolean | null;
};
export type CustomerMessageSender_MutationVariables = {
    customerMessageDefinitionRefs: Array<string>;
    targetAudience: TargetAudienceInput;
};
export type CustomerMessageSender_MutationResponse = {
    readonly Admin: {
        readonly CustomerMessage: {
            readonly sendCustomerMessages: {
                readonly clientMutationId: string | null;
            } | null;
        };
    };
};
export type CustomerMessageSender_Mutation = {
    readonly response: CustomerMessageSender_MutationResponse;
    readonly variables: CustomerMessageSender_MutationVariables;
};



/*
mutation CustomerMessageSender_Mutation(
  $customerMessageDefinitionRefs: [ID!]!
  $targetAudience: TargetAudienceInput!
) {
  Admin {
    CustomerMessage {
      sendCustomerMessages(input: {customerMessageDefinitionRefs: $customerMessageDefinitionRefs, targetAudience: $targetAudience}) {
        clientMutationId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customerMessageDefinitionRefs"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "targetAudience"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerMessageAdminMutationSchema",
        "kind": "LinkedField",
        "name": "CustomerMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "customerMessageDefinitionRefs",
                    "variableName": "customerMessageDefinitionRefs"
                  },
                  {
                    "kind": "Variable",
                    "name": "targetAudience",
                    "variableName": "targetAudience"
                  }
                ],
                "kind": "ObjectValue",
                "name": "input"
              }
            ],
            "concreteType": "CustomerMessageDefinitionRefsWithTargetAudienceInputPayload",
            "kind": "LinkedField",
            "name": "sendCustomerMessages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageSender_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerMessageSender_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0a397e07cd48829b245c0f8feb96b0e2",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageSender_Mutation",
    "operationKind": "mutation",
    "text": "mutation CustomerMessageSender_Mutation(\n  $customerMessageDefinitionRefs: [ID!]!\n  $targetAudience: TargetAudienceInput!\n) {\n  Admin {\n    CustomerMessage {\n      sendCustomerMessages(input: {customerMessageDefinitionRefs: $customerMessageDefinitionRefs, targetAudience: $targetAudience}) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1fbf043d93dfb9db1cdb1b2753194220';
export default node;
