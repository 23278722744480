import graphql from "babel-plugin-relay/macro";
import { useMutation, usePaginationFragment } from "react-relay";
import React from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useHistory } from "react-router-dom";
import { DialogResult, showDialogAction } from "@thekeytechnology/framework-react";
import { useDispatch } from "react-redux";
import {CourseBundleTable_CourseBundleList$key} from "../../../__generated__/CourseBundleTable_CourseBundleList.graphql";
import {
    CourseBundleTable_DeleteCourseBundleMutation
} from "../../../__generated__/CourseBundleTable_DeleteCourseBundleMutation.graphql";
import {CourseBundleTable_Refetch} from "../../../__generated__/CourseBundleTable_Refetch.graphql";

const COURSE_BUNDLES_FRAGMENT = graphql`
    fragment CourseBundleTable_CourseBundleList on Query @refetchable(queryName: "CourseBundleTable_Refetch") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String"},
        filterByTitle: {type: "String"},
    ){
        Admin {
            Bundle {
                CourseBundles(first: $first, after: $after, filterByTitle: $filterByTitle) @connection(key: "CourseBundleTable_CourseBundles") {
                    __id
                    pageInfo {
                        endCursor
                        hasPreviousPage
                        hasNextPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            title
                        }
                    }
                }
            }
        }

    }
`;

const DELETE_MUTATION = graphql`
    mutation CourseBundleTable_DeleteCourseBundleMutation($input: DeleteCourseBundleInput!, $connections: [ID!]!) {
        Admin {
            Bundle{
                deleteCourseBundle(input: $input) {
                    deletedId @deleteEdge(connections: $connections)
                }
            }
        }
    }
`;

interface OwnProps {
    courseBundleListFragmentRef: CourseBundleTable_CourseBundleList$key
}

export const CourseBundleTable = ({courseBundleListFragmentRef}: OwnProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        data,
        hasPrevious,
        hasNext,
        loadPrevious,
        loadNext
    } = usePaginationFragment<CourseBundleTable_Refetch, CourseBundleTable_CourseBundleList$key>(COURSE_BUNDLES_FRAGMENT, courseBundleListFragmentRef)
    const [deleteCourseBundle, isDeleting] = useMutation<CourseBundleTable_DeleteCourseBundleMutation>(DELETE_MUTATION)

    const items = data.Admin.Bundle.CourseBundles.edges?.map(x => x!.node!) || [];
    return <>
        <DataTable className="p-mb-2" value={items}>
            <Column field="title" header="Titel"/>
            <Column header="Aktionen" style={{width: "20%"}} body={item => <>
                <Button
                    className="mr-2"
                    onClick={() => {
                        history.push(`/course-bundles/${item.id}/edit`)
                    }} icon={"pi pi-pencil"}/>

                <Button
                    disabled={isDeleting}
                    onClick={() => {
                        dispatch(showDialogAction({
                            question: "Möchtest du das Bundle wirklich löschen?",
                            title: "Bundle löschen",
                            affirmativeText: "Ja",
                            negativeText: "Nein",
                            explanation: "Dies kann nicht rückgängig gemacht werden"
                        }, result => {
                            if (result === DialogResult.ACCEPT) {
                                deleteCourseBundle({
                                    variables: {
                                        input: {
                                            id: item.id
                                        },
                                        connections: [data.Admin.Bundle.CourseBundles.__id]
                                    }
                                })
                            }
                        }))
                    }} icon={"pi pi-trash"}/>
            </>}/>
        </DataTable>

        <div className="d-flex justify-content-center align-items-center">
            <Button disabled={!hasPrevious} onClick={() => loadPrevious(20)}
                    className="mr-3 p-button-secondary">Zurück</Button>
            <Button className="p-button-secondary" disabled={!hasNext} onClick={() => loadNext(20)}>Weiter</Button>
        </div>
    </>

}
