export class QnaForSaving {
    public static TYPE = "qna";

    public constructor(
        public userRef: string,
        public language: string,
        public question: string,
        public answer: string | undefined,
        public isPublic: boolean,
        public course: string,
        public lesson: string | undefined,
        public element: string | undefined,
        public createdAt: string | undefined
    ) {
    }
}
