import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AttachmentDisplay} from "./AttachmentDisplay";
import {FileSelector} from "./FileSelector";

interface OwnProps {
    fieldValue?: EntityWrapper<TkFile>
    updateField: (newValue?: EntityWrapper<TkFile>) => void
}

export const InlineSingleAttachmentSelectV2 = ({fieldValue, updateField}: OwnProps) => {
    const {t} = useTranslation("files")

    const [state, setState] = useState({
        selectorShown: false
    })

    const onSelectionChange = (selections?: EntityWrapper<TkFile>[]) => {
        const newValue = selections && selections.length > 0 ? selections[0] : undefined
        updateField(newValue)
        setState({
            selectorShown: false
        })
    }

    const remove = () => onSelectionChange(undefined)

    return <>
            {!state.selectorShown && <div className="attachment-select">
                {fieldValue ?
                    <ul className="list-group mb-3">
                        <AttachmentDisplay item={fieldValue as EntityWrapper<TkFile>} remove={remove}/>
                    </ul>
                    : null}
                <button type="button" className="btn btn-secondary" onClick={() => {
                    setState({
                        selectorShown: true
                    });
                }}>
                    {t("attachment-field.browse")}
                </button>
            </div>}
            {state.selectorShown && <FileSelector
                isSingleSelect={true}
                initialSelections={fieldValue ? [(fieldValue as EntityWrapper<TkFile>).id] : []}
                onSelection={onSelectionChange}
                onClose={() => {
                    setState({
                        selectorShown: false
                    });
                }}/>
            }
        </>
}
