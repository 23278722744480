import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ADDITIONAL_CONTENT_TYPE } from "@thekeytechnology/thekey-academy-frontend-library";
import { Filters } from "../../core/components/filters/Filters";
import { TextFilter } from "../../core/components/filters/TextFilter";

const GlossaryFiltersComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <Filters entityType={ADDITIONAL_CONTENT_TYPE}
                 filterKeys={[`entity.title`]}>
            <TextFilter
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.additional-content.labels.title")})}
                entityType={ADDITIONAL_CONTENT_TYPE}
                property={`entity.title`}/>
        </Filters>
    );
};

export const AdditionalContentFilters = withTranslation(["additionalContent", "core"])(GlossaryFiltersComponent);
