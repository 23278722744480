import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addModuleAction } from "../../../actions/module/add-module";
import { AddButton } from "../common/AddButton";
import { DRAGGABLE_TYPES } from "../common/draggable-types";
import { EditModuleForm } from "./EditModuleForm";
import { CourseModule } from "@thekeytechnology/thekey-academy-frontend-library";

interface DispatchProps {
    addModule: typeof addModuleAction
}

type Props = DispatchProps & WithTranslation;

const AddModuleComponent = (props: Props) => {
    const {addModule, t} = props;

    return (
        <AddButton
            acceptableType={DRAGGABLE_TYPES.MODULE}
            title={t("course-editor.add-module.new")}
            dropped={() => {
                // Nothing
            }}
            editForm={(onClose => <EditModuleForm onSubmit={(entity: EntityWrapper<CourseModule>) => {
                addModule(entity);
                onClose();
            }} onClose={onClose}/>)}
        />
    );
};

export const AddModule = connect<{}, DispatchProps, {}>(
    null,
    {
        addModule: addModuleAction
    }
)(withTranslation("courses")(AddModuleComponent));
