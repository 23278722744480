import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { API_LOGIN, ApiCallState, loginAttemptAction, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { ValidatedField } from "../form/ValidatedField";
import { BaseAccountScreen } from "./BaseAccountScreen";

interface StateProps {
    apiCallState: ApiCallState;
}

interface DispatchProps {
    doLogin: (email: string, password: string) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

const LoginScreenComponent = (props: Props) => {
    const {t, apiCallState, doLogin} = props;

    return (
        <BaseAccountScreen>
            <h1 className="text-white text-center mb-5">Login to your account</h1>

            <hr/>
            <Formik
                initialValues={{email: "", password: ""}}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email().required(),
                    password: Yup.string().min(8).required()
                })}
                onSubmit={(values, {setSubmitting}) => {
                    doLogin(values.email, values.password);
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <div className="form-group">
                            <ValidatedField formikState={formikState} icon={faEnvelope}
                                            type="email" placeholder={t("E-Mail")} name="email"
                                            className="form-control default-input" required/>
                        </div>
                        <div className="form-group mb-2">
                            <ValidatedField formikState={formikState} type="password" icon={faLock}
                                            placeholder={t("Password")} name="password"
                                            className="form-control default-input" required/>
                        </div>
                        <div className="small-link mb-5">
                            <NavLink
                                to="/forgot-password">{t("login-screen.forgot-password")}</NavLink>
                        </div>
                        <div className="form-group d-flex mb-3">
                            <button type="submit" disabled={formikState.isSubmitting || apiCallState.inProgress}
                                    className="btn btn-primary align-self-end w-100">
                                {t("login-screen.login")}
                            </button>
                        </div>
                        <div className="text-center paragraph mb-3">
                            {t("login-screen.sign-up-explanation")} <NavLink
                            className="mb-5"
                            to="/register">{t("login-screen.sign-up-link")}</NavLink>
                        </div>
                        <div>
                            {apiCallState.inProgress &&
                            <div className="d-flex justify-content-center">
                                <i className="fa fa-spinner fa-spin fa-3x fa-fw"/>
                            </div>
                            }

                        </div>
                    </Form>
                )}
            </Formik>
        </BaseAccountScreen>
    );
};

export const LoginScreen = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        apiCallState: selectCombinedApiState(API_LOGIN)(state)
    }),
    {doLogin: loginAttemptAction}
)(withTranslation("auth")((LoginScreenComponent)));
