import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reloadEntityListAction, removeFiltersAction, selectFilter } from "@thekeytechnology/framework-react";
import { Filter, FilterTerm, PropertyFilter, addFiltersAction } from "@thekeytechnology/framework-react";
import React from "react";
import { connect } from "react-redux";

interface OwnProps {
    entityType: string;
    property: string;
    placeholder: string;

    filterOperation?: string;
    filterKey?: string;

    min?: number;
    max?: number;
    step?: number;
}

interface StateProps {
    currentFilters?: Filter[];
}

interface DispatchProps {
    addFilters: ReturnType<typeof addFiltersAction>,
    removeFilters: ReturnType<typeof removeFiltersAction>
    reloadEntities: ReturnType<typeof reloadEntityListAction>
}

type Props = OwnProps & StateProps & DispatchProps;

export const UnconnectedNumberFilter = ({
                                   addFilters,
                                   removeFilters,
                                   property,
                                   reloadEntities,
                                   currentFilters,
                                   min,
                                   max,
                                   step,
                                   placeholder,
                                   filterOperation,
                                   filterKey
                               }: Props) => {
    const filter = (event: any) => {
        const text = event.target.value;
        const filterKeyToUse = filterKey ? filterKey : property;

        if (text.length) {
            const filterOperationToUse = filterOperation ? filterOperation : FilterTerm.OPERATION_EQ;
            addFilters(filterKeyToUse, [new PropertyFilter(property,
                new FilterTerm(FilterTerm.TYPE_BIG_DECIMAL, filterOperationToUse, text as number)
            )]);
        } else {
            removeFilters([filterKeyToUse]);
        }
        reloadEntities();
    };

    const currentFilter = currentFilters && currentFilters.length && currentFilters[0] ?
        (currentFilters[0] as PropertyFilter).filterTerm.value : "";

    return (
        <div className="filter text-filter">
            <input type="number"
                   min={min}
                   max={max}
                   step={step}
                   value={currentFilter}
                   className="text-filter default-input" onChange={filter} placeholder={placeholder}/>
            <FontAwesomeIcon icon={faSearch}/>
        </div>
    );
};

export const NumberFilter = connect<StateProps, DispatchProps, OwnProps>(
    (state: any, ownProps: OwnProps) => {
        const filterKeyToUse = ownProps.filterKey ? ownProps.filterKey : ownProps.property;

        return {
            currentFilters: selectFilter(ownProps.entityType, filterKeyToUse)(state)
        };
    },
    (dispatch, ownProps: OwnProps) => {
        return {
            addFilters: (key: string, filters: Filter[]) => dispatch(addFiltersAction(ownProps.entityType)(key, filters)),
            removeFilters: (keys: string[]) => dispatch(removeFiltersAction(ownProps.entityType)(keys)),
            reloadEntities: () => dispatch(reloadEntityListAction(ownProps.entityType)())
        };
    }
)(UnconnectedNumberFilter);
