import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

const NoDataRowComponent = ({t}: WithTranslation) => {
    return (
        <div className="table-row no-data pt-3 pb-3 d-flex justify-content-center text-center align-items-center">
            <span className="mr-3">{t("entity-table.no-data")}</span>
        </div>
    );
};

export const NoDataRow = withTranslation("core")(NoDataRowComponent);
