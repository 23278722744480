import { FieldProps } from "formik";
import { OnboardingPage } from "../model/onboarding";
import { Card } from "react-bootstrap";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { EntityWrapper, TkFile, WithSelections, WithSelectionsProps } from "@thekeytechnology/framework-react";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";
import { BooleanSelect } from "../../core/components/form/BooleanSelect";
import { AttachmentSelect } from "../../core/components/attachment/AttachmentSelect";

interface OwnProps {
    files: EntityWrapper<TkFile>[]
}

type Props = OwnProps & FieldProps & WithSelectionsProps<string>

const OnboardingPagesFieldsComponent = ({form, field, files, selectionModel}: Props) => {
    const pages = field.value as OnboardingPage[]
    const {t} = useTranslation("onboarding")

    const newPage = () => {
        const newPages = pages.concat(
            {
                title: "Titel",
                content: "",
                gradient: undefined,
                hasCloseButton: false,
                imageRef: undefined,
                mainTitle: "Haupttitel",
                videoFileRef: undefined
            } as OnboardingPage
        );
        form.setFieldValue(field.name, newPages);
    };

    const removePage = (index: number) => {
        form.setFieldValue(field.name,
            update(
                pages, {$splice: [[index, 1]]}
            )
        );
    };

    const [selectedImage, setSelectedImage] = useState<{ [index: number]: EntityWrapper<TkFile> | undefined }>(
        pages.length > 0 ? pages.map((p: OnboardingPage, i: number) => ({[i]: files.find(f => f.id === p.imageRef)})).reduce((a, b) => ({...a, ...b})) : {}
    )

    return <div className="onboarding-pages d-flex flex-column w-100">
        {pages.map((page: OnboardingPage, index: number) =>
            <Card key={index}>
                <Card.Body>
                    <h3 className="d-flex align-items-center">
                        {t("onboarding-pages-field.onboarding-page", {index: index + 1})}
                        <button onClick={() => removePage(index)}
                                type="button"
                                className="btn btn-link text-danger">
                            <FontAwesomeIcon icon={faTrash}/></button>
                    </h3>

                    <div className="form-group row">
                        <label htmlFor="colFormLabelSm"
                               className="col-sm-2 col-form-label col-form-label-sm">{t("onboarding-pages-field.title")}</label>
                        <div className="col-sm-10">
                            <input type="text"
                                   className="form-control default-input"
                                   value={page.title}
                                   onChange={event => {
                                       form.setFieldValue(field.name, update(pages, {
                                           [index]: {
                                               $set: {
                                                   ...page,
                                                   title: event.target.value,
                                               }
                                           }
                                       }));
                                   }}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="colFormLabelSm"
                               className="col-sm-2 col-form-label col-form-label-sm">{t("onboarding-pages-field.main-title")}</label>
                        <div className="col-sm-10">
                            <input type="text"
                                   className="form-control default-input"
                                   value={page.mainTitle}
                                   onChange={event => {
                                       form.setFieldValue(field.name, update(pages, {
                                           [index]: {
                                               $set: {
                                                   ...page,
                                                   mainTitle: event.target.value,
                                               }
                                           }
                                       }));
                                   }
                                   }/>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="colFormLabelSm"
                               className="col-sm-2 col-form-label col-form-label-sm">{t("onboarding-pages-field.content")}</label>
                        <div className="col-sm-10">
                            <input type="text"
                                   className="form-control default-input"
                                   value={page.content}
                                   onChange={event => {
                                       form.setFieldValue(field.name, update(pages, {
                                           [index]: {
                                               $set: {
                                                   ...page,
                                                   content: event.target.value,
                                               }
                                           }
                                       }));
                                   }}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="colFormLabelSm"
                               className="col-sm-2 col-form-label col-form-label-sm">{t("onboarding-pages-field.image")}</label>
                        <div className="col-sm-10">
                            <AttachmentSelect
                                selectionModel={selectionModel}
                                isSingleSelect={true}
                                value={selectedImage[index]}
                                changeValue={newValue => {
                                    setSelectedImage({
                                        ...selectedImage,
                                        [index]: (newValue as EntityWrapper<TkFile> | undefined)
                                    });
                                    form.setFieldValue(field.name, update(pages, {
                                        [index]: {
                                            $set: {
                                                ...page,
                                                imageRef: (newValue as EntityWrapper<TkFile> | undefined)?.id
                                            }
                                        }
                                    }))
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="colFormLabelSm"
                               className="col-sm-2 col-form-label col-form-label-sm">{t("onboarding-pages-field.video")}</label>
                        <div className="col-sm-10">
                            <AttachmentSelect
                                selectionModel={selectionModel}
                                isSingleSelect={true}
                                value={files.find(file => file.id === page.videoFileRef)}
                                changeValue={newValue =>
                                    form.setFieldValue(field.name, update(pages, {
                                        [index]: {
                                            $set: {
                                                ...page,
                                                videoFileRef: (newValue as EntityWrapper<TkFile> | undefined)?.id
                                            }
                                        }
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="colFormLabelSm"
                               className="col-sm-2 col-form-label col-form-label-sm">{t("onboarding-pages-field.gradient")}</label>
                        <div className="col-sm-10">
                            <label className="d-flex flex-column">
                                <div>Farbverlauf Farbe oben (zb #ffffff)</div>
                                <input type="text"
                                       className="form-control default-input"
                                       value={page.gradient?.from}
                                       onChange={event => {
                                           form.setFieldValue(field.name, update(pages, {
                                               [index]: {
                                                   $set: {
                                                       ...page,
                                                       gradient: page.gradient ? {
                                                           ...page.gradient,
                                                           from: event.target.value
                                                       } : {from: event.target.value, to: event.target.value}
                                                   }
                                               }
                                           }));
                                       }}
                                />
                            </label>
                            <label className="d-flex flex-column">
                                <div>Farbverlauf Farbe unten (zb #ffffff)</div>
                                <input type="text"
                                       className="form-control default-input"
                                       value={page.gradient?.to}
                                       onChange={event => {
                                           form.setFieldValue(field.name, update(pages, {
                                               [index]: {
                                                   $set: {
                                                       ...page,
                                                       gradient: page.gradient ? {
                                                           ...page.gradient,
                                                           to: event.target.value
                                                       } : {from: event.target.value, to: event.target.value}
                                                   }
                                               }
                                           }));
                                       }}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="colFormLabelSm"
                               className="col-sm-2 col-form-label col-form-label-sm">{t("onboarding-pages-field.has-close-button")}</label>
                        <div className="col-sm-10">
                            <BooleanSelect trueLabel={t("onboarding-pages-field.has-close-button-true")}
                                           falseLabel={t("onboarding-pages-field.has-close-button-false")}
                                           value={page.hasCloseButton}
                                           onChange={newValue => {
                                               form.setFieldValue(field.name, update(pages, {
                                                   [index]: {
                                                       $set: {
                                                           ...page,
                                                           hasCloseButton: newValue
                                                       }
                                                   }
                                               }));
                                           }
                                           }/>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        )}
        <Card>
            <Card.Body>
                <button type="button" className="btn-link text-success" onClick={newPage}>
                    <FontAwesomeIcon className="mr-2" icon={faPlus}/>{t("onboarding-pages-field.new-page")}
                </button>
            </Card.Body>
        </Card>
    </div>

}
export const OnboardingPagesField = WithSelections(OnboardingPagesFieldsComponent)
