import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React, {useState} from "react";
import {CartHelperScreen_ProductsQuery} from "../../../../../__generated__/CartHelperScreen_ProductsQuery.graphql";
import {InputNumber} from "primereact/inputnumber";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {DiscountCode, DiscountsSelect} from "../../../../core/components/entity-selects/DiscountsSelect";

const QUERY = graphql`
    query CartHelperScreen_ProductsQuery {
        Admin {
            Billing {
                Products(includeHidden: false) {
                    edges {
                        node {
                            id
                            title
                        }
                    }
                }
                DiscountActions {
                    edges {
                        node {
                            discountCodes {
                                edges {
                                    node {
                                        code
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        ... DiscountsSelect_QueryFragment @arguments(ids: [])
    }
`;

interface ProductSelection {
    productId: string,
    amount: number
}

interface State {
    selectedProducts: ProductSelection[]
    selectedDiscountCodes: DiscountCode[]
}

export const CartHelperScreen = () => {
    const data = useLazyLoadQuery<CartHelperScreen_ProductsQuery>(QUERY, {
        discountCodeIds: []
    })

    const [state, setState] = useState<State>({selectedProducts: [], selectedDiscountCodes: []})
    const [isCopied, setCopied] = useState(false);

    const stateToCartLinkCleanup = (state: State) => ({
        ...state,
        selectedDiscountCodes: state.selectedDiscountCodes.map(code => code.code)
    })

    const stateString = state.selectedProducts.length === 0 ? "" : btoa(JSON.stringify(stateToCartLinkCleanup(state)))
    const cartLink = `${process.env.REACT_APP_WEBAPP_URL}/checkout/orders-v3/from-cart/${stateString}`;
    return <div>
        <h1 className="p-mb-3">Warenkorb zusammenbauen</h1>

        <div className="p-fluid">
            <h2>Produkte</h2>
            {data.Admin.Billing.Products.edges?.map(edge => {
                return <div key={edge!.node.id} className="p-field mb-3">
                    <label>{edge!.node.title}</label>
                    <InputNumber
                        name={edge!.node.id}
                        key={edge!.node.id}
                        id={edge!.node.id}
                        step={1}
                        min={0}
                        max={100}
                        showButtons={true}
                        value={state.selectedProducts.find(ps => ps.productId === edge!.node.id)?.amount}
                        onChange={e => {
                            setState({
                                ...state,
                                selectedProducts: [
                                    ...state.selectedProducts.filter(ps => ps.productId !== edge!.node.id),
                                    ...(e.value === 0 ? [] : [{productId: edge!.node.id, amount: e.value}])
                                ]
                            })
                            setCopied(false)
                        }}
                    />
                </div>
            })}
            <h2>Rabatte</h2>
            <DiscountsSelect
                fieldValue={state.selectedDiscountCodes.map(code => code.id)}
                onChange={(e: DiscountCode[]) => {
                    setState({
                        ...state,
                        selectedDiscountCodes: e
                    })
                    setCopied(false)
                }}
                queryFragmentRef={data}/>

            <h2>Warenkorb-Link</h2>
            <div className="mb-5">
                <a target="_blank" rel={"nofollow noreferrer"} href={cartLink}>
                    {cartLink}
                </a>
            </div>


            <CopyToClipboard text={cartLink}
                             onCopy={() => setCopied(true)}>
                <button>
                    {isCopied ? "Kopiert..." : "In die Zwischenablage kopieren"}
                </button>
            </CopyToClipboard>

            <div>{JSON.stringify(stateToCartLinkCleanup(state))}</div>
        </div>
    </div>
}
