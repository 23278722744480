import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { EntityWrapper, selectCurrentUser, UserWithRoles } from "@thekeytechnology/framework-react";

export interface OnlyNonLoggedInRouteProps {
    component: any,

    // Indexer
    [x: string]: any;
}

interface StateProps {
    currentUser?: EntityWrapper<UserWithRoles>
}

type Props = OnlyNonLoggedInRouteProps & StateProps;

const OnlyNonLoggedInRouteComponent = (props: Props) => {
    const {currentUser, component, ...rest} = props;

    const Component = component;

    return <Route
        {...rest}
        render={innerProps => (
            !currentUser
                ? <Component {...innerProps} />
                : <Redirect to="/"/>
        )}
    />
};

export const OnlyNonLoggedInRoute = connect<StateProps, {}, OnlyNonLoggedInRouteProps>(
    state => ({currentUser: selectCurrentUser(state)})
)(OnlyNonLoggedInRouteComponent);
