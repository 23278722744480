import {faLink, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {FieldProps} from "formik";
import update from "immutability-helper";
import React from "react";
import {Card} from "react-bootstrap";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect} from "react-redux";
import {extractId} from "../../../../../reducers/changes/common/helpers";
import {selectOtherElementInLessonOf} from "../../../../../selectors";
import {ConnectionTypeSelect} from "./ConnectionTypeSelect";
import {ElementInCourseSelect} from "./ElementInCourseSelect";
import {
    Connection,
    CONNECTION_TYPE_SOURCE,
    CourseElement,
    ELEMENT_TYPE_TEXT,
    ELEMENT_TYPE_VIDEO
} from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    currentElementId: string;
}

interface StateProps {
    availableElements: EntityWrapper<CourseElement>[]
}

type Props = StateProps & FieldProps & WithTranslation;

const ConnectionsFieldComponent = (props: Props) => {

    const {t, form, field, availableElements} = props;

    const currentConnections = props.field.value ? props.field.value as Connection[] : [];

    const removeConnection = (index: number) => {
        form.setFieldValue(field.name,
            update(
                currentConnections, {$splice: [[index, 1]]}
            )
        );
    };

    const newConnection = () => {
        const newAnswers = currentConnections.concat(
            {
                targetCourseRef: "",
                targetLessonRef: "",
                targetModuleRef: "",
                targetElementRef: extractId(availableElements[0]),
                connectionType: CONNECTION_TYPE_SOURCE
            }
        );
        form.setFieldValue(field.name, newAnswers);
    };

    const updateConnection = (index: number, newTargetELement: string, newType: string, lineNumber: number | undefined, timestamp: number | undefined) => {
        const newState = update(currentConnections, {
            [index]: {
                $set: {
                    targetCourseRef: "",
                    targetLessonRef: "",
                    targetModuleRef: "",
                    targetElementRef: newTargetELement,
                    connectionType: newType,
                    lineNumber,
                    timestamp
                }
            }
        });
        form.setFieldValue(field.name, newState);
    };

    const updateConnectionTargetRef = (connection: Connection, index: number, updateElement: EntityWrapper<CourseElement>) => {
        updateConnection(index, extractId(updateElement), connection.connectionType, connection.lineNumber, connection.timestamp);
    };

    const updateConnectionType = (connection: Connection, index: number, newType: string) => {
        updateConnection(index, connection.targetElementRef, newType, connection.lineNumber, connection.timestamp);
    };

    const updateLineNumber = (connection: Connection, index: number, lineNumber: number) => {
        updateConnection(index, connection.targetElementRef, connection.connectionType, lineNumber, connection.timestamp);
    };

    const updateTimestamp = (connection: Connection, index: number, timestamp: number) => {
        updateConnection(index, connection.targetElementRef, connection.connectionType, connection.lineNumber, timestamp);
    };

    return <>
        <div className="answer-options d-flex flex-column w-100">
            {currentConnections.map((connection: Connection, index: number) => {
                const referredElement = availableElements.find(el => extractId(el) === connection.targetElementRef);

                return (
                    <Card key={index}>
                        <Card.Body>
                            <h3 className="d-flex align-items-center">
                                {t("course-editor.connections-field.connection", {index: index + 1})}
                                <button onClick={() => removeConnection(index)}
                                        type="button"
                                        className="btn btn-link text-danger">
                                    <FontAwesomeIcon icon={faTrash}/></button>
                            </h3>

                            {!referredElement ? <div
                                className="alert alert-warning">{t("course-editor.connections-field.stale-warning")}</div> : null}

                            <div className="form-group row">
                                <label htmlFor="colFormLabelSm"
                                       className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.connections-field.target-label")}</label>
                                <div className="col-sm-10">
                                    <ElementInCourseSelect
                                        availableElements={availableElements}
                                        selectedElementId={connection.targetElementRef}
                                        newElementSelected={(newElement: EntityWrapper<CourseElement>) =>
                                            updateConnectionTargetRef(connection, index, newElement)}/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="colFormLabelSm"
                                       className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.connections-field.connection-type-label")}</label>
                                <div className="col-sm-10 d-flex align-items-center">
                                    <ConnectionTypeSelect
                                        connectionType={connection.connectionType}
                                        connectionTypeChanged={(newConnectionType: string) =>
                                            updateConnectionType(connection, index, newConnectionType)}
                                    />
                                </div>
                            </div>

                            {referredElement && referredElement.entity.elementType === ELEMENT_TYPE_TEXT ?
                                <div className="form-group row">
                                    <label htmlFor="colFormLabelSm"
                                           className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.connections-field.line-number-label")}</label>
                                    <div className="col-sm-10 d-flex align-items-center">
                                        <div className="input-field-wrapper w-100">
                                            <FontAwesomeIcon className="input-icon" icon={faLink}/>
                                            <input type="number"
                                                   className="form-control default-input"
                                                   min={1}
                                                   required
                                                   value={connection.lineNumber}
                                                   onChange={event => updateLineNumber(connection, index, parseInt(event.target.value, 10))}/>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {referredElement && referredElement.entity.elementType === ELEMENT_TYPE_VIDEO ?
                                <div className="form-group row">
                                    <label htmlFor="colFormLabelSm"
                                           className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.connections-field.timestamp-label")}</label>
                                    <div className="col-sm-10 d-flex align-items-center">
                                        <div className="input-field-wrapper w-100">
                                            <FontAwesomeIcon className="input-icon" icon={faLink}/>
                                            <input type="number"
                                                   className="form-control default-input"
                                                   min={0}
                                                   required
                                                   value={connection.timestamp}
                                                   onChange={event => updateTimestamp(connection, index, parseInt(event.target.value, 10))}/>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </Card.Body>
                    </Card>
                );
            })}
            <Card>
                <Card.Body>
                    {availableElements.length > 0 ? <button type="button" className="btn-link text-success" onClick={newConnection}>
                        <FontAwesomeIcon className="mr-2"
                                         icon={faPlus}/>{t("course-editor.connections-field.new-connection")}
                    </button> : <p className="mb-0">{t("course-editor.connections-field.no-elements")}</p>}
                </Card.Body>
            </Card>
        </div>
    </>;
};

export const ConnectionsField = connect<StateProps, {}, OwnProps>(
    (state: any, {
        currentElementId
    }: OwnProps) => ({
        availableElements: selectOtherElementInLessonOf(currentElementId)(state)
    })
)(withTranslation("courses")(ConnectionsFieldComponent));
