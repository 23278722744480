import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { EntityWrapper, selectCurrentUser, UserWithRoles } from "@thekeytechnology/framework-react";

export interface PrivateRouteProps {
    component: any;
    requiredRoles?: string[];

    // Indexer
    [x: string]: any;
}

interface StateProps {
    user?: EntityWrapper<UserWithRoles>;
}

type Props = PrivateRouteProps & StateProps;

const PrivateRouteComponent = (outerProps: Props) => {
    const {component: Component, user, requiredRoles, ...rest} = outerProps;

    return (
        <Route
            {...rest}
            render={props => {
                if (!user) {
                    return <Redirect to="/login"/>;
                } else {
                    const requiredRolesSafe = requiredRoles ? requiredRoles : [];
                    const roleIntersection = requiredRolesSafe.filter(x => user.entity.roles.includes(x));

                    const hasRequiredRoles = requiredRoles == null ? true : roleIntersection.length > 0;

                    return hasRequiredRoles ? <Component {...props} {...rest}/> : <Redirect to="/"/>;
                }
            }}
        />
    );
};

export const PrivateRoute = connect<StateProps, {}, PrivateRouteProps>(state => ({
    user: selectCurrentUser(state),
}))(PrivateRouteComponent);
