import React from "react";
import {useTranslation} from "react-i18next";
import {useLazyLoadQuery} from "react-relay";
import {useRouteMatch} from "react-router";
import {graphql} from "babel-plugin-relay/macro";
import {Header} from "../../../core/components/prime-react/Header";
import {EditCustomerMessageDefinitionV2CoreForm} from "./EditCustomerMessageDefinitionV2CoreForm";
import {CustomerMessageDefinitionPages} from "./edit-customer-message-definition-pages/CustomerMessageDefinitionPages";
import {EditCustomerMessageDefinitionV2Screen_Query} from "../../../../__generated__/EditCustomerMessageDefinitionV2Screen_Query.graphql";

const QUERY = graphql`
    query EditCustomerMessageDefinitionV2Screen_Query($id: ID!) {
        node(id: $id) {
            ... on CustomerMessageDefinitionV2 {
                internalTitle
                ...CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment
                ...EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment
            }
        }
    }`

export const EditCustomerMessageDefinitionV2Screen = () => {
    const {t: tCMV2} = useTranslation("customerMessagesV2")

    const {params: {customerMessageDefinitionId}} = useRouteMatch<{ customerMessageDefinitionId: string }>()

    const query = useLazyLoadQuery<EditCustomerMessageDefinitionV2Screen_Query>(QUERY, {id: customerMessageDefinitionId})

    return query.node && <>
        <Header
            heading={tCMV2("edit-customer-message-definition-v2.heading", {internalTitle: query.node?.internalTitle})}/>
        <EditCustomerMessageDefinitionV2CoreForm customerMessageDefinitionV2FragmentRef={query.node}/>
        <CustomerMessageDefinitionPages customerMessageDefinitionV2FragmentRef={query.node}/>
    </>
}