import { EntityWrapper } from "@thekeytechnology/framework-react";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { moveModuleAction } from "../../../actions/module/move-module";
import { extractId } from "../../../reducers/changes/common/helpers";
import { AddModule } from "./AddModule";
import { ModuleItem } from "./ModuleItem";
import { CourseModule } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    modules: EntityWrapper<CourseModule>[];
}

interface DispatchProps {
    moveModule: typeof moveModuleAction
}

type Props = OwnProps & DispatchProps;

const ModuleListComponent = (props: Props) => {
    const {modules, moveModule} = props;

    const moveModuleMemoized = useCallback(moveModule, [moveModule, modules]);

    return <>
        {modules.map((module: EntityWrapper<CourseModule>, index: number) =>
            <ModuleItem key={extractId(module)}
                        moduleIndex={index}
                        index={index}
                        module={module}
                        dropped={(dragIndex: number, dropIndex: number) => moveModuleMemoized(dragIndex, dropIndex)}
            />)
        }
        <AddModule/>
    </>;
};

export const ModuleList = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        moveModule: moveModuleAction,
    }
)(ModuleListComponent);
