import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Account, EntityWrapper, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { API_UPDATE_USER } from "../../../actions/update-user";
import { updateAccountAction } from "../../../actions/update-account";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";

export interface OwnProps {
    account: EntityWrapper<Account>
}

export const EditAccountData = ({account}: OwnProps) => {
    const {t} = useTranslation("admin")

    const apiCallState = useSelector(selectCombinedApiState(API_UPDATE_USER))
    const dispatch = useDispatch();

    return <Formik
        initialValues={{
            name: account.entity.name,
        }}
        validationSchema={Yup.object().shape({
            name: Yup.string().min(3),
        })}
        onSubmit={(values, {setSubmitting}) => {
            dispatch(updateAccountAction(
                account.id!,
                values.name === account.entity.name ? undefined : values.name
            ));
            setSubmitting(false);
        }}
    >
        {formikState => (
            <Form>
                <div className="form-group row">
                    <ValidatedField formikState={formikState}
                                    placeholder={t("entity.account.labels.name")}
                                    type="text"
                                    name="name"
                                    className="form-control default-input"
                                    label={t("entity.account.labels.name")}
                    />
                </div>
                <button disabled={apiCallState.inProgress} className="btn btn-success">
                    {apiCallState.succeeded ? "✓" : "Speichern"}
                </button>
            </Form>
        )}
    </Formik>
}
