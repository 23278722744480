import {NavLink, Switch} from "react-router-dom";
import {Menubar} from "primereact/menubar";
import {MenuItem, MenuItemOptions} from "primereact/menuitem";
import {Card} from "primereact/card";
import React, {Suspense} from "react";
import {GlossaryModuleRoutes} from "./glossary/GlossaryModuleRoutes";
import {AdditionalContentModuleRoutes} from "./additionalcontent/AdditionalContentModuleRoutes";
import {ExpertModuleRoutes} from "./expert/ExpertModuleRoutes";
import {QnaModuleRoutes} from "./qna/QnaModuleRoutes";
import {RankModuleRoutes} from "./rank/RankModuleRoutes";
import {SettingsModuleRoutes} from "./settings/SettingsModuleRoutes";
import {FAQModuleRoutes} from "./faq/FAQModuleRoutes";
import {CustomerMessageRoutes} from "./customer-messages/CustomerMessageRoutes";
import {EmailModuleRoutes} from "./emails/components/EmailModuleRoutes";
import {NotificationModuleRoutes} from "./notifications/components/NotificationModuleRoutes";
import {AchievementModuleRoutes} from "./achievements/components/AchievementModuleRoutes";
import {UnlockModuleRoutes} from "./unlocks/components/UnlockModuleRoutes";
import {TriggersModuleRoutes} from "./triggers/TriggersModuleRoutes";
import {SyncRequestsModuleRoutes} from "./sync-requests/SyncRequestModuleRouter";
import {BillingModuleRoutes} from "./billing/BillingModuleRoutes";
import {FeedbackModuleRoutes} from "./feedback/FeedbackModuleRoues";
import {AdminModuleRoutes} from "./admin/component/AdminModuleRoutes";
import {OnboardingModuleRoutes} from "./onboarding/OnboardingModuleRoutes";
import {Button} from "primereact/button";
import {useDispatch} from "react-redux";
import {logoutAction} from "@thekeytechnology/framework-react";
import {CourseModuleRoutes} from "./course/CourseModuleRoutes";
import {OnlyNonLoggedInRoute} from "./core/components/navigation/OnlyNonLoggedInRoute";
import {PrivateRoute} from "./core/components/navigation/PrivateRoute";
import {LoginScreen} from "./core/components/login/LoginScreen";
import {EnvironmentBadge} from "./EnvironmentBadge";
import {CustomerMessageRoutesV2} from "./customer-messages-v2/CustomerMessageRoutes";
import {CourseBundleModuleRoutes} from "./bundles/CourseBundleRoutes";

const MenuTemplate = (item: MenuItem, options: MenuItemOptions) => {
    return <NavLink className={options.className} target={item.target} to={item.url!}>{item.label}</NavLink>
};

const items: MenuItem[] = [
    {
        label: "Kurse & Zugehöriges",
        items: [
            {
                label: "Kurse",
                url: "/courses",
                template: MenuTemplate
            },
            {
                label: "Material",
                url: "/additional-content",
                template: MenuTemplate
            },
            {
                label: "Glossar",
                url: "/glossary",
                template: MenuTemplate
            },
            {
                label: "Experten",
                url: "/experts",
                template: MenuTemplate
            },
        ],
    },
    {
        label: "Kommunikation",
        items: [
            {
                label: "Kundennachrichten",
                url: "/customer-message",
                template: MenuTemplate
            },
            {
                label: "Kundennachrichten V2",
                url: "/customer-message-v2",
                template: MenuTemplate
            },
            {
                label: "QNAs",
                url: "/qnas",
                template: MenuTemplate
            },
            {
                label: "FAQs",
                url: "/faqs",
                template: MenuTemplate
            },
            {
                label: "Feedback",
                url: "/feedbacks",
                template: MenuTemplate
            },
            {
                label: "Onboardings",
                url: "/onboardings",
                template: MenuTemplate
            },
        ],
    },
    {
        label: "Gamification",
        items: [
            {
                label: "Ränge",
                url: "/ranks",
                template: MenuTemplate
            },
        ],
    },
    {
        label: "Billing V2",
        items: [
            {
                label: "Angebote",
                url: "/offers-v2",
                template: MenuTemplate
            },
            {
                label: "Rabattcodes",
                url: "/vouchers",
                template: MenuTemplate
            },
            {
                label: "Bestellungen",
                url: "/orders",
                template: MenuTemplate
            }
        ],
    },
    {
        label: "Billing V3",
        items: [
            {
                label: "Produkte",
                url: "/products",
                template: MenuTemplate
            },
            {
                label: "Bundles",
                url: "/course-bundles",
                template: MenuTemplate
            },
            {
                label: "Rabattaktionen",
                url: "/discount-actions",
                template: MenuTemplate
            },
            {
                label: "Bestellung",
                url: "/orders-v3",
                template: MenuTemplate
            },
            {
                label: "Warenkorb-Generator",
                url: "/cart-helper",
                template: MenuTemplate
            },
        ]
    },
    {
        label: "Kunden",
        items: [
            {
                label: "Nutzer",
                url: "/users",
                template: MenuTemplate
            },
            {
                label: "Konten",
                url: "/accounts",
                template: MenuTemplate
            },
            {
                label: "Zertifikate-Download",
                url: "/certificate-download",
                template: MenuTemplate
            },
        ]
    },
    {
        label: "Downloads",
        items: [
            {
                label: "Bestellungen",
                url: "/billing-download",
                template: MenuTemplate
            },
            {
                label: "Zertifikate",
                url: "/certificate-download",
                template: MenuTemplate
            },
        ]
    },
    {
        label: "Automatische Auslöser",
        items: [
            {
                label: "E-Mails",
                url: "/emails",
                template: MenuTemplate
            },
            {
                label: "Benachrichtigungen",
                url: "/notifications",
                template: MenuTemplate
            },
            {
                label: "Freischaltungen",
                url: "/unlocks",
                template: MenuTemplate
            },
            {
                label: "Auszeichnungen",
                url: "/achievements",
                template: MenuTemplate
            },
        ]
    },
    {
        label: "Debug",
        items: [
            {
                label: "Trigger-Executions",
                url: "/trigger-executions",
                template: MenuTemplate
            },
            {
                label: "Sync Requests",
                url: "/sync-requests",
                template: MenuTemplate
            },
        ]
    },
    {
        label: "Einstellungen",
        url: "/settings",
        template: MenuTemplate
    },
]

export const MainNavigation = () => {
    const dispatch = useDispatch();
    return <div className="h-100">
        <Menubar model={items} end={() => <Button onClick={() => dispatch(logoutAction())}
                                                  label={"logout"}><EnvironmentBadge/></Button>}/>
        <div className="p-grid p-justify-center p-mt-5">
            <Card className="p-col p-ml-6 p-mr-6 shadow-none">
                <Suspense fallback={<div>Lade...</div>}>
                    <Switch>
                        <OnlyNonLoggedInRoute exact path="/login" component={LoginScreen}/>
                        {[
                            ...GlossaryModuleRoutes,
                            ...CourseModuleRoutes,
                            ...AdditionalContentModuleRoutes,
                            ...ExpertModuleRoutes,
                            ...QnaModuleRoutes,
                            ...RankModuleRoutes,
                            ...SettingsModuleRoutes,
                            ...FAQModuleRoutes,
                            ...CustomerMessageRoutes,
                            ...CustomerMessageRoutesV2,
                            ...EmailModuleRoutes,
                            ...NotificationModuleRoutes,
                            ...AchievementModuleRoutes,
                            ...UnlockModuleRoutes,
                            ...TriggersModuleRoutes,
                            ...SyncRequestsModuleRoutes,
                            ...BillingModuleRoutes,
                            ...FeedbackModuleRoutes,
                            ...AdminModuleRoutes,
                            ...OnboardingModuleRoutes,
                            ...CourseBundleModuleRoutes
                        ].map(prd => {
                            return <PrivateRoute
                                key={prd.path}
                                exact={true}
                                requiredRoles={prd.requiredRoles}
                                path={prd.path} component={prd.component}/>
                        })}
                    </Switch>
                </Suspense>
            </Card>
        </div>
    </div>
}
