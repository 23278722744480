import {Pagination} from "@thekeytechnology/framework-react";
import React from "react";
import {useTranslation} from "react-i18next";
import "./pagination-info.scss"

interface OwnProps {
    pagination: Pagination;
    className?: string;
}

export const UnconnectedPaginationInfo = ({pagination, className}: OwnProps) => {

    const {t} = useTranslation(["core"]);

    if (!pagination || pagination.totalDocs === 0) {
        return <div className="pagination-info">&nbsp;</div>;
    }
    const currentRangeStart = pagination.docsPerPage * pagination.currentPage;
    const currentRangeEnd = pagination.currentPage + 1 === pagination.totalPages ?
        pagination.totalDocs : currentRangeStart + pagination.docsPerPage;
    return (
        <div className={"pagination-info " + (className ? className : "")}>
            {t("pagination-info.page", {
                currentPage: pagination.currentPage + 1,
                totalPages: pagination.totalPages,
                currentRangeStart: currentRangeStart + 1,
                currentRangeEnd,
                totalDocs: pagination.totalDocs
            })}
        </div>
    );
};
