import { UserWithRoles } from "@thekeytechnology/framework-react";
import { EditOnboardingForm } from "./components/EditOnboarding";
import { OnboardingOverview } from "./components/OnboardingOverview";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";

export const OnboardingModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/onboardings",
        component: OnboardingOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/onboardings/:onboardingId/edit",
        component: EditOnboardingForm,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
];
