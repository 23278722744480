import React from "react";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";

interface OwnProps {
    createCustomerMessageDefinition: () => void
}

export const CustomerMessageDefinitionV2CreateButton = ({createCustomerMessageDefinition}: OwnProps) => {

    const {t: tCMV2} = useTranslation("customerMessagesV2")

    return <div className="flex justify-content-start pb-4">
        <Button label={tCMV2("overview.create-customer-message-definition.button")} onClick={createCustomerMessageDefinition}/>
    </div>
}