import { Form, Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { PriceField } from "../offer/edit/PriceField";
import {
    ENTITY_TYPE_OFFER_V2,
    ENTITY_TYPE_VOUCHER, NEW_VOUCHER_FACTORY,
    OfferV2,
    Voucher
} from "@thekeytechnology/thekey-academy-frontend-library";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../../core/components/entity/WithSingleEntityFromPath";
import { LoadingEditHeader } from "../../../../../core/components/header/LoadingEditHeader";
import { EditHeaderWithBackground } from "../../../../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../../../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../../../../core/components/containers/Container";
import { ValidatedField } from "../../../../../core/components/form/ValidatedField";
import { AsyncEntitySelectField } from "../../../../../core/components/entity/AsyncEntitySelectField";
import { LoadingRow } from "../../../../../core/components/table/LoadingRow";

type Props = WithSingleEntityFromPathProps<Voucher, Voucher> & WithTranslation;

const EditVoucherComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return entity ? (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.voucher.singular")}/>

            <Formik
                initialValues={{
                    code: entity.entity.code,
                    usageLimit: entity.entity.usageLimit,
                    price: entity.entity.price,
                    limitToOfferRef: entity.entity.limitToOfferRef ? {id: entity.entity.limitToOfferRef} : undefined,
                    uses: entity.entity.uses,
                }}
                validationSchema={Yup.object().shape({
                    code: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.voucher.labels.code")})),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper<Voucher>(entity.id,
                        {
                            code: values.code,
                            usageLimit: values.usageLimit,
                            price: values.price,
                            limitToOfferRef: values.limitToOfferRef ? values.limitToOfferRef.id : undefined,
                            uses: values.uses
                        }
                    );
                    upsertEntity(saving);
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                formikState.values.code :
                                t("core:edit-header.heading-empty")}
                        >
                            <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                backPath="/vouchers/"/>
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.voucher.labels.title")}
                                                type="text"
                                                name="code"
                                                className="form-control default-input"
                                                label={t("entity.voucher.labels.code")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    label={t("entity.voucher.labels.price")}
                                    name="price"
                                    component={PriceField}
                                    disableDiscount={true}
                                    formikState={formikState}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    label={t("entity.voucher.labels.limit-to-offer")}
                                    name="limitToOfferRef"
                                    component={AsyncEntitySelectField}
                                    placeholder={"Nicht auf ein bestimmtes Angebot beschränkt."}
                                    listRenderer={(value: EntityWrapper<OfferV2>) => value?.entity?.internalTitle}
                                    formikState={formikState}
                                    shownEntityType={ENTITY_TYPE_OFFER_V2}
                                    shownEntityTypeProperties={["entity.internalTitle"]}
                                    isMulti={false}
                                    isClearable={true}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.voucher.labels.usage-limit")}
                                                type="number"
                                                min={0}
                                                step={1}
                                                name="usageLimit"
                                                className="form-control default-input"
                                                label={t("entity.voucher.labels.usage-limit")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.voucher.labels.uses")}
                                                type="number"
                                                min={0}
                                                step={1}
                                                name="uses"
                                                className="form-control default-input"
                                                label={t("entity.voucher.labels.uses")}
                                />
                            </div>
                        </ContentContainer>
                    </Form>
                )}
            </Formik>
        </>
    ) : <LoadingRow/>;
};

export const EditVoucher = WithSingleEntityFromPath<{}, Voucher, Voucher>(
    withTranslation(["billing", "core"])(EditVoucherComponent),
    ENTITY_TYPE_VOUCHER,
    "voucherId",
    NEW_VOUCHER_FACTORY,
    undefined);
