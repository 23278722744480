import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import "./triggered-notification-table.scss";
import {withTranslation, WithTranslation} from "react-i18next";
import {ENTITY_TYPE_TRIGGERED_NOTIFICATION, TriggeredNotification} from "../../model/triggered-notification";
import { AboveTableContainer } from "../../../core/components/containers/Container";
import { PaginationInfo } from "../../../core/components/pagination/PaginationInfo";
import { EntityCreateAndDeleteButtons } from "../../../core/components/button/EntityCreateAndDeleteButtons";
import { Table, TableColumn } from "../../../core/components/table/Table";
import { EntityTableHeader } from "../../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../../core/components/table/AsyncEntityTableBody";
import { EntityTableRow } from "../../../core/components/table/EntityTableRow";
import { ActionLink } from "../../../core/components/icon-link-tooltip/ActionLink";

type Props = WithEntityListProps<TriggeredNotification> & WithTranslation & WithSelectionsProps<string>;

const TriggeredNotificationTableComponent = ({
                                                 entities,
                                                 entityApiState,
                                                 t,
                                                 selectionModel
                                             }: Props) => {

    return (<>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_TRIGGERED_NOTIFICATION} className="mr-auto"/>

                <EntityCreateAndDeleteButtons
                    selectedEntities={selectionModel.selections}
                    entityType={ENTITY_TYPE_TRIGGERED_NOTIFICATION}
                    createPath="/triggered-notifications/new/edit"
                    label={t("entity.triggered-notification.singular")}
                    labelPlural={t("entity.triggered-notification.plural")}
                />
            </AboveTableContainer>
            <Table className="triggered-notification-table" itemCount={entities.length}>
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <div className="table-column">{t("entity.triggered-notification.labels.title")}</div>
                    <div className="table-column">{t("entity.triggered-notification.labels.subject")}</div>
                    <div className="table-column">{t("entity.triggered-notification.labels.trigger")}</div>
                    <div className="table-column">{t("triggered-notification-table.actions")}</div>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={notification =>
                        <EntityTableRow
                            key={notification.id}
                            entity={notification} selectionModel={selectionModel}>
                            <TableColumn className="bold">
                                {notification.entity.title}
                            </TableColumn>
                            <TableColumn>
                                {notification.entity.subjects.de}
                            </TableColumn>
                            <TableColumn>
                                {t("triggers:trigger-field.trigger-types." + notification.entity.trigger?.key)}
                            </TableColumn>
                            <TableColumn>
                                <ActionLink
                                    to={"/triggered-notifications/" + notification.id + "/edit"}
                                    tooltip={t("Edit notification")}
                                    icon={faPencilAlt}/>
                            </TableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const TriggeredNotificationsTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation(["notifications", "triggers"])(TriggeredNotificationTableComponent),
        ENTITY_TYPE_TRIGGERED_NOTIFICATION
    )
);
