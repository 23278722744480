import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { OffersV2Table } from "./OffersV2Table";
import { ENTITY_TYPE_OFFER_V2 } from "@thekeytechnology/thekey-academy-frontend-library";
import {
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../../../../core/components/containers/Container";
import { SimpleHeader } from "../../../../../core/components/header/SimpleHeader";
import { Pagination } from "../../../../../core/components/pagination/Pagination";

const OffersOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/offers-v2"}>{t("entity.offer.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.offer.plural") + " V2"}/>
            </HeadingContainer>
            <ContentContainer>
                <OffersV2Table/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_OFFER_V2}/>
            </PaginationContainer>
        </>
    );
};

export const OffersV2Overview = withTranslation("billing")(OffersOverviewComponent);
