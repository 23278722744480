import React, {useEffect} from "react";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {Account, EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountMetaAdmin} from "../../../selectors";
import {API_FETCH_ACCOUNT_META_ADMIN, fetchAccountMetaAction} from "../../../actions/fetch-account-meta";
import {API_UPDATE_ACCOUNT_META, updateAccountMetaAction} from "../../../actions/update-account-meta";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {ValidatedField} from "../../../../core/components/form/ValidatedField";
import {SimpleOptionSelectField} from "../../../../course/components/course-editor/course/SimpleOptionSelectField";
import {LoadingRow} from "../../../../core/components/table/LoadingRow";
import {BooleanSelectField} from "../../../../core/components/select/BooleanSelectField";

export interface OwnProps {
    account: EntityWrapper<Account>
}

export const EditAccountMeta = ({account}: OwnProps) => {
    const {t} = useTranslation("admin")
    const fetchCallState = useSelector(selectCombinedApiState(API_FETCH_ACCOUNT_META_ADMIN))
    const updateCallState = useSelector(selectCombinedApiState(API_UPDATE_ACCOUNT_META))

    const accountMeta = useSelector(selectAccountMetaAdmin);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAccountMetaAction(account.id!))
    }, [dispatch, account])

    return fetchCallState.inProgress || !accountMeta ? <LoadingRow/> : <Formik
        initialValues={{
            companySize: accountMeta.entity.companySize,
            industry: accountMeta.entity.industry,
            isBusinessAccount: accountMeta.entity.isBusinessAccount,
            isSlaveAccount: accountMeta.entity.isSlaveAccount
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={(values, {setSubmitting}) => {
            dispatch(updateAccountMetaAction(
                account.id!,
                values.companySize === accountMeta?.entity.companySize ? undefined : values.companySize,
                values.industry === accountMeta?.entity.industry ? undefined : values.industry,
                values.isBusinessAccount === accountMeta?.entity.isBusinessAccount ? undefined : values.isBusinessAccount,
                values.isSlaveAccount === accountMeta?.entity.isSlaveAccount ? undefined : values.isSlaveAccount
            ));
            setSubmitting(false);
        }}
    >
        {formikState => (
            <Form>
                <div className="form-group row">
                    <ValidatedField formikState={formikState}
                                    component={SimpleOptionSelectField}
                                    placeholder={t("entity.account-meta.labels.industry")}
                                    name="industry"
                                    label={t("entity.account-meta.labels.industry")}
                                    className="form-control default-input"
                                    listRenderer={(value: any) => t("entity.account-meta.industry-options." + value)}
                                    options={["automotive",
                                        "finance-insurance",
                                        "healthcare",
                                        "it",
                                        "tourism",
                                        "fashion",
                                        "furniture",
                                        "energy",
                                        "logistics-transport",
                                        "media-marketing",
                                        "food",
                                        "other"]}
                    />
                </div>
                <div className="form-group row">
                    <ValidatedField formikState={formikState}
                                    component={SimpleOptionSelectField}
                                    label={t("entity.account-meta.labels.company-size")}
                                    placeholder={t("entity.account-meta.labels.company-size")}
                                    name="companySize"
                                    listRenderer={(value: any) => value}
                                    className="form-control default-input"
                                    options={["1-10", "11-50", "50-150", "150-250", "250-500", "500+"]}
                    />
                </div>
                <div className="form-group row">
                    <ValidatedField
                        icon={faCheckCircle}
                        label={t("entity.account-meta.labels.is-business-account")}
                        name="isBusinessAccount"
                        formikState={formikState}
                        component={BooleanSelectField}
                        trueLabel={"Ja, dieses Konto ist ein Geschäftskonto (und hat spezielle Funktionen)."}
                        falseLabel={"Nein, dieses ist ein normales Konto."}
                    />
                </div>
                <div className="form-group row">
                    <ValidatedField
                        icon={faCheckCircle}
                        label={t("entity.account-meta.labels.is-slave-account")}
                        name="isSlaveAccount"
                        formikState={formikState}
                        component={BooleanSelectField}
                        trueLabel={"Ja, dieses Konto ist ein Unterkonto.."}
                        falseLabel={"Nein, dieses ist ein normales Konto."}
                    />
                </div>

                {accountMeta?.entity.freeformAccountMeta ? <>
                    {Object.entries(accountMeta.entity.freeformAccountMeta).map(accountMeta => {
                        return <div className="form-group row" key={accountMeta[0]}>
                            <label
                                className="col-sm-2 col-form-label">{accountMeta[0]}</label>
                            <div className="col-sm-10 align-items-center d-flex">
                                {[accountMeta[1]]}
                            </div>
                        </div>
                    })}
                </> : null}
                <button disabled={updateCallState.inProgress} className="btn btn-success">
                    {updateCallState.succeeded ? "✓" : "Speichern"}
                </button>
            </Form>
        )}
    </Formik>
}
