import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { selectHasUnsavedChanges } from "../../../selectors";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Lesson } from "../../../model/lesson/lesson";
import { PreparingAggregatedCourse } from "../../../model/preparing-aggregated-course";
import { anyIdMatches } from "../../../reducers/changes/common/helpers";
import { CourseElement, ELEMENT_TYPE_VIDEO } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    className?: string;
    course: EntityWrapper<PreparingAggregatedCourse>,
    lesson: EntityWrapper<Lesson>
}

interface StateProps {
    hasUnsavedChanges: boolean;
}

type Props = OwnProps & StateProps;

const HasImageBadgeComponent = ({
                                    course,
                                    lesson,
                                    hasUnsavedChanges,
                                    className,
                                }: Props) => {

    const {t} = useTranslation("courses");

    const elements: EntityWrapper<CourseElement>[] = lesson.entity.elementsRef.flatMap(elId => {
        return course.entity.elements.find(el => anyIdMatches(el, elId))
    }).filter(x => x !== undefined) as EntityWrapper<CourseElement>[];

    const hasVideoElement = lesson.entity.imageRef || elements.find(el => el.entity.elementType === ELEMENT_TYPE_VIDEO)

    const badgeStatus = hasUnsavedChanges || !hasVideoElement ? "badge-warning" : "badge-success";

    let UnsavedWarning: any = null;

    if (hasUnsavedChanges) {
        UnsavedWarning = <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-top`}>
                    {t("has-image-badge.warning")}
                </Tooltip>
            }
        >
            <FontAwesomeIcon className="ml-2" icon={faExclamationTriangle}/>
        </OverlayTrigger>;
    }

    return <div className={`mr-2 badge ${badgeStatus} ${className ? className : ""}`}>
        Hat Bild (oder Videoelement)
        {UnsavedWarning}
    </div>
};

export const HasImageBadge = connect<StateProps, {}, OwnProps>(
    (state: any) => ({
        hasUnsavedChanges: selectHasUnsavedChanges(state)
    })
)(HasImageBadgeComponent);
