import React from "react";
import { PaddedContainer } from "./PaddedContainer";
import "./containers.scss";

export const HeadingContainer = (props: any) => {
    return (
        <PaddedContainer className="pt-4 pb-4 bg-white">
            {props.children}
        </PaddedContainer>
    );
};

export const BackgroundContainer = (props: any) => {
    return (
        <PaddedContainer className={"background-container pt-4 pb-4 " + (props.className ? props.className : "")}>
            {props.children}
        </PaddedContainer>
    );
};

export const ContentContainer = (props: any) => {
    return (
        <PaddedContainer className={props.className + " content-container pt-4 pt-lg-0 pt-lg-4 pb-2"}>
            {props.children}
        </PaddedContainer>
    );
};

export const PaginationContainer = (props: any) => {
    return (
        <PaddedContainer className="pb-4">
            {props.children}
        </PaddedContainer>
    );
};

export const AboveTableContainer = (props: any) => {
    return (
        <div className="above-table-container d-flex justify-content-end mb-3 align-items-center">
            {props.children}
        </div>
    );
};
