import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Account, EntityWrapper, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    API_FETCH_LICENSE_POOL_ADMIN,
    fetchLicensePoolAdminAction
} from "../../../actions/license-pool/fetch-license-pool";
import { selectLicensePoolAdmin } from "../../../selectors";
import {
    API_UPDATE_LICENSE_POOL_ADMIN,
    updateLicensePoolAction
} from "../../../actions/license-pool/update-license-pool";
import { createLicensePoolAdminAction } from "../../../actions/license-pool/create-license-pool";
import { MultipleTextField } from "../../../../course/components/course-editor/elements/common/MultipleTextField";
import { ApplicationRulesField } from "./application-rules/ApplicationRulesField";
import {
    APPLICATION_RULE_LIMIT_FOR_ENTIRE_ACCOUNT,
    APPLICATION_RULE_TYPE_LIMIT_FOR_USERS,
    ApplicationRule,
    LimitForEntireAccountApplicationRule,
    LimitForUsersApplicationRule
} from "../../../../licenses/model/ApplicationRule";
import { LicensesTable } from "./LicensesTable";
import { LoadingRow } from "../../../../core/components/table/LoadingRow";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { AsyncEntitySelectField } from "../../../../core/components/entity/AsyncEntitySelectField";
import { CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY } from "../../../../core/components/entity/AsyncEntitySelect";

export interface OwnProps {
    account: EntityWrapper<Account>
}

export const EditLicensePool = ({account}: OwnProps) => {
    const {t} = useTranslation("admin")

    const fetchCallState = useSelector(selectCombinedApiState(API_FETCH_LICENSE_POOL_ADMIN))
    const updateCallState = useSelector(selectCombinedApiState(API_UPDATE_LICENSE_POOL_ADMIN))

    const licensePoolContainer = useSelector(selectLicensePoolAdmin);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLicensePoolAdminAction(account.id!));
    }, [dispatch, account])

    return fetchCallState.inProgress ? <LoadingRow/> : <>
        {licensePoolContainer?.licensePool ? <>
            <Formik
                initialValues={{
                    accountRef: {id: licensePoolContainer?.licensePool.entity.accountRef},
                    slaveAccountsRef: licensePoolContainer?.licensePool.entity.slaveAccountsRef.map(id => ({id})),
                    applicationRules: licensePoolContainer?.licensePool.entity.applicationRules,
                    masterDomains: licensePoolContainer?.licensePool.entity.masterDomains,
                }}
                validationSchema={Yup.object().shape({
                    accountRef: Yup.string().required(t("entity.license-pool.labels.account") + " " + t("translation:is a required field")),
                    applicationRules: Yup.array().test("applicationRules", "test", function(value) {
                        const applicationRules = value as ApplicationRule[];

                        const accountTypeUserTypeTuples = applicationRules
                            .filter(ar => ar.ruleType === APPLICATION_RULE_TYPE_LIMIT_FOR_USERS)
                            .map(ar => ar as LimitForUsersApplicationRule)
                            .filter(ar => ar.limitToUserRole && ar.limitToAccountType)
                            .map(ar => ar.limitToAccountType! + ar.limitToUserRole!)
                            .map(((value, index, array) => {
                                return array.indexOf(value) !== index;
                            }))

                        const hasDuplicateRulesForSameTypes = accountTypeUserTypeTuples.length > 0 && accountTypeUserTypeTuples.reduce((a, b) => a || b)

                        if (hasDuplicateRulesForSameTypes) {
                            return this.createError({
                                path: "applicationRules",
                                message: t("entity.license-pool.labels.application-rules-duplicate-error")
                            });
                        }

                        const faultyAccountRules = applicationRules.filter(ar => ar.ruleType === APPLICATION_RULE_LIMIT_FOR_ENTIRE_ACCOUNT)
                            .map(ar => ar as LimitForEntireAccountApplicationRule)
                            .find(ar => !ar.limitToAccountType || ar.limitAmount <= 0)

                        if (faultyAccountRules !== undefined) {
                            return this.createError({
                                path: "applicationRules",
                                message: t("entity.license-pool.labels.application-rules-faulty-account-rule-error")
                            });
                        }

                        const faultyUserRules = applicationRules.filter(ar => ar.ruleType === APPLICATION_RULE_TYPE_LIMIT_FOR_USERS)
                            .map(ar => ar as LimitForUsersApplicationRule)
                            .find(ar => (!ar.limitToAccountType && !ar.limitToUserRole) || !ar.limitAmount || ar.limitAmount <= 0)
                        if (faultyUserRules !== undefined) {
                            return this.createError({
                                path: "applicationRules",
                                message: t("entity.license-pool.labels.application-rules-faulty-user-rule-error")
                            });
                        }

                        return true;
                    })
                })}
                onSubmit={(values, {setSubmitting}) => {
                    dispatch(updateLicensePoolAction(
                        licensePoolContainer?.licensePool?.id!,
                        values.accountRef.id,
                        values.slaveAccountsRef ? values.slaveAccountsRef.map(x => x.id) : [],
                        values.applicationRules,
                        values.masterDomains ? values.masterDomains : []
                    ));
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <div className="form-group row">
                            <label
                                className="col-sm-2 col-form-label">{t("entity.license-pool.labels.register-link")}</label>
                            <div className="col-sm-10 align-items-center d-flex">
                                <a target="_blank" rel={"noopener noreferrer"}
                                   href={process.env.REACT_APP_WEBAPP_URL + "/register/" + licensePoolContainer?.licensePool?.id}>{t("entity.license-pool.labels.register-link")}</a>
                            </div>
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.license-pool.labels.account")}
                                name="accountRef"
                                component={AsyncEntitySelectField}
                                fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/api/admin/v1/accounts/list")}
                                placeholder={"Hauptkonto"}
                                listRenderer={(value: EntityWrapper<Account>) => value?.entity?.name}
                                formikState={formikState}
                                shownEntityType={"doesnt-matter"}
                                shownEntityTypeProperties={["entity.name"]}
                                isMulti={false}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.license-pool.labels.slave-accounts")}
                                name="slaveAccountsRef"
                                component={AsyncEntitySelectField}
                                fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/api/admin/v1/accounts/list")}
                                placeholder={"Unterkonten"}
                                listRenderer={(value: EntityWrapper<Account>) => value?.entity?.name}
                                formikState={formikState}
                                shownEntityType={"doesnt-matter"}
                                shownEntityTypeProperties={["entity.name"]}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.license-pool.labels.application-rules")}
                                name="applicationRules"
                                component={ApplicationRulesField}
                                placeholder={t("entity.license-pool.labels.application-rules")}
                                formikState={formikState}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.license-pool.labels.master-domains")}
                                name="masterDomains"
                                component={MultipleTextField}
                                placeholder={t("entity.license-pool.labels.master-domains")}
                                formikState={formikState}
                                helpText={t("entity.license-pool.labels.master-domains-explanation")}
                            />
                        </div>
                        <button disabled={updateCallState.inProgress} className="btn btn-success">
                            {updateCallState.succeeded ? "✓" : "Speichern"}
                        </button>
                    </Form>
                )}
            </Formik>
            <LicensesTable/>
        </> : <button onClick={() => dispatch(createLicensePoolAdminAction(account.id!))}
                      className="btn btn-success">Lizenzpool anlegen</button>}
    </>
}
