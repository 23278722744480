import update from "immutability-helper";
import { EditedCourseState } from "../../edited-course";
import { MoveLessonAction } from "../../../actions/lesson/move-lesson";

export function moveLesson(state: EditedCourseState,
                           {sourceModuleId, targetModuleId, sourceIndex, targetIndex}: MoveLessonAction) {
    if (sourceModuleId === targetModuleId && sourceIndex === targetIndex) {
        return state;
    }

    const sourceModule = state.editedCourse!.entity.modules.find(m => m.id === sourceModuleId || m.temporaryId === sourceModuleId)
    const sourceModuleIndex = state.editedCourse!.entity.modules.indexOf(sourceModule!);

    const targetModule = state.editedCourse!.entity.modules.find(m => m.id === targetModuleId || m.temporaryId === targetModuleId)
    const targetModuleIndex = state.editedCourse!.entity.modules.indexOf(targetModule!);

    const lessonId = sourceModule!.entity.lessonsRef[sourceIndex];

    if (sourceModuleIndex === targetModuleIndex) {
        return update(state, {
            hasUnsavedChanges: {$set: true},
            editedCourse: {
                entity: {
                    modules: {
                        [sourceModuleIndex]: {
                            entity: {
                                lessonsRef: {
                                    $splice: [
                                        [sourceIndex, 1],
                                        [targetIndex, 0, lessonId]
                                    ],
                                }
                            }
                        },
                    }
                }
            }
        });
    } else {
        return update(state, {
            hasUnsavedChanges: {$set: true},
            editedCourse: {
                entity: {
                    modules: {
                        [sourceModuleIndex]: {
                            entity: {
                                lessonsRef: {
                                    $splice: [
                                        [sourceIndex, 1],
                                    ],
                                }
                            }
                        },
                        [targetModuleIndex]: {
                            entity: {
                                lessonsRef: {
                                    $splice: [
                                        [targetIndex, 0, lessonId]
                                    ]
                                }
                            }
                        }
                    }
                }
            }
        });
    }

}
