import { faList } from "@fortawesome/free-solid-svg-icons";
import { FieldProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { MultipleTexts } from "../common/MultipleTexts";

type Props = FieldProps & WithTranslation;

const AdditionalWordsFieldComponent = (props: Props) => {
    const {field, form, t} = props;

    const currentAlternatives = field.value ? field.value as string[] : [];

    return <MultipleTexts
        icon={faList}
        currentTexts={currentAlternatives}
        updatedTexts={(updatedTexts?: string[]) => {
            form.setFieldValue(field.name, updatedTexts);
        }}
        inputFieldPlaceholder={t("course-editor.additional-words-field.input-placeholder")}
        removeTooltip={t("course-editor.additional-words-field.remove-text")}
        addTooltip={t("course-editor.additional-words-field.add-text")}
    />;
};

export const AdditionalWordsField = withTranslation("courses")(AdditionalWordsFieldComponent);
