import { MonthlyPriceExplanation, PriceExplanation } from "@thekeytechnology/thekey-academy-frontend-library";
import { MonthlyPriceDisplay } from "../../../users/orders/v2/MonthlyPriceDisplay";
import { PriceDisplay } from "../../../users/orders/v2/PriceDisplay";
import React from "react";

interface OwnProps {
    isPaid: boolean
    isMonthly: boolean
    priceExplanation: PriceExplanation
    monthlyPriceExplanation?: MonthlyPriceExplanation
}

export const OrderPriceDisplay = ({isPaid, isMonthly, priceExplanation, monthlyPriceExplanation}: OwnProps) => {
    if (isPaid) {
        return isMonthly && monthlyPriceExplanation ?
            <MonthlyPriceDisplay monthlyPriceExplanation={monthlyPriceExplanation}
                                 monthlyText={" "}/> :
            <PriceDisplay priceExplanation={priceExplanation}/>

    } else {
        return <div className="order-price-display d-flex flex-column">
            <PriceDisplay priceExplanation={priceExplanation}/>
            {monthlyPriceExplanation ? <MonthlyPriceDisplay
                beforeText={"Oder "}
                monthlyPriceExplanation={monthlyPriceExplanation}
                monthlyText={" "}/> : null}
        </div>
    }
}
