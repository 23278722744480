import {faEnvelopeOpenText, faHeading} from "@fortawesome/free-solid-svg-icons";
import {
    EntityWrapper,
    WithSwitchableLanguage,
    WithSwitchableLanguageProps
} from "@thekeytechnology/framework-react";
import {Form, Formik} from "formik";
import {WithTranslation, withTranslation} from "react-i18next";
import React, {useEffect} from "react";
import * as Yup from "yup";
import {
    ENTITY_TYPE_TRIGGERED_EMAIL,
    NEW_TRIGGERED_EMAIL,
    TriggeredEmail
} from "../../model/triggered-email";
import {useDispatch, useSelector} from "react-redux";
import {fetchTriggerDefinitionsAction} from "../../../triggers/actions/fetch-trigger-definitions";
import {selectTriggerDefinitions} from "../../../triggers/selectors";
import {YUP_SCHEMA_TRIGGER} from "../../../core/model/trigger/trigger";
import {TriggerField} from "../../../triggers/components/TriggerField";
import {
    CustomerMessageDefinition,
    ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION
} from "../../../customer-messages/model/CustomerMessage";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../core/components/entity/WithSingleEntityFromPath";
import { LoadingEditHeader } from "../../../core/components/header/LoadingEditHeader";
import { EditHeaderWithBackground } from "../../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../../core/components/containers/Container";
import { ValidatedField } from "../../../core/components/form/ValidatedField";
import { AsyncEntitySelectField } from "../../../core/components/entity/AsyncEntitySelectField";
import { WysiwygField } from "../../../core/components/wysiwyg/WysiwygField";

type Props =
    WithSingleEntityFromPathProps<TriggeredEmail, TriggeredEmail>
    & WithTranslation
    & WithSwitchableLanguageProps;

const EditTriggeredEmailComponent = (props: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTriggerDefinitionsAction())
    }, [dispatch])

    const definitions = useSelector(selectTriggerDefinitions)
    const {entity, upsertEntity, t, currentLanguage} = props;

    return (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.triggered-email.singular")}/>

            {entity && definitions ?
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        title: entity.entity.title,
                        trigger: entity.entity.trigger,
                        customerMessageDefinitionRef: {id: entity.entity.customerMessageDefinitionRef},
                        subject: entity.entity.subjects[currentLanguage],
                        body: entity.entity.bodies[currentLanguage],
                        preview: entity.entity.previewTexts[currentLanguage]
                    }}
                    validationSchema={Yup.object().shape({
                        trigger: YUP_SCHEMA_TRIGGER(definitions, t),
                        title: Yup.string().required(t("entity.triggered-email.labels.title") + " " + t("core:is a required field")),
                        subject: Yup.string().required(t("entity.triggered-email.labels.subject") + " " + t("core:is a required field")),
                        body: Yup.string().required(t("entity.triggered-email.labels.body") + " " + t("core:is a required field")),
                        preview: Yup.string().required(t("entity.triggered-email.labels.preview") + " " + t("core:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        upsertEntity(new EntityWrapper(entity.id,
                            {
                                title: values.title,
                                trigger: values.trigger,
                                customerMessageDefinitionRef: values.customerMessageDefinitionRef.id,
                                subjects: {
                                    ...entity.entity.subjects,
                                    [currentLanguage]: values.subject
                                },
                                previewTexts: {
                                    ...entity.entity.previewTexts,
                                    [currentLanguage]: values.preview
                                },
                                bodies: {
                                    ...entity.entity.bodies,
                                    [currentLanguage]: values.body
                                }
                            } as TriggeredEmail
                            )
                        )
                        setSubmitting(false);
                    }}
                >
                    {formikState => {
                        const currentDefinition = formikState.values.trigger ? definitions.find(d => d.key === formikState.values.trigger!.key) : undefined;
                        return (
                            <Form>
                                <EditHeaderWithBackground
                                    heading={t("edit-triggered-email.heading")}
                                    leadText={<>{formikState.values.subject}</>}>
                                    <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                        backPath="/emails"/>
                                </EditHeaderWithBackground>
                                <ContentContainer>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-email.labels.title")}
                                                        name="title"
                                                        formikState={formikState}
                                                        type="text"
                                                        placeholder={t("entity.triggered-email.labels.title-placeholder")}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-email.labels.trigger")}
                                                        name="trigger"
                                                        formikState={formikState}
                                                        definitions={definitions}
                                                        component={TriggerField}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-email.labels.customer-message")}
                                                        name="customerMessageDefinitionRef"
                                                        component={AsyncEntitySelectField}
                                                        shownEntityType={ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION}
                                                        shownEntityTypeProperties={["entity.title"]}
                                                        listRenderer={(item: EntityWrapper<CustomerMessageDefinition>) => item.entity ? `${item.entity.internalTitle} (${item.entity.title})` : ""}
                                                        placeholder={t("entity.triggered-email.labels.customer-message")}
                                                        isClearable={true}
                                                        formikState={formikState}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField icon={faHeading}
                                                        label={t("entity.triggered-email.labels.subject")}
                                                        name="subject"
                                                        formikState={formikState}
                                                        type="text"
                                                        placeholder={t("entity.triggered-email.labels.subject-placeholder")}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField icon={faEnvelopeOpenText}
                                                        label={t("entity.triggered-email.labels.preview")}
                                                        name="preview"
                                                        formikState={formikState}
                                                        type="text"
                                                        placeholder={t("entity.triggered-email.labels.preview-placeholder")}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField
                                            label={t("entity.triggered-email.labels.body")}
                                            rows="10"
                                            name="body"
                                            formikState={formikState}
                                            component={WysiwygField}
                                            language="de"
                                            config={{
                                                height: 700,
                                            }}
                                            required
                                        />
                                    </div>
                                    {currentDefinition ? <div className="form-group row">
                                        <label htmlFor="html"
                                               className="col-sm-2 col-form-label">{t("Available Variables")}</label>
                                        <div className="col-sm-10">
                                            {currentDefinition.availableVariables.length ? <ul>
                                                {currentDefinition.availableVariables.map(v => {
                                                    return <li key={v}>%%{v}%%
                                                        - {t("triggers:trigger-field.variables." + v)}</li>;
                                                })}
                                            </ul> : null}
                                        </div>
                                    </div> : null}

                                </ContentContainer>
                            </Form>
                        );
                    }}
                </Formik> : null}
        </>
    );
};

export const EditTriggeredEmail = WithSingleEntityFromPath<{}, TriggeredEmail, TriggeredEmail>(WithSwitchableLanguage<any>(
    withTranslation(["emails", "triggers"])(EditTriggeredEmailComponent)),
    ENTITY_TYPE_TRIGGERED_EMAIL,
    "emailId",
    NEW_TRIGGERED_EMAIL
);
