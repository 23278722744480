import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import "./onboarding-table.scss";
import { ENTITY_TYPE_ONBOARDING, Onboarding } from "../model/onboarding";
import { AboveTableContainer } from "../../core/components/containers/Container";
import { PaginationInfo } from "../../core/components/pagination/PaginationInfo";
import { EntityCreateAndDeleteButtons } from "../../core/components/button/EntityCreateAndDeleteButtons";
import { Table, TableColumn } from "../../core/components/table/Table";
import { EntityTableHeader } from "../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../core/components/table/AsyncEntityTableBody";
import { EntityTableRow } from "../../core/components/table/EntityTableRow";
import { ActionTableColumn } from "../../core/components/table/ActionTableColumn";
import { ActionLink } from "../../core/components/icon-link-tooltip/ActionLink";

type Props =
    WithEntityListProps<Onboarding>
    & WithTranslation
    & WithSelectionsProps<string>;

const OnboardingTableComponent = ({
                                      entities,
                                      selectionModel,
                                      entityApiState,
                                      t
                                  }: Props) => {

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_ONBOARDING} className="mr-auto"/>

                <EntityCreateAndDeleteButtons
                    entityType={ENTITY_TYPE_ONBOARDING}
                    selectedEntities={selectionModel.selections}
                    label={t("entity.onboarding.singular")}
                    labelPlural={t("entity.onboarding.plural")}
                    createPath="/onboardings/new/edit"
                />
            </AboveTableContainer>

            <Table itemCount={entities.length} className="onboarding-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.onboarding.labels.internalTitle")}</TableColumn>
                    <TableColumn>{t("entity.onboarding.labels.actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={onboarding =>
                        <EntityTableRow
                            key={onboarding.id}
                            entity={onboarding} selectionModel={selectionModel}>
                            <TableColumn className="bold title-column">
                                {onboarding.entity.internalTitle}
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink to={`/onboardings/${onboarding.id}/edit`} icon={faPencilAlt}
                                            tooltip={t("entity.onboarding.labels.edit-tooltip")}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const OnboardingTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation("onboarding")(OnboardingTableComponent),
        ENTITY_TYPE_ONBOARDING
    )
);
