/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProviderDataDisplayV3_OrderFragment = {
    readonly paymentData: {
        readonly iban?: string;
        readonly mandateInformation?: {
            readonly acceptanceDateTime: string;
            readonly mandateNumber: string;
        };
    } | null;
    readonly " $refType": "ProviderDataDisplayV3_OrderFragment";
};
export type ProviderDataDisplayV3_OrderFragment$data = ProviderDataDisplayV3_OrderFragment;
export type ProviderDataDisplayV3_OrderFragment$key = {
    readonly " $data"?: ProviderDataDisplayV3_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ProviderDataDisplayV3_OrderFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "MandateInformation",
  "kind": "LinkedField",
  "name": "mandateInformation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptanceDateTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mandateNumber",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProviderDataDisplayV3_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "paymentData",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "iban",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "type": "SepaData",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/)
          ],
          "type": "StripeSepaData",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();
(node as any).hash = 'd49cc44e44c20a10f6ed52ed27d46148';
export default node;
