import React from "react";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {
    UsageInvoiceColumn_DiscountCodeUsageFragment$key
} from "../../../../../../../__generated__/UsageInvoiceColumn_DiscountCodeUsageFragment.graphql";

const DISCOUNT_CODE_USAGE_FRAGMENT = graphql`
    fragment UsageInvoiceColumn_DiscountCodeUsageFragment on DiscountCodeUsage {
        order {
            invoiceData {
                invoiceNumber
            }
        }
    }
`

interface OwnProps {
    discountCodeUsageFragmentRef: UsageInvoiceColumn_DiscountCodeUsageFragment$key
}

export const UsageInvoiceColumn = ({discountCodeUsageFragmentRef}: OwnProps) => {
    const discountCodeUsage = useFragment<UsageInvoiceColumn_DiscountCodeUsageFragment$key>(DISCOUNT_CODE_USAGE_FRAGMENT, discountCodeUsageFragmentRef)

    /*const downloadInvoicePDF = () => {
        if (discountCodeUsage.order?.invoiceData?.invoiceDataBase64) {
            const link = document.createElement("a");
            link.href = "data:application/octet-stream;base64," + discountCodeUsage.order?.invoiceData?.invoiceDataBase64;
            link.download = discountCodeUsage.order?.invoiceData.invoiceNumber + ".pdf";
            link.click();
        }
    }*/

    return <div className="cursor-pointer" onClick={() => {}}>
        {discountCodeUsage.order?.invoiceData?.invoiceNumber}
    </div>
}
