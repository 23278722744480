import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { TriggerExecutionsFilters } from "./TriggerExecutionsFilters";
import { TriggerExecutionsTable } from "./TriggerExecutionsTable";
import { ENTITY_TYPE_TRIGGER_EXECUTION } from "../../model/TriggerExecution";
import { SimpleHeader } from "../../../core/components/header/SimpleHeader";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../../core/components/containers/Container";
import { Pagination } from "../../../core/components/pagination/Pagination";

const ExpertOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/trigger-executions"}>{t("entity.trigger-execution.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.trigger-execution.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <TriggerExecutionsFilters/>
            </BackgroundContainer>
            <ContentContainer>
                <TriggerExecutionsTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_TRIGGER_EXECUTION}/>
            </PaginationContainer>
        </>
    );
};

export const TriggerExecutionsOverview = withTranslation("triggers")(ExpertOverviewComponent);
