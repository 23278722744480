import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_ADD_LICENSES_ADMIN = "add-licenses-admin";

export interface CreateLicensesPayload {
    licensePoolId: string
    amount: number
    limitedToCoursesRef: string[]
}

export const addLicensesToPoolAction = (licensePoolId: string, amount: number, limitedToCoursesRef?: string[]) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_ADD_LICENSES_ADMIN,
            callType: API_ADD_LICENSES_ADMIN
        },
        payload: {
            licensePoolId,
            amount,
            limitedToCoursesRef
        },
    } as ApiCallAction<CreateLicensesPayload>;
};
