import React from "react";
import {NavLink} from "react-router-dom";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {graphql} from "babel-plugin-relay/macro";
import {usePaginationFragment} from "react-relay";
import {Button} from "primereact/button";
import {OrdersTableV3_OrdersList$key} from "../../../../../__generated__/OrdersTableV3_OrdersList.graphql";
import {OrdersTableV3_Refetch} from "../../../../../__generated__/OrdersTableV3_Refetch.graphql";
import {OrderPriceDisplayV3} from "./OrderPriceDisplayV3";
import {PaymentProviderLinksV3} from "./PaymentProviderLinksV3";
import {OrdersTableFiltersV3} from "./OrdersTableFiltersV3";
import {OrdersPaginationV3} from "./OrdersPaginationV3";

const ORDERS_FRAGMENT = graphql`
    fragment OrdersTableV3_OrdersList on Query @refetchable(queryName: "OrdersTableV3_Refetch") @argumentDefinitions(
        firstName: {type: "String"},
        lastName: {type: "String"},
        orderNumber: {type: "String"},
        first: {type: "Int!"},
        after: {type: "String"}
    ){
        Admin {
            Billing  {
                Orders(first: $first, after: $after, firstName: $firstName, lastName: $lastName, orderNumber: $orderNumber) @connection(key: "OrdersTableV3_Orders") {
                    edges {
                        node{
                            id
                            createdAt
                            status
                            ...OrderPriceDisplayV3_OrderFragment
                            ...PaymentProviderLinksV3_OrderFragment
                        }
                    }
                }
            }
        }
    }
`;

export interface OwnProps {
    ordersFragmentRef: OrdersTableV3_OrdersList$key,
    ordersPerPage: number
}

export const OrdersTableV3 = ({ordersFragmentRef, ordersPerPage}: OwnProps) => {
    const {
        data: orders,
        hasNext,
        loadNext,
        refetch
    } = usePaginationFragment<OrdersTableV3_Refetch, OrdersTableV3_OrdersList$key>(ORDERS_FRAGMENT, ordersFragmentRef)


    const items = orders.Admin.Billing.Orders.edges?.filter(e => !!e).map(e => e!.node)  || []

    return <>
        <OrdersTableFiltersV3 refetch={(firstName: string, lastName: string, orderNumber: string ) => refetch({firstName: firstName, lastName: lastName, orderNumber: orderNumber, first: ordersPerPage})}/>
        <DataTable className="p-mb-2" value={items} emptyMessage={"Keine Bestellungen"}>
            <Column field="createdAt" header="Datum / Uhrzeit"/>
            <Column field="status" header="Status"/>
            <Column header="Preis" body={row => {
                return <OrderPriceDisplayV3 orderFragmentRef={row}/>
            }}/>
            <Column header="Preis" body={row => {
                return <PaymentProviderLinksV3 orderFragmentRef={row}/>
            }}/>
            <Column header="Aktionen" style={{width: "20%"}} body={item =>
                <NavLink to={`/orders-v3/${item.id}/edit`}> <Button icon={"pi pi-search"}/></NavLink>
            }/>
        </DataTable>
        <OrdersPaginationV3 hasNext={hasNext} loadNext={() => loadNext(ordersPerPage)}/>
    </>
}
