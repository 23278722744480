import { EntityWrapper, generateString } from "@thekeytechnology/framework-react";
import update from "immutability-helper";
import React from "react";
import { Card } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addModuleAction } from "../../../actions/module/add-module";
import { removeModuleAction } from "../../../actions/module/remove-module";
import { extractId } from "../../../reducers/changes/common/helpers";
import { CourseEditorCardHeader } from "../common/CourseEditorCardHeader";
import { withCourseEditorDraggable, WithCourseEditorDraggableProps } from "../common/WithCourseEditorDraggable";
import { DRAGGABLE_TYPES } from "../common/draggable-types";
import { EditModule } from "./EditModule";
import { LessonList } from "../lesson/LessonList";
import { toggleCardAction } from "../../../actions/course-editor/toggle-card";
import { selectIsCardOpen } from "../../../selectors";
import { CourseModule } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    module: EntityWrapper<CourseModule>;
    moduleIndex: number;
}

interface StateProps {
    isCardOpen: boolean
}

interface DispatchProps {
    addModule: typeof addModuleAction,
    removeModule: typeof removeModuleAction,
    toggleCard: typeof toggleCardAction
}

type Props = OwnProps & StateProps & DispatchProps & WithCourseEditorDraggableProps & WithTranslation;

export const ModuleItemComponent = ({
                                        module,
                                        moduleIndex,
                                        t,
                                        removeModule,
                                        dragHandleRef,
                                        dropMightHappen,
                                        addModule,
                                        toggleCard,
                                        isCardOpen
                                    }: Props) => {

    const moduleId = extractId(module);

    return <Card>
        <CourseEditorCardHeader
            itemId={module.id ? module.id : module.temporaryId}
            item={module}
            dropMightHappen={dropMightHappen}
            dragHandleRef={dragHandleRef}
            title={`${t("entity.module.singular")} ${moduleIndex + 1}`}
            onCopy={() => {
                const copiedModule = update(module, {
                    id: {$set: undefined},
                    entity: {containerMeta: {title: {$set: module.entity.containerMeta.title + " (Kopie)"}}},
                    temporaryId: {$set: generateString(10)}
                });
                addModule(copiedModule);
            }}
            onDelete={() => removeModule(module)}
            onClick={() => toggleCard(extractId(module))}
        >
            <EditModule module={module}/>
        </CourseEditorCardHeader>
        {isCardOpen && <Card.Body>
            <LessonList moduleIndex={moduleIndex} parentModuleId={moduleId} lessonIds={module.entity.lessonsRef}/>
        </Card.Body>}
    </Card>;
};

export const ModuleItem = connect<StateProps, DispatchProps, OwnProps>(
    (state: any, {module}: OwnProps) => ({
        isCardOpen: selectIsCardOpen(extractId(module))(state)
    }),
    {
        addModule: addModuleAction,
        removeModule: removeModuleAction,
        toggleCard: toggleCardAction
    }
)(withCourseEditorDraggable(DRAGGABLE_TYPES.MODULE, withTranslation("courses")(ModuleItemComponent)));
