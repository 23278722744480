import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { CourseModule } from "@thekeytechnology/thekey-academy-frontend-library";

export const REMOVE_MODULE_ACTION = "remove-module";

export interface RemoveModuleAction extends Action {
    module: EntityWrapper<CourseModule>
}

export const removeModuleAction = (module: EntityWrapper<CourseModule>) => ({
    type: REMOVE_MODULE_ACTION,
    module
}) as RemoveModuleAction;
