import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterTerm, removeFiltersAction } from "@thekeytechnology/framework-react";
import { TkFile } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SimpleOptionSelectFilter } from "../filters/SimpleOptionSelectFilter";
import { TextFilter } from "../filters/TextFilter";


interface DispatchProps {
    removeFilters: ReturnType<typeof removeFiltersAction>
}

type Props = DispatchProps & WithTranslation;

class FileFiltersComponent extends React.Component<Props> {

    public constructor(props: Props) {
        super(props);
        this.clearFilters = this.clearFilters.bind(this);
    }

    public render() {
        const {t} = this.props;

        const Filter = SimpleOptionSelectFilter<any>();

        return (
            <div className="filters file-filters">
                <FontAwesomeIcon className="filter-icon" icon={faFilter}/>
                <TextFilter
                    placeholder={t("file-filters.name.placeholder")}
                    entityType={TkFile.TYPE}
                    property="entity.name"/>
                <Filter
                    placeholder={t("file-filters.file-type.placeholder")}
                    filterType={FilterTerm.TYPE_STRING}
                    options={["image/jpg", "image/png", "application/zip"].map(fileType => {
                        return {
                            label: t("file-filters.file-type." + fileType),
                            value: fileType
                        }
                    })}
                    filterKey={"entity.fileType"}
                    entityType={TkFile.TYPE}
                    property="entity.fileType"/>
                <button type="button" onClick={this.clearFilters} className="clear-filters">
                    <FontAwesomeIcon icon={faTimes}/>{t("file-filters.clear-filters")}
                </button>
            </div>
        );
    }

    public clearFilters() {
        this.props.removeFilters(
            ["entity.name", "entity.fileType"]
        );
    }
}

export const FileFilters = connect<{}, DispatchProps, {}>(
    null,
    {
        removeFilters: removeFiltersAction(TkFile.TYPE)
    }
)(withTranslation("files")(FileFiltersComponent));
