import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_UPDATE_ACCOUNT = "update-account";

export interface UpdateAccount {
    accountId: string
    name?: string
}

export const updateAccountAction = (accountId: string, name?: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_ACCOUNT,
            callType: API_UPDATE_ACCOUNT
        },
        payload: {
            accountId,
            name
        }
    } as ApiCallAction<UpdateAccount>;
};
