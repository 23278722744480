import { FieldProps } from "formik";
import { PriceEditor } from "./PriceEditor";
import React from "react";

interface OwnProps {
    disableDiscount?: boolean
    disableMonthly?: boolean
}

type Props = OwnProps & FieldProps

export const PriceField = ({form, field, disableDiscount, disableMonthly}: Props) => {

    return <PriceEditor price={field.value}
                        disableDiscount={disableDiscount}
                        disableMonthly={disableMonthly}
                        onUpdate={newPrice => form.setFieldValue(field.name, newPrice)}
                        onBlur={field.onBlur}/>
}
