import { Observable } from "rxjs";
import { Action } from "redux";
import { map } from "rxjs/operators";
import { push } from "connected-react-router";
import { ApiQualifier, matchesAnyOfTheseApiSuccesses } from "@thekeytechnology/framework-react";

export const redirectAfterEntityApiOperation = (apiTypes: string[], entityType: string, toPath: string) => (action$: Observable<Action>) =>
    action$.pipe(
        matchesAnyOfTheseApiSuccesses(
            ...apiTypes.map(apiType => ({apiType, callType: entityType}))
        ),
        map(() => push(toPath))
    );


export const redirectAfterApiSuccess = (apiQualifier: ApiQualifier, toPath: string) => redirectAfterApiSuccesses([apiQualifier], toPath);

export const redirectAfterApiSuccesses = (apiQualifiers: ApiQualifier[], toPath: string) => (action$: Observable<Action>) =>
    action$.pipe(
        matchesAnyOfTheseApiSuccesses(...apiQualifiers),
        map(() => push(toPath))
    );
