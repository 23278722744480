import { ToggleCardAction } from "../actions/course-editor/toggle-card";
import {Action} from "redux";

export interface EditorCardsState {
    openCards: string[]
}

export const initialState: EditorCardsState = {
    openCards: [],
};

export function editorCardsReducer(state = initialState, action : Action): EditorCardsState {
    switch (action.type) {
        case ToggleCardAction:
            const id = (action as ToggleCardAction).id;
            const inCards = state.openCards.indexOf(id) > -1;

            const updatedArray = inCards ? state.openCards.filter(card => card !== id) : [...state.openCards, id];

            return {
                ...state,
                openCards: updatedArray
            }
    }

    return state;
}
