/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditCustomerMessageDefinitionV2Screen_QueryVariables = {
    id: string;
};
export type EditCustomerMessageDefinitionV2Screen_QueryResponse = {
    readonly node: {
        readonly internalTitle?: string;
        readonly " $fragmentRefs": FragmentRefs<"CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment" | "EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment">;
    } | null;
};
export type EditCustomerMessageDefinitionV2Screen_Query = {
    readonly response: EditCustomerMessageDefinitionV2Screen_QueryResponse;
    readonly variables: EditCustomerMessageDefinitionV2Screen_QueryVariables;
};



/*
query EditCustomerMessageDefinitionV2Screen_Query(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on CustomerMessageDefinitionV2 {
      internalTitle
      ...CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment
      ...EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment
    }
    id
  }
}

fragment CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {
  id
  pages {
    edges {
      node {
        id
        internalTitle
        ...EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {
  ...CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment
}

fragment EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment on CustomerMessageDefinitionPage {
  id
  internalTitle
  imageOpt {
    fileType
    id
    name
    rawId
    rawUrl
    thumbnailUrl
    uploadDateTime
    url
  }
}

fragment EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {
  id
  internalTitle
  buttonOpt {
    __typename
    type
    title
    ... on CustomerMessageDefinitionLinkButton {
      link
    }
  }
  showUntilOpt
  notificationDefinitionOpt {
    title
    body
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalTitle",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCustomerMessageDefinitionV2Screen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment"
              }
            ],
            "type": "CustomerMessageDefinitionV2",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditCustomerMessageDefinitionV2Screen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerMessageDefinitionPagesConnection",
                "kind": "LinkedField",
                "name": "pages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionPagesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageDefinitionPage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "imageOpt",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fileType",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rawId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rawUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumbnailUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "uploadDateTime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "connection",
                "key": "CustomerMessageDefinitionPagesOrderList_pages",
                "kind": "LinkedHandle",
                "name": "pages"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "buttonOpt",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "link",
                        "storageKey": null
                      }
                    ],
                    "type": "CustomerMessageDefinitionLinkButton",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showUntilOpt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationDefinitionV2Type",
                "kind": "LinkedField",
                "name": "notificationDefinitionOpt",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "body",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CustomerMessageDefinitionV2",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8572cd8c8764090dff1335b84785376c",
    "id": null,
    "metadata": {},
    "name": "EditCustomerMessageDefinitionV2Screen_Query",
    "operationKind": "query",
    "text": "query EditCustomerMessageDefinitionV2Screen_Query(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on CustomerMessageDefinitionV2 {\n      internalTitle\n      ...CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment\n      ...EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment\n    }\n    id\n  }\n}\n\nfragment CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {\n  id\n  pages {\n    edges {\n      node {\n        id\n        internalTitle\n        ...EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {\n  ...CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment\n}\n\nfragment EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment on CustomerMessageDefinitionPage {\n  id\n  internalTitle\n  imageOpt {\n    fileType\n    id\n    name\n    rawId\n    rawUrl\n    thumbnailUrl\n    uploadDateTime\n    url\n  }\n}\n\nfragment EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {\n  id\n  internalTitle\n  buttonOpt {\n    __typename\n    type\n    title\n    ... on CustomerMessageDefinitionLinkButton {\n      link\n    }\n  }\n  showUntilOpt\n  notificationDefinitionOpt {\n    title\n    body\n  }\n}\n"
  }
};
})();
(node as any).hash = '9fffb4e5fe43b1e591fc34b5c91cb387';
export default node;
