import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createEntitiesAction, EntityWrapper } from "@thekeytechnology/framework-react";
import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Select from "react-select";
import "./add-additional-content.scss";
import {
    ADDITIONAL_CONTENT_TYPE,
    ADDITIONAL_CONTENT_TYPE_FILE,
    ADDITIONAL_CONTENT_TYPE_TEXT,
    ADDITIONAL_CONTENT_TYPE_VIDEO,
    AdditionalContent,
    FileAdditionalContent,
    TextAdditionalContent,
    VideoAdditionalContent
} from "@thekeytechnology/thekey-academy-frontend-library";
import { ClearIndicator } from "../../core/components/select/ClearIndicator";

interface State {
    selectedType: string;
}

interface DispatchProps {
    addAdditionalContent: (entities: EntityWrapper<AdditionalContent>[]) => void;
}

type Props = DispatchProps & WithTranslation;

const AddAdditionalContentComponent = ({t, addAdditionalContent}: Props) => {

    const CONTENT_TYPE_OPTIONS = [ADDITIONAL_CONTENT_TYPE_TEXT, ADDITIONAL_CONTENT_TYPE_VIDEO, ADDITIONAL_CONTENT_TYPE_FILE].map(contentType => {
        return {
            value: contentType,
            label: t(`content-types.${contentType}`)
        }
    })

    const [state, setType] = useState<State>({
        selectedType: ADDITIONAL_CONTENT_TYPE_TEXT
    });

    return (
        <div className="d-flex mr-4 add-additional-content">
            <Select
                className="react-select mr-4"
                classNamePrefix="react-select"
                components={{ClearIndicator}}
                placeholder={""}
                options={CONTENT_TYPE_OPTIONS}
                value={CONTENT_TYPE_OPTIONS.find(o => o.value === state.selectedType)}
                onChange={(item: any) => setType({selectedType: item.value})}
                getOptionValue={(opt: any) => opt.value}
                getOptionLabel={item => item.label}
            />
            <button onClick={() => {
                let additionalContent: EntityWrapper<AdditionalContent> | undefined;
                switch (state.selectedType) {
                    case ADDITIONAL_CONTENT_TYPE_TEXT:
                        additionalContent = new EntityWrapper<TextAdditionalContent>(undefined,
                            {
                                contentType: ADDITIONAL_CONTENT_TYPE_TEXT,
                                title: t("add-additional-content.new-text-content"),
                                text: "",
                                versions: {
                                    releaseVersion: -1,
                                    draftVersion: 0
                                }
                            }
                        );
                        break;
                    case ADDITIONAL_CONTENT_TYPE_VIDEO:
                        additionalContent = new EntityWrapper<VideoAdditionalContent>(undefined,
                            {
                                contentType: ADDITIONAL_CONTENT_TYPE_VIDEO,
                                title: t("add-additional-content.new-video-content"),
                                videoData: undefined as any,
                                videoFile: undefined as any,
                                versions: {
                                    releaseVersion: -1,
                                    draftVersion: 0
                                }
                            }
                        );
                        break;
                    case ADDITIONAL_CONTENT_TYPE_FILE:
                        additionalContent = new EntityWrapper<FileAdditionalContent>(undefined,
                            {
                                contentType: ADDITIONAL_CONTENT_TYPE_FILE,
                                title: t("add-additional-content.new-file-content"),
                                file: undefined,
                                fileRef: undefined,
                                versions: {
                                    releaseVersion: -1,
                                    draftVersion: 0
                                }
                            }
                        );
                        break;
                    default:
                        additionalContent = undefined;
                }
                if (additionalContent) {
                    addAdditionalContent([additionalContent]);
                }

            }} className="btn btn-success d-flex align-items-center mt-auto mb-auto">
                <FontAwesomeIcon className="mr-2" icon={faPlus}/>{t("add-additional-content.new")}
            </button>
        </div>
    );
};

export const AddAdditionalContent = connect<{}, DispatchProps, {}>(
    null,
    {
        addAdditionalContent: createEntitiesAction(ADDITIONAL_CONTENT_TYPE)
    }
)(withTranslation("additionalContent")(AddAdditionalContentComponent));
