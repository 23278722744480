export const ENTITY_TYPE_COURSE_UPLOAD_STATUS = "course-upload-status"
export const COURSE_UPLOAD_ENTITY_ID = "course-publishing"

export interface CourseUploadStatus {
    courseRef: string,
    inProgress: boolean,
    logEntries: LogEntry[]
}

export interface LogEntry {
    timestamp: string,
    message: string
}
