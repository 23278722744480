import React, {useState} from 'react'
import {useRefetchableFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {MultiSelect} from "primereact/multiselect";
import {useTranslation} from "react-i18next";
import {
    AccountsSelectWithId_QueryFragmentRefetchQuery
} from "../../../../__generated__/AccountsSelectWithId_QueryFragmentRefetchQuery.graphql";
import {
    AccountsSelectWithId_QueryFragment$key
} from "../../../../__generated__/AccountsSelectWithId_QueryFragment.graphql";

const NUM_ACCOUNTS_PER_PAGE = 20

const QUERY_FRAGMENT = graphql`
    fragment AccountsSelectWithId_QueryFragment on Query @refetchable(queryName: "AccountsSelectWithId_QueryFragmentRefetchQuery") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String", defaultValue: null},
        name: {type: "String", defaultValue: null},
        ids: {type :"[ID!]!"}
    ) {
        Admin {
            Auth {
                AccountsMultiselect(first: $first, after: $after, nameOpt: $name, ids: $ids) @connection(key: "AccountsSelectWithId_AccountsMultiselect") {
                    edges {
                        cursor
                        node {
                            id
                            ... on Account {
                                name
                            }
                        }
                    }
                }
            }
        }
    }`

export interface Account {
    id: string
    name: string
}

interface OwnProps {
    fieldValue: string[]
    onChange: (accounts: string[]) => void
    queryFragmentRef: AccountsSelectWithId_QueryFragment$key
}

export const AccountsSelectWithId = ({
                                         fieldValue,
                                         onChange,
                                         queryFragmentRef
                                     }: OwnProps) => {

    const {t} = useTranslation("core")

    const [data, refetch] = useRefetchableFragment<AccountsSelectWithId_QueryFragmentRefetchQuery, AccountsSelectWithId_QueryFragment$key>(QUERY_FRAGMENT, queryFragmentRef)

    const accounts = data.Admin.Auth.AccountsMultiselect.edges?.filter(e => !!e).map(e => e!.node) || []

    const [accountsCache, setAccountsCache] = useState<Account[]>(accounts.filter(acc => fieldValue.includes(acc.id)))

    const onFilter = (e: { filter: string }) => {
        refetch({name: e.filter, first: NUM_ACCOUNTS_PER_PAGE, ids: fieldValue})
    }

    return <MultiSelect value={accountsCache}
                        options={accounts}
                        optionLabel="name"
                        onHide={() => onChange(accountsCache?.map((l: Account) => l.id) || [])}
                        onChange={(e) => setAccountsCache(e.value)}
                        filter
                        resetFilterOnHide={true}
                        filterPlaceholder={t("entity-selects.filter-placeholder")}
                        className="multiselect-custom"
                        onFilter={onFilter}
                        showSelectAll={false}
                        emptyFilterMessage={t("entity-selects.no-results")}
    />
}
