import { EditedCourseState } from "../../edited-course";
import { extractId } from "../common/helpers";
import update from "immutability-helper";
import { AddElementAction } from "../../../actions/element/add-element";

export function addElement(state: EditedCourseState,
                           {parentLessonId, element}: AddElementAction) {
    const parentLesson = state.editedCourse!.entity.lessons.find(m => m.id === parentLessonId || m.temporaryId === parentLessonId)!
    const parentLessonIndex = state.editedCourse!.entity.lessons.indexOf(parentLesson);

    return update(state,
        {
            hasUnsavedChanges: {$set: true},
            editedCourse: {
                entity: {
                    lessons: {[parentLessonIndex]: {entity: {elementsRef: {$push: [extractId(element)]}}}},
                    elements: {$push: [element]}
                }
            }
        }
    )
}
