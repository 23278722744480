import { Trigger } from "../../core/model/trigger/trigger";
import { EntityWrapper, TkFile } from "@thekeytechnology/framework-react";

export const ENTITY_TYPE_TRIGGERED_ACHIEVEMENT = "aggregated-triggered-achievement";
export const ENTITY_TYPE_TRIGGERED_ACHIEVEMENT_FOR_SAVING = "triggered-achievement";

export const NEW_TRIGGERED_ACHIEVEMENT = () => new EntityWrapper(undefined, {
    internalTitle: "Neu",
    titles: {},
    explanations: {},
    texts: {},
    textsGotten: {},
    image: undefined
} as TriggeredAchievement)

export interface TriggeredAchievement {
    internalTitle: string,
    titles: { [lang: string]: string },
    trigger?: Trigger,
    explanations: { [lang: string]: string },
    texts: { [lang: string]: string },
    textsGotten: { [lang: string]: string },
    image?: EntityWrapper<TkFile>,
    points: number
}

export interface TriggeredAchievementForSaving {
    internalTitle: string,
    titles: { [lang: string]: string },
    trigger?: Trigger,
    explanations: { [lang: string]: string },
    texts: { [lang: string]: string },
    textsGotten: { [lang: string]: string },
    imageRef?: string
    points: number
}
