import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import "./offer-table.scss";
import { ENTITY_TYPE_OFFER_V2, OfferV2 } from "@thekeytechnology/thekey-academy-frontend-library";
import { AboveTableContainer } from "../../../../../core/components/containers/Container";
import { PaginationInfo } from "../../../../../core/components/pagination/PaginationInfo";
import { EntityCreateAndDeleteButtons } from "../../../../../core/components/button/EntityCreateAndDeleteButtons";
import { Table, TableColumn } from "../../../../../core/components/table/Table";
import { EntityTableHeader } from "../../../../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../../../../core/components/table/AsyncEntityTableBody";
import { EntityTableRow } from "../../../../../core/components/table/EntityTableRow";
import { ActionTableColumn } from "../../../../../core/components/table/ActionTableColumn";
import { ActionLink } from "../../../../../core/components/icon-link-tooltip/ActionLink";

type Props =
    WithEntityListProps<OfferV2>
    & WithTranslation
    & WithSelectionsProps<string>;

const OffersTableComponent = ({
                                  entities,
                                  selectionModel,
                                  entityApiState,
                                  t
                              }: Props) => {

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_OFFER_V2} className="mr-auto"/>

                <EntityCreateAndDeleteButtons
                    entityType={ENTITY_TYPE_OFFER_V2}
                    selectedEntities={selectionModel.selections}
                    label={t("entity.offer.singular")}
                    labelPlural={t("entity.offer.plural")}
                    createPath="/offers-v2/new/edit"
                />
            </AboveTableContainer>

            <Table itemCount={entities.length} className="offer-v2-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.offer.labels.id")}</TableColumn>
                    <TableColumn>Interner Titel</TableColumn>
                    <TableColumn>Titel</TableColumn>
                    <TableColumn>Positionen</TableColumn>
                    <TableColumn>{t("entity.offer.labels.actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={offer =>
                        <EntityTableRow
                            key={offer.id}
                            entity={offer} selectionModel={selectionModel}>
                            <TableColumn>
                                {offer.id}
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {offer.entity.internalTitle}
                            </TableColumn>
                            <TableColumn>
                                {offer.entity.title}
                            </TableColumn>
                            <TableColumn>
                                {offer.entity.items.length} Positionen
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink to={`/offers-v2/${offer.id}/edit`} icon={faPencilAlt}
                                            tooltip={t("entity.offer.labels.edit-tooltip")}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const OffersV2Table = WithSelections<any, string>(
    WithEntityList(
        withTranslation("billing")(OffersTableComponent),
        ENTITY_TYPE_OFFER_V2
    )
);
