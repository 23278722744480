import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { useRouteMatch } from "react-router";
import { ValueField } from "../v2/single/ValueField";
import { useTranslation } from "react-i18next";
import { EditOrderScreen_Query } from "../../../../../__generated__/EditOrderScreen_Query.graphql";
import { LexofficeLink } from "../../common/LexofficeLink";
import { PaymentProviderLinksV3 } from "./PaymentProviderLinksV3";
import { ProviderDataDisplayV3 } from "./ProviderDataDisplayV3";
import { OrderHistoryTableV3 } from "./OrderHistoryTableV";

const QUERY = graphql`
    query EditOrderScreen_Query($orderId: ID!) {
        node(id: $orderId) {
            id
            ... on Order {
                billingDetails {
                    customerType
                    invoiceEmail
                    companyBillingDetails {
                        company
                        companyDetails
                        companyType
                    }
                    salutation
                    firstName
                    lastName
                    street
                    houseNumber
                    postalCode
                    country
                }
                allowedPaymentMethods

                paymentData {
                    dataType
                }

                invoiceData {
                    invoiceNumber
                    invoiceId
                }

                ...PaymentProviderLinksV3_OrderFragment
                ...ProviderDataDisplayV3_OrderFragment
                ...OrderHistoryTableV3_OrderFragment
            }
        }
    }
`;

export const EditOrderScreen = () => {
    const {t} = useTranslation("billing")
    const router = useRouteMatch<{ orderId: string }>();

    const order = useLazyLoadQuery<EditOrderScreen_Query>(QUERY, {orderId: router.params.orderId}).node

    if (!order) {
        return <div>Bestellung unbekannt</div>
    }

    return <div>
        <h1 className="mb-3">Bestellung </h1>

        <h2>Bestelldaten</h2>
        <ValueField label="Kundenart"
                    value={t("billing:customer-types." + order.billingDetails?.customerType)}/>
        <ValueField label="Rechnungsemail" value={order.billingDetails?.invoiceEmail}/>

        {order.billingDetails?.customerType === "business" ? <>
            <hr/>
            <ValueField label="Firmenart"
                        value={order.billingDetails.companyBillingDetails?.companyType}/>
            <ValueField label="Firma"
                        value={order.billingDetails.companyBillingDetails?.company}/>
            <ValueField label="Firmendetails"
                        value={order.billingDetails.companyBillingDetails?.companyDetails}/>
            <hr/>
        </> : null}

        <ValueField label="Anrede" value={order.billingDetails?.salutation}/>
        <ValueField label="Vorname" value={order.billingDetails?.firstName}/>
        <ValueField label="Nacnname" value={order.billingDetails?.lastName}/>
        <ValueField label="Straße" value={order.billingDetails?.street}/>
        <ValueField label="Hausnummer" value={order.billingDetails?.houseNumber}/>
        <ValueField label="PLZ" value={order.billingDetails?.postalCode}/>
        <ValueField label="Land" value={order.billingDetails?.country}/>

        <h3>Zahlungsdaten</h3>
        <ValueField label="Erlaubte Zahlmethoden"
                    value={order.allowedPaymentMethods?.map(pm => t("billing:payment-methods." + pm)).join(", ")}/>
        {order.paymentData ? <>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Zahlmethode</label>
                <div className="col-sm-10 orm-control default-input d-flex align-items-center">
                    <PaymentProviderLinksV3 orderFragmentRef={order}/>
                </div>
            </div>
            <ProviderDataDisplayV3 orderFragmentRef={order}/>
        </> : null}

        {order.invoiceData ? <>
            <h3>Rechnungsdaten</h3>

            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Rechnung</label>
                <div className="col-sm-10 orm-control default-input d-flex align-items-center">
                    <LexofficeLink invoiceData={order.invoiceData}/>
                </div>
            </div>
        </> : null}

        <h3>Historie</h3>
        <OrderHistoryTableV3 orderFragmentRef={order}/>
    </div>

}
