import {EntityWrapper, SelectionModel, TkFile} from "@thekeytechnology/framework-react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AttachmentDisplay} from "./AttachmentDisplay";
import {FileSelector} from "./FileSelector";

interface OwnProps {
    selectionModel: SelectionModel<string>
    isSingleSelect: boolean;

    value: EntityWrapper<TkFile>[] | EntityWrapper<TkFile> | undefined
    changeValue: (newValue: EntityWrapper<TkFile>[] | EntityWrapper<TkFile> | undefined) => void;
}

type Props = OwnProps;

export const InlineAttachmentSelect = ({value, changeValue, isSingleSelect}: Props) => {
    const {t} = useTranslation("files")

    const [state, setState] = useState({
        selectorShown: false
    })

    const remove = (item: EntityWrapper<TkFile>) => {
        if (isSingleSelect) {
            onSelectionChange(undefined);
        } else {
            onSelectionChange((value as EntityWrapper<TkFile>[]).filter((f: any) => f.id !== item.id));
        }
    }

    const onSelectionChange = (selections: EntityWrapper<TkFile>[] | undefined) => {
        const newValue = isSingleSelect ? (selections && selections.length > 0 ? selections[0] : undefined) : selections;
        changeValue(newValue);
        setState({
            selectorShown: false
        });
    }

    return (
        <>
            {!state.selectorShown && <div className="attachment-select">
                {value ?
                    <ul className="list-group mb-3">
                        {isSingleSelect ? <AttachmentDisplay item={value as EntityWrapper<TkFile>} remove={remove}/>
                            : (
                                Array.isArray(value) ? (value as EntityWrapper<TkFile>[]).map((file: EntityWrapper<TkFile>) =>
                                    <AttachmentDisplay key={file.id} item={file} remove={remove}/>
                                ) : <p>There is an error here, the field is set to not single select but the return
                                    value is not an array.</p>
                            )
                        }
                    </ul>
                    : null}
                <button type="button" className="btn btn-secondary" onClick={() => {
                    setState({
                        selectorShown: true
                    });
                }}>
                    {t("attachment-field.browse")}
                </button>
            </div>}
            {state.selectorShown && <FileSelector
                isSingleSelect={isSingleSelect}
                initialSelections={
                    isSingleSelect ? (value ? [(value as EntityWrapper<TkFile>).id] : [])
                        : Array.isArray(value) ? value.map((f: any) => f.id) : []
                }
                onSelection={onSelectionChange}
                onClose={() => {
                    setState({
                        selectorShown: false
                    });
                }}/>
            }
        </>
    );
}
