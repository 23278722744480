import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { TriggeredAchievementsTable } from "./TriggeredAchievementsTable";
import { ENTITY_TYPE_TRIGGERED_ACHIEVEMENT } from "../model/triggered-achievement";
import { ContentContainer, HeadingContainer, PaginationContainer } from "../../core/components/containers/Container";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const AchievementsOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/achievements"}>{t("entity.achievement.singular")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.triggered-achievement.singular")}/>
            </HeadingContainer>
            <ContentContainer>
                <TriggeredAchievementsTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_TRIGGERED_ACHIEVEMENT}/>
            </PaginationContainer>
        </>
    );
};

export const TriggeredAchievementsOverview = withTranslation("achievements")(AchievementsOverviewComponent);
