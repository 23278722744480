import { UserWithRoles } from "@thekeytechnology/framework-react";
import { TriggeredUnlocksOverview } from "./TriggeredUnlocksOverview";
import { EditTriggeredUnlock } from "./EditTriggeredUnlock";
import { PrivateRouteDefinition } from "../../core/utils/PrivateRouteDefinition";

export const UnlockModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/unlocks",
        component: TriggeredUnlocksOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/unlocks/:unlockId/edit",
        component: EditTriggeredUnlock,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
];
