import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import "./glossary-entry-table.scss";
import {GlossaryEntry} from "../model/glossary-entry";
import { AboveTableContainer } from "../../core/components/containers/Container";
import { PaginationInfo } from "../../core/components/pagination/PaginationInfo";
import { EntityCreateAndDeleteButtons } from "../../core/components/button/EntityCreateAndDeleteButtons";
import { Table, TableColumn } from "../../core/components/table/Table";
import { EntityTableHeader } from "../../core/components/table/EntityTableHeader";
import { ActionTableColumn } from "../../core/components/table/ActionTableColumn";
import { EntityTableRow } from "../../core/components/table/EntityTableRow";
import { AsyncEntityTableBody } from "../../core/components/table/AsyncEntityTableBody";
import { ActionLink } from "../../core/components/icon-link-tooltip/ActionLink";

type Props =
    WithEntityListProps<GlossaryEntry>
    & WithTranslation
    & WithSelectionsProps<string>;

const GlossaryEntryTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={GlossaryEntry.TYPE} className="mr-auto"/>

                <EntityCreateAndDeleteButtons
                    selectedEntities={selectionModel.selections}
                    entityType={GlossaryEntry.TYPE}
                    createPath="/glossary/new/edit"
                    label={t("entity.glossary-entry.singular")}
                    labelPlural={t("entity.glossary-entry.plural")}
                />
            </AboveTableContainer>

            <Table itemCount={entities.length} className="glossary-entry-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.glossary-entry.labels.title")}</TableColumn>
                    <TableColumn>{t("entity.glossary-entry.labels.actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={glossaryEntry =>
                        <EntityTableRow
                            key={glossaryEntry.id}
                            entity={glossaryEntry} selectionModel={selectionModel}>
                            <TableColumn className="bold title-column">
                                {glossaryEntry.entity.title}
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink to={`/glossary/${glossaryEntry.id}/edit`} icon={faPencilAlt}
                                            tooltip={t("entity.glossary-entry.labels.edit-tooltip")}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const GlossaryEntryTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation("glossary")(GlossaryEntryTableComponent),
        GlossaryEntry.TYPE
    )
);
