import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { ListGroup } from "react-bootstrap";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { DndProvider } from "react-dnd";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
    PreparingAggregatedCourse,
    COURSE_ATTRIBUTE_PUBLISHED,
    ENTITY_TYPE_AGGREGATED_COURSE
} from "../../model/preparing-aggregated-course";
import { selectEditedCourse } from "../../selectors";
import { CourseSaveButton } from "./course/CourseSaveButton";
import { EditCourse } from "./course/EditCourse";
import { ModuleList } from "./module/ModuleList";
import "./course-editor.scss";
import { HTML5Backend } from "react-dnd-html5-backend";
import { VersionsBadge } from "./common/VersionsBadge";
import { ShowWhenNotLocked } from "../../../locking/components/ShowWhenNotLocked";
import { LoadingEditHeader } from "../../../core/components/header/LoadingEditHeader";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../core/components/entity/WithSingleEntityFromPath";

interface StateProps {
    editedCourse?: EntityWrapper<PreparingAggregatedCourse>;
}

type Props = StateProps & WithTranslation & WithSingleEntityFromPathProps<PreparingAggregatedCourse, PreparingAggregatedCourse>

const CourseEditorComponent = (props: Props) => {
    const {t, editedCourse} = props;

    return <DndProvider backend={HTML5Backend}>
        {editedCourse ? (
            <ShowWhenNotLocked resourceId={editedCourse?.id!}>
                <BreadcrumbsItem to={"/courses/"}>{t("entity.course.plural")}</BreadcrumbsItem>
                {editedCourse ?
                    <BreadcrumbsItem
                        to={"/course-defintions/" + editedCourse.id}>
                        {editedCourse.entity.containerMeta.title}
                    </BreadcrumbsItem> : null}

                <LoadingEditHeader entity={editedCourse} label={t("entity.course.singular")}/>

                {editedCourse && editedCourse.entity.attributes.includes(COURSE_ATTRIBUTE_PUBLISHED) && editedCourse.entity.versions.draftVersion > editedCourse.entity.versions.releaseVersion ?
                    <div className="alert alert-warning">
                        Dieser Kurs ist veröffentlicht, enthält aber unveröffentlichte Änderungen. Zu gegeber Zeit
                        sollte
                        der Kurs neu veröffentlicht werden. Die geänderten Kursbestandteile sind markiert.
                    </div> : null}

                <ListGroup.Item
                    className={"d-flex flex-row align-items-center mb-5"}>
                <span>
                                    {t("course-editor.course-item.title", {courseTitle: editedCourse.entity.containerMeta.title})}
                </span>

                    {editedCourse ?
                        <VersionsBadge versions={editedCourse.entity.versions} className="ml-2 mr-2"/> : null}


                    <div className="edit-buttons ml-auto mr-2">
                        <EditCourse
                            course={editedCourse}/>
                        <CourseSaveButton/>
                    </div>
                </ListGroup.Item>

                <div className="course-editor">
                    {editedCourse ? <ModuleList modules={editedCourse.entity.modules}/> : null}
                </div>
            </ShowWhenNotLocked>
        ) : null}
    </DndProvider>
};

export const CourseEditor = connect<StateProps, {}, {}>(
    (state: any) => ({
        editedCourse: selectEditedCourse(state)
    })
)(WithSingleEntityFromPath(
    withTranslation("courses")(CourseEditorComponent),
    ENTITY_TYPE_AGGREGATED_COURSE, "courseId", undefined) as any);
