import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ENTITY_TYPE_TRIGGER_EXECUTION } from "../../model/TriggerExecution";
import { Filters } from "../../../core/components/filters/Filters";
import { TextFilter } from "../../../core/components/filters/TextFilter";
import { NumberFilter } from "../../../core/components/filters/NumberFilter";

const ExpertFiltersComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <Filters entityType={ENTITY_TYPE_TRIGGER_EXECUTION}
                 filterKeys={[`entity.triggerKey`, "entity.parameters.key"]}>
            <TextFilter
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.trigger-execution.labels.trigger-key")})}
                entityType={ENTITY_TYPE_TRIGGER_EXECUTION}
                property={`entity.triggerKey`}/>

            <TextFilter
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.trigger-execution.labels.parameter-key")})}
                entityType={ENTITY_TYPE_TRIGGER_EXECUTION}
                property={`entity.parameters.key`}/>
            <TextFilter
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.trigger-execution.labels.parameter-value-string")})}
                entityType={ENTITY_TYPE_TRIGGER_EXECUTION}
                property={`entity.parameters.value`}/>
            <NumberFilter
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.trigger-execution.labels.parameter-value-int")})}
                entityType={ENTITY_TYPE_TRIGGER_EXECUTION}
                property={`entity.parameters.value`}/>
        </Filters>
    );
};

export const TriggerExecutionsFilters = withTranslation(["triggers", "core"])(ExpertFiltersComponent);
