import { faCheck, faCircleNotch, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiCallState, selectApiCallState, selectCombinedApiState } from "@thekeytechnology/framework-react";
import React from "react";
import { connect } from "react-redux";

interface ApiStateSubmitButtonProps {
    isSubmitting: boolean;
    hasError: boolean;
    apiQualifier: string;
    callType?: string;

    submitText?: string;

}

interface StateProps {
    apiState: ApiCallState;
}

type Props = ApiStateSubmitButtonProps & StateProps;

const ApiStateSubmitButtonComponent = (props: Props) => {
    const {isSubmitting, hasError, apiState, submitText} = props;
    return (
        <button className="btn btn-outline-success" type="submit"
                disabled={isSubmitting || apiState.inProgress || hasError}>
            {apiState.inProgress ? <FontAwesomeIcon icon={faCircleNotch} spin/> :
                apiState.succeeded ? <FontAwesomeIcon icon={faCheck}/> :
                    apiState.failed ? <FontAwesomeIcon icon={faTimes}/> :
                        (submitText ? submitText : <FontAwesomeIcon icon={faSave}/>)}
        </button>
    );
};

export const ApiStateSubmitButton = connect<StateProps, {}, ApiStateSubmitButtonProps>(
    (state: any, ownProps: ApiStateSubmitButtonProps) => {
        return {
            ...ownProps,
            apiState: (ownProps.callType ?
                selectApiCallState(ownProps.apiQualifier, ownProps.callType) :
                selectCombinedApiState(ownProps.apiQualifier))(state),
        };
    }
)(ApiStateSubmitButtonComponent);
