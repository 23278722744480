import {
    reloadEntityListAction,
    selectApiBase,
    selectAuthState,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import { TkFile } from "@thekeytechnology/framework-react";
import Axios, { AxiosInstance } from "axios";
import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

registerPlugin(FilePondPluginImagePreview);

interface OwnProps {
    onUploaded?: (id: string) => void;
}

interface DispatchProps {
    fetchList: ReturnType<typeof reloadEntityListAction>
}

interface StateProps {
    authedAxios: AxiosInstance

}

type Props = StateProps & DispatchProps & WithTranslation & OwnProps;

const UploaderComponent = ({authedAxios, fetchList, t, onUploaded}: Props) => {

    const [state] = useState({
        isPublic: false
    })

    return (
        <div className="form-group">
            <FilePond
                instantUpload={true}
                allowRevert={false}

                labelIdle={t("filepond.idle")}
                labelFileProcessing={t("filepond.file-processing")}
                labelTapToCancel={t("filepond.tap-to-cancel")}
                labelFileProcessingComplete={t("filepond.file-processing-complete")}
                server={{
                    process: (fieldName: any, file: any, metadata: any, load: any, error: any, progress: any) => {
                        const formData = new FormData();
                        formData.append("file", file, file.name);
                        formData.append("isPublic", state.isPublic ? "true" : "false")

                        const CancelToken = Axios.CancelToken;
                        const source = CancelToken.source();

                        authedAxios.post("/files/upload", formData, {
                            cancelToken: source.token,
                            onUploadProgress: e => {
                                // updating progress indicator
                                progress(e.lengthComputable, e.loaded, e.total);
                            }
                        }).then(response => {
                            // passing the file id to FilePond
                            load(response.data.fileId);
                            fetchList();
                            if (onUploaded) {
                                onUploaded(response.data.fileId)
                            }
                        }).catch(thrown => {
                            error(thrown);
                        });

                        // noinspection JSUnusedGlobalSymbols
                        return {
                            abort: () => {
                                source.cancel("Operation canceled by the user.");
                            }
                        };
                    }
                }}
            />
        </div>
    );
};

export const Uploader = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            authedAxios: withAuthedAxios(selectApiBase(state), selectAuthState(state))
        };
    },
    {
        fetchList: reloadEntityListAction(TkFile.TYPE)
    }
)(withTranslation("files")(UploaderComponent));
