import { UserWithRoles } from "@thekeytechnology/framework-react";
import { TriggerExecutionsOverview } from "./components/overview/TriggerExecutionsOverview";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";

export const TriggersModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/trigger-executions",
        component: TriggerExecutionsOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
];
