import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import "./single-value-form.scss"
import { ApiStateSubmitButton } from "./ApiStateSubmitButton";
interface SingleValueFormProps {
    initialValue: any;
    label: string;
    apiQualifier: string;
    callType?: string;
    icon: IconDefinition;
    validationSchema?: any | (() => any);
    onSubmit: (value: any) => void;

    // 'Indexer'
    [x: string]: any
}

export const SingleValueForm = (props: SingleValueFormProps) => {
    const {label, icon, initialValue, onSubmit, validationSchema, apiQualifier, callType, ...rest} = props;
    return (
        <Formik
            initialValues={{
                value: initialValue,
            }}
            validationSchema={Yup.object().shape({
                value: validationSchema
            })}
            onSubmit={(values, {setSubmitting}) => {
                onSubmit(values.value);
                setSubmitting(false);
            }}
        >
            {({isSubmitting, errors, touched}) => {
                const hasError = !!(errors.value && touched.value);

                return (<Form className="single-value-form w-100">
                        <div className="form-group row">
                            <div className="col-12 col-sm-2">
                                <label htmlFor="value" className="col-form-label">{label}</label>
                            </div>
                            <div className="col-9 col-sm-9">
                                <div className="input-field-wrapper">
                                    <FontAwesomeIcon className="input-icon" icon={icon}/>
                                    <Field name="value"
                                           {...rest}
                                           className={"form-control default-input " + (hasError ? "error" : "")}/>
                                </div>
                            </div>
                            <div className="col-3 col-sm-1 d-flex align-items-center justify-content-center">
                                <ApiStateSubmitButton apiQualifier={apiQualifier} callType={callType}
                                                      hasError={hasError}
                                                      isSubmitting={isSubmitting}/>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>);
};
