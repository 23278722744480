import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_UPDATE_USER_META = "update-user-meta";

export interface UpdateUserMeta {
    userId: string
    firstName?: string
    lastName?: string
    position?: string
    adsOptIn?: boolean
    isOnboardingSeen?: boolean
}

export const updateUserMetaAction = (userId: string, firstName?: string, lastName?: string, position?: string, adsOptIn?: boolean, isOnboardingSeen?: boolean) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_USER_META,
            callType: API_UPDATE_USER_META
        },
        payload: {
            userId,
            firstName,
            lastName,
            position,
            adsOptIn,
            isOnboardingSeen
        }
    } as ApiCallAction<UpdateUserMeta>;
};
