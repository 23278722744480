import graphql from "babel-plugin-relay/macro"
import {Button} from "primereact/button";
import {useFragment, useMutation} from "react-relay";
import React, {useRef, useState} from "react";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from "primereact/inputtext";
import {useTranslation} from "react-i18next";
import {
    CreateSingleCodeButton_CreateMutation
} from "../../../../../../__generated__/CreateSingleCodeButton_CreateMutation.graphql";
import {Toast} from "primereact/toast";
import {
    CreateSingleCodeButton_DiscountActionFragment$key
} from "../../../../../../__generated__/CreateSingleCodeButton_DiscountActionFragment.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
    fragment CreateSingleCodeButton_DiscountActionFragment on DiscountAction {
        id
    }
`

const CREATE_DISCOUNT_CODE_MUTATION = graphql`
    mutation CreateSingleCodeButton_CreateMutation($input: CreateDiscountCodeV2Input!,$connections: [ID!]! ){
        Admin {
            Billing {
                createDiscountCodeV2(input: $input){
                    data @appendEdge(connections: $connections){
                        node {
                            ...DiscountCodeTable_DiscountCodeFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    className?: string
    connectionId: string
    discountActionFragmentRef: CreateSingleCodeButton_DiscountActionFragment$key
}

export const CreateSingleCodeButton = ({connectionId, className, discountActionFragmentRef}: OwnProps) => {
    const createDiscountSingleCodeButtonNs = "billing.discount-code.button.create-code-single"
    const {t} = useTranslation("billingV3")

    const discountAction = useFragment<CreateSingleCodeButton_DiscountActionFragment$key>(DISCOUNT_ACTION_FRAGMENT, discountActionFragmentRef)
    const [createSingleCode, isLoading] = useMutation<CreateSingleCodeButton_CreateMutation>(CREATE_DISCOUNT_CODE_MUTATION)

    const [codeName, setCodeName] = useState<string>("Neu")

    const overlayPanel = useRef<OverlayPanel>(null)
    const toast = useRef<Toast>(null)

    return (
        <>
            <Toast ref={toast}/>
            <Button
                label={t(`${createDiscountSingleCodeButtonNs}.title`)}
                loading={isLoading}
                disabled={isLoading}
                className={className}
                onClick={(e) => overlayPanel.current?.toggle(e)}
            />
            <OverlayPanel ref={overlayPanel}>
                <span className="p-float-label mb-2 mt-3">
                    <InputText id="nameOfCode" value={codeName} onChange={e => setCodeName(e.target.value)}/>
                    <label htmlFor="nameOfCode">{t(`${createDiscountSingleCodeButtonNs}.placeholder`)}</label>
                </span>
                <Button
                    label={t(`${createDiscountSingleCodeButtonNs}.submit`)}
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => {
                        createSingleCode({
                            variables: {
                                input: {
                                    data: {
                                        code: codeName,
                                        discountActionId: discountAction.id
                                    }
                                },
                                connections: [connectionId]
                            },
                            onCompleted: () => {
                                toast.current?.show({
                                    severity: "success",
                                    life: 3000,
                                    summary: t(`${createDiscountSingleCodeButtonNs}.toast.success.summary`),
                                    detail: t(`${createDiscountSingleCodeButtonNs}.toast.success.detail`),
                                })
                            }
                        })
                    }}/>
            </OverlayPanel>
        </>
    )
}
