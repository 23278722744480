import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Expert } from "../model/expert";
import { Filters } from "../../core/components/filters/Filters";
import { TextFilter } from "../../core/components/filters/TextFilter";

const ExpertFiltersComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <Filters entityType={Expert.TYPE}
                 filterKeys={[`entity.name`]}>
            <TextFilter
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.expert.labels.name")})}
                entityType={Expert.TYPE}
                property={`entity.name`}/>
        </Filters>
    );
};

export const ExpertFilters = withTranslation(["expert", "core"])(ExpertFiltersComponent);
