import React from "react";
import { useFragment } from "react-relay";
import { ProviderDataDisplayV3_OrderFragment$key } from "../../../../../__generated__/ProviderDataDisplayV3_OrderFragment.graphql";
import { ValueField } from "../v2/single/ValueField";
import graphql from "babel-plugin-relay/macro";
import { DateTimeDisplay } from "../../../../core/components/datetime/DateTimeDisplay";

const ORDER_FRAGMENT = graphql`
    fragment ProviderDataDisplayV3_OrderFragment on Order {

        paymentData {
            ... on SepaData {
                iban
                mandateInformation {
                    acceptanceDateTime
                    mandateNumber
                }
            }
            ... on StripeSepaData {
                mandateInformation {
                    acceptanceDateTime
                    mandateNumber
                }
            }
        }
    }
`;

interface OwnProps {
    orderFragmentRef: ProviderDataDisplayV3_OrderFragment$key
}

export const ProviderDataDisplayV3 = ({orderFragmentRef}: OwnProps) => {

    const order = useFragment<ProviderDataDisplayV3_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    return order.paymentData?.mandateInformation ? <>
        <ValueField label={"IBAN"} value={order.paymentData.iban}/>
        <ValueField label={"Mandatsnummer"}
                    value={order.paymentData.mandateInformation.mandateNumber}/>
        <div className="form-group row">
            <label className="col-sm-2 col-form-label">Akzeptiert am</label>
            <div className="col-sm-10 orm-control default-input">
                <DateTimeDisplay
                    dateTime={order.paymentData.mandateInformation.acceptanceDateTime as any}/>
            </div>
        </div>
    </> : null;
}
