import { EntityWrapper, generateString } from "@thekeytechnology/framework-react";
import update from "immutability-helper";
import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { addLessonAction } from "../../../actions/lesson/add-lesson";
import { removeLessonAction } from "../../../actions/lesson/remove-lesson";
import { Lesson } from "../../../model/lesson/lesson";
import { extractId } from "../../../reducers/changes/common/helpers";
import { CourseEditorCardHeader } from "../common/CourseEditorCardHeader";
import { DRAGGABLE_TYPES } from "../common/draggable-types";
import { withCourseEditorDraggable, WithCourseEditorDraggableProps } from "../common/WithCourseEditorDraggable";
import { ElementList } from "../elements/ElementList";
import { EditLesson } from "./EditLesson";
import { selectEditedCourse, selectIsCardOpen } from "../../../selectors";
import { toggleCardAction } from "../../../actions/course-editor/toggle-card";
import { IsTrialBadge } from "../common/TrialBadge";
import { HasImageBadge } from "../common/HasImageBadge";
import { LESSON_TYPE_NORMAL } from "@thekeytechnology/thekey-academy-frontend-library";
import { FileDownloadLink } from "./FileDownloadLink";

interface OwnProps {
    moduleIndex: number;
    parentModuleId: string;
    lessonIndex: number;
    lesson: EntityWrapper<Lesson>;
}

interface StateProps {
    isCardOpen: boolean
}

interface DispatchProps {
    addLesson: typeof addLessonAction;
    removeLesson: typeof removeLessonAction;
    toggleCard: typeof toggleCardAction;
}

type Props = OwnProps & StateProps & DispatchProps & WithCourseEditorDraggableProps;

const LessonItemComponent = ({
                                 lessonIndex,
                                 lesson,
                                 moduleIndex,
                                 parentModuleId,
                                 addLesson,
                                 removeLesson,
                                 dragHandleRef,
                                 dropMightHappen,
                                 toggleCard,
                                 isCardOpen
                             }: Props) => {

    const {t} = useTranslation("courses");

    const course = useSelector(selectEditedCourse);

    return course ? <Card>
        <CourseEditorCardHeader
            itemId={lesson.id ? lesson.id : lesson.temporaryId}
            item={lesson}
            dragHandleRef={dragHandleRef}
            title={`${lesson.entity.lessonType === LESSON_TYPE_NORMAL ? t("entity.lesson.singular") : t("entity.lesson.singular-test")} ${moduleIndex + 1}.${lessonIndex + 1} `}
            dropMightHappen={dropMightHappen}
            onCopy={() => {
                const copiedLesson = update(lesson, {
                    id: {$set: undefined},
                    entity: {containerMeta: {title: {$set: lesson.entity.containerMeta.title + " (Kopie)"}}},
                    temporaryId: {$set: generateString(10)}
                });
                addLesson(parentModuleId, copiedLesson);
            }}
            onDelete={() => removeLesson(
                parentModuleId,
                lesson
            )}
            onClick={() => toggleCard(extractId(lesson))}

            badgesSlot={() => <><IsTrialBadge isPartOfTrial={lesson.entity.isPartOfTrial}/> <HasImageBadge
                course={course!} lesson={lesson}/></>}
        >
            <EditLesson
                parentModuleId={parentModuleId}
                lesson={lesson}/>

            {lesson.entity.lessonFileRef ?
                <FileDownloadLink className="ml-2" fileId={lesson.entity.lessonFileRef}/> : null}
        </CourseEditorCardHeader>
        {isCardOpen &&
        <Card.Body>
            <ElementList
                moduleIndex={moduleIndex}
                parentModuleId={parentModuleId}
                lessonIndex={lessonIndex}
                parentLessonId={extractId(lesson)}
                elementIds={lesson.entity.elementsRef}/>
        </Card.Body>}
    </Card> : null;
};

export const LessonItem = connect<StateProps, DispatchProps, OwnProps>(
    (state: any, {lesson}: OwnProps) => ({
        isCardOpen: selectIsCardOpen(extractId(lesson))(state)
    }),
    {
        addLesson: addLessonAction,
        removeLesson: removeLessonAction,
        toggleCard: toggleCardAction
    }
)(withCourseEditorDraggable(DRAGGABLE_TYPES.LESSON, LessonItemComponent));
