import { ApiCallAction } from "@thekeytechnology/framework-react";
import {UserInAccount} from "../model/UserInAccount";

export const API_UPDATE_USER_ROLES = "update-user-roles";

export interface UpdateUserRoles {
    account: string,
    usersInAccount: UserInAccount[]
}

export const updateUserRolesAction = (updateUserRoles: UpdateUserRoles ) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_USER_ROLES,
            callType: API_UPDATE_USER_ROLES
        },
        payload: updateUserRoles
    } as ApiCallAction<UpdateUserRoles>;
};
