import { FieldProps } from "formik";
import React from "react";
import { Course, ENTITY_TYPE_COURSE } from "../../../../../../course/model/preparing-aggregated-course";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { PriceEditor } from "./PriceEditor";
import "./offer-items-field.scss"
import { replaceInArray } from "../../../../../../core/utils/utils";
import { CourseOfferItem, DiscountOfferItem, OfferItem } from "@thekeytechnology/thekey-academy-frontend-library";
import { Table, TableBody, TableColumn, TableRow } from "../../../../../../core/components/table/Table";
import { NoDataRow } from "../../../../../../core/components/table/NoDataRow";
import { AsyncEntitySelect } from "../../../../../../core/components/entity/AsyncEntitySelect";

type Props = FieldProps

export const OfferItemsField = ({
                                    field,
                                    form
                                }: Props) => {
    const items = field.value as OfferItem[];

    const addDiscount = () => {
        if (!items.find(item => item.itemType === "discount-offer-item")) {
            form.setFieldValue(field.name, [
                ...items,
                {
                    price: {
                        taxRate: 19,
                        netPrice: 10
                    },
                    title: "Rabatt",
                    itemType: "discount-offer-item",
                } as DiscountOfferItem
            ])
        }
    }

    return <div className="offer-items-field">
        <Table itemCount={items.length}>
            <TableBody>
                {items.length === 0 ? <NoDataRow/> : null}
                {items.map((item, index) => {

                    let ItemEditor;
                    switch (item.itemType) {
                        case "course-offer-item":
                            const courseItem = item as CourseOfferItem
                            ItemEditor = <TableRow key={index} className="course-offer-item-row">
                                <TableColumn>
                                    <label className="d-flex flex-column w-100">
                                        <div>Menge</div>
                                        <input type="number"
                                               className="form-control default-input"
                                               step={1}
                                               min={1}
                                               value={courseItem.amount}
                                               onBlur={field.onBlur}
                                               onChange={event => {
                                                   form.setFieldValue(
                                                       field.name,
                                                       replaceInArray(
                                                           items,
                                                           index,
                                                           {
                                                               ...item,
                                                               amount: parseFloat(event.target.value)
                                                           } as CourseOfferItem)
                                                   )
                                               }}/>
                                    </label>
                                </TableColumn>
                                <TableColumn>
                                    <label className="d-flex flex-column w-100">
                                        <div>Kurs</div>
                                        <AsyncEntitySelect
                                            shownEntityType={ENTITY_TYPE_COURSE}
                                            shownEntityTypeProperties={["entity.containerMeta.title"]}
                                            listRenderer={(item: EntityWrapper<Course>) => item?.entity?.containerMeta?.title}
                                            selected={{id: courseItem.courseRef} as EntityWrapper<Course>}
                                            select={newValue => {
                                                form.setFieldValue(
                                                    field.name,
                                                    replaceInArray(
                                                        items,
                                                        index,
                                                        {
                                                            ...item,
                                                            courseRef: (newValue as EntityWrapper<Course>)?.id
                                                        } as CourseOfferItem)
                                                )
                                            }}
                                            onBlur={field.onBlur}
                                            placeholder={"Kurs auswählen"}/>
                                    </label>
                                </TableColumn>
                                <TableColumn>
                                    <PriceEditor
                                        price={courseItem.price}
                                        onBlur={field.onBlur}
                                        onUpdate={newPrice => {
                                            form.setFieldValue(
                                                field.name,
                                                replaceInArray(
                                                    items,
                                                    index,
                                                    {
                                                        ...item,
                                                        price: newPrice
                                                    } as CourseOfferItem)
                                            )
                                        }}/>
                                </TableColumn>
                                <TableColumn>
                                    <button type="button" className="btn btn-danger" onClick={() => {
                                        form.setFieldValue(
                                            field.name,
                                            items.filter((i, idx) => idx !== index)
                                        )
                                    }}>
                                        Löschen
                                    </button>
                                </TableColumn>
                            </TableRow>
                            break;
                        case "discount-offer-item":
                            const discountItem = item as DiscountOfferItem
                            ItemEditor = <TableRow key={index} className="discount-offer-item-row">
                                <TableColumn>
                                    <label className="d-flex flex-column w-100">
                                        <div>Name des Rabatts</div>
                                        <input type="text"
                                               className="form-control default-input"
                                               value={discountItem.title}
                                               onBlur={field.onBlur}
                                               onChange={event => {
                                                   form.setFieldValue(
                                                       field.name,
                                                       replaceInArray(
                                                           items,
                                                           index,
                                                           {
                                                               ...item,
                                                               title: event.target.value
                                                           } as DiscountOfferItem)
                                                   )
                                               }}/>
                                    </label>
                                </TableColumn>
                                <TableColumn>
                                    <PriceEditor
                                        disableDiscount={true}
                                        price={discountItem.price}
                                        onBlur={field.onBlur}
                                        onUpdate={newPrice => {
                                            form.setFieldValue(
                                                field.name,
                                                replaceInArray(
                                                    items,
                                                    index,
                                                    {
                                                        ...item,
                                                        price: newPrice
                                                    } as DiscountOfferItem)
                                            )
                                        }}/>
                                </TableColumn>

                                <TableColumn>
                                    <button type="button" className="btn btn-danger" onClick={() => {
                                        form.setFieldValue(
                                            field.name,
                                            items.filter((i, idx) => idx !== index)
                                        )
                                    }}>
                                        Löschen
                                    </button>
                                </TableColumn>
                            </TableRow>
                            break;
                        default:
                            ItemEditor = null;
                            break;
                    }

                    return ItemEditor
                })}
            </TableBody>

        </Table>

        <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-success" onClick={() => {
                form.setFieldValue(field.name, [
                    ...items,
                    {
                        amount: 1,
                        courseRef: "",
                        itemType: "course-offer-item",
                        price: {
                            netPrice: 0,
                            taxRate: 19
                        }
                    } as CourseOfferItem
                ])
            }}>
                Kursangebot hinzufügen
            </button>

            <button type="button" className="btn btn-success ml-2" onClick={addDiscount}>
                Rabatt hinzufügen
            </button>
        </div>


    </div>

}
