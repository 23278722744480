import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import "./attachment-display.scss"

interface AttachmentDisplayProps extends WithTranslation {
    item: EntityWrapper<TkFile>
    remove: (item: EntityWrapper<TkFile>) => void;
}

export const AttachmentDisplayComponent = (props: AttachmentDisplayProps) => {
    const {item, remove, t} = props;
    return <li className="d-flex list-group-item attachment-display">
        <div className="mr-2">
            {item.entity.thumbnailUrl ?
                <img alt="" src={item.entity.thumbnailUrl}/> : <>{t("attachment-display.no-preview")}</>}
        </div>
        <div className="title">{item.entity.name}&nbsp;</div>
        <div className="cross ml-auto">
            <button type="button" className="btn-link" onClick={() => {
                remove(props.item);
            }}><FontAwesomeIcon icon={faTimes}/></button>
        </div>
    </li>;
};

export const AttachmentDisplay = withTranslation("files")(AttachmentDisplayComponent);
