import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import "./sync-requests-table.scss";
import {ENTITY_TYPE_SYNC_REQUEST, SyncRequest} from "../model/SyncRequest";
import { AboveTableContainer } from "../../core/components/containers/Container";
import { PaginationInfo } from "../../core/components/pagination/PaginationInfo";
import { Table, TableColumn } from "../../core/components/table/Table";
import { EntityTableHeader } from "../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../core/components/table/AsyncEntityTableBody";
import { EntityTableRow } from "../../core/components/table/EntityTableRow";
import { OverlayTooltip } from "../../core/components/tooltip/OverlayTooltip";
import { DateTimeDisplay } from "../../core/components/datetime/DateTimeDisplay";

type Props =
    WithEntityListProps<SyncRequest>
    & WithTranslation
    & WithSelectionsProps<string>;

const ExpertTableComponent = ({
                                  entities,
                                  selectionModel,
                                  entityApiState,
                                  t
                              }: Props) => {

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_SYNC_REQUEST} className="mr-auto"/>
            </AboveTableContainer>

            <Table itemCount={entities.length} className="sync-requests-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.sync-request.labels.id")}</TableColumn>
                    <TableColumn>{t("entity.sync-request.labels.strategy")}</TableColumn>
                    <TableColumn>{t("entity.sync-request.labels.parameters")}</TableColumn>
                    <TableColumn>{t("entity.sync-request.labels.status")}</TableColumn>
                    <TableColumn>{t("entity.sync-request.labels.dont-cleanup")}</TableColumn>
                    <TableColumn>{t("entity.sync-request.labels.run-after")}</TableColumn>
                    <TableColumn>{t("entity.sync-request.labels.created")}</TableColumn>
                    <TableColumn>{t("entity.sync-request.labels.last-try")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={syncRequest =>
                        <EntityTableRow
                            key={syncRequest.id}
                            entity={syncRequest} selectionModel={selectionModel}>
                            <TableColumn>
                                {syncRequest.id}
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {syncRequest.entity.strategy}
                            </TableColumn>
                            <TableColumn>
                                <OverlayTooltip tooltip={JSON.stringify(syncRequest.entity.parameters)}>
                                    <button type="button" className="btn-link" onClick={() => {
                                        navigator.clipboard.writeText(JSON.stringify(syncRequest.entity.parameters))
                                    }}>{JSON.stringify(syncRequest.entity.parameters).substr(0, 25)}..
                                    </button>
                                </OverlayTooltip>
                            </TableColumn>
                            <TableColumn>
                                {syncRequest.entity.status} {syncRequest.entity.statusMessage}
                            </TableColumn>
                            <TableColumn>
                                {syncRequest.entity.dontCleanUp ? "true" : "false"}
                            </TableColumn>
                            <TableColumn>
                                <DateTimeDisplay dateTime={syncRequest.entity.runAfter}/>
                            </TableColumn>
                            <TableColumn>
                                <DateTimeDisplay dateTime={syncRequest.entity.created}/>
                            </TableColumn>
                            <TableColumn>
                                <DateTimeDisplay dateTime={syncRequest.entity.lastTry}/>
                            </TableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const SyncRequestsTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation("syncRequests")(ExpertTableComponent),
        ENTITY_TYPE_SYNC_REQUEST
    )
);
