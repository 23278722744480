import React, {useCallback, useState} from 'react'
import {useRefetchableFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {MultiSelect} from "primereact/multiselect";
import {useTranslation} from "react-i18next";
import {DiscountsSelect_QueryFragment$key} from "../../../../__generated__/DiscountsSelect_QueryFragment.graphql";
import {
    DiscountsSelect_QueryFragmentRefetchQuery
} from "../../../../__generated__/DiscountsSelect_QueryFragmentRefetchQuery.graphql";
import {debounce} from "lodash";

const NUM_DISCOUNTS_PER_PAGE = 20

const QUERY_FRAGMENT = graphql`
    fragment DiscountsSelect_QueryFragment on Query @refetchable(queryName: "DiscountsSelect_QueryFragmentRefetchQuery") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String", defaultValue: null},
        code: {type: "String", defaultValue: null},
        ids: {type :"[ID!]!"}
    ) {
        Admin {
            Billing {
                DiscountsMultiselect(first: $first, after: $after, codeOpt: $code, ids: $ids) @connection(key: "DiscountsSelect_DiscountsMultiselect") {
                    edges {
                        cursor
                        node {
                            id
                            code
                        }
                    }
                }
            }
        }
    }`

export interface DiscountCode {
    id: string
    code: string
}

interface OwnProps {
    fieldValue: string[]
    onChange: (products: DiscountCode[]) => void
    queryFragmentRef: DiscountsSelect_QueryFragment$key
}

export const DiscountsSelect = ({
                                   fieldValue,
                                   onChange,
                                   queryFragmentRef
                               }: OwnProps) => {

    const {t} = useTranslation("core")

    const [data, refetch] = useRefetchableFragment<DiscountsSelect_QueryFragmentRefetchQuery, DiscountsSelect_QueryFragment$key>(QUERY_FRAGMENT, queryFragmentRef)

    const discounts = data?.Admin?.Billing.DiscountsMultiselect.edges?.filter(e => !!e).map(e => e!.node) || []

    const [discountsCache, setDiscountsCache] = useState<DiscountCode[]>(discounts.filter(discount => fieldValue.includes(discount.id)))

    const onFilter = (e: { filter: string }) => delayedRefetch(e.filter)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedRefetch = useCallback(debounce((filterTerm: string) => refetch({code: filterTerm, first: NUM_DISCOUNTS_PER_PAGE, ids: fieldValue}), 500), []);

    return <MultiSelect value={discountsCache}
                        options={discounts}
                        optionLabel="code"
                        onHide={() => onChange(discountsCache?.map((l: DiscountCode) => l) || [])}
                        onChange={(e) => setDiscountsCache(e.value)}
                        filter
                        resetFilterOnHide={true}
                        filterPlaceholder={t("entity-selects.filter-placeholder")}
                        className="multiselect-custom"
                        onFilter={onFilter}
                        emptyFilterMessage={t("entity-selects.no-results")}
    />
}
