import { FieldProps } from "formik";
import React from "react";
import Select from "react-select";
import { ClearIndicator } from "../../../../core/components/select/ClearIndicator";

interface OwnProps<T> {
    options: T[];
    listRenderer: (item: T) => string;
    isMulti?: boolean;
    placeholder: string;
    isClearable?: boolean
}

type Props<T> = FieldProps & OwnProps<T>;

export interface OptionType<T> {
    value: T;
    label: string;
}

export function SimpleOptionSelectField<T>({
                                               field,
                                               form,
                                               placeholder,
                                               options,
                                               isMulti,
                                               listRenderer,
                                               isClearable
                                           }: Props<T>) {

    const selected = field.value;

    let currentValue: OptionType<T> | OptionType<T>[] | undefined;
    if (selected) {
        if (isMulti && Array.isArray(selected)) {
            currentValue = selected.map(value => ({
                value,
                label: listRenderer(value)
            }))
        } else {
            currentValue = {
                value: selected,
                label: listRenderer(selected),
            }
        }
    } else {
        currentValue = isMulti ? [] : undefined;
    }

    const optionsWrapped = options.map(option => ({
        value: option,
        label: listRenderer(option)
    }));

    const findSelectedValue = (value: any) => {
        if (!value) {
            return undefined;
        }
        return optionsWrapped.find((o: OptionType<T>) => o.value === value)
    };

    return (<Select
        className="react-select category-select"
        classNamePrefix="react-select"
        components={{ClearIndicator}}
        placeholder={placeholder}
        options={optionsWrapped}
        isClearable={isClearable}
        name={field.name}
        isMulti={isMulti}
        value={currentValue}
        onChange={(option: any) => {
            if (isMulti && Array.isArray(option)) {
                const values = option.map(o => {
                    const value = o.value ? o.value : o;
                    return findSelectedValue(value);
                });
                form.setFieldValue(field.name, values ? values.map(v => v!.value) : [])
            } else {
                const value = option && option.value ? option.value : option;
                const selectedValue = findSelectedValue(value);
                form.setFieldValue(field.name, selectedValue?.value)
            }
        }}
        getOptionValue={(opt: any) => opt.value}
        getOptionLabel={item => item.label}
        onBlur={field.onBlur}
    />);
}
