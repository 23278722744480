/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteMultipleCustomerMessageDefinitionInput = {
    ids: Array<string>;
    clientMutationId?: string | null;
};
export type CustomerMessageDefinitionOverview_DeleteMutationVariables = {
    input: DeleteMultipleCustomerMessageDefinitionInput;
    connections: Array<string>;
};
export type CustomerMessageDefinitionOverview_DeleteMutationResponse = {
    readonly Admin: {
        readonly CustomerMessage: {
            readonly deleteMultipleCustomerMessageDefinition: {
                readonly deletedIds: ReadonlyArray<string>;
            } | null;
        };
    };
};
export type CustomerMessageDefinitionOverview_DeleteMutation = {
    readonly response: CustomerMessageDefinitionOverview_DeleteMutationResponse;
    readonly variables: CustomerMessageDefinitionOverview_DeleteMutationVariables;
};



/*
mutation CustomerMessageDefinitionOverview_DeleteMutation(
  $input: DeleteMultipleCustomerMessageDefinitionInput!
) {
  Admin {
    CustomerMessage {
      deleteMultipleCustomerMessageDefinition(input: $input) {
        deletedIds
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageDefinitionOverview_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageAdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteMultipleCustomerMessageDefinitionPayload",
                "kind": "LinkedField",
                "name": "deleteMultipleCustomerMessageDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CustomerMessageDefinitionOverview_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageAdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteMultipleCustomerMessageDefinitionPayload",
                "kind": "LinkedField",
                "name": "deleteMultipleCustomerMessageDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedIds",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9da2d00b8e94cf2c77b1caef12b97ccd",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageDefinitionOverview_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerMessageDefinitionOverview_DeleteMutation(\n  $input: DeleteMultipleCustomerMessageDefinitionInput!\n) {\n  Admin {\n    CustomerMessage {\n      deleteMultipleCustomerMessageDefinition(input: $input) {\n        deletedIds\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd6f99a55be9292b9acb573cdf916b2f6';
export default node;
