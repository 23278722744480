import { ApiCallAction } from "@thekeytechnology/framework-react";
import { ApplicationRule } from "../../../licenses/model/ApplicationRule";

export const API_UPDATE_LICENSE_POOL_ADMIN = "update-license-pool";

export interface UpdateLicensePool {
    licensePoolId: string
    accountRef?: string
    slaveAccountsRef?: string[]
    applicationRules?: ApplicationRule[]
    masterDomains?: string[]
}

export const updateLicensePoolAction = (licensePoolId: string, accountRef?: string, slaveAccountsRef?: string[], applicationRules?: ApplicationRule[], masterDomains?: string[]) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_LICENSE_POOL_ADMIN,
            callType: API_UPDATE_LICENSE_POOL_ADMIN
        },
        payload: {
            licensePoolId,
            accountRef,
            slaveAccountsRef,
            applicationRules,
            masterDomains
        }
    } as ApiCallAction<UpdateLicensePool>;
};
