import { UserWithRoles } from "@thekeytechnology/framework-react";
import { EmailOverview } from "./EmailOverview";
import { EditEmail } from "./email/EditEmail";
import { EditTriggeredEmail } from "./triggered-email/EditTriggeredEmail";
import { PrivateRouteDefinition } from "../../core/utils/PrivateRouteDefinition";

export const EmailModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/emails",
        component: EmailOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/emails/:emailId/edit",
        component: EditEmail,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/triggered-emails/:emailId/edit",
        component: EditTriggeredEmail,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    }
];
