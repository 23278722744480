import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { TableColumn } from "./Table";

interface ActionTableColumnProps {
    className?: string;
    children: any;
    isDisabled?: boolean;
}

interface ActionTableColumnState {
    isOpen: boolean;
}

export class ActionTableColumn extends Component<ActionTableColumnProps, ActionTableColumnState> {
    constructor(props: ActionTableColumnProps, state: ActionTableColumnState) {
        super(props, state);
        this.state = {
            isOpen: false
        };
        this.toggleOpen = this.toggleOpen.bind(this);
    }

    public toggleOpen() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    public render() {
        const {isDisabled} = this.props;
        const {isOpen} = this.state;
        return (
            <TableColumn className={"actions-column" + (this.props.className ? this.props.className : "")}>
                {isDisabled ? null :
                    <>
                        <button onClick={this.toggleOpen} type="button" className="mobile-toggle-button">
                            <FontAwesomeIcon icon={faEllipsisH}/>
                        </button>
                        <div className={"mobile-menu " + (isOpen ? "open" : "")}>
                            {this.props.children}
                        </div>
                    </>}
            </TableColumn>
        );
    }
}
