import { UserWithRoles } from "@thekeytechnology/framework-react";
import { EditQnaForm } from "./components/EditQna";
import { QnasOverviewOverview } from "./components/QnasOverviewOverview";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";

export const QnaModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/qnas",
        component: QnasOverviewOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    },
    {
        path: "/qnas/:qnaId/edit",
        component: EditQnaForm,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    }
];
