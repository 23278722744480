import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Versions } from "@thekeytechnology/thekey-academy-frontend-library";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

interface OwnProps {
    className?: string;
    versions: Versions;
}

export const VersionsBadge = ({
                                  className,
                                  versions
                              }: OwnProps) => {
    const {t} = useTranslation("courses");

    const unpublishedChanges = versions.draftVersion > versions.releaseVersion;
    return <div className={`badge ${unpublishedChanges ? "badge-warning" : "badge-success"} ${className}`}>
        {unpublishedChanges ? <FontAwesomeIcon icon={faExclamationTriangle}/> : null}
        <span className="ml-1">
            {unpublishedChanges ? t("versions-badge.warning") + " - " : ""}Entwurf {versions.draftVersion}, Release: {versions.releaseVersion}
            </span>
    </div>;
};

