import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef, useState} from "react";
import {Card, Modal} from "react-bootstrap";
import {useDrop} from "react-dnd";
import {DragItem, DroppableProps} from "./WithCourseEditorDraggable";

interface OwnProps extends DroppableProps {
    acceptableType: string;
    title: string;
    editForm: (onClose: () => void) => any;
}

type Props = OwnProps;

export const AddButton = (props: Props) => {
    const {acceptableType, dropped, title, editForm} = props;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const ref = useRef<HTMLDivElement>(null);

    const [{isOver, canDrop}, drop] = useDrop({
        accept: acceptableType,
        drop(item: DragItem) {
            dropped(item.index, 0, item.dragMeta);
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const dropMightHappen = canDrop && isOver;
    drop(ref);
    return (
        <div ref={ref}>
            <Card>
                <Card.Header
                    className={"d-flex flex-row justify-content-center align-items-center" + (dropMightHappen ? " drop-might-happen" : "")}>
                    <button type="button"  className="btn-link text-success" onClick={handleShow}>
                        <FontAwesomeIcon className="mr-2" icon={faPlus}/>{title}
                    </button>
                </Card.Header>
            </Card>

            <Modal size="lg" show={show} onHide={handleClose}>
                {editForm(handleClose)}
            </Modal>
        </div>
    );
};
