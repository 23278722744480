import update from "immutability-helper";
import { EditedCourseState } from "../../edited-course";
import { MoveModuleAction } from "../../../actions/module/move-module";

export const moveModule = (state: EditedCourseState, moveModuleAction: MoveModuleAction) => {
    const movedModule = state.editedCourse!.entity.modules[moveModuleAction.sourceIndex];
    return update(state, {
        hasUnsavedChanges: {$set: true},
        editedCourse: {
            entity: {
                modules: {
                    $splice: [
                        [moveModuleAction.sourceIndex, 1],
                        [moveModuleAction.targetIndex, 0, movedModule],
                    ],
                }
            }
        }
    });
};
