/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiscountCodeUsageTable_DiscountCodeUsageFragment = {
    readonly " $fragmentRefs": FragmentRefs<"UsageUsedAtColumn_DiscountCodeUsageFragment" | "UsageOrderColumn_DiscountCodeUsageFragment" | "UsageIdColumn_DiscountCodeUsageFragment" | "UsageInvoiceColumn_DiscountCodeUsageFragment">;
    readonly " $refType": "DiscountCodeUsageTable_DiscountCodeUsageFragment";
};
export type DiscountCodeUsageTable_DiscountCodeUsageFragment$data = DiscountCodeUsageTable_DiscountCodeUsageFragment;
export type DiscountCodeUsageTable_DiscountCodeUsageFragment$key = {
    readonly " $data"?: DiscountCodeUsageTable_DiscountCodeUsageFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"DiscountCodeUsageTable_DiscountCodeUsageFragment">;
};



const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "DiscountCodeUsageTable_DiscountCodeUsageFragment"
};
(node as any).hash = '659346918b601bcdafbbd80a328e40df';
export default node;
