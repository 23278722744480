import { FieldProps } from "formik";
import React from "react";
import Select from "react-select";
import { FormProps } from "react-bootstrap";
import {
    ConditionDefinition,
    ParameterDefinition,
    TriggerDefinition
} from "../../core/model/trigger/definitions/trigger-definition";
import { Trigger } from "../../core/model/trigger/trigger";
import { Parameter } from "../../core/model/trigger/parameter";
import "./trigger-field.scss"
import { ParameterInput } from "./ParameterInput";
import { ConditionsInput } from "./ConditionsInput";
import { useTranslation } from "react-i18next";
import { Condition } from "../../core/model/trigger/condition";
import { BooleanSelect } from "../../core/components/form/BooleanSelect";

interface OwnProps {
    definitions: TriggerDefinition[],
}

type Props = FieldProps & FormProps & OwnProps;

export function TriggerField({field, form, definitions}: Props) {
    const {t} = useTranslation("triggers");

    const fieldValue = field.value as Trigger | undefined;

    const selectedDefinition = definitions.find(d => d.key === fieldValue?.key)

    const updateTriggerDefinition = (definition: TriggerDefinition) => {
        form.setFieldValue(field.name, {
            key: definition.key,
            parameters: [],
            conditions: [],
            repeatable: false,
            delayInHours: undefined
        } as Trigger)
    }

    const updateDelay = (delay?: number) => {
        if (fieldValue) {
            form.setFieldValue(field.name, {
                ...fieldValue,
                delayInHours: delay
            } as Trigger)
        } else if (selectedDefinition) {
            form.setFieldValue(field.name, {
                key: selectedDefinition.key,
                parameters: [],
                conditions: [],
                repeatable: false,
                delayInHours: delay
            } as Trigger)
        }
    }

    const updateRepeatable = (value: boolean) => {
        if (fieldValue) {
            form.setFieldValue(field.name, {
                ...fieldValue,
                repeatable: value
            } as Trigger)
        } else if (selectedDefinition) {
            form.setFieldValue(field.name, {
                key: selectedDefinition.key,
                parameters: [],
                conditions: [],
                repeatable: value,
            } as Trigger)
        }
    }

    const updateParameter = (definition: ParameterDefinition, value: any) => {
        const newParameter = {key: definition.key, dataType: definition.dataType, value,} as Parameter;

        if (fieldValue) {
            form.setFieldValue(field.name, {
                ...fieldValue,
                parameters: [...fieldValue.parameters.filter(p => p.key !== definition.key), newParameter]
            } as Trigger)
        } else if (selectedDefinition) {
            form.setFieldValue(field.name, {
                key: selectedDefinition.key,
                parameters: [newParameter],
                conditions: [],
                repeatable: false,
            } as Trigger)
        }
    }

    const updateCondition = (definition: ConditionDefinition, condition?: Condition) => {
        if (fieldValue) {
            if (!condition) {
                form.setFieldValue(field.name, {
                    ...fieldValue,
                    conditions: [...fieldValue.conditions.filter(c => c.key !== definition.key)]
                } as Trigger)
            } else if (fieldValue.conditions.find(c => c.key === definition.key) === undefined) {
                form.setFieldValue(field.name, {
                    ...fieldValue,
                    conditions: [...fieldValue.conditions, condition]
                } as Trigger)
            } else {
                form.setFieldValue(field.name, {
                    ...fieldValue,
                    conditions: fieldValue.conditions.map(oldCondition => {
                        if (oldCondition.key === definition.key) {
                            return {
                                key: oldCondition.key,
                                parameters: condition.parameters
                            } as Condition
                        } else {
                            return oldCondition
                        }
                    })
                } as Trigger)
            }
        }
    }

    return <div className="d-flex flex-column w-100 trigger-field">
        <Select
            className="react-select category-select mb-2"
            classNamePrefix="react-select"
            options={definitions}
            placeholder={t("trigger-field.trigger-type-placeholder")}
            name={field.name}
            value={selectedDefinition}
            onChange={(item: any) => updateTriggerDefinition(item)}
            getOptionValue={(opt: any) => opt}
            getOptionLabel={item => t("trigger-field.trigger-types." + item.key)}
        />

        {selectedDefinition ? <div className="w-100">
            <div className="form-group">
                <label>{t("trigger-field.delay")}</label>
                <input
                    className="form-control default-input"
                    type="number" min={0} step={1}
                    value={fieldValue?.delayInHours}
                    onChange={event => {
                        if (event.target.value) {
                            updateDelay(parseInt(event.target.value, 10))
                        } else {
                            updateDelay(undefined)
                        }
                    }}/>
            </div>

            {selectedDefinition.isRepeatable ? <div className="form-group">
                <label>{t("trigger-field.repeatable.label")}</label>
                <BooleanSelect
                    placeholder={t("trigger-field.repeatable.placeholder")}
                    trueLabel={t("trigger-field.repeatable.true")}
                    falseLabel={t("trigger-field.repeatable.false")}
                    value={fieldValue?.repeatable ? fieldValue.repeatable : false}
                    onChange={newValue => {
                        updateRepeatable(newValue)
                    }}
                />
            </div> : null}

            {selectedDefinition.parameters.map(parameterDefinition => {
                const parameterValue = fieldValue?.parameters.find(p => p.key === parameterDefinition.key)?.value;
                return <div className="form-group" key={parameterDefinition.key}>
                    <label>{t("trigger-field.trigger-parameters." + parameterDefinition.key)}</label>
                    <ParameterInput parameterDefinition={parameterDefinition} parameterValue={parameterValue}
                                    updateParameter={updateParameter}/>
                </div>
            })}

            {selectedDefinition.permissibleConditions.length ? <ConditionsInput
                conditions={fieldValue!.conditions}
                permissibleConditions={selectedDefinition.permissibleConditions}
                updateCondition={updateCondition}
            /> : null}
        </div> : null}
    </div>
}
