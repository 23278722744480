import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useTranslation } from "react-i18next";
import { VoucherTable } from "./VoucherTable";
import { ENTITY_TYPE_VOUCHER } from "@thekeytechnology/thekey-academy-frontend-library";
import {
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../../../../core/components/containers/Container";
import { SimpleHeader } from "../../../../../core/components/header/SimpleHeader";
import { Pagination } from "../../../../../core/components/pagination/Pagination";

export const VoucherOverview = () => {
    const {t} = useTranslation("billing");
    return (
        <>
            <BreadcrumbsItem to={"/vouchers"}>{t("entity.voucher.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.voucher.plural")}/>
            </HeadingContainer>
            <ContentContainer>
                <VoucherTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_VOUCHER}/>
            </PaginationContainer>
        </>
    );
};

