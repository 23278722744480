import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { ContentContainer, HeadingContainer, PaginationContainer } from "../../core/components/containers/Container";
import { NotificationConfiguration } from "../model/notification-configuration";
import { NotificationTable } from "./notification/NotificationTable";
import { TriggeredNotificationsTable } from "./triggered-notification/TriggeredNotificationsTable";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const NotificationOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/notifications"}>{t("Notifications")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("Notifications")}/>
            </HeadingContainer>
            <ContentContainer>
                <h2>System-Benachrichtigungen</h2>
                <NotificationTable/>
                <h2>Dynamische Benachrichtigungen</h2>
                <TriggeredNotificationsTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={NotificationConfiguration.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const NotificationOverview = withTranslation("notifications")(NotificationOverviewComponent);
