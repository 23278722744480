/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UsageUsedAtColumn_DiscountCodeUsageFragment = {
    readonly usedAt: string;
    readonly " $refType": "UsageUsedAtColumn_DiscountCodeUsageFragment";
};
export type UsageUsedAtColumn_DiscountCodeUsageFragment$data = UsageUsedAtColumn_DiscountCodeUsageFragment;
export type UsageUsedAtColumn_DiscountCodeUsageFragment$key = {
    readonly " $data"?: UsageUsedAtColumn_DiscountCodeUsageFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"UsageUsedAtColumn_DiscountCodeUsageFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageUsedAtColumn_DiscountCodeUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usedAt",
      "storageKey": null
    }
  ],
  "type": "DiscountCodeUsage",
  "abstractKey": null
};
(node as any).hash = 'd67b46a7186eba29bb35991999461544';
export default node;
