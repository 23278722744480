import graphql from "babel-plugin-relay/macro"
import {Button} from "primereact/button";
import {useFragment, useMutation} from "react-relay";
import React, {useRef, useState} from "react";
import {OverlayPanel} from "primereact/overlaypanel";
import {useTranslation} from "react-i18next";
import {Toast} from "primereact/toast";
import {
    CreateRandomCodesButton_CreateMutation
} from "../../../../../../__generated__/CreateRandomCodesButton_CreateMutation.graphql";
import {InputNumber} from "primereact/inputnumber";
import {
    CreateRandomCodesButton_DiscountActionFragment$key
} from "../../../../../../__generated__/CreateRandomCodesButton_DiscountActionFragment.graphql";
import {Dialog} from "primereact/dialog";
import {ProgressSpinner} from "primereact/progressspinner";

const DISCOUNT_ACTION_FRAGMENT = graphql`
    fragment CreateRandomCodesButton_DiscountActionFragment on DiscountAction {
        id
    }
`

const CREATE_RANDOM_DISCOUNT_CODES_MUTATION = graphql`
    mutation CreateRandomCodesButton_CreateMutation($input: CreateRandomDiscountCodesInputInput!, $connections: [ID!]!){
        Admin {
            Billing {
                createRandomDiscountCodes(input: $input){
                    discountAction {
                        discountCodes {
                            edges @prependEdge(connections: $connections) {
                                node {
                                    ...DiscountCodeTable_DiscountCodeFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    className?: string
    connectionId: string
    discountActionFragmentRef: CreateRandomCodesButton_DiscountActionFragment$key
}

export const CreateRandomCodesButton = ({className, connectionId, discountActionFragmentRef}: OwnProps) => {
    const createRandomCodesButtonNs = "billing.discount-code.button.create-code-multiple"
    const {t} = useTranslation("billingV3")

    const discountAction = useFragment<CreateRandomCodesButton_DiscountActionFragment$key>(DISCOUNT_ACTION_FRAGMENT, discountActionFragmentRef)
    const [createRandomCodes, isLoading] = useMutation<CreateRandomCodesButton_CreateMutation>(CREATE_RANDOM_DISCOUNT_CODES_MUTATION)

    const [amount, setAmount] = useState<number>(0)

    const overlayPanel = useRef<OverlayPanel>(null)
    const toast = useRef<Toast>(null)


    return <>
        <Dialog header="Rabattcodes werden erstellt" visible={isLoading} closable={false} onHide={() => {}}>
            <div className="flex justify-content-center align-items-center">
                <ProgressSpinner/>
            </div>
        </Dialog>
        <Toast ref={toast}/>
        <Button
            label={t(`${createRandomCodesButtonNs}.title`)}
            loading={isLoading}
            disabled={isLoading}
            className={className}
            onClick={(e) => overlayPanel.current?.toggle(e)}
        />
        <OverlayPanel ref={overlayPanel}>
                <span className="p-float-label mb-2 mt-3">
                    <InputNumber max={1000} min={1} id="nameOfCode" value={amount} onChange={(e) => setAmount(e.value)}/>
                    <label htmlFor="nameOfCode">{t(`${createRandomCodesButtonNs}.placeholder`)}</label>
                </span>
            <Button
                label={t(`${createRandomCodesButtonNs}.submit`)}
                loading={isLoading}
                disabled={isLoading}
                onClick={() => {
                    createRandomCodes({
                        variables: {
                            input: {
                                discountActionId: discountAction.id,
                                number: amount
                            },
                            connections: [connectionId]
                        },
                        onCompleted: () => {
                            toast.current?.show({
                                severity: "success",
                                life: 3000,
                                summary: t(`${createRandomCodesButtonNs}.toast.success.summary`),
                                detail: t(`${createRandomCodesButtonNs}.toast.success.detail`),
                            })
                        }
                    })
                }}/>
        </OverlayPanel>
    </>
}
