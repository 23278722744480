import {FieldProps} from "formik";
import React from "react";
import Select from "react-select";
import {FormProps} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ButtonCta} from "../../model/cta/button-cta";

interface OwnProps {
    definitions: ButtonCta[],
}

type Props = FieldProps & FormProps & OwnProps;

export function ButtonSelectField({field, form, meta, definitions}: Props) {
    const {t} = useTranslation("core");

    const fieldValue = field.value as ButtonCta;
    const selectedDefinition = definitions.find(d => d.dataType === fieldValue.dataType)

    const updateButtonType = (button: ButtonCta) => {
        form.setFieldValue(field.name, {
            dataType: button.dataType
        } as ButtonCta)
    }
    return <div className="d-flex flex-column w-100 trigger-field">
        <Select
            className="react-select category-select mb-2"
            classNamePrefix="react-select"
            options={definitions}
            placeholder={t("button-type.button-type-placeholder")}
            name={field.name}
            value={selectedDefinition}
            onChange={(item: any) => updateButtonType(item)}
            getOptionValue={(opt: any) => opt}
            getOptionLabel={item => t("button-type.button-types.button-" + item.dataType)}
        />
    </div>
}
