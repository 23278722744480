/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateCustomerMessageDefinitionPageInput = {
    data: CreateCustomerMessageDefinitionPageData;
    clientMutationId?: string | null;
};
export type CreateCustomerMessageDefinitionPageData = {
    internalTitle: string;
    imageIdOpt?: string | null;
    customerMessageDefinitionId: string;
};
export type CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutationVariables = {
    input: CreateCustomerMessageDefinitionPageInput;
    connections: Array<string>;
};
export type CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutationResponse = {
    readonly Admin: {
        readonly CustomerMessageV2: {
            readonly createCustomerMessageDefinitionPage: {
                readonly data: {
                    readonly node: {
                        readonly id: string;
                        readonly internalTitle: string;
                        readonly imageOpt: {
                            readonly id: string;
                        } | null;
                    };
                };
            } | null;
        };
    };
};
export type CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutation = {
    readonly response: CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutationResponse;
    readonly variables: CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutationVariables;
};



/*
mutation CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutation(
  $input: CreateCustomerMessageDefinitionPageInput!
) {
  Admin {
    CustomerMessageV2 {
      createCustomerMessageDefinitionPage(input: $input) {
        data {
          node {
            id
            internalTitle
            imageOpt {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomerMessageDefinitionPagesEdge",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerMessageDefinitionPage",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "imageOpt",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCustomerMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "createCustomerMessageDefinitionPage",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCustomerMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "createCustomerMessageDefinitionPage",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b26380b86f12fadb699c664b7f19f5f9",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutation(\n  $input: CreateCustomerMessageDefinitionPageInput!\n) {\n  Admin {\n    CustomerMessageV2 {\n      createCustomerMessageDefinitionPage(input: $input) {\n        data {\n          node {\n            id\n            internalTitle\n            imageOpt {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4f2a505224780d259b63298abd0ab659';
export default node;
