import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { Lesson } from "../../model/lesson/lesson";

export const REMOVE_LESSON_ACTION = "remove-lesson";

export interface RemoveLessonAction extends Action {
    lesson: EntityWrapper<Lesson>
    parentModuleId: string;
}

export const removeLessonAction = (parentModuleId: string, lesson: EntityWrapper<Lesson>) => ({
    type: REMOVE_LESSON_ACTION,
    parentModuleId,
    lesson
}) as RemoveLessonAction;
