import React from "react";

import "./_modules/i18n";
import "./assets/scss/index.scss";
import { App } from "./App";
import ReactDOM from "react-dom";
import "./style.css"
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"

ReactDOM.render(
        <App/>,
    document.getElementById("root")
);

