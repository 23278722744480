/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteProductInput = {
    id: string;
    clientMutationId?: string | null;
};
export type ProductsTable_DeleteDiscountCodeMutationVariables = {
    input: DeleteProductInput;
    connections: Array<string>;
};
export type ProductsTable_DeleteDiscountCodeMutationResponse = {
    readonly Admin: {
        readonly Billing: {
            readonly deleteProduct: {
                readonly deletedId: string;
            } | null;
        };
    };
};
export type ProductsTable_DeleteDiscountCodeMutation = {
    readonly response: ProductsTable_DeleteDiscountCodeMutationResponse;
    readonly variables: ProductsTable_DeleteDiscountCodeMutationVariables;
};



/*
mutation ProductsTable_DeleteDiscountCodeMutation(
  $input: DeleteProductInput!
) {
  Admin {
    Billing {
      deleteProduct(input: $input) {
        deletedId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductsTable_DeleteDiscountCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteProductPayload",
                "kind": "LinkedField",
                "name": "deleteProduct",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProductsTable_DeleteDiscountCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteProductPayload",
                "kind": "LinkedField",
                "name": "deleteProduct",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedId",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00d0528c16cb3e9539f2caa868df4235",
    "id": null,
    "metadata": {},
    "name": "ProductsTable_DeleteDiscountCodeMutation",
    "operationKind": "mutation",
    "text": "mutation ProductsTable_DeleteDiscountCodeMutation(\n  $input: DeleteProductInput!\n) {\n  Admin {\n    Billing {\n      deleteProduct(input: $input) {\n        deletedId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a241f252a966907e112c14160d297a8b';
export default node;
