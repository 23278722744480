import { faEllipsisV, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { removeElementAction } from "../../../actions/element/remove-lesson";
import { LengthBadge } from "../common/LengthBadge";
import { withCourseEditorDraggable, WithCourseEditorDraggableProps } from "../common/WithCourseEditorDraggable";
import { DRAGGABLE_TYPES } from "../common/draggable-types";
import { CopyElementButton } from "./CopyElementButton";
import { EditElementButton } from "./EditElementButton";
import { PointsBadge } from "../common/PointsBadge";
import { CourseElement } from "@thekeytechnology/thekey-academy-frontend-library";
import { VersionsBadge } from "../common/VersionsBadge";

interface OwnProps {
    moduleIndex: number;
    lessonIndex: number;
    elementIndex: number;
    parentModuleId: string;
    parentLessonId: string;
    element: EntityWrapper<CourseElement>;
}

interface DispatchProps {
    removeElement: typeof removeElementAction;
}

type Props = OwnProps & DispatchProps & WithCourseEditorDraggableProps;

export const ElementItemComponent = ({
                                         element,
                                         dragHandleRef,
                                         removeElement,
                                         moduleIndex,
                                         parentModuleId,
                                         lessonIndex,
                                         parentLessonId,
                                         elementIndex,
                                         dropMightHappen
                                     }: Props) => {

    const {t} = useTranslation("courses");

    return <ListGroup.Item
        className={"d-flex flex-row align-items-center" + (dropMightHappen ? " drop-might-happen" : "")}>
        <div ref={dragHandleRef} className="drag-handle mr-2">
            <FontAwesomeIcon icon={faEllipsisV}/>
        </div>
        {t(`course-editor.element-types.${element.entity.elementType}`)} {moduleIndex + 1}.{lessonIndex + 1}.{elementIndex + 1} : {element.entity.generalSettings.internalTitle}

        <span className="small ml-2 mr-2">{element.id ? element.id : element.temporaryId}</span>
        <LengthBadge lengthInSeconds={element.entity.generalSettings.lengthInSeconds} className="mr-2"/>
        <PointsBadge points={element.entity.generalSettings.points} className="mr-2"/>
        <VersionsBadge versions={element.entity.versions} className="mr-2"/>

        <div className="edit-buttons ml-auto mr-2">
            <CopyElementButton
                parentModuleId={parentModuleId}
                parentLessonId={parentLessonId}
                element={element}
            />
            <button className="btn btn-danger" onClick={() => removeElement(
                parentModuleId,
                parentLessonId,
                element
            )}>
                <FontAwesomeIcon icon={faTrash}/>
            </button>
            <EditElementButton
                parentModuleId={parentModuleId}
                parentLessonId={parentLessonId}
                element={element}/>
        </div>
    </ListGroup.Item>;
};

export const ElementItem = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        removeElement: removeElementAction
    }
)(withCourseEditorDraggable(DRAGGABLE_TYPES.ELEMENT, ElementItemComponent));
