/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CustomerMessageSenderScreen_QueryVariables = {
    customerMessageDefinitionV2Id: string;
};
export type CustomerMessageSenderScreen_QueryResponse = {
    readonly node: {
        readonly id: string;
        readonly internalTitle?: string;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"AccountsSelect_QueryFragment" | "UsersSelect_QueryFragment" | "CoursesSelect_QueryFragment">;
};
export type CustomerMessageSenderScreen_Query = {
    readonly response: CustomerMessageSenderScreen_QueryResponse;
    readonly variables: CustomerMessageSenderScreen_QueryVariables;
};



/*
query CustomerMessageSenderScreen_Query(
  $customerMessageDefinitionV2Id: ID!
) {
  node(id: $customerMessageDefinitionV2Id) {
    __typename
    id
    ... on CustomerMessageDefinitionV2 {
      internalTitle
    }
  }
  ...AccountsSelect_QueryFragment_4vS6aX
  ...UsersSelect_QueryFragment_4vS6aX
  ...CoursesSelect_QueryFragment_4vS6aX
}

fragment AccountsSelect_QueryFragment_4vS6aX on Query {
  Admin {
    Auth {
      AccountsMultiselect(ids: []) {
        edges {
          cursor
          node {
            id
            name
            __typename
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}

fragment CoursesSelect_QueryFragment_4vS6aX on Query {
  Admin {
    Course {
      CoursesMultiselect(ids: []) {
        edges {
          cursor
          node {
            id
            containerMeta {
              title
            }
            __typename
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}

fragment UsersSelect_QueryFragment_4vS6aX on Query {
  Admin {
    Auth {
      UsersMultiselect(ids: []) {
        edges {
          cursor
          node {
            id
            email
            __typename
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customerMessageDefinitionV2Id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "customerMessageDefinitionV2Id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    }
  ],
  "type": "CustomerMessageDefinitionV2",
  "abstractKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": ([]/*: any*/)
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageSenderScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "AccountsSelect_QueryFragment"
      },
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "UsersSelect_QueryFragment"
      },
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "CoursesSelect_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerMessageSenderScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "AccountsConnection",
                "kind": "LinkedField",
                "name": "AccountsMultiselect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": "AccountsMultiselect(ids:[])"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "nameOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "AccountSelect_AccountsMultiselect",
                "kind": "LinkedHandle",
                "name": "AccountsMultiselect"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "UsersConnection",
                "kind": "LinkedField",
                "name": "UsersMultiselect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UsersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": "UsersMultiselect(ids:[])"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "emailOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "UserSelect_UsersMultiselect",
                "kind": "LinkedHandle",
                "name": "UsersMultiselect"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseAdminSchema",
            "kind": "LinkedField",
            "name": "Course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "CoursesConnection",
                "kind": "LinkedField",
                "name": "CoursesMultiselect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoursesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Course",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContainerMeta",
                            "kind": "LinkedField",
                            "name": "containerMeta",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": "CoursesMultiselect(ids:[])"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "titleOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "CourseSelect_CoursesMultiselect",
                "kind": "LinkedHandle",
                "name": "CoursesMultiselect"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da7f47922e434a1d88033b24411f137f",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageSenderScreen_Query",
    "operationKind": "query",
    "text": "query CustomerMessageSenderScreen_Query(\n  $customerMessageDefinitionV2Id: ID!\n) {\n  node(id: $customerMessageDefinitionV2Id) {\n    __typename\n    id\n    ... on CustomerMessageDefinitionV2 {\n      internalTitle\n    }\n  }\n  ...AccountsSelect_QueryFragment_4vS6aX\n  ...UsersSelect_QueryFragment_4vS6aX\n  ...CoursesSelect_QueryFragment_4vS6aX\n}\n\nfragment AccountsSelect_QueryFragment_4vS6aX on Query {\n  Admin {\n    Auth {\n      AccountsMultiselect(ids: []) {\n        edges {\n          cursor\n          node {\n            id\n            name\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment CoursesSelect_QueryFragment_4vS6aX on Query {\n  Admin {\n    Course {\n      CoursesMultiselect(ids: []) {\n        edges {\n          cursor\n          node {\n            id\n            containerMeta {\n              title\n            }\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment UsersSelect_QueryFragment_4vS6aX on Query {\n  Admin {\n    Auth {\n      UsersMultiselect(ids: []) {\n        edges {\n          cursor\n          node {\n            id\n            email\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '38498c4606ebb646ca2a999a58f03382';
export default node;
