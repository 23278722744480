import React, {Suspense} from "react";
import {useTranslation} from "react-i18next";
import {OrderList} from "primereact/orderlist";
import {Button} from "primereact/button";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutation} from "../../../../../__generated__/CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutation.graphql";
import {CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutation} from "../../../../../__generated__/CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutation.graphql";
import {CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutation} from "../../../../../__generated__/CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutation.graphql";
import {CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment$key} from "../../../../../__generated__/CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment.graphql";
import {ProgressSpinner} from "primereact/progressspinner";
import {confirmDialog} from "primereact/confirmdialog";

const CUSTOMER_MESSAGE_DEFINITION_V2_FRAGMENT = graphql`
    fragment CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {
        id
        pages(after: null, first: null) @connection(key: "CustomerMessageDefinitionPagesOrderList_pages") {
            __id
            edges {
                node {
                    id
                    ... on CustomerMessageDefinitionPage {
                        internalTitle
                        ...EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment
                    }
                }
            }
        }
    }`

const CREATE_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION = graphql`
    mutation CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutation($input: CreateCustomerMessageDefinitionPageInput!, $connections: [ID!]!) {
        Admin {
            CustomerMessageV2 {
                createCustomerMessageDefinitionPage(input: $input) {
                    data @appendEdge(connections: $connections){
                        node {
                            id
                            ... on CustomerMessageDefinitionPage {
                                internalTitle
                                imageOpt {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    }`

const DELETE_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION = graphql`
    mutation CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutation($input: DeleteCustomerMessageDefinitionPageInput!, $connections: [ID!]!) {
        Admin {
            CustomerMessageV2 {
                deleteCustomerMessageDefinitionPage(input: $input) {
                    deletedId @deleteEdge(connections: $connections)
                }
            }
        }
    }`

const REARRANGE_CUSTOMER_MESSAGE_DEFINITION_PAGES_MUTATION = graphql`
    mutation CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutation($input: RearrangeCustomerMessageDefinitionPagesDataInput!) {
        Admin {
            CustomerMessageV2 {
                rearrangeCustomerMessageDefinitionPages(input: $input) {
                    customerMessageDefinitionV2 {
                        ...CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment
                    }
                }
            }
        }
    }`

interface OwnProps {
    editPage: (item: any) => void
    customerMessageDefinitionV2FragmentRef: CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment$key
}

export const CustomerMessageDefinitionPagesOrderList = ({
                                                            editPage,
                                                            customerMessageDefinitionV2FragmentRef
                                                        }: OwnProps) => {
    const {t: tCMV2} = useTranslation("customerMessagesV2")

    const customerMessageDefinition = useFragment<CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment$key>(CUSTOMER_MESSAGE_DEFINITION_V2_FRAGMENT, customerMessageDefinitionV2FragmentRef)

    const [createPageRaw, isCreatingPage] = useMutation<CustomerMessageDefinitionPagesOrderList_CreateCustomerMessageDefinitionPageMutation>(CREATE_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION)

    const createPage = () => createPageRaw(
        {
            variables: {
                input: {
                    data: {
                        customerMessageDefinitionId: customerMessageDefinition.id,
                        internalTitle: tCMV2("edit-customer-message-definition-v2.pages.create-page.initial-internal-title")
                    }
                },
                connections: [customerMessageDefinition.pages.__id]
            }
        }
    )

    const [deletePageRaw, isDeletingPage] = useMutation<CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutation>(DELETE_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION)

    const deletePageWithConfirmation = (internalTitle: string, pageId: string) => confirmDialog({
        message: tCMV2("edit-customer-message-definition-v2.pages.delete-page-dialog.message"),
        header: tCMV2("edit-customer-message-definition-v2.pages.delete-page-dialog.header", {internalTitle: internalTitle}),
        icon: "pi pi-exclamation-triangle",
        acceptLabel: tCMV2("edit-customer-message-definition-v2.pages.delete-page-dialog.accept-label"),
        rejectLabel: tCMV2("edit-customer-message-definition-v2.pages.delete-page-dialog.reject-label"),
        acceptClassName: "p-button-danger",
        accept: () => deletePageRaw(
            {
                variables: {
                    input: {
                        id: pageId
                    },
                    connections: [customerMessageDefinition.pages.__id]
                }
            })
    })

    const [rearrangePagesRaw, isRearrangingPages] = useMutation<CustomerMessageDefinitionPagesOrderList_RearrangeCustomerMessageDefinitionPagesMutation>(REARRANGE_CUSTOMER_MESSAGE_DEFINITION_PAGES_MUTATION)

    const rearrangePages = (rearrangedPageIds: string[]) => rearrangePagesRaw(
        {
            variables: {
                input: {
                    rearrangedPageIds: rearrangedPageIds
                }
            }
        }
    )

    const pages = customerMessageDefinition.pages.edges?.filter(e => !!e).map(e => e!.node) || []

    return <Suspense fallback={<ProgressSpinner/>}>
        {!(isCreatingPage || isDeletingPage || isRearrangingPages) ? <>
            <Button
                onClick={createPage}
                label={tCMV2("edit-customer-message-definition-v2.pages.create-page.button")}
                className="mb-3"
            />
            <OrderList
                dragdrop={false}
                onChange={e => rearrangePages(e.value.map((item: { id: string }) => item.id))}
                itemTemplate={item => {
                    return <div className="flex align-items-center">
                        {item.internalTitle}
                        <Button
                            disabled={pages.length === 1}
                            className="ml-auto"
                            icon="pi pi-trash"
                            onClick={() => deletePageWithConfirmation(item.internalTitle, item.id)}
                        />
                        <Button
                            disabled={false}
                            className="ml-2"
                            icon="pi pi-pencil"
                            onClick={() => editPage(item)}
                        />
                    </div>
                }}
                value={pages}
            />
        </> : <ProgressSpinner/>}
    </Suspense>
}