import { EntityWrapper, } from "@thekeytechnology/framework-react";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { extractId } from "../../../../reducers/changes/common/helpers";
import { EditElementModalBase } from "../common/EditElementModalBase";
import { TITLE_VALIDATION_SCHEME } from "../common/GeneralElementSettingsEditor";
import { ClassesField } from "./ClassesField";
import { QuestionnaireQuestionsField } from "./QuestionnaireQuestionsField";
import {
    ELEMENT_TYPE_QUESTIONNAIRE,
    QuestionnaireClass,
    QuestionnaireElement
} from "@thekeytechnology/thekey-academy-frontend-library";
import { ValidatedField } from "../../../../../core/components/form/ValidatedField";

interface OwnProps {
    element: EntityWrapper<QuestionnaireElement>;
    handleSubmit: (element: EntityWrapper<QuestionnaireElement>) => void;
    handleClose: () => void;
}

type Props = OwnProps;

export const EditQuestionnaireElementForm = ({element, handleSubmit, handleClose}: Props) => {
    const {t} = useTranslation(["courses", "core"])
    return (
        <Formik
            initialValues={{
                internalTitle: element.entity.generalSettings.internalTitle,
                points: element.entity.generalSettings.points,
                lengthInSeconds: element.entity.generalSettings.lengthInSeconds,
                connections: element.entity.generalSettings.connections,
                classes: element.entity.classes,
                questions: element.entity.questions
            }}
            validationSchema={Yup.object().shape({
                internalTitle: TITLE_VALIDATION_SCHEME(t),
                classes: Yup.array().min(1, t("course-editor.edit-element-form.questionnaire.fields.classes-error"))
                    .test("", "", function(value) {
                        const classes = value as QuestionnaireClass[]

                        const allValid = classes.map(x => {
                            return Yup.object().shape({
                                title: Yup.string().min(1).required(),
                                description: Yup.string().min(1).required(),
                                pointsThreshold: Yup.number().min(0).required()
                            }).isValidSync(x)
                        }).reduce((x, y) => x && y)

                        const classWithZeroThreshold = classes.find(c => c.pointsThreshold === 0)

                        if (!allValid || !classWithZeroThreshold) {
                            return this.createError({
                                path: "classes",
                                message: t("course-editor.edit-element-form.questionnaire.fields.classes-error")
                            });
                        }
                        return true;
                    })
                ,
                questions: Yup.array().min(1, t("course-editor.edit-element-form.questionnaire.fields.questions-error")).of(
                    Yup.object().shape({
                        title: Yup.string().required()
                    })
                ).typeError(t("course-editor.edit-element-form.questionnaire.fields.questions-error"))
            })}
            onSubmit={(values, {setSubmitting}) => {
                handleSubmit(new EntityWrapper<QuestionnaireElement>(
                    element.id,
                    {
                        generalSettings: {
                            internalTitle: values.internalTitle,
                            points: values.points,
                            lengthInSeconds: values.lengthInSeconds,
                            connections: values.connections
                        },
                        elementType: ELEMENT_TYPE_QUESTIONNAIRE,
                        elementPath: element.entity.elementPath,
                        classes: values.classes,
                        questions: values.questions,
                        versions: element.entity.versions
                    },
                    element.temporaryId,
                ));
                setSubmitting(false);
            }}
        >
            {formikState => (
                <EditElementModalBase title={t("course-editor.edit-element-form.questionnaire.heading")}
                                      currentElementId={extractId(element)}
                                      formikState={formikState}
                                      handleClose={handleClose}>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.questionnaire.fields.classes")}
                            name="classes"
                            formikState={formikState}
                            component={ClassesField}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.questionnaire.fields.questions")}
                            name="questions"
                            formikState={formikState}
                            component={QuestionnaireQuestionsField}
                        />
                    </div>
                </EditElementModalBase>
            )}
        </Formik>
    );
};
