export class ExpertForSaving {
    public static TYPE = "expert";

    public constructor(
        public name: string,
        public language: string,
        public image: string | undefined,
        public position: string,
        public biography: string,
    ) {
    }
}
