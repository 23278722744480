import {faQuestion} from "@fortawesome/free-solid-svg-icons";
import {EntityWrapper, TkFile,} from "@thekeytechnology/framework-react";
import {Formik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {extractId} from "../../../../reducers/changes/common/helpers";
import {EditElementModalBase} from "../common/EditElementModalBase";
import {
    CONNECTION_VALIDATION_SCHEME_WITH_MANDATORY_SOURCE,
    TITLE_VALIDATION_SCHEME
} from "../common/GeneralElementSettingsEditor";
import {AnswersField} from "./AnswersField";
import {PhrasingAlternativesField} from "../common/PhrasingAlternativesField";
import {connect} from "react-redux";
import {selectImage} from "../../../../selectors";
import {InlineAttachmentField} from "../../../../../core/components/attachment/InlineAttachmentField";
import {
    AnswerOption,
    ELEMENT_TYPE_MULTIPLE_CHOICE,
    MultipleChoiceElement
} from "@thekeytechnology/thekey-academy-frontend-library";
import { ValidatedField } from "../../../../../core/components/form/ValidatedField";
import { BooleanSelectField } from "../../../../../core/components/select/BooleanSelectField";

interface OwnProps {
    element: EntityWrapper<MultipleChoiceElement>;
    handleSubmit: (element: EntityWrapper<MultipleChoiceElement>) => void;
    handleClose: () => void;
}

interface StateProps {
    image: EntityWrapper<TkFile> | undefined
}

type Props = OwnProps & StateProps;

const EditMultipleChoiceElementFormComponent = ({element, handleSubmit, handleClose, image}: Props) => {
    const {t} = useTranslation(["courses", "core"])
    return (
        <Formik
            initialValues={{
                internalTitle: element.entity.generalSettings.internalTitle,
                points: element.entity.generalSettings.points,
                lengthInSeconds: element.entity.generalSettings.lengthInSeconds,
                connections: element.entity.generalSettings.connections,
                allowMultipleAnswers: element.entity.allowMultipleAnswers,
                allCorrectAnswersNeedToBeGiven: element.entity.allCorrectAnswersNeedToBeGiven,
                question: element.entity.question,
                answerOptions: element.entity.answerOptions,
                image
            }}
            validationSchema={Yup.object().shape({
                internalTitle: TITLE_VALIDATION_SCHEME(t),
                connections: CONNECTION_VALIDATION_SCHEME_WITH_MANDATORY_SOURCE(t),
                question: Yup.object().shape({
                    alternatives: Yup.array().min(1).required()
                }).required(),
                answerOptions: Yup.array()
                    .required(t("course-editor.edit-element-form.multiple-choice.fields.answers.error"))
                    .min(1, t("course-editor.edit-element-form.multiple-choice.fields.answers.error")).of(
                        Yup.object().shape({
                            // tslint:disable-next-line:only-arrow-functions
                            answer: Yup.object().test("test", "test", function (value) {
                                const innerSchema = Yup.object().shape({
                                    alternatives: Yup.array().min(1).required()
                                }).required();

                                try {
                                    innerSchema.validateSync(value);
                                } catch (e) {
                                    return this.createError({
                                        path: "answerOptions",
                                        message: t("course-editor.edit-element-form.multiple-choice.fields.answers.error")
                                    });
                                }
                                return true;
                            })
                        })
                    ).test("test2", "test2", function (value) {
                        if (!(value as AnswerOption[]).find(ao => ao.isCorrect)) {
                            return this.createError({
                                path: "answerOptions",
                                message: t("course-editor.edit-element-form.multiple-choice.fields.answers.error")
                            });
                        }
                        return true;
                    }).typeError(t("course-editor.edit-element-form.multiple-choice.fields.answers.error"))
            }).test("test3", "test3", function (values: any) {
                if (values && !values.allowMultipleAnswers && values.allCorrectAnswersNeedToBeGiven) {
                    const answers = values.answerOptions as AnswerOption[];
                    if (answers.filter(a => a.isCorrect).length > 1) {
                        return this.createError({
                            path: "answerOptions",
                            message: t("course-editor.edit-element-form.multiple-choice.answer-logic-error")
                        });
                    }
                }
                return true;
            })}
            onSubmit={(values, {setSubmitting}) => {
                handleSubmit(new EntityWrapper<MultipleChoiceElement>(
                    element.id,
                    {
                        generalSettings: {
                            internalTitle: values.internalTitle,
                            points: values.points,
                            lengthInSeconds: values.lengthInSeconds,
                            connections: values.connections
                        },
                        elementType: ELEMENT_TYPE_MULTIPLE_CHOICE,
                        elementPath: element.entity.elementPath,
                        imageRef: values.image?.id,
                        allowMultipleAnswers: values.allowMultipleAnswers,
                        allCorrectAnswersNeedToBeGiven: values.allCorrectAnswersNeedToBeGiven,
                        question: values.question ? values.question : element.entity.question,
                        answerOptions: values.answerOptions,
                        versions: element.entity.versions
                    },
                    element.temporaryId,
                ));
                setSubmitting(false);
            }}
        >
            {formikState => (
                <EditElementModalBase title={t("course-editor.edit-element-form.multiple-choice.heading")}
                                      currentElementId={extractId(element)}
                                      formikState={formikState}
                                      handleClose={handleClose}>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.common.fields.question")}
                            name="question"
                            formikState={formikState}
                            component={PhrasingAlternativesField}
                            innerIcon={faQuestion}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.common.fields.image")}
                            name="image"
                            formikState={formikState}
                            component={InlineAttachmentField}
                            isSingleSelect={true}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.multiple-choice.fields.allow-multiple-answers.label")}
                            name="allowMultipleAnswers"
                            formikState={formikState}
                            component={BooleanSelectField}
                            placeholder={t("course-editor.edit-element-form.multiple-choice.fields.allow-multiple-answers.placeholder")}
                            trueLabel={t("course-editor.edit-element-form.multiple-choice.fields.allow-multiple-answers.true")}
                            falseLabel={t("course-editor.edit-element-form.multiple-choice.fields.allow-multiple-answers.false")}

                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.multiple-choice.fields.all-correct-answers-need-to-be-given.label")}
                            name="allCorrectAnswersNeedToBeGiven"
                            formikState={formikState}
                            component={BooleanSelectField}
                            placeholder={t("course-editor.edit-element-form.multiple-choice.fields.allow-multiple-answers.placeholder")}
                            trueLabel={t("course-editor.edit-element-form.multiple-choice.fields.all-correct-answers-need-to-be-given.true")}
                            falseLabel={t("course-editor.edit-element-form.multiple-choice.fields.all-correct-answers-need-to-be-given.false")}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.multiple-choice.fields.answers.label")}
                            name="answerOptions"
                            formikState={formikState}
                            component={AnswersField}
                        />
                    </div>
                </EditElementModalBase>
            )}
        </Formik>
    );
};

export const EditMultipleChoiceElementForm = connect<StateProps, {}, OwnProps>(
    (state: any, {element}: OwnProps) => ({
        image: selectImage(element?.entity.imageRef)(state)
    })
)(EditMultipleChoiceElementFormComponent);
