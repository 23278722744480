import React, {useState} from 'react'
import DateTimePicker from 'react-datetime-picker'
import {
    DownloadBillingCsvWrapper,
    DownloadButton,
    DatetimePickerWrapper,
    LabelDisplay
} from './StyledElements'
import moment from 'moment'
import {
    selectApiBase,
    selectAuthState,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import {useSelector} from "react-redux";
import {csvDownloader} from '../../utils/utils'

interface State {
    billingCsvDto: {
        from: Date,
        to: Date
    }
    isDownloading: boolean
}

export const DownloadBillingCsv = () => {
    const apiBase = useSelector(selectApiBase);
    const authState = useSelector(selectAuthState)
    const axios = withAuthedAxios(apiBase, authState)

    const [state, setState] = useState<State>({
            billingCsvDto: {
                from: moment().subtract(1, 'month').toDate(),
                to: moment().toDate()
            },
            isDownloading: false
        }
    )

    const downloadCsv = () => {
        setState({...state, isDownloading: true})
        csvDownloader(
            axios,
            `/api/admin/v1/getBillingCsv`,
            `billing-${moment(state.billingCsvDto.from).format('DD.MM.YYYY')}-${moment(state.billingCsvDto.to).format('DD.MM.YYYY')}.csv`,
            state.billingCsvDto,
            () => setState({...state, isDownloading: false})
        )
    }

    return (
        <DownloadBillingCsvWrapper>
            <DatetimePickerWrapper>
                <LabelDisplay>Von:</LabelDisplay>
                <DateTimePicker
                    onChange={(element: any) => setState({
                        ...state,
                        billingCsvDto: {...state.billingCsvDto, from: moment(element).toDate()}
                    })}
                    value={state.billingCsvDto.from}
                />
            </DatetimePickerWrapper>
            <DatetimePickerWrapper>
                <LabelDisplay>Bis:</LabelDisplay>
                <DateTimePicker
                    onChange={(element: any) => setState({
                        ...state,
                        billingCsvDto: {...state.billingCsvDto, to: moment(element).toDate()}
                    })}
                    value={state.billingCsvDto.to}
                />
            </DatetimePickerWrapper>
            <DownloadButton
                isLoading={state.isDownloading}
                name={"Download"}
                margin={{top: '1rem'}}
                border={{width: '2'}}
                onClick={downloadCsv}/>
        </DownloadBillingCsvWrapper>
    )
}