import { EntityWrapper } from "@thekeytechnology/framework-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { extractId } from "../../../../../reducers/changes/common/helpers";
import React from "react";
import Select from "react-select";
import { CourseElement } from "@thekeytechnology/thekey-academy-frontend-library";
import { ClearIndicator } from "../../../../../../core/components/select/ClearIndicator";

interface OwnProps {
    selectedElementId?: string;
    availableElements: EntityWrapper<CourseElement>[]
    newElementSelected: (element: EntityWrapper<CourseElement>) => void;
}

type Props = OwnProps & WithTranslation;

const ElementInCourseSelectComponent = (props: Props) => {
    const {availableElements, selectedElementId, newElementSelected, t} = props;

    const referredElement = availableElements.find(el => extractId(el) === selectedElementId);

    return <Select
        className="react-select category-select"
        classNamePrefix="react-select"
        components={{ClearIndicator}}
        placeholder={t("course-editor.element-in-course-select.placeholder")}
        options={availableElements.sort(((a, b) => {
            if (a.entity.generalSettings.internalTitle > b.entity.generalSettings.internalTitle) {
                return 1
            } else if (a.entity.generalSettings.internalTitle < b.entity.generalSettings.internalTitle) {
                return -1
            } else {
                return 0
            }
        }))}
        value={referredElement}
        onChange={(item: any) => newElementSelected(availableElements.find(el => extractId(el) === extractId(item))!)}
        getOptionValue={(opt: any) => extractId(opt)}
        getOptionLabel={(val: any) => {
            return `${val.entity.generalSettings.internalTitle} (${t(`course-editor.element-types.${val.entity.elementType}`)})`;
        }}
    />;
};

export const ElementInCourseSelect = withTranslation("courses")(ElementInCourseSelectComponent);
