/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import CoursesSelect_QueryFragmentRefetchQuery from "./CoursesSelect_QueryFragmentRefetchQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type CoursesSelect_QueryFragment = {
    readonly Admin: {
        readonly Course: {
            readonly CoursesMultiselect: {
                readonly edges: ReadonlyArray<{
                    readonly cursor: string;
                    readonly node: {
                        readonly id: string;
                        readonly containerMeta: {
                            readonly title: string;
                        };
                    };
                } | null> | null;
            };
        };
    };
    readonly " $refType": "CoursesSelect_QueryFragment";
};
export type CoursesSelect_QueryFragment$data = CoursesSelect_QueryFragment;
export type CoursesSelect_QueryFragment$key = {
    readonly " $data"?: CoursesSelect_QueryFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CoursesSelect_QueryFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "Course",
  "CoursesMultiselect"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "ids"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "title"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": CoursesSelect_QueryFragmentRefetchQuery
    }
  },
  "name": "CoursesSelect_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CourseAdminSchema",
          "kind": "LinkedField",
          "name": "Course",
          "plural": false,
          "selections": [
            {
              "alias": "CoursesMultiselect",
              "args": [
                {
                  "kind": "Variable",
                  "name": "ids",
                  "variableName": "ids"
                },
                {
                  "kind": "Variable",
                  "name": "titleOpt",
                  "variableName": "title"
                }
              ],
              "concreteType": "CoursesConnection",
              "kind": "LinkedField",
              "name": "__CourseSelect_CoursesMultiselect_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CoursesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Course",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ContainerMeta",
                          "kind": "LinkedField",
                          "name": "containerMeta",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '1aa42f0cadd173850ed24fdc13555b4a';
export default node;
