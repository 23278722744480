import { apiPost, fetchSingleEntityAction, matchesAnyOfTheseApiSuccesses } from "@thekeytechnology/framework-react";
import { combineEpics } from "redux-observable";
import { API_PUBLISH_COURSE } from "../actions/publish-course";
import { Observable } from "rxjs";
import { Action } from "redux";
import { delay, map } from "rxjs/operators";
import { COURSE_UPLOAD_ENTITY_ID, ENTITY_TYPE_COURSE_UPLOAD_STATUS } from "../model/CourseUploadStatus";

const publishCourse$ = apiPost({apiType: API_PUBLISH_COURSE},
    "/api/admin/v1/courses/publish",
);

const reloadCourseUploadLogOnPublishing$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses(
        {apiType: API_PUBLISH_COURSE},
    ),
    delay(2000),
    map(() => {
        return fetchSingleEntityAction(ENTITY_TYPE_COURSE_UPLOAD_STATUS)(COURSE_UPLOAD_ENTITY_ID);
    })
);

export const publishCourseEpics$ = combineEpics(
    publishCourse$,
    reloadCourseUploadLogOnPublishing$
);
