import React from "react";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {DeleteDiscountActionButton} from "../../buttons/DeleteDiscountActionButton";
import {EditDiscountActionButton} from "../../buttons/EditDiscountActionButton";
import {
    ActionActionColumn_DiscountActionFragment$key
} from "../../../../../../../__generated__/ActionActionColumn_DiscountActionFragment.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
    fragment ActionActionColumn_DiscountActionFragment on DiscountAction {
        id
    }
`

interface OwnProps {
    connectionId: string
    discountActionFragmentRef: ActionActionColumn_DiscountActionFragment$key
}

export const ActionActionColumn = ({discountActionFragmentRef, connectionId}: OwnProps) => {
    const discountAction = useFragment<ActionActionColumn_DiscountActionFragment$key>(DISCOUNT_ACTION_FRAGMENT, discountActionFragmentRef)

    return <div>
        <EditDiscountActionButton id={discountAction.id}/>
        <DeleteDiscountActionButton id={discountAction.id} className="ml-2" connectionId={connectionId}/>
    </div>
}
