import {
    ApiCallSucceededAction,
    isApiSuccess
} from "@thekeytechnology/framework-react";
import { API_FETCH_VIDEO_DATA } from "../actions/element/fetch-video-data";
import { VideoData } from "@thekeytechnology/thekey-academy-frontend-library";
import {Action} from "redux";

export interface VideoDataState {
    videoData?: VideoData,
}

export const initialState: VideoDataState = {
    videoData: undefined,
};

export function videoDataReducer(state = initialState, action : Action): VideoDataState {
    if (isApiSuccess(API_FETCH_VIDEO_DATA)(action)) {
        const fetchVideoDataAction = action as ApiCallSucceededAction<VideoData>;

        return {
            ...state,
            videoData: fetchVideoDataAction.payload
        };
    }
    return state;
}
