import { Form, Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { SimpleOptionSelectField } from "../../../../../../course/components/course-editor/course/SimpleOptionSelectField";
import { Account, EntityWrapper } from "@thekeytechnology/framework-react";
import { faPercent } from "@fortawesome/free-solid-svg-icons";
import { OfferItemsField } from "./OfferItemsField";
import { UpsellOptionsField } from "./UpsellOptionsField";
import { OfferTotals } from "../OfferTotals";
import {
    CourseOfferItem,
    DiscountOfferItem, ENTITY_TYPE_OFFER_V2, NEW_OFFER_V2_FACTORY,
    OfferItem,
    OfferV2, UpsellOption
} from "@thekeytechnology/thekey-academy-frontend-library";
import { LoadingEditHeader } from "../../../../../../core/components/header/LoadingEditHeader";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../../../core/components/entity/WithSingleEntityFromPath";
import { EditHeaderWithBackground } from "../../../../../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../../../../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../../../../../core/components/containers/Container";
import { ValidatedField } from "../../../../../../core/components/form/ValidatedField";
import { AsyncEntitySelectField } from "../../../../../../core/components/entity/AsyncEntitySelectField";
import { CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY } from "../../../../../../core/components/entity/AsyncEntitySelect";
import { BooleanSelectField } from "../../../../../../core/components/select/BooleanSelectField";
import { LoadingRow } from "../../../../../../core/components/table/LoadingRow";

type Props = WithSingleEntityFromPathProps<OfferV2, OfferV2> & WithTranslation;

const EditOfferFormComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return entity ? (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.offer.singular")}/>

            <Formik
                initialValues={{
                    internalTitle: entity.entity.internalTitle,
                    title: entity.entity.title,
                    forcedCustomerType: entity.entity.forcedCustomerType,
                    disabledPaymentMethods: entity.entity.disabledPaymentMethods,
                    limitedToAccountRef: entity.entity.limitedToAccountRef ? {id: entity.entity.limitedToAccountRef} : undefined,
                    showNetPrices: entity.entity.showNetPrices,
                    items: entity.entity.items,
                    upsellOptions: entity.entity.upsellOptions
                }}
                validationSchema={Yup.object().shape({
                    title: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.offer.labels.title")})),
                    internalTitle: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.offer.labels.internal-title")})),
                    items: Yup.array().min(1, t("entity.offer.labels.items-error-minimum")).test("items", "test", function(value) {
                        const items = value as OfferItem[];
                        let usesMonthly = false
                        let rateCount = 0
                        let taxRate = 0
                        const usedCourses: string[] = []
                        for (const item of items) {
                            switch (item.itemType) {
                                case "course-offer-item":
                                    const courseItem = item as CourseOfferItem

                                    if (usedCourses.includes(courseItem.courseRef)) {
                                        return this.createError({
                                            path: "items",
                                            message: t("entity.offer.labels.items-error-duplicate-course")
                                        });
                                    } else {
                                        usedCourses.push(courseItem.courseRef)
                                    }

                                    if (!courseItem.courseRef) {
                                        return this.createError({
                                            path: "items",
                                            message: t("entity.offer.labels.items-error-course")
                                        });
                                    }
                                    if (courseItem.price.discount && (!courseItem.price.discount.title || !courseItem.price.discount.text)) {
                                        return this.createError({
                                            path: "items",
                                            message: t("entity.offer.labels.items-error-discount")
                                        });
                                    }

                                    const notAllUseMonthly = usesMonthly && !courseItem.price.monthlyPrice;
                                    const rateCountNotEqual = rateCount > 0 && (courseItem.price.monthlyPrice?.rateCount !== rateCount)
                                    if (notAllUseMonthly || rateCountNotEqual) {
                                        return this.createError({
                                            path: "items",
                                            message: t("entity.offer.labels.items-error-monthly")
                                        });
                                    }

                                    if (taxRate > 0 && taxRate !== courseItem.price.taxRate) {
                                        return this.createError({
                                            path: "items",
                                            message: t("entity.offer.labels.items-error-tax-rate")
                                        });
                                    } else {
                                        taxRate = courseItem.price.taxRate
                                    }

                                    if (!usesMonthly && courseItem.price.monthlyPrice) {
                                        usesMonthly = true
                                        rateCount = courseItem.price.monthlyPrice.rateCount
                                    }
                                    break;
                                case "discount-offer-item":
                                    const discountItem = item as DiscountOfferItem;
                                    if (!discountItem.title) {
                                        return this.createError({
                                            path: "items",
                                            message: t("entity.offer.labels.items-error-discount")
                                        });
                                    }
                            }
                        }
                        return true;
                    }),
                    upsellOptions: Yup.array().test("upsellOptions", "test", function(value) {
                        const upsellOptions = value as UpsellOption[];

                        const items = this.parent.items as OfferItem[];
                        const usedCoursesInItems = items.filter(i => i.itemType === "course-offer-item").map(i => (i as CourseOfferItem).courseRef)
                        const isMonthly = items.filter(i => i.itemType === "course-offer-item").find(i => (i as CourseOfferItem).price.monthlyPrice !== undefined) !== undefined
                        const monthlyRate = (items.filter(i => i.itemType === "course-offer-item").find(i => (i as CourseOfferItem).price.monthlyPrice !== undefined) as CourseOfferItem)?.price.monthlyPrice?.rateCount
                        const taxRate = items.filter(i => i.itemType === "course-offer-item").map(i => (i as CourseOfferItem).price.taxRate).find(() => true)

                        for (const options of upsellOptions) {
                            if (!options.courseRef) {
                                return this.createError({
                                    path: "upsellOptions",
                                    message: t("entity.offer.labels.upsell-options-error-course")
                                });
                            }
                            if (options.price.taxRate !== taxRate) {
                                return this.createError({
                                    path: "upsellOptions",
                                    message: t("entity.offer.labels.upsell-options-error-tax-rate")
                                });
                            }
                            if (usedCoursesInItems.includes(options.courseRef)) {
                                return this.createError({
                                    path: "upsellOptions",
                                    message: t("entity.offer.labels.upsell-options-error-course-already-included")
                                });
                            }
                            if (isMonthly && !options.price.monthlyPrice) {
                                return this.createError({
                                    path: "upsellOptions",
                                    message: t("entity.offer.labels.upsell-options-error-monthly")
                                });
                            }
                            if (isMonthly && options.price.monthlyPrice?.rateCount !== monthlyRate) {
                                return this.createError({
                                    path: "upsellOptions",
                                    message: t("entity.offer.labels.upsell-options-error-monthly")
                                });
                            }
                            if (!options.description) {
                                return this.createError({
                                    path: "upsellOptions",
                                    message: t("entity.offer.labels.upsell-options-error-description")
                                });
                            }
                        }
                        return true;
                    })
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper<OfferV2>(entity.id,
                        {
                            title: values.title,
                            internalTitle: values.internalTitle,
                            limitedToAccountRef: values.limitedToAccountRef ? values.limitedToAccountRef.id : undefined,
                            forcedCustomerType: values.forcedCustomerType,
                            showNetPrices: !!values.showNetPrices,
                            disabledPaymentMethods: values.disabledPaymentMethods,
                            items: values.items,
                            upsellOptions: values.upsellOptions
                        }
                    );
                    upsertEntity(saving);
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                formikState.values.title :
                                t("core:edit-header.heading-empty")}
                        >
                            <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                backPath="/offers-v2/"/>
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.title")}
                                                type="text"
                                                name="title"
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.title")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.internal-title")}
                                                type="text"
                                                name="internalTitle"
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.internal-title")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    label={t("entity.offer.labels.forced-customer-type")}
                                    name="forcedCustomerType"
                                    component={SimpleOptionSelectField}
                                    placeholder={"Dem Kunden Auswahl erlauben"}
                                    options={["private", "business"]}
                                    listRenderer={(value: any) => t("customer-types." + value)}
                                    formikState={formikState}
                                    isClearable={true}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    label={t("entity.offer.labels.disabled-payment-methods")}
                                    name="disabledPaymentMethods"
                                    component={SimpleOptionSelectField}
                                    placeholder={"Alle Zahlungsmittel erlaubt"}
                                    options={[
                                        "card",
                                        "sepa",
                                        "giropay",
                                        "sofort",
                                        "paypal",
                                        "invoice",
                                        "monthly-partner",
                                        "monthly-tk"
                                    ]}
                                    isMulti={true}
                                    listRenderer={(value: any) => t("payment-methods." + value)}
                                    formikState={formikState}
                                    isClearable={true}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    label={t("entity.offer.labels.limited-to-account")}
                                    name="limitedToAccountRef"
                                    component={AsyncEntitySelectField}
                                    fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/api/admin/v1/accounts/list")}
                                    placeholder={"Von jedem Kunden benutzbar"}
                                    listRenderer={(value: EntityWrapper<Account>) => value?.entity?.name}
                                    formikState={formikState}
                                    shownEntityType={"doesnt-matter"}
                                    shownEntityTypeProperties={["entity.name", "entity.email"]}
                                    isMulti={false}
                                    isClearable={true}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    icon={faPercent}
                                    label={t("entity.offer.labels.show-net-prices")}
                                    name="showNetPrices"
                                    placeholder={"Nein, Preise als brutto anzeigen (inkl. MwSt. Hinweis)"}
                                    formikState={formikState}
                                    component={BooleanSelectField}
                                    trueLabel={t("Ja, Preise als netto anzeigen (exkl. MwSt. Hinweis)")}
                                    falseLabel={t("Nein, Preise als brutto anzeigen (inkl. MwSt. Hinweis)")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    label={t("entity.offer.labels.offer-items")}
                                    name="items"
                                    formikState={formikState}
                                    component={OfferItemsField}
                                />
                            </div>
                            <div className="text-center mb-5 font-weight-bold">
                                <OfferTotals offerItems={formikState.values.items}/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    label={t("entity.offer.labels.upsell-options")}
                                    name="upsellOptions"
                                    formikState={formikState}
                                    showNetPrices={formikState.values.showNetPrices}
                                    component={UpsellOptionsField}
                                />
                            </div>
                        </ContentContainer>
                    </Form>
                )}
            </Formik>
        </>
    ) : <LoadingRow/>;
};

export const EditOfferV2Form = WithSingleEntityFromPath<{}, OfferV2, OfferV2>(
    withTranslation(["billing", "core"])(EditOfferFormComponent),
    ENTITY_TYPE_OFFER_V2,
    "offerId",
    NEW_OFFER_V2_FACTORY,
    undefined);
