import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import {
    fetchSingleEntityAction,
    reloadSingleEntityAction,
    selectCombinedApiState,
    selectSingleEntity
} from "@thekeytechnology/framework-react";
import {
    COURSE_UPLOAD_ENTITY_ID,
    CourseUploadStatus,
    ENTITY_TYPE_COURSE_UPLOAD_STATUS
} from "../model/CourseUploadStatus";
import "./course-publishing-log.scss"
import { API_PUBLISH_COURSE } from "../actions/publish-course";
import { DateTimeDisplay } from "../../core/components/datetime/DateTimeDisplay";

export const CoursePublishingLog = () => {
    const dispatch = useDispatch();
    const log = useSelector(selectSingleEntity<CourseUploadStatus>(ENTITY_TYPE_COURSE_UPLOAD_STATUS))
    const apiCallState = useSelector(selectCombinedApiState(API_PUBLISH_COURSE));

    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        dispatch(fetchSingleEntityAction(ENTITY_TYPE_COURSE_UPLOAD_STATUS)(COURSE_UPLOAD_ENTITY_ID))
    }, [dispatch])

    useEffect(() => {
        if (log?.entity?.inProgress || apiCallState.inProgress || apiCallState.succeeded) {
            setVisible(true);

            const interval = setInterval(() => {
                dispatch(reloadSingleEntityAction(ENTITY_TYPE_COURSE_UPLOAD_STATUS)())
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [apiCallState, dispatch, log])

    return isVisible ? <div className="course-publishing-log">
        <h3>Log der Veröffentlichung:</h3>

        <div className="log-wrapper">
            {log?.entity?.logEntries.map((logEntry, index) => <div className="log-entry" key={index}>
                <div className="timestamp"><DateTimeDisplay dateTime={logEntry.timestamp}/></div>
                <div className="message">{logEntry.message}</div>
            </div>)}
        </div>
    </div> : null
}
