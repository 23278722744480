import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { ProductsTable } from "./ProductsTable";
import { ProductsScreen_ProductsQuery } from "../../../../../__generated__/ProductsScreen_ProductsQuery.graphql";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";

export const ProductsScreen = () => {
    const history = useHistory();

    const data = useLazyLoadQuery<ProductsScreen_ProductsQuery>(graphql`
        query ProductsScreen_ProductsQuery {
            ...ProductsTable_ProductsList
        }
    `, {}, {fetchPolicy: "network-only"})

    return <div>
        <h1 className="mb-3">Produkte</h1>

        <div className="p-mb-3 p-justify-end p-d-flex">
            <Button onClick={() => history.push({pathname: "/products/new/edit"})}>
                Neu
            </Button>
        </div>
        <ProductsTable productsListFragmentRef={data}/>
    </div>

}
