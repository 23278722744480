import { UserWithRoles } from "@thekeytechnology/framework-react";
import { SyncRequestsOverview } from "./components/SyncRequestsOverview";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";

export const SyncRequestsModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/sync-requests",
        component: SyncRequestsOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
];
