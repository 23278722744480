import React from "react";
import { Button, Modal } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";

interface OwnProps {
    isSubmitting: boolean;
    onClose: () => void;
}

type Props = OwnProps & WithTranslation;

const EditModalFooterComponent = (props: Props) => {
    const {isSubmitting, onClose, t} = props;

    return <Modal.Footer>
        <Button disabled={isSubmitting} variant="secondary" onClick={onClose}>
            {t("course-editor.edit-modal.close")}
        </Button>
        <Button disabled={isSubmitting} type="submit" variant="primary">
            {t("course-editor.edit-modal.submit")}
        </Button>
    </Modal.Footer>;
};

export const EditModalFooter = withTranslation("courses")(EditModalFooterComponent);
