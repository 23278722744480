import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_COURSES_ADMIN = "fetch-courses-admin";

export const fetchCoursesAdminAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_COURSES_ADMIN,
            callType: API_FETCH_COURSES_ADMIN
        },
        payload: undefined,
    } as ApiCallAction<void>;
};
