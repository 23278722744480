import update from "immutability-helper";
import { EditedCourseState } from "../../edited-course";
import { anyIdMatches, extractId } from "../common/helpers";
import { RemoveElementAction } from "../../../actions/element/remove-lesson";

export function removeElement(state: EditedCourseState,
                              {element, parentLessonId}: RemoveElementAction) {
    const parentLesson = state.editedCourse!.entity.lessons.find(l => anyIdMatches(l, parentLessonId))!
    const parentLessonIndex = state.editedCourse!.entity.lessons.indexOf(parentLesson);
    const elementInLessonIndex = parentLesson.entity.elementsRef.indexOf(extractId(element))
    const elementInElementListIndex = state.editedCourse!.entity.elements.indexOf(element)

    return update(state,
        {
            hasUnsavedChanges: {$set: true},
            editedCourse: {
                entity: {
                    lessons: {[parentLessonIndex]: {entity: {elementsRef: {$splice: [[elementInLessonIndex, 1]]}}}},
                    elements: {$splice: [[elementInElementListIndex, 1]]}
                }
            }
        }
    )
}
