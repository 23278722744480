import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldProps } from "formik";
import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";
import { QuestionnaireQuestion } from "@thekeytechnology/thekey-academy-frontend-library";

type Props = FieldProps;

export const QuestionnaireQuestionsField = ({field: {name, value}, form}: Props) => {
    const {t} = useTranslation("courses")

    const questionnaireQuestions = value ? value as QuestionnaireQuestion[] : [];

    const newClass = () => {
        const newClasses = questionnaireQuestions.concat(
            {title: "", reverseScale: false} as QuestionnaireQuestion
        );
        form.setFieldValue(name, newClasses);
    };

    const updateTitle = (question: QuestionnaireQuestion, index: number, updatedTitle?: string) => {
        const newState = updatedTitle ? update(questionnaireQuestions, {
            [index]: {
                $set: {title: updatedTitle, reverseScale: question.reverseScale} as QuestionnaireQuestion
            }
        }) : update(questionnaireQuestions, {
            [index]: {
                $set:
                    {title: question.title, reverseScale: question.reverseScale} as QuestionnaireQuestion
            }
        });
        form.setFieldValue(name, newState);
    };

    const updateReverseScale = (question: QuestionnaireQuestion, index: number, reverseScale: boolean) => {
        const newState = update(questionnaireQuestions, {
            [index]: {
                $set:
                    {title: question.title, reverseScale} as QuestionnaireQuestion
            }
        });
        form.setFieldValue(name, newState);
    };

    const removeClass = (index: number) => {
        form.setFieldValue(name,
            update(
                questionnaireQuestions, {$splice: [[index, 1]]}
            )
        );
    };

    return (
        <div className="questionnaire-answers d-flex flex-column w-100">
            {questionnaireQuestions.map((questionnaireClass: QuestionnaireQuestion, index: number) =>
                <Card key={index}>
                    <Card.Body>
                        <h3 className="d-flex align-items-center">
                            {t("course-editor.questionnaire-questions-field.questionnaire-question", {index: index + 1})}
                            <button onClick={() => removeClass(index)}
                                    type="button"
                                    className="btn btn-link text-danger">
                                <FontAwesomeIcon icon={faTrash}/></button>
                        </h3>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.questionnaire-questions-field.title")}*</label>
                            <div className="col-sm-10">
                                <input
                                    className="form-control default-input"
                                    type="text"
                                    placeholder={t("course-editor.questionnaire-questions-field.title-placeholder")}
                                    value={questionnaireClass.title}
                                    onChange={(event => updateTitle(questionnaireClass, index, event.target.value))}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.questionnaire-questions-field.reverse-scale-label")}</label>
                            <div className="col-sm-10 d-flex align-items-center">
                                <div className="form-check d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input"
                                           checked={questionnaireClass.reverseScale}
                                           onChange={event => updateReverseScale(questionnaireClass, index, event.target.checked)}/>
                                    <label
                                        className="form-check-label ml-2"> {t("course-editor.questionnaire-questions-field.reverse-scale-explanation")}</label>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}
            <Card>
                <Card.Body>
                    <button type="button" className="btn-link text-success" onClick={newClass}>
                        <FontAwesomeIcon className="mr-2"
                                         icon={faPlus}/>{t("course-editor.questionnaire-questions-field.new-question")}
                    </button>
                </Card.Body>
            </Card>
        </div>
    );
};
