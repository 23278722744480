/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteCustomerMessageDefinitionPageInput = {
    id: string;
    clientMutationId?: string | null;
};
export type CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutationVariables = {
    input: DeleteCustomerMessageDefinitionPageInput;
    connections: Array<string>;
};
export type CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutationResponse = {
    readonly Admin: {
        readonly CustomerMessageV2: {
            readonly deleteCustomerMessageDefinitionPage: {
                readonly deletedId: string;
            } | null;
        };
    };
};
export type CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutation = {
    readonly response: CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutationResponse;
    readonly variables: CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutationVariables;
};



/*
mutation CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutation(
  $input: DeleteCustomerMessageDefinitionPageInput!
) {
  Admin {
    CustomerMessageV2 {
      deleteCustomerMessageDefinitionPage(input: $input) {
        deletedId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteCustomerMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "deleteCustomerMessageDefinitionPage",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteCustomerMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "deleteCustomerMessageDefinitionPage",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedId",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aed8025dfae8fbac5402add9bb950e17",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerMessageDefinitionPagesOrderList_DeleteCustomerMessageDefinitionPageMutation(\n  $input: DeleteCustomerMessageDefinitionPageInput!\n) {\n  Admin {\n    CustomerMessageV2 {\n      deleteCustomerMessageDefinitionPage(input: $input) {\n        deletedId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ae483108f97b72db4e189b35e8305bbc';
export default node;
