import { FormikState } from "formik";
import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import { EditModalBase } from "../../common/modal/EditModalBase";
import { GENERAL_FIELDS, GeneralElementSettingsEditor } from "./GeneralElementSettingsEditor";
import update from "immutability-helper";

interface OwnProps {
    currentElementId: string;
    title: string;

    children: React.ReactNode;

    formikState: FormikState<any>;
    handleClose: () => void;
}

type Props = OwnProps & WithTranslation;

const EditElementModalBaseComponent = (props: Props) => {
    const {title, formikState, handleClose, currentElementId, children, t} = props;

    const errorInGeneralFields = GENERAL_FIELDS
        .map(field => !!formikState.errors[field])
        .reduce((prev, next) => prev || next);

    const generalFieldsTouched = GENERAL_FIELDS
        .map(field => !!formikState.touched[field])
        .reduce((prev, next) => prev || next);

    const errorsWithoutGeneralFields = update(formikState.errors, {$unset: GENERAL_FIELDS});
    const touchedWithoutGeneralFields = update(formikState.touched, {$unset: GENERAL_FIELDS});

    const errorSomewhereElse = Object.entries(errorsWithoutGeneralFields).length > 0;
    const otherFieldsTouched = Object.entries(touchedWithoutGeneralFields).length > 0;

    return <EditModalBase formikState={formikState} handleClose={handleClose} title={title}>
        <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
            <Tab eventKey="general"
                 tabClassName={errorInGeneralFields && generalFieldsTouched ? "text-danger" : ""}
                 className={"pt-3 "}
                 title={t("course-editor.edit-element-form.common.general-heading")}>
                <GeneralElementSettingsEditor
                    currentElementId={currentElementId}
                    formikState={formikState}/>
            </Tab>
            <Tab eventKey="specific"
                 tabClassName={errorSomewhereElse && otherFieldsTouched ? "text-danger" : ""}
                 className={"pt-3 "}
                 title={t("course-editor.edit-element-form.common.specific-heading")}>
                {children}
            </Tab>
        </Tabs>
    </EditModalBase>;
};

export const EditElementModalBase = withTranslation("courses")(EditElementModalBaseComponent);
