import React, {useState} from "react";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {FieldProps} from "formik";
import {
    CourseForOverview,
    CourseModule,
    CourseState,
    DownloadableLessonMeta,
    LESSON_STATUS_FINISHED,
    LessonState,
    ModuleState,
    Progress
} from "@thekeytechnology/thekey-academy-frontend-library";
import Select from "react-select";
import moment from "moment";
import {BooleanSelect} from "../../../../core/components/form/BooleanSelect";
import "./course-state-field.scss";
import {Card} from "react-bootstrap";
import { DateTimeDisplay } from "../../../../core/components/datetime/DateTimeDisplay";
import { ClearIndicator } from "../../../../core/components/select/ClearIndicator";

interface OwnProps {
    course: EntityWrapper<CourseForOverview>
}

type Props = OwnProps & FieldProps

const STATE_OPTIONS = [
    {
        value: undefined,
        label: "Nicht freigeschalten"
    },
    {
        value: "current",
        label: "Aktuell"
    },
    {
        value: "finished",
        label: "Abgeschlossen"
    }
]

export const CourseStateField = ({field, form, course}: Props) => {
    const courseState = field.value as EntityWrapper<CourseState> | undefined

    const [openModules, setOpenModules] = useState<string[]>([]);

    return courseState ? <div className="course-state-field">
            <div>
                <label>
                    <div>Ist gekauft</div>
                    <BooleanSelect
                        value={courseState.entity.isBought}
                        trueLabel={"Ja"}
                        falseLabel={"Nein"}
                        onChange={newValue => {
                            form.setFieldValue(field.name, {
                                ...courseState,
                                entity: unlockTrialIfNecessary(course, courseState?.entity, newValue)
                            })
                        }}
                    />
                </label>
                <label>
                    <div>Kann Zertifikat bestellen</div>
                    <BooleanSelect
                        value={courseState.entity.isCanOrderCertificate}
                        trueLabel={"Ja"}
                        falseLabel={"Nein"}
                        onChange={newValue => {
                            form.setFieldValue(field.name, {
                                ...courseState,
                                entity: {...courseState?.entity, isCanOrderCertificate: newValue}
                            }, true);
                        }}
                    />
                </label>
                <label>
                    <div>Begonnen am</div>
                    <DateTimeDisplay dateTime={courseState.entity.startedAt}/>
                </label>
                {courseState.entity.certificateData ? <>
                    <label>
                        <div>Zertifikatsnummer %</div>
                        <input
                            disabled={true}
                            className="form-control default-input"
                            type="text"
                            value={courseState.entity.certificateData.certificateId}/>
                    </label>
                    <label>
                        <div>Ausgestellt am</div>
                        <DateTimeDisplay dateTime={courseState.entity.certificateData.issuedAt}/>
                    </label>
                    <label>
                        <div>Mit Auszeichnung</div>
                        <div>{courseState.entity.certificateData.isWithHonors ? "Ja" : "Nein"}</div>
                    </label>
                </> : null}
                <label>
                    <div>Fortschritt %</div>
                    <input
                        disabled={true}
                        className="form-control default-input"
                        type="number"
                        value={courseState.entity.progress.progressPercentage}/>
                </label>
                <label>
                    <div>Performance %</div>
                    <input
                        disabled={true}
                        className="form-control default-input"
                        type="number"
                        value={courseState.entity.progress.performancePercentage}/>
                </label>
            </div>
            <div className="modules">
                {course.entity.modules.map(module => {
                    const moduleState = courseState?.entity.moduleStati[module.id!]
                    const isModuleOpen = openModules.includes(module.id!);
                    return <Card className="mb-4" key={module.id!}>
                        <Card.Header>
                            <h3>Modul {module.entity.modulePath.moduleIndex + 1}: {module.entity.containerMeta.title}</h3>
                            <div>
                                <label>
                                    <div>Status</div>
                                    <Select
                                        className="react-select category-select"
                                        classNamePrefix="react-select"
                                        components={{ClearIndicator}}
                                        options={STATE_OPTIONS}
                                        value={STATE_OPTIONS.find(o => o.value === moduleState?.status)}
                                        onChange={(item: any) => {
                                            form.setFieldValue(field.name, {
                                                ...courseState,
                                                entity: setModuleStatus(course, module, courseState.entity, item.value)
                                            }, true)
                                        }}
                                        onInputChange={() => {
                                            form.setTouched({
                                                [field.name]: true
                                            })
                                        }}
                                        onMenuOpen={() => {
                                            form.setTouched({
                                                [field.name]: true
                                            })
                                        }}
                                        getOptionValue={(opt: any) => opt.value}
                                        getOptionLabel={item => item.label}
                                        onBlur={field.onBlur}
                                    />
                                </label>
                                {moduleState ? <>
                                    <label>
                                        <div>Erstes Update</div>
                                        <DateTimeDisplay dateTime={moduleState.firstUpdate}/>
                                    </label>
                                    <label>
                                        <div>Letztes Update</div>
                                        <DateTimeDisplay dateTime={moduleState.lastUpdate}/>
                                    </label></> : null}
                                {moduleState && moduleState.status === "finished" ?
                                    <label>
                                        <div>Punkte</div>
                                        <input
                                            disabled={true}
                                            className="form-control default-input"
                                            type="number"
                                            value={moduleState?.points}/>
                                    </label> : null}
                            </div>
                            <button type="button" className="btn btn-link" onClick={() => {
                                if (isModuleOpen) {
                                    setOpenModules(openModules.filter(m => m !== module.id))
                                } else {
                                    setOpenModules([...openModules, module.id!])
                                }
                            }}>
                                {isModuleOpen ? "Lektionen verbergen" : "Lektionen anzeigen"}
                            </button>
                        </Card.Header>
                        {isModuleOpen && <Card.Body>
                            <div className="lessons pl-5">

                                {module.entity.lessonsRef.map(lId => {
                                    const lesson = course.entity.lessons.find(l => l.id === lId)

                                    if (!lesson) {
                                        return null;
                                    }

                                    const lessonState = courseState.entity.lessonStati[lesson.id!]

                                    return <div className="lesson d-flex flex-column mb-2" key={lesson.id}>
                                        <h4>Lektion {lesson.entity.lessonPath.moduleIndex + 1}.{lesson.entity.lessonPath.lessonIndex + 1}: {lesson.entity.containerMeta.title} (Punkte: {lesson.entity.containerMeta.points})</h4>
                                        <label>
                                            <div>Status</div>
                                            <Select
                                                className="react-select category-select"
                                                classNamePrefix="react-select"
                                                components={{ClearIndicator}}
                                                options={STATE_OPTIONS}
                                                value={STATE_OPTIONS.find(o => o.value === lessonState?.status)}
                                                onChange={(item: any) => {
                                                    form.setFieldValue(field.name, {
                                                        ...courseState,
                                                        entity: setLessonStatus(course, module, lesson, courseState.entity, item.value)
                                                    }, true);
                                                }}
                                                onInputChange={() => {
                                                    form.setTouched({
                                                        [field.name]: true
                                                    })
                                                }}
                                                onMenuOpen={() => {
                                                    form.setTouched({
                                                        [field.name]: true
                                                    })
                                                }}
                                                getOptionValue={(opt: any) => opt.value}
                                                getOptionLabel={item => item.label}
                                                onBlur={field.onBlur}
                                            />
                                        </label>
                                        {lessonState ? <>
                                            <label>
                                                <div>Erstes Update</div>
                                                <DateTimeDisplay dateTime={lessonState.firstUpdate}/>
                                            </label>
                                            <label>
                                                <div>Letztes Update</div>
                                                <DateTimeDisplay dateTime={lessonState.lastUpdate}/>
                                            </label>
                                            <label>
                                                <div>Lektion zum 1. Mal abgeschlossen</div>
                                                <DateTimeDisplay dateTime={lessonState.firstSubmittedAt}/>
                                            </label>
                                        </> : null}
                                        {lessonState && lessonState.status === "finished" ?
                                            <label>
                                                <div>Punkte</div>
                                                <input
                                                    className="form-control default-input"
                                                    type="number"
                                                    min={0}
                                                    step={1}
                                                    max={lesson.entity.containerMeta.points}
                                                    value={lessonState?.points}
                                                    onChange={event => {
                                                        const pointsValue = parseInt(event.target.value);

                                                        const newLessonStati: { [lessonId: string]: LessonState } = {
                                                            ...courseState.entity.lessonStati,
                                                            [lesson.id!]: {
                                                                ...lessonState,
                                                                points: pointsValue
                                                            }
                                                        }
                                                        const newModuleStati: { [moduleId: string]: ModuleState } = {
                                                            ...courseState.entity.moduleStati,
                                                            [module.id!]: {
                                                                ...moduleState,
                                                                points: Object.entries(newLessonStati).filter(ls => module.entity.lessonsRef.includes(ls[0])).map(ls => ls[1].points).reduce((a, b) => a + b, 0)
                                                            },
                                                        }

                                                        form.setFieldValue(field.name, {
                                                            ...courseState,
                                                            entity: {
                                                                ...courseState.entity,
                                                                moduleStati: newModuleStati,
                                                                lessonStati: newLessonStati,
                                                                progress: calculateProgress(course, newLessonStati)
                                                            }
                                                        }, true);

                                                    }}

                                                />
                                            </label> : null}
                                    </div>;
                                })}
                            </div>
                        </Card.Body>}
                    </Card>
                })}
            </div>
        </div>
        : <div className="align-items-center">
            <button
                className="btn btn-secondary"
                type="button"

                onClick={() => {
                    const firstModule = course.entity.modules.find(() => true)!;
                    form.setFieldValue(field.name, {
                        entity: {
                            courseId: course.id!,
                            lessonStati: {
                                [firstModule.entity.lessonsRef.find(() => true)!]: {
                                    status: "current",
                                    points: 0,
                                    lastUpdate: moment().toISOString(),
                                    firstUpdate: moment().toISOString()
                                }
                            },
                            moduleStati: {
                                [firstModule.id!]: {
                                    status: "current",
                                    points: 0,
                                    lastUpdate: moment().toISOString(),
                                    firstUpdate: moment().toISOString()
                                }
                            },
                            isBought: false,
                            isAtEndOfTrial: false,
                            isDone: false,
                            progress: {
                                isDone: false,
                                progressPercentage: 0,
                                lastUpdate: moment().toISOString()
                            } as Progress,
                            isCanOrderCertificate: false,
                            isCertificateOrdered: false
                        }
                    })
                }}>Freischalten
            </button>
        </div>
}

const unlockTrialIfNecessary = (course: EntityWrapper<CourseForOverview>, oldState: CourseState, courseIsBought: boolean): CourseState | undefined => {
    if (courseIsBought) {
        const lessons = course.entity.modules
            .flatMap(m => m.entity.lessonsRef)
            .flatMap(lessonId => {
                const lesson = course.entity.lessons.find(l => l.id === lessonId);
                return lesson ? [lesson] : [];
            });

        const trialLessons = lessons.filter(l => l.entity.isPartOfTrial)
        const firstNonTrialLesson = lessons.find(l => !l.entity.isPartOfTrial)
        const lastTrialLesson = trialLessons[trialLessons.length - 1]

        if (lastTrialLesson) {
            const lastTrialLessonState = oldState.lessonStati[lastTrialLesson.id!]
            const lessonsAfterLastTrialLessonsHaveBeenUnlocked = Object.entries(oldState.lessonStati).length - 1 > lastTrialLesson?.entity.lessonPath.lessonIndex;
            if (lastTrialLessonState?.status === LESSON_STATUS_FINISHED) {
                if (!lessonsAfterLastTrialLessonsHaveBeenUnlocked && firstNonTrialLesson) {
                    return {
                        ...setLessonStatus(
                            course,
                            course.entity.modules.find(m => m.id === firstNonTrialLesson.entity.lessonPath.moduleRef)!,
                            firstNonTrialLesson,
                            oldState,
                            "current")!,
                        isBought: true
                    }
                }
            }
        }
    }
    return {
        ...oldState,
        isBought: courseIsBought
    }
}

const setModuleStatus = (course: EntityWrapper<CourseForOverview>, module: EntityWrapper<CourseModule>, courseState: CourseState, newStatus: "current" | "finished" | undefined): CourseState | undefined => {
    let newState: CourseState | undefined;
    switch (newStatus) {
        case "current":
            const firstLessonOfCurrentModule = module.entity.lessonsRef.find(() => true)
            newState = updateState(course, courseState!, firstLessonOfCurrentModule)
            break;
        case "finished":
            const nextModule = course.entity.modules[course.entity.modules.findIndex(m => m === module) + 1]
            const firstLessonOfNextModule = nextModule?.entity.lessonsRef.find(() => true)
            newState = updateState(course, courseState!, firstLessonOfNextModule)
            break;
        case undefined:
            const modulesAfter = [...course.entity.modules].splice(
                course.entity.modules.findIndex(m => m === module)
            )

            const moduleIdsAfter = modulesAfter.map(m => m.id!);
            const lessonIdsAfter = modulesAfter.flatMap(m => m.entity.lessonsRef)
            const newModuleStati = Object
                .entries<ModuleState>(courseState.moduleStati)
                .filter(ms => ms[0] !== module.id && !moduleIdsAfter.includes(ms[0]))
                .map(ms => ({[ms[0]]: ms[1]}))
                .reduce((a, b) => ({...a, ...b}), {});
            const newLessonStati = Object
                .entries<LessonState>(courseState?.lessonStati)
                .filter(ls => !module.entity.lessonsRef.includes(ls[0]) && !lessonIdsAfter.includes(ls[0]))
                .map(ls => ({[ls[0]]: ls[1]}))
                .reduce((a, b) => ({...a, ...b}), {});

            const firstModule = course.entity.modules.find(() => true)!;

            if (Object.entries(newLessonStati).length === 0) {
                newState = updateState(course, {
                    ...courseState,
                    moduleStati: newModuleStati,
                    lessonStati: newLessonStati
                }, firstModule.entity.lessonsRef.find(() => true))
            } else {

                newState = {
                    ...courseState,
                    moduleStati: newModuleStati,
                    lessonStati: newLessonStati,
                    progress: calculateProgress(course, newLessonStati)
                }
            }

            break;
        default:
            newState = undefined;
    }
    return newState;
}

const setLessonStatus = (course: EntityWrapper<CourseForOverview>, module: EntityWrapper<CourseModule>, lesson: EntityWrapper<DownloadableLessonMeta>, courseState: CourseState, newStatus: "current" | "finished" | undefined): CourseState | undefined => {

    let newState: CourseState | undefined;
    switch (newStatus) {
        case "current":
            newState = updateState(course, courseState!, lesson.id)
            break;
        case "finished":
            const isLastInModule = module.entity.lessonsRef.findIndex(l => l === lesson.id!) === module.entity.lessonsRef.length - 1
            if (isLastInModule) {
                const nextModule = course.entity.modules[course.entity.modules.findIndex(m => m === module) + 1]
                const firstLessonOfNextModule = nextModule?.entity.lessonsRef.find(() => true)
                newState = updateState(course, courseState!, firstLessonOfNextModule)
            } else {
                const nextLesson = module.entity.lessonsRef[module.entity.lessonsRef.findIndex(l => l === lesson.id!) + 1]
                newState = updateState(course, courseState!, nextLesson)
            }
            break;
        case undefined:
            const modulesAfter = [...course.entity.modules].splice(
                course.entity.modules.findIndex(m => m === module)
            )

            const moduleIdsAfter = modulesAfter.map(m => m.id!);
            const lessonIdsAfter = modulesAfter.flatMap(m => m.entity.lessonsRef)
            const newModuleStati = Object
                .entries<ModuleState>(courseState.moduleStati)
                .filter(ms => !moduleIdsAfter.includes(ms[0]))
                .map(ms => ({[ms[0]]: ms[1]}))
                .reduce((a, b) => ({...a, ...b}), {});
            const newLessonStati = Object
                .entries<LessonState>(courseState?.lessonStati)
                .filter(ls => !module.entity.lessonsRef.includes(ls[0]) && !lessonIdsAfter.includes(ls[0]))
                .map(ls => ({[ls[0]]: ls[1]}))
                .reduce((a, b) => ({...a, ...b}), {});

            const firstModule = course.entity.modules.find(() => true)!;

            if (Object.entries(newLessonStati).length === 0) {
                newState = updateState(course, {
                    ...courseState,
                    moduleStati: newModuleStati,
                    lessonStati: newLessonStati,
                    progress: calculateProgress(course, newLessonStati)
                }, firstModule.entity.lessonsRef.find(() => true))
            } else {
                newState = {
                    ...courseState,
                    moduleStati: newModuleStati,
                    lessonStati: newLessonStati,
                    progress: calculateProgress(course, newLessonStati)
                }
            }

            break;
        default:
            newState = undefined;
    }
    return newState;
}

const calculateProgress = (course: EntityWrapper<CourseForOverview>, lessonStati: { [lessonId: string]: LessonState }): Progress => {
    const finishedLessonStati = Object.entries(lessonStati).filter(ls => ls[1].status === "finished");
    const progressDecimal = finishedLessonStati.length / course.entity.lessons.length;
    const progressPercentage = parseInt((progressDecimal * 100).toFixed(0));

    const pointsGottenAndPossible = finishedLessonStati.map(ls => {
        const lesson = course.entity.lessons.find(l => l.id === ls[0])

        return [ls[1].points, lesson!.entity.containerMeta.points]
    }).reduce((a, b) => [a[0] + b[0], a[1] + b[1]], [0, 0])
    const performanceDecimal = pointsGottenAndPossible[0] / Math.max(pointsGottenAndPossible[1], 1)
    const performancePercentage = parseInt((performanceDecimal * 100).toFixed(0));

    return {
        isDone: progressPercentage === 100,
        progressPercentage: progressPercentage,
        lastUpdate: moment().toISOString(),
        performancePercentage: performancePercentage
    }
}

const updateState = (course: EntityWrapper<CourseForOverview>, oldState: CourseState, newCurrentLesson: string | undefined): CourseState => {
    const modulesToFinish: EntityWrapper<CourseModule>[] = []
    for (const module of course.entity.modules) {
        if (newCurrentLesson && module.entity.lessonsRef.includes(newCurrentLesson)) {
            break;
        }
        modulesToFinish.push(module)
    }

    const currentModule = course.entity.modules.find(m => newCurrentLesson && m.entity.lessonsRef.includes(newCurrentLesson))

    const finishedModuleStati = modulesToFinish.map(m => {
        const existingModuleState = oldState.moduleStati[m.id!]
        const firstUpdate = existingModuleState && existingModuleState.firstUpdate ? existingModuleState.firstUpdate : moment().toISOString()
        const lastUpdate = moment().toISOString()
        return {
            [m.id!]: existingModuleState ? {...existingModuleState, status: "finished", firstUpdate, lastUpdate} : {
                status: "finished",
                points: 0,
                lastUpdate,
                firstUpdate
            }
        } as { [id: string]: ModuleState }

    }).reduce((a, b) => ({...a, ...b} as { [id: string]: ModuleState }), {})

    const lessonsInFinishedModules = modulesToFinish.flatMap(m => m.entity.lessonsRef);
    const lessonsBeforeCurrentLessonInCurrentModule = currentModule ? [...currentModule.entity.lessonsRef].slice(0, currentModule.entity.lessonsRef.findIndex(l => l === newCurrentLesson)) : []

    const finishedLessonState = (lessonsInFinishedModules.concat(lessonsBeforeCurrentLessonInCurrentModule)).map(l => {
        const existingLessonState = oldState.lessonStati[l]
        const firstUpdate = existingLessonState && existingLessonState.firstUpdate ? existingLessonState.firstUpdate : moment().toISOString()
        const lastUpdate = moment().toISOString()
        const firstSubmittedAt = existingLessonState && existingLessonState.firstSubmittedAt ? existingLessonState.firstSubmittedAt : undefined
        return {
            [l]: existingLessonState ? {...existingLessonState, status: "finished", firstUpdate, lastUpdate} : {
                status: "finished",
                points: 0,
                lastUpdate,
                firstUpdate,
                firstSubmittedAt
            }
        } as { [id: string]: LessonState }

    }).reduce((a, b) => ({...a, ...b} as { [id: string]: LessonState }), {})

    const currentModuleState: { [moduleId: string]: ModuleState } = currentModule ? {
        [currentModule.id!]: (oldState.moduleStati[currentModule.id!] ? {
            ...oldState.moduleStati[currentModule.id!],
            status: "current",
            lastUpdate: moment().toISOString(),
            firstUpdate: oldState && oldState.moduleStati[currentModule.id!].firstUpdate ? oldState.moduleStati[currentModule.id!].firstUpdate : moment().toISOString()
        } : {status: "current", points: 0, lastUpdate: moment().toISOString(), firstUpdate: moment().toISOString()})
    } : {}

    const currentLessonState: { [lessonId: string]: LessonState } = currentModule ? {
        [newCurrentLesson!]: (oldState.lessonStati[newCurrentLesson!] ? {
            ...oldState.lessonStati[newCurrentLesson!],
            status: "current",
            lastUpdate: moment().toISOString(),
            firstUpdate: oldState && oldState.lessonStati[newCurrentLesson!].firstUpdate ? oldState.lessonStati[newCurrentLesson!].firstUpdate : moment().toISOString()
        } : {status: "current", points: 0, lastUpdate: moment().toISOString(), firstUpdate: moment().toISOString()})
    } : {}

    const newLessonStati = {
        ...finishedLessonState,
        ...currentLessonState
    };
    return {
        ...oldState,
        moduleStati: {
            ...finishedModuleStati,
            ...currentModuleState
        },
        lessonStati: newLessonStati,
        progress: calculateProgress(course, newLessonStati)

    }
}
