import React, {useContext} from "react";
import {Button} from "primereact/button";
import {DiscountUsageSearchContext} from "./DiscountUsageSearchContext";
import moment from "moment/moment";
import {Calendar} from "primereact/calendar";
import {useTranslation} from "react-i18next";

export const DiscountUsageFilter = () => {
    const {t} = useTranslation("billingV3")
    const discountUsageFilterNs = "billing.discount-code.usage.header"

    const {filters, setFilters, clearFilters} = useContext(DiscountUsageSearchContext)

    return <div className="flex flex-wrap align-items-center">
        <div className="mr-2">
            <Calendar dateFormat={"dd.mm.yy"}
                      value={filters.filterByUsedAtFrom ? moment(filters.filterByUsedAtFrom).toDate() : undefined}
                      showTime
                      className="mr-2"
                      placeholder={t(`${discountUsageFilterNs}.filter-by-used-at-from`)}
                      showButtonBar
                      onChange={(e) => setFilters({
                          ...filters,
                          filterByUsedAtFrom: e.value ? (moment(e.value as Date).format("YYYY-MM-DDTHH:mm:ss")) : undefined
                      })}/>
            <Calendar dateFormat={"dd.mm.yy"}
                      value={filters.filterByUsedToFrom ? moment(filters.filterByUsedToFrom).toDate() : undefined}
                      showTime
                      showButtonBar
                      placeholder={t(`${discountUsageFilterNs}.filter-by-used-to=from`)}
                      onChange={(e) => setFilters({
                          ...filters,
                          filterByUsedToFrom: e.value ? (moment(e.value as Date).format("YYYY-MM-DDTHH:mm:ss")) : undefined
                      })}/>
        </div>
        <Button
            className="h-2rem"
            tooltip={"Zurücksetzen"}
            icon="pi pi-times"
            onClick={() => clearFilters()}/>
    </div>
}

