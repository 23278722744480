import {
    EntityWrapper, FilterTerm, PropertyFilter,
} from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import { WithTranslation, withTranslation } from "react-i18next";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Trigger, YUP_SCHEMA_TRIGGER } from "../../core/model/trigger/trigger";
import { fetchTriggerDefinitionsAction } from "../../triggers/actions/fetch-trigger-definitions";
import { selectTriggerDefinitions } from "../../triggers/selectors";
import { TriggerField } from "../../triggers/components/TriggerField";
import { ENTITY_TYPE_TRIGGERED_UNLOCK, NEW_TRIGGERED_UNLOCK, TriggeredUnlock } from "../model/triggered-unlock";
import { PreparingAggregatedCourse, COURSE_ATTRIBUTE_PUBLISHED } from "../../course/model/preparing-aggregated-course";
import { ENTITY_TYPE_COURSE_MODULE } from "@thekeytechnology/thekey-academy-frontend-library";
import { LoadingEditHeader } from "../../core/components/header/LoadingEditHeader";
import { EditHeaderWithBackground } from "../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../core/components/containers/Container";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { AsyncEntitySelectField } from "../../core/components/entity/AsyncEntitySelectField";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../core/components/entity/WithSingleEntityFromPath";

type Props =
    WithSingleEntityFromPathProps<TriggeredUnlock, TriggeredUnlock>
    & WithTranslation;

interface FormState {
    internalTitle: string,
    trigger?: Trigger,
    module: any
}

const EditTriggeredUnlockComponent = (props: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTriggerDefinitionsAction())
    }, [dispatch])

    const definitions = useSelector(selectTriggerDefinitions)

    const {entity, upsertEntity, t} = props;

    return (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.triggered-unlock.singular")}/>

            {entity && definitions ?
                <Formik<FormState>
                    enableReinitialize={true}
                    initialValues={{
                        internalTitle: entity.entity.internalTitle,
                        trigger: entity.entity.trigger,
                        module: {id: entity.entity.moduleRef},
                    }}
                    validationSchema={Yup.object().shape({
                        trigger: YUP_SCHEMA_TRIGGER(definitions, t),
                        internalTitle: Yup.string().required(t("entity.triggered-unlock.labels.internal-title") + " " + t("translation:is a required field")),
                        module: Yup.string().required(t("entity.triggered-unlock.labels.module") + " " + t("translation:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const unlockForSaving = new EntityWrapper(entity.id,
                            {
                                internalTitle: values.internalTitle,
                                trigger: values.trigger,
                                moduleRef: values.module.id ? values.module.id : values.module
                            } as TriggeredUnlock
                        );
                        upsertEntity(unlockForSaving);
                        setSubmitting(false);
                    }}
                >
                    {formikState => {
                        return (
                            <Form>
                                <EditHeaderWithBackground
                                    heading={t("edit-triggered-unlock.heading")}
                                    leadText={<>{formikState.values.internalTitle}</>}>
                                    <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                        backPath="/unlocks"/>
                                </EditHeaderWithBackground>
                                <ContentContainer>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-unlock.labels.internal-title")}
                                                        name="internalTitle"
                                                        formikState={formikState}
                                                        type="text"
                                                        placeholder={t("entity.triggered-unlock.labels.internal-title-placeholder")}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-unlock.labels.module")}
                                                        name="module"
                                                        formikState={formikState}
                                                        additionalFilters={[
                                                            new PropertyFilter("entity.releaseInformation.courseAttributes", new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, COURSE_ATTRIBUTE_PUBLISHED))
                                                        ]}
                                                        shownEntityType={ENTITY_TYPE_COURSE_MODULE}
                                                        shownEntityTypeProperties={["entity.containerMeta.title"]}
                                                        listRenderer={(item: EntityWrapper<PreparingAggregatedCourse>) => item?.entity?.containerMeta?.title}
                                                        component={AsyncEntitySelectField}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-unlock.labels.trigger")}
                                                        name="trigger"
                                                        formikState={formikState}
                                                        definitions={definitions}
                                                        component={TriggerField}
                                                        required
                                        />
                                    </div>

                                </ContentContainer>
                            </Form>
                        );
                    }}
                </Formik> : null}
        </>
    );
};

export const EditTriggeredUnlock = WithSingleEntityFromPath<{}, TriggeredUnlock, TriggeredUnlock>(
    withTranslation(["unlocks", "triggers"])(EditTriggeredUnlockComponent),
    ENTITY_TYPE_TRIGGERED_UNLOCK,
    "unlockId",
    NEW_TRIGGERED_UNLOCK,
    undefined
);
