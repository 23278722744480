/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UsageInvoiceColumn_DiscountCodeUsageFragment = {
    readonly order: {
        readonly invoiceData: {
            readonly invoiceNumber: string | null;
        } | null;
    } | null;
    readonly " $refType": "UsageInvoiceColumn_DiscountCodeUsageFragment";
};
export type UsageInvoiceColumn_DiscountCodeUsageFragment$data = UsageInvoiceColumn_DiscountCodeUsageFragment;
export type UsageInvoiceColumn_DiscountCodeUsageFragment$key = {
    readonly " $data"?: UsageInvoiceColumn_DiscountCodeUsageFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"UsageInvoiceColumn_DiscountCodeUsageFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageInvoiceColumn_DiscountCodeUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "order",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvoiceData",
          "kind": "LinkedField",
          "name": "invoiceData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invoiceNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DiscountCodeUsage",
  "abstractKey": null
};
(node as any).hash = 'e42abf0d048b7c0fb559d73c64bf8107';
export default node;
