import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_ACCOUNT_META_ADMIN = "fetch-account-meta-admin";

export const fetchAccountMetaAction = (accountId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_ACCOUNT_META_ADMIN,
            callType: API_FETCH_ACCOUNT_META_ADMIN
        },
        payload: accountId,
    } as ApiCallAction<string>;
};
