import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Account, ApiCallState, EntityWrapper, Pagination } from "@thekeytechnology/framework-react";
import React from "react";
import { useTranslation } from "react-i18next";
import "./accounts-table.scss";
import { UnconnectedPaginationInfo } from "../../../core/components/pagination/UnconnectedPaginationInfo";
import { AboveTableContainer } from "../../../core/components/containers/Container";
import { Table, TableColumn, TableHeader, TableRow } from "../../../core/components/table/Table";
import { AsyncEntityTableBody } from "../../../core/components/table/AsyncEntityTableBody";
import { ActionLink } from "../../../core/components/icon-link-tooltip/ActionLink";
import { ActionTableColumn } from "../../../core/components/table/ActionTableColumn";

interface OwnProps {
    entities: EntityWrapper<Account>[]
    pagination: Pagination
    apiCallState: ApiCallState
}

export const AccountsTable = ({
                                  entities,
                                  apiCallState,
                                  pagination
                              }: OwnProps) => {
    const {t} = useTranslation("admin")

    return <>
        <AboveTableContainer>
            <UnconnectedPaginationInfo pagination={pagination} className="mr-auto"/>
        </AboveTableContainer>

        <Table itemCount={entities.length} className="accounts-table">
            <TableHeader entities={entities}>
                <TableColumn>{t("entity.account.labels.id")}</TableColumn>
                <TableColumn>{t("entity.account.labels.name")}</TableColumn>
            </TableHeader>
            <AsyncEntityTableBody
                apiCallState={apiCallState}
                items={entities}
                renderItem={account =>
                    <TableRow
                        key={account.id}>
                        <TableColumn className="bold title-column">
                            {account.id}
                        </TableColumn>
                        <TableColumn className="bold title-column">
                            {account.entity.name}
                        </TableColumn>
                        <ActionTableColumn>
                            <ActionLink to={`/accounts/${account.id}/edit`} icon={faSearch}
                                        tooltip={""}/>
                        </ActionTableColumn>
                    </TableRow>
                }/>
        </Table>
    </>

};

