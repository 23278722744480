import {
    API_CREATE_ENTITIES,
    API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    matchesApiSuccess,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import {push} from "connected-react-router";
import {Action} from "redux";
import {combineEpics} from "redux-observable";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {NotificationConfiguration} from "../model/notification-configuration";
import {ENTITY_TYPE_TRIGGERED_NOTIFICATION} from "../model/triggered-notification";
import { redirectAfterApiSuccesses } from "../../core/epics/redirects";

const redirectOnNotificationsSave$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_UPDATE_ENTITIES, NotificationConfiguration.TYPE),
    map(() => push("/notifications"))
);

const redirectOnTriggeredNotificationSave$ = redirectAfterApiSuccesses(
    [
        {apiType: API_UPDATE_ENTITIES, callType: ENTITY_TYPE_TRIGGERED_NOTIFICATION},
        {apiType: API_CREATE_ENTITIES, callType: ENTITY_TYPE_TRIGGERED_NOTIFICATION}
    ], "/notifications")

const reloadOnDelete = reloadOnEntityApiOperation(
    API_DELETE_ENTITIES,
    ENTITY_TYPE_TRIGGERED_NOTIFICATION
)

export const notificationModuleEpics$ = combineEpics(
    redirectOnTriggeredNotificationSave$,
    reloadOnDelete,
    redirectOnNotificationsSave$
);
