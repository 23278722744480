import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_USER_ORDERS_ADMIN = "fetch-orders-admin";

export const fetchUserOrdersAdminAction = (userId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_USER_ORDERS_ADMIN,
            callType: API_FETCH_USER_ORDERS_ADMIN
        },
        payload: userId,
    } as ApiCallAction<string>;
};
