/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiscountTypeKind = "system" | "user" | "%future added value";
export type ActionTypeColumn_DiscountActionFragment = {
    readonly discountType: {
        readonly kind?: DiscountTypeKind;
    };
    readonly " $refType": "ActionTypeColumn_DiscountActionFragment";
};
export type ActionTypeColumn_DiscountActionFragment$data = ActionTypeColumn_DiscountActionFragment;
export type ActionTypeColumn_DiscountActionFragment$key = {
    readonly " $data"?: ActionTypeColumn_DiscountActionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ActionTypeColumn_DiscountActionFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActionTypeColumn_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "discountType",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "SystemDiscountType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "UserDiscountType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};
})();
(node as any).hash = '1a3d2235b5f6e58f45ca88c56b6fbd60';
export default node;
