import React, { Suspense } from "react";
import * as Sentry from "@sentry/react";
import { ThroughProvider } from "react-through";
import { Provider } from "react-redux";
import { RelayEnvironmentProvider } from "react-relay";
import { RelayEnvironment } from "./RelayEnvironment";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createEpicMiddleware } from "redux-observable";
import { loadState, saveState } from "./localStorage";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { appReducers } from "./_modules/reducers";
import throttle from "lodash.throttle";
import { appEpics } from "./_modules/epics";
import { MainNavigation } from "./_modules/MainNavigation";
import { MessageDisplay } from "./_modules/core/components/message/MessageDisplay";
import { DialogPortal } from "./_modules/core/components/dialog/DialogPortal";

const isProduction = process.env.APP_ENVIRONMENT === "prod";
const isStaging = process.env.APP_ENVIRONMENT === "staging";

export const history = createBrowserHistory();
const epicMiddleware = createEpicMiddleware();

const persistedState = loadState();

const middlewares = applyMiddleware(
    routerMiddleware(history), // for dispatching history actions
    epicMiddleware,
);

const storeEnhancers = isProduction ? middlewares : composeWithDevTools({
    trace: true,
})(middlewares);

const store = createStore(
    appReducers(history, process.env.REACT_APP_API_BASE!), // root reducer with router state
    persistedState,
    storeEnhancers,
);

store.subscribe(throttle(() => {
    saveState({
        auth: {
            authState: store.getState().auth.authState
        },
    });
}, 1000));

epicMiddleware.run(appEpics);

if (isProduction || isStaging) {
    Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});
}

export const App = () => {
    return <Sentry.ErrorBoundary>
        <ThroughProvider>
            <Provider store={store}>
                <Suspense fallback="loading">
                    <RelayEnvironmentProvider environment={RelayEnvironment(store)}>
                        <MessageDisplay/>
                        <ConnectedRouter history={history}>
                            <MainNavigation/>
                        </ConnectedRouter>
                        <DialogPortal/>
                    </RelayEnvironmentProvider>
                </Suspense>
            </Provider>
        </ThroughProvider>
    </Sentry.ErrorBoundary>
}

