import { EntityWrapper } from "@thekeytechnology/framework-react";
import * as _ from "lodash";
import React from "react";
import { ParameterDefinition } from "../../core/model/trigger/definitions/trigger-definition";
import { useTranslation } from "react-i18next";
import DateTimePicker from "react-datetime-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { BooleanSelect } from "../../core/components/form/BooleanSelect";
import { AsyncEntitySelect } from "../../core/components/entity/AsyncEntitySelect";

interface OwnProps {
    parameterDefinition: ParameterDefinition;
    parameterValue: any
    updateParameter: (definition: ParameterDefinition, value: any) => void;
}

export const ParameterInput = ({
                                   parameterDefinition,
                                   parameterValue,
                                   updateParameter
                               }: OwnProps) => {
    const {t} = useTranslation("triggers");

    switch (parameterDefinition.dataType) {
        case "reference":
            return <AsyncEntitySelect
                shownEntityType={parameterDefinition.referenceDefinition?.entityType!}
                shownEntityTypeProperties={parameterDefinition.referenceDefinition?.searchableFields!}
                selected={parameterValue ? {id: parameterValue.id ? parameterValue.id : parameterValue} as any : undefined}
                select={(newValue: any) => updateParameter(parameterDefinition, newValue ? (newValue as EntityWrapper<any>).id : undefined)}
                listRenderer={(item: any) =>
                    parameterDefinition.referenceDefinition!.labelFields.map(labelField => _.get(item, labelField)).filter(field => field !== undefined).join(" - ")
                }
                placeholder={t("trigger-field.trigger-parameters." + parameterDefinition.key)}
                additionalFilters={parameterDefinition.referenceDefinition?.filters}
            />
        case "multi-reference":
            return <AsyncEntitySelect
                shownEntityType={parameterDefinition.referenceDefinition?.entityType!}
                shownEntityTypeProperties={parameterDefinition.referenceDefinition?.searchableFields!}
                selected={parameterValue ? parameterValue.map((ex: any) => ({id: ex})) : []}
                select={(newValue: any) => updateParameter(parameterDefinition, (newValue as EntityWrapper<any>[]).map(ae => ae.id!))}
                listRenderer={(item: any) => parameterDefinition.referenceDefinition!.labelFields.map(labelField => _.get(item, labelField)).join(" - ")}
                placeholder={t("trigger-field.trigger-parameters." + parameterDefinition.key)}
                additionalFilters={parameterDefinition.referenceDefinition?.filters}
                isMulti={true}
            />
        case "string":
            return <input
                className="form-control default-input"
                required
                key={parameterDefinition.key}
                type="text"
                value={parameterValue}
                onChange={(event: any) => {
                    updateParameter(parameterDefinition, event.target.value)
                }}
                placeholder={t("trigger-field.trigger-parameters." + parameterDefinition.key)}
            />
        case "int":
            return <input
                className="form-control default-input"
                required
                key={parameterDefinition.key}
                type="number"
                step={1}
                value={parameterValue}
                onChange={(event: any) => {
                    updateParameter(parameterDefinition, parseInt(event.target.value, 10))
                }}
                placeholder={t("trigger-field.trigger-parameters." + parameterDefinition.key)}
            />
        case "dateTime":
            return <DateTimePicker
                showLeadingZeros={false}
                clearIcon={<FontAwesomeIcon icon={faTimes}/>}
                calendarIcon={<FontAwesomeIcon icon={faCalendarAlt}/>}
                value={parameterValue ? new Date(parameterValue) : null}
                onChange={(date: any) => updateParameter(parameterDefinition, date)}
            />
        case "boolean":
            return <BooleanSelect trueLabel={"Ja"}
                                  falseLabel={"Nein"} value={parameterValue}
                                  onChange={newValue => updateParameter(parameterDefinition, newValue)}/>
        default:
            return <div>{parameterDefinition.dataType} not supported!</div>;
    }

}
