import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { CourseElement } from "@thekeytechnology/thekey-academy-frontend-library";

export const EDIT_ELEMENT_ACTION = "edit-element";

export interface EditElementAction extends Action {
    element: EntityWrapper<CourseElement>
    parentModuleId: string;
    parentLessonId: string;
}

export const editElementAction = (parentModuleId: string, parentLessonId: string, element: EntityWrapper<CourseElement>) => ({
    type: EDIT_ELEMENT_ACTION,
    parentModuleId,
    parentLessonId,
    element
}) as EditElementAction;
