/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CourseSelect_QueryVariables = {};
export type CourseSelect_QueryResponse = {
    readonly Admin: {
        readonly Course: {
            readonly Courses: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly containerMeta: {
                            readonly title: string;
                        };
                    };
                } | null> | null;
            } | null;
        };
    };
};
export type CourseSelect_Query = {
    readonly response: CourseSelect_QueryResponse;
    readonly variables: CourseSelect_QueryVariables;
};



/*
query CourseSelect_Query {
  Admin {
    Course {
      Courses {
        edges {
          node {
            id
            containerMeta {
              title
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseAdminSchema",
        "kind": "LinkedField",
        "name": "Course",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoursesConnection",
            "kind": "LinkedField",
            "name": "Courses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoursesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Course",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContainerMeta",
                        "kind": "LinkedField",
                        "name": "containerMeta",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseSelect_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CourseSelect_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a041bfc89999bff675a37e1f3a682ef4",
    "id": null,
    "metadata": {},
    "name": "CourseSelect_Query",
    "operationKind": "query",
    "text": "query CourseSelect_Query {\n  Admin {\n    Course {\n      Courses {\n        edges {\n          node {\n            id\n            containerMeta {\n              title\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4e8e7238915bdd70dc28ba0ab05c9f82';
export default node;
