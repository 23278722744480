import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React, {Suspense} from "react";
import {OrdersTableV3} from "./OrdersTableV3";
import {OrdersScreenV3_Query} from "../../../../../__generated__/OrdersScreenV3_Query.graphql";
import {ProgressSpinner} from "primereact/progressspinner";

const QUERY = graphql`
    query OrdersScreenV3_Query ($firstName: String, $lastName: String, $orderNumber: String, $numItemsPerPage: Int! )  {
        ...OrdersTableV3_OrdersList @arguments(firstName: $firstName, lastName: $lastName, orderNumber: $orderNumber, first: $numItemsPerPage, after: null)
    }`

export const OrdersScreenV3 = () => {
    const ordersPerPage = 20
    const query = useLazyLoadQuery<OrdersScreenV3_Query>(QUERY, {
        firstName: "",
        lastName: "",
        orderNumber: "",
        numItemsPerPage: ordersPerPage
    })

    return <Suspense fallback={<ProgressSpinner/>}>
        <h1 className="mb-3">Bestellungen</h1>
        <OrdersTableV3 ordersFragmentRef={query} ordersPerPage={ordersPerPage}/>
    </Suspense>

}
