/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UsersScreen_QueryVariables = {
    first: number;
    after?: string | null;
    nameOrEmail?: string | null;
    userId?: string | null;
};
export type UsersScreen_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"UsersTable_UsersFragment">;
};
export type UsersScreen_Query = {
    readonly response: UsersScreen_QueryResponse;
    readonly variables: UsersScreen_QueryVariables;
};



/*
query UsersScreen_Query(
  $first: Int!
  $after: String
  $nameOrEmail: String
  $userId: String
) {
  ...UsersTable_UsersFragment_4pMajc
}

fragment UsersTable_UsersFragment_4pMajc on Query {
  Admin {
    User {
      Users(first: $first, after: $after, nameOrEmail: $nameOrEmail, userId: $userId) {
        edges {
          node {
            id
            rawId
            name
            email
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameOrEmail"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "nameOrEmail",
    "variableName": "nameOrEmail"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersScreen_Query",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "UsersTable_UsersFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "UsersScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAdminSchema",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "UsersConnection",
                "kind": "LinkedField",
                "name": "Users",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UsersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "nameOrEmail",
                  "userId"
                ],
                "handle": "connection",
                "key": "UsersTable_Users",
                "kind": "LinkedHandle",
                "name": "Users"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "64bdfbe90065031a63d922cc7d01dcec",
    "id": null,
    "metadata": {},
    "name": "UsersScreen_Query",
    "operationKind": "query",
    "text": "query UsersScreen_Query(\n  $first: Int!\n  $after: String\n  $nameOrEmail: String\n  $userId: String\n) {\n  ...UsersTable_UsersFragment_4pMajc\n}\n\nfragment UsersTable_UsersFragment_4pMajc on Query {\n  Admin {\n    User {\n      Users(first: $first, after: $after, nameOrEmail: $nameOrEmail, userId: $userId) {\n        edges {\n          node {\n            id\n            rawId\n            name\n            email\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f5c1407a4cf09907e581c5c7c3a09a63';
export default node;
