import { Form, Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { ENTITY_TYPE_ONBOARDING, NEW_ONBOARDING_FACTORY, Onboarding, OnboardingForSaving } from "../model/onboarding";
import { Account, EntityWrapper } from "@thekeytechnology/framework-react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { OnboardingPagesField } from "./OnboardingPagesField";

import TutorialImage from "./tutorial-onboarding.png"
import { LoadingEditHeader } from "../../core/components/header/LoadingEditHeader";
import { EditHeaderWithBackground } from "../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../core/components/containers/Container";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { AsyncEntitySelectField } from "../../core/components/entity/AsyncEntitySelectField";
import { CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY } from "../../core/components/entity/AsyncEntitySelect";
import { BooleanSelectField } from "../../core/components/select/BooleanSelectField";
import { LoadingRow } from "../../core/components/table/LoadingRow";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../core/components/entity/WithSingleEntityFromPath";

type Props = WithSingleEntityFromPathProps<Onboarding, OnboardingForSaving> & WithTranslation;

const EditOnboardingFormComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return entity ? (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.onboarding.singular")}/>

            <Formik
                initialValues={{
                    internalTitle: entity.entity.internalTitle,
                    accountRef: {id: entity.entity.accountRef},
                    useInAccountsRef: entity.entity.useInAccountsRef.map(id => ({id})),
                    pages: entity.entity.pages,
                    inheritToSlaveAccounts: entity.entity.inheritToSlaveAccounts
                }}
                validationSchema={Yup.object().shape({
                    internalTitle: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.onboarding.labels.internal-title")})),
                    accountRef: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.onboarding.labels.account")})),
                    pages: Yup.array().min(1).required(t("core:forms.required-field", {fieldName: t("entity.onboarding.labels.pages")})),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper(entity.id,
                        {
                            internalTitle: values.internalTitle,
                            accountRef: values.accountRef.id,
                            useInAccountsRef: values.useInAccountsRef ? values.useInAccountsRef.map(a => a.id!) : [],
                            inheritToSlaveAccounts: values.inheritToSlaveAccounts,
                            pages: values.pages
                        } as OnboardingForSaving
                    );
                    upsertEntity(saving);
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                formikState.values.internalTitle :
                                t("core:edit-header.heading-empty")}
                        >
                            <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                backPath="/onboardings/"/>
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.onboarding.labels.internalTitle")}
                                                type="text"
                                                name="internalTitle"
                                                className="form-control default-input"
                                                label={t("entity.onboarding.labels.internalTitle")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    label={t("entity.onboarding.labels.account")}
                                    name="accountRef"
                                    component={AsyncEntitySelectField}
                                    fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/api/admin/v1/accounts/list")}
                                    placeholder={t("entity.onboarding.labels.account")}
                                    listRenderer={(value: EntityWrapper<Account>) => value?.entity?.name}
                                    formikState={formikState}
                                    shownEntityType={"doesnt-matter"}
                                    shownEntityTypeProperties={["entity.name"]}
                                    isClearable={true}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    label={t("entity.onboarding.labels.use-in-accounts")}
                                    helpText={t("entity.onboarding.labels.use-in-accounts-help")}
                                    name="useInAccountsRef"
                                    component={AsyncEntitySelectField}
                                    fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/api/admin/v1/accounts/list")}
                                    placeholder={t("entity.onboarding.labels.use-in-accounts")}
                                    listRenderer={(value: EntityWrapper<Account>) => value?.entity?.name}
                                    formikState={formikState}
                                    shownEntityType={"doesnt-matter"}
                                    shownEntityTypeProperties={["entity.name"]}
                                    isMulti={true}
                                    isClearable={true}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    icon={faCheckCircle}
                                    label={t("entity.onboarding.labels.inherit-to-slave-accounts")}
                                    name="inheritToSlaveAccounts"
                                    formikState={formikState}
                                    component={BooleanSelectField}
                                    trueLabel={t("entity.onboarding.labels.inherit-to-slave-accounts-true")}
                                    falseLabel={t("entity.onboarding.labels.inherit-to-slave-accounts-false")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField
                                    label={t("entity.onboarding.labels.pages")}
                                    name="pages"
                                    component={OnboardingPagesField}
                                    formikState={formikState}
                                    files={entity.entity.files}
                                />
                            </div>
                            <img alt="Wie man das Formular richtig ausfüllt" src={TutorialImage}/>
                        </ContentContainer>
                    </Form>

                )}
            </Formik>
        </>
    ) : <LoadingRow/>;
};

export const EditOnboardingForm = WithSingleEntityFromPath<{}, Onboarding, OnboardingForSaving>(
    withTranslation(["onboarding", "core"])(EditOnboardingFormComponent),
    ENTITY_TYPE_ONBOARDING,
    "onboardingId",
    NEW_ONBOARDING_FACTORY,
    undefined,
    ENTITY_TYPE_ONBOARDING);
