import {
    API_CREATE_ENTITIES,
    API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import { combineEpics } from "redux-observable";
import { ENTITY_TYPE_RANK, ENTITY_TYPE_RANK_FOR_SAVING } from "../model/rank";
import { redirectAfterEntityApiOperation } from "../../core/epics/redirects";

export const rankModuleEpics$ = combineEpics(
    reloadOnEntityApiOperation(API_DELETE_ENTITIES, ENTITY_TYPE_RANK),
    redirectAfterEntityApiOperation([API_UPDATE_ENTITIES, API_CREATE_ENTITIES], ENTITY_TYPE_RANK_FOR_SAVING, "/ranks")
);
