import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { ENTITY_TYPE_OFFER_FOR_SAVING, NEW_OFFER_FACTORY, OfferForSaving } from "../model/OfferForSaving";
import { ENTITY_TYPE_OFFER, Offer } from "../model/Offer";
import { Course, ENTITY_TYPE_COURSE } from "../../../../course/model/preparing-aggregated-course";
import moment from "moment";
import { LoadingEditHeader } from "../../../../core/components/header/LoadingEditHeader";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";
import { EditHeaderWithBackground } from "../../../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../../../core/components/button/SaveAndBackButtons";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { ContentContainer } from "../../../../core/components/containers/Container";
import { DateField } from "../../../../core/components/datetime/DateField";
import { AsyncEntitySelectField } from "../../../../core/components/entity/AsyncEntitySelectField";
import { LoadingRow } from "../../../../core/components/table/LoadingRow";

type Props = WithSingleEntityFromPathProps<Offer, OfferForSaving> & WithTranslation;

const EditOfferFormComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return entity ? (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.offer.singular")}/>

            <Formik
                initialValues={{
                    title: entity.entity.title,
                    price: entity.entity.price,
                    discountPrice: entity.entity.discountPrice,
                    monthlyRate: entity.entity.monthlyRate,
                    rateCount: entity.entity.rateCount,
                    course: {id: entity.entity.courseId},
                    discountTitle: entity.entity.discountTitle,
                    discountText: entity.entity.discountText,
                    discountUntil: entity.entity.discountUntil,
                    taxRate: entity.entity.taxRate
                }}
                validationSchema={Yup.object().shape({
                    title: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.offer.labels.title")})),
                    price: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.offer.labels.price")})),
                    rateCount: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.offer.labels.rate-count")})),
                    monthlyRate: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.offer.labels.monthly-rate")})),
                    course: Yup.object().required(t("core:forms.required-field", {fieldName: t("entity.offer.labels.course")})),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper<OfferForSaving>(entity.id,
                        {
                            title: values.title,
                            price: values.price,
                            discountPrice: values.discountPrice,
                            monthlyRate: values.monthlyRate,
                            rateCount: values.rateCount,
                            taxRate: values.taxRate,
                            courseRef: values.course.id,
                            discountTitle: values.discountTitle,
                            discountText: values.discountText,
                            discountUntil: moment(values.discountUntil).format("YYYY-MM-DD")
                        }
                    );
                    upsertEntity(saving);
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                formikState.values.title :
                                t("core:edit-header.heading-empty")}
                        >
                            <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                backPath="/offers/"/>
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <div className="mb-2">
                                Alle Preise sind als Netto-Preise anzugeben.
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.title")}
                                                type="text"
                                                name="title"
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.title")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.price")}
                                                type="number"
                                                name="price"
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.price")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.tax-rate")}
                                                type="number"
                                                name="taxRate"
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.tax-rate")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.discount-price")}
                                                type="number"
                                                name="discountPrice"
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.discount-price")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.monthly-rate")}
                                                type="number"
                                                name="monthlyRate"
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.monthly-rate")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.rate-count")}
                                                type="number"
                                                name="rateCount"
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.rate-count")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.discount-title")}
                                                type="text"
                                                name="discountTitle"
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.discount-title")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.discount-text")}
                                                type="text"
                                                name="discountText"
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.discount-text")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.discount-until")}
                                                component={DateField}
                                                name="discountUntil"
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.discount-until")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.offer.labels.course")}
                                                name="course"
                                                component={AsyncEntitySelectField}
                                                shownEntityType={ENTITY_TYPE_COURSE}
                                                shownEntityTypeProperties={["entity.containerMeta.title"]}
                                                listRenderer={(item: EntityWrapper<Course>) => item?.entity?.containerMeta?.title}
                                                className="form-control default-input"
                                                label={t("entity.offer.labels.course")}
                                                required/>
                            </div>
                        </ContentContainer>
                    </Form>
                )}
            </Formik>
        </>
    ) : <LoadingRow/>;
};

export const EditOfferForm = WithSingleEntityFromPath<{}, Offer, OfferForSaving>(
    withTranslation(["billing", "core"])(EditOfferFormComponent),
    ENTITY_TYPE_OFFER,
    "offerId",
    NEW_OFFER_FACTORY,
    undefined,
    ENTITY_TYPE_OFFER_FOR_SAVING);
