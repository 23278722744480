import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useTranslation } from "react-i18next";
import { AccountsTable } from "./AccountsTable";
import { Account, usePaginatedEndpoint } from "@thekeytechnology/framework-react";
import { AccountFilters } from "./AccountFilters";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../../core/components/containers/Container";
import { SimpleHeader } from "../../../core/components/header/SimpleHeader";
import { UnconnectedPagination } from "../../../core/components/pagination/UnconnectedPagination";

export const AccountsOverview = () => {
    const {t} = useTranslation("admin");
    const {
        resolved: {entities, pagination},
        apiCallState,
        removeFilters,
        reload: reloadEntities,
        setFilters,
        filters: currentFilters,
        setPaginationQuery
    } = usePaginatedEndpoint<Account>("/api/admin/v1/accounts/list")

    return (
        <>
            <BreadcrumbsItem to={"/accounts"}>{t("entity.account.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.account.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <AccountFilters removeFilters={removeFilters} reloadEntities={reloadEntities} setFilters={setFilters}
                                filters={currentFilters}/>
            </BackgroundContainer>
            <ContentContainer>
                <AccountsTable pagination={pagination} entities={entities} apiCallState={apiCallState}/>
            </ContentContainer>
            <PaginationContainer>
                <UnconnectedPagination  pagination={pagination} setPage={page => {
                    setPaginationQuery({page, docsPerPage: 20})
                }}/>
            </PaginationContainer>
        </>
    );
};

