import { AppState } from "../../reducers";
import { createSelector } from "reselect";
import { UserManagementModuleState } from "../reducer";

export const selectUserManagementModuleState = (state: AppState) => state.userManagement;

export const selectUserAdmin = createSelector(
    selectUserManagementModuleState,
    (moduleState: UserManagementModuleState) =>
        moduleState.user
);

export const selectOrderAdmin = createSelector(
    selectUserManagementModuleState,
    (moduleState: UserManagementModuleState) =>
        moduleState.order
);

export const selectUserMetaAdmin = createSelector(
    selectUserManagementModuleState,
    (moduleState: UserManagementModuleState) =>
        moduleState.userMeta
);

export const selectOrdersAdmin = createSelector(
    selectUserManagementModuleState,
    (moduleState: UserManagementModuleState) =>
        moduleState.orders
);
export const selectCoursesAdmin = createSelector(
    selectUserManagementModuleState,
    (moduleState: UserManagementModuleState) =>
        moduleState.courses
);
export const selectCourseStatesAdmin = createSelector(
    selectUserManagementModuleState,
    (moduleState: UserManagementModuleState) =>
        moduleState.states
);

export const selectUserRolesAdmin = createSelector(
    selectUserManagementModuleState,
    (moduleState: UserManagementModuleState) =>
        moduleState.userRoles
);

export const selectAccountAdmin = createSelector(
    selectUserManagementModuleState,
    (moduleState: UserManagementModuleState) =>
        moduleState.account
);

export const selectAccountMetaAdmin = createSelector(
    selectUserManagementModuleState,
    (moduleState: UserManagementModuleState) =>
        moduleState.accountMeta
);

export const selectUsersInAccount = createSelector(
    selectUserManagementModuleState,
    (moduleState: UserManagementModuleState) =>
        moduleState.usersInAccount
);

export const selectLicensePoolAdmin = createSelector(
    selectUserManagementModuleState,
    (moduleState: UserManagementModuleState) =>
        moduleState.licensePool
);
