import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MultipleTextField } from "../../course/components/course-editor/elements/common/MultipleTextField";
import { GlossaryEntry } from "../model/glossary-entry";
import * as Yup from "yup";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../core/components/entity/WithSingleEntityFromPath";
import { EditHeaderWithBackground } from "../../core/components/edit/EditHeaderWithBackground";
import { ContentContainer } from "../../core/components/containers/Container";
import { SaveAndBackButtons } from "../../core/components/button/SaveAndBackButtons";
import { LoadingEditHeader } from "../../core/components/header/LoadingEditHeader";
import { LoadingRow } from "../../core/components/table/LoadingRow";

type Props = WithSingleEntityFromPathProps<GlossaryEntry, GlossaryEntry> & WithTranslation;

const EditGlossaryEntryFormComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return entity ? (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.glossary-entry.singular")}/>

            <Formik
                initialValues={{
                    title: entity.entity.title,
                    definition: entity.entity.definition,
                    additionalLinks: entity.entity.additionalLinks,
                }}
                validationSchema={Yup.object().shape({
                    title: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.glossary-entry.labels.title")})),
                    definition: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.glossary-entry.labels.definition")})),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper(entity.id,
                        new GlossaryEntry(
                            values.title,
                            values.definition,
                            values.additionalLinks,
                        )
                    );
                    upsertEntity(saving);
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                formikState.values.title :
                                t("core:edit-header.heading-empty")}
                        >
                            <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                backPath="/glossary/"/>
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.glossary-entry.labels.title")}
                                                name="title"
                                                className="form-control default-input"
                                                label={t("entity.glossary-entry.labels.title")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.glossary-entry.labels.definition")}
                                                name="definition"
                                                className="form-control default-input"
                                                label={t("entity.glossary-entry.labels.definition")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={MultipleTextField}
                                                name="additionalLinks"
                                                className="form-control default-input"
                                                placeholder={t("entity.glossary-entry.labels.additional-links")}
                                                addTooltip={t("entity.glossary-entry.labels.additional-links-add-tooltip")}
                                                removeTooltip={t("entity.glossary-entry.labels.additional-links-remove-tooltip")}
                                                label={t("entity.glossary-entry.labels.additional-links")}/>
                            </div>
                        </ContentContainer>
                    </Form>
                )}
            </Formik>
        </>
    ) : <LoadingRow/>;
};

export const EditGlossaryEntryForm = WithSingleEntityFromPath<any, GlossaryEntry, GlossaryEntry>(
    withTranslation(["glossary", "core"])(EditGlossaryEntryFormComponent),
    GlossaryEntry.TYPE,
    "glossaryEntryId", GlossaryEntry.new);
