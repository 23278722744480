import { UserWithRoles } from "@thekeytechnology/framework-react";
import { EditCustomerDefinitionForm } from "./components/customer-message-definition-editor/EditCustomerMessageDefinitionForm"
import { CustomerMessageSender } from "./components/customer-message-sender/CustomerMessageSender";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";
import {CustomerMessageDefinitionOverview} from "./components/CustomerMessageDefinitionOverview";

export const CustomerMessageRoutes: PrivateRouteDefinition[] = [
    {
        path: "/customer-message",
        component: CustomerMessageDefinitionOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    },
    {
        path: "/customer-message/:customerMessageId/edit",
        component: EditCustomerDefinitionForm,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/customer-message/:customerMessageDefinitionIdAndInternalTitles/send",
        component: CustomerMessageSender,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    }
];
