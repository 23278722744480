import React, {useState} from "react";
import { DiscountTypeKind } from "../../../../../../../__generated__/DiscountActionForm_DiscountActionFragment.graphql";

export interface DiscountActionFilters {
    filterByDiscountTypeKind?: DiscountTypeKind
    filterByActionTitle?: string
    filterByCode?: string
}


interface Search {
    filters: DiscountActionFilters
    setFilters: (filters: DiscountActionFilters) => void
    clearFilters: () => void
}

export const DiscountActionSearchContext = React.createContext<Search>({
    setFilters: () => {
    },
    clearFilters: () => {
    },
    filters: {}
})

interface OwnProps {
    children: any
}

export const DiscountActionSearchContainer = ({children}: OwnProps) => {
    const [state, setState] = useState<DiscountActionFilters>({})

    return <DiscountActionSearchContext.Provider value={{
        filters: state,
        setFilters: filters => {
            setState(() => filters)
        },
        clearFilters: () => {
            setState({})
        }
    }}>
        {children}
    </DiscountActionSearchContext.Provider>
}
