import React from "react";
import {InputText} from "primereact/inputtext";
import {RenderConfig} from "../ValidatedFieldV2";
import {classNames} from "primereact/utils";

export function DefaultTextFieldComponent({fieldName, fieldValue, updateField, isValid}: RenderConfig<string>) {
    return <InputText
        id={fieldName}
        name={fieldName}
        value={fieldValue}
        onChange={e => updateField(e.target.value)}
        className={classNames({"p-invalid": !isValid})}/>
}
