import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ENTITY_TYPE_RANK } from "../model/rank";
import { Filters } from "../../core/components/filters/Filters";
import { TextFilter } from "../../core/components/filters/TextFilter";

const ExpertFiltersComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <Filters entityType={ENTITY_TYPE_RANK}
                 filterKeys={[`entity.title`]}>
            <TextFilter
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.rank.labels.title")})}
                entityType={ENTITY_TYPE_RANK}
                property={`entity.title`}/>
        </Filters>
    );
};

export const RankFilters = withTranslation(["rank", "core"])(ExpertFiltersComponent);
