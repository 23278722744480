import { PaymentProviderData, SepaData } from "@thekeytechnology/thekey-academy-frontend-library";
import { ValueField } from "./ValueField";
import React from "react";
import { DateTimeDisplay } from "../../../../../core/components/datetime/DateTimeDisplay";

interface OwnProps {
    paymentProviderData: PaymentProviderData
}

export const OrderPaymentProviderDataDisplay = ({paymentProviderData}: OwnProps) => {

    switch (paymentProviderData.dataType) {
        case "sepa":
            return <>
                <ValueField label={"IBAN"} value={(paymentProviderData as SepaData).iban}/>
                <ValueField label={"Mandatsnummer"}
                            value={(paymentProviderData as SepaData).mandateInformation.mandateNumber}/>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Akzeptiert an</label>
                    <div className="col-sm-10 orm-control default-input">
                        <DateTimeDisplay
                            dateTime={(paymentProviderData as SepaData).mandateInformation.acceptanceDateTime}/>
                    </div>
                </div>
            </>
        default:

            return null;
    }

}
