import styled from "styled-components";

export const DownloadBillingCsvWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
`

export const LabelDisplay = styled.label`
  width: 200px;
`

export const DatetimePickerWrapper = styled.div`
  margin: 10px 0;
  width: 45%;
`

interface DownloadButtonProps {
    margin?: {
        left?: string,
        top?: string,
        right?: string,
        bottom?: string,
    }

    border?: {
        radius?: string,
        style?: string,
        color?: string,
        width?: string,
    }

    backgroundColor?: string

    isLoading?: boolean

}

export const DateSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectWrapper = styled.div`
  width: 50%;
  
  .css-yk16xz-control {
    height: 45px;
    border: solid 1px #e9eef8
  }
`


export const DownloadButton = styled.button<DownloadButtonProps>`
  position: relative;
  overflow: hidden;
  width: 250px;
  height: 40px;
  cursor: pointer;
  background-color: #17a2b8;
  color: white;
  border-radius: 5px;
  border: 2px solid #17a2b8;

  margin: ${({margin}: DownloadButtonProps) => margin ? `
  ${margin.top ?? 0} 
  ${margin.right ?? 0} 
  ${margin.bottom ?? 0}
  ${margin.left ?? 0} `
          : 0
  };

  &:after {
    content: 'Download';
  }

  &:focus {
    outline: none;
  }

  ${({isLoading}: DownloadButtonProps) => isLoading && `
    &:before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    content: '';
    z-index: 1;
    width: 250px;
    background: linear-gradient(to right, rgba(70, 136, 240, .2) 0%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .4) 100%);
    animation: slide 1.5s infinite;
    ) : ''}
  }

  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(340%);
    }
  `}
`
