import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {debounce} from "lodash";

interface State {
    value: string
}

interface OwnProps {
    refetch: (value: string) => void
}

export const CustomerMessageDefinitionV2TableFilter = ({refetch}: OwnProps) => {
    const {t: tCMV2} = useTranslation("customerMessagesV2")

    const [state, setState] = useState<State>({
        value: ""
    })

    const delayedRefetch = debounce((input: string) => refetch(input), 500)

    return <div className="flex pb-4">
        <span className="p-input-icon-left flex-grow-1">
            <i className={"pi pi-filter"}/>
            <InputText value={state.value} placeholder={tCMV2("overview.filter.placeholder")} className="w-full"
                       onChange={(e) => {
                           setState(state => ({...state, value: (e.target.value)}))
                           delayedRefetch(e.target.value)
                       }}/>
        </span>
        <Button className="ml-3" type="button" label={tCMV2("overview.filter.clear")}
                icon="pi pi-filter-slash"
                iconPos="right" onClick={_ => {
            setState(state => ({...state, value: ''}))
            refetch('')
        }}/>
    </div>
}