import { FieldProps } from "formik";
import React from "react";
import { replaceInArray } from "../../../core/utils/utils";
import "./unlock-component-field.scss"
import { UnlockUser } from "../../actions/mass-unlock";

type Props = FieldProps

export const UnlockComponentField = ({form, field}: Props) => {
    const value = field.value as UnlockUser[]

    return <div className="unlock-component-field">
        {value.map((unlockComponent, index) => {
            return <div className="mb-3" key={"unlock-component-" + index}>
                <label>
                    <div>Vorname</div>
                    <input
                        className="form-control default-input"
                        type="text" value={unlockComponent.firstName} onChange={(event) => {
                        form.setFieldValue(field.name, replaceInArray(
                            value,
                            index,
                            {
                                ...unlockComponent,
                                firstName: event.target.value
                            }
                        ))
                    }
                    }/>
                </label>
                <label>
                    <div>Nachname</div>
                    <input
                        className="form-control default-input"
                        type="text" value={unlockComponent.lastName} onChange={event => {
                        form.setFieldValue(field.name, replaceInArray(
                            value,
                            index,
                            {
                                ...unlockComponent,
                                lastName: event.target.value
                            }
                        ))
                    }
                    }/>
                </label>
                <label>
                    <div>Email</div>
                    <input
                        className="form-control default-input"
                        type="email" value={unlockComponent.email} onChange={event => {
                        form.setFieldValue(field.name, replaceInArray(
                            value,
                            index,
                            {
                                ...unlockComponent,
                                email: event.target.value
                            }
                        ))
                    }
                    }/>
                </label>
                <button type="button" className="btn btn-link text-danger"
                        onClick={() => form.setFieldValue(field.name, value.splice(index, 1))}>Entfernen
                </button>
                <hr/>
            </div>
        })}

        <button className="btn btn-secondary" type="button"
                onClick={() => form.setFieldValue(field.name, [...value, {firstName: "", lastName: "", email: ""}])}
        >Hinzufügen
        </button>
    </div>
}
