/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateCourseBundleInput = {
    data: CreateCourseBundle;
    clientMutationId?: string | null;
};
export type CreateCourseBundle = {
    title: string;
    bundleCourses: Array<string>;
    finalCourse: string;
};
export type EditCourseBundleScreen_CreateMutationVariables = {
    input: CreateCourseBundleInput;
    connections: Array<string>;
};
export type EditCourseBundleScreen_CreateMutationResponse = {
    readonly Admin: {
        readonly Bundle: {
            readonly createCourseBundle: {
                readonly data: {
                    readonly node: {
                        readonly " $fragmentRefs": FragmentRefs<"EditCourseBundleScreen_CourseBundleFragment">;
                    };
                };
            } | null;
        };
    };
};
export type EditCourseBundleScreen_CreateMutation = {
    readonly response: EditCourseBundleScreen_CreateMutationResponse;
    readonly variables: EditCourseBundleScreen_CreateMutationVariables;
};



/*
mutation EditCourseBundleScreen_CreateMutation(
  $input: CreateCourseBundleInput!
) {
  Admin {
    Bundle {
      createCourseBundle(input: $input) {
        data {
          node {
            ...EditCourseBundleScreen_CourseBundleFragment
            id
          }
        }
      }
    }
  }
}

fragment EditCourseBundleScreen_CourseBundleFragment on CourseBundle {
  id
  title
  bundleCourses {
    id
    containerMeta {
      title
    }
  }
  finalCourseId
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCourseBundleScreen_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BundleAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Bundle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCourseBundlePayload",
                "kind": "LinkedField",
                "name": "createCourseBundle",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseBundlesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseBundle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditCourseBundleScreen_CourseBundleFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditCourseBundleScreen_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BundleAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Bundle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCourseBundlePayload",
                "kind": "LinkedField",
                "name": "createCourseBundle",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseBundlesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseBundle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Course",
                            "kind": "LinkedField",
                            "name": "bundleCourses",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContainerMeta",
                                "kind": "LinkedField",
                                "name": "containerMeta",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "finalCourseId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ebf243879c6f4b069a820bfd77b21eb",
    "id": null,
    "metadata": {},
    "name": "EditCourseBundleScreen_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation EditCourseBundleScreen_CreateMutation(\n  $input: CreateCourseBundleInput!\n) {\n  Admin {\n    Bundle {\n      createCourseBundle(input: $input) {\n        data {\n          node {\n            ...EditCourseBundleScreen_CourseBundleFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditCourseBundleScreen_CourseBundleFragment on CourseBundle {\n  id\n  title\n  bundleCourses {\n    id\n    containerMeta {\n      title\n    }\n  }\n  finalCourseId\n}\n"
  }
};
})();
(node as any).hash = '2cc7b3e6e2c124f08f4f217d82b72d23';
export default node;
