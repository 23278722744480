import React from "react";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {ValidatedFieldV2} from "../../../core/components/form/ValidatedFieldV2";
import {DefaultTextFieldComponent} from "../../../core/components/form/forms/DefaultTextComponent";

interface State {
    userId?: string,
    nameOrEmail?: string
}

interface OwnProps {
    refetch: (userId?: string, nameOrEmail?: string) => void
}

export const UsersTableFilters = ({refetch}: OwnProps) => {
    const formik = useFormik<State>({
        initialValues: {
            userId: undefined,
            nameOrEmail: undefined
        },
        onSubmit: (values: State, {setSubmitting}) => {
            refetch(
                values.userId || undefined,
                values.nameOrEmail || undefined
            )
            setSubmitting(false)
        }
    })

    return <form onSubmit={formik.handleSubmit} className="p-fluid">
        <div className="flex flex-row">
            <div className="mr-2">
                <ValidatedFieldV2<State, string>
                    name={"userId"}
                    label="Nutzer ID"
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}
                />
            </div>
            <div className="mr-auto">
                <ValidatedFieldV2<State, string>
                    name={"nameOrEmail"}
                    label="Name oder E-Mail"
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}
                />
            </div>
            <Button
                disabled={false}
                type="submit"
                icon="pi pi-search"
                className="h-3rem"/>
        </div>
    </form>
}
