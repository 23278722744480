import {
    API_CREATE_ENTITIES,
    API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    ApiCallSucceededAction,
    EntitiesCreatedPayload,
    matchesApiSuccess,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import { push } from "connected-react-router";
import { Action } from "redux";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ADDITIONAL_CONTENT_TYPE } from "@thekeytechnology/thekey-academy-frontend-library";
import { redirectAfterEntityApiOperation } from "../../core/epics/redirects";

const redirectOnAdditionalContextCreation$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_CREATE_ENTITIES, ADDITIONAL_CONTENT_TYPE),
    map((action: ApiCallSucceededAction<EntitiesCreatedPayload>) => {
        return push(`/additional-content/${action.payload.ids[0]}/edit`);
    })
);

export const additionalContentModuleEpics$ = combineEpics(
    redirectOnAdditionalContextCreation$,
    reloadOnEntityApiOperation(API_DELETE_ENTITIES, ADDITIONAL_CONTENT_TYPE),
    redirectAfterEntityApiOperation([API_UPDATE_ENTITIES], ADDITIONAL_CONTENT_TYPE, "/additional-content")
);
