/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import DiscountCodeTable_Refetch from "./DiscountCodeTable_Refetch.graphql";
import { FragmentRefs } from "relay-runtime";
export type DiscountCodeTable_QueryFragment = {
    readonly node: {
        readonly discountCodes?: {
            readonly __id: string;
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly " $fragmentRefs": FragmentRefs<"DiscountCodeTable_DiscountCodeFragment">;
                };
            } | null> | null;
            readonly pageInfo: {
                readonly startCursor: string | null;
                readonly endCursor: string | null;
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
            };
        };
        readonly " $fragmentRefs": FragmentRefs<"DiscountCodeCodesTableHeader_DiscountActionFragment">;
    } | null;
    readonly " $refType": "DiscountCodeTable_QueryFragment";
};
export type DiscountCodeTable_QueryFragment$data = DiscountCodeTable_QueryFragment;
export type DiscountCodeTable_QueryFragment$key = {
    readonly " $data"?: DiscountCodeTable_QueryFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"DiscountCodeTable_QueryFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "node",
  "discountCodes"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": DiscountCodeTable_Refetch
    }
  },
  "name": "DiscountCodeTable_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "discountCodes",
              "args": null,
              "concreteType": "DiscountCodesV2Connection",
              "kind": "LinkedField",
              "name": "__DiscountCodeTable_discountCodes_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DiscountCodesV2Edge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DiscountCodeV2",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "DiscountCodeTable_DiscountCodeFragment",
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "CodeIdColumn_DiscountCodeFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "CodeCodeColumn_DiscountCodeFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "CodeNumUsagesColumn_DiscountCodeFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "CodeCreatedAtColumn_DiscountCodeFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "CodeActionColumn_DiscountCodeFragment"
                            }
                          ]
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DiscountCodeCodesTableHeader_DiscountActionFragment"
            }
          ],
          "type": "DiscountAction",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '2ef19dfbf56d89331a5220a58dd4e798';
export default node;
