import React from "react";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {DateTimeDisplay} from "../../../../../../core/components/datetime/DateTimeDisplay";
import {
    UsageUsedAtColumn_DiscountCodeUsageFragment$key
} from "../../../../../../../__generated__/UsageUsedAtColumn_DiscountCodeUsageFragment.graphql";

const DISCOUNT_CODE_USAGE_FRAGMENT = graphql`
    fragment UsageUsedAtColumn_DiscountCodeUsageFragment on DiscountCodeUsage {
        usedAt
    }
`

interface OwnProps {
    discountCodeUsageFragmentRef: UsageUsedAtColumn_DiscountCodeUsageFragment$key
}

export const UsageUsedAtColumn = ({discountCodeUsageFragmentRef}: OwnProps) => {
    const discountCodeUsage = useFragment<UsageUsedAtColumn_DiscountCodeUsageFragment$key>(DISCOUNT_CODE_USAGE_FRAGMENT, discountCodeUsageFragmentRef)

    return <DateTimeDisplay dateTime={discountCodeUsage.usedAt}/>
}
