import {PrivateRouteDefinition} from "../core/utils/PrivateRouteDefinition";
import {UserWithRoles} from "@thekeytechnology/framework-react";
import {CourseBundleScreen} from "./components/CourseBundleScreen";
import {EditCourseBundleScreen} from "./components/EditCourseBundleScreen";

export const CourseBundleModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/course-bundles",
        component: CourseBundleScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/course-bundles/:courseBundleId/edit",
        component: EditCourseBundleScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
];
