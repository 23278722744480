import React, {useState} from 'react'
import {useRefetchableFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {MultiSelect} from "primereact/multiselect";
import {useTranslation} from "react-i18next";
import {ProductsSelect_QueryFragment$key} from "../../../../__generated__/ProductsSelect_QueryFragment.graphql";
import {
    ProductsSelect_QueryFragmentRefetchQuery
} from "../../../../__generated__/ProductsSelect_QueryFragmentRefetchQuery.graphql";

const NUM_PRODUCTS_PER_PAGE = 20

const QUERY_FRAGMENT = graphql`
    fragment ProductsSelect_QueryFragment on Query @refetchable(queryName: "ProductsSelect_QueryFragmentRefetchQuery") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String", defaultValue: null},
        title: {type: "String", defaultValue: null},
        ids: {type :"[ID!]!"}
    ) {
        Admin {
            Billing {
                ProductsMultiselect(first: $first, after: $after, titleOpt: $title, ids: $ids) @connection(key: "ProductsSelect_ProductsMultiselect") {
                    edges {
                        cursor
                        node {
                            id
                            ... on Product{
                                title
                            }
                        }
                    }
                }
            }
        }
    }`

export interface Product {
    id: string
    title: string
}

interface OwnProps {
    fieldValue: string[]
    onChange: (products: string[]) => void
    queryFragmentRef: ProductsSelect_QueryFragment$key
}

export const ProductsSelect = ({
                                   fieldValue,
                                   onChange,
                                   queryFragmentRef
                               }: OwnProps) => {

    const {t} = useTranslation("core")

    const [data, refetch] = useRefetchableFragment<ProductsSelect_QueryFragmentRefetchQuery, ProductsSelect_QueryFragment$key>(QUERY_FRAGMENT, queryFragmentRef)

    const products = data?.Admin?.Billing.ProductsMultiselect.edges?.filter(e => !!e).map(e => e!.node) || []

    const [productsCache, setProductsCache] = useState<Product[]>(products.filter(product => fieldValue.includes(product.id)))

    const onFilter = (e: { filter: string }) => {
        refetch({title: e.filter, first: NUM_PRODUCTS_PER_PAGE, ids: fieldValue})
    }

    return <MultiSelect value={productsCache}
                        options={products}
                        optionLabel="title"
                        onHide={() => onChange(productsCache?.map((l: Product) => l.id) || [])}
                        onChange={(e) => setProductsCache(e.value)}
                        filter
                        resetFilterOnHide={true}
                        filterPlaceholder={t("entity-selects.filter-placeholder")}
                        className="multiselect-custom"
                        onFilter={onFilter}
                        showSelectAll={false}
                        emptyFilterMessage={t("entity-selects.no-results")}
    />
}
