/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditUserDataInput = {
    name: string;
    email: string;
    activated: boolean;
    userId: string;
    clientMutationId?: string | null;
};
export type EditUserData_EditUserDataMutationVariables = {
    input: EditUserDataInput;
};
export type EditUserData_EditUserDataMutationResponse = {
    readonly Admin: {
        readonly User: {
            readonly editUserData: {
                readonly user: {
                    readonly " $fragmentRefs": FragmentRefs<"EditUserData_UserFragment">;
                };
            } | null;
        };
    };
};
export type EditUserData_EditUserDataMutation = {
    readonly response: EditUserData_EditUserDataMutationResponse;
    readonly variables: EditUserData_EditUserDataMutationVariables;
};



/*
mutation EditUserData_EditUserDataMutation(
  $input: EditUserDataInput!
) {
  Admin {
    User {
      editUserData(input: $input) {
        user {
          ...EditUserData_UserFragment
          id
        }
      }
    }
  }
}

fragment EditUserData_UserFragment on User {
  id
  name
  email
  activated
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditUserData_EditUserDataMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAdminMutationSchema",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditUserDataPayload",
                "kind": "LinkedField",
                "name": "editUserData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EditUserData_UserFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditUserData_EditUserDataMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAdminMutationSchema",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditUserDataPayload",
                "kind": "LinkedField",
                "name": "editUserData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "activated",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6a044c1e95aef765301be784b0c96065",
    "id": null,
    "metadata": {},
    "name": "EditUserData_EditUserDataMutation",
    "operationKind": "mutation",
    "text": "mutation EditUserData_EditUserDataMutation(\n  $input: EditUserDataInput!\n) {\n  Admin {\n    User {\n      editUserData(input: $input) {\n        user {\n          ...EditUserData_UserFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment EditUserData_UserFragment on User {\n  id\n  name\n  email\n  activated\n}\n"
  }
};
})();
(node as any).hash = 'eacd544476620c514f7f26425e9ef93b';
export default node;
