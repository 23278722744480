import React, {useRef, useState} from "react";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {API_FETCH_SETTINGS, EntityWrapper} from "@thekeytechnology/framework-react";
import {Course, ENTITY_TYPE_COURSE} from "../../course/model/preparing-aggregated-course";
import {useTranslation} from "react-i18next";
import {SingleValueForm} from "../../core/components/form/SingleValueForm";
import {AsyncEntitySelectField} from "../../core/components/entity/AsyncEntitySelectField";
import {useLazyLoadQuery, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {
    SettingsScreen_SetAdvertisedCourseMutation
} from "../../../__generated__/SettingsScreen_SetAdvertisedCourseMutation.graphql";
import {
    SettingsScreen_SetGlobalDiscountMutation
} from "../../../__generated__/SettingsScreen_SetGlobalDiscountMutation.graphql";
import {SettingsScreen_Query} from "../../../__generated__/SettingsScreen_Query.graphql";
import {ClearAllCachesComponent} from "./ClearAllCachesComponent";
import {Button} from "primereact/button";
import {DiscountSelect} from "../../core/components/entity-selects/DiscountSelect";
import {Toast} from "primereact/toast";

const QUERY = graphql`
    query SettingsScreen_Query {
        Viewer {
            Settings {
                AdvertisedCourseWithoutState {
                    rawId
                }
            }
        }
        Admin {
            Settings {
                GlobalDiscount {
                    code
                    id
                }
            }
        }
        ... DiscountSelect_QueryFragment @arguments(ids: [])
    }`

const SET_ADVERTISED_COURSE_MUTATION = graphql`
    mutation SettingsScreen_SetAdvertisedCourseMutation($input: SetAdvertisedCourseInputInput!) {
        Admin {
            Settings {
                setAdvertisedCourse(input: $input){
                    clientMutationId
                }
            }
        }
    }`

const SET_GLOBAL_DISCOUNT_MUTATION = graphql`
    mutation SettingsScreen_SetGlobalDiscountMutation($input: SetGlobalDiscountInputInput!) {
        Admin {
            Settings {
                setGlobalDiscount(input: $input){
                    clientMutationId
                }
            }
        }
    }`

export const SettingsScreen = () => {
    const {t} = useTranslation("settings")

    const query = useLazyLoadQuery<SettingsScreen_Query>(QUERY, {})
    const toast = useRef<any>(null)

    const [discountCode, setDiscountCode] = useState<string | undefined>(query.Admin.Settings.GlobalDiscount?.id)

    const [setAdvertisedCourseMutation] = useMutation<SettingsScreen_SetAdvertisedCourseMutation>(SET_ADVERTISED_COURSE_MUTATION)
    const [setGlobalDiscountCode] = useMutation<SettingsScreen_SetGlobalDiscountMutation>(SET_GLOBAL_DISCOUNT_MUTATION)

    return <div className="mt-5">
        <Toast ref={toast} />
        <h1 className={"mb-5"}>{t("settings.title")}</h1>
        <SingleValueForm
            icon={faStar}
            label={t("settings.advertised-course.label")}
            apiQualifier={API_FETCH_SETTINGS}
            initialValue={query.Viewer.Settings.AdvertisedCourseWithoutState?.rawId ? {id: query.Viewer.Settings.AdvertisedCourseWithoutState?.rawId} : undefined}
            onSubmit={value => {
                setAdvertisedCourseMutation({
                    variables: {
                        input: {
                            advertisedCourseId: value?.id ? btoa("Course:" + value?.id) : undefined
                        }
                    },
                    onCompleted: () => window.location.reload()
                })
            }}
            component={AsyncEntitySelectField}
            shownEntityType={ENTITY_TYPE_COURSE}
            shownEntityTypeProperties={["entity.containerMeta.title"]}
            listRenderer={(item: EntityWrapper<Course>) => item.entity?.containerMeta.title}
            placeholder={t("settings.advertised-course.placeholder")}
            isClearable={true}
        />
        <div className="w-full">
            <div className="col-12 col-sm-2 pl-0">
                <label htmlFor="value" className="col-form-label">{t("settings.global-discount.label")}</label>
            </div>
            <DiscountSelect
                fieldValue={discountCode}
                onChange={discount => {
                    setDiscountCode(discount)
                }}
                queryFragmentRef={query}/>
            <Button
                disabled={false}
                type="button"
                onClick={() => setGlobalDiscountCode({
                    variables: {
                        input: {
                            discountCodeIdOpt: discountCode
                        }
                    },
                    onCompleted: () => toast.current?.show({
                        severity: "success",
                        life: 3000,
                        summary: t(`settings.global-discount.toast.success.summary`),
                        detail: t(`settings.global-discount.toast.success.detail`),
                    })
                })}
                icon="pi pi-save"
                className="ml-2"/>
        </div>
        <ClearAllCachesComponent/>
    </div>

}
