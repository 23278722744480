/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CourseBundleScreen_CourseBundlesQueryVariables = {};
export type CourseBundleScreen_CourseBundlesQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"CourseBundleTable_CourseBundleList">;
};
export type CourseBundleScreen_CourseBundlesQuery = {
    readonly response: CourseBundleScreen_CourseBundlesQueryResponse;
    readonly variables: CourseBundleScreen_CourseBundlesQueryVariables;
};



/*
query CourseBundleScreen_CourseBundlesQuery {
  ...CourseBundleTable_CourseBundleList
}

fragment CourseBundleTable_CourseBundleList on Query {
  Admin {
    Bundle {
      CourseBundles {
        pageInfo {
          endCursor
          hasPreviousPage
          hasNextPage
          startCursor
        }
        edges {
          node {
            id
            title
            __typename
          }
          cursor
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseBundleScreen_CourseBundlesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CourseBundleTable_CourseBundleList"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CourseBundleScreen_CourseBundlesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BundleAdminSchema",
            "kind": "LinkedField",
            "name": "Bundle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseBundlesConnection",
                "kind": "LinkedField",
                "name": "CourseBundles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseBundlesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseBundle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": [
                  "filterByTitle"
                ],
                "handle": "connection",
                "key": "CourseBundleTable_CourseBundles",
                "kind": "LinkedHandle",
                "name": "CourseBundles"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d68f39dde2080bdaede1a41f1a7c0a8b",
    "id": null,
    "metadata": {},
    "name": "CourseBundleScreen_CourseBundlesQuery",
    "operationKind": "query",
    "text": "query CourseBundleScreen_CourseBundlesQuery {\n  ...CourseBundleTable_CourseBundleList\n}\n\nfragment CourseBundleTable_CourseBundleList on Query {\n  Admin {\n    Bundle {\n      CourseBundles {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            title\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
(node as any).hash = '8d66bcc06490777de032807c4a19096b';
export default node;
