import React from "react";
import {Button} from "primereact/button";

interface OwnProps {
    hasNext: boolean
    loadNext: () => void
}

export const OrdersPaginationV3 = ({hasNext, loadNext}: OwnProps) => {
    return hasNext ? <div className="flex pt-4 justify-content-center">
        <Button label="Mehr laden..." onClick={loadNext}/>
    </div> : null
}