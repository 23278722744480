import { Table } from "react-bootstrap";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLicensePoolAdmin } from "../../../selectors";
import "./licenses-tables.scss"
import { AddLicenses } from "./AddLicenses";
import { NavLink } from "react-router-dom"
import { removeLicenseAction } from "../../../actions/license-pool/remove-license";
import { TableColumn, TableHeader, TableRow } from "../../../../core/components/table/Table";
import { NoDataRow } from "../../../../core/components/table/NoDataRow";
import { DateTimeDisplay } from "../../../../core/components/datetime/DateTimeDisplay";

export const LicensesTable = () => {
    const licensePoolContainer = useSelector(selectLicensePoolAdmin);
    const dispatch = useDispatch();

    return licensePoolContainer ? <>
        <h4 className="mt-3">Lizenzen im Pool</h4>
        <div className="licenses-table">
            <Table>
                <TableHeader>
                    <TableColumn>#</TableColumn>
                    <TableColumn>Limitiert auf Kurse</TableColumn>
                    <TableColumn>Bestellung</TableColumn>
                    <TableColumn>Verwendet</TableColumn>
                </TableHeader>
                {licensePoolContainer.licenses.length === 0 ? <NoDataRow/> : null}
                {licensePoolContainer.licenses.map((license, index) => {
                    return <TableRow key={license.id}>
                        <TableColumn>{index + 1}</TableColumn>
                        <TableColumn>
                            {license.entity.limitedToCoursesRef ? license.entity.limitedToCoursesRef
                                    .map(courseId => licensePoolContainer?.referredCourses.find(c => c.id === courseId)?.entity.containerMeta.title).join(", ")
                                : "Nicht limitiert"}
                        </TableColumn>
                        <TableColumn>
                            {license.entity.orderRef ?
                                <NavLink to={`/orders/${license.entity.orderRef}/edit`}>Zur
                                    Bestellung</NavLink> : "Keine Bestellung"}
                        </TableColumn>
                        <TableColumn>
                            {license.entity.usageData ? <>
                                <DateTimeDisplay
                                    dateTime={license.entity.usageData.usedAt}/> von {licensePoolContainer?.referredUsers.find(u => license.entity.usageData?.usedByUser === u.id)?.entity.name} für {licensePoolContainer?.referredCourses.find(c => c.id === license.entity.usageData?.usedForCourse)?.entity.containerMeta.title}
                            </> : "Offen"}
                        </TableColumn>
                        <TableColumn>
                            {license.entity.usageData ? null : <button
                                onClick={() => dispatch(removeLicenseAction(license.id!))}
                                type={"button"}
                                className="btn btn-danger">Löschen
                            </button>}
                        </TableColumn>
                    </TableRow>
                })}
            </Table>
        </div>

        <h4 className="mt-3">Lizenzen hinzufügen</h4>
        <AddLicenses/>
    </> : null;
}
