/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditProductScreen_ProductFragment = {
    readonly id: string;
    readonly title: string;
    readonly isHidden: boolean;
    readonly price: {
        readonly netPrice: number;
        readonly grossPrice: number;
        readonly taxRatePercentage: number;
    };
    readonly courseRef?: string;
    readonly " $refType": "EditProductScreen_ProductFragment";
};
export type EditProductScreen_ProductFragment$data = EditProductScreen_ProductFragment;
export type EditProductScreen_ProductFragment$key = {
    readonly " $data"?: EditProductScreen_ProductFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"EditProductScreen_ProductFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditProductScreen_ProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHidden",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceV3",
      "kind": "LinkedField",
      "name": "price",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grossPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxRatePercentage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        }
      ],
      "type": "CourseProduct",
      "abstractKey": null
    }
  ],
  "type": "Product",
  "abstractKey": "__isProduct"
};
(node as any).hash = 'd092d87aeea59680480369e1e948f4ff';
export default node;
