import React, {Suspense} from "react";
import {useTranslation} from "react-i18next";
import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import {Button} from "primereact/button";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {useFormik} from "formik";
import * as Yup from "yup";
import {InlineSingleAttachmentSelectV2} from "../../../../core/components/attachment/InlineSingleAttachmentSelectV2";
import {EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment$key} from "../../../../../__generated__/EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment.graphql";
import {EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutation} from "../../../../../__generated__/EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutation.graphql";
import {ProgressSpinner} from "primereact/progressspinner";
import {ValidatedFieldV2} from "../../../../core/components/form/ValidatedFieldV2";
import {DefaultTextFieldComponent} from "../../../../core/components/form/forms/DefaultTextComponent";

const CUSTOMER_MESSAGE_DEFINITION_PAGE_FRAGMENT = graphql`
    fragment EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment on CustomerMessageDefinitionPage {
        id
        internalTitle
        imageOpt {
            fileType
            id
            name
            rawId
            rawUrl
            thumbnailUrl
            uploadDateTime
            url
        }
    }`

const EDIT_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION = graphql`
    mutation EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutation($input: UpdateCustomerMessageDefinitionPageInput!) {
        Admin {
            CustomerMessageV2 {
                updateCustomerMessageDefinitionPage(input: $input) {
                    data {
                        node {
                            ...EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment
                        }
                    }
                }
            }
        }
    }`

interface CustomerMessageDefinitionPage {
    internalTitle: string
    image?: EntityWrapper<TkFile>
}

interface OwnProps {
    onClose: () => void
    customerMessageDefinitionPageFragmentRef: EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment$key
}

export const EditCustomerMessageDefinitionPageForm = ({
                                                          onClose,
                                                          customerMessageDefinitionPageFragmentRef
                                                      }: OwnProps) => {

    const {t: tCore} = useTranslation("core")
    const {t: tCMV2} = useTranslation("customerMessagesV2")

    const page = useFragment<EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment$key>(CUSTOMER_MESSAGE_DEFINITION_PAGE_FRAGMENT, customerMessageDefinitionPageFragmentRef)

    const [editPageRaw, isEditingPage] = useMutation<EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutation>(EDIT_CUSTOMER_MESSAGE_DEFINITION_PAGE_MUTATION)

    const editPage = (newPage: CustomerMessageDefinitionPage) => {
        editPageRaw({
            variables: {
                input: {
                    id: page.id,
                    data: {
                        internalTitle: newPage.internalTitle,
                        imageIdOpt: newPage.image?.id
                    }
                }
            }
        })
    }

    const formik = useFormik<CustomerMessageDefinitionPage>({
        initialValues: {
            internalTitle: page.internalTitle,
            image: page.imageOpt ? new EntityWrapper<TkFile>(page.imageOpt.rawId, new TkFile(page.imageOpt.name, page.imageOpt.fileType, page.imageOpt.uploadDateTime, page.imageOpt.url || "", page.imageOpt.rawUrl || "", page.imageOpt.thumbnailUrl || "")) : undefined
        },
        validationSchema: Yup.object().shape({
            internalTitle: Yup.string().required(tCore("core:forms.required-field", {fieldName: tCMV2("edit-customer-message-definition-v2.pages.edit-page.internal-title")})),
            image: Yup.string().required(tCore("core:forms.required-field", {fieldName: tCMV2("edit-customer-message-definition-v2.pages.edit-page.image")}))
        }),
        onSubmit: (values, {setSubmitting}) => {
            editPage(values)
            setSubmitting(false)
            onClose()
        }
    })

    return <Suspense fallback={<ProgressSpinner/>}>
        {!isEditingPage ? <form onSubmit={formik.handleSubmit} className="p-fluid">
            <ValidatedFieldV2<CustomerMessageDefinitionPage, string>
                name={"internalTitle"}
                label={tCMV2("edit-customer-message-definition-v2.pages.edit-page.internal-title")}
                required={true}
                component={({fieldName, fieldValue, updateField, isValid, required}) => <DefaultTextFieldComponent
                    fieldName={fieldName} fieldValue={fieldValue} updateField={updateField} isValid={isValid}
                    required={required}/>}
                formikConfig={formik}
            />

            <ValidatedFieldV2<CustomerMessageDefinitionPage, EntityWrapper<TkFile>>
                name={"image"}
                label={tCMV2("edit-customer-message-definition-v2.pages.edit-page.image")}
                required={true}
                component={({fieldValue, updateField}) => <InlineSingleAttachmentSelectV2
                    fieldValue={fieldValue}
                    updateField={updateField}
                />}
                formikConfig={formik}
            />

            <div className="flex pt-2">
                <Button
                    disabled={isEditingPage}
                    type="submit"
                    label={tCMV2("edit-customer-message-definition-v2.pages.edit-page.buttons.save")}
                    className="mr-5"
                />
                <Button
                    disabled={isEditingPage}
                    onClick={onClose}
                    label={tCMV2("edit-customer-message-definition-v2.pages.edit-page.buttons.close")}
                    className="ml-5 p-button-secondary"
                />
            </div>
        </form> : <ProgressSpinner/>}
    </Suspense>
}