import React, { Component } from "react";
import { BackgroundContainer } from "../containers/Container";

interface EditHeaderWithBackgroundProps {
    heading: React.ReactNode;
    leadText?: any;
}

export class EditHeaderWithBackground extends Component<EditHeaderWithBackgroundProps> {
    public render() {
        const {heading, leadText, children} = this.props;
        return (
            <BackgroundContainer className="edit-header d-flex flex-column flex-lg-row align-items-center">
                <div className="heading">
                    <h2 className="mb-0">{heading}</h2>
                    {leadText ? <p className="lead mt-2 mb-0">
                        {leadText}
                    </p> : null}
                </div>
                <div className="ml-lg-auto mt-3 mt-lg-0 d-flex align-items-center justify-content-center">
                    {children}
                </div>
            </BackgroundContainer>
        );
    }
}
