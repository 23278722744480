import { EditedCourseState } from "../../edited-course";
import { EditModuleAction } from "../../../actions/module/edit-module";
import update from "immutability-helper";
import { equalById } from "../common/helpers";

export const editModule = (state: EditedCourseState, {module}: EditModuleAction) => {
    const editModuleIndex = state.editedCourse!.entity.modules.findIndex(m => equalById(m, module));

    return update(state,
        {
            hasUnsavedChanges: {$set: true},
            editedCourse: {entity: {modules: {$splice: [[editModuleIndex, 1, module]]}}}
        }
    )
};
