/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CodeNumUsagesColumn_DiscountCodeFragment = {
    readonly numUsages: number;
    readonly " $refType": "CodeNumUsagesColumn_DiscountCodeFragment";
};
export type CodeNumUsagesColumn_DiscountCodeFragment$data = CodeNumUsagesColumn_DiscountCodeFragment;
export type CodeNumUsagesColumn_DiscountCodeFragment$key = {
    readonly " $data"?: CodeNumUsagesColumn_DiscountCodeFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CodeNumUsagesColumn_DiscountCodeFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodeNumUsagesColumn_DiscountCodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numUsages",
      "storageKey": null
    }
  ],
  "type": "DiscountCodeV2",
  "abstractKey": null
};
(node as any).hash = 'e12238ea27bc19ce10774b2bad97c1e5';
export default node;
