import { UserWithRoles } from "@thekeytechnology/framework-react";
import { EditGlossaryEntryForm } from "./components/EditGlossaryEntryForm";
import { GlossaryEntryOverview } from "./components/GlossaryEntryOverview";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";

export const GlossaryModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/glossary",
        component: GlossaryEntryOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    },
    {
        path: "/glossary/:glossaryEntryId/edit",
        component: EditGlossaryEntryForm,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    }
];
