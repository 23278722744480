import React from 'react';
import graphql from 'babel-plugin-relay/macro'
import {DataTable} from "primereact/datatable";
import {readInlineData, usePaginationFragment} from "react-relay";
import {
    DiscountCodeTable_DiscountCodeFragment$key
} from "../../../../../../__generated__/DiscountCodeTable_DiscountCodeFragment.graphql";
import {Column} from "primereact/column";
import {useTranslation} from "react-i18next";
import {CodeIdColumn} from "./columns/CodeIdColumn";
import {CodeCodeColumn} from "./columns/CodeCodeColumn";
import {CodeNumUsagesColumn} from "./columns/CodeNumUsagesColumn";
import {CodeCreatedAtColumn} from "./columns/CodeCreatedAtColumn";
import {DiscountCodeCodesTableHeader} from "./header/DiscountCodeCodesTableHeader";
import {CodeActionColumn} from "./columns/CodeActionColumn";
import {Button} from "primereact/button";
import {DiscountCodeTable_Refetch} from "../../../../../../__generated__/DiscountCodeTable_Refetch.graphql";

const QUERY_FRAGMENT = graphql`
    fragment DiscountCodeTable_QueryFragment on Query @refetchable(queryName: "DiscountCodeTable_Refetch") @argumentDefinitions(
        first: {type: "Int!"},
        after: {type: "String"},
        id: {type: "ID!"}
    ) {
        node(id: $id) {
            ... on DiscountAction {
                discountCodes(after: $after, first: $first) @connection(key: "DiscountCodeTable_discountCodes"){
                    __id
                    edges {
                        node {
                            ... DiscountCodeTable_DiscountCodeFragment
                        }
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasPreviousPage
                        hasNextPage
                    }
                }
                ... DiscountCodeCodesTableHeader_DiscountActionFragment
            }
        }
    }
`


const DISCOUNT_CODE_FRAGMENT = graphql`
    fragment DiscountCodeTable_DiscountCodeFragment on DiscountCodeV2 @inline {
        ...CodeIdColumn_DiscountCodeFragment
        ...CodeCodeColumn_DiscountCodeFragment
        ...CodeNumUsagesColumn_DiscountCodeFragment
        ...CodeCreatedAtColumn_DiscountCodeFragment
        ...CodeActionColumn_DiscountCodeFragment
    }
`


interface OwnProps {
    discountActionId: string
    query: DiscountCodeTable_DiscountCodeFragment$key
}

export const DiscountCodeTable = ({query, discountActionId}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const discountCodeTableNs = 'billing.discount-code.code'

    const {
        data: code,
        hasNext,
        loadNext,
    } = usePaginationFragment<DiscountCodeTable_Refetch, DiscountCodeTable_DiscountCodeFragment$key>(QUERY_FRAGMENT, query)

    return (
        <>
            <DataTable
                emptyMessage={t(`${discountCodeTableNs}.table.empty-message`)}
                className="mb-3"
                header={() => code.node &&
                    <DiscountCodeCodesTableHeader connectionId={code.node.discountCodes?.__id!}
                                                  discountActionFragmentRef={code.node}/>
                }
                value={code.node?.discountCodes?.edges?.filter((e: any) => !!(e?.node)).map((e: any) => e!.node).map((item: any) => readInlineData(DISCOUNT_CODE_FRAGMENT, item)) || []}>
                <Column header={t(`${discountCodeTableNs}.table.id`)}
                        body={item => <CodeIdColumn discountCodeFragmentRef={item}/>}/>
                <Column header={t(`${discountCodeTableNs}.table.code`)}
                        body={item => <CodeCodeColumn discountCodeFragmentRef={item}/>}/>
                <Column header={t(`${discountCodeTableNs}.table.num-usages`)}
                        body={item => <CodeNumUsagesColumn discountCodeFragmentRef={item}/>}/>
                <Column header={t(`${discountCodeTableNs}.table.created-at`)}
                        body={item => <CodeCreatedAtColumn discountCodeFragmentRef={item}/>}/>
                <Column header={t(`${discountCodeTableNs}.table.action`)}
                        body={item => <CodeActionColumn discountCodeFragmentRef={item}
                                                        connectionId={code.node?.discountCodes?.__id!}
                                                        discountActionId={discountActionId}/>}/>
            </DataTable>
            <div className="flex justify-content-center align-items-center">
                <Button
                    className="p-button-secondary"
                    disabled={!hasNext}
                    label="Weiter"
                    onClick={_ => loadNext(20)}/>
            </div>
        </>
    )
}
