import { FieldProps } from "formik";
import React from "react";
import { WithTranslation } from "react-i18next";
import { PhrasingAlternativesEditor } from "./PhrasingAlternativesEditor";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { PhrasingAlternatives } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    innerIcon?: IconDefinition;
}

type Props = OwnProps & FieldProps & WithTranslation;

export const PhrasingAlternativesField = ({form, field, innerIcon}: Props) => {

    return (
        <PhrasingAlternativesEditor
            icon={innerIcon}
            currentAlternatives={field.value}
            updatePhrasing={(update?: PhrasingAlternatives) => form.setFieldValue(field.name, update)}/>
    );
};
