/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UsageLimitationInput_QueryVariables = {
    accountIds: Array<string>;
    productIds: Array<string>;
};
export type UsageLimitationInput_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"AccountsSelectWithId_QueryFragment" | "ProductsSelect_QueryFragment">;
};
export type UsageLimitationInput_Query = {
    readonly response: UsageLimitationInput_QueryResponse;
    readonly variables: UsageLimitationInput_QueryVariables;
};



/*
query UsageLimitationInput_Query(
  $accountIds: [ID!]!
  $productIds: [ID!]!
) {
  ...AccountsSelectWithId_QueryFragment_3E5dtq
  ...ProductsSelect_QueryFragment_3vo7vN
}

fragment AccountsSelectWithId_QueryFragment_3E5dtq on Query {
  Admin {
    Auth {
      AccountsMultiselect(ids: $accountIds) {
        edges {
          cursor
          node {
            id
            name
            __typename
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}

fragment ProductsSelect_QueryFragment_3vo7vN on Query {
  Admin {
    Billing {
      ProductsMultiselect(ids: $productIds) {
        edges {
          cursor
          node {
            __typename
            id
            __isProduct: __typename
            title
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "accountIds"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "productIds"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsageLimitationInput_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "AccountsSelectWithId_QueryFragment"
      },
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "ProductsSelect_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsageLimitationInput_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AccountsConnection",
                "kind": "LinkedField",
                "name": "AccountsMultiselect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "nameOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "AccountsSelectWithId_AccountsMultiselect",
                "kind": "LinkedHandle",
                "name": "AccountsMultiselect"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "ProductsConnection",
                "kind": "LinkedField",
                "name": "ProductsMultiselect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isProduct"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": [
                  "titleOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "ProductsSelect_ProductsMultiselect",
                "kind": "LinkedHandle",
                "name": "ProductsMultiselect"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8823cd40f67bd9b323db753cbd7606e9",
    "id": null,
    "metadata": {},
    "name": "UsageLimitationInput_Query",
    "operationKind": "query",
    "text": "query UsageLimitationInput_Query(\n  $accountIds: [ID!]!\n  $productIds: [ID!]!\n) {\n  ...AccountsSelectWithId_QueryFragment_3E5dtq\n  ...ProductsSelect_QueryFragment_3vo7vN\n}\n\nfragment AccountsSelectWithId_QueryFragment_3E5dtq on Query {\n  Admin {\n    Auth {\n      AccountsMultiselect(ids: $accountIds) {\n        edges {\n          cursor\n          node {\n            id\n            name\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment ProductsSelect_QueryFragment_3vo7vN on Query {\n  Admin {\n    Billing {\n      ProductsMultiselect(ids: $productIds) {\n        edges {\n          cursor\n          node {\n            __typename\n            id\n            __isProduct: __typename\n            title\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bac8247f55265b87280875f7c157a8cf';
export default node;
