import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { CourseElement } from "@thekeytechnology/thekey-academy-frontend-library";

export const REMOVE_ELEMENT_ACTION = "remove-element";

export interface RemoveElementAction extends Action {
    element: EntityWrapper<CourseElement>
    parentModuleId: string;
    parentLessonId: string;
}

export const removeElementAction = (parentModuleId: string, parentLessonId: string, element: EntityWrapper<CourseElement>) => ({
    type: REMOVE_ELEMENT_ACTION,
    parentModuleId,
    parentLessonId,
    element
}) as RemoveElementAction;
