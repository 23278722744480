import { EntityWrapper } from "@thekeytechnology/framework-react";

export interface FAQ {
    question: string,
    answer: string
}

export const ENTITY_TYPE_FAQ = "faq"

export const newFAQ = (): EntityWrapper<FAQ> => new EntityWrapper<FAQ>(undefined, {
    question: "",
    answer: ""
});
