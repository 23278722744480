import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { removeMessageAction, selectMessages } from "@thekeytechnology/framework-react";
import "./message-display.scss";
import React from "react";

interface OwnProps {
    translationNamespace?: string;
}

export const MessageDisplay = ({translationNamespace}: OwnProps) => {
    const {t} = useTranslation(translationNamespace || "core")
    const messages = useSelector(selectMessages);
    const dispatch = useDispatch()

    if (!messages || messages.length === 0) {
        return null;
    }

    const messageDisplay = <div className="message-display w-100 d-flex flex-column">
        {messages.map(message =>
            <div key={message.id} className={`message d-flex ${message.messageType} alert-${message.messageType}`}>
                <div dangerouslySetInnerHTML={{__html: t(message.message)}}>
                </div>

                {/* eslint-disable-next-line */}
                <a className="ml-auto" onClick={() => dispatch(removeMessageAction(message.id))}>
                    <FontAwesomeIcon icon={faTimes}/>
                </a>
            </div>
        )}
    </div>;

    const element = document.querySelector("#messages");
    return element ? createPortal(
        messageDisplay,
        element
    ) : null;
};
