import {faCertificate, faHeading, faStop} from "@fortawesome/free-solid-svg-icons";
import {EntityWrapper, generateString, TkFile,} from "@thekeytechnology/framework-react";
import {Formik} from "formik";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import * as Yup from "yup";
import {Lesson} from "../../../model/lesson/lesson";
import {EditModalBase} from "../common/modal/EditModalBase";
import {connect} from "react-redux";
import {selectImage} from "../../../selectors";
import {TestLesson} from "../../../model/lesson/test-lesson";
import {SelectElementsField} from "./SelectElementsField";
import {InlineAttachmentField} from "../../../../core/components/attachment/InlineAttachmentField";
import {LESSON_TYPE_TEST} from "@thekeytechnology/thekey-academy-frontend-library";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { BooleanSelectField } from "../../../../core/components/select/BooleanSelectField";
import { WysiwygField } from "../../../../core/components/wysiwyg/WysiwygField";

interface OwnProps {
    lesson?: EntityWrapper<TestLesson>;

    onSubmit: (module: EntityWrapper<Lesson>) => void;
    onClose: () => void;
}

interface StateProps {
    image: EntityWrapper<TkFile> | undefined
}

type Props = OwnProps & StateProps & WithTranslation;

const EditTestLessonFormComponent = ({lesson, t, onSubmit, onClose, image}: Props) => {

    return (
        <Formik
            initialValues={{
                title: lesson ? lesson.entity.containerMeta.title : "",
                description: lesson ? lesson.entity.containerMeta.description : "",
                selectedElementsRef: lesson ? lesson.entity.selectedElementsRef : [],
                blockIfNotPassed: lesson ? lesson.entity.blockIfNotPassed : false,
                percentageOfPointsRequiredToPass: lesson ? lesson.entity.percentageOfPointsRequiredToPass : 75,
                image,
                isPartOfTrial: lesson ? lesson.entity.isPartOfTrial : false,
                isCertificationTest: lesson ? lesson.entity.isCertificationTest : false
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
                title: Yup.string().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-lesson-form.fields.title")})),
                description: Yup.string().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-lesson-form.fields.description")})),
            })}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(false);

                const temporaryId = lesson && lesson.temporaryId ? lesson.temporaryId : generateString(10);

                onSubmit(new EntityWrapper<TestLesson>(
                    lesson ? lesson.id : undefined,
                    {
                        containerMeta: {
                            title: values.title,
                            description: values.description,
                            length: lesson ? lesson.entity.containerMeta.length : 0,
                            points: lesson ? lesson.entity.containerMeta.points : 0
                        },
                        lessonType: LESSON_TYPE_TEST,
                        lessonPath: {courseRef: "", moduleRef: "", moduleIndex: -1, lessonIndex: -1},
                        isPartOfTrial: values.isPartOfTrial,
                        imageRef: values.image?.id,
                        elementsRef: lesson ? lesson.entity.elementsRef : [],
                        selectedElementsRef: values.selectedElementsRef,
                        percentageOfPointsRequiredToPass: values.percentageOfPointsRequiredToPass,
                        blockIfNotPassed: values.blockIfNotPassed,
                        versions: lesson ? lesson.entity.versions : {releaseVersion: -1, draftVersion: 0},
                        lessonFileRef: lesson ? lesson.entity.lessonFileRef : undefined,
                        releaseInformation: lesson ? lesson.entity.releaseInformation : undefined,
                        isCertificationTest: values.isCertificationTest
                    },
                    temporaryId
                ));
            }}
        >
            {formikState => (
                <EditModalBase handleClose={onClose}
                               formikState={formikState}
                               title={t("course-editor.edit-test-lesson-form.heading", {lessonId: lesson ? lesson.id : ""})}>
                    <div className="form-group row">
                        <ValidatedField
                            icon={faHeading}
                            label={t("course-editor.edit-lesson-form.fields.title")}
                            name="title"
                            formikState={formikState}
                            type="text"
                            placeholder={t("course-editor.edit-lesson-form.fields.title-placeholder")}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            icon={faCertificate}
                            label={t("course-editor.edit-lesson-form.fields.is-part-of-trial.label")}
                            name="isPartOfTrial"
                            formikState={formikState}
                            component={BooleanSelectField}
                            trueLabel={t("course-editor.edit-lesson-form.fields.is-part-of-trial.true")}
                            falseLabel={t("course-editor.edit-lesson-form.fields.is-part-of-trial.false")}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-lesson-form.fields.image")}
                            name="image"
                            formikState={formikState}
                            component={InlineAttachmentField}
                            isSingleSelect={true}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-lesson-form.fields.description")}
                            name="description"
                            component={WysiwygField}
                            formikState={formikState}
                            required
                        />
                    </div>

                    <div className="alert alert-warning mb-2">
                        Achtung: Ausschließlich Bausteine die HIER ausgewählt werden erscheinen im Test.
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-test-lesson-form.fields.elements")}
                            name="selectedElementsRef"
                            component={SelectElementsField}
                            formikState={formikState}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            icon={faStop}
                            label={t("course-editor.edit-test-lesson-form.fields.block-if-not-passed.label")}
                            name="blockIfNotPassed"
                            formikState={formikState}
                            component={BooleanSelectField}
                            trueLabel={t("course-editor.edit-test-lesson-form.fields.block-if-not-passed.true")}
                            falseLabel={t("course-editor.edit-test-lesson-form.fields.block-if-not-passed.false")}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-test-lesson-form.fields.percentage-of-points-required-to-pass")}
                            name="percentageOfPointsRequiredToPass"
                            type={"number"}
                            step={"1"}
                            formikState={formikState}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            icon={faCertificate}
                            label={t("course-editor.edit-lesson-form.fields.is-certification-test.label")}
                            trueLabel={t("course-editor.edit-lesson-form.fields.is-certification-test.true")}
                            falseLabel={t("course-editor.edit-lesson-form.fields.is-certification-test.false")}
                            name="isCertificationTest"
                            formikState={formikState}
                            component={BooleanSelectField}
                            required
                        />
                    </div>
                </EditModalBase>
            )}
        </Formik>
    );
};

export const EditTestLessonForm = connect<StateProps, {}, OwnProps>(
    (state: any, {lesson}: OwnProps) => ({
        image: selectImage(lesson?.entity.imageRef)(state)
    })
)(withTranslation(["courses", "core"])(EditTestLessonFormComponent));
