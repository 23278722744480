/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiscountCodeTable_DiscountCodeFragment = {
    readonly " $fragmentRefs": FragmentRefs<"CodeIdColumn_DiscountCodeFragment" | "CodeCodeColumn_DiscountCodeFragment" | "CodeNumUsagesColumn_DiscountCodeFragment" | "CodeCreatedAtColumn_DiscountCodeFragment" | "CodeActionColumn_DiscountCodeFragment">;
    readonly " $refType": "DiscountCodeTable_DiscountCodeFragment";
};
export type DiscountCodeTable_DiscountCodeFragment$data = DiscountCodeTable_DiscountCodeFragment;
export type DiscountCodeTable_DiscountCodeFragment$key = {
    readonly " $data"?: DiscountCodeTable_DiscountCodeFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"DiscountCodeTable_DiscountCodeFragment">;
};



const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "DiscountCodeTable_DiscountCodeFragment"
};
(node as any).hash = '3f9868d8fae02e1412e0ab9a4c78ca83';
export default node;
