/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrderStatus = "hasBillingDetails" | "hasCart" | "hasPaymentDetails" | "paymentReceived" | "purchased" | "transient" | "%future added value";
export type PaymentMethod = "card" | "giropay" | "iapApple" | "iapGoogle" | "invoicePartner" | "invoiceTk" | "monthlyPartner" | "monthlyTk" | "paypal" | "sepa" | "sofort" | "%future added value";
export type PaymentProviderDataType = "appleIap" | "default" | "googleIap" | "sepa" | "stripe" | "stripeSepa" | "%future added value";
export type PaymentProviderLinksV3_OrderFragment = {
    readonly paymentData: {
        readonly paymentMethod: PaymentMethod;
        readonly dataType: PaymentProviderDataType;
        readonly paymentIntentId?: string;
    } | null;
    readonly status: OrderStatus;
    readonly " $refType": "PaymentProviderLinksV3_OrderFragment";
};
export type PaymentProviderLinksV3_OrderFragment$data = PaymentProviderLinksV3_OrderFragment;
export type PaymentProviderLinksV3_OrderFragment$key = {
    readonly " $data"?: PaymentProviderLinksV3_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PaymentProviderLinksV3_OrderFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paymentIntentId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentProviderLinksV3_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "paymentData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethod",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "StripeData",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "StripeSepaData",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();
(node as any).hash = '43a8668507fbf4d5fe68da24ff3dfd37';
export default node;
