import { faCertificate, faHeading } from "@fortawesome/free-solid-svg-icons";
import { EntityWrapper, generateString, TkFile, } from "@thekeytechnology/framework-react";
import { Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { Lesson } from "../../../model/lesson/lesson";
import { EditModalBase } from "../common/modal/EditModalBase";
import { Expert } from "../../../../expert/model/expert";
import { connect } from "react-redux";
import { selectImage } from "../../../selectors";
import { NormalLesson } from "../../../model/lesson/normal-lesson";
import { GlossaryEntry } from "../../../../glossary/model/glossary-entry";
import { InlineAttachmentField } from "../../../../core/components/attachment/InlineAttachmentField";
import {
    ADDITIONAL_CONTENT_TYPE,
    AdditionalContent,
    LESSON_TYPE_NORMAL
} from "@thekeytechnology/thekey-academy-frontend-library";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { BooleanSelectField } from "../../../../core/components/select/BooleanSelectField";
import { WysiwygField } from "../../../../core/components/wysiwyg/WysiwygField";
import { AsyncEntitySelectField } from "../../../../core/components/entity/AsyncEntitySelectField";

interface OwnProps {
    lesson?: EntityWrapper<NormalLesson>;

    onSubmit: (module: EntityWrapper<Lesson>) => void;
    onClose: () => void;
}

interface StateProps {
    image: EntityWrapper<TkFile> | undefined
}

type Props = OwnProps & StateProps & WithTranslation;

interface FormikValues {
    title: string;
    description: string;
    glossary: EntityWrapper<GlossaryEntry>[] | { id: string }[];
    additionalExperts: EntityWrapper<Expert>[] | { id: string }[];
    additionalContent: EntityWrapper<AdditionalContent>[] | { id: string }[];
    image: EntityWrapper<TkFile> | undefined,
    isPartOfTrial: boolean
}

const EditNormalLessonFormComponent = ({lesson, t, onSubmit, onClose, image}: Props) => {

    return (
        <Formik<FormikValues>
            initialValues={{
                title: lesson ? lesson.entity.containerMeta.title : "",
                description: lesson ? lesson.entity.containerMeta.description : "",
                glossary: lesson ? lesson.entity.glossaryRef.map(ex => ({id: ex})) : [],
                additionalExperts: lesson ? lesson.entity.additionalExpertsRef.map(ex => ({id: ex})) : [],
                additionalContent: lesson ? lesson.entity.additionalContentRef.map(ex => ({id: ex})) : [],
                image,
                isPartOfTrial: lesson ? lesson.entity.isPartOfTrial : false,
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
                title: Yup.string().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-lesson-form.fields.title")})),
                description: Yup.string().required(t("core:forms.required-field", {fieldName: t("course-editor.edit-lesson-form.fields.description")})),
            })}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(false);

                const temporaryId = lesson && lesson.temporaryId ? lesson.temporaryId : generateString(10);

                onSubmit(new EntityWrapper<NormalLesson>(
                    lesson ? lesson.id : undefined,
                    {
                        containerMeta: {
                            title: values.title,
                            description: values.description,
                            length: lesson ? lesson.entity.containerMeta.length : 0,
                            points: lesson ? lesson.entity.containerMeta.points : 0
                        },
                        lessonType: LESSON_TYPE_NORMAL,
                        lessonPath: {courseRef: "", moduleRef: "", lessonIndex: -1, moduleIndex: -1},
                        isPartOfTrial: values.isPartOfTrial,
                        imageRef: values.image?.id,
                        elementsRef: lesson ? lesson.entity.elementsRef : [],
                        glossaryRef: (values.glossary as EntityWrapper<GlossaryEntry>[]).map(ae => ae.id!),
                        additionalExpertsRef: (values.additionalExperts as EntityWrapper<Expert>[]).map(ae => ae.id!),
                        additionalContentRef: (values.additionalContent as EntityWrapper<AdditionalContent>[]).map(ac => ac.id!),
                        versions: lesson ? lesson.entity.versions : {releaseVersion: -1, draftVersion: 0},
                        lessonFileRef: lesson ? lesson.entity.lessonFileRef : undefined,
                        releaseInformation: lesson ? lesson.entity.releaseInformation : undefined,
                    },
                    temporaryId
                ));
            }}
        >
            {formikState => (
                <EditModalBase handleClose={onClose}
                               formikState={formikState}
                               title={t("course-editor.edit-lesson-form.heading", {lessonId: lesson ? lesson.id : ""})}>
                    <div className="form-group row">
                        <ValidatedField
                            icon={faHeading}
                            label={t("course-editor.edit-lesson-form.fields.title")}
                            name="title"
                            formikState={formikState}
                            type="text"
                            placeholder={t("course-editor.edit-lesson-form.fields.title-placeholder")}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            icon={faCertificate}
                            label={t("course-editor.edit-lesson-form.fields.is-part-of-trial.label")}
                            name="isPartOfTrial"
                            formikState={formikState}
                            component={BooleanSelectField}
                            trueLabel={t("course-editor.edit-lesson-form.fields.is-part-of-trial.true")}
                            falseLabel={t("course-editor.edit-lesson-form.fields.is-part-of-trial.false")}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-lesson-form.fields.image")}
                            name="image"
                            formikState={formikState}
                            component={InlineAttachmentField}
                            isSingleSelect={true}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-lesson-form.fields.description")}
                            name="description"
                            component={WysiwygField}
                            formikState={formikState}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-lesson-form.fields.glossary")}
                            name="glossary"
                            component={AsyncEntitySelectField}
                            shownEntityType={GlossaryEntry.TYPE}
                            shownEntityTypeProperties={["entity.title"]}
                            listRenderer={(item: EntityWrapper<GlossaryEntry>) => item.entity?.title}
                            placeholder={t("course-editor.edit-lesson-form.fields.glossary")}
                            isMulti={true}
                            isClearable={true}
                            formikState={formikState}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-lesson-form.fields.additional-content")}
                            name="additionalContent"
                            component={AsyncEntitySelectField}
                            shownEntityType={ADDITIONAL_CONTENT_TYPE}
                            shownEntityTypeProperties={["entity.title"]}
                            listRenderer={(item: EntityWrapper<AdditionalContent>) => item.entity?.title}
                            placeholder={t("course-editor.edit-lesson-form.fields.additional-content")}
                            isMulti={true}
                            isClearable={true}
                            formikState={formikState}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-lesson-form.fields.additional-experts")}
                            name="additionalExperts"
                            component={AsyncEntitySelectField}
                            shownEntityType={Expert.TYPE}
                            shownEntityTypeProperties={["entity.name"]}
                            listRenderer={(item: EntityWrapper<Expert>) => item.entity?.name}
                            placeholder={t("course-editor.edit-lesson-form.fields.additional-experts-placeholder")}
                            isMulti={true}
                            isClearable={true}
                            formikState={formikState}
                        />
                    </div>
                </EditModalBase>
            )}
        </Formik>
    );
};

export const EditNormalLessonForm = connect<StateProps, {}, OwnProps>(
    (state: any, {lesson}: OwnProps) => ({
        image: selectImage(lesson?.entity.imageRef)(state)
    })
)(withTranslation(["courses", "core"])(EditNormalLessonFormComponent));
