/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateCustomerMessageDefinitionPageInput = {
    data: CustomerMessageDefinitionPageInput;
    id: string;
    clientMutationId?: string | null;
};
export type CustomerMessageDefinitionPageInput = {
    internalTitle: string;
    imageIdOpt?: string | null;
};
export type EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutationVariables = {
    input: UpdateCustomerMessageDefinitionPageInput;
};
export type EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutationResponse = {
    readonly Admin: {
        readonly CustomerMessageV2: {
            readonly updateCustomerMessageDefinitionPage: {
                readonly data: {
                    readonly node: {
                        readonly " $fragmentRefs": FragmentRefs<"EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment">;
                    };
                };
            } | null;
        };
    };
};
export type EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutation = {
    readonly response: EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutationResponse;
    readonly variables: EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutationVariables;
};



/*
mutation EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutation(
  $input: UpdateCustomerMessageDefinitionPageInput!
) {
  Admin {
    CustomerMessageV2 {
      updateCustomerMessageDefinitionPage(input: $input) {
        data {
          node {
            ...EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment
            id
          }
        }
      }
    }
  }
}

fragment EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment on CustomerMessageDefinitionPage {
  id
  internalTitle
  imageOpt {
    fileType
    id
    name
    rawId
    rawUrl
    thumbnailUrl
    uploadDateTime
    url
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateCustomerMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "updateCustomerMessageDefinitionPage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionPagesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageDefinitionPage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateCustomerMessageDefinitionPagePayload",
                "kind": "LinkedField",
                "name": "updateCustomerMessageDefinitionPage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionPagesEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageDefinitionPage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "imageOpt",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fileType",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rawId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rawUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumbnailUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "uploadDateTime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02b2372237865f6c49f6a2acb780b620",
    "id": null,
    "metadata": {},
    "name": "EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutation",
    "operationKind": "mutation",
    "text": "mutation EditCustomerMessageDefinitionPageForm_UpdateCustomerMessageDefinitionPageMutation(\n  $input: UpdateCustomerMessageDefinitionPageInput!\n) {\n  Admin {\n    CustomerMessageV2 {\n      updateCustomerMessageDefinitionPage(input: $input) {\n        data {\n          node {\n            ...EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment on CustomerMessageDefinitionPage {\n  id\n  internalTitle\n  imageOpt {\n    fileType\n    id\n    name\n    rawId\n    rawUrl\n    thumbnailUrl\n    uploadDateTime\n    url\n  }\n}\n"
  }
};
})();
(node as any).hash = '512ab97a9af60bc35439ed68677aaf8d';
export default node;
