import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Expert } from "../model/expert";
import * as Yup from "yup";
import { ExpertForSaving } from "../model/expert-for-saving";
import { InlineAttachmentField } from "../../core/components/attachment/InlineAttachmentField";
import { LoadingEditHeader } from "../../core/components/header/LoadingEditHeader";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../core/components/entity/WithSingleEntityFromPath";
import { ContentContainer } from "../../core/components/containers/Container";
import { SaveAndBackButtons } from "../../core/components/button/SaveAndBackButtons";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { EditHeaderWithBackground } from "../../core/components/edit/EditHeaderWithBackground";
import { WysiwygField } from "../../core/components/wysiwyg/WysiwygField";
import { LoadingRow } from "../../core/components/table/LoadingRow";

type Props = WithSingleEntityFromPathProps<Expert, ExpertForSaving> & WithTranslation;

const EditExpertFormComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return entity ? (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.expert.singular")}/>

            <Formik
                initialValues={{
                    name: entity.entity.name,
                    position: entity.entity.position,
                    biography: entity.entity.biography,
                    image: entity.entity.image
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.expert.labels.name")})),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper(entity.id,
                        new ExpertForSaving(
                            values.name,
                            "de",
                            values.image ? values.image.id : undefined,
                            values.position,
                            values.biography
                        )
                    );
                    upsertEntity(saving);
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                formikState.values.name :
                                t("core:edit-header.heading-empty")}
                        >
                            <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                backPath="/experts/"/>
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.expert.labels.name")}
                                                type="text"
                                                name="name"
                                                className="form-control default-input"
                                                label={t("entity.expert.labels.name")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.expert.labels.position")}
                                                type="text"
                                                name="position"
                                                className="form-control default-input"
                                                label={t("entity.expert.labels.position")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.expert.labels.image")}
                                                name="image"
                                                component={InlineAttachmentField}
                                                isSingleSelect={true}
                                                className="form-control default-input"
                                                label={t("entity.expert.labels.image")}/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                name="biography"
                                                component={WysiwygField}
                                                isSingleSelect={true}
                                                className="form-control default-input"
                                                label={t("entity.expert.labels.biography")}/>
                            </div>
                        </ContentContainer>
                    </Form>
                )}
            </Formik>
        </>
    ) : <LoadingRow/>;
};

export const EditExpertForm = WithSingleEntityFromPath<{}, Expert, ExpertForSaving>(
    withTranslation(["expert", "core"])(EditExpertFormComponent),
    Expert.TYPE,
    "expertId",
    Expert.new,
    undefined,
    ExpertForSaving.TYPE);
