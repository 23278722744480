import {
    API_CREATE_ENTITIES,
    API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import { combineEpics } from "redux-observable";
import { ENTITY_TYPE_OFFER } from "../v1/model/Offer";
import { ENTITY_TYPE_OFFER_FOR_SAVING } from "../v1/model/OfferForSaving";
import { ENTITY_TYPE_OFFER_V2, ENTITY_TYPE_VOUCHER } from "@thekeytechnology/thekey-academy-frontend-library";
import { redirectAfterEntityApiOperation } from "../../../core/epics/redirects";

export const billingModuleEpics$ = combineEpics(
    reloadOnEntityApiOperation(API_DELETE_ENTITIES, ENTITY_TYPE_OFFER),
    reloadOnEntityApiOperation(API_DELETE_ENTITIES, ENTITY_TYPE_OFFER_V2),
    reloadOnEntityApiOperation(API_DELETE_ENTITIES, ENTITY_TYPE_VOUCHER),
    redirectAfterEntityApiOperation([API_UPDATE_ENTITIES, API_CREATE_ENTITIES], ENTITY_TYPE_OFFER_FOR_SAVING, "/offers"),
    redirectAfterEntityApiOperation([API_UPDATE_ENTITIES, API_CREATE_ENTITIES], ENTITY_TYPE_OFFER_V2, "/offers-v2"),
    redirectAfterEntityApiOperation([API_UPDATE_ENTITIES, API_CREATE_ENTITIES], ENTITY_TYPE_VOUCHER, "/vouchers")
);
