import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_UPDATE_USER = "update-user";

export interface UpdateUser {
    userId: string
    name?: string
    email?: string
    isActivated?: boolean
}

export const updateUserAction = (userId: string, name?: string, email?: string, isActivated?: boolean) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_USER,
            callType: API_UPDATE_USER
        },
        payload: {
            userId,
            name,
            email,
            isActivated
        }
    } as ApiCallAction<UpdateUser>;
};
