import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_COPY_COURSE = "copy-course";

export interface CopyCoursePayload {
    courseId: string;
}

export const copyCourseAction = (courseId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_COPY_COURSE,
            callType: API_COPY_COURSE
        },
        payload: {
            courseId,
        }
    } as ApiCallAction<CopyCoursePayload>;
};
