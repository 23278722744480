/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TargetAudienceInputV2 = {
    selectAllUsers: boolean;
    excludeAdsOptIn?: boolean | null;
    selectAccountIds: Array<string>;
    selectUserIds: Array<string>;
    selectHaveBoughtCourseIds: Array<string>;
    selectHaveNotBoughtCourseIds: Array<string>;
    selectHaveNotBoughtButStartedCourseIds: Array<string>;
    excludeUserIds: Array<string>;
    excludeHaveBoughtCourseIds: Array<string>;
    excludeHaveNotBoughtCourseIds: Array<string>;
    excludeHaveNotBoughtButStartedCourseIds: Array<string>;
};
export type CustomerMessageSenderScreen_sendCustomerMessagesMutationVariables = {
    customerMessageDefinitionId: string;
    targetAudience: TargetAudienceInputV2;
};
export type CustomerMessageSenderScreen_sendCustomerMessagesMutationResponse = {
    readonly Admin: {
        readonly CustomerMessageV2: {
            readonly sendCustomerMessages: {
                readonly clientMutationId: string | null;
            } | null;
        };
    };
};
export type CustomerMessageSenderScreen_sendCustomerMessagesMutation = {
    readonly response: CustomerMessageSenderScreen_sendCustomerMessagesMutationResponse;
    readonly variables: CustomerMessageSenderScreen_sendCustomerMessagesMutationVariables;
};



/*
mutation CustomerMessageSenderScreen_sendCustomerMessagesMutation(
  $customerMessageDefinitionId: ID!
  $targetAudience: TargetAudienceInputV2!
) {
  Admin {
    CustomerMessageV2 {
      sendCustomerMessages(input: {customerMessageDefinitionId: $customerMessageDefinitionId, targetAudience: $targetAudience}) {
        clientMutationId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customerMessageDefinitionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "targetAudience"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerMessageV2AdminMutationSchema",
        "kind": "LinkedField",
        "name": "CustomerMessageV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "customerMessageDefinitionId",
                    "variableName": "customerMessageDefinitionId"
                  },
                  {
                    "kind": "Variable",
                    "name": "targetAudience",
                    "variableName": "targetAudience"
                  }
                ],
                "kind": "ObjectValue",
                "name": "input"
              }
            ],
            "concreteType": "CustomerMessageDefinitionIdWithTargetAudienceInputPayload",
            "kind": "LinkedField",
            "name": "sendCustomerMessages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageSenderScreen_sendCustomerMessagesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerMessageSenderScreen_sendCustomerMessagesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6aa8e67147d861fcd30d0e2f36410075",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageSenderScreen_sendCustomerMessagesMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerMessageSenderScreen_sendCustomerMessagesMutation(\n  $customerMessageDefinitionId: ID!\n  $targetAudience: TargetAudienceInputV2!\n) {\n  Admin {\n    CustomerMessageV2 {\n      sendCustomerMessages(input: {customerMessageDefinitionId: $customerMessageDefinitionId, targetAudience: $targetAudience}) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'abcd5a61935d12d43be25dfa7c7aeddf';
export default node;
