import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_UPDATE_ACCOUNT_META = "update-account-meta";

export interface UpdateAccountMeta {
    accountId: string
    companySize?: string
    industry?: string
    isBusinessAccount?: boolean
}

export const updateAccountMetaAction = (accountId: string, companySize?: string, industry?: string, isBusinessAccount?: boolean, isSlaveAccount?: boolean) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_ACCOUNT_META,
            callType: API_UPDATE_ACCOUNT_META
        },
        payload: {
            accountId,
            companySize,
            industry,
            isBusinessAccount,
            isSlaveAccount
        }
    } as ApiCallAction<UpdateAccountMeta>;
};
