import { Dropdown } from "primereact/dropdown";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { CourseSelect_Query } from "../../../../../__generated__/CourseSelect_Query.graphql";
import { SelectItem } from "primereact/selectitem";

const QUERY = graphql`
    query CourseSelect_Query {
        Admin {
            Course {
                Courses {
                    edges {
                        node {
                            id
                            containerMeta {
                                title
                            }
                        }
                    }
                }
            }
        }
    }
`;

interface OwnProps {
    courseRef?: string
    onChange: (courseRef?: string) => void;
}

export const CourseSelect = ({
                                 courseRef,
                                 onChange
                             }: OwnProps) => {
    const data = useLazyLoadQuery<CourseSelect_Query>(QUERY, {})

    const courses = data.Admin.Course.Courses?.edges?.map(node => node!.node!) || []
    return <Dropdown value={courses.find(c => c.id === courseRef)}
                     options={courses.map(c => {
                         return {value: c, label: c.containerMeta.title, title: c.containerMeta.title} as SelectItem
                     })}
                     onChange={e => onChange(e.value.id)}
                     filter
                     showClear
                     filterBy="containerMeta.title"
                     placeholder="Kurs auswählen"
    />
}
