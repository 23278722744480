import React from "react";
import {Dropdown} from "primereact/dropdown";
import {useTranslation} from "react-i18next";
import {
    CustomerMessageDefinitionButton, CustomerMessageDefinitionConfirmButton,
    CustomerMessageDefinitionLinkButton
} from "./EditCustomerMessageDefinitionV2CoreForm";
import {DefaultTextFieldComponent} from "../../../core/components/form/forms/DefaultTextComponent";

interface OwnProps {
    fieldValue: CustomerMessageDefinitionButton | undefined
    updateField: (button?: CustomerMessageDefinitionButton) => void
}

export const EditCustomerMessageDefinitionButtonSubform = ({fieldValue, updateField}: OwnProps) => {

    const {t: tCMV2} = useTranslation("customerMessagesV2")

    return <div className="mt-2">
        <div className="p-field mb-3">
            <label>{tCMV2("edit-customer-message-definition-v2.core.button.types.name")} *</label>
            <Dropdown
                value={(fieldValue as CustomerMessageDefinitionButton).type}
                options={[
                    {
                        label: tCMV2("edit-customer-message-definition-v2.core.button.types.none"),
                        value: "none"
                    },
                    {
                        label: tCMV2("edit-customer-message-definition-v2.core.button.types.link"),
                        value: "link"
                    },
                    {
                        label: tCMV2("edit-customer-message-definition-v2.core.button.types.confirm"),
                        value: "confirm"
                    },
                ]}
                onChange={e => updateField({
                    ...fieldValue,
                    type: e.value
                } as CustomerMessageDefinitionButton)}
            />
        </div>
        {(fieldValue as CustomerMessageDefinitionButton).type === "link" || (fieldValue as CustomerMessageDefinitionButton).type === "confirm" ?
            <div className="p-field mb-3">
                <label>{tCMV2("edit-customer-message-definition-v2.core.button.text")} *</label>
                <DefaultTextFieldComponent fieldName={"buttonText"}
                                           fieldValue={(fieldValue as CustomerMessageDefinitionLinkButton | CustomerMessageDefinitionConfirmButton).title}
                                           updateField={(title: string | undefined) => updateField({
                                               ...fieldValue,
                                               title: title
                                           } as CustomerMessageDefinitionLinkButton | CustomerMessageDefinitionConfirmButton)}
                                           isValid={true}
                                           required={true}
                />
            </div> : undefined}
        {(fieldValue as CustomerMessageDefinitionButton).type === "link" ?
            <div className="p-field mb-3">
                <label>{tCMV2("edit-customer-message-definition-v2.core.button.link")} *</label>
                <DefaultTextFieldComponent
                    fieldName={"link"}
                    fieldValue={(fieldValue as CustomerMessageDefinitionLinkButton).link}
                    updateField={(link: string | undefined) => updateField({
                        ...fieldValue,
                        link: link
                    } as CustomerMessageDefinitionLinkButton)}
                    isValid={true}
                    required={true}
                />
            </div> : undefined}
    </div>
}
