/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SetAdvertisedCourseInputInput = {
    advertisedCourseId?: string | null;
    clientMutationId?: string | null;
};
export type SettingsScreen_SetAdvertisedCourseMutationVariables = {
    input: SetAdvertisedCourseInputInput;
};
export type SettingsScreen_SetAdvertisedCourseMutationResponse = {
    readonly Admin: {
        readonly Settings: {
            readonly setAdvertisedCourse: {
                readonly clientMutationId: string | null;
            } | null;
        };
    };
};
export type SettingsScreen_SetAdvertisedCourseMutation = {
    readonly response: SettingsScreen_SetAdvertisedCourseMutationResponse;
    readonly variables: SettingsScreen_SetAdvertisedCourseMutationVariables;
};



/*
mutation SettingsScreen_SetAdvertisedCourseMutation(
  $input: SetAdvertisedCourseInputInput!
) {
  Admin {
    Settings {
      setAdvertisedCourse(input: $input) {
        clientMutationId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingsAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Settings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "SetAdvertisedCourseInputPayload",
            "kind": "LinkedField",
            "name": "setAdvertisedCourse",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsScreen_SetAdvertisedCourseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettingsScreen_SetAdvertisedCourseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ccfb9c2e546bf2e23a271b3dbd8d0a35",
    "id": null,
    "metadata": {},
    "name": "SettingsScreen_SetAdvertisedCourseMutation",
    "operationKind": "mutation",
    "text": "mutation SettingsScreen_SetAdvertisedCourseMutation(\n  $input: SetAdvertisedCourseInputInput!\n) {\n  Admin {\n    Settings {\n      setAdvertisedCourse(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2a856aa96bdfbc489f6ebf32d5be3536';
export default node;
