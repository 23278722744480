import React, {Suspense} from "react";
import {useTranslation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery, useMutation, usePaginationFragment} from "react-relay";
import {Header} from "../../../core/components/prime-react/Header";
import {ProgressSpinner} from "primereact/progressspinner";
import {CustomerMessageDefinitionOverviewScreen_PaginationFragmentRefetch} from "../../../../__generated__/CustomerMessageDefinitionOverviewScreen_PaginationFragmentRefetch.graphql";
import {useHistory} from "react-router-dom";
import {CustomerMessageDefinitionV2CreateButton} from "./CustomerMessageDefinitionV2CreateButton";
import {CustomerMessageDefinitionV2Table} from "./CustomerMessageDefinitionV2Table";
import {CustomerMessageDefinitionV2OverviewScreen_Query} from "../../../../__generated__/CustomerMessageDefinitionV2OverviewScreen_Query.graphql";
import {CustomerMessageDefinitionV2OverviewScreen_PaginationFragment$key} from "../../../../__generated__/CustomerMessageDefinitionV2OverviewScreen_PaginationFragment.graphql";
import {CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutation} from "../../../../__generated__/CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutation.graphql";
import {CustomerMessageDefinitionV2OverviewScreen_DeleteCustomerMessageDefinitionMutation} from "../../../../__generated__/CustomerMessageDefinitionV2OverviewScreen_DeleteCustomerMessageDefinitionMutation.graphql";
import {confirmDialog} from "primereact/confirmdialog";
import {CustomerMessageDefinitionV2TableFilter} from "./CustomerMessageDefinitionV2TableFilter";
import {PaginationButton} from "../../../core/components/pagination/PaginationButton";

const NUM_ITEMS_PER_PAGE = 10

const QUERY = graphql`
    query CustomerMessageDefinitionV2OverviewScreen_Query($numItemsPerPage: Int!) {
        ...CustomerMessageDefinitionV2OverviewScreen_PaginationFragment @arguments(internalTitle: "", first: $numItemsPerPage, after: null)
    }`

const FRAGMENT = graphql`
    fragment CustomerMessageDefinitionV2OverviewScreen_PaginationFragment on Query @refetchable(queryName: "CustomerMessageDefinitionOverviewScreen_PaginationFragmentRefetch") @argumentDefinitions(
        internalTitle: {type: "String!"},
        first: {type: "Int!"},
        after: {type: "String"}
    ){
        Admin {
            CustomerMessageV2 {
                CustomerMessageDefinitions(internalTitle: $internalTitle, first: $first, after: $after) @connection(key: "CustomerMessageDefinitionOverviewScreen_CustomerMessageDefinitions") {
                    __id
                    edges {
                        node {
                            id
                            ... on CustomerMessageDefinitionV2 {
                                internalTitle
                            }
                        }
                    }
                }
            }
        }
    }`

const CREATE_CUSTOMER_MESSAGE_DEFINITION_MUTATION = graphql`
    mutation CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutation($input: CreateCustomerMessageDefinitionInput!, $connections: [ID!]!) {
        Admin {
            CustomerMessageV2 {
                createCustomerMessageDefinition(input: $input) {
                    data @prependEdge(connections: $connections){
                        node  {
                            id
                            ... on CustomerMessageDefinitionV2 {
                                internalTitle
                            }
                        }
                    }
                }
            }
        }
    }`

const DELETE_CUSTOMER_MESSAGE_DEFINITION_MUTATION = graphql`
    mutation CustomerMessageDefinitionV2OverviewScreen_DeleteCustomerMessageDefinitionMutation($input: DeleteCustomerMessageDefinitionV2Input!, $connections: [ID!]!) {
        Admin {
            CustomerMessageV2 {
                deleteCustomerMessageDefinitionV2(input: $input) {
                    deletedId @deleteEdge(connections: $connections)
                }
            }
        }
    }`

export const CustomerMessageDefinitionV2OverviewScreen = () => {

    const {t: tCMV2} = useTranslation("customerMessagesV2")

    const history = useHistory()

    const query = useLazyLoadQuery<CustomerMessageDefinitionV2OverviewScreen_Query>(QUERY, {numItemsPerPage: NUM_ITEMS_PER_PAGE})

    const {
        data: customerMessageDefinitions,
        hasNext,
        loadNext,
        refetch
    } = usePaginationFragment<CustomerMessageDefinitionOverviewScreen_PaginationFragmentRefetch, CustomerMessageDefinitionV2OverviewScreen_PaginationFragment$key>(FRAGMENT, query)

    const items = customerMessageDefinitions.Admin.CustomerMessageV2.CustomerMessageDefinitions.edges?.filter(e => !!e).map(e => e!.node) || []

    const [createCustomerMessageDefinitionRaw, isCreatingCustomerMessageDefinition] = useMutation<CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutation>(CREATE_CUSTOMER_MESSAGE_DEFINITION_MUTATION)

    const createCustomerMessageDefinition = () => createCustomerMessageDefinitionRaw({
            variables: {
                input: {
                    data: {
                        internalTitle: tCMV2("overview.create-customer-message-definition.initial-internal-title"),
                        page: {
                            internalTitle: tCMV2("edit-customer-message-definition-v2.pages.create-page.initial-internal-title")
                        }
                    }
                },
                connections: [customerMessageDefinitions.Admin.CustomerMessageV2.CustomerMessageDefinitions.__id]
            },
            onCompleted: (response) => {
                const id = response.Admin.CustomerMessageV2.createCustomerMessageDefinition?.data.node.id
                if (id) history.push("/customer-message-v2/" + id)
            }
        }
    )

    const [deleteCustomerMessageDefinitionRaw] = useMutation<CustomerMessageDefinitionV2OverviewScreen_DeleteCustomerMessageDefinitionMutation>(DELETE_CUSTOMER_MESSAGE_DEFINITION_MUTATION)

    const deleteCustomerMessageDefinitionWithConfirmation = (internalTitle: string, id: string) => confirmDialog({
        message: tCMV2("overview.delete-customer-message-definition-dialog.message"),
        header: tCMV2("overview.delete-customer-message-definition-dialog.header", {internalTitle: internalTitle}),
        icon: "pi pi-exclamation-triangle",
        acceptLabel: tCMV2("overview.delete-customer-message-definition-dialog.accept-label"),
        rejectLabel: tCMV2("overview.delete-customer-message-definition-dialog.reject-label"),
        acceptClassName: "p-button-danger",
        accept: () => deleteCustomerMessageDefinitionRaw({
            variables: {
                input: {
                    id: id
                },
                connections: [customerMessageDefinitions.Admin.CustomerMessageV2.CustomerMessageDefinitions.__id]
            }
        })
    })

    return <Suspense fallback={<ProgressSpinner/>}>
        {isCreatingCustomerMessageDefinition ? <ProgressSpinner/> : <>
            <Header heading={tCMV2("overview.heading")}/>

            <CustomerMessageDefinitionV2CreateButton
                createCustomerMessageDefinition={createCustomerMessageDefinition}
            />

            <CustomerMessageDefinitionV2TableFilter refetch={(internalTitle: string) => refetch({
                internalTitle: internalTitle,
                first: NUM_ITEMS_PER_PAGE
            })}/>

            <CustomerMessageDefinitionV2Table
                items={items}
                deleteCustomerMessageDefinitionV2={deleteCustomerMessageDefinitionWithConfirmation}
            />

            <PaginationButton hasNext={hasNext} loadNext={() => loadNext(NUM_ITEMS_PER_PAGE)}/>
        </>}
    </Suspense>
}