import {
    API_FETCH_SINGLE_ENTITY,
    API_UPDATE_ENTITIES,
    ApiCallSucceededAction,
    EntityWrapper,
    isApiCall,
    isApiSuccess,
    SingleEntityResolvedPayload
} from "@thekeytechnology/framework-react";
import { EDIT_COURSE_ACTION, EditCourseAction } from "../actions/course/edit-course";
import { ADD_ELEMENT_ACTION, AddElementAction } from "../actions/element/add-element";
import { EDIT_ELEMENT_ACTION, EditElementAction } from "../actions/element/edit-lesson";
import { MOVE_ELEMENT_ACTION, MoveElementAction } from "../actions/element/move-lesson";
import { REMOVE_ELEMENT_ACTION, RemoveElementAction } from "../actions/element/remove-lesson";
import { ADD_LESSON_ACTION, AddLessonAction } from "../actions/lesson/add-lesson";
import { EDIT_LESSON_ACTION, EditLessonAction } from "../actions/lesson/edit-lesson";
import { MOVE_LESSON_ACTION, MoveLessonAction } from "../actions/lesson/move-lesson";
import { REMOVE_LESSON_ACTION, RemoveLessonAction } from "../actions/lesson/remove-lesson";
import { ADD_MODULE_ACTION, AddModuleAction } from "../actions/module/add-module";
import { EDIT_MODULE_ACTION, EditModuleAction } from "../actions/module/edit-module";
import { MOVE_MODULE_ACTION, MoveModuleAction } from "../actions/module/move-module";
import { REMOVE_MODULE_ACTION, RemoveModuleAction } from "../actions/module/remove-module";
import { PreparingAggregatedCourse, ENTITY_TYPE_AGGREGATED_COURSE } from "../model/preparing-aggregated-course";
import { addElement } from "./changes/element/add-element";
import { editElement } from "./changes/element/edit-element";
import { moveElement } from "./changes/element/move-element";
import { removeElement } from "./changes/element/remove-element";
import { editLesson } from "./changes/lesson/edit-lesson";
import { moveLesson } from "./changes/lesson/move-lesson";
import { removeLesson } from "./changes/lesson/remove-lesson";
import update from "immutability-helper";
import { addLesson } from "./changes/lesson/add-lesson";
import { addModule } from "./changes/module/add-module";
import { removeModule } from "./changes/module/remove-module";
import { editModule } from "./changes/module/edit-module";
import { moveModule } from "./changes/module/move-module";
import { Action } from "redux";

export interface EditedCourseState {
    editedCourse?: EntityWrapper<PreparingAggregatedCourse>,
    hasUnsavedChanges: boolean;
}

export const initialState: EditedCourseState = {
    editedCourse: undefined,
    hasUnsavedChanges: false
};

export function editedCourseReducer(state = initialState, action: Action): EditedCourseState {
    if (isApiSuccess(API_FETCH_SINGLE_ENTITY, ENTITY_TYPE_AGGREGATED_COURSE)(action)) {
        const fetchSingleCourseAction = action as ApiCallSucceededAction<SingleEntityResolvedPayload<PreparingAggregatedCourse>>;

        return {
            ...state,
            editedCourse: fetchSingleCourseAction.payload.entity,
            hasUnsavedChanges: false
        };
    }

    if (isApiSuccess(API_UPDATE_ENTITIES, ENTITY_TYPE_AGGREGATED_COURSE)(action)) {
        return {
            ...state,
            hasUnsavedChanges: false
        };
    }

    if (isApiCall(API_FETCH_SINGLE_ENTITY, ENTITY_TYPE_AGGREGATED_COURSE)(action)) {
        return {
            ...state,
            editedCourse: undefined,
            hasUnsavedChanges: false
        };
    }

    if (!state.editedCourse) {
        return state;
    }

    switch (action.type) {
        case EDIT_COURSE_ACTION:
            const editCourseAction = action as EditCourseAction;
            return update(state, {
                hasUnsavedChanges: {$set: true},
                editedCourse: {
                    entity: {
                        containerMeta: {
                            title: {
                                $set: editCourseAction.course.entity.containerMeta.title
                            },
                            description: {
                                $set: editCourseAction.course.entity.containerMeta.description
                            }
                        },
                        shortTitle: {$set: editCourseAction.course.entity.shortTitle},
                        image: {$set: editCourseAction.course.entity.image},
                        icon: {$set: editCourseAction.course.entity.icon},
                        isIHK: {$set: editCourseAction.course.entity.isIHK},
                        expertsRef: {$set: editCourseAction.course.entity.expertsRef},
                        certificateTitle: {
                            $set: editCourseAction.course.entity.certificateTitle
                        },
                        certificateDescription: {
                            $set: editCourseAction.course.entity.certificateDescription
                        },
                        mainOffer: {$set: editCourseAction.course.entity.mainOffer},
                        mainOfferV2: {$set: editCourseAction.course.entity.mainOfferV2},
                        mainProduct: {$set: editCourseAction.course.entity.mainProduct},
                        isFreeCourse: {$set: editCourseAction.course.entity.isFreeCourse},
                        attributes: {$set: editCourseAction.course.entity.attributes},
                        courseFile: {$set: editCourseAction.course.entity.courseFile}
                    }
                }
            });

        case ADD_MODULE_ACTION:
            const addModuleAction = action as AddModuleAction;
            return addModule(state, addModuleAction);
        case REMOVE_MODULE_ACTION:
            const removeModuleAction = action as RemoveModuleAction;
            return removeModule(state, removeModuleAction);
        case EDIT_MODULE_ACTION:
            const editModuleAction = action as EditModuleAction;
            return editModule(state, editModuleAction);
        case MOVE_MODULE_ACTION:
            const moveModuleAction = action as MoveModuleAction;
            return moveModule(state, moveModuleAction);
        case ADD_LESSON_ACTION: {
            const addLessonAction = action as AddLessonAction;
            return addLesson(state, addLessonAction);
        }
        case REMOVE_LESSON_ACTION: {
            const removeLessonAction = action as RemoveLessonAction;
            return removeLesson(state, removeLessonAction);
        }
        case EDIT_LESSON_ACTION: {
            const editLessonAction = action as EditLessonAction;
            return editLesson(state, editLessonAction);
        }
        case MOVE_LESSON_ACTION: {
            const moveLessonAction = action as MoveLessonAction;
            return moveLesson(state, moveLessonAction);
        }
        case ADD_ELEMENT_ACTION: {
            const addElementAction = action as AddElementAction;
            return addElement(state, addElementAction);
        }
        case EDIT_ELEMENT_ACTION: {
            const editElementAction = action as EditElementAction;
            return editElement(state, editElementAction);
        }
        case REMOVE_ELEMENT_ACTION: {
            const removeElementAction = action as RemoveElementAction;
            return removeElement(state, removeElementAction);
        }
        case MOVE_ELEMENT_ACTION: {
            const moveElementAction = action as MoveElementAction;
            return moveElement(state, moveElementAction);
        }
    }
    return state;
}
