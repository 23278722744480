import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { SyncRequestsFilters } from "./SyncRequestsFilters";
import { SyncRequestsTable } from "./SyncRequestsTable";
import { ENTITY_TYPE_SYNC_REQUEST } from "../model/SyncRequest";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../core/components/containers/Container";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const ExpertOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/sync-requests"}>{t("entity.sync-request.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.sync-request.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <SyncRequestsFilters/>
            </BackgroundContainer>
            <ContentContainer>
                <SyncRequestsTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_SYNC_REQUEST}/>
            </PaginationContainer>
        </>
    );
};

export const SyncRequestsOverview = withTranslation("syncRequests")(ExpertOverviewComponent);
