import { faEnvelopeOpenText, faHeading } from "@fortawesome/free-solid-svg-icons";
import {
    EntityWrapper,
    WithSwitchableLanguage,
    WithSwitchableLanguageProps
} from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import { WithTranslation, withTranslation } from "react-i18next";
import React from "react";
import { EmailConfiguration } from "../../model/email-configuration.model";
import * as Yup from "yup";
import { LoadingEditHeader } from "../../../core/components/header/LoadingEditHeader";
import { EditHeaderWithBackground } from "../../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../../core/components/containers/Container";
import { ValidatedField } from "../../../core/components/form/ValidatedField";
import { WysiwygField } from "../../../core/components/wysiwyg/WysiwygField";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../core/components/entity/WithSingleEntityFromPath";

type Props =
    WithSingleEntityFromPathProps<EmailConfiguration, EmailConfiguration>
    & WithTranslation
    & WithSwitchableLanguageProps;

const EditEmailComponent = (props: Props) => {
    const {entity, upsertEntity, t, currentLanguage} = props;

    return (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.email-configuration.singular")}/>

            {entity ?
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        subject: entity.entity.subjects[currentLanguage],
                        body: entity.entity.bodies[currentLanguage],
                        preview: entity.entity.previewTexts[currentLanguage]
                    }}
                    validationSchema={Yup.object().shape({
                        subject: Yup.string().required(t("Subject") + " " + t("translation:is a required field")),
                        body: Yup.string().required(t("Body") + " " + t("translation:is a required field")),
                        preview: Yup.string().required(t("Preview") + " " + t("translation:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const emailForSaving = new EntityWrapper(entity.id,
                            new EmailConfiguration(
                                entity.entity.key,
                                {
                                    ...entity.entity.subjects,
                                    [currentLanguage]: values.subject
                                },
                                {
                                    ...entity.entity.previewTexts,
                                    [currentLanguage]: values.preview
                                },
                                {
                                    ...entity.entity.bodies,
                                    [currentLanguage]: values.body
                                },
                                entity.entity.variables
                            )
                        );
                        upsertEntity(emailForSaving);
                        setSubmitting(false);
                    }}
                >
                    {formikState => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={t("emails." + entity.entity.key + ".key")}
                                leadText={<>{formikState.values.subject}</>}>
                                <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                    backPath="/emails"/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                {/*<div className="form-group row">*/}
                                {/*    <label htmlFor="editing-language"*/}
                                {/*           className="col-sm-2 col-form-label">{t("Choose translation")}</label>*/}
                                {/*    <div className="col-sm-10">*/}
                                {/*        <LanguageSwitcher availableLanguages={props.availableLanguages}*/}
                                {/*                          selectedLanguage={props.editingLanguage}*/}
                                {/*                          selectLanguage={language => props.setEditingLanguage(language)}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="form-group row">
                                    <ValidatedField icon={faHeading}
                                                    label={t("Subject")}
                                                    name="subject"
                                                    formikState={formikState}
                                                    type="text"
                                                    placeholder={t("Enter email subject")}
                                                    required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faEnvelopeOpenText}
                                                    label={t("Preview")}
                                                    name="preview"
                                                    formikState={formikState}
                                                    type="text"
                                                    placeholder={t("Enter email preview")}
                                                    required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField label={t("Body")}
                                                    rows="10"
                                                    name="body"
                                                    formikState={formikState}
                                                    component={WysiwygField}
                                                    config={{
                                                        extraPlugins: [],
                                                        resize_enabled: true,
                                                        height: 700,
                                                        maxHeight: 700
                                                    }}
                                                    required
                                    />
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="html"
                                           className="col-sm-2 col-form-label">{t("Available Variables")}</label>
                                    <div className="col-sm-10">
                                        {entity.entity.variables.length ? <ul>
                                            {entity.entity.variables.map(v => {
                                                return <li key={v}>%%{v}%%
                                                    - {t("emails.variables." + v)}</li>;
                                            })}
                                        </ul> : null}
                                    </div>
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik> : null}
        </>
    );
};

export const EditEmail = WithSingleEntityFromPath<{}, EmailConfiguration, EmailConfiguration>(WithSwitchableLanguage<any>(
    withTranslation("emails")(EditEmailComponent)), EmailConfiguration.TYPE, "emailId"
);
