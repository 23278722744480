import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { CourseElement } from "@thekeytechnology/thekey-academy-frontend-library";

export const ADD_ELEMENT_ACTION = "add-element";

export interface AddElementAction extends Action {
    element: EntityWrapper<CourseElement>
    parentModuleId: string;
    parentLessonId: string;
}

export const addElementAction = (parentModuleId: string, parentLessonId: string, element: EntityWrapper<CourseElement>) => ({
    type: ADD_ELEMENT_ACTION,
    parentModuleId,
    parentLessonId,
    element
}) as AddElementAction;
