/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditCourseBundleForm_QueryVariables = {
    courseIds: Array<string>;
};
export type EditCourseBundleForm_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"CoursesSelect_QueryFragment">;
};
export type EditCourseBundleForm_Query = {
    readonly response: EditCourseBundleForm_QueryResponse;
    readonly variables: EditCourseBundleForm_QueryVariables;
};



/*
query EditCourseBundleForm_Query(
  $courseIds: [ID!]!
) {
  ...CoursesSelect_QueryFragment_1Vd5ye
}

fragment CoursesSelect_QueryFragment_1Vd5ye on Query {
  Admin {
    Course {
      CoursesMultiselect(ids: $courseIds) {
        edges {
          cursor
          node {
            id
            containerMeta {
              title
            }
            __typename
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "courseIds"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCourseBundleForm_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "CoursesSelect_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditCourseBundleForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseAdminSchema",
            "kind": "LinkedField",
            "name": "Course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoursesConnection",
                "kind": "LinkedField",
                "name": "CoursesMultiselect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoursesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Course",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContainerMeta",
                            "kind": "LinkedField",
                            "name": "containerMeta",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "titleOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "CourseSelect_CoursesMultiselect",
                "kind": "LinkedHandle",
                "name": "CoursesMultiselect"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f026072ba21e716fae108d0d16986d6",
    "id": null,
    "metadata": {},
    "name": "EditCourseBundleForm_Query",
    "operationKind": "query",
    "text": "query EditCourseBundleForm_Query(\n  $courseIds: [ID!]!\n) {\n  ...CoursesSelect_QueryFragment_1Vd5ye\n}\n\nfragment CoursesSelect_QueryFragment_1Vd5ye on Query {\n  Admin {\n    Course {\n      CoursesMultiselect(ids: $courseIds) {\n        edges {\n          cursor\n          node {\n            id\n            containerMeta {\n              title\n            }\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '327ab2e62a3c8a2a16c553e2affc957c';
export default node;
