import { UserWithRoles } from "@thekeytechnology/framework-react";
import { TriggeredAchievementsOverview } from "./TriggeredAchievementsOverview";
import { EditTriggeredAchievement } from "./EditTriggeredAchievement";
import { PrivateRouteDefinition } from "../../core/utils/PrivateRouteDefinition";

export const AchievementModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/achievements",
        component: TriggeredAchievementsOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/achievements/:achievementId/edit",
        component: EditTriggeredAchievement,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
];
