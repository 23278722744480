import React, {useEffect} from 'react'
import {useRefetchableFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {useTranslation} from "react-i18next";
import {DiscountSelect_QueryFragment$key} from "../../../../__generated__/DiscountSelect_QueryFragment.graphql";
import {
    DiscountSelect_QueryFragmentRefetchQuery
} from "../../../../__generated__/DiscountSelect_QueryFragmentRefetchQuery.graphql";
import {Dropdown, DropdownChangeParams} from "primereact/dropdown";

const NUM_PRODUCTS_PER_PAGE = 20

const QUERY_FRAGMENT = graphql`
    fragment DiscountSelect_QueryFragment on Query @refetchable(queryName: "DiscountSelect_QueryFragmentRefetchQuery") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String", defaultValue: null},
        code: {type: "String", defaultValue: null},
        ids: {type :"[ID!]!"}
    ) {
        Admin {
            Billing {
                DiscountsMultiselect(first: $first, after: $after, codeOpt: $code, ids: $ids) @connection(key: "DiscountSelect_DiscountsMultiselect") {
                    edges {
                        cursor
                        node {
                            id
                            code
                        }
                    }
                }
            }
        }
    }`

export interface DiscountCode {
    id: string
    code: string
}

interface OwnProps {
    fieldValue?: string
    onChange: (discount?: string) => void
    queryFragmentRef: DiscountSelect_QueryFragment$key
}

export const DiscountSelect = ({
                                   fieldValue,
                                   onChange,
                                   queryFragmentRef
                               }: OwnProps) => {

    const {t} = useTranslation("core")

    const [data, refetch] = useRefetchableFragment<DiscountSelect_QueryFragmentRefetchQuery, DiscountSelect_QueryFragment$key>(QUERY_FRAGMENT, queryFragmentRef)

    const discounts = data?.Admin?.Billing.DiscountsMultiselect.edges?.filter(e => !!e).map(e => e!.node) || []

    useEffect(() => {
        refetch({code: undefined, first: NUM_PRODUCTS_PER_PAGE, ids: fieldValue ? [fieldValue] : []})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldValue])

    return <Dropdown value={discounts.find(discount => fieldValue === discount.id)}
                     options={discounts}
                     optionLabel="code"
                     showClear
                     onChange={(e: DropdownChangeParams) => onChange(e.value?.id)}
                     onFilter={(e: { filter: string }) => {
                         refetch({code: e.filter, first: NUM_PRODUCTS_PER_PAGE, ids: fieldValue ? [fieldValue] : []})
                     }}
                     filter
                     resetFilterOnHide={true}
                     className="multiselect-custom"
                     filterPlaceholder={t("entity-selects.filter-placeholder")}
                     emptyFilterMessage={t("entity-selects.no-results")}
    />
}

