import {faExclamation, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FieldProps} from "formik";
import React from "react";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {PhrasingAlternativesEditor} from "../common/PhrasingAlternativesEditor";
import update from "immutability-helper";
import {connect} from "react-redux";
import {EntityWrapper, TkFile, WithSelections, WithSelectionsProps} from "@thekeytechnology/framework-react";
import {OwnProps} from "../../common/WithCourseEditorDraggable";
import {selectChildFiles} from "../../../../selectors";
import {AnswerOption, PhrasingAlternatives} from "@thekeytechnology/thekey-academy-frontend-library";
import { AttachmentSelect } from "../../../../../core/components/attachment/AttachmentSelect";

interface StateProps {
    childFiles: EntityWrapper<TkFile>[];
}

type Props = FieldProps & WithSelectionsProps<string> & StateProps;

const AnswersFieldComponent = ({field: {name, value}, childFiles, form, selectionModel}: Props) => {
    const {t} = useTranslation("courses")

    const answerOptions = value ? value as AnswerOption[] : [];

    const newAnswer = () => {
        const newAnswers = answerOptions.concat(
            {
                answer: {alternatives: []},
                imageRef: undefined,
                isCorrect: false
            }
        );
        form.setFieldValue(name, newAnswers);
    };

    const updatePhrasing = (answer: AnswerOption, index: number, updatedPhrasing?: PhrasingAlternatives) => {
        const newState = updatedPhrasing ? update(answerOptions, {
            [index]: {
                $set: {
                    answer: updatedPhrasing,
                    imageRef: answer.imageRef,
                    isCorrect: answer.isCorrect
                }
            }
        }) : update(answerOptions, {
            [index]: {
                $set: {
                    answer: answer.answer,
                    imageRef: answer.imageRef,
                    isCorrect: answer.isCorrect
                }
            }
        });
        form.setFieldValue(name, newState);
    };

    const updateIsCorrect = (answer: AnswerOption, index: number, isCorrect: boolean) => {
        const newState = update(answerOptions, {
            [index]: {
                $set: {
                    answer: answer.answer,
                    imageRef: answer.imageRef,
                    isCorrect
                }
            }
        });
        form.setFieldValue(name, newState);
    };

    const updateImage = (answer: AnswerOption, index: number, imageRef: string | undefined) => {
        const newState = update(answerOptions, {
            [index]: {
                $set: {
                    answer: answer.answer,
                    imageRef,
                    isCorrect: answer.isCorrect
                }
            }
        });
        form.setFieldValue(name, newState);
    };

    const removeAnswer = (index: number) => {
        form.setFieldValue(name,
            update(
                answerOptions, {$splice: [[index, 1]]}
            )
        );
    };

    return (
        <div className="answer-options d-flex flex-column w-100">
            {answerOptions.map((answer: AnswerOption, index: number) =>
                <Card key={index}>
                    <Card.Body>
                        <h3 className="d-flex align-items-center">
                            {t("course-editor.answers-field.answer-option", {index: index + 1})}
                            <button onClick={() => removeAnswer(index)}
                                    type="button"
                                    className="btn btn-link text-danger">
                                <FontAwesomeIcon icon={faTrash}/></button>
                        </h3>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.answers-field.answer-text")}</label>
                            <div className="col-sm-10">
                                <PhrasingAlternativesEditor
                                    icon={faExclamation}
                                    currentAlternatives={answer.answer}
                                    updatePhrasing={(updatedPhrasing?: PhrasingAlternatives) => updatePhrasing(answer, index, updatedPhrasing)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.answers-field.answer-image")}</label>
                            <div className="col-sm-10">
                                <AttachmentSelect
                                    selectionModel={selectionModel}
                                    isSingleSelect={true}
                                    value={childFiles.find(file => file.id === answer.imageRef)}
                                    changeValue={(newValue => updateImage(answer, index, (newValue as EntityWrapper<TkFile> | undefined)?.id))}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="colFormLabelSm"
                                   className="col-sm-2 col-form-label col-form-label-sm">{t("course-editor.answers-field.is-correct-label")}</label>
                            <div className="col-sm-10 d-flex align-items-center">
                                <div className="form-check d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input" checked={answer.isCorrect}
                                           onChange={event => updateIsCorrect(answer, index, event.target.checked)}/>
                                    <label
                                        className="form-check-label ml-2"> {t("course-editor.answers-field.is-correct-explanation")}</label>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}
            <Card>
                <Card.Body>
                    <button type="button" className="btn-link text-success" onClick={newAnswer}>
                        <FontAwesomeIcon className="mr-2" icon={faPlus}/>{t("course-editor.answers-field.new-answer")}
                    </button>
                </Card.Body>
            </Card>
        </div>
    );
};

export const AnswersField = connect<StateProps, {}, OwnProps>(
    (state: any) => ({
        childFiles: selectChildFiles(state)
    })
)(WithSelections(AnswersFieldComponent));
