/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateDiscountCodeV2Input = {
    data: CreateDiscountCode;
    clientMutationId?: string | null;
};
export type CreateDiscountCode = {
    discountActionId: string;
    code: string;
};
export type CreateSingleCodeButton_CreateMutationVariables = {
    input: CreateDiscountCodeV2Input;
    connections: Array<string>;
};
export type CreateSingleCodeButton_CreateMutationResponse = {
    readonly Admin: {
        readonly Billing: {
            readonly createDiscountCodeV2: {
                readonly data: {
                    readonly node: {
                        readonly " $fragmentRefs": FragmentRefs<"DiscountCodeTable_DiscountCodeFragment">;
                    };
                };
            } | null;
        };
    };
};
export type CreateSingleCodeButton_CreateMutation = {
    readonly response: CreateSingleCodeButton_CreateMutationResponse;
    readonly variables: CreateSingleCodeButton_CreateMutationVariables;
};



/*
mutation CreateSingleCodeButton_CreateMutation(
  $input: CreateDiscountCodeV2Input!
) {
  Admin {
    Billing {
      createDiscountCodeV2(input: $input) {
        data {
          node {
            ...DiscountCodeTable_DiscountCodeFragment
            id
          }
        }
      }
    }
  }
}

fragment CodeActionColumn_DiscountCodeFragment on DiscountCodeV2 {
  id
}

fragment CodeCodeColumn_DiscountCodeFragment on DiscountCodeV2 {
  code
}

fragment CodeCreatedAtColumn_DiscountCodeFragment on DiscountCodeV2 {
  createdAt
}

fragment CodeIdColumn_DiscountCodeFragment on DiscountCodeV2 {
  rawId
}

fragment CodeNumUsagesColumn_DiscountCodeFragment on DiscountCodeV2 {
  numUsages
}

fragment DiscountCodeTable_DiscountCodeFragment on DiscountCodeV2 {
  ...CodeIdColumn_DiscountCodeFragment
  ...CodeCodeColumn_DiscountCodeFragment
  ...CodeNumUsagesColumn_DiscountCodeFragment
  ...CodeCreatedAtColumn_DiscountCodeFragment
  ...CodeActionColumn_DiscountCodeFragment
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSingleCodeButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateDiscountCodeV2Payload",
                "kind": "LinkedField",
                "name": "createDiscountCodeV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodeV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "DiscountCodeTable_DiscountCodeFragment",
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "CodeIdColumn_DiscountCodeFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "CodeCodeColumn_DiscountCodeFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "CodeNumUsagesColumn_DiscountCodeFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "CodeCreatedAtColumn_DiscountCodeFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "CodeActionColumn_DiscountCodeFragment"
                              }
                            ]
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateSingleCodeButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateDiscountCodeV2Payload",
                "kind": "LinkedField",
                "name": "createDiscountCodeV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodeV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "numUsages",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cabb50f7eec90953385df23d3ab74622",
    "id": null,
    "metadata": {},
    "name": "CreateSingleCodeButton_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSingleCodeButton_CreateMutation(\n  $input: CreateDiscountCodeV2Input!\n) {\n  Admin {\n    Billing {\n      createDiscountCodeV2(input: $input) {\n        data {\n          node {\n            ...DiscountCodeTable_DiscountCodeFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment CodeActionColumn_DiscountCodeFragment on DiscountCodeV2 {\n  id\n}\n\nfragment CodeCodeColumn_DiscountCodeFragment on DiscountCodeV2 {\n  code\n}\n\nfragment CodeCreatedAtColumn_DiscountCodeFragment on DiscountCodeV2 {\n  createdAt\n}\n\nfragment CodeIdColumn_DiscountCodeFragment on DiscountCodeV2 {\n  rawId\n}\n\nfragment CodeNumUsagesColumn_DiscountCodeFragment on DiscountCodeV2 {\n  numUsages\n}\n\nfragment DiscountCodeTable_DiscountCodeFragment on DiscountCodeV2 {\n  ...CodeIdColumn_DiscountCodeFragment\n  ...CodeCodeColumn_DiscountCodeFragment\n  ...CodeNumUsagesColumn_DiscountCodeFragment\n  ...CodeCreatedAtColumn_DiscountCodeFragment\n  ...CodeActionColumn_DiscountCodeFragment\n}\n"
  }
};
})();
(node as any).hash = '51d1e97076cc5236fedb945359731dd9';
export default node;
