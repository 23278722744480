import {Button} from "primereact/button";
import React from "react";
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom";

interface OwnProps {
    discountActionId: string
    codeId: string
}

export const EditCodeButton = ({discountActionId, codeId}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const history = useHistory();

    return <Button
        icon="pi pi-pencil"
        tooltip={t("billing.discount-code.button.edit-action.tooltip")}
        onClick={(e) => history.push(`/discount-actions/${discountActionId}/${codeId}/edit`)}/>
}
