import { EditedCourseState } from "../../edited-course";
import { RemoveModuleAction } from "../../../actions/module/remove-module";
import update from "immutability-helper";
import { extractId } from "../common/helpers";

export const removeModule = (state: EditedCourseState, removeModuleAction: RemoveModuleAction) => {
    const moduleIndex = state.editedCourse!.entity.modules.indexOf(removeModuleAction.module);

    const lessonIdsInModule = removeModuleAction.module.entity.lessonsRef;
    const lessonsToRemove = state.editedCourse!.entity.lessons.filter(l => lessonIdsInModule.includes(extractId(l)))
    const elementsInLesson = lessonsToRemove.flatMap(l => l.entity.elementsRef);

    const updatedLessons = state.editedCourse!.entity.lessons.filter(l => !lessonIdsInModule.includes(extractId(l)))
    const updatedElements = state.editedCourse!.entity.elements.filter(el => !elementsInLesson.includes(extractId(el)))

    return update(state,
        {
            hasUnsavedChanges: {$set: true},
            editedCourse: {
                entity: {
                    modules: {$splice: [[moduleIndex, 1]]},
                    lessons: {$set: updatedLessons},
                    elements: {$set: updatedElements}
                }
            }
        }
    )
};
