import { InvoiceData } from "@thekeytechnology/thekey-academy-frontend-library";
import React from "react";

interface OwnProps {
    invoiceData: InvoiceData
}

export const LexofficeLink = ({invoiceData}: OwnProps) => {
    return <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://app.lexoffice.de/permalink/invoices/view/${invoiceData.invoiceId}`}>{invoiceData.invoiceNumber}</a>
}
