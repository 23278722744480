import {EntityWrapper} from "@thekeytechnology/framework-react";
import {TriggeredEmail} from "../../model/triggered-email";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Modal, ModalBody, ModalFooter} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {fetchTriggerDefinitionsAction} from "../../../triggers/actions/fetch-trigger-definitions";
import {selectTriggerDefinitions} from "../../../triggers/selectors";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {sendTestEmailAction} from "../../actions/send-test-email";
import { ValidatedField } from "../../../core/components/form/ValidatedField";

interface OwnProps {
    email: EntityWrapper<TriggeredEmail>
}

export const SendTestEmailButton = ({email}: OwnProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation(["emails", "triggers"])
    useEffect(() => {
        dispatch(fetchTriggerDefinitionsAction())
    }, [dispatch])

    const definitions = useSelector(selectTriggerDefinitions)
    const [modalVisible, setModalVisible] = useState(false);

    const definition = definitions.find(d => d.key === email.entity.trigger?.key)

    if (!definition) {
        return null;
    }

    const variablesToFillIn = definition.availableVariables
        .filter(v => !["sender", "frontendUrl"].includes(v));

    return <>
        <button type="button" onClick={() => setModalVisible(true)} className="btn btn-link">
            <FontAwesomeIcon icon={faEnvelope}/>
        </button>
        {modalVisible ? <Modal size={"lg"} show={true}>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    recipient: "",
                    ...variablesToFillIn
                        .map(v => ({[v]: ""}))
                        .reduce(((previousValue, currentValue) => ({
                            ...previousValue,
                            ...currentValue
                        })))
                }}
                onSubmit={(values, {setSubmitting}) => {
                    const {recipient, ...rest} = values;
                    dispatch(sendTestEmailAction(email.id!, recipient, rest as any))
                    setSubmitting(false);
                    setModalVisible(false)
                }}>
                {formikState => {
                    return (
                        <Form>
                            <ModalBody>
                                <div className="form-group row">
                                    <ValidatedField
                                        label={t("send-test-email-button.recipient")}
                                        name="recipient"
                                        formikState={formikState}
                                        type="email"
                                        required
                                    />
                                </div>
                                {variablesToFillIn.map((v, index) => {
                                    return <div className="form-group row" key={index}>
                                        <ValidatedField
                                            label={t("triggers:trigger-field.variables." + v)}
                                            name={v}
                                            formikState={formikState}
                                            type="text"
                                            required
                                        />
                                    </div>
                                })}
                            </ModalBody>
                            <ModalFooter>
                                <button type="button"
                                        onClick={() => setModalVisible(false)}
                                        className="btn btn-danger ml-auto">{t("send-test-email-button.close")}</button>

                                <button type="submit"
                                        className="btn btn-success ml-3">{t("send-test-email-button.submit")}</button>
                            </ModalFooter>
                        </Form>
                    );
                }}
            </Formik>
        </Modal> : null}
    </>;

}
