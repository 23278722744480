import { UserWithRoles } from "@thekeytechnology/framework-react";
import { FeedbackOverview } from "./components/FeedbackOverview";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";

export const FeedbackModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/feedbacks",
        component: FeedbackOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
];
