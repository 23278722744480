import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { VideoField } from "../../../course/components/course-editor/elements/video/VideoField";
import {
    ADDITIONAL_CONTENT_TYPE_VIDEO,
    VideoAdditionalContent
} from "@thekeytechnology/thekey-academy-frontend-library";
import { EditHeaderWithBackground } from "../../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../../core/components/containers/Container";
import { ValidatedField } from "../../../core/components/form/ValidatedField";

interface OwnProps {
    entity: EntityWrapper<VideoAdditionalContent>
    onUpdate: (entity: EntityWrapper<VideoAdditionalContent>) => void;
}

type Props = OwnProps
    & WithTranslation;

const EditVideoAdditionalContentFormComponent = ({
                                                     entity,
                                                     onUpdate,
                                                     t
                                                 }: Props) => {

    return <Formik
        initialValues={{
            title: entity.entity.title,
            videoData: entity.entity.videoData
        }}
        validationSchema={Yup.object().shape({
            title: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.additional-content.labels.title")})),
            videoData: Yup.object().required(t("entity.additional-content.labels.video-data-error"))
        })}
        onSubmit={(values, {setSubmitting}) => {
            const saving = new EntityWrapper<VideoAdditionalContent>(entity.id,
                {
                    contentType: ADDITIONAL_CONTENT_TYPE_VIDEO,
                    title: values.title,
                    videoData: values.videoData,
                    videoFile: undefined as any,
                    versions: entity.entity.versions
                }
            );
            onUpdate(saving);
            setSubmitting(false);
        }}
    >
        {formikState => (
            <Form>
                <EditHeaderWithBackground
                    heading={entity.id ?
                        formikState.values.title :
                        t("core:edit-header.heading-empty")}
                >
                    <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                        backPath="/additional-content/"/>
                </EditHeaderWithBackground>
                <ContentContainer>
                    <div className="form-group row">
                        <ValidatedField
                            formikState={formikState}
                            placeholder={t("entity.additional-content.labels.title")}
                            name="title"
                            className="form-control default-input"
                            label={t("entity.additional-content.labels.title")}
                            required/>
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("entity.additional-content.labels.video-data")}
                            name="videoData"
                            formikState={formikState}
                            component={VideoField}
                            required
                        />
                    </div>
                </ContentContainer>
            </Form>
        )}
    </Formik>;
};

export const EditVideoAdditionalContentForm = withTranslation(["additionalContent", "core"])(EditVideoAdditionalContentFormComponent);
