/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditCourseBundleScreen_QueryVariables = {
    id: string;
    skip: boolean;
};
export type EditCourseBundleScreen_QueryResponse = {
    readonly node?: {
        readonly " $fragmentRefs": FragmentRefs<"EditCourseBundleScreen_CourseBundleFragment">;
    } | null;
};
export type EditCourseBundleScreen_Query = {
    readonly response: EditCourseBundleScreen_QueryResponse;
    readonly variables: EditCourseBundleScreen_QueryVariables;
};



/*
query EditCourseBundleScreen_Query(
  $id: ID!
  $skip: Boolean!
) {
  node(id: $id) @skip(if: $skip) {
    __typename
    ... on CourseBundle {
      ...EditCourseBundleScreen_CourseBundleFragment
    }
    id
  }
}

fragment EditCourseBundleScreen_CourseBundleFragment on CourseBundle {
  id
  title
  bundleCourses {
    id
    containerMeta {
      title
    }
  }
  finalCourseId
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCourseBundleScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditCourseBundleScreen_CourseBundleFragment"
                  }
                ],
                "type": "CourseBundle",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditCourseBundleScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Course",
                    "kind": "LinkedField",
                    "name": "bundleCourses",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContainerMeta",
                        "kind": "LinkedField",
                        "name": "containerMeta",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "finalCourseId",
                    "storageKey": null
                  }
                ],
                "type": "CourseBundle",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "a1df6975ca09c8df6ee74abd721e289e",
    "id": null,
    "metadata": {},
    "name": "EditCourseBundleScreen_Query",
    "operationKind": "query",
    "text": "query EditCourseBundleScreen_Query(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on CourseBundle {\n      ...EditCourseBundleScreen_CourseBundleFragment\n    }\n    id\n  }\n}\n\nfragment EditCourseBundleScreen_CourseBundleFragment on CourseBundle {\n  id\n  title\n  bundleCourses {\n    id\n    containerMeta {\n      title\n    }\n  }\n  finalCourseId\n}\n"
  }
};
})();
(node as any).hash = 'b22ca08cce02d8f4ec2fd1b87f9693b3';
export default node;
