import { Action } from "redux";

export const ToggleCardAction = "ToggleCard";

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface ToggleCardAction extends Action {
    id: string;
}

export const toggleCardAction = (id: string) => ({
    type: ToggleCardAction,
    id
} as ToggleCardAction)
