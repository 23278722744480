import graphql from "babel-plugin-relay/macro";
import { useMutation, usePaginationFragment } from "react-relay";
import { ProductsTable_Refetch } from "../../../../../__generated__/ProductsTable_Refetch.graphql";
import { ProductsTable_ProductsList$key } from "../../../../../__generated__/ProductsTable_ProductsList.graphql";
import React from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useHistory } from "react-router-dom";
import { PriceDisplay } from "./PriceDisplay";
import { DialogResult, showDialogAction } from "@thekeytechnology/framework-react";
import { useDispatch } from "react-redux";
import { ProductsTable_DeleteDiscountCodeMutation } from "../../../../../__generated__/ProductsTable_DeleteDiscountCodeMutation.graphql";

const PRODUCTS_FRAGMENT = graphql`
    fragment ProductsTable_ProductsList on Query @refetchable(queryName: "ProductsTable_Refetch") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String"},
    ){
        Admin {
            Billing { 
                Products(first: $first, after: $after, includeHidden: true) @connection(key: "ProductsTable_Products") {
                    __id
                    pageInfo {
                        endCursor
                        hasPreviousPage
                        hasNextPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            ... on CourseProduct {
                                title
                                price {
                                    netPrice
                                    grossPrice
                                }
                            }
                        }
                    }
                }
            }
        }

    }
`;

const DELETE_MUTATION = graphql`
    mutation ProductsTable_DeleteDiscountCodeMutation($input: DeleteProductInput!, $connections: [ID!]!) {
        Admin {
            Billing{
                deleteProduct(input: $input) {
                    deletedId @deleteEdge(connections: $connections)
                }
            }
        }
    }
`;

interface OwnProps {
    productsListFragmentRef: ProductsTable_ProductsList$key
}

export const ProductsTable = ({productsListFragmentRef}: OwnProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        data,
        hasPrevious,
        hasNext,
        loadPrevious,
        loadNext
    } = usePaginationFragment<ProductsTable_Refetch, ProductsTable_ProductsList$key>(PRODUCTS_FRAGMENT, productsListFragmentRef)
    const [deleteProduct, isDeleting] = useMutation<ProductsTable_DeleteDiscountCodeMutation>(DELETE_MUTATION)

    const items = data.Admin.Billing.Products.edges?.map(x => x!.node!) || [];
    return <>
        <DataTable className="p-mb-2" value={items}>
            <Column field="title" header="Titel"/>
            <Column header="Preis" body={row => {
                return <PriceDisplay netPrice={row.price.netPrice} grossPrice={row.price.grossPrice}/>
            }}/>
            <Column header="Aktionen" style={{width: "20%"}} body={item => <>
                <Button
                    className="mr-2"
                    onClick={() => {
                        history.push(`/products/${item.id}/edit`)
                    }} icon={"pi pi-pencil"}/>

                <Button
                    disabled={isDeleting}
                    onClick={() => {
                        dispatch(showDialogAction({
                            question: "Möchtest du das Produkt wirklich löschen?",
                            title: "Produkt löschen",
                            affirmativeText: "Ja",
                            negativeText: "Nein",
                            explanation: "Dies kann nicht rückgängig gemacht werden"
                        }, result => {
                            if (result === DialogResult.ACCEPT) {
                                deleteProduct({
                                    variables: {
                                        input: {
                                            id: item.id
                                        },
                                        connections: [data.Admin.Billing.Products.__id]
                                    }
                                })
                            }
                        }))
                    }} icon={"pi pi-trash"}/>
            </>}/>
        </DataTable>

        <div className="d-flex justify-content-center align-items-center">
            <Button disabled={!hasPrevious} onClick={() => loadPrevious(20)}
                    className="mr-3 p-button-secondary">Zurück</Button>
            <Button className="p-button-secondary" disabled={!hasNext} onClick={() => loadNext(20)}>Weiter</Button>
        </div>
    </>

}
