import React, {useState} from 'react'
import DateTimePicker from 'react-datetime-picker'
import {
    DownloadBillingCsvWrapper,
    DownloadButton,
    DatetimePickerWrapper,
    LabelDisplay
} from './StyledElements'
import moment from 'moment'
import {
    selectApiBase,
    selectAuthState,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import {useSelector} from "react-redux";
import {csvDownloader} from "../../utils/utils";

interface State {
    certificateCsvDto: {
        start: Date,
        end: Date
    }
    isDownloading: boolean
}

export const DownloadCertificateCsv = () => {
    const apiBase = useSelector(selectApiBase);
    const authState = useSelector(selectAuthState)
    const axios = withAuthedAxios(apiBase, authState)

    const [state, setState] = useState<State>({
            certificateCsvDto: {
                start: moment().subtract(3, 'month').toDate(),
                end: new Date()
            },
            isDownloading: false
        }
    )

    const downloadCsv = () => {
        setState({...state, isDownloading: true})
        csvDownloader(
            axios,
            `/api/admin/v1/getCertificateCsv`,
            `certificates.csv`,
            state.certificateCsvDto,
            () => setState({...state, isDownloading: false})
        )
    }

    return (
        <DownloadBillingCsvWrapper>
            <DatetimePickerWrapper>
                <LabelDisplay>Von:</LabelDisplay>
                <DateTimePicker
                    onChange={(element: any) => setState({
                        ...state,
                        certificateCsvDto: {...state.certificateCsvDto, start: moment(element).toDate()}
                    })}
                    value={state.certificateCsvDto.start}
                />
            </DatetimePickerWrapper>
            <DatetimePickerWrapper>
                <LabelDisplay>Bis:</LabelDisplay>
                <DateTimePicker
                    onChange={(element: any) => setState({
                        ...state,
                        certificateCsvDto: {...state.certificateCsvDto, end: moment(element).toDate()}
                    })}
                    value={state.certificateCsvDto.end}
                />
            </DatetimePickerWrapper>
            <DownloadButton
                isLoading={state.isDownloading}
                name={"Download"}
                margin={{top: '1rem'}}
                border={{width: '2'}}
                onClick={downloadCsv}/>
        </DownloadBillingCsvWrapper>
    )
}