/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateDiscountCodeV2Input = {
    data: EditDiscountCode;
    id: string;
    clientMutationId?: string | null;
};
export type EditDiscountCode = {
    newCode: string;
};
export type DiscountCodeForm_EditCodeMutationVariables = {
    input: UpdateDiscountCodeV2Input;
};
export type DiscountCodeForm_EditCodeMutationResponse = {
    readonly Admin: {
        readonly Billing: {
            readonly updateDiscountCodeV2: {
                readonly data: {
                    readonly node: {
                        readonly " $fragmentRefs": FragmentRefs<"DiscountCodeForm_DiscountCodeFragment">;
                    };
                };
            } | null;
        };
    };
};
export type DiscountCodeForm_EditCodeMutation = {
    readonly response: DiscountCodeForm_EditCodeMutationResponse;
    readonly variables: DiscountCodeForm_EditCodeMutationVariables;
};



/*
mutation DiscountCodeForm_EditCodeMutation(
  $input: UpdateDiscountCodeV2Input!
) {
  Admin {
    Billing {
      updateDiscountCodeV2(input: $input) {
        data {
          node {
            ...DiscountCodeForm_DiscountCodeFragment
            id
          }
        }
      }
    }
  }
}

fragment DiscountCodeForm_DiscountCodeFragment on DiscountCodeV2 {
  id
  code
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscountCodeForm_EditCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateDiscountCodeV2Payload",
                "kind": "LinkedField",
                "name": "updateDiscountCodeV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodeV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DiscountCodeForm_DiscountCodeFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DiscountCodeForm_EditCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateDiscountCodeV2Payload",
                "kind": "LinkedField",
                "name": "updateDiscountCodeV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodeV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8bead7436ec64dce2ea34537f818cd62",
    "id": null,
    "metadata": {},
    "name": "DiscountCodeForm_EditCodeMutation",
    "operationKind": "mutation",
    "text": "mutation DiscountCodeForm_EditCodeMutation(\n  $input: UpdateDiscountCodeV2Input!\n) {\n  Admin {\n    Billing {\n      updateDiscountCodeV2(input: $input) {\n        data {\n          node {\n            ...DiscountCodeForm_DiscountCodeFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment DiscountCodeForm_DiscountCodeFragment on DiscountCodeV2 {\n  id\n  code\n}\n"
  }
};
})();
(node as any).hash = '81c420265059758526c47a82ef11c54c';
export default node;
