import { UserWithRoles } from "@thekeytechnology/framework-react";
import { EditRankForm } from "./components/EditRankForm";
import { RankOverview } from "./components/RankOverview";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";

export const RankModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/ranks",
        component: RankOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    },
    {
        path: "/ranks/:rankId/edit",
        component: EditRankForm,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    }
];
