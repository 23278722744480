import {EntityWrapper} from "@thekeytechnology/framework-react";
import {Form, Formik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {
    CustomerMessageDefinition, CustomerMessageDefinitionForSaving,
    ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION, ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION_FOR_SAVING,
    NEW_CUSTOMER_MESSAGE_DEFINITION
} from "../../model/CustomerMessage";
import {InlineAttachmentField} from "../../../core/components/attachment/InlineAttachmentField";
import {
    ButtonCta,
    ConfirmButton,
    LinkButton,
    PurchaseButton,
    PurchaseButtonForSaving, YUP_SCHEMA_BUTTONCTA
} from "../../../core/model/cta/button-cta";
import {ButtonSelector} from "./ButtonSelector";
import moment from "moment";
import { NotificationDefinitionField } from "./NotificationDefinitionField";
import { LoadingEditHeader } from "../../../core/components/header/LoadingEditHeader";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../core/components/entity/WithSingleEntityFromPath";
import { EditHeaderWithBackground } from "../../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../../core/components/containers/Container";
import { ValidatedField } from "../../../core/components/form/ValidatedField";
import { WysiwygField } from "../../../core/components/wysiwyg/WysiwygField";
import { LoadingRow } from "../../../core/components/table/LoadingRow";
import { DateField } from "../../../core/components/datetime/DateField";

type Props =
    WithSingleEntityFromPathProps<CustomerMessageDefinition, CustomerMessageDefinitionForSaving>

const EditCustomerMessageFormComponent = (props: Props) => {
    const {entity, upsertEntity} = props;

    const {t} = useTranslation(["customerMessages", "core"])

    return entity ? (
            <>
                <LoadingEditHeader entity={entity} label={t("entity.customer-message.singular")}/>

                <Formik
                    initialValues={{
                        internalTitle: entity.entity.internalTitle,
                        image: entity.entity.image,
                        title: entity.entity.title,
                        caption: entity.entity.caption,
                        text: entity.entity.text,
                        buttonCta: ((): ButtonCta => {
                            switch (entity.entity.buttonCta.dataType) {
                                case 'purchase': {
                                    return {
                                        mainOfferV2Ref: {id: (entity.entity.buttonCta as PurchaseButtonForSaving).mainOfferV2Ref},
                                        buttonTitle: entity.entity.buttonCta.buttonTitle,
                                        dataType: entity.entity.buttonCta.dataType
                                    } as PurchaseButton;
                                }
                                case 'link': {
                                    return {
                                        buttonTitle: entity.entity.buttonCta.buttonTitle,
                                        dataType: entity.entity.buttonCta.dataType,
                                        link: (entity.entity.buttonCta as LinkButton).link
                                    } as LinkButton;
                                }
                                case 'confirm': {
                                    return {
                                        dataType: entity.entity.buttonCta.dataType,
                                        buttonTitle: entity.entity.buttonCta.buttonTitle
                                    } as ConfirmButton;
                                }
                                default: {
                                    return {buttonTitle: entity.entity.buttonCta.buttonTitle} as ConfirmButton;
                                }
                            }
                        })(),
                        showUntil: entity.entity.showUntil,
                        notificationDefinition: entity.entity.notificationDefinition
                    }}
                    validateOnChange={true}
                    validationSchema={
                        Yup.lazy((values: any) =>
                            Yup.object().shape({
                                internalTitle: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.customer-message.labels.internal-title")})),
                                title: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.customer-message.labels.title")})),
                                text: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.customer-message.labels.text")})),
                                caption: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.customer-message.labels.caption")})),
                                buttonCta: YUP_SCHEMA_BUTTONCTA(values.buttonCta, t)
                            }))}
                    onSubmit={(values, {setSubmitting}) => {
                        let button
                        if (values.buttonCta.dataType === 'purchase') {
                            const purchaseBtn = values.buttonCta as PurchaseButton
                            button = new PurchaseButtonForSaving(purchaseBtn.mainOfferV2Ref.id!, values.buttonCta.buttonTitle)
                        } else if (values.buttonCta.dataType === 'link') {
                            const linkBtn = values.buttonCta as LinkButton
                            button = new LinkButton(linkBtn.link, values.buttonCta.buttonTitle)
                        } else {
                            button = new ConfirmButton(values.buttonCta.buttonTitle)
                        }

                        const saving = new EntityWrapper<CustomerMessageDefinitionForSaving>(entity.id,
                            {
                                title: values.title,
                                internalTitle: values.internalTitle,
                                image: values.image ? values.image.id : undefined,
                                caption: values.caption,
                                text: values.text,
                                buttonCta: button,
                                showUntil: values.showUntil ? moment(values.showUntil).format("YYYY-MM-DD") : undefined,
                                notificationDefinition: values.notificationDefinition
                            }
                        );
                        upsertEntity(saving);
                        setSubmitting(false);
                    }}
                >
                    {formikState => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={entity.id ?
                                    formikState.values.internalTitle :
                                    t("core:edit-header.heading-empty")}
                            >
                                <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                    backPath="/customer-message/"/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    placeholder={t("entity.customer-message.labels.internal-title")}
                                                    name="internalTitle"
                                                    className="form-control default-input"
                                                    label={t("entity.customer-message.labels.internal-title")}
                                                    required/>
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    placeholder={t("entity.customer-message.labels.title")}
                                                    name="title"
                                                    className="form-control default-input"
                                                    label={t("entity.customer-message.labels.title")}
                                                    required/>
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    placeholder={t("entity.customer-message.labels.caption")}
                                                    name="caption"
                                                    className="form-control default-input"
                                                    label={t("entity.customer-message.labels.caption")}
                                                    required/>
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    placeholder={t("entity.customer-message.labels.notification-definition")}
                                                    name="notificationDefinition"
                                                    className="form-control default-input"
                                                    component={NotificationDefinitionField}
                                                    label={t("entity.customer-message.labels.notification-definition")}
                                                    required/>
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    placeholder={t("entity.customer-message.labels.image")}
                                                    name="image"
                                                    component={InlineAttachmentField}
                                                    isSingleSelect={true}
                                                    className="form-control default-input"
                                                    label={t("entity.customer-message.labels.image")}/>
                                </div>
                                <ButtonSelector t={t} formikState={formikState}/>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    placeholder={t("entity.customer-message.labels.show-until")}
                                                    name="showUntil"
                                                    component={DateField}
                                                    label={t("entity.customer-message.labels.show-until")}
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField label={t("entity.customer-message.labels.text")}
                                                    rows="10"
                                                    name="text"
                                                    formikState={formikState}
                                                    component={WysiwygField}
                                                    config={{
                                                        extraPlugins: [],
                                                        resize_enabled: true,
                                                        height: 300,
                                                        maxHeight: 700
                                                    }}
                                                    required
                                    />
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik>
            </>
        ) :
        <LoadingRow/>;
};

export const EditCustomerDefinitionForm = WithSingleEntityFromPath<any, CustomerMessageDefinition, CustomerMessageDefinitionForSaving>(
    EditCustomerMessageFormComponent,
    ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION,
    "customerMessageId",
    NEW_CUSTOMER_MESSAGE_DEFINITION,
    undefined,
    ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION_FOR_SAVING);
