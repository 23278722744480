import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { EditFileAdditionalContentForm } from "./EditFileAdditionalContentForm";
import { EditTextAdditionalContentForm } from "./EditTextAdditionalContentForm";
import { EditVideoAdditionalContentForm } from "./EditVideoAdditionalContentForm";
import { FileAdditionalContentForSaving } from "../../model/file-additional-content-for-saving";
import {
    ADDITIONAL_CONTENT_TYPE,
    ADDITIONAL_CONTENT_TYPE_FILE,
    ADDITIONAL_CONTENT_TYPE_TEXT,
    ADDITIONAL_CONTENT_TYPE_VIDEO,
    AdditionalContent,
    TextAdditionalContent
} from "@thekeytechnology/thekey-academy-frontend-library";
import {
    FileAdditionalContent,
    VideoAdditionalContent
} from "@thekeytechnology/thekey-academy-frontend-library";
import { LoadingEditHeader } from "../../../core/components/header/LoadingEditHeader";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../core/components/entity/WithSingleEntityFromPath";
import { LoadingRow } from "../../../core/components/table/LoadingRow";

type Props =
    WithSingleEntityFromPathProps<AdditionalContent, AdditionalContent>
    & WithTranslation;

const EditAdditionalContentComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return entity ? (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.additional-content.singular")}/>

            {entity.entity.contentType === ADDITIONAL_CONTENT_TYPE_TEXT ?
                <EditTextAdditionalContentForm entity={entity as EntityWrapper<TextAdditionalContent>}
                                               onUpdate={(saved: EntityWrapper<TextAdditionalContent>) => upsertEntity(saved)}/> : null}
            {entity.entity.contentType === ADDITIONAL_CONTENT_TYPE_VIDEO ?
                <EditVideoAdditionalContentForm entity={entity as EntityWrapper<VideoAdditionalContent>}
                                                onUpdate={(saved: EntityWrapper<VideoAdditionalContent>) => upsertEntity(saved)}/> : null}
            {entity.entity.contentType === ADDITIONAL_CONTENT_TYPE_FILE ?
                <EditFileAdditionalContentForm entity={entity as EntityWrapper<FileAdditionalContent>}
                                               onUpdate={(saved: EntityWrapper<FileAdditionalContentForSaving>) => upsertEntity(saved)}/> : null}

        </>
    ) : <LoadingRow/>;
};

export const EditAdditionalContent = WithSingleEntityFromPath<{}, AdditionalContent, AdditionalContent>(
    withTranslation(["additionalContent", "core"])(EditAdditionalContentComponent),
    ADDITIONAL_CONTENT_TYPE,
    "additionalContentId");
