import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { GlossaryEntry } from "../model/glossary-entry";
import { Filters } from "../../core/components/filters/Filters";
import { TextFilter } from "../../core/components/filters/TextFilter";

const GlossaryFiltersComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <Filters entityType={GlossaryEntry.TYPE}
                 filterKeys={[`entity.title`]}>
            <TextFilter
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.glossary-entry.labels.title")})}
                entityType={GlossaryEntry.TYPE}
                property={`entity.title`}/>
        </Filters>
    );
};

export const GlossaryFilters = withTranslation(["glossary", "core"])(GlossaryFiltersComponent);
