import { CourseEditor } from "./components/course-editor/CourseEditor";
import { CourseOverview } from "./components/CourseOverview";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";
import { UserWithRoles } from "@thekeytechnology/framework-react";

export const CourseModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/courses",
        component: CourseOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/courses/:courseId/editor",
        component: CourseEditor,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    }
];
