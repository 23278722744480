import { SelectionModel } from "@thekeytechnology/framework-react";
import { FieldProps } from "formik";
import React from "react";
import { WithTranslation} from "react-i18next";
import { InlineAttachmentSelect } from "./InlineAttachmentSelect";

interface AttachmentFieldProps extends WithTranslation {
    selectionModel: SelectionModel<string>
    isSingleSelect: boolean;
}

type Props = FieldProps & AttachmentFieldProps;

export const InlineAttachmentField = ({selectionModel, isSingleSelect, form, field}: Props) => {

    return <InlineAttachmentSelect selectionModel={selectionModel}
                                   isSingleSelect={isSingleSelect}
                                   changeValue={(newValue => form.setFieldValue(field.name, newValue))}
                                   value={field.value}/>
}
