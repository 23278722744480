/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiscountActionCalcTypeKind = "percentage" | "%future added value";
export type ActionCalcTypeColumn_DiscountActionFragment = {
    readonly calcType: {
        readonly kind?: DiscountActionCalcTypeKind;
        readonly percentage?: number;
    };
    readonly " $refType": "ActionCalcTypeColumn_DiscountActionFragment";
};
export type ActionCalcTypeColumn_DiscountActionFragment$data = ActionCalcTypeColumn_DiscountActionFragment;
export type ActionCalcTypeColumn_DiscountActionFragment$key = {
    readonly " $data"?: ActionCalcTypeColumn_DiscountActionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ActionCalcTypeColumn_DiscountActionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActionCalcTypeColumn_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "calcType",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentage",
              "storageKey": null
            }
          ],
          "type": "DiscountActionPercentageCalcType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};
(node as any).hash = '7a2bd8a38f5bb33ff58a3fda0b931511';
export default node;
