import { FieldProps } from "formik";
import React from "react";
import { Course, ENTITY_TYPE_COURSE } from "../../../../../../course/model/preparing-aggregated-course";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { PriceEditor } from "./PriceEditor";
import "./upsell-options-field.scss"
import { replaceInArray } from "../../../../../../core/utils/utils";
import { UpsellOption } from "@thekeytechnology/thekey-academy-frontend-library";
import { AsyncEntitySelect } from "../../../../../../core/components/entity/AsyncEntitySelect";
import { Table, TableBody, TableColumn, TableRow } from "../../../../../../core/components/table/Table";
import { NoDataRow } from "../../../../../../core/components/table/NoDataRow";

interface OwnProps {
    showNetPrices: boolean
}

type Props = FieldProps & OwnProps

export const UpsellOptionsField = ({
                                       field,
                                       form,
                                       showNetPrices
                                   }: Props) => {
    const items = field.value as UpsellOption[];

    return <div className="upsell-options-field">
        <Table itemCount={items.length}>
            <TableBody>
                {items.length === 0 ? <NoDataRow/> : null}
                {items.map((item, index) => {
                    return <TableRow key={index} className="course-offer-item-row">
                        <TableColumn>
                            <label className="d-flex flex-column w-100">
                                <div>Menge</div>
                                <input type="number"
                                       className="form-control default-input"
                                       step={1}
                                       min={1}
                                       value={item.amount}
                                       onBlur={field.onBlur}
                                       onChange={event => {
                                           form.setFieldValue(
                                               field.name,
                                               replaceInArray(
                                                   items,
                                                   index,
                                                   {
                                                       ...item,
                                                       amount: parseFloat(event.target.value)
                                                   } as UpsellOption)
                                           )
                                       }}/>
                            </label>
                        </TableColumn>
                        <TableColumn>
                            <label className="d-flex flex-column w-100">
                                <div>Kurs</div>
                                <AsyncEntitySelect
                                    shownEntityType={ENTITY_TYPE_COURSE}
                                    shownEntityTypeProperties={["entity.containerMeta.title"]}
                                    listRenderer={(item: EntityWrapper<Course>) => item?.entity?.containerMeta?.title}
                                    selected={{id: item.courseRef} as EntityWrapper<Course>}
                                    select={newValue => {
                                        form.setFieldValue(
                                            field.name,
                                            replaceInArray(
                                                items,
                                                index,
                                                {
                                                    ...item,
                                                    courseRef: (newValue as EntityWrapper<Course>)?.id
                                                } as UpsellOption)
                                        )
                                    }}
                                    onBlur={field.onBlur}
                                    placeholder={"Kurs auswählen"}/>
                            </label>
                        </TableColumn>
                        <TableColumn>
                            <PriceEditor
                                price={item.price}
                                onBlur={field.onBlur}
                                onUpdate={newPrice => {
                                    form.setFieldValue(
                                        field.name,
                                        replaceInArray(
                                            items,
                                            index,
                                            {
                                                ...item,
                                                price: newPrice
                                            } as UpsellOption)
                                    )
                                }}/>
                        </TableColumn>
                        <TableColumn>
                            <label className="d-flex flex-column w-100">
                                <div>Beschreibung</div>
                                <input
                                    className="form-control default-input"
                                    type="text"
                                    placeholder={"Jetzt für x€ statt y€..."}
                                    value={item.description}
                                    onBlur={field.onBlur}
                                    onChange={event => {
                                        form.setFieldValue(
                                            field.name,
                                            replaceInArray(
                                                items,
                                                index,
                                                {
                                                    ...item,
                                                    description: event.target.value
                                                } as UpsellOption)
                                        )
                                    }
                                    }
                                />
                            </label>
                        </TableColumn>
                        <TableColumn>
                            <button type="button" className="btn btn-danger" onClick={() => {
                                form.setFieldValue(
                                    field.name,
                                    items.filter((i, idx) => idx !== index)
                                )
                            }}>
                                Löschen
                            </button>
                        </TableColumn>
                    </TableRow>
                })}
            </TableBody>

        </Table>

        <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-success" onClick={() => {
                form.setFieldValue(field.name, [
                    ...items,
                    {
                        amount: 1,
                        courseRef: "",
                        price: {
                            netPrice: 0,
                            taxRate: 19
                        }
                    } as UpsellOption
                ])
            }}>
                Upsell hinzufügen
            </button>
        </div>
    </div>

}
