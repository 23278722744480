import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import {ButtonCta, LinkButton} from "../../core/model/cta/button-cta";

export const ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION = "aggregated-customer-message-definition"
export const ENTITY_TYPE_CUSTOMER_MESSAGE_DEFINITION_FOR_SAVING = "customer-message-definition"


export interface NotificationDefinition {
    title: string
    body: string
}

export interface CustomerMessageDefinition {
    internalTitle: string;
    image?: EntityWrapper<TkFile>;
    title: string;
    caption: string;
    text: string;
    buttonCta: ButtonCta;
    showUntil?: string;
    notificationDefinition?: NotificationDefinition
}

export interface CustomerMessageDefinitionForSaving {
    internalTitle: string;
    image?: string;
    caption: string;
    title: string;
    text: string;
    buttonCta: ButtonCta;
    showUntil?: string;
    notificationDefinition?: NotificationDefinition
}

export const NEW_CUSTOMER_MESSAGE_DEFINITION = () => {
    return new EntityWrapper<CustomerMessageDefinition>(
        undefined,
        {
            internalTitle: "",
            title: "",
            caption: "",
            image: undefined,
            text: "",
            buttonCta: new LinkButton("", ""),
            showUntil: undefined,
            notificationDefinition: undefined
        }
    )
}
