import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {useTranslation} from "react-i18next";
import {UsersTable} from "./UsersTable";
import {Card} from "react-bootstrap";
import {UnlockModalButton} from "../unlock/UnlockModalButton";
import {ContentContainer, HeadingContainer} from "../../../core/components/containers/Container";
import {SimpleHeader} from "../../../core/components/header/SimpleHeader";
import graphql from 'babel-plugin-relay/macro'
import {useLazyLoadQuery} from "react-relay";
import {UsersScreen_Query} from "../../../../__generated__/UsersScreen_Query.graphql";

const QUERY = graphql`
    query UsersScreen_Query ($first: Int!, $after: String, $nameOrEmail: String, $userId: String){
        ...UsersTable_UsersFragment @arguments(first: $first, after: $after, nameOrEmail: $nameOrEmail, userId: $userId)
    }
`


export const UsersScreen = () => {
    const {t} = useTranslation("admin");

    const query = useLazyLoadQuery<UsersScreen_Query>(QUERY, {
        userId: null,
        name: null,
        email: null,
        first: 20
    })

    return (
        <>
            <BreadcrumbsItem to={"/users"}>{t("entity.user.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.user.plural")}/>
            </HeadingContainer>
            <ContentContainer>
                <Card className="mb-5">
                    <Card.Header>
                        <UnlockModalButton/>
                    </Card.Header>
                </Card>
                <UsersTable usersFragmentRef={query}/>
            </ContentContainer>
        </>
    )
}

