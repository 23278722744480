import { faCopy, faEllipsisV, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { Card } from "react-bootstrap";
import { DragElementWrapper, DragSourceOptions } from "react-dnd";
import { LengthBadge } from "./LengthBadge";
import { PointsBadge } from "./PointsBadge";
import { ContainerMeta, Versions } from "@thekeytechnology/thekey-academy-frontend-library";
import { VersionsBadge } from "./VersionsBadge";

interface VersionedContainer {
    entity: {
        containerMeta: ContainerMeta,
        versions: Versions
    }
}

interface OwnProps {
    itemId?: string,
    item: VersionedContainer,
    dragHandleRef: DragElementWrapper<DragSourceOptions>;
    dropMightHappen: boolean;
    title: string;
    onDelete: () => void;
    onCopy: () => void;
    children: any;
    onClick: () => void;
    badgesSlot?: () => ReactNode
}

type Props = OwnProps;

export const CourseEditorCardHeader = ({
                                           itemId,
                                           item,
                                           dragHandleRef,
                                           onDelete,
                                           title,
                                           children,
                                           dropMightHappen,
                                           onCopy,
                                           onClick,
                                           badgesSlot
                                       }: Props) => {
    return <Card.Header
        className={"d-flex flex-row align-items-center" + (dropMightHappen ? " drop-might-happen" : "")}>
        <div ref={dragHandleRef} className="drag-handle">
            <FontAwesomeIcon icon={faEllipsisV}/>
        </div>

        <button type="button" className="btn btn-link" onClick={() => onClick()}>
            {title}: {item.entity.containerMeta.title}
        </button>

        <LengthBadge lengthInSeconds={item.entity.containerMeta.length} className="ml-2 mr-2"/>
        <PointsBadge points={item.entity.containerMeta.points} className={"mr-2"}/>

        {badgesSlot ? badgesSlot() : null}

        <VersionsBadge versions={item.entity.versions} className="mr-2"/>

        <div className="small ml-2">{itemId}</div>

        <div className="edit-buttons ml-auto mr-2">
            <button className="mr-2 btn btn-secondary" onClick={onCopy}>
                <FontAwesomeIcon icon={faCopy}/>
            </button>

            <button className="btn btn-danger" onClick={onDelete}>
                <FontAwesomeIcon icon={faTrash}/>
            </button>
            {children}
        </div>
    </Card.Header>;
};
