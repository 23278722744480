import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import "./trigger-executions-table.scss";
import { ENTITY_TYPE_TRIGGER_EXECUTION, TriggerExecution } from "../../model/TriggerExecution";
import { AboveTableContainer } from "../../../core/components/containers/Container";
import { PaginationInfo } from "../../../core/components/pagination/PaginationInfo";
import { Table, TableColumn } from "../../../core/components/table/Table";
import { EntityTableHeader } from "../../../core/components/table/EntityTableHeader";
import { AsyncEntityTableBody } from "../../../core/components/table/AsyncEntityTableBody";
import { EntityTableRow } from "../../../core/components/table/EntityTableRow";
import { DateTimeDisplay } from "../../../core/components/datetime/DateTimeDisplay";
import { OverlayTooltip } from "../../../core/components/tooltip/OverlayTooltip";

type Props =
    WithEntityListProps<TriggerExecution>
    & WithTranslation
    & WithSelectionsProps<string>;

const ExpertTableComponent = ({
                                  entities,
                                  selectionModel,
                                  entityApiState,
                                  t
                              }: Props) => {

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_TRIGGER_EXECUTION} className="mr-auto"/>
            </AboveTableContainer>

            <Table itemCount={entities.length} className="trigger-executions-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.trigger-execution.labels.trigger-key")}</TableColumn>
                    <TableColumn>{t("entity.trigger-execution.labels.user")}</TableColumn>
                    <TableColumn>{t("entity.trigger-execution.labels.parameters")}</TableColumn>
                    <TableColumn>{t("entity.trigger-execution.labels.variables")}</TableColumn>
                    <TableColumn>{t("entity.trigger-execution.labels.delay")}</TableColumn>
                    <TableColumn>{t("entity.trigger-execution.labels.created-at")}</TableColumn>
                    <TableColumn>{t("entity.trigger-execution.labels.executed-at")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={triggerExecution =>
                        <EntityTableRow
                            key={triggerExecution.id}
                            entity={triggerExecution} selectionModel={selectionModel}>
                            <TableColumn className="bold title-column">
                                {triggerExecution.entity.triggerKey}
                            </TableColumn>
                            <TableColumn>
                                {triggerExecution.entity.user.entity.name}
                            </TableColumn>
                            <TableColumn>
                                <OverlayTooltip tooltip={JSON.stringify(triggerExecution.entity.parameters)}>
                                    <button type="button" className="btn-link" onClick={() => {
                                        navigator.clipboard.writeText(JSON.stringify(triggerExecution.entity.parameters))
                                    }}>{JSON.stringify(triggerExecution.entity.parameters).substr(0, 25)}..
                                    </button>
                                </OverlayTooltip>
                            </TableColumn>
                            <TableColumn>
                                <OverlayTooltip tooltip={JSON.stringify(triggerExecution.entity.variables)}>
                                    <button type="button" className="btn-link" onClick={() => {
                                        navigator.clipboard.writeText(JSON.stringify(triggerExecution.entity.variables))
                                    }}>{JSON.stringify(triggerExecution.entity.variables).substr(0, 25)}..
                                    </button>
                                </OverlayTooltip>
                            </TableColumn>
                            <TableColumn>
                                {triggerExecution.entity.customDelayInHours ? `${triggerExecution.entity.customDelayInHours}h` : "immediately"}
                            </TableColumn>
                            <TableColumn>
                                <DateTimeDisplay dateTime={triggerExecution.entity.createdAt}/>
                            </TableColumn>
                            <TableColumn>
                                <DateTimeDisplay dateTime={triggerExecution.entity.executedAt}/>
                            </TableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const TriggerExecutionsTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation("triggers")(ExpertTableComponent),
        ENTITY_TYPE_TRIGGER_EXECUTION
    )
);
