import React from 'react'
import {useDispatch} from "react-redux";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {
    Account,
    DialogResult,
    EntityWrapper,
    showDialogAction
} from "@thekeytechnology/framework-react";
import {ENTITY_TYPE_USER, User} from "../../../admin/model/User";
import {NavLink, useHistory} from "react-router-dom";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {Course, ENTITY_TYPE_COURSE} from "../../../course/model/preparing-aggregated-course";
import {EditHeaderWithBackground} from "../../../core/components/edit/EditHeaderWithBackground";
import {ContentContainer} from "../../../core/components/containers/Container";
import {ValidatedField} from "../../../core/components/form/ValidatedField";
import {AsyncEntitySelectField} from "../../../core/components/entity/AsyncEntitySelectField";
import {CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY} from "../../../core/components/entity/AsyncEntitySelect";
import {BooleanSelectField} from "../../../core/components/select/BooleanSelectField";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {CustomerMessageSender_Mutation} from "../../../../__generated__/CustomerMessageSender_Mutation.graphql";
import {useRouteMatch} from "react-router";

const MUTATION = graphql`
    mutation CustomerMessageSender_Mutation($customerMessageDefinitionRefs: [ID!]!, $targetAudience: TargetAudienceInput!) {
        Admin {
            CustomerMessage {
                sendCustomerMessages(input: {customerMessageDefinitionRefs: $customerMessageDefinitionRefs, targetAudience: $targetAudience}) {
                    clientMutationId
                }
            }
        }
    }`

const entityWrappersToIds = (entityWrappers: EntityWrapper<any>[] | undefined): string[] | null => entityWrappers?.map(ew => ew.id).filter(ewi => !!ewi).map(ewi => ewi!) || null;

interface CreateCustomerMessagesByAudienceDisplay {
    customerMessageDefinitionRefs: string[];
    targetAudience: {
        selectAccounts?: EntityWrapper<Account>[],
        selectUsers?: EntityWrapper<User>[],
        selectHaveBoughtCourses?: EntityWrapper<Course>[],
        selectHaveNotBoughtCourses?: EntityWrapper<Course>[],
        selectHaveNotBoughtButStartedCourses?: EntityWrapper<Course>[],
        selectAllUsers: boolean,
        excludeUsers?: EntityWrapper<User>[],
        excludeHaveBoughtCourses?: EntityWrapper<Course>[],
        excludeHaveNotBoughtCourses?: EntityWrapper<Course>[],
        excludeHaveNotBoughtButStartedCourses?: EntityWrapper<Course>[],
        excludeAdsOptIn?: boolean
    }
}

export const CustomerMessageSender = () => {
    const {t} = useTranslation(["customerMessages", "core"])

    const history = useHistory();

    const dispatch = useDispatch()

    const router = useRouteMatch<{ customerMessageDefinitionIdAndInternalTitles: string }>();

    const customerMessageDefinitionIdsAndInternalTitles: { id: string, internalTitle: string }[] = decodeURIComponent(router.params.customerMessageDefinitionIdAndInternalTitles).split(",").map(cmdiait => {
        const index = cmdiait.indexOf(":")
        return {
            id: cmdiait.substring(0, index),
            internalTitle: cmdiait.substring(index + 1, cmdiait.length)
        }
    })

    const [send, sendIsInFlight] = useMutation<CustomerMessageSender_Mutation>(MUTATION)

    return <>
        <Formik<CreateCustomerMessagesByAudienceDisplay>
            initialValues={{
                targetAudience: {
                    selectAllUsers: false
                },
                customerMessageDefinitionRefs: customerMessageDefinitionIdsAndInternalTitles.map(cmdiait => cmdiait.id)
            }}
            onSubmit={(values, {setSubmitting}) => {
                const sendCustomerMessage = () => {
                    send({
                        variables: {
                            customerMessageDefinitionRefs: values.customerMessageDefinitionRefs,
                            targetAudience: {
                                selectAccountIds: entityWrappersToIds(values.targetAudience.selectAccounts),
                                selectUserIds: entityWrappersToIds(values.targetAudience.selectUsers),
                                selectHaveBoughtCourses: entityWrappersToIds(values.targetAudience.selectHaveBoughtCourses),
                                selectHaveNotBoughtCourses: entityWrappersToIds(values.targetAudience.selectHaveNotBoughtCourses),
                                selectHaveNotBoughtButStartedCourses: entityWrappersToIds(values.targetAudience.selectHaveNotBoughtButStartedCourses),
                                selectAllUsers: values.targetAudience.selectAllUsers,
                                excludeUserIds: entityWrappersToIds(values.targetAudience.excludeUsers),
                                excludeHaveBoughtCourses: entityWrappersToIds(values.targetAudience.excludeHaveBoughtCourses),
                                excludeHaveNotBoughtCourses: entityWrappersToIds(values.targetAudience.excludeHaveNotBoughtCourses),
                                excludeHaveNotBoughtButStartedCourses: entityWrappersToIds(values.targetAudience.excludeHaveNotBoughtButStartedCourses),
                                excludeAdsOptIn: values.targetAudience.excludeAdsOptIn
                            }
                        }, onCompleted: () => {
                            history.push(`/customer-message/`);
                        }
                    });
                }

                if (values.targetAudience.selectAllUsers) {
                    dispatch(showDialogAction({
                        question: "Nachrichten werden an alle Nutzer abzüglich der Ausschlüsse gesendet.",
                        affirmativeButtonClass: "btn btn-danger mr-3",
                        affirmativeText: "Senden",
                        explanation: "",
                        negativeText: "Abbrechen",
                        title: "Alle Nutzer ausgewählt"
                    }, result => {
                        if (result === DialogResult.ACCEPT) sendCustomerMessage();
                    }))
                } else sendCustomerMessage();

                setSubmitting(false);
            }}
        >
            {formikState => (
                <Form>
                    <EditHeaderWithBackground
                        heading={customerMessageDefinitionIdsAndInternalTitles.length > 0 ?
                            customerMessageDefinitionIdsAndInternalTitles.map(cmdiait => cmdiait.internalTitle).reverse().join(", ") :
                            t("core:edit-header.heading-empty")}
                    >
                        <button
                            type="submit"
                            className="btn btn-primary mr-3"
                            disabled={formikState.isSubmitting || sendIsInFlight}
                        >
                            {t("entity.customer-message.buttons.send")}
                        </button>
                        <NavLink to="/customer-message">
                            <button type="button"
                                    className="btn btn-secondary">{t("entity.customer-message.buttons.cancel")}</button>
                        </NavLink>
                    </EditHeaderWithBackground>
                    <ContentContainer>
                        <div className="text-center d-flex mt-2 mb-2">
                            <hr className="flex-grow-1"/>
                            <span className="px-2 font-weight-bolder big align-self-center">
                                    Einschlüsse
                                  </span>
                            <hr className="flex-grow-1"/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.customer-message.send-customer-message.account")}
                                name="targetAudience.selectAccounts"
                                component={AsyncEntitySelectField}
                                fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/api/admin/v1/accounts/list")}
                                placeholder={"Hauptkonto"}
                                listRenderer={(value: EntityWrapper<Account>) => value?.entity?.name}
                                formikState={formikState}
                                shownEntityType={"doesnt-matter"}
                                shownEntityTypeProperties={["entity.name"]}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.customer-message.send-customer-message.user")}
                                name="targetAudience.selectUsers"
                                component={AsyncEntitySelectField}
                                placeholder={t("entity.customer-message.placeholder.select-user")}
                                listRenderer={(value: EntityWrapper<User>) => value?.entity?.email}
                                formikState={formikState}
                                shownEntityType={ENTITY_TYPE_USER}
                                shownEntityTypeProperties={["entity.email"]}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.customer-message.send-customer-message.course-bought")}
                                name="targetAudience.selectHaveBoughtCourses"
                                component={AsyncEntitySelectField}
                                placeholder={t("entity.customer-message.placeholder.choose-course")}
                                listRenderer={(value: EntityWrapper<Course>) => value?.entity?.containerMeta.title}
                                formikState={formikState}
                                shownEntityType={ENTITY_TYPE_COURSE}
                                shownEntityTypeProperties={["entity.containerMeta.title"]}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.customer-message.send-customer-message.course-not-bought")}
                                name="targetAudience.selectHaveNotBoughtCourses"
                                component={AsyncEntitySelectField}
                                placeholder={t("entity.customer-message.placeholder.choose-course")}
                                listRenderer={(value: EntityWrapper<Course>) => value?.entity?.containerMeta.title}
                                formikState={formikState}
                                shownEntityType={ENTITY_TYPE_COURSE}
                                shownEntityTypeProperties={["entity.containerMeta.title"]}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.customer-message.send-customer-message.course-not-bought-but-started")}
                                name="targetAudience.selectHaveNotBoughtButStartedCourses"
                                component={AsyncEntitySelectField}
                                placeholder={t("entity.customer-message.placeholder.choose-course")}
                                listRenderer={(value: EntityWrapper<Course>) => value?.entity?.containerMeta.title}
                                formikState={formikState}
                                shownEntityType={ENTITY_TYPE_COURSE}
                                shownEntityTypeProperties={["entity.containerMeta.title"]}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                icon={faCheckCircle}
                                label={t("entity.customer-message.send-customer-message.all-users")}
                                name="targetAudience.selectAllUsers"
                                formikState={formikState}
                                component={BooleanSelectField}
                                trueLabel={"Ja"}
                                falseLabel={"Nein"}
                                isClearable={false}
                            />
                        </div>
                        <div className="text-center d-flex mt-5 mb-2">
                            <hr className="flex-grow-1"/>
                            <span className="px-2 font-weight-bolder big align-self-center">
                                    Ausschlüsse
                                  </span>
                            <hr className="flex-grow-1"/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.customer-message.send-customer-message.user")}
                                name="targetAudience.excludeUsers"
                                component={AsyncEntitySelectField}
                                placeholder={t("entity.customer-message.placeholder.select-user")}
                                listRenderer={(value: EntityWrapper<User>) => value?.entity?.email}
                                formikState={formikState}
                                shownEntityType={ENTITY_TYPE_USER}
                                shownEntityTypeProperties={["entity.email"]}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.customer-message.send-customer-message.course-bought")}
                                name="targetAudience.excludeHaveBoughtCourses"
                                component={AsyncEntitySelectField}
                                placeholder={t("entity.customer-message.placeholder.choose-course")}
                                listRenderer={(value: EntityWrapper<Course>) => value?.entity?.containerMeta.title}
                                formikState={formikState}
                                shownEntityType={ENTITY_TYPE_COURSE}
                                shownEntityTypeProperties={["entity.containerMeta.title"]}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.customer-message.send-customer-message.course-not-bought")}
                                name="targetAudience.excludeHaveNotBoughtCourses"
                                component={AsyncEntitySelectField}
                                placeholder={t("entity.customer-message.placeholder.choose-course")}
                                listRenderer={(value: EntityWrapper<Course>) => value?.entity?.containerMeta.title}
                                formikState={formikState}
                                shownEntityType={ENTITY_TYPE_COURSE}
                                shownEntityTypeProperties={["entity.containerMeta.title"]}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                label={t("entity.customer-message.send-customer-message.course-not-bought-but-started")}
                                name="targetAudience.excludeHaveNotBoughtButStartedCourses"
                                component={AsyncEntitySelectField}
                                placeholder={t("entity.customer-message.placeholder.choose-course")}
                                listRenderer={(value: EntityWrapper<Course>) => value?.entity?.containerMeta.title}
                                formikState={formikState}
                                shownEntityType={ENTITY_TYPE_COURSE}
                                shownEntityTypeProperties={["entity.containerMeta.title"]}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField
                                icon={faCheckCircle}
                                label={t("entity.customer-message.send-customer-message.ads-opt-in")}
                                name="targetAudience.excludeAdsOptIn"
                                formikState={formikState}
                                component={BooleanSelectField}
                                trueLabel={"Ja"}
                                falseLabel={"Nein"}
                            />
                        </div>
                    </ContentContainer>
                </Form>
            )}
        </Formik>
    </>
}
