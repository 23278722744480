/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment = {
    readonly sumGrossPriceWithDiscounts: number;
    readonly sumGrossPriceWithoutDiscounts: number;
    readonly " $refType": "DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment";
};
export type DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment$data = DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment;
export type DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment$key = {
    readonly " $data"?: DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sumGrossPriceWithDiscounts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sumGrossPriceWithoutDiscounts",
      "storageKey": null
    }
  ],
  "type": "SearchDiscountCodeUsagesResult",
  "abstractKey": null
};
(node as any).hash = 'fdeec8c48a256f557a5b2906e134d40e';
export default node;
