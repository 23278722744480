/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrderStatus = "hasBillingDetails" | "hasCart" | "hasPaymentDetails" | "paymentReceived" | "purchased" | "transient" | "%future added value";
export type OrderHistoryTableV3_OrderFragment = {
    readonly history: ReadonlyArray<{
        readonly additionalInformation: string | null;
        readonly dateTime: string;
        readonly message: string;
        readonly newStatus: OrderStatus | null;
    }>;
    readonly " $refType": "OrderHistoryTableV3_OrderFragment";
};
export type OrderHistoryTableV3_OrderFragment$data = OrderHistoryTableV3_OrderFragment;
export type OrderHistoryTableV3_OrderFragment$key = {
    readonly " $data"?: OrderHistoryTableV3_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"OrderHistoryTableV3_OrderFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderHistoryTableV3_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderHistoryElement",
      "kind": "LinkedField",
      "name": "history",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "additionalInformation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
(node as any).hash = '42ae19d545fcf28b8d4bed75334916dd';
export default node;
