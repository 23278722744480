import { EntityWrapper, TkFile } from "@thekeytechnology/framework-react";

export class Expert {
    public static TYPE = "aggregated-expert";

    public static new = () => new EntityWrapper<Expert>(
        undefined,
        new Expert(
            "",
            undefined,
            "",
            ""
        )
    );

    public constructor(
        public name: string,
        public image: EntityWrapper<TkFile> | undefined,
        public position: string,
        public biography: string,
    ) {
    }
}
