import { EntityWrapper } from "@thekeytechnology/framework-react";

export function equalById(x: EntityWrapper<any>, y: EntityWrapper<any>) {
    if (x.id && y.id) {
        return x.id === y.id;
    } else if (x.temporaryId && y.temporaryId) {
        return x.temporaryId === y.temporaryId;
    } else {
        return false;
    }
}

export function anyIdMatches(x: EntityWrapper<any>, id: string) {
    return x.id === id || x.temporaryId === id
}

export function extractId(x: EntityWrapper<any>): string {
    return (x.id ? x.id : x.temporaryId) as string;
}
