import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_LICENSE_POOL_ADMIN = "fetch-license-pool-admin";

export const fetchLicensePoolAdminAction = (accountId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_LICENSE_POOL_ADMIN,
            callType: API_FETCH_LICENSE_POOL_ADMIN
        },
        payload: accountId,
    } as ApiCallAction<string>;
};
