/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CustomerMessageTableComponent_RefetchVariables = {
    after?: string | null;
    first: number;
    title: string;
};
export type CustomerMessageTableComponent_RefetchResponse = {
    readonly " $fragmentRefs": FragmentRefs<"CustomerMessageDefinitionOverview_PaginationFragment">;
};
export type CustomerMessageTableComponent_Refetch = {
    readonly response: CustomerMessageTableComponent_RefetchResponse;
    readonly variables: CustomerMessageTableComponent_RefetchVariables;
};



/*
query CustomerMessageTableComponent_Refetch(
  $after: String
  $first: Int!
  $title: String!
) {
  ...CustomerMessageDefinitionOverview_PaginationFragment_2PG6LC
}

fragment CustomerMessageDefinitionOverview_PaginationFragment_2PG6LC on Query {
  Admin {
    CustomerMessage {
      CustomerMessageDefinitions(title: $title, first: $first, after: $after) {
        edges {
          node {
            id
            rawId
            internalTitle
            title
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "title"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "title",
    "variableName": "title"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageTableComponent_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "CustomerMessageDefinitionOverview_PaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerMessageTableComponent_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageAdminSchema",
            "kind": "LinkedField",
            "name": "CustomerMessage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CustomerMessageDefinitionsConnection",
                "kind": "LinkedField",
                "name": "CustomerMessageDefinitions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "title"
                ],
                "handle": "connection",
                "key": "CustomerMessageTableComponent_CustomerMessageDefinitions",
                "kind": "LinkedHandle",
                "name": "CustomerMessageDefinitions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "27a7711eef4cfd639d00d78b677abb0d",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageTableComponent_Refetch",
    "operationKind": "query",
    "text": "query CustomerMessageTableComponent_Refetch(\n  $after: String\n  $first: Int!\n  $title: String!\n) {\n  ...CustomerMessageDefinitionOverview_PaginationFragment_2PG6LC\n}\n\nfragment CustomerMessageDefinitionOverview_PaginationFragment_2PG6LC on Query {\n  Admin {\n    CustomerMessage {\n      CustomerMessageDefinitions(title: $title, first: $first, after: $after) {\n        edges {\n          node {\n            id\n            rawId\n            internalTitle\n            title\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7085004738eed32fac7f6bab839462c0';
export default node;
