import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_REMOVE_LICENSE_ADMIN = "remove-licenses-admin";

export const removeLicenseAction = (licenseId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_REMOVE_LICENSE_ADMIN,
            callType: API_REMOVE_LICENSE_ADMIN
        },
        payload: licenseId,
    } as ApiCallAction<string>;
};
