import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { Lesson } from "../../model/lesson/lesson";

export const EDIT_LESSON_ACTION = "edit-lesson";

export interface EditLessonAction extends Action {
    lesson: EntityWrapper<Lesson>
    parentModuleId: string;
}

export const editLessonAction = (parentModuleId: string, lesson: EntityWrapper<Lesson>) => ({
    type: EDIT_LESSON_ACTION,
    parentModuleId,
    lesson
}) as EditLessonAction;
