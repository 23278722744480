import React from "react";
import graphql from "babel-plugin-relay/macro"
import {DiscountActionsTable} from "./table/DiscountActionsTable";
import {useLazyLoadQuery} from "react-relay";
import {DiscountActionSearchContainer} from "./table/filter/DiscountActionSearchContext";
import {DiscountActionsScreen_Query} from "../../../../../__generated__/DiscountActionsScreen_Query.graphql";

const QUERY = graphql`
    query DiscountActionsScreen_Query($after: String, $before: String, $filterByDiscountTypeKind: DiscountTypeKindInput, $filterByActionTitle: String, $filterByCode: String,  $first: Int, $last: Int) {
        ... DiscountActionsTable_QueryFragment @arguments(first: $first, last: $last, before: $before, after: $after, filterByDiscountTypeKind: $filterByDiscountTypeKind, filterByActionTitle: $filterByActionTitle, filterByCode: $filterByCode)
    }
`

export const DiscountActionsScreen = () => {
    const query = useLazyLoadQuery<DiscountActionsScreen_Query>(QUERY, {first: 20})
    return (
        <div>
            <h2>Discount Aktionen</h2>
            <DiscountActionSearchContainer>
                <DiscountActionsTable discountActionsQueryFragment={query}/>
            </DiscountActionSearchContainer>
        </div>
    )
}
