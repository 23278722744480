import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Account, EntityWrapper } from "@thekeytechnology/framework-react";
import { Course, ENTITY_TYPE_COURSE } from "../../../course/model/preparing-aggregated-course";
import { UnlockComponentField } from "./UnlockComponentField";
import { ModalBody, ModalFooter } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { massUnlockAction, UnlockUser } from "../../actions/mass-unlock";
import { ValidatedField } from "../../../core/components/form/ValidatedField";
import { AsyncEntitySelectField } from "../../../core/components/entity/AsyncEntitySelectField";
import { CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY } from "../../../core/components/entity/AsyncEntitySelect";

interface OwnProps {
    onClose: () => void;
    accountId?: string;
}

interface FormState {
    account: EntityWrapper<Account> | undefined
    accountName?: string
    courses: EntityWrapper<Course>[]
    unlockComponents: UnlockUser[]
}

export const UnlockForm = ({onClose, accountId}: OwnProps) => {
    const {t} = useTranslation("admin")
    const dispatch = useDispatch();

    return <Formik<FormState>
        initialValues={{
            account: accountId ? {id: accountId} as EntityWrapper<Account> : undefined,
            accountName: "Neues Konto",
            courses: [],
            unlockComponents: []
        }}
        onSubmit={(values, {setSubmitting}) => {
            dispatch(massUnlockAction({
                accountId: values.account?.id,
                accountName: values.accountName,
                courseIds: values.courses.map(course => course.id!),
                users: values.unlockComponents.map(u => ({
                    ...u,
                    email: u.email?.trim().toLocaleLowerCase()
                } as UnlockUser))
            }))
            onClose();
            setSubmitting(false);
        }}
    >
        {formikState => (
            <Form>
                <ModalBody>
                    <h3>Massenfreischaltung</h3>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("unlock-form.labels.account-id")}
                            name="account"
                            component={AsyncEntitySelectField}
                            fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/api/admin/v1/accounts/list")}
                            placeholder={"Kein bestehendes Konto"}
                            listRenderer={(value: EntityWrapper<Account>) => value?.entity?.name}
                            formikState={formikState}
                            shownEntityType={"doesnt-matter"}
                            shownEntityTypeProperties={["entity.name"]}
                            isMulti={false}
                            isClearable={true}
                        />
                    </div>
                    {!formikState.values.account ?
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("unlock-form.labels.account-name")}
                                            type="text"
                                            name="accountName"
                                            className="form-control default-input"
                                            label={t("unlock-form.labels.account-name")}
                                            required/>
                        </div> : null}
                    <div className="form-group row">
                        <ValidatedField
                            label={t("unlock-form.labels.course-ids")}
                            name="courses"
                            component={AsyncEntitySelectField}
                            placeholder={"Kurse freischalten"}
                            listRenderer={(value: EntityWrapper<Course>) => value?.entity?.containerMeta.title}
                            formikState={formikState}
                            shownEntityType={ENTITY_TYPE_COURSE}
                            shownEntityTypeProperties={["entity.containerMeta.title"]}
                            isMulti={true}
                            isClearable={true}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("unlock-form.labels.unlock-components")}
                            name="unlockComponents"
                            component={UnlockComponentField}
                            placeholder={"Kurse freischalten"}
                            formikState={formikState}
                        />
                    </div>


                </ModalBody>

                <ModalFooter>
                    <button className="btn btn-outline-secondary" type={"button"} onClick={() => onClose()}>
                        Abbrechen
                    </button>

                    <button className={"btn btn-success"}>
                        Freischalten
                    </button>
                </ModalFooter>
            </Form>
        )}
    </Formik>
}
