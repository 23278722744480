/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CourseBundleTable_RefetchVariables = {
    after?: string | null;
    filterByTitle?: string | null;
    first?: number | null;
};
export type CourseBundleTable_RefetchResponse = {
    readonly " $fragmentRefs": FragmentRefs<"CourseBundleTable_CourseBundleList">;
};
export type CourseBundleTable_Refetch = {
    readonly response: CourseBundleTable_RefetchResponse;
    readonly variables: CourseBundleTable_RefetchVariables;
};



/*
query CourseBundleTable_Refetch(
  $after: String
  $filterByTitle: String
  $first: Int
) {
  ...CourseBundleTable_CourseBundleList_1sW96K
}

fragment CourseBundleTable_CourseBundleList_1sW96K on Query {
  Admin {
    Bundle {
      CourseBundles(first: $first, after: $after, filterByTitle: $filterByTitle) {
        pageInfo {
          endCursor
          hasPreviousPage
          hasNextPage
          startCursor
        }
        edges {
          node {
            id
            title
            __typename
          }
          cursor
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByTitle"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterByTitle",
    "variableName": "filterByTitle"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseBundleTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "CourseBundleTable_CourseBundleList"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseBundleTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BundleAdminSchema",
            "kind": "LinkedField",
            "name": "Bundle",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CourseBundlesConnection",
                "kind": "LinkedField",
                "name": "CourseBundles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseBundlesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseBundle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "filterByTitle"
                ],
                "handle": "connection",
                "key": "CourseBundleTable_CourseBundles",
                "kind": "LinkedHandle",
                "name": "CourseBundles"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f471abdaa9e1fd879bca53ef7d23d78d",
    "id": null,
    "metadata": {},
    "name": "CourseBundleTable_Refetch",
    "operationKind": "query",
    "text": "query CourseBundleTable_Refetch(\n  $after: String\n  $filterByTitle: String\n  $first: Int\n) {\n  ...CourseBundleTable_CourseBundleList_1sW96K\n}\n\nfragment CourseBundleTable_CourseBundleList_1sW96K on Query {\n  Admin {\n    Bundle {\n      CourseBundles(first: $first, after: $after, filterByTitle: $filterByTitle) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            title\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '02533745f671ea6fa7f6484e35f0ada5';
export default node;
