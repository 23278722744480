import React, {Suspense} from 'react'
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery, useMutation} from "react-relay";
import {useRouteMatch} from "react-router";
import {ValidatedFieldV2} from "../../../core/components/form/ValidatedFieldV2";
import {Account, AccountsSelect} from '../../../core/components/entity-selects/AccountsSelect';
import {Card} from "primereact/card";
import {User, UsersSelect} from "../../../core/components/entity-selects/UsersSelect";
import {Course, CoursesSelect} from "../../../core/components/entity-selects/CoursesSelect";
import {Dropdown} from "primereact/dropdown";
import {Header} from "../../../core/components/prime-react/Header";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import {ProgressSpinner} from "primereact/progressspinner";
import {CustomerMessageSenderScreen_Query} from "../../../../__generated__/CustomerMessageSenderScreen_Query.graphql";
import {CustomerMessageSenderScreen_sendCustomerMessagesMutation} from "../../../../__generated__/CustomerMessageSenderScreen_sendCustomerMessagesMutation.graphql";

const QUERY = graphql`
    query CustomerMessageSenderScreen_Query($customerMessageDefinitionV2Id: ID!) {
        node(id: $customerMessageDefinitionV2Id) {
            id
            ... on CustomerMessageDefinitionV2 {
                internalTitle
            }
        }
        ...AccountsSelect_QueryFragment @arguments(ids: [])
        ...UsersSelect_QueryFragment @arguments(ids: [])
        ...CoursesSelect_QueryFragment @arguments(ids: [])
    }`

const SEND_CUSTOMER_MESSAGES_MUTATION = graphql`
    mutation CustomerMessageSenderScreen_sendCustomerMessagesMutation($customerMessageDefinitionId: ID!, $targetAudience: TargetAudienceInputV2!) {
        Admin {
            CustomerMessageV2 {
                sendCustomerMessages(input: {customerMessageDefinitionId: $customerMessageDefinitionId, targetAudience: $targetAudience}) {
                    clientMutationId
                }
            }
        }
    }`

interface TargetAudience {
    selectAccounts: Account[]
    selectUsers: User[]
    selectHaveBoughtCourses: Course[]
    selectHaveNotBoughtCourses: Course[]
    selectHaveNotBoughtButStartedCourses: Course[]
    selectAllUsers: boolean
    excludeUsers: User[]
    excludeHaveBoughtCourses: Course[]
    excludeHaveNotBoughtCourses: Course[]
    excludeHaveNotBoughtButStartedCourses: Course[],
    excludeAdsOptIn?: boolean
}

export const CustomerMessageSenderScreen = () => {

    const {t: tCMV2} = useTranslation("customerMessagesV2")

    const history = useHistory()

    const router = useRouteMatch<{ customerMessageDefinitionId: string }>();

    const query = useLazyLoadQuery<CustomerMessageSenderScreen_Query>(QUERY, {
        customerMessageDefinitionV2Id: router.params.customerMessageDefinitionId
    })

    const [send, isSending] = useMutation<CustomerMessageSenderScreen_sendCustomerMessagesMutation>(SEND_CUSTOMER_MESSAGES_MUTATION)

    const formik = useFormik<TargetAudience>({
        initialValues: {
            selectAccounts: [],
            selectUsers: [],
            selectHaveBoughtCourses: [],
            selectHaveNotBoughtCourses: [],
            selectHaveNotBoughtButStartedCourses: [],
            selectAllUsers: false,
            excludeUsers: [],
            excludeHaveBoughtCourses: [],
            excludeHaveNotBoughtCourses: [],
            excludeHaveNotBoughtButStartedCourses: []
        },
        onSubmit: (values, {setSubmitting}) => {
            if (!query.node) {
                setSubmitting(false)
                return
            }

            const sendCustomerMessage = () => {
                send({
                    variables: {
                        customerMessageDefinitionId: query.node!.id,
                        targetAudience: {
                            selectAccountIds: values.selectAccounts.map(a => a.id),
                            selectUserIds: values.selectUsers.map(u => u.id),
                            selectHaveBoughtCourseIds: values.selectHaveBoughtCourses.map(c => c.id),
                            selectHaveNotBoughtCourseIds: values.selectHaveNotBoughtCourses.map(c => c.id),
                            selectHaveNotBoughtButStartedCourseIds: values.selectHaveNotBoughtButStartedCourses.map(c => c.id),
                            selectAllUsers: values.selectAllUsers,
                            excludeUserIds: values.excludeUsers.map(u => u.id),
                            excludeHaveBoughtCourseIds: values.excludeHaveBoughtCourses.map(c => c.id),
                            excludeHaveNotBoughtCourseIds: values.excludeHaveNotBoughtCourses.map(c => c.id),
                            excludeHaveNotBoughtButStartedCourseIds: values.excludeHaveNotBoughtButStartedCourses.map(c => c.id),
                            excludeAdsOptIn: values.excludeAdsOptIn
                        }
                    }, onCompleted: () => {
                        history.push(`/customer-message-v2/`);
                    }
                });
            }

            if (values.selectAllUsers) {

                confirmDialog({
                    message: tCMV2("sender.send-to-all-users-dialog.message"),
                    header: tCMV2("sender.send-to-all-users-dialog.header"),
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: tCMV2("sender.send-to-all-users-dialog.accept-label"),
                    rejectLabel: tCMV2("sender.send-to-all-users-dialog.reject-label"),
                    acceptClassName: "p-button-danger",
                    accept: () => sendCustomerMessage()
                })
            } else sendCustomerMessage()

            setSubmitting(false)
        }
    })

    return query.node ? <Card>
        <Suspense fallback={<ProgressSpinner/>}>
            <Header heading={tCMV2("sender.heading", {internalTitle: query.node?.internalTitle})}/>

            {!isSending ? <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <Card>
                        <h5>{tCMV2("sender.includes")}</h5>

                        <ValidatedFieldV2<TargetAudience, Account[]>
                            name={"selectAccounts"}
                            label={tCMV2("sender.select-accounts")}
                            component={({fieldValue, updateField}) => {
                                return <Suspense fallback={<ProgressSpinner/>}>
                                    <AccountsSelect fieldValue={fieldValue || []} onChange={accounts => {
                                        updateField(accounts)
                                    }} queryFragmentRef={query}/>
                                </Suspense>
                            }}
                            formikConfig={formik}
                        />

                        <ValidatedFieldV2<TargetAudience, User[]>
                            name={"selectUsers"}
                            label={tCMV2("sender.select-users")}
                            component={({fieldValue, updateField}) => {
                                return <Suspense fallback={<ProgressSpinner/>}>
                                    <UsersSelect fieldValue={fieldValue || []} onChange={accounts => {
                                        updateField(accounts)
                                    }} queryFragmentRef={query}/>
                                </Suspense>
                            }}
                            formikConfig={formik}
                        />
                        <ValidatedFieldV2<TargetAudience, Course[]>
                            name={"selectHaveBoughtCourses"}
                            label={tCMV2("sender.select-have-bought-courses")}
                            component={({fieldValue, updateField}) => {
                                return <Suspense fallback={<ProgressSpinner/>}>
                                    <CoursesSelect fieldValue={fieldValue || []}
                                                   onChange={courses => {
                                                       updateField(courses)
                                                   }} queryFragmentRef={query}/>
                                </Suspense>
                            }}
                            formikConfig={formik}
                        />
                        <ValidatedFieldV2<TargetAudience, Course[]>
                            name={"selectHaveNotBoughtCourses"}
                            label={tCMV2("sender.select-have-not-bought-courses")}
                            component={({fieldValue, updateField}) => {
                                return <Suspense fallback={<ProgressSpinner/>}>
                                    <CoursesSelect fieldValue={fieldValue || []} onChange={courses => {
                                        updateField(courses)
                                    }} queryFragmentRef={query}/>
                                </Suspense>
                            }}
                            formikConfig={formik}
                        />
                        <ValidatedFieldV2<TargetAudience, Course[]>
                            name={"selectHaveNotBoughtButStartedCourses"}
                            label={tCMV2("sender.select-have-not-bought-but-started-courses")}
                            component={({fieldValue, updateField}) => {
                                return <Suspense fallback={<ProgressSpinner/>}>
                                    <CoursesSelect fieldValue={fieldValue || []} onChange={courses => {
                                        updateField(courses)
                                    }} queryFragmentRef={query}/></Suspense>
                            }}
                            formikConfig={formik}
                        />
                        <ValidatedFieldV2<TargetAudience, boolean>
                            name={"selectAllUsers"}
                            label={tCMV2("sender.select-all-users")}
                            component={({fieldValue, updateField}) => {
                                return <Suspense fallback={<ProgressSpinner/>}>
                                    <Dropdown
                                        value={fieldValue}
                                        options={[
                                            {
                                                label: "Nein",
                                                value: false
                                            },
                                            {
                                                label: "Ja",
                                                value: true
                                            }
                                        ]}
                                        onChange={e => updateField(e.value)}
                                    />
                                </Suspense>
                            }}
                            formikConfig={formik}
                        />
                    </Card>
                    <Card className="mt-5">
                        <h5>{tCMV2("sender.excludes")}</h5>

                        <ValidatedFieldV2<TargetAudience, User[]>
                            name={"excludeUsers"}
                            label={tCMV2("sender.exclude-users")}
                            component={({fieldValue, updateField}) => {
                                return <Suspense fallback={<ProgressSpinner/>}>
                                    <UsersSelect fieldValue={fieldValue || []} onChange={accounts => {
                                        updateField(accounts)
                                    }} queryFragmentRef={query}/>
                                </Suspense>
                            }}
                            formikConfig={formik}
                        />
                        <ValidatedFieldV2<TargetAudience, Course[]>
                            name={"excludeHaveBoughtCourses"}
                            label={tCMV2("sender.exclude-have-bought-courses")}
                            component={({fieldValue, updateField}) => {
                                return <Suspense fallback={<ProgressSpinner/>}>
                                    <CoursesSelect fieldValue={fieldValue || []} onChange={courses => {
                                        updateField(courses)
                                    }} queryFragmentRef={query}/>
                                </Suspense>
                            }}
                            formikConfig={formik}
                        />
                        <ValidatedFieldV2<TargetAudience, Course[]>
                            name={"excludeHaveNotBoughtCourses"}
                            label={tCMV2("sender.exclude-have-not-bought-courses")}
                            component={({fieldValue, updateField}) => {
                                return <Suspense fallback={<ProgressSpinner/>}>
                                    <CoursesSelect fieldValue={fieldValue || []} onChange={courses => {
                                        updateField(courses)
                                    }} queryFragmentRef={query}/>
                                </Suspense>
                            }}
                            formikConfig={formik}
                        />
                        <ValidatedFieldV2<TargetAudience, Course[]>
                            name={"excludeHaveNotBoughtButStartedCourses"}
                            label={tCMV2("sender.exclude-have-not-bought-but-started-courses")}
                            component={({fieldValue, updateField}) => {
                                return <Suspense fallback={<ProgressSpinner/>}>
                                    <CoursesSelect fieldValue={fieldValue || []} onChange={courses => {
                                        updateField(courses)
                                    }} queryFragmentRef={query}/>
                                </Suspense>
                            }}
                            formikConfig={formik}
                        />
                        <ValidatedFieldV2<TargetAudience, boolean>
                            name={"excludeAdsOptIn"}
                            label={tCMV2("sender.exclude-ads-opt-in")}
                            component={({fieldValue, updateField}) => {
                                return <Suspense fallback={<ProgressSpinner/>}>
                                    <Dropdown
                                        value={fieldValue}
                                        options={[
                                            {
                                                label: "Nein",
                                                value: false
                                            },
                                            {
                                                label: "Ja",
                                                value: true
                                            }
                                        ]}
                                        onChange={e => updateField(e.value)}
                                    />
                                </Suspense>
                            }}
                            formikConfig={formik}
                        />
                    </Card>

                    <div className="flex pt-5">
                        <Button
                            disabled={false}
                            type="submit"
                            label={tCMV2("sender.buttons.send")}
                            className="mr-5"/>
                        <Button
                            disabled={false}
                            onClick={() => history.push("/customer-message-v2")}
                            label={tCMV2("sender.buttons.close")}
                            className="ml-5 p-button-secondary"/>
                    </div>
                </form> :
                <ProgressSpinner/>
            }
        </Suspense>
    </Card> : undefined
}
