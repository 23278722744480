import {faPlusCircle, faTrash, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface OwnProps {
    currentTexts: string[];
    updatedTexts: (newPhrasing?: string[]) => void;
    icon?: IconDefinition;

    inputFieldPlaceholder: string;
    removeTooltip: string;
    addTooltip: string;
}

interface State {
    currentLines: string;
}

type Props = OwnProps;

export const MultipleTexts = ({
                                  currentTexts,
                                  updatedTexts,
                                  icon,
                                  inputFieldPlaceholder,
                                  removeTooltip,
                                  addTooltip,
                              }: Props
) => {

    const [state, setState] = useState<State>({
        currentLines: ""
    });

    const addLine = () => {
        if (state.currentLines) {
            updatedTexts(currentTexts.concat(state.currentLines));
            setState({
                ...state,
                currentLines: ""
            });
        }
    };

    const removeLine = (line: string) => {
        const newPhrasings = currentTexts.filter(alternative => alternative !== line);
        if (newPhrasings.length === 0) {
            updatedTexts(undefined);
        } else {
            updatedTexts(newPhrasings);
        }
    };

    const changeCurrentLine = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (newValue) {
            setState({
                ...state,
                currentLines: event.target.value
            });
        } else {
            setState({
                ...state,
                currentLines: ""
            });
        }
    };

    const InputField = <input
        onKeyPress={e => {
            if (e.key === "Enter") {
                e.preventDefault();
                if (state.currentLines) {
                    addLine()
                }
            }
        }}
        className="form-control default-input"
        type="text"
        placeholder={inputFieldPlaceholder}
        value={state.currentLines}
        onChange={changeCurrentLine}/>;

    return (
        <div className="phrasing-alternatives d-flex flex-column w-100">
            {currentTexts.map(alternative =>
                <p className="mb-2" key={alternative}>
                    {alternative}
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-remove`}>
                                {removeTooltip}
                            </Tooltip>
                        }
                    >
                        <button type="button" className="btn-link text-danger ml-2" onClick={() => removeLine(alternative)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </button>
                    </OverlayTrigger>

                </p>
            )}
            <div className="new-alternative d-flex align-items-center">
                {
                    icon ? <div className="input-field-wrapper w-100">
                        <FontAwesomeIcon className="input-icon" icon={icon}/>
                        {InputField}
                    </div> : InputField
                }

                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-add-phrasing`}>
                            {addTooltip}
                        </Tooltip>
                    }
                >
                    <button type="button"
                            className="ml-2 text-success btn btn-link"
                            disabled={state.currentLines === undefined}
                            onClick={addLine}>
                        <FontAwesomeIcon icon={faPlusCircle}/>
                    </button>
                </OverlayTrigger>
            </div>

        </div>
    );
};
