import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { FileAdditionalContentForSaving } from "../../model/file-additional-content-for-saving";
import { InlineAttachmentField } from "../../../core/components/attachment/InlineAttachmentField";
import { ADDITIONAL_CONTENT_TYPE_FILE, FileAdditionalContent } from "@thekeytechnology/thekey-academy-frontend-library";
import { EditHeaderWithBackground } from "../../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../../core/components/containers/Container";
import { ValidatedField } from "../../../core/components/form/ValidatedField";

interface OwnProps {
    entity: EntityWrapper<FileAdditionalContent>
    onUpdate: (entity: EntityWrapper<FileAdditionalContentForSaving>) => void;
}

type Props = OwnProps
    & WithTranslation;

const EditFileAdditionalContentFormComponent = ({
                                                    entity,
                                                    onUpdate,
                                                    t
                                                }: Props) => {

    return <Formik
        initialValues={{
            title: entity.entity.title,
            file: entity.entity.file
        }}
        validationSchema={Yup.object().shape({
            title: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.additional-content.labels.title")})),
            file: Yup.object().required(t("entity.additional-content.labels.file-id-ref"))
        })}
        onSubmit={(values, {setSubmitting}) => {
            const saving = new EntityWrapper<FileAdditionalContentForSaving>(entity.id,
                {
                    contentType: ADDITIONAL_CONTENT_TYPE_FILE,
                    title: values.title,
                    file: values.file!.id!,
                    versions: entity.entity.versions
                }
            );
            onUpdate(saving);
            setSubmitting(false);
        }}
    >
        {formikState => (
            <Form>
                <EditHeaderWithBackground
                    heading={entity.id ?
                        formikState.values.title :
                        t("core:edit-header.heading-empty")}
                >
                    <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                        backPath="/additional-content/"/>
                </EditHeaderWithBackground>
                <ContentContainer>
                    <div className="form-group row">
                        <ValidatedField
                            formikState={formikState}
                            placeholder={t("entity.additional-content.labels.title")}
                            name="title"
                            className="form-control default-input"
                            label={t("entity.additional-content.labels.title")}
                            required/>
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("entity.additional-content.labels.file-id-ref")}
                            name="file"
                            formikState={formikState}
                            isSingleSelect={true}
                            component={InlineAttachmentField}
                            required
                        />
                    </div>
                </ContentContainer>
            </Form>
        )}
    </Formik>;
};

export const EditFileAdditionalContentForm = withTranslation(["additionalContent", "core"])(EditFileAdditionalContentFormComponent);
