import React from "react"
import { CurrencyDisplay } from "../../../../../core/components/CurrencyDisplay";

interface OwnProps {
    netPrice: number
    grossPrice?: number
    taxRate?: number
    showNetPrice?: boolean
}

export const PriceWithTaxDisplay = ({netPrice, showNetPrice, taxRate, grossPrice}: OwnProps) => {
    const taxRateToUse = taxRate ? taxRate : 0
    const displayPrice = showNetPrice ? netPrice : (grossPrice ? grossPrice : (netPrice * ((100 + taxRateToUse) / 100)))
    return <CurrencyDisplay value={displayPrice}/>
}
