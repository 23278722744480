import {FieldProps} from "formik";
import React from "react";
import {
    APPLICATION_RULE_LIMIT_FOR_ENTIRE_ACCOUNT,
    APPLICATION_RULE_TYPE_LIMIT_FOR_USERS,
    ApplicationRule,
    LimitForEntireAccountApplicationRule,
    LimitForUsersApplicationRule
} from "../../../../../licenses/model/ApplicationRule";
import {useTranslation} from "react-i18next";
import "./application-rules-field.scss"
import {LimitForUserApplicationRuleRow} from "./LimitForUserApplicationRuleRow";
import {LimitForEntireAccountApplicationRuleRow} from "./LimitForEntireAccountApplicationRuleRow";
import {Table, TableBody} from "../../../../../core/components/table/Table";
import {NoDataRow} from "../../../../../core/components/table/NoDataRow";

type Props = FieldProps;

export const ApplicationRulesField = ({form, field, meta}: Props) => {
    const {t} = useTranslation("admin")
    const items = field.value as ApplicationRule[]

    return <div className="application-rules-field">
        <Table itemCount={items.length}>
            <TableBody>
                {items.length === 0 ? <NoDataRow/> : null}
                {items.map((item, index) => {
                    switch (item.ruleType) {
                        case APPLICATION_RULE_TYPE_LIMIT_FOR_USERS:
                            return <LimitForUserApplicationRuleRow
                                key={index}
                                index={index} items={items}
                                item={item as LimitForUsersApplicationRule}
                                field={field} form={form} meta={meta}/>
                        case APPLICATION_RULE_LIMIT_FOR_ENTIRE_ACCOUNT:
                            return <LimitForEntireAccountApplicationRuleRow
                                key={index}
                                index={index} items={items}
                                item={item as LimitForEntireAccountApplicationRule}
                                field={field} form={form} meta={meta}/>
                    }
                    return null;
                })}
            </TableBody>

        </Table>

        <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-success mr-3" onClick={() => {
                form.setFieldValue(field.name, [
                    ...items,
                    {
                        limitAmount: 1,
                        limitToUserRole: undefined,
                        limitToAccountType: undefined,
                        canRequestMore: false,
                        ruleType: APPLICATION_RULE_TYPE_LIMIT_FOR_USERS
                    } as LimitForUsersApplicationRule
                ])
            }}>
                {t("entity.license-pool.labels.application-rules-field.add-new-limit-for-users")}
            </button>

            <button type="button" className="btn btn-success" onClick={() => {
                form.setFieldValue(field.name, [
                    ...items,
                    {
                        limitAmount: 0,
                        limitToAccountType: "",
                        ruleType: APPLICATION_RULE_LIMIT_FOR_ENTIRE_ACCOUNT
                    } as LimitForEntireAccountApplicationRule
                ])
            }}>
                {t("entity.license-pool.labels.application-rules-field.add-new-limit-for-account")}
            </button>
        </div>
    </div>
};
