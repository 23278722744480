import {
    addFiltersAction,
    EntityListResolvedPayload,
    EntityWrapper,
    fetchEntityList,
    Filter,
    FilterTerm,
    OrFilter,
    PropertyFilter,
    removeFiltersAction, selectApiBase,
    selectAuthState,
    selectFilter,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import React, { Component, useRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import { AxiosInstance } from "axios";
import { map } from "rxjs/operators";
import { ClearIndicator } from "../select/ClearIndicator";

interface EntitySelectFilterProps<T> {
    filteredEntityType: string;
    property: string;

    shownEntityType: string;
    shownEntityTypeProperties: string[];

    listRenderer: (item: EntityWrapper<T>) => string;
    placeholder: string;

    filterType?: string;
    filterOperation?: string;
}

interface StateProps {
    currentFilters?: Filter[];
    authedAxios: AxiosInstance;
}

interface DispatchProps {
    addFiltersForFilteredEntityType: ReturnType<typeof addFiltersAction>
    removeFiltersForFilteredEntityType: ReturnType<typeof removeFiltersAction>
}

type Props<T> = EntitySelectFilterProps<T> & StateProps & DispatchProps & WithTranslation;

export function UnconnectedAsyncEntitySelectFilter<T>({
                                                 t,
                                                 placeholder,
                                                 addFiltersForFilteredEntityType,
                                                 removeFiltersForFilteredEntityType,
                                                 shownEntityTypeProperties,
                                                 shownEntityType,
                                                 property,
                                                 listRenderer,
                                                 currentFilters,
                                                 authedAxios,
                                                 filterType,
                                                 filterOperation,
                                             }: Props<T>) {
    const filterTypeToUse = filterType ? filterType : FilterTerm.TYPE_STRING;

    const convertAccordingToFilterType = (value: string) => {
        if (filterTypeToUse === FilterTerm.TYPE_BIG_DECIMAL) {
            return Number.parseFloat(value);
        } else if (filterTypeToUse === FilterTerm.TYPE_INT) {
            return Number.parseInt(value, 10)
        } else {
            return value;
        }
    };

    const filter = (selected: any[]) => {
        if (selected && selected.length > 0) {
            addFiltersForFilteredEntityType(property, [new PropertyFilter(
                property,
                new FilterTerm(
                    FilterTerm.TYPE_STRING_LIST,
                    FilterTerm.OPERATION_IN,
                    selected.map(s => {
                        return s.value ? s.value : s
                    })
                )
            )]);
        } else {
            removeFiltersForFilteredEntityType([property]);
        }
    };

    const currentFilterValues = currentFilters ? (currentFilters[0] as PropertyFilter).filterTerm.value : undefined;

    const promiseOptions = (inputValue: string) => {
        const convertedValue = convertAccordingToFilterType(inputValue);

        const selectedIdsFilters = currentFilterValues ? [new PropertyFilter("id", new FilterTerm(FilterTerm.TYPE_STRING_LIST, FilterTerm.OPERATION_IN, currentFilterValues))] : [];

        const filterToUse = inputValue ? [new OrFilter(selectedIdsFilters.concat(
            shownEntityTypeProperties.map(p => new PropertyFilter(p,
                new FilterTerm(
                    filterTypeToUse,
                    filterOperation ? filterOperation : FilterTerm.OPERATION_REGEX,
                    convertedValue)
            ))))] : selectedIdsFilters;

        return fetchEntityList(authedAxios, shownEntityType, {page: 0, docsPerPage: 20}, filterToUse).pipe(
            map((action: EntityListResolvedPayload<any>) => {
                return action.entities.map(e => ({
                    value: e.id,
                    label: listRenderer(e)
                }));
            })
        ).toPromise();
    };

    const selectRef = useRef<any>(null);

    return (
        <AsyncSelect
            ref={selectRef}
            components={{
                ClearIndicator,
            }}
            placeholder={placeholder}
            className="filter react-select"
            classNamePrefix="react-select"
            isMulti={true}
            isClearable={true}
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => {
                /*
                This ghetto solution is because react-select does not allow asynchronously setting the value,
                nor reloading the label of an already set value. The problem is that the filter only provides the ID,
                not the label for that id. This means that during initialization, there is no label - which breaks the UI.
                We will now use the default label (if set) or looking for the appropriate (resolved) item.
                */

                if (option.label) {
                    return option.label;
                }
                const value = option.value ? option.value : option;

                const loadedOptions = selectRef.current!.state.loadedOptions;
                const defaultOptions = selectRef.current!.state.defaultOptions;
                return (loadedOptions.concat(defaultOptions)).find((o: any) => o.value === value).label;
            }}
            noOptionsMessage={() => t("async-entity-select-filter.no-options")}
            loadingMessage={() => t("async-entity-select-filter.loading")}
            onSelectResetsInput={false}
            value={currentFilterValues}
            onChange={(val: any) => filter(val)}
            loadOptions={promiseOptions}
            defaultOptions
        />
    );
}

// tslint:disable-next-line:max-classes-per-file
export class AsyncEntitySelectFilter<T> extends Component<EntitySelectFilterProps<T>> {
    private InnerComponent = connect<StateProps, DispatchProps, EntitySelectFilterProps<T>>(
        (state: any, ownProps: EntitySelectFilterProps<T>) => {
            return {
                currentFilters: selectFilter(ownProps.filteredEntityType, ownProps.property)(state),
                authedAxios: withAuthedAxios(selectApiBase(state), selectAuthState(state))
            };
        },
        (dispatch, ownProps: EntitySelectFilterProps<T>) => {
            return {
                addFiltersForFilteredEntityType: (key: string, filters: Filter[]) =>
                    dispatch(addFiltersAction(ownProps.filteredEntityType)(key, filters)),
                removeFiltersForFilteredEntityType: (keys: string[]) =>
                    dispatch(removeFiltersAction(ownProps.filteredEntityType)(keys)),
            } as DispatchProps;
        }
    )(withTranslation("core")(UnconnectedAsyncEntitySelectFilter));

    public render() {
        const InnerComponent = this.InnerComponent;
        // @ts-ignore
        return <InnerComponent {...this.props}/>;
    }
}
