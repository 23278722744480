/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment = {
    readonly " $fragmentRefs": FragmentRefs<"DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment" | "DownloadUsageCsvButton_SearchDiscountUsageResultFragment">;
    readonly " $refType": "DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment";
};
export type DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$data = DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment;
export type DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$key = {
    readonly " $data"?: DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DownloadUsageCsvButton_SearchDiscountUsageResultFragment"
    }
  ],
  "type": "SearchDiscountCodeUsagesResult",
  "abstractKey": null
};
(node as any).hash = 'b9bae434359886f486edcdc9c04fdff4';
export default node;
