import {connect} from "react-redux";
import React, {useState} from "react";
import Select from "react-select"
import {useTranslation} from "react-i18next";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {selectAllElements} from "../../../selectors";
import {FieldProps} from "formik";
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV, faTimes} from "@fortawesome/free-solid-svg-icons";
import {anyIdMatches, extractId} from "../../../reducers/changes/common/helpers";
import {CourseElement} from "@thekeytechnology/thekey-academy-frontend-library";
import { ClearIndicator } from "../../../../core/components/select/ClearIndicator";

interface State {
    toAdd: EntityWrapper<CourseElement> | undefined
}

interface StateProps {
    elements: EntityWrapper<CourseElement>[]
}

type Props = StateProps & FieldProps;

const SortableItem = SortableElement(
    ({onRemove, value}: { onRemove: (value: any) => void, value: any }) => {
        const {t} = useTranslation("courses");

        return <li className="list-group-item d-flex align-items-center">
            <FontAwesomeIcon
                className="mr-2 dnd-handle"
                icon={faEllipsisV}/>
            <span>{t(`course-editor.element-types.${value.entity.elementType}`)}: {value.entity.generalSettings.internalTitle}</span>
            <button type="button" className="ml-auto btn btn-link p-2 skip-dnd" onClick={() => onRemove(value)}>
                <FontAwesomeIcon className="skip-dnd" icon={faTimes}/>
            </button>
        </li>
    }
);

const SortableList = SortableContainer(({items, onRemove}: { onRemove: (value: any) => void, items: any[] }) => {
    return (
        <ul className="list-group">
            {items.map((value, index) => (
                <SortableItem key={`item-${value.id}`} index={index} value={value} onRemove={onRemove}/>
            ))}
        </ul>
    );
});

export const SelectElementsFieldComponent = ({elements, field, form}: Props) => {
    const {t} = useTranslation("courses");
    const [state, setState] = useState<State>({toAdd: undefined})

    const selectedElementIds = field.value ? field.value as string[] : [];

    const onSortEnd = (change: any) => {
        const reorderedCourseDefinitions = arrayMove<string>(selectedElementIds, change.oldIndex, change.newIndex);
        form.setFieldValue(field.name, reorderedCourseDefinitions)
    }

    return <div>
        <SortableList
            distance={20}
            items={selectedElementIds.map(eId => elements.find(el => anyIdMatches(el, eId))).filter(el => el !== undefined)}
            onRemove={(item: EntityWrapper<CourseElement>) => {
                form.setFieldValue(field.name, selectedElementIds.filter(elId => elId !== extractId(item)))
            }}
            onSortEnd={onSortEnd}
        />

        <div className="d-flex flex-row mt-2">
            <Select
                components={{ClearIndicator}}
                placeholder={t("course-editor.select-elements-field.placeholder")}
                className="filter react-select mr-2"
                classNamePrefix="react-select"
                getOptionValue={(opt: any) => opt.id}
                isMulti={false}
                value={state.toAdd}
                onChange={(val: any) => setState({...state, toAdd: val})}
                getOptionLabel={(val: any) => {
                    return `${val.entity.generalSettings.internalTitle} (${t(`course-editor.element-types.${val.entity.elementType}`)})`;
                }}
                options={elements.filter(el => selectedElementIds.indexOf(extractId(el)) === -1).sort(((a, b) => {
                    if (a.entity.generalSettings.internalTitle > b.entity.generalSettings.internalTitle) {
                        return 1
                    } else if (a.entity.generalSettings.internalTitle < b.entity.generalSettings.internalTitle) {
                        return -1
                    } else {
                        return 0
                    }
                }))}
            />
            <button type="button"
                    className="btn btn-secondary"
                    disabled={state.toAdd === undefined}
                    onClick={() => {
                        form.setFieldValue(field.name, [...selectedElementIds, extractId(state.toAdd!)]);
                        setState({toAdd: undefined});
                    }}>
                {t("course-editor.select-elements-field.add")}
            </button>
        </div>

    </div>
}

export const SelectElementsField = connect<StateProps, {}, {}>(
    (state: any) => ({
        elements: selectAllElements(state)
    })
)(SelectElementsFieldComponent);
