export const ENTITY_TYPE_SYNC_REQUEST = "sync-request"

export interface SyncRequest {
    strategy: string,
    parameters: { [key: string]: string },
    created: string,
    lastTry?: string,
    runAfter?: string,
    dontCleanUp: boolean,
    status: string,
    statusMessage: string
}
