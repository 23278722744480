import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { MonthlyPriceDisplay } from "./MonthlyPriceDisplay";
import { PriceDisplay } from "./PriceDisplay";
import { OrderV2, paymentMethodIsMonthly } from "@thekeytechnology/thekey-academy-frontend-library";
import { LexofficeLink } from "../../../common/LexofficeLink";

export interface OwnProps {
    order: EntityWrapper<OrderV2>
}

export const OrderPriceDisplay = ({order}: OwnProps) => {
    if (order.entity.status === "payment-in-process") {
        const Price = paymentMethodIsMonthly(order.entity.paymentProviderData!.paymentMethod) ?
            <MonthlyPriceDisplay monthlyPriceExplanation={order.entity.priceTable.monthlyTotal!}
                                 monthlyText={"Monatlich: "}/> :
            <PriceDisplay priceExplanation={order.entity.priceTable.total}/>

        if (order.entity.invoiceData) {
            return <>{Price}&nbsp;(<LexofficeLink invoiceData={order.entity.invoiceData}/>)</>
        } else {
            return Price
        }
    } else {
        return <div>
            <div>unbezahlt</div>
            <small>
                <PriceDisplay priceExplanation={order.entity.priceTable.total}/>
                {order.entity.priceTable.monthlyTotal ? <>Oder <MonthlyPriceDisplay
                    monthlyPriceExplanation={order.entity.priceTable.monthlyTotal}
                    monthlyText={" "}/></> : null}
            </small>
        </div>
    }
}
