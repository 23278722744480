import { ApiCallState, EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { TableBody } from "./Table";
import { AsyncContent } from "./AsyncContent";
import { LoadingRow } from "./LoadingRow";
import { NoDataRow } from "./NoDataRow";

export interface AsyncEntityTableContentProps<T> {
    apiCallState: ApiCallState;
    items: EntityWrapper<T>[],
    renderItem: (item: EntityWrapper<T>) => any;
}

export function AsyncEntityTableBody<T>(props: AsyncEntityTableContentProps<T>) {
    const {apiCallState, items, renderItem} = props;
    return (
        <TableBody>
            <AsyncContent
                loadingComponent={() => <LoadingRow/>}
                successComponent={
                    () => items.length === 0 ? <NoDataRow/> :
                        items.map(item => {
                            return (
                                renderItem(item)
                            );
                        })
                }
                apiCallState={apiCallState}/>
        </TableBody>
    );
}
