
export const ENTITY_TYPE_PRODUCT = "product"

export interface Product {
    title: string
    productType: string
    price: PriceV3
}

export interface CourseProduct extends Product {
    courseRef: string
}

export interface PriceV3 {
    netPrice: number
    grossPrice: number
    taxRatePercentage: number
}
