import { Action } from "redux";

export const MOVE_MODULE_ACTION = "move-module";

export interface MoveModuleAction extends Action {
    sourceIndex: number;
    targetIndex: number;
}

export const moveModuleAction = (sourceIndex: number, targetIndex: number) => ({
    type: MOVE_MODULE_ACTION,
    sourceIndex,
    targetIndex
}) as MoveModuleAction;
