import {EntityWrapper} from "@thekeytechnology/framework-react";
import {Form, Formik} from "formik";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import * as Yup from "yup";
import {ENTITY_TYPE_FAQ, FAQ, newFAQ} from "../model/FAQ";
import { LoadingEditHeader } from "../../core/components/header/LoadingEditHeader";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../core/components/entity/WithSingleEntityFromPath";
import { EditHeaderWithBackground } from "../../core/components/edit/EditHeaderWithBackground";
import { SaveAndBackButtons } from "../../core/components/button/SaveAndBackButtons";
import { ContentContainer } from "../../core/components/containers/Container";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { WysiwygField } from "../../core/components/wysiwyg/WysiwygField";
import { LoadingRow } from "../../core/components/table/LoadingRow";

type Props = WithSingleEntityFromPathProps<FAQ, FAQ> & WithTranslation;

const EditExpertFormComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return entity ? (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.faq.singular")}/>

            <Formik
                initialValues={{
                    question: entity.entity.question,
                    answer: entity.entity.answer,
                }}
                validationSchema={Yup.object().shape({
                    question: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.faq.labels.question")})),
                    answer: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.faq.labels.answer")})),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper(entity.id,
                        {
                            question: values.question,
                            answer: values.answer
                        } as FAQ
                    );
                    upsertEntity(saving);
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                formikState.values.question :
                                t("core:edit-header.heading-empty")}
                        >
                            <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                backPath="/experts/"/>
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.faq.labels.question")}
                                                type="text"
                                                name="question"
                                                className="form-control default-input"
                                                label={t("entity.faq.labels.question")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                name="answer"
                                                component={WysiwygField}
                                                isSingleSelect={true}
                                                className="form-control default-input"
                                                label={t("entity.faq.labels.answer")}
                                                required/>
                            </div>
                        </ContentContainer>
                    </Form>
                )}
            </Formik>
        </>
    ) : <LoadingRow/>;
};

export const EditFAQForm = WithSingleEntityFromPath<{}, FAQ, FAQ>(
    withTranslation(["faq", "core"])(EditExpertFormComponent),
    ENTITY_TYPE_FAQ,
    "faqId",
    newFAQ,
    undefined);
