import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { UnlockForm } from "./UnlockForm";

interface OwnProps {
    buttonText?: string;
    accountId?: string;
}

export const UnlockModalButton = ({buttonText, accountId}: OwnProps) => {
    const [modalVisible, setModalVisible] = useState(false);

    return <>
        <button type="button" onClick={() => setModalVisible(true)} className="btn btn-secondary">
            {buttonText ? buttonText: "Massenfreischaltung"}
        </button>
        {modalVisible ? <Modal size={"lg"} show={true}>
            <div className="p-3">
                <UnlockForm accountId={accountId} onClose={() => setModalVisible(false)}/>
            </div>
        </Modal> : null}

    </>
}
