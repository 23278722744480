/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateRandomCodesButton_DiscountActionFragment = {
    readonly id: string;
    readonly " $refType": "CreateRandomCodesButton_DiscountActionFragment";
};
export type CreateRandomCodesButton_DiscountActionFragment$data = CreateRandomCodesButton_DiscountActionFragment;
export type CreateRandomCodesButton_DiscountActionFragment$key = {
    readonly " $data"?: CreateRandomCodesButton_DiscountActionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CreateRandomCodesButton_DiscountActionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateRandomCodesButton_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};
(node as any).hash = 'a21c82fe2d9afdc64e0c996410040be9';
export default node;
