import React from 'react'
import {useRefetchableFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {MultiSelect} from "primereact/multiselect";
import {useTranslation} from "react-i18next";
import {UserSelect_QueryFragmentRefetchQuery} from "../../../../__generated__/UserSelect_QueryFragmentRefetchQuery.graphql";
import {UsersSelect_QueryFragment$key} from "../../../../__generated__/UsersSelect_QueryFragment.graphql";

const NUM_USERS_PER_PAGE = 20

const QUERY_FRAGMENT = graphql`
    fragment UsersSelect_QueryFragment on Query @refetchable(queryName: "UserSelect_QueryFragmentRefetchQuery") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String", defaultValue: null},
        email: {type: "String", defaultValue: null},
        ids: {type :"[ID!]!"}
    ) {
        Admin {
            Auth {
                UsersMultiselect(first: $first, after: $after, emailOpt: $email, ids: $ids) @connection(key: "UserSelect_UsersMultiselect") {
                    edges {
                        cursor
                        node {
                            id
                            ... on User {
                                email
                            }
                        }
                    }
                }
            }
        }
    }`

export interface User {
    id: string
    name: string
}

interface OwnProps {
    fieldValue: User[]
    onChange: (Users: User[]) => void
    queryFragmentRef: UsersSelect_QueryFragment$key
}

export const UsersSelect = ({
                                fieldValue,
                                onChange,
                                queryFragmentRef
                            }: OwnProps) => {

    const {t} = useTranslation("core")

    const [data, refetch] = useRefetchableFragment<UserSelect_QueryFragmentRefetchQuery, UsersSelect_QueryFragment$key>(QUERY_FRAGMENT, queryFragmentRef)

    const users = data.Admin.Auth.UsersMultiselect.edges?.filter(e => !!e).map(e => e!.node) || []

    const onFilter = (e: { filter: string }) => {
        refetch({email: e.filter, first: NUM_USERS_PER_PAGE, ids: fieldValue.map(a => a.id)})
    }

    return <MultiSelect value={fieldValue} options={users}
                     optionLabel="email"
                     onChange={(e) => onChange(e.value)}
                     placeholder={t("entity-selects.placeholder")} filter
                     resetFilterOnHide={true}
                     filterPlaceholder={t("entity-selects.filter-placeholder")}
                     className="multiselect-custom"
                     onFilter={onFilter} showSelectAll={false}
                     emptyFilterMessage={t("entity-selects.no-results")}
        />
}
