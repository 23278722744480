import React from "react";
import {DiscountTypeKind} from "../../../../../../__generated__/TypeColumn_EntityFragment.graphql";
import {Dropdown} from "primereact/dropdown";
import {useTranslation} from "react-i18next";


interface OwnProps {
    fieldValue?: string,
    updateField: (discountType: string) => void
}

export const DiscountTypeInput = ({fieldValue, updateField}: OwnProps) => {
    const {t} = useTranslation("billingV3")

    const options: { label: string, value: DiscountTypeKind }[] = [
        {label: "System", value: "system"},
        {label: "User", value: "user"}]


    return <Dropdown
        value={options.find(k => k.value === fieldValue)?.value}
        options={options}
        placeholder={t("billing.discount-code.action.form.type")}
        onChange={(e) => updateField(e.value)}/>
}
