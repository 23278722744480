import { FieldProps } from "formik";
import React from "react";
import Select from "react-select";

interface OwnProps {
    trueLabel: string;
    falseLabel: string;
    placeholder?: string;
}

type Props = OwnProps & FieldProps;

const BooleanSelectFieldComponent = (props: Props) => {
    const {field, form, trueLabel, falseLabel, placeholder} = props;
    const options = [
        {
            value: true,
            label: trueLabel
        },
        {
            value: false,
            label: falseLabel
        }
    ];
    return (<Select
        className="react-select"
        classNamePrefix="react-select"
        isClearable={false}
        options={options}
        name={field.name}
        placeholder={placeholder}
        value={field.value !== undefined ? options.find(o => o.value === field.value) : undefined}
        onChange={((item: any) => form.setFieldValue(field.name, item ? item.value : undefined))}
        getOptionValue={(opt: any) => opt.value}
        getOptionLabel={(item: any) => item.label}
        onBlur={field.onBlur}
    />);
};

export const BooleanSelectField = BooleanSelectFieldComponent;
