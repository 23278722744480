import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectAccountAdmin } from "../../selectors";
import { ShowWhenNotLocked } from "../../../locking/components/ShowWhenNotLocked";
import { fetchAccountAdminAction } from "../../actions/fetch-account";
import { EditAccountData } from "./accountdata/EditAccountData";
import { EditAccountMeta } from "./meta/EditAccountMeta";
import { EditUserInAccount } from "./users-in-account/EditUserInAccount";
import { EditLicensePool } from "./license-pool/EditLicensePool";
import { AccountOrdersTable } from "./orders/AccountOrdersTable";
import { EditHeaderWithBackground } from "../../../core/components/edit/EditHeaderWithBackground";
import { ContentContainer } from "../../../core/components/containers/Container";

export const SingleAccount = () => {
    const router = useRouteMatch<any>();
    const dispatch = useDispatch();

    const account = useSelector(selectAccountAdmin)

    useEffect(() => {
        if (router.params.accountId) {
            dispatch(fetchAccountAdminAction(router.params.accountId))
        }
    }, [dispatch, router.params.accountId])

    const {t} = useTranslation("admin");

    return account ? <div><EditHeaderWithBackground
        heading={t("single-account.heading", {accountName: account.entity.name})}
    >
    </EditHeaderWithBackground>
        <ShowWhenNotLocked resourceId={account?.id!}>
            <ContentContainer>
                <h3>Kontodaten</h3>
                <EditAccountData account={account}/>
                <h3 className="mt-4">Metadaten</h3>
                <EditAccountMeta account={account}/>
                <h3 className="mt-4">Benutzer im Konto</h3>
                <EditUserInAccount account={account}/>
                <h3 className="mt-4">Lizenzpool</h3>
                <EditLicensePool account={account}/>
                <h3 className="mt-4">Bestellungen</h3>
                <AccountOrdersTable account={account}/>
            </ContentContainer>
        </ShowWhenNotLocked>
    </div> : null;
};

