import React from "react";
import { LexofficeLink } from "../../common/LexofficeLink";
import { useFragment } from "react-relay";
import { OrderPriceDisplayV3_OrderFragment$key } from "../../../../../__generated__/OrderPriceDisplayV3_OrderFragment.graphql";
import { CurrencyDisplay } from "../../../../core/components/CurrencyDisplay";
import { graphql } from "babel-plugin-relay/macro";

const ORDER_FRAGMENT = graphql`
    fragment OrderPriceDisplayV3_OrderFragment on Order {
        status
        cart {
            cartTotals{
                totalsIncludingAllDiscounts {
                    netPrice
                }
            }
        }
        selectedPaymentMethod {
            ... on MonthlySelectedPaymentMethod {
                chosenOption {
                    monthlyRate {
                        netPrice
                    }
                }
            }
        }
        invoiceData {
            invoiceId
            invoiceNumber
        }
    }
`;

export interface OwnProps {
    orderFragmentRef: OrderPriceDisplayV3_OrderFragment$key
}

export const OrderPriceDisplayV3 = ({orderFragmentRef}: OwnProps) => {

    const order = useFragment<OrderPriceDisplayV3_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    if (order.status === "purchased" || order.status === "paymentReceived") {

        const Price = order.selectedPaymentMethod?.chosenOption ? <>
            Monatlich: <CurrencyDisplay value={order.selectedPaymentMethod.chosenOption.monthlyRate.netPrice}/>
        </> : <CurrencyDisplay value={order.cart.cartTotals.totalsIncludingAllDiscounts?.netPrice!}/>

        if (order.invoiceData) {
            return <>{Price}&nbsp;(<LexofficeLink invoiceData={order.invoiceData}/>)</>
        } else {
            return Price
        }
    } else {
        return <div>
            <div>unbezahlt</div>
        </div>
    }
}
