import { EntityWrapper, TkFile } from "@thekeytechnology/framework-react";
import { createSelector } from "reselect";
import { AppState } from "../../reducers";
import { PreparingAggregatedCourse } from "../model/preparing-aggregated-course";
import { CoursesModuleState } from "../reducers";
import { EditedCourseState } from "../reducers/edited-course";
import { VideoDataState } from "../reducers/video-data";
import { anyIdMatches } from "../reducers/changes/common/helpers";
import { Lesson } from "../model/lesson/lesson";
import { EditorCardsState } from "../reducers/editor-cards";
import { CourseElement } from "@thekeytechnology/thekey-academy-frontend-library";

export const selectCoursesModuleState = (state: AppState) => state.courses;

export const selectEditedCoursesState = createSelector(
    selectCoursesModuleState,
    (moduleState: CoursesModuleState) =>
        moduleState.editedCourse
);

export const selectEditorCardState = createSelector(
    selectCoursesModuleState,
    (moduleState: CoursesModuleState) =>
        moduleState.editorCards
);

export const selectEditedCourse = createSelector(
    selectEditedCoursesState,
    (subscriptionState: EditedCourseState) =>
        subscriptionState.editedCourse
);

export const selectHasUnsavedChanges = createSelector(
    selectEditedCoursesState,
    (subscriptionState: EditedCourseState) =>
        subscriptionState.hasUnsavedChanges
);

// @TODO: Fix this with context for lesson
export const selectOtherElementInLessonOf = (elementId: string) => createSelector(
    selectEditedCourse,
    (editedCourse?: EntityWrapper<PreparingAggregatedCourse>) => {
        if (!editedCourse) {
            return [];
        }

        return editedCourse.entity.elements.filter(el => !anyIdMatches(el, elementId));
    }
);

export const selectLessons = (lessonIds: string[]) => createSelector(
    selectEditedCourse,
    (editedCourse?: EntityWrapper<PreparingAggregatedCourse>) => {
        return editedCourse ? lessonIds
            .map(lessonId => editedCourse.entity.lessons.find(l => anyIdMatches(l, lessonId)))
            .filter(l => l !== undefined) as EntityWrapper<Lesson>[] : [];
    }
)

export const selectElementsByIds = (elementIds: string[]) => createSelector(
    selectEditedCourse,
    (editedCourse?: EntityWrapper<PreparingAggregatedCourse>) => {

        return editedCourse ?
            elementIds.map(elementId => editedCourse.entity.elements.find(el => anyIdMatches(el, elementId)))
                .filter(el => el !== undefined) as EntityWrapper<CourseElement>[] : []
    }
)

export const selectAllElements = createSelector(
    selectEditedCourse,
    (editedCourse?: EntityWrapper<PreparingAggregatedCourse>) => {
        return editedCourse ? editedCourse.entity.elements : []
    }
)

export const selectChildFiles = createSelector(
    selectEditedCourse,
    (editedCourse?: EntityWrapper<PreparingAggregatedCourse>) => {
        return editedCourse ? editedCourse.entity.childFiles : []
    }
)

export const selectImage = (imageRef: string | undefined) => createSelector(
    selectChildFiles,
    (childFiles: EntityWrapper<TkFile>[]) => {
        return childFiles.find(f => f.id === imageRef)
    }
)

export const selectVideoDataState = createSelector(
    selectCoursesModuleState,
    (moduleState: CoursesModuleState) =>
        moduleState.videoData
);

export const selectVideoData = createSelector(
    selectVideoDataState,
    (videoDataState: VideoDataState) => videoDataState.videoData
);

export const selectIsCardOpen = (id: string) => createSelector(
    selectEditorCardState,
    (editorCardsState: EditorCardsState) =>
        editorCardsState.openCards.indexOf(id) > -1
);
