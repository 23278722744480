import React from "react";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { User } from "../../../../model/User";
import { NavLink } from "react-router-dom";
import { UserOrdersTableV3_Query } from "../../../../../../__generated__/UserOrdersTableV3_Query.graphql";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OrderPriceDisplayV3 } from "../../../orders/v3/OrderPriceDisplayV3";
import { PaymentProviderLinksV3 } from "../../../orders/v3/PaymentProviderLinksV3";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { Button } from "primereact/button";

const QUERY = graphql`
    query UserOrdersTableV3_Query($userId: ID!) {
        Admin {
            Billing {
                Orders(userId: $userId) {
                    edges {
                        node{
                            id
                            createdAt
                            status
                            ...OrderPriceDisplayV3_OrderFragment
                            ...PaymentProviderLinksV3_OrderFragment
                        }
                    }
                }
            }
        }
    }
`;

export interface OwnProps {
    user: EntityWrapper<User>
}

export const UserOrdersTableV3 = ({user}: OwnProps) => {
    const orders = useLazyLoadQuery<UserOrdersTableV3_Query>(QUERY, {
        userId: btoa("User:" + user.id)
    })

    const items = orders.Admin.Billing.Orders.edges?.map(e => e!.node) || []

    return items.length ? <DataTable className="p-mb-2" value={items}>
        <Column field="createdAt" header="Datum / Uhrzeit"/>
        <Column header="Preis" body={row => {
            return <OrderPriceDisplayV3 orderFragmentRef={row}/>
        }}/>
        <Column header="Preis" body={row => {
            return <PaymentProviderLinksV3 orderFragmentRef={row}/>
        }}/>
        <Column header="Aktionen" style={{width: "20%"}} body={item =>
            <NavLink to={`/orders-v3/${item.id}/edit`}> <Button icon={"pi pi-search"}/></NavLink>
        }/>
    </DataTable> : null;
}
