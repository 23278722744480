import { OrderContainer } from "@thekeytechnology/thekey-academy-frontend-library";
import React from "react";
import "./order-history-table.scss"
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableColumn, TableHeader, TableRow } from "../../../../../core/components/table/Table";
import { DateTimeDisplay } from "../../../../../core/components/datetime/DateTimeDisplay";

interface OwnProps {
    order: OrderContainer
}

export const OrderHistoryTable = ({order}: OwnProps) => {
    const {t} = useTranslation(["admin", "billing"])

    return <Table className="order-history-table" itemCount={order.order.entity.history.length}>
        <TableHeader>
            <TableColumn>Datum</TableColumn>
            <TableColumn>Nachricht</TableColumn>
            <TableColumn>Zusatzinfos</TableColumn>
            <TableColumn>Neuer Status</TableColumn>
        </TableHeader>
        <TableBody>
            {order.order.entity.history.map((historyItem, index) => {

                let additionalInformation: string | undefined;

                if (historyItem.additionalInformation) {
                    switch (historyItem.message) {
                        case "payment-method-switch":
                            additionalInformation = t("billing:payment-methods." + historyItem.additionalInformation)
                            break;
                        default:
                            additionalInformation = historyItem.additionalInformation

                    }
                }

                return <TableRow key={index}>
                    <TableColumn>
                        <DateTimeDisplay dateTime={historyItem.dateTime}/>
                    </TableColumn>
                    <TableColumn>
                        {t("billing:order-history-messages." + historyItem.message)}
                    </TableColumn>
                    <TableColumn>
                        {additionalInformation}
                    </TableColumn>
                    <TableColumn>
                        {historyItem.newStatus ? t("billing:order-statuus." + historyItem.newStatus) : null}
                    </TableColumn>
                </TableRow>
            })}
        </TableBody>
    </Table>
}
