import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_USER_ADMIN = "fetch-user-admin";

export const fetchUserAdminAction = (userId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_USER_ADMIN,
            callType: API_FETCH_USER_ADMIN
        },
        payload: userId,
    } as ApiCallAction<string>;
};
