import { faCalendarAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldProps } from "formik";
import React, { Component } from "react";
import { Props as ReactSelectProps } from "react-select";
import DatePicker from "react-date-picker";

type Props = ReactSelectProps & FieldProps

export class DateField extends Component<Props> {
    public constructor(props: Props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    public onChange(dateTime: string) {
        this.props.form.setFieldValue(this.props.field.name, dateTime);
    }

    public render() {
        const {field, form} = this.props;
        return (
            <DatePicker
                showLeadingZeros={false}
                clearIcon={<FontAwesomeIcon icon={faTimes}/>}
                calendarIcon={<FontAwesomeIcon icon={faCalendarAlt}/>}
                value={field.value ? new Date(field.value) : undefined}
                onChange={(date: any) => form.setFieldValue(this.props.field.name, date)}
            />
        );
    }
}
