import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_DOWNLOAD_USERS_CSV = "download-users-csv";

export const downloadUsersAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_USERS_CSV,
            callType: API_DOWNLOAD_USERS_CSV
        },
        payload: undefined,
    } as ApiCallAction<void>;
};
