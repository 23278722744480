import {
    API_CREATE_ENTITIES, API_DELETE_ENTITIES, API_UPDATE_ENTITIES,
    matchesAnyOfTheseApiSuccesses, reloadEntityListAction
} from "@thekeytechnology/framework-react";
import { push } from "connected-react-router";
import { Action } from "redux";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GlossaryEntry } from "../model/glossary-entry";

const redirectOnGlossaryEntryWrite$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses(
        {apiType: API_CREATE_ENTITIES, callType: GlossaryEntry.TYPE},
        {apiType: API_UPDATE_ENTITIES, callType: GlossaryEntry.TYPE}
    ),
    map(() => {
        return push(`/glossary`);
    })
);

const reloadOnGlossaryEntryDelete$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses(
        {apiType: API_DELETE_ENTITIES, callType: GlossaryEntry.TYPE},
    ),
    map(() => {
        return reloadEntityListAction(GlossaryEntry.TYPE)();
    })
);

export const glossaryModuleEpics$ = combineEpics(
    redirectOnGlossaryEntryWrite$,
    reloadOnGlossaryEntryDelete$
);
