import { Trigger } from "../../core/model/trigger/trigger";
import { EntityWrapper } from "@thekeytechnology/framework-react";

export const ENTITY_TYPE_TRIGGERED_EMAIL = "triggered-email";

export const NEW_TRIGGERED_EMAIL = () => new EntityWrapper(undefined, {
    title: "Neu",
    subjects: {},
    previewTexts: {},
    bodies: {}
} as TriggeredEmail)

export interface TriggeredEmail {
    title: string,
    trigger?: Trigger,
    customerMessageDefinitionRef?: string,
    subjects: { [lang: string]: string },
    previewTexts: { [lang: string]: string },
    bodies: { [lang: string]: string },
}