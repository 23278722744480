import { EntityWrapper, TkFile } from "@thekeytechnology/framework-react";
import { Lesson } from "./lesson/lesson";
import {
    ContainerMeta,
    CourseElement,
    CourseModule, OfferV2,
    Versions
} from "@thekeytechnology/thekey-academy-frontend-library";
import { Offer } from "../../billing/old/v1/model/Offer";
import { Product } from "./Product";

export const ENTITY_TYPE_AGGREGATED_COURSE = "preparing-aggregated-course"
export const ENTITY_TYPE_COURSE = "course";

export const COURSE_ATTRIBUTE_PUBLISHED = "published";
export const COURSE_ATTRIBUTE_HIDDEN = "hidden";
export const COURSE_ATTRIBUTE_NEW = "new";
export const COURSE_ATTRIBUTE_EARLY_BIRD = "earlyBird"


export interface PreparingAggregatedCourse {
    containerMeta: ContainerMeta,
    shortTitle: string,
    language: string,
    image: EntityWrapper<TkFile> | undefined,
    icon: EntityWrapper<TkFile> | undefined,
    certificateTitle: string,
    certificateDescription?: string,
    isIHK: boolean,
    childFiles: EntityWrapper<TkFile>[],
    expertsRef: string[],
    modules: EntityWrapper<CourseModule>[],
    lessons: EntityWrapper<Lesson>[],
    elements: EntityWrapper<CourseElement>[],
    attributes: string[],
    mainOffer: EntityWrapper<Offer> | undefined,
    mainOfferV2: EntityWrapper<OfferV2> | undefined,
    mainProduct: EntityWrapper<Product> | undefined,
    isFreeCourse: boolean,
    courseFile: EntityWrapper<TkFile> | undefined,
    versions: Versions
}

export interface Course {
    containerMeta: ContainerMeta,
    attributes: string[],
    certificateTitle: string,
    versions: Versions
}
