import { UserWithRoles } from "@thekeytechnology/framework-react";
import { EditExpertForm } from "./components/EditExpert";
import { ExpertOverview } from "./components/ExpertOverview";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";

export const ExpertModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/experts",
        component: ExpertOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    },
    {
        path: "/experts/:expertId/edit",
        component: EditExpertForm,
        requiredRoles: [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER]
    }
];
