import { combineReducers } from "redux";
import { editedCourseReducer } from "./edited-course";
import { videoDataReducer } from "./video-data";
import { editorCardsReducer } from "./editor-cards";

export const coursesModuleReducer = combineReducers({
    editedCourse: editedCourseReducer,
    videoData: videoDataReducer,
    editorCards: editorCardsReducer
});

export type CoursesModuleState = ReturnType<typeof coursesModuleReducer>;
