import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BusinessBillingDetails, paymentMethodIsMonthly } from "@thekeytechnology/thekey-academy-frontend-library";
import { NavLink } from "react-router-dom";
import { useRouteMatch } from "react-router";
import { selectOrderAdmin } from "../../../selectors";
import { fetchOrderAdminAction } from "../../../actions/fetch-order";
import { ValueField } from "./single/ValueField";
import { OrderPriceDisplay } from "./single/OrderPriceDisplay";
import { OrderItemTable } from "./single/OrderItemTable";
import { OrderPaymentProviderDataDisplay } from "./single/ProviderDataDisplay";
import { PaymentProviderLinks } from "../../users/orders/v2/PaymentProviderLinks";
import { LexofficeLink } from "../../common/LexofficeLink";
import { OrderHistoryTable } from "./single/OrderHistoryTable";
import { ShowWhenNotLocked } from "../../../../locking/components/ShowWhenNotLocked";
import { EditHeaderWithBackground } from "../../../../core/components/edit/EditHeaderWithBackground";
import { ContentContainer } from "../../../../core/components/containers/Container";

export const SingleOrder = () => {
    const dispatch = useDispatch();
    const router = useRouteMatch<any>();

    const order = useSelector(selectOrderAdmin)

    useEffect(() => {
        if (router.params["orderId"]) {
            dispatch(fetchOrderAdminAction(router.params.orderId))
        }
    }, [dispatch, router])

    const {t} = useTranslation("admin");

    return order ? <div><EditHeaderWithBackground
        heading={t("single-order.heading", {
            orderId: order.order.id,
            orderStatus: t("billing:order-statuus." + order.order.entity.status)
        })}
    >
    </EditHeaderWithBackground>
        <ShowWhenNotLocked resourceId={order.order.id!}>
            <ContentContainer>

                <h3>Rechnungsdaten</h3>
                <ValueField label="Kundenart"
                            value={t("billing:customer-types." + order.order.entity.billingDetails.customerType)}/>
                <ValueField label="Rechnungsemail" value={order.order.entity.billingDetails.invoiceEmail}/>

                {order.order.entity.billingDetails.customerType === "business" ? <>
                    <hr/>
                    <ValueField label="Firmenart"
                                value={(order.order.entity.billingDetails as BusinessBillingDetails).companyType}/>
                    <ValueField label="Firma"
                                value={(order.order.entity.billingDetails as BusinessBillingDetails).company}/>
                    <ValueField label="Firmendetails"
                                value={(order.order.entity.billingDetails as BusinessBillingDetails).companyDetails}/>
                    <hr/>
                </> : null}

                <ValueField label="Anrede" value={order.order.entity.billingDetails.salutation}/>
                <ValueField label="Vorname" value={order.order.entity.billingDetails.firstName}/>
                <ValueField label="Nacnname" value={order.order.entity.billingDetails.lastName}/>
                <ValueField label="Straße" value={order.order.entity.billingDetails.street}/>
                <ValueField label="Hausnummer" value={order.order.entity.billingDetails.houseNumber}/>
                <ValueField label="PLZ" value={order.order.entity.billingDetails.postalCode}/>
                <ValueField label="Land" value={order.order.entity.billingDetails.country}/>

                <h3>Bestelldaten</h3>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Angebot</label>
                    <NavLink className="col-sm-10 orm-control default-input align-items-center d-flex"
                             to={`/offers-v2/${order.order.entity.offerConfiguration.offerId}/edit`}>
                        {order.baseOffer.entity.title}
                    </NavLink>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Bezahlt</label>
                    <div className="col-sm-10 orm-control default-input d-flex align-items-center">
                        {order.order.entity.status === "payment-in-process" ? "Im Prozess" : "Nein"}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Bestandteile</label>
                    <div className="col-sm-10 orm-control default-input">
                        <OrderItemTable order={order}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Gesamtpreis</label>
                    <div className="col-sm-10 orm-control default-input">
                        <OrderPriceDisplay priceExplanation={order.order.entity.priceTable.total}
                                           monthlyPriceExplanation={order.order.entity.priceTable.monthlyTotal}
                                           isPaid={order.order.entity.paymentProviderData !== undefined && order?.order.entity.status === "payment-in-process"}
                                           isMonthly={order.order.entity.paymentProviderData ? paymentMethodIsMonthly(order.order.entity.paymentProviderData.paymentMethod) : false}/>
                    </div>
                </div>

                <h3>Zahlungsdaten</h3>
                <ValueField label="Erlaubte Zahlmethoden"
                            value={order.order.entity.allowedPaymentMethods.map(pm => t("billing:payment-methods." + pm)).join(", ")}/>
                {order.order.entity.paymentProviderData ? <>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Zahlmethode</label>
                        <div className="col-sm-10 orm-control default-input d-flex align-items-center">
                            <PaymentProviderLinks isPaid={true}
                                                  paymentProviderData={order.order.entity.paymentProviderData}/>
                        </div>
                    </div>
                    <OrderPaymentProviderDataDisplay paymentProviderData={order.order.entity.paymentProviderData}/>
                </> : null}

                {order.order.entity.invoiceData ? <>
                    <h3>Rechnungsdaten</h3>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Rechnung</label>
                        <div className="col-sm-10 orm-control default-input d-flex align-items-center">
                            <LexofficeLink invoiceData={order.order.entity.invoiceData}/>
                        </div>
                    </div>
                </> : null}

                <h3>Historie</h3>
                <OrderHistoryTable order={order}/>
            </ContentContainer>
        </ShowWhenNotLocked>
    </div> : null;
};

