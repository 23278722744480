import {
    API_CREATE_ENTITIES,
    API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import { combineEpics } from "redux-observable";
import { ENTITY_TYPE_TRIGGERED_UNLOCK } from "../model/triggered-unlock";
import { redirectAfterEntityApiOperation } from "../../core/epics/redirects";

export const triggeredUnlockModuleEpics$ = combineEpics(
    reloadOnEntityApiOperation(API_DELETE_ENTITIES, ENTITY_TYPE_TRIGGERED_UNLOCK),
    redirectAfterEntityApiOperation([API_UPDATE_ENTITIES, API_CREATE_ENTITIES], ENTITY_TYPE_TRIGGERED_UNLOCK, "/unlocks")
);
