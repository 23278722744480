import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import coreDE from "./core/i18n/core.de.json";
import coursesDE from "./course/i18n/courses.de.json";
import glossaryDE from "./glossary/i18n/glossary.de.json";
import additionalContentDE from "./additionalcontent/i18n/additional-content.de.json";
import filesDE from "./files/i18n/files.de.json";
import expertDE from "./expert/i18n/expert.de.json";
import qnaDE from "./qna/i18n/qna.de.json";
import rankDE from "./rank/i18n/rank.de.json";
import settingsDE from "./settings/i18n/settings.de.json";
import faqDE from "./faq/i18n/faq.de.json";
import customerMessagesDE from "./customer-messages/i18n/customer-messages.de.json"
import customerMessagesV2DE from "./customer-messages-v2/i18n/customer-messages-v2.de.json"
import emailsDE from "./emails/i18n/emails.de.json";
import notificationsDE from "./notifications/i18n/notifications.json";
import triggersDE from "./triggers/i18n/triggers.de.json";
import achievementsDE from "./achievements/i18n/achievements.de.json";
import unlocksDE from "./unlocks/i18n/unlocks.de.json";
import syncRequestsDE from "./sync-requests/i18n/sync-requests.json";
import billingDE from "./billing/old/i18n/billing.de.json";
import billingV3DE from "./billing/v3/i18n/billing.de.json";
import feedbackDE from "./feedback/i18n/feedback.de.json";
import adminDE from "./admin/i18n/admin.de.json"
import onboardingDE from "./onboarding/i18n/onboarding.de.json"

const resources = {
    de: {
        courses: coursesDE,
        core: coreDE,
        glossary: glossaryDE,
        additionalContent: additionalContentDE,
        files: filesDE,
        expert: expertDE,
        qna: qnaDE,
        rank: rankDE,
        settings: settingsDE,
        faq: faqDE,
        customerMessages: customerMessagesDE,
        customerMessagesV2: customerMessagesV2DE,
        emails: emailsDE,
        notifications: notificationsDE,
        triggers: triggersDE,
        achievements: achievementsDE,
        unlocks: unlocksDE,
        syncRequests: syncRequestsDE,
        billing: billingDE,
        billingV3: billingV3DE,
        feedback: feedbackDE,
        admin: adminDE,
        onboarding: onboardingDE
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "de",
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            checkWhitelist: true
        }
    });

i18n.changeLanguage("de");
