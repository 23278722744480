import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {EditUserData} from "./userdata/EditUserData";
import {EditUserMeta} from "./meta/EditUserMeta";
import {UserOrders} from "./orders/v2/UserOrdersTable";
import {useRouteMatch} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserAdminAction} from "../../actions/fetch-user";
import {selectUserAdmin} from "../../selectors";
import {CourseStatesForm} from "./courses/CourseStatesForm";
import {UserActions} from "./UserActions";
import {ShowWhenNotLocked} from "../../../locking/components/ShowWhenNotLocked";
import {EditUserRoles} from "./roles/EditUserRoles";
import {UserOrdersTableV3} from "./orders/v3/UserOrdersTableV3";
import {EditHeaderWithBackground} from "../../../core/components/edit/EditHeaderWithBackground";
import {ContentContainer} from "../../../core/components/containers/Container";
import {EditAdditionalInformation} from "./additional-information/EditAdditionalInformation";
import graphql from 'babel-plugin-relay/macro'
import {useLazyLoadQuery} from "react-relay";
import {SingleUser_Query} from "../../../../__generated__/SingleUser_Query.graphql";

const QUERY = graphql`
    query SingleUser_Query($id: ID!) {
        node(id: $id) {
            id
            ... on User {
                rawId
                name
                meta {
                    ...EditAdditionalInformation_UserMetaFragment
                }
                ...UserActions_UserFragment
                ...EditUserData_UserFragment
            }
        }
    }`

export const SingleUser = () => {
    const router = useRouteMatch<any>();
    const {t} = useTranslation("admin");
    const dispatch = useDispatch();

    const singleUserQuery = useLazyLoadQuery<SingleUser_Query>(QUERY, {
        id: router.params.userId
    })

    const user = singleUserQuery.node

    //TODO: Remove userOld (used for Konto&Rollen, Bestellungen, Kurse)
    const userOld = useSelector(selectUserAdmin)

    useEffect(() => {
        if (user?.rawId) {
            dispatch(fetchUserAdminAction(user?.rawId))
        }
    }, [dispatch, user])


    return user && userOld ? <div><EditHeaderWithBackground
        heading={t("single-user.heading", {userName: user.name})}
    >
    </EditHeaderWithBackground>
        <ShowWhenNotLocked resourceId={user?.id!}>
            <ContentContainer>
                <UserActions userFragmentRef={user}/>

                <h3>Nutzerdaten</h3>
                <EditUserData userFragmentRef={user}/>

                <h3 className="mt-4">Metadaten</h3>
                <EditUserMeta user={userOld}/>

                <h3 className="mt-4">Zusätzliche Informationen</h3>
                <EditAdditionalInformation userId={user.id} additionalInformationFragmentRef={user.meta!}/>

                <h3 className="mt-4">Konten & Rollen</h3>
                <EditUserRoles user={userOld}/>

                <h3 className="mt-4">Bestellungen</h3>
                <UserOrders user={userOld}/>
                <UserOrdersTableV3 user={userOld}/>

                <h3>Kurse</h3>
                <CourseStatesForm user={userOld}/>
            </ContentContainer>
        </ShowWhenNotLocked>
    </div> : null;
};

