import { EntityWrapper, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { User } from "../../../model/User";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { fetchCoursesAdminAction } from "../../../actions/fetch-courses-admin";
import { fetchCourseStatesAdminAction } from "../../../actions/fetch-course-states-admin";
import { selectCoursesAdmin, selectCourseStatesAdmin } from "../../../selectors";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { CourseStateField } from "./CourseStateField";
import { API_UPDATE_COURSE_STATE, updateCourseStateAction } from "../../../actions/update-course-state";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";

interface OwnProps {
    user: EntityWrapper<User>
}

export const CourseStatesForm = ({user}: OwnProps) => {
    const dispatch = useDispatch();

    const apiCallState = useSelector(selectCombinedApiState(API_UPDATE_COURSE_STATE))

    useEffect(() => {
        dispatch(fetchCoursesAdminAction())
    }, [dispatch])

    useEffect(() => {
        dispatch(fetchCourseStatesAdminAction(user.id!))
    }, [dispatch, user])

    const courses = useSelector(selectCoursesAdmin)
    const courseStates = useSelector(selectCourseStatesAdmin)

    return courses && courseStates ? <div className="mb-5">
        {courses?.map(course => {
            return <Formik
                key={course.id}
                initialValues={{
                    courseState: courseStates?.find(cs => cs.entity.courseId === course.id),
                }}
                validationSchema={Yup.object().shape({
                    courseState: Yup.object().required("Test")
                })}
                onSubmit={(values, {setSubmitting}) => {
                    dispatch(updateCourseStateAction(user.id!, values.courseState!));
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form className="mb-5">
                        <h2>{course.entity.containerMeta.title}</h2>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            component={CourseStateField}
                                            name="courseState"
                                            course={course}
                                            className="form-control default-input"
                            />
                        </div>
                        <button disabled={apiCallState.inProgress}
                                type="submit"
                                className="btn btn-success ml-auto">
                            {apiCallState.succeeded ? "✓" : "Speichern"}
                        </button>
                    </Form>
                )}
            </Formik>

        })}

    </div> : null
}
