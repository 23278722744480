import { ApiCallState, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { FieldProps } from "formik";
import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { API_FETCH_VIDEO_DATA, fetchVideoDataAction } from "../../../../actions/element/fetch-video-data";
import { selectVideoData } from "../../../../selectors";
import { VideoData } from "@thekeytechnology/thekey-academy-frontend-library";
import { LoadingRow } from "../../../../../core/components/table/LoadingRow";

interface OwnProps {
    placeholder?: string;
}

interface StateProps {
    videoData?: VideoData;
    apiCallState: ApiCallState;
}

interface DispatchProps {
    fetchVideoData: typeof fetchVideoDataAction
}

type Props = OwnProps & StateProps & DispatchProps & FieldProps & WithTranslation;

const VideoFieldComponent = (props: Props) => {
    const {form, field, fetchVideoData, videoData, placeholder, apiCallState, t} = props;

    const currentData = field.value ? field.value as VideoData : undefined;

    const [videoId, setVideoID] = useState(currentData ? (currentData.videoId ? currentData.videoId : "") : "");

    useEffect(() => {
        form.setFieldValue(field.name, videoData);
        // eslint-disable-next-line
    }, [videoData]);

    useEffect(() => {
        if (videoId) {
            fetchVideoData(videoId);
        }
    }, [fetchVideoData, videoId]);

    const convert = (num: number) => {
        const hours = Math.floor(num / 60);
        const minutes = num % 60;
        return `${hours}:${minutes}`;
    };

    return (
        <div>
            <input className="form-control default-input"
                   type="text"
                   placeholder={placeholder}
                   value={videoId}
                   onChange={event => {
                       setVideoID(event.target.value);
                   }}/>
            {apiCallState.inProgress ? <LoadingRow/> : null}

            {apiCallState.succeeded && videoData && videoData.wasError ?
                <p>{t("course-editor.video-field.error")}</p> : null}

            {apiCallState.succeeded && videoData && !videoData.wasError ? <div className="row mt-3">
                <div className="col-3">
                    <img className="w-100" src={videoData.thumbnailUrl} alt=""/>
                </div>
                <div className="col-9 d-flex align-items-center">
                    {videoData.name} ({convert(videoData.duration)})
                </div>
                <div>
                    {videoData.downloadUrl}
                </div>
            </div> : null}
        </div>
    );
};

export const VideoField = connect<StateProps, {}, FieldProps & OwnProps>(
    (state: any) => ({
        videoData: selectVideoData(state),
        apiCallState: selectCombinedApiState(API_FETCH_VIDEO_DATA)(state)
    }),
    {
        fetchVideoData: fetchVideoDataAction
    }
)(withTranslation("courses")(VideoFieldComponent));
