/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrderStatus = "hasBillingDetails" | "hasCart" | "hasPaymentDetails" | "paymentReceived" | "purchased" | "transient" | "%future added value";
export type UserOrdersTableV3_QueryVariables = {
    userId: string;
};
export type UserOrdersTableV3_QueryResponse = {
    readonly Admin: {
        readonly Billing: {
            readonly Orders: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly createdAt: string;
                        readonly status: OrderStatus;
                        readonly " $fragmentRefs": FragmentRefs<"OrderPriceDisplayV3_OrderFragment" | "PaymentProviderLinksV3_OrderFragment">;
                    };
                } | null> | null;
            };
        };
    };
};
export type UserOrdersTableV3_Query = {
    readonly response: UserOrdersTableV3_QueryResponse;
    readonly variables: UserOrdersTableV3_QueryVariables;
};



/*
query UserOrdersTableV3_Query(
  $userId: ID!
) {
  Admin {
    Billing {
      Orders(userId: $userId) {
        edges {
          node {
            id
            createdAt
            status
            ...OrderPriceDisplayV3_OrderFragment
            ...PaymentProviderLinksV3_OrderFragment
          }
        }
      }
    }
  }
}

fragment OrderPriceDisplayV3_OrderFragment on Order {
  status
  cart {
    cartTotals {
      totalsIncludingAllDiscounts {
        netPrice
      }
    }
  }
  selectedPaymentMethod {
    __typename
    ... on MonthlySelectedPaymentMethod {
      chosenOption {
        monthlyRate {
          netPrice
        }
      }
    }
  }
  invoiceData {
    invoiceId
    invoiceNumber
  }
}

fragment PaymentProviderLinksV3_OrderFragment on Order {
  paymentData {
    __typename
    paymentMethod
    dataType
    ... on StripeData {
      paymentIntentId
    }
    ... on StripeSepaData {
      paymentIntentId
    }
  }
  status
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paymentIntentId",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserOrdersTableV3_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "OrdersConnection",
                "kind": "LinkedField",
                "name": "Orders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrdersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Order",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "OrderPriceDisplayV3_OrderFragment"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PaymentProviderLinksV3_OrderFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserOrdersTableV3_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "OrdersConnection",
                "kind": "LinkedField",
                "name": "Orders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrdersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Order",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Cart",
                            "kind": "LinkedField",
                            "name": "cart",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CartTotals",
                                "kind": "LinkedField",
                                "name": "cartTotals",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PriceV3",
                                    "kind": "LinkedField",
                                    "name": "totalsIncludingAllDiscounts",
                                    "plural": false,
                                    "selections": (v5/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "selectedPaymentMethod",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MonthlyOption",
                                    "kind": "LinkedField",
                                    "name": "chosenOption",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PriceV3",
                                        "kind": "LinkedField",
                                        "name": "monthlyRate",
                                        "plural": false,
                                        "selections": (v5/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "MonthlySelectedPaymentMethod",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InvoiceData",
                            "kind": "LinkedField",
                            "name": "invoiceData",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "invoiceId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "invoiceNumber",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "paymentData",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paymentMethod",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dataType",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v7/*: any*/),
                                "type": "StripeData",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v7/*: any*/),
                                "type": "StripeSepaData",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "19f725abb440df81e15936cbf5114bb0",
    "id": null,
    "metadata": {},
    "name": "UserOrdersTableV3_Query",
    "operationKind": "query",
    "text": "query UserOrdersTableV3_Query(\n  $userId: ID!\n) {\n  Admin {\n    Billing {\n      Orders(userId: $userId) {\n        edges {\n          node {\n            id\n            createdAt\n            status\n            ...OrderPriceDisplayV3_OrderFragment\n            ...PaymentProviderLinksV3_OrderFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment OrderPriceDisplayV3_OrderFragment on Order {\n  status\n  cart {\n    cartTotals {\n      totalsIncludingAllDiscounts {\n        netPrice\n      }\n    }\n  }\n  selectedPaymentMethod {\n    __typename\n    ... on MonthlySelectedPaymentMethod {\n      chosenOption {\n        monthlyRate {\n          netPrice\n        }\n      }\n    }\n  }\n  invoiceData {\n    invoiceId\n    invoiceNumber\n  }\n}\n\nfragment PaymentProviderLinksV3_OrderFragment on Order {\n  paymentData {\n    __typename\n    paymentMethod\n    dataType\n    ... on StripeData {\n      paymentIntentId\n    }\n    ... on StripeSepaData {\n      paymentIntentId\n    }\n  }\n  status\n}\n"
  }
};
})();
(node as any).hash = '31de47c65d6a2a3d46cdb11d98f10961';
export default node;
