import {useFormik} from "formik";
import {Button} from "primereact/button";
import React, {Suspense} from "react";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {ValidatedFieldV2} from "../../core/components/form/ValidatedFieldV2";
import {DefaultTextFieldComponent} from "../../core/components/form/forms/DefaultTextComponent";
import {CourseSelect} from "../../billing/v3/components/product/CourseSelect";
import {Course, CoursesSelect} from "../../core/components/entity-selects/CoursesSelect";
import {ProgressSpinner} from "primereact/progressspinner";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {EditCourseBundleForm_Query} from "../../../__generated__/EditCourseBundleForm_Query.graphql";

const QUERY = graphql`
    query EditCourseBundleForm_Query($courseIds: [ID!]!) {
        ...CoursesSelect_QueryFragment @arguments(ids: $courseIds)
    }
`;

export interface CourseBundleFormValues {
    title?: string,
    bundleCourseIds?: Course[]
    finalCourseId?: string
}

interface OwnProps {
    loading?: boolean
    initialValues?: CourseBundleFormValues
    onSubmit: (values: CourseBundleFormValues) => void
}

export const EditCourseBundleForm = ({
                                         loading,
                                         initialValues,
                                         onSubmit
                                     }: OwnProps) => {
    const {t} = useTranslation(["billing", "core"])

    const query = useLazyLoadQuery<EditCourseBundleForm_Query>(QUERY, {
        courseIds: initialValues?.bundleCourseIds?.map(l => l.id) || [],
    })

    const formik = useFormik<CourseBundleFormValues>({
        initialValues: {
            title: initialValues?.title ?? "Neues Bundle",
            bundleCourseIds: initialValues?.bundleCourseIds,
            finalCourseId: initialValues?.finalCourseId
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required(t("core:forms.required-field", {fieldName: "Titel"})),
        }),
        onSubmit: (values, {setSubmitting}) => {
            onSubmit(values)
            setSubmitting(false)
        }
    })

    return <form onSubmit={formik.handleSubmit} className="p-fluid">
        <ValidatedFieldV2<CourseBundleFormValues, string>
            name={"title"}
            label={"Name"}
            component={DefaultTextFieldComponent}
            formikConfig={formik}
        />
        <ValidatedFieldV2<CourseBundleFormValues, string>
            name={"finalCourseId"}
            label={"Bundle Lehrgang"}
            component={({fieldValue, updateField}) => {
                return <CourseSelect courseRef={fieldValue} onChange={courseRef => {
                    updateField(courseRef)
                }}/>
            }}
            formikConfig={formik}
        />
        <ValidatedFieldV2<CourseBundleFormValues, Course[]>
            name={"bundleCourseIds"}
            label={"Lehrgänge"}
            component={({fieldValue, updateField}) => {
                return <Suspense fallback={<ProgressSpinner/>}>
                    <CoursesSelect fieldValue={fieldValue || []}
                                   onChange={courses => {
                                       updateField(courses)
                                   }} queryFragmentRef={query}/>
                </Suspense>
            }}
            formikConfig={formik}
        />

        <Button
            disabled={loading}
            type="submit"
            label="Speichern"
            className="p-mt-2"/>
    </form>
}
