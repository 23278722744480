import React from "react";
import { CurrencyDisplay } from "../../../../core/components/CurrencyDisplay";

interface OwnProps {
    netPrice: number
    grossPrice: number
}

export const PriceDisplay = ({netPrice, grossPrice}: OwnProps) => {
    return <div>
        <CurrencyDisplay value={netPrice}/> netto<br/>
        <small><CurrencyDisplay value={grossPrice}/> brutto</small>
    </div>
}
