/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditUserData_UserFragment = {
    readonly id: string;
    readonly name: string;
    readonly email: string;
    readonly activated: boolean;
    readonly " $refType": "EditUserData_UserFragment";
};
export type EditUserData_UserFragment$data = EditUserData_UserFragment;
export type EditUserData_UserFragment$key = {
    readonly " $data"?: EditUserData_UserFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"EditUserData_UserFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditUserData_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activated",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'dbfab7c9f97aee8ffbb2b85a5739301f';
export default node;
