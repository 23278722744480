import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {Course, COURSE_ATTRIBUTE_PUBLISHED, ENTITY_TYPE_COURSE} from "../model/preparing-aggregated-course";
import "./course-table.scss";
import {CopyCourseButton} from "./CopyCourseButton";
import {CreateCourseButton} from "./CreateCourseButton";
import {PublishCourseButton} from "../../publishing/components/PublishCourseButton";
import {PaginationInfo} from "../../core/components/pagination/PaginationInfo";
import {AboveTableContainer} from "../../core/components/containers/Container";
import {EntityDeletionButton} from "../../core/components/button/EntityDeletionButton";
import {Table, TableColumn} from "../../core/components/table/Table";
import {EntityTableHeader} from "../../core/components/table/EntityTableHeader";
import {AsyncEntityTableBody} from "../../core/components/table/AsyncEntityTableBody";
import {EntityTableRow} from "../../core/components/table/EntityTableRow";
import {ActionTableColumn} from "../../core/components/table/ActionTableColumn";
import {ActionLink} from "../../core/components/icon-link-tooltip/ActionLink";

type Props =
    WithEntityListProps<Course>
    & WithTranslation
    & WithSelectionsProps<string>;

const CourseTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_COURSE} className="mr-auto"/>

                <CreateCourseButton/>

                <EntityDeletionButton entityType={ENTITY_TYPE_COURSE}
                                      selectedEntities={selectionModel.selections}
                                      label={t("entity.course.singular")}
                                      labelPlural={t("entity.course.plural")}
                />
            </AboveTableContainer>

            <Table itemCount={entities.length} className="course-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>Id</TableColumn>
                    <TableColumn>{t("course-table.columns.title.heading")}</TableColumn>
                    <TableColumn>{t("course-table.columns.certificate-title.heading")}</TableColumn>
                    <TableColumn>{t("course-table.columns.status.heading")}</TableColumn>
                    <TableColumn>{t("course-table.columns.duration.heading")}</TableColumn>
                    <TableColumn>{t("course-table.columns.versions.heading")}</TableColumn>
                    <TableColumn>{t("course-table.columns.actions.heading")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={course =>
                        <EntityTableRow
                            key={course.id}
                            entity={course}
                            disableSelection={course.entity.attributes.includes(COURSE_ATTRIBUTE_PUBLISHED)}
                            selectionModel={selectionModel}>
                            <TableColumn>
                                {course.id}
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {course.entity.containerMeta.title}
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {course.entity.certificateTitle}
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {course.entity.attributes.map(attribute => {
                                    return t("course-editor.edit-course-form.fields.attributes." + attribute)
                                }).join(",")}
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {
                                    (() => {
                                        const hours = Math.floor(course.entity.containerMeta.length / 3600)
                                        const minutes = Math.floor((course.entity.containerMeta.length - hours * 3600) / 60)
                                        const seconds = course.entity.containerMeta.length - hours * 3600 - minutes * 60
                                        return hours + " h " + minutes + " min " + seconds + " s"
                                    })()
                                }
                            </TableColumn>
                            <TableColumn>
                                {course.entity.versions.releaseVersion >= 0 ? "Release: " + course.entity.versions.releaseVersion : ""}<br/>
                                {"Entwurf: " + course.entity.versions.draftVersion}
                            </TableColumn>
                            <ActionTableColumn>
                                <PublishCourseButton courseId={course.id!}/>
                                <CopyCourseButton courseId={course.id as string}/>
                                <ActionLink to={`/courses/${course.id}/editor`} icon={faPencilAlt}
                                            tooltip={t("course-table.columns.actions.modules-tooltip")}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const CourseTable = WithSelections<any, string>(
    WithEntityList(
        withTranslation("courses")(CourseTableComponent),
        ENTITY_TYPE_COURSE
    )
);
