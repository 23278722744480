import React from "react";
import Select from "react-select";

interface OwnProps {
    trueLabel: string;
    falseLabel: string;
    placeholder?: string;

    value: boolean;
    onChange: (newValue: boolean) => void;
}

type Props = OwnProps ;

export const BooleanSelect = ({value, onChange, trueLabel, falseLabel, placeholder}: Props) => {
    const options = [
        {
            value: true,
            label: trueLabel
        },
        {
            value: false,
            label: falseLabel
        }
    ];
    return (<Select
        className="react-select"
        classNamePrefix="react-select"
        isClearable={false}
        options={options}
        placeholder={placeholder}
        value={value !== undefined ? options.find(o => o.value === value) : undefined}
        onChange={((item: any) => onChange(item.value))}
        getOptionValue={(opt: any) => opt.value}
        getOptionLabel={(item: any) => item.label}
    />);
};

