import React, {useState} from "react";
import {Card} from "primereact/card";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {EditCustomerMessageDefinitionPageForm} from "./EditCustomerMessageDefinitionPageForm";
import {CustomerMessageDefinitionPagesOrderList} from "./CustomerMessageDefinitionPagesOrderList";
import {EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment$key} from "../../../../../__generated__/EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment.graphql";
import {CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment$key} from "../../../../../__generated__/CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment.graphql";

const CUSTOMER_MESSAGE_DEFINITION_V2_FRAGMENT = graphql`
    fragment CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {
        ...CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment
    }`

interface State {
    customerMessageDefinitionPage?: EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment$key
}

interface OwnProps {
    customerMessageDefinitionV2FragmentRef: CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment$key
}

export const CustomerMessageDefinitionPages = ({customerMessageDefinitionV2FragmentRef}: OwnProps) => {

    const [state, setState] = useState<State>({})

    const customerMessageDefinitionV2 = useFragment<CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment$key>(CUSTOMER_MESSAGE_DEFINITION_V2_FRAGMENT, customerMessageDefinitionV2FragmentRef)

    return <Card className="mb-2 mt-5">
        {state.customerMessageDefinitionPage ? <EditCustomerMessageDefinitionPageForm
                onClose={() => setState(_ => ({}))}
                customerMessageDefinitionPageFragmentRef={state.customerMessageDefinitionPage}/> :
            <CustomerMessageDefinitionPagesOrderList
                editPage={(item: EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment$key) => setState({customerMessageDefinitionPage: item})}
                customerMessageDefinitionV2FragmentRef={customerMessageDefinitionV2}
            />}
    </Card>
}