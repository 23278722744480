import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {CurrencyDisplay} from "../../../../../core/components/CurrencyDisplay";
import {useFragment} from "react-relay";
import {
    DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment$key
} from "../../../../../../__generated__/DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment.graphql";

const SEARCH_DISCOUNT_USAGE_RESULT_FRAGMENT = graphql`
    fragment DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment on SearchDiscountCodeUsagesResult {
        sumGrossPriceWithDiscounts
        sumGrossPriceWithoutDiscounts
    }
`

interface OwnProps {
    searchDiscountUsageResultFragmentRef: DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment$key
}

export const DiscountUsagePriceDisplay = ({searchDiscountUsageResultFragmentRef}: OwnProps) => {
    const searchDiscountUsageResult = useFragment<DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment$key>(SEARCH_DISCOUNT_USAGE_RESULT_FRAGMENT, searchDiscountUsageResultFragmentRef)

    return (
        <div className="grid ">
            <div className="col-6">Summe mit Discounts:</div>
            <CurrencyDisplay
                className="col-6"
                value={searchDiscountUsageResult.sumGrossPriceWithDiscounts}/>
            <div className="col-6">Summe ohne Discounts:</div>
            <CurrencyDisplay
                className="col-6"
                value={searchDiscountUsageResult.sumGrossPriceWithoutDiscounts}/>
        </div>
    )
}
