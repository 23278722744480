import { EntityWrapper } from "@thekeytechnology/framework-react";

export class GlossaryEntry {
    public static TYPE = "glossary-entry";

    public static new = () => new EntityWrapper<GlossaryEntry>(
        undefined,
        new GlossaryEntry(
            "",
            "",
            []
        )
    );

    public constructor(public title: string,
                       public definition: string,
                       public additionalLinks: string[]) {
    }
}
