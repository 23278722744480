import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import Select from "react-select";
import { CONNECTION_TYPE_SOURCE } from "@thekeytechnology/thekey-academy-frontend-library";
import { ClearIndicator } from "../../../../../../core/components/select/ClearIndicator";

interface OwnProps {
    connectionType: string;
    connectionTypeChanged: (newType: string) => void;
}

type Props = OwnProps & WithTranslation;

const ConnectionTypeSelectComponent = (props: Props) => {
    const {connectionType, connectionTypeChanged, t} = props;

    const OPTIONS = [{
        value: CONNECTION_TYPE_SOURCE,
        label: t(`course-editor.connection-type-select.connection-types.${CONNECTION_TYPE_SOURCE}`)
    }];

    return <Select
        className="react-select category-select"
        classNamePrefix="react-select"
        components={{ClearIndicator}}
        placeholder={t("course-editor.connection-type-select.placeholder")}
        options={OPTIONS}
        value={OPTIONS.find(o => o.value === connectionType)}
        onChange={(item: any) => connectionTypeChanged(item.value)}
        getOptionValue={(opt: any) => opt.value}
        getOptionLabel={item => item.label}
    />;
};

export const ConnectionTypeSelect = withTranslation("courses")(ConnectionTypeSelectComponent);
