import React from "react";
import { CurrencyDisplay } from "../../../../../core/components/CurrencyDisplay";
import { CourseOfferItem, DiscountOfferItem, OfferItem } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    offerItems: OfferItem[];
}

export const OfferTotals = ({offerItems}: OwnProps) => {
    let totalNetPrice = 0
    let totalGrossPrice = 0;

    let monthlyPriceNet = 0
    let monthlyPriceGross = 0
    let rateCount = 0

    offerItems.forEach(item => {
        switch (item.itemType) {
            case "course-offer-item":
                const courseOfferItem = item as CourseOfferItem;
                const netPriceToUse = courseOfferItem.price.discount ? courseOfferItem.price.discount.netAmount : courseOfferItem.price.netPrice;
                const grossPriceToUse = courseOfferItem.price.discount ? courseOfferItem.price.discount.grossAmount : courseOfferItem.price.grossPrice

                totalNetPrice += netPriceToUse * courseOfferItem.amount
                totalGrossPrice += grossPriceToUse * courseOfferItem.amount

                const monthlyNetPriceToUse = courseOfferItem.price.monthlyPrice ? courseOfferItem.price.monthlyPrice.netPrice : 0;
                const monthlyGrossPriceToUse = courseOfferItem.price.monthlyPrice ? courseOfferItem.price.monthlyPrice.grossPrice : 0;

                monthlyPriceNet += monthlyNetPriceToUse * courseOfferItem.amount
                monthlyPriceGross += monthlyGrossPriceToUse * courseOfferItem.amount

                rateCount = courseOfferItem.price.monthlyPrice ? courseOfferItem.price.monthlyPrice.rateCount : 0

                break;
            case "discount-offer-item":
                const discountOfferItem = item as DiscountOfferItem;
                totalNetPrice -= discountOfferItem.price.netPrice
                totalGrossPrice -= discountOfferItem.price.grossPrice

                const monthlyNetDiscountToUse = discountOfferItem.price.monthlyPrice ? discountOfferItem.price.monthlyPrice.netPrice : 0;
                const monthlyGrossDiscountToUse = discountOfferItem.price.monthlyPrice ? discountOfferItem.price.monthlyPrice.grossPrice : 0;

                monthlyPriceNet -= monthlyNetDiscountToUse
                monthlyPriceGross -= monthlyGrossDiscountToUse
                break;
        }
    })

    return <>
        Das gesamte Angebot beläuft sich auf <CurrencyDisplay value={totalNetPrice}/> netto (<CurrencyDisplay
        value={totalGrossPrice}/> brutto). {rateCount ? <><br/>Oder in {rateCount} Raten zu
        je <CurrencyDisplay value={monthlyPriceNet}/> netto (<CurrencyDisplay
            value={monthlyPriceGross}/> brutto). Gesamt: <CurrencyDisplay
            value={monthlyPriceNet * rateCount}/> netto (<CurrencyDisplay
            value={monthlyPriceGross * rateCount}/> brutto)</> : null}</>
}
