import React from "react";
import { useLocking } from "../hooks/useLocking";

interface OwnProps {
    resourceId: string
    children: any
}

export const ShowWhenNotLocked = ({children, resourceId}: OwnProps) => {
    const {isLockedBySomeone} = useLocking(resourceId)

    if (isLockedBySomeone) {
        return <>WIRD GERADE VON JEMAND ANDEREM BEARBEITET</>
    } else {
        return <>{children}</>;
    }
}
