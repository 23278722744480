import { ApiCallState } from "@thekeytechnology/framework-react";

export interface AsyncContentProps {
    loadingComponent: () => any
    successComponent: () => any
    apiCallState: ApiCallState
}

export const AsyncContent = (props: AsyncContentProps) => {
    const {apiCallState, loadingComponent, successComponent} = props;
    return (
        apiCallState.inProgress ? loadingComponent() : successComponent()
    );
};
