import {
    ApiCallAction,
    apiCallSucceededAction,
    ApiCallSucceededAction,
    apiDelete,
    apiGet,
    apiPost,
    defaultApiErrorHandling,
    Filter,
    matchesAnyOfTheseApiSuccesses,
    matchesApiCall,
    matchesApiSuccess,
    Message,
    postMessageAction,
    reloadEntityListAction,
    selectApiBase,
    selectAuthState,
    selectFilters,
    selectPagination,
    UserWithRoles,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import {combineEpics, StateObservable} from "redux-observable";
import {API_UPDATE_USER, UpdateUser} from "../actions/update-user";
import {from, Observable} from "rxjs";
import {Action} from "redux";
import {map, mergeMap, withLatestFrom} from "rxjs/operators";
import {API_FETCH_USER_META_ADMIN} from "../actions/fetch-user-meta";
import {API_UPDATE_USER_META} from "../actions/update-user-meta";
import {API_FETCH_USER_ORDERS_ADMIN} from "../actions/fetch-orders";
import {API_FETCH_USER_ADMIN, fetchUserAdminAction} from "../actions/fetch-user";
import {API_FETCH_COURSE_STATES_ADMIN, fetchCourseStatesAdminAction} from "../actions/fetch-course-states-admin";
import {API_FETCH_COURSES_ADMIN} from "../actions/fetch-courses-admin";
import {API_UPDATE_COURSE_STATE, UpdateCourseState} from "../actions/update-course-state";
import {API_FETCH_ORDER_ADMIN} from "../actions/fetch-order";
import {API_MASS_UNLOCK, MassUnlockResponse} from "../actions/mass-unlock";
import {API_FETCH_USER_ROLES_ADMIN} from "../actions/fetch-user-roles";
import {API_FETCH_ACCOUNT_ADMIN, fetchAccountAdminAction} from "../actions/fetch-account";
import {API_UPDATE_ACCOUNT, UpdateAccount} from "../actions/update-account";
import {selectAccountAdmin, selectUserAdmin} from "../selectors";
import {API_UPDATE_ACCOUNT_META, UpdateAccountMeta} from "../actions/update-account-meta";
import {API_FETCH_ACCOUNT_META_ADMIN} from "../actions/fetch-account-meta";
import {API_FETCH_USERS_IN_ACCOUNT_ADMIN} from "../actions/fetch-users-in-account";
import {API_UPDATE_LICENSE_POOL_ADMIN, UpdateLicensePool} from "../actions/license-pool/update-license-pool";
import {API_FETCH_LICENSE_POOL_ADMIN, fetchLicensePoolAdminAction} from "../actions/license-pool/fetch-license-pool";
import {API_CREATE_LICENSE_POOL_ADMIN} from "../actions/license-pool/create-license-pool";
import {API_ADD_LICENSES_ADMIN} from "../actions/license-pool/add-licenses";
import {API_REMOVE_LICENSE_ADMIN} from "../actions/license-pool/remove-license";
import {API_FETCH_ACCOUNT_ORDERS_ADMIN} from "../actions/fetch-account-orders";
import {AxiosResponse} from "axios";
import {API_DOWNLOAD_USERS_CSV} from "../actions/download-users-csv";
import {API_UPDATE_USER_ROLES, UpdateUserRoles} from "../actions/update-user-roles";

const fetchUser$ = apiGet({apiType: API_FETCH_USER_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/users/${action.payload}`,
);

const saveUser$ = apiPost({apiType: API_UPDATE_USER},
    (action: ApiCallAction<UpdateUser>) => `/api/admin/v1/users/${action.payload.userId}`,
);

const saveUserMeta$ = apiPost({apiType: API_UPDATE_USER_META},
    (action: ApiCallAction<UpdateUser>) => `/api/admin/v1/users/${action.payload.userId}/meta`,
);

const fetchUserMeta$ = apiGet({apiType: API_FETCH_USER_META_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/users/${action.payload}/meta`,
);

const fetchUserRoles$ = apiGet({apiType: API_FETCH_USER_ROLES_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/users/${action.payload}/roles`,
);

const fetchUserOrders$ = apiGet({apiType: API_FETCH_USER_ORDERS_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/users/${action.payload}/orders`,
);

const fetchAccountOrders$ = apiGet({apiType: API_FETCH_ACCOUNT_ORDERS_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/accounts/${action.payload}/orders`,
);

const fetchOrder$ = apiGet({apiType: API_FETCH_ORDER_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/orders/${action.payload}`,
);

const fetchCourses$ = apiGet({apiType: API_FETCH_COURSES_ADMIN},
    () => `/api/admin/v1/courses`,
);

const fetchCourseStates$ = apiGet({apiType: API_FETCH_COURSE_STATES_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/users/${action.payload}/states`,
);

const updateCourseState$ = apiPost({apiType: API_UPDATE_COURSE_STATE},
    (action: ApiCallAction<UpdateCourseState>) => `/api/admin/v1/users/${action.payload.userId}/states`,
);

const massUnlock$ = apiPost({apiType: API_MASS_UNLOCK},
    `/api/admin/v1/mass-unlock`,
);

const fetchAccount$ = apiGet({apiType: API_FETCH_ACCOUNT_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/accounts/${action.payload}`,
);

const saveAccount$ = apiPost({apiType: API_UPDATE_ACCOUNT},
    (action: ApiCallAction<UpdateAccount>) => `/api/admin/v1/accounts/${action.payload.accountId}`,
);

const saveAccountMeta$ = apiPost({apiType: API_UPDATE_ACCOUNT_META},
    (action: ApiCallAction<UpdateAccountMeta>) => `/api/admin/v1/accounts/${action.payload.accountId}/meta`,
);

const fetchAccountMeta$ = apiGet({apiType: API_FETCH_ACCOUNT_META_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/accounts/${action.payload}/meta`,
);

const fetchUsersInAccount$ = apiGet({apiType: API_FETCH_USERS_IN_ACCOUNT_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/accounts/${action.payload}/users`,
);

const fetchLicensePool$ = apiGet({apiType: API_FETCH_LICENSE_POOL_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/accounts/${action.payload}/license-pool`,
);

const createLicensePool$ = apiGet({apiType: API_CREATE_LICENSE_POOL_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/accounts/${action.payload}/license-pool/create`,
);

const updateLicensePool$ = apiPost({apiType: API_UPDATE_LICENSE_POOL_ADMIN},
    (action: ApiCallAction<UpdateLicensePool>) => `/api/admin/v1/license-pools/${action.payload.licensePoolId}/update`,
);

const addLicenses$ = apiPost({apiType: API_ADD_LICENSES_ADMIN},
    (action: ApiCallAction<UpdateLicensePool>) => `/api/admin/v1/license-pools/${action.payload.licensePoolId}/add`,
);

const updateUserRoles$ = apiPost({apiType: API_UPDATE_USER_ROLES},
    (action: ApiCallAction<UpdateUserRoles>) => `/api/admin/v1/users/roles/update`,
);

const removeLicense$ = apiDelete({apiType: API_REMOVE_LICENSE_ADMIN},
    (action: ApiCallAction<string>) => `/api/admin/v1/licenses/${action.payload}`,
);


const reloadOnUpdateUserRoles$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    matchesApiCall(API_UPDATE_USER_ROLES),
    withLatestFrom(state$),
    map(([, state]: [Action, any]) => {
        const account = selectAccountAdmin(state);
        return fetchAccountAdminAction(account?.id!);
    }),
);

const reloadLicensePoolOnCreationAndUpdate$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_CREATE_LICENSE_POOL_ADMIN}, {apiType: API_UPDATE_LICENSE_POOL_ADMIN}, {apiType: API_ADD_LICENSES_ADMIN}, {apiType: API_REMOVE_LICENSE_ADMIN}),
    withLatestFrom(state$),
    map(([, state]: [Action, any]) => {
        const account = selectAccountAdmin(state);
        return fetchLicensePoolAdminAction(account?.id!);
    })
);

const postMessageOnMassUnlockSuccess$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_MASS_UNLOCK}),
    map((action: Action) => {
        const responseAction = action as ApiCallSucceededAction<MassUnlockResponse>
        return postMessageAction(Message.TYPE_SUCCESS, `Erfolgreich ${responseAction.payload.existingUsers} existierende Benutzer und ${responseAction.payload.newUsers} neue Benutzer freigeschalten.`)
    })
);

const reloadUsersOnMassUnlockSuccess$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_MASS_UNLOCK}),
    map(() => {
        return reloadEntityListAction(UserWithRoles.TYPE)()
    })
);

const reloadUser$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_UPDATE_USER}),
    withLatestFrom(state$),
    map(([, state]: [Action, any]) => {
        const user = selectUserAdmin(state)
        return fetchUserAdminAction(user?.id!);
    })
);

const reloadAccount$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_UPDATE_ACCOUNT}),
    withLatestFrom(state$),
    map(([, state]: [Action, any]) => {
        const account = selectAccountAdmin(state);
        return fetchAccountAdminAction(account?.id!);
    })
);

const reloadCourseStatesOnUpdate$ = (action$: Observable<Action>, state: StateObservable<any>) => action$.pipe(
    matchesApiSuccess(API_UPDATE_COURSE_STATE),
    withLatestFrom(state),
    map(([, state]: [Action, any]) => {
        const user = selectUserAdmin(state)
        return fetchCourseStatesAdminAction(user?.id!);
    })
);

const reloadLicensePoolOnUpdate$ = (action$: Observable<Action>, state: StateObservable<any>) => action$.pipe(
    matchesApiSuccess(API_UPDATE_LICENSE_POOL_ADMIN),
    withLatestFrom(state),
    map(([, state]: [Action, any]) => {
        const account = selectAccountAdmin(state);
        return fetchLicensePoolAdminAction(account?.id!)
    })
);

const downloadUsers$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    matchesApiCall(API_DOWNLOAD_USERS_CSV),
    withLatestFrom(state$),
    mergeMap(([action, state]: [ApiCallAction<null>, any]) => {
            const pagination = selectPagination(UserWithRoles.TYPE)(state);

            const currentFiltersForAllTypes: Filter[][] = Object.values(selectFilters(UserWithRoles.TYPE)(state));
            const filters: Filter[] = ([] as Filter[]).concat(...currentFiltersForAllTypes);

            return from(withAuthedAxios(selectApiBase(state), selectAuthState(state)).post(`/entities/read`, {
                entityType: UserWithRoles.TYPE,
                paginationRequest: {
                    page: pagination.currentPage,
                    docsPerPage: pagination.docsPerPage
                },
                filters
            }, {headers: {Accept: "text/csv"}}))
                .pipe(
                    map((response: AxiosResponse) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `users.csv`);
                        document.body.appendChild(link);
                        link.click();
                        if (link.parentNode) {
                            link.parentNode.removeChild(link);
                        }

                        return apiCallSucceededAction(action.apiQualifier, null);
                    }),
                    defaultApiErrorHandling(action.apiQualifier)
                );
        }
    )
);

export const userManagementModuleEpics$ = combineEpics(
    fetchUser$,
    saveUser$,
    reloadUser$,
    saveUserMeta$,
    fetchUserMeta$,
    fetchUserOrders$,
    fetchCourses$,
    fetchCourseStates$,
    updateCourseState$,
    reloadCourseStatesOnUpdate$,
    fetchOrder$,
    massUnlock$,
    postMessageOnMassUnlockSuccess$,
    reloadUsersOnMassUnlockSuccess$,
    fetchUserRoles$,
    fetchAccount$,
    saveAccount$,
    reloadAccount$,
    saveAccountMeta$,
    fetchAccountMeta$,
    fetchUsersInAccount$,
    updateLicensePool$,
    reloadLicensePoolOnUpdate$,
    fetchLicensePool$,
    createLicensePool$,
    reloadLicensePoolOnCreationAndUpdate$,
    addLicenses$,
    removeLicense$,
    fetchAccountOrders$,
    downloadUsers$,
    updateUserRoles$,
    reloadOnUpdateUserRoles$
)
