import React from "react";
import {InputSwitch} from "primereact/inputswitch";
import {CustomerMessageDefinitionNotificationDefinition} from "./EditCustomerMessageDefinitionV2CoreForm";
import {useTranslation} from "react-i18next";
import {DefaultTextFieldComponent} from "../../../core/components/form/forms/DefaultTextComponent";

interface OwnProps {
    fieldValue: CustomerMessageDefinitionNotificationDefinition | undefined
    updateField: (notificationDefinition?: CustomerMessageDefinitionNotificationDefinition) => void
}

export const EditCustomerMessageDefinitionNotificationDefinitionSubform = ({
                                                                           fieldValue,
                                                                           updateField
                                                                       }: OwnProps) => {

    const {t: tCMV2} = useTranslation("customerMessagesV2")

    return <div className="mt-2">
        <div className="p-field mb-3">
            <InputSwitch checked={fieldValue !== undefined} onChange={(e) => updateField(e.value ? {title: "", body: ""} : undefined)}/>
        </div>
        {fieldValue ?
            <div className="p-field mb-3">
                <label>{tCMV2("edit-customer-message-definition-v2.core.notification-definition.title")} *</label>
                <DefaultTextFieldComponent fieldName={"notificationDefinitionTitle"}
                                           fieldValue={fieldValue.title}
                                           updateField={(title: string | undefined) => updateField({
                                               ...fieldValue,
                                               title: title || "",
                                           })}
                                           isValid={true}
                                           required={true}
                />
            </div> : undefined}
        {fieldValue ?
            <div className="p-field mb-3">
                <label>{tCMV2("edit-customer-message-definition-v2.core.notification-definition.body")} *</label>
                <DefaultTextFieldComponent fieldName={"notificationDefinitionBody"}
                                           fieldValue={fieldValue.body}
                                           updateField={(body: string | undefined) => updateField({
                                               ...fieldValue,
                                               body: body || ""
                                           })}
                                           isValid={true}
                                           required={true}
                />
            </div> : undefined}
    </div>
}
