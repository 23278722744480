import {
    addFiltersAction,
    Filter,
    FilterTerm,
    PropertyFilter,
    removeFiltersAction,
    selectFilter
} from "@thekeytechnology/framework-react";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Action, Dispatch } from "redux";
import { OptionType } from "../../../course/components/course-editor/course/SimpleOptionSelectField";
import { ClearIndicator } from "../select/ClearIndicator";

interface OwnProps<T> {
    options: OptionType<T>[];
    entityType: string;
    property: string;
    filterKey: string;
    placeholder: string;
    filterType?: string;
}

interface StateProps {
    currentFilters?: Filter[];
}

interface DispatchProps {
    removeFilters: () => void;
    addFilters: (filters: Filter[]) => void;
}

type Props<T> = OwnProps<T> & StateProps & DispatchProps;

export function UnconnectedSimpleOptionSelectFilter<T>(props: Props<T>) {
    const {placeholder, options, currentFilters} = props;

    const determineStatus = () => {
        if (!currentFilters || currentFilters.length === 0) {
            return null;
        }

        const firstFilter = currentFilters[0] as PropertyFilter;
        return options.find(option => option.value === firstFilter.filterTerm.value);
    };

    const filter = (selection: OptionType<T>) => {
        const {addFilters, removeFilters, property, filterType} = props;

        const filterTypeToUse = filterType ? filterType : FilterTerm.TYPE_STRING;

        if (selection === null) {
            removeFilters();
        } else {
            addFilters([
                new PropertyFilter(
                    property, new FilterTerm(filterTypeToUse, FilterTerm.OPERATION_EQ, selection.value)
                )
            ]);
        }
    };

    return (
        <Select
            components={{ClearIndicator}}
            placeholder={placeholder}
            className="filter react-select"
            classNamePrefix="react-select"
            isClearable={true}
            onChange={(option: any) => filter(option as OptionType<T>)}
            options={options as any}
            value={determineStatus()}
        />
    );
}

export const SimpleOptionSelectFilter: <T extends any>() => React.NamedExoticComponent<OwnProps<T>> = <T extends any>() => connect<StateProps, DispatchProps, OwnProps<T>>(
    (state: any, props: OwnProps<T>) => {
        return {
            currentFilters: selectFilter(props.entityType, props.filterKey)(state)
        };
    },
    (dispatch: Dispatch<Action>, props: OwnProps<T>) =>
        ({
            addFilters: (filters: Filter[]) => dispatch(addFiltersAction(props.entityType)(props.filterKey, filters)),
            removeFilters: () => dispatch(removeFiltersAction(props.entityType)([props.filterKey]))
        })
)
(UnconnectedSimpleOptionSelectFilter);
