import React from "react";
import "./users-table.scss";
import graphql from 'babel-plugin-relay/macro'
import {usePaginationFragment} from "react-relay";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {NavLink} from "react-router-dom";
import {Button} from "primereact/button";
import {UsersTableFilters} from "./UsersTableFilter";
import {PaginationButton} from "../../../core/components/pagination/PaginationButton";
import {UsersTable_UsersFragment$key} from "../../../../__generated__/UsersTable_UsersFragment.graphql";
import {UsersTable_UsersFragmentRefetch} from "../../../../__generated__/UsersTable_UsersFragmentRefetch.graphql";

const USERS_FRAGMENT = graphql`
    fragment UsersTable_UsersFragment on Query @refetchable(queryName: "UsersTable_UsersFragmentRefetch") @argumentDefinitions(
        nameOrEmail: {type: "String"},
        userId: {type: "String"},
        first: {type: "Int!"},
        after: {type: "String"}
    ){
        Admin {
            User {
                Users(first: $first, after: $after, nameOrEmail: $nameOrEmail, userId: $userId) @connection(key: "UsersTable_Users") {
                    edges {
                        node {
                            id
                            rawId
                            name
                            email
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    usersFragmentRef: UsersTable_UsersFragment$key
}

export const UsersTable = ({usersFragmentRef}: OwnProps) => {
    const {
        data: {Admin: {User}},
        hasNext,
        loadNext,
        refetch
    } = usePaginationFragment<UsersTable_UsersFragmentRefetch, UsersTable_UsersFragment$key>(USERS_FRAGMENT, usersFragmentRef)

    const users = User.Users.edges?.filter(e => !!e).map(e => e!.node)|| []

    return <>
        <UsersTableFilters refetch={(userId?: string, nameOrEmail?: string) => refetch({
            userId: userId,
            nameOrEmail: nameOrEmail,
            first: 20
        })}/>
        <DataTable className="p-mb-2" value={users}>
            <Column field="rawId" header="ID"/>
            <Column field="name" header="Name"/>
            <Column field="email" header="E-Mail"/>
            <Column header="Aktionen" style={{width: "20%"}} body={item =>
                <NavLink to={`/users/${item.id}/edit`}> <Button icon={"pi pi-search"}/></NavLink>
            }/>
        </DataTable>
        <PaginationButton hasNext={hasNext} loadNext={() => loadNext(20)}/>
    </>
};
