import { ApiCallSucceededAction, isApiSuccess } from "@thekeytechnology/framework-react";
import { TriggerDefinition } from "../../core/model/trigger/definitions/trigger-definition";
import { API_FETCH_TRIGGER_DEFINITIONS } from "../actions/fetch-trigger-definitions";
import {Action} from "redux";

export interface EmailModuleState {
    triggerDefinitions: TriggerDefinition[];
}

export const initialState: EmailModuleState = {
    triggerDefinitions: []
};

export function triggersModuleReducer(state = initialState, action : Action): EmailModuleState {
    if (isApiSuccess(API_FETCH_TRIGGER_DEFINITIONS)(action)) {
        return {
            ...state,
            triggerDefinitions: (action as ApiCallSucceededAction<{ triggers: TriggerDefinition[] }>).payload.triggers
        }
    }
    return state;
}
