import { EntityWrapper, TkFile } from "@thekeytechnology/framework-react";

export const ENTITY_TYPE_OFFER = "aggregated-offer"

export interface Offer {
    title: string
    price: number
    discountPrice?: number
    monthlyRate: number
    rateCount: number
    taxRate: number
    courseId: string
    courseTitle: string
    isIHK: boolean
    courseIcon?: EntityWrapper<TkFile>
    discountTitle?: string
    discountText?: string
    discountUntil?: string
}
