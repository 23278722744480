import React, {Suspense, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {ProgressSpinner} from "primereact/progressspinner";
import {ValidatedFieldV2} from "../../../../../core/components/form/ValidatedFieldV2";
import {DefaultTextFieldComponent} from "../../../../../core/components/form/forms/DefaultTextComponent";
import {Toast} from "primereact/toast";
import {
    DiscountCodeForm_DiscountCodeFragment$key
} from "../../../../../../__generated__/DiscountCodeForm_DiscountCodeFragment.graphql";
import {
    DiscountCodeForm_EditCodeMutation, EditDiscountCode
} from "../../../../../../__generated__/DiscountCodeForm_EditCodeMutation.graphql";

const DISCOUNT_CODE_FRAGMENT = graphql`
    fragment DiscountCodeForm_DiscountCodeFragment on DiscountCodeV2 {
        id
        code
    }`

const EDIT_CODE_MUTATION = graphql`
    mutation DiscountCodeForm_EditCodeMutation($input: UpdateDiscountCodeV2Input!) {
        Admin {
            Billing {
                updateDiscountCodeV2(input: $input) {
                    data {
                        node {
                            ... DiscountCodeForm_DiscountCodeFragment
                        }
                    }
                }
            }
        }
    }`


interface DiscountCode {
    id: string
    code: string

}

interface OwnProps {
    discountCodeFragmentRef: DiscountCodeForm_DiscountCodeFragment$key
}

export const DiscountCodeForm = ({discountCodeFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const discountCodeFormNs = "billing.discount-code.code.form"

    const toast = useRef<Toast>(null)

    const discountCode = useFragment<DiscountCodeForm_DiscountCodeFragment$key>(DISCOUNT_CODE_FRAGMENT, discountCodeFragmentRef)

    const [editActionRaw, isLoading] = useMutation<DiscountCodeForm_EditCodeMutation>(EDIT_CODE_MUTATION)

    const editCode = (data: EditDiscountCode) => editActionRaw({
        variables: {
            input: {
                id: discountCode.id,
                data: data
            }
        },
        onCompleted: () => {
            toast.current?.show({
                severity: "success",
                life: 3000,
                summary: "Discount-Aktion",
                detail: "Erfolgreich gespeichert"
            })
        }
    })

    const formik = useFormik<DiscountCode>({
        initialValues: {
            id: discountCode.id,
            code: discountCode.code
        },
        validationSchema: Yup.object().shape({}),
        onSubmit: (values, {setSubmitting}) => {
            editCode({
                newCode: values.code
            })
            setSubmitting(false)
        }
    })

    return <Card>
        <Toast ref={toast}/>
        <Suspense fallback={<ProgressSpinner/>}>
            {!isLoading ? <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div className="flex flex-column">
                    <Button
                        disabled={false}
                        type="submit"
                        icon="pi pi-save"
                        label={t(`billing.discount-code.button.save`)}
                        className="ml-auto w-8rem"/>
                    <ValidatedFieldV2<DiscountCode, string>
                        name={"code"}
                        label={t(`${discountCodeFormNs}.code`)}
                        required={true}
                        component={DefaultTextFieldComponent}
                        formikConfig={formik}
                    />
                </div>
            </form> : <ProgressSpinner/>}
        </Suspense>
    </Card>
}
