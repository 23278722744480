import { EntityWrapper, SelectionModel } from "@thekeytechnology/framework-react";
import React from "react";
import { SelectionTableColumn, TableRow } from "./Table";

interface EntityTableRowProps<T> {
    entity: EntityWrapper<T>;
    selectionModel: SelectionModel<string>;
    children: any;
    disableSelection?: boolean;
}

export function EntityTableRow<T>(props: EntityTableRowProps<T>) {
    const {entity, selectionModel, children, disableSelection} = props;
    return (
        <TableRow>
            <SelectionTableColumn<string> disableSelection={disableSelection} item={entity.id as string}
                                          selectionModel={selectionModel}/>
            {children}
        </TableRow>
    );
}
