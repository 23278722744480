import React, { FocusEventHandler } from "react";
import Select from "react-select";
import { CurrencyDisplay } from "../../../../../../core/components/CurrencyDisplay";
import { TaxPriceInput } from "./TaxPriceInput";
import { Price } from "@thekeytechnology/thekey-academy-frontend-library";
import { ClearIndicator } from "../../../../../../core/components/select/ClearIndicator";

interface OwnProps {
    price: Price
    onUpdate: (newPrice: Price) => void;
    onBlur?: FocusEventHandler<any>;
    disableDiscount?: boolean;
    disableMonthly?: boolean;
}

export const PriceEditor = ({
                                price,
                                onUpdate,
                                onBlur,
                                disableDiscount,
                                disableMonthly
                            }: OwnProps) => {

    const untilOptions = ["end-of-month"].map(o => ({
        value: o,
        label: o
    }));
    return <div>
        <div className="d-flex">
            <TaxPriceInput
                taxRate={price.taxRate}
                netValue={price.netPrice}
                grossValue={price.grossPrice}
                onBlur={onBlur}
                updatePrice={(netPrice, grossPrice) => onUpdate(
                    {
                        ...price,
                        netPrice: netPrice,
                        grossPrice: grossPrice
                    }
                )}/>
            <label className="ml-2 d-flex flex-column">
                <div>Steuersatz</div>
                <input
                    className="form-control default-input"
                    min={0}
                    onBlur={onBlur}
                    type="number"
                    value={price.taxRate}
                    step={1}
                    onChange={event => {
                        onUpdate(
                            {
                                ...price,
                                taxRate: parseFloat(event.target.value)
                            }
                        )
                    }}/>
            </label>
        </div>

        {!disableDiscount ? <label className="mt-3 d-flex align-items-center">
            <input
                type="checkbox"
                className="mr-3 position-relative"
                checked={price.discount !== undefined}
                onBlur={onBlur}
                onChange={event => {
                    onUpdate(
                        {
                            ...price,
                            discount: event.target.checked ? {
                                netAmount: price.netPrice,
                                grossAmount: price.grossPrice,
                                title: "",
                                text: "",
                                until: "end-of-month"
                            } : undefined
                        }
                    )
                }}/>
            <span>Angebot</span>
        </label> : null}

        {price.discount && !disableDiscount ? <div className="mt-2">
            <TaxPriceInput
                taxRate={price.taxRate}
                netValue={price.discount.netAmount}
                grossValue={price.discount.grossAmount}
                onBlur={onBlur}
                updatePrice={(netPrice, grossPrice) => onUpdate(
                    {
                        ...price,
                        discount: {
                            ...price.discount!,
                            netAmount: netPrice,
                            grossAmount: grossPrice
                        }
                    }
                )}/>
            <label className="d-flex flex-column">
                <div>Angebotstitel</div>
                <input
                    className="form-control default-input"
                    type="text"
                    onBlur={onBlur}
                    placeholder={"Angebotstitel"}
                    value={price.discount.title}
                    onChange={event => {
                        onUpdate(
                            {
                                ...price,
                                discount: {
                                    ...price.discount!,
                                    title: event.target.value
                                }
                            }
                        )
                    }}/>
            </label>
            <label className="d-flex flex-column">
                <div>Beschreibung des Angebots</div>
                <input
                    className="form-control default-input"
                    onBlur={onBlur}
                    placeholder={"Beschreibung des Angebots"}
                    type="text" value={price.discount.text} onChange={event => {
                    onUpdate(
                        {
                            ...price,
                            discount: {
                                ...price.discount!,
                                text: event.target.value
                            }
                        }
                    )
                }}/>
            </label>
            <label className="d-flex flex-column">
                <div>Laufzeit</div>
                <Select<any>
                    className="react-select category-select"
                    classNamePrefix="react-select"
                    components={{ClearIndicator}}
                    options={untilOptions}
                    onBlur={onBlur}
                    value={untilOptions.find(o => o.value === price.discount?.until)}
                    onChange={(item: any) => {
                        onUpdate(
                            {
                                ...price,
                                discount: {
                                    ...price.discount!,
                                    until: item
                                }
                            }
                        )
                    }}
                    getOptionValue={(opt: any) => opt.value}
                    getOptionLabel={item => item.label}
                />
            </label>
        </div> : null}

        {!disableMonthly ? <label className="mt-3 d-flex align-items-center">
            <input
                type="checkbox"
                className="mr-3 position-relative"
                checked={price.monthlyPrice !== undefined}
                onBlur={onBlur}
                onChange={event => {
                    onUpdate(
                        {
                            ...price,
                            monthlyPrice: event.target.checked ? {
                                netPrice: price.netPrice,
                                grossPrice: price.grossPrice,
                                rateCount: 1
                            } : undefined
                        }
                    )
                }}/>
            <span>Monatliche Zahlung</span>
        </label> : null}


        {price.monthlyPrice && !disableMonthly ? <div className="mt-2">
            <TaxPriceInput
                taxRate={price.taxRate}
                netValue={price.monthlyPrice.netPrice}
                grossValue={price.monthlyPrice.grossPrice}
                onBlur={onBlur}
                updatePrice={(netPrice, grossPrice) => onUpdate(
                    {
                        ...price,
                        monthlyPrice: {
                            ...price.monthlyPrice!,
                            netPrice,
                            grossPrice
                        }
                    }
                )}/>

            <label className="d-flex flex-column">
                <div>Anzahl Raten</div>
                <input
                    className="form-control default-input"
                    type="number"
                    step={1}
                    min={1}
                    onBlur={onBlur}
                    value={price.monthlyPrice.rateCount}
                    onChange={event => {
                        onUpdate(
                            {
                                ...price,
                                monthlyPrice: {
                                    ...price.monthlyPrice!,
                                    rateCount: parseInt(event.target.value)
                                }
                            }
                        )
                    }}/>
            </label>
            <div>
                Gesamtpreis netto: <CurrencyDisplay
                value={price.monthlyPrice.netPrice * price.monthlyPrice.rateCount}/><br/>
                Gesamtpreis brutto: <CurrencyDisplay
                value={price.monthlyPrice.grossPrice * price.monthlyPrice.rateCount}/>
            </div>
        </div> : null}


    </div>

}
