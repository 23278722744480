import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { WithEntityList } from "@thekeytechnology/framework-react";
import { WithEntityListProps } from "@thekeytechnology/framework-react";
import React from "react";
import "./notificationl-table.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import { NotificationConfiguration } from "../../model/notification-configuration";
import { Table, TableColumn, TableHeader, TableRow } from "../../../core/components/table/Table";
import { AsyncEntityTableBody } from "../../../core/components/table/AsyncEntityTableBody";
import { ActionLink } from "../../../core/components/icon-link-tooltip/ActionLink";

type Props = WithEntityListProps<NotificationConfiguration> & WithTranslation;

const NotificationTableComponent = (props: Props) => {
    const {
        entities,
        entityApiState,
        t
    } = props;

    return (
        <Table className="email-table" itemCount={entities.length}>
            <TableHeader>
                <div className="table-column">{t("Name")}</div>
                <div className="table-column">{t("Subject")}</div>
                <div className="table-column">{t("Actions")}</div>
            </TableHeader>
            <div className="table-body">
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={notification =>
                        <TableRow key={notification.id}>
                            <TableColumn className="bold">
                                {t("notifications." + notification.entity.key + ".key")}
                            </TableColumn>
                            <TableColumn className="bold">
                                {notification.entity.subjects.de}
                            </TableColumn>
                            <TableColumn>
                                <ActionLink
                                    to={"/notifications/" + notification.id + "/edit"}
                                    tooltip={t("Edit notification")}
                                    icon={faPencilAlt}/>
                            </TableColumn>
                        </TableRow>
                    }/>
            </div>
        </Table>
    );
};

export const NotificationTable = WithEntityList<{}, NotificationConfiguration>(
    withTranslation("notifications")(NotificationTableComponent),
    NotificationConfiguration.TYPE);
