import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { ENTITY_TYPE_QNA} from "../model/qna";
import { QnaFilters } from "./QnaFilters";
import { QnasTable } from "./QnasTable";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../core/components/containers/Container";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const QnasOverview = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/qnas"}>{t("entity.qna.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.qna.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <QnaFilters/>
            </BackgroundContainer>
            <ContentContainer>
                <QnasTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_QNA}/>
            </PaginationContainer>
        </>
    );
};

export const QnasOverviewOverview = withTranslation("qna")(QnasOverview);
