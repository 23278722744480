import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { editCourseAction } from "../../../actions/course/edit-course";
import { PreparingAggregatedCourse } from "../../../model/preparing-aggregated-course";
import { EditCourseForm } from "./EditCourseForm";

interface OwnProps {
    course: EntityWrapper<PreparingAggregatedCourse>;
}

interface DispatchProps {
    editCourse: typeof editCourseAction
}

type Props = OwnProps & DispatchProps & WithTranslation;

const EditCourseComponent = (props: Props) => {
    const {editCourse, course} = props;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button className="ml-2 btn-secondary mr-2" onClick={handleShow}>
                <FontAwesomeIcon icon={faPencilAlt}/>
            </Button>

            <Modal size="lg" show={show} onHide={handleClose}>
                <EditCourseForm course={course} onSubmit={(entity: EntityWrapper<PreparingAggregatedCourse>) => {
                    editCourse(entity);
                    handleClose();
                }} onClose={handleClose}/>
            </Modal>
        </>
    );
};

export const EditCourse = connect<{}, DispatchProps, {}>(
    null,
    {
        editCourse: editCourseAction
    }
)(withTranslation("courses")(EditCourseComponent));
