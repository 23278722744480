import React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import { OrderHistoryTableV3_OrderFragment$key } from "../../../../../__generated__/OrderHistoryTableV3_OrderFragment.graphql";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import graphql from "babel-plugin-relay/macro";
import { DateTimeDisplay } from "../../../../core/components/datetime/DateTimeDisplay";

const ORDER_FRAGMENT = graphql`
    fragment OrderHistoryTableV3_OrderFragment on Order {
        history {
            additionalInformation
            dateTime
            message
            newStatus
        }
    }
`;

interface OwnProps {
    orderFragmentRef: OrderHistoryTableV3_OrderFragment$key
}

export const OrderHistoryTableV3 = ({orderFragmentRef}: OwnProps) => {
    const {t} = useTranslation(["admin", "billing"])

    const order = useFragment<OrderHistoryTableV3_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    return <DataTable className="p-mb-2" value={order.history as any}>
        <Column header="Datum" body={row => {
            return <DateTimeDisplay dateTime={row.dateTime}/>
        }}/>
        <Column header="Nachricht" body={row => {
            return t("billing:order-history-messages." + row.message)
        }}/>
        <Column header="Zusatzinfos" body={row => {
            let additionalInformation: string | undefined;

            if (row.additionalInformation) {
                switch (row.message) {
                    case "payment-method-switch":
                        additionalInformation = t("billing:payment-methods." + row.additionalInformation)
                        break;
                    default:
                        additionalInformation = row.additionalInformation

                }
            }
            return additionalInformation
        }}/>
        <Column header="Neuer Status" body={row => {
            return row.newStatus ? t("billing:order-statuus." + row.newStatus) : null
        }}/>
    </DataTable>
}
