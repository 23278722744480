import { ComponentProps } from "react";
import * as React from "react";
import { createPortal } from "react-dom";
import "./modal-portal.scss"

interface ModalProps {
    show: boolean;
    attachedId?: string
}

export const ModalPortal = ({
                                show,
                                children,
                                attachedId
                            }: ModalProps & ComponentProps<any>) => {
    const modal = (
        <div className={"modal " + (show ? "show" : "")}>
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                {children}
            </div>
        </div>
    );

    const element = document.querySelector(`#${attachedId ? attachedId : "modal"}`);

    return element ? createPortal(
        modal,
        element
    ) : null;
};
