import {
    API_CREATE_ENTITIES,
    API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    apiPost,
    matchesApiSuccess,
    Message,
    postMessageAction,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import { push } from "connected-react-router";
import { Action } from "redux";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EmailConfiguration } from "../model/email-configuration.model";
import {ENTITY_TYPE_TRIGGERED_EMAIL} from "../model/triggered-email";
import { API_SEND_TEST_EMAIL } from "../actions/send-test-email";
import { redirectAfterApiSuccesses } from "../../core/epics/redirects";

const redirectOnEmailSave$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_UPDATE_ENTITIES, EmailConfiguration.TYPE),
    map(() => push("/emails"))
);

const redirectOnTriggeredEmailSave$ = redirectAfterApiSuccesses(
    [
        {apiType: API_UPDATE_ENTITIES, callType: ENTITY_TYPE_TRIGGERED_EMAIL},
        {apiType: API_CREATE_ENTITIES, callType: ENTITY_TYPE_TRIGGERED_EMAIL}
    ], "/emails")

const reloadOnDelete = reloadOnEntityApiOperation(
    API_DELETE_ENTITIES,
    ENTITY_TYPE_TRIGGERED_EMAIL
)

const sendTestEmail = apiPost({apiType: API_SEND_TEST_EMAIL}, "/triggers/test-email")

const sendMessageOnTestEmailSuccess = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_SEND_TEST_EMAIL),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "test-email-sent"))
);

export const emailModuleEpic$ = combineEpics(
    sendTestEmail,
    redirectOnTriggeredEmailSave$,
    redirectOnEmailSave$,
    sendMessageOnTestEmailSuccess,
    reloadOnDelete
);
