import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { CourseModule } from "@thekeytechnology/thekey-academy-frontend-library";

export const EDIT_MODULE_ACTION = "edit-module";

export interface EditModuleAction extends Action {
    module: EntityWrapper<CourseModule>
}

export const editModuleAction = (module: EntityWrapper<CourseModule>) => ({
    type: EDIT_MODULE_ACTION,
    module
}) as EditModuleAction;
