import { ApiCallAction} from "@thekeytechnology/framework-react";

export const API_FETCH_VIDEO_DATA = "fetch-video-data";

export interface FetchVideoDataPayload {
    videoId: string;
}

export const fetchVideoDataAction = (videoId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_VIDEO_DATA,
            callType: API_FETCH_VIDEO_DATA
        },
        payload: {
            videoId,
        }
    } as ApiCallAction<FetchVideoDataPayload>;
};
