import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EntityWrapper, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { API_FETCH_USER_META_ADMIN, fetchUserMetaAction } from "../../../actions/fetch-user-meta";
import { API_UPDATE_USER_META, updateUserMetaAction } from "../../../actions/update-user-meta";
import { selectUserMetaAdmin } from "../../../selectors";
import { User } from "../../../model/User";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { LoadingRow } from "../../../../core/components/table/LoadingRow";

export interface OwnProps {
    user: EntityWrapper<User>
}

export const EditUserMeta = ({user}: OwnProps) => {
    const {t} = useTranslation("admin")
    const fetchCallState = useSelector(selectCombinedApiState(API_FETCH_USER_META_ADMIN))
    const updateCallState = useSelector(selectCombinedApiState(API_UPDATE_USER_META))

    const userMeta = useSelector(selectUserMetaAdmin);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserMetaAction(user.id!))
    }, [dispatch, user])

    return fetchCallState.inProgress || !userMeta ? <LoadingRow/> : <Formik
        initialValues={{
            firstName: userMeta.entity.firstName,
            lastName: userMeta.entity.lastName,
            position: userMeta.entity.position
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={(values, {setSubmitting}) => {
            dispatch(updateUserMetaAction(
                user.id!,
                values.firstName === userMeta?.entity.firstName ? undefined : values.firstName,
                values.lastName === userMeta?.entity.lastName ? undefined : values.lastName,
                values.position === userMeta?.entity.position ? undefined : values.position,
            ));
            setSubmitting(false);
        }}
    >
        {formikState => (
            <Form>
                <div className="form-group row">
                    <ValidatedField formikState={formikState}
                                    placeholder={t("entity.user-meta.labels.first-name")}
                                    type="text"
                                    name="firstName"
                                    className="form-control default-input"
                                    label={t("entity.user-meta.labels.first-name")}
                    />
                </div>
                <div className="form-group row">
                    <ValidatedField formikState={formikState}
                                    placeholder={t("entity.user-meta.labels.last-name")}
                                    type="text"
                                    name="lastName"
                                    className="form-control default-input"
                                    label={t("entity.user-meta.labels.last-name")}
                    />
                </div>
                <div className="form-group row">
                    <ValidatedField formikState={formikState}
                                    placeholder={t("entity.user-meta.labels.position")}
                                    type="text"
                                    name="position"
                                    className="form-control default-input"
                                    label={t("entity.user-meta.labels.position")}
                    />
                </div>
                {userMeta?.entity.freeformUserMeta ? <>
                    {Object.entries(userMeta.entity.freeformUserMeta).map(userMeta => {
                        return <div className="form-group row" key={userMeta[0]}>
                            <label
                                className="col-sm-2 col-form-label">{userMeta[0]}</label>
                            <div className="col-sm-10 align-items-center d-flex">
                                {[userMeta[1]]}
                            </div>
                        </div>
                    })}
                </> : null}
                <button disabled={updateCallState.inProgress} className="btn btn-success">
                    {updateCallState.succeeded ? "✓" : "Speichern"}
                </button>
            </Form>
        )}
    </Formik>
}
