import {
    CoursePriceTableItem,
    DiscountPriceTableItem,
    OrderContainer,
    paymentMethodIsMonthly,
    VoucherPriceTableItem
} from "@thekeytechnology/thekey-academy-frontend-library";
import React from "react";
import { OrderPriceDisplay } from "./OrderPriceDisplay";
import "./order-item-table.scss"
import { Table, TableBody, TableColumn, TableHeader, TableRow } from "../../../../../core/components/table/Table";

interface OwnProps {
    order: OrderContainer
}

export const OrderItemTable = ({order}: OwnProps) => {

    return <Table className="order-item-table" itemCount={order.order.entity.priceTable.offerItems.length}>
        <TableHeader>
            <TableColumn>#</TableColumn>
            <TableColumn>Art</TableColumn>
            <TableColumn>Preis</TableColumn>
        </TableHeader>
        <TableBody>
            {order.order.entity.priceTable.offerItems.map((orderItem, index) => {

                let ItemDisplay = null;
                let isOwned = false

                switch (orderItem.itemType) {
                    case "course-item":
                        ItemDisplay = <>
                            {order?.referredCourses.find(c => c.id === (orderItem as CoursePriceTableItem).courseRef)?.title}
                        </>
                        isOwned = (orderItem as CoursePriceTableItem).isOwned;
                        break;
                    case "selected-upsell-item":
                        ItemDisplay = <>
                            Upsell: {order?.referredCourses.find(c => c.id === (orderItem as CoursePriceTableItem).courseRef)?.title}
                        </>
                        break;
                    case "applied-voucher-item":
                        ItemDisplay = <>
                            Rabattcode {(orderItem as VoucherPriceTableItem).code}
                        </>
                        break;
                    case "discount-item":
                        ItemDisplay = <>
                            {(orderItem as DiscountPriceTableItem).title}
                        </>
                }

                return <TableRow key={index}>
                    <TableColumn>{index}</TableColumn>
                    <TableColumn className={isOwned ? "line-throug" : ""}>
                        {ItemDisplay}
                    </TableColumn>
                    <TableColumn className={isOwned ? "line-throug" : ""}>
                        <OrderPriceDisplay priceExplanation={orderItem.priceExplanation}
                                           monthlyPriceExplanation={orderItem.monthlyPriceExplanation}
                                           isPaid={order.order.entity.paymentProviderData !== undefined && order?.order.entity.status === "payment-in-process"}
                                           isMonthly={order.order.entity.paymentProviderData ? paymentMethodIsMonthly(order.order.entity.paymentProviderData.paymentMethod) : false}/>
                    </TableColumn>
                </TableRow>
            })}
        </TableBody>
    </Table>
}
