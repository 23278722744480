import React from "react";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {confirmDialog} from "primereact/confirmdialog";
import {NavLink} from "react-router-dom";
import {CustomerMessageVersion} from "./CustomerMessageDefinitionOverview";

interface OwnProps {
    deleteSelectedItems: () => void
    numSelectedItems: number
    version: CustomerMessageVersion
}

export const CustomerMessageDefinitionButtons = ({deleteSelectedItems, numSelectedItems, version}: OwnProps) => {

    const {t: tCustomerMessages} = useTranslation("customerMessages")
    const {t: tCore} = useTranslation("core")

    const countTerm = numSelectedItems === 1 ? tCore("entity-deletion-button.dialog.count-term-singular", {label: tCustomerMessages("entity.customer-message.singular")}) : tCore("entity-deletion-button.dialog.count-term-plural", {count: numSelectedItems, labelPlural: tCustomerMessages("entity.customer-message.plural")})

    const confirm = () => {
        confirmDialog({
            message: tCore("entity-deletion-button.dialog.explanation"),
            header: tCore("entity-deletion-button.dialog.question", {countTerm}),
            icon: "pi pi-exclamation-triangle",
            acceptLabel: tCore("entity-deletion-button.dialog.affirmative-text"),
            rejectLabel: tCore("entity-deletion-button.dialog.negative-text"),
            acceptClassName: "p-button-danger",
            accept: deleteSelectedItems
        });
    }

    return <div className="flex justify-content-end pb-4">
        <NavLink to={ version === CustomerMessageVersion.V1 ? "/customer-message/new/edit" : "/customer-message-v2/new"}>
            <Button label={tCore("entity-create-delete-button.create")}/>
        </NavLink>
        <Button label={tCore("entity-deletion-button.button-text")} icon="pi pi-times" className="p-button-danger ml-3"
                onClick={confirm} disabled={numSelectedItems === 0}/>
    </div>
}