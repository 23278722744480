import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {OffersTable} from "./OffersTable";
import {ENTITY_TYPE_OFFER} from "../model/Offer";
import {
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../../../core/components/containers/Container";
import { SimpleHeader } from "../../../../core/components/header/SimpleHeader";
import { Pagination } from "../../../../core/components/pagination/Pagination";

const OffersOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/offers"}>{t("entity.offer.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.offer.plural")}/>
            </HeadingContainer>
            <ContentContainer>
                <OffersTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_OFFER}/>
            </PaginationContainer>
        </>
    );
};

export const OffersOverview = withTranslation("billing")(OffersOverviewComponent);
