import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_ACCOUNT_ORDERS_ADMIN = "fetch-account-orders-admin";

export const fetchAccountOrdersAdminAction = (accountId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_ACCOUNT_ORDERS_ADMIN,
            callType: API_FETCH_ACCOUNT_ORDERS_ADMIN
        },
        payload: accountId,
    } as ApiCallAction<string>;
};
