/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateRandomDiscountCodesInputInput = {
    discountActionId: string;
    number: number;
    clientMutationId?: string | null;
};
export type CreateRandomCodesButton_CreateMutationVariables = {
    input: CreateRandomDiscountCodesInputInput;
    connections: Array<string>;
};
export type CreateRandomCodesButton_CreateMutationResponse = {
    readonly Admin: {
        readonly Billing: {
            readonly createRandomDiscountCodes: {
                readonly discountAction: {
                    readonly discountCodes: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly " $fragmentRefs": FragmentRefs<"DiscountCodeTable_DiscountCodeFragment">;
                            };
                        } | null> | null;
                    };
                };
            } | null;
        };
    };
};
export type CreateRandomCodesButton_CreateMutation = {
    readonly response: CreateRandomCodesButton_CreateMutationResponse;
    readonly variables: CreateRandomCodesButton_CreateMutationVariables;
};



/*
mutation CreateRandomCodesButton_CreateMutation(
  $input: CreateRandomDiscountCodesInputInput!
) {
  Admin {
    Billing {
      createRandomDiscountCodes(input: $input) {
        discountAction {
          discountCodes {
            edges {
              node {
                ...DiscountCodeTable_DiscountCodeFragment
                id
              }
            }
          }
          id
        }
      }
    }
  }
}

fragment CodeActionColumn_DiscountCodeFragment on DiscountCodeV2 {
  id
}

fragment CodeCodeColumn_DiscountCodeFragment on DiscountCodeV2 {
  code
}

fragment CodeCreatedAtColumn_DiscountCodeFragment on DiscountCodeV2 {
  createdAt
}

fragment CodeIdColumn_DiscountCodeFragment on DiscountCodeV2 {
  rawId
}

fragment CodeNumUsagesColumn_DiscountCodeFragment on DiscountCodeV2 {
  numUsages
}

fragment DiscountCodeTable_DiscountCodeFragment on DiscountCodeV2 {
  ...CodeIdColumn_DiscountCodeFragment
  ...CodeCodeColumn_DiscountCodeFragment
  ...CodeNumUsagesColumn_DiscountCodeFragment
  ...CodeCreatedAtColumn_DiscountCodeFragment
  ...CodeActionColumn_DiscountCodeFragment
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateRandomCodesButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateRandomDiscountCodesInputPayload",
                "kind": "LinkedField",
                "name": "createRandomDiscountCodes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountAction",
                    "kind": "LinkedField",
                    "name": "discountAction",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodesV2Connection",
                        "kind": "LinkedField",
                        "name": "discountCodes",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DiscountCodesV2Edge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DiscountCodeV2",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "InlineDataFragmentSpread",
                                    "name": "DiscountCodeTable_DiscountCodeFragment",
                                    "selections": [
                                      {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "CodeIdColumn_DiscountCodeFragment"
                                      },
                                      {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "CodeCodeColumn_DiscountCodeFragment"
                                      },
                                      {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "CodeNumUsagesColumn_DiscountCodeFragment"
                                      },
                                      {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "CodeCreatedAtColumn_DiscountCodeFragment"
                                      },
                                      {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "CodeActionColumn_DiscountCodeFragment"
                                      }
                                    ]
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateRandomCodesButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateRandomDiscountCodesInputPayload",
                "kind": "LinkedField",
                "name": "createRandomDiscountCodes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountAction",
                    "kind": "LinkedField",
                    "name": "discountAction",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodesV2Connection",
                        "kind": "LinkedField",
                        "name": "discountCodes",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DiscountCodesV2Edge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DiscountCodeV2",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "rawId",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "code",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "numUsages",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "prependEdge",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "edges",
                            "handleArgs": [
                              {
                                "kind": "Variable",
                                "name": "connections",
                                "variableName": "connections"
                              }
                            ]
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "35d799d854a8a3c571d0ef474094bb36",
    "id": null,
    "metadata": {},
    "name": "CreateRandomCodesButton_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation CreateRandomCodesButton_CreateMutation(\n  $input: CreateRandomDiscountCodesInputInput!\n) {\n  Admin {\n    Billing {\n      createRandomDiscountCodes(input: $input) {\n        discountAction {\n          discountCodes {\n            edges {\n              node {\n                ...DiscountCodeTable_DiscountCodeFragment\n                id\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment CodeActionColumn_DiscountCodeFragment on DiscountCodeV2 {\n  id\n}\n\nfragment CodeCodeColumn_DiscountCodeFragment on DiscountCodeV2 {\n  code\n}\n\nfragment CodeCreatedAtColumn_DiscountCodeFragment on DiscountCodeV2 {\n  createdAt\n}\n\nfragment CodeIdColumn_DiscountCodeFragment on DiscountCodeV2 {\n  rawId\n}\n\nfragment CodeNumUsagesColumn_DiscountCodeFragment on DiscountCodeV2 {\n  numUsages\n}\n\nfragment DiscountCodeTable_DiscountCodeFragment on DiscountCodeV2 {\n  ...CodeIdColumn_DiscountCodeFragment\n  ...CodeCodeColumn_DiscountCodeFragment\n  ...CodeNumUsagesColumn_DiscountCodeFragment\n  ...CodeCreatedAtColumn_DiscountCodeFragment\n  ...CodeActionColumn_DiscountCodeFragment\n}\n"
  }
};
})();
(node as any).hash = '5b708888fc2c014879f0e684f7daac05';
export default node;
