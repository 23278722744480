import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import { saveCourseAction } from "../../../actions/save-course";
import { selectHasUnsavedChanges } from "../../../selectors";

interface StateProps {
    hasUnsavedChanges: boolean;
}

interface DispatchProps {
    saveCourse: typeof saveCourseAction
}

type Props = StateProps & DispatchProps & WithTranslation;

const CourseSaveButtonComponent = (props: Props) => {
    const {hasUnsavedChanges, saveCourse, t} = props;
    return <>
        <Prompt
            when={hasUnsavedChanges}
            message={t("course-save-button.message-before-unload")}
        />
        <button className="btn btn-primary" disabled={!hasUnsavedChanges}
                onClick={() => saveCourse()}>{t("course-save-button.save")}</button>
    </>;
};

export const CourseSaveButton = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        hasUnsavedChanges: selectHasUnsavedChanges(state)
    }),
    {
        saveCourse: saveCourseAction
    }
)(withTranslation("courses")(CourseSaveButtonComponent));
