import { matchesApiSuccess } from "@thekeytechnology/framework-react";
import { API_ACCEPT_INVITATION } from "@thekeytechnology/framework-react";
import { push } from "connected-react-router";
import { Action } from "redux";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

const redirectAfterInvitationAcceptance$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_ACCEPT_INVITATION),
        map(() => push("/"))
    );

export const invitationEpics$ = combineEpics(
    redirectAfterInvitationAcceptance$,
);
