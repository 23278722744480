import { SelectionModel } from "@thekeytechnology/framework-react";
import React from "react";

interface SelectInModelCheckboxProps<ItemType> {
    items: ItemType[];
    selectionModel: SelectionModel<ItemType>;
}

export function SelectAllInModelCheckbox<ItemType>(props: SelectInModelCheckboxProps<ItemType>) {
    const {items, selectionModel} = props;
    return <input name="select-all" type="checkbox"
                  checked={selectionModel.areAllSelected(items)}
                  onChange={() => {
                      selectionModel.handleSelectAll(items);
                  }}/>;
}
