import { Trigger } from "../../core/model/trigger/trigger";
import { EntityWrapper } from "@thekeytechnology/framework-react";

export const ENTITY_TYPE_TRIGGERED_UNLOCK = "triggered-unlock";

export const NEW_TRIGGERED_UNLOCK = () => new EntityWrapper(undefined, {
    internalTitle: "Neu",
    moduleRef: "",
} as TriggeredUnlock)

export interface TriggeredUnlock {
    internalTitle: string,
    moduleRef: string,
    trigger?: Trigger
}

