/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment = {
    readonly id: string;
    readonly internalTitle: string;
    readonly imageOpt: {
        readonly fileType: string;
        readonly id: string;
        readonly name: string;
        readonly rawId: string;
        readonly rawUrl: string | null;
        readonly thumbnailUrl: string | null;
        readonly uploadDateTime: string;
        readonly url: string | null;
    } | null;
    readonly " $refType": "EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment";
};
export type EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment$data = EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment;
export type EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment$key = {
    readonly " $data"?: EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditCustomerMessageDefinitionPageForm_CustomerMessageDefinitionPageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "imageOpt",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fileType",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rawId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rawUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uploadDateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CustomerMessageDefinitionPage",
  "abstractKey": null
};
})();
(node as any).hash = '5c3594145f831ab161b7145be7fd61e3';
export default node;
