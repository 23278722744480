import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_USERS_IN_ACCOUNT_ADMIN = "fetch-users-in-account-admin";

export const fetchUsersInAccountAction = (accountId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_USERS_IN_ACCOUNT_ADMIN,
            callType: API_FETCH_USERS_IN_ACCOUNT_ADMIN
        },
        payload: accountId,
    } as ApiCallAction<string>;
};
