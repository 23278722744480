import {EntityWrapper, TkFile,} from "@thekeytechnology/framework-react";
import {Formik} from "formik";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {extractId} from "../../../../reducers/changes/common/helpers";
import {EditElementModalBase} from "../common/EditElementModalBase";
import {
    CONNECTION_VALIDATION_SCHEME_WITH_MANDATORY_SOURCE,
    TITLE_VALIDATION_SCHEME
} from "../common/GeneralElementSettingsEditor";
import {AdditionalWordsField} from "./AdditionalWordsField";
import {connect} from "react-redux";
import {selectImage} from "../../../../selectors";
import {InlineAttachmentField} from "../../../../../core/components/attachment/InlineAttachmentField";
import {
    CLOZE_TEXT_PART_TYPE_CLOZE,
    CLOZE_TEXT_PART_TYPE_TEXT,
    ClozePart,
    ClozeTextElement,
    ClozeTextPart,
    ELEMENT_TYPE_CLOZE,
    TextPart
} from "@thekeytechnology/thekey-academy-frontend-library";
import { ValidatedField } from "../../../../../core/components/form/ValidatedField";

interface OwnProps {
    element: EntityWrapper<ClozeTextElement>;
    handleSubmit: (element: EntityWrapper<ClozeTextElement>) => void;
    handleClose: () => void;
}

interface StateProps {
    image: EntityWrapper<TkFile> | undefined
}

type Props = OwnProps & StateProps;

const EditClozeTextElementFormComponent = ({element, image, handleSubmit, handleClose}: Props) => {
    const {t} = useTranslation(["courses", "core"])

    const parseText = useCallback((text: string): ClozeTextPart[] => {
        const reg = /([^[\]]*)(\[.*?])?/gm;

        const parts: ClozeTextPart[] = [];
        let result = reg.exec(text);
        while (result !== null) {
            if (result[1]) {
                parts.push({partType: CLOZE_TEXT_PART_TYPE_TEXT, text: result[1]} as TextPart)
            }
            if (result[2]) {
                parts.push({
                    partType: CLOZE_TEXT_PART_TYPE_CLOZE, cloze: result[2].replace("[", "").replace("]", "")
                } as ClozePart)
            }

            if (!result[1] && !result[2]) {
                break;
            }
            result = reg.exec(text);
        }
        return parts;
    }, [])

    const toText = useCallback((parts: ClozeTextPart[]): string => {
        return parts.map(cte => {
            switch (cte.partType) {
                case CLOZE_TEXT_PART_TYPE_CLOZE:
                    return "[" + (cte as ClozePart).cloze + "]"
                case CLOZE_TEXT_PART_TYPE_TEXT:
                    return (cte as TextPart).text
            }
            return "";
        }).join("")
    }, [])

    return (
        <Formik
            initialValues={{
                internalTitle: element.entity.generalSettings.internalTitle,
                points: element.entity.generalSettings.points,
                lengthInSeconds: element.entity.generalSettings.lengthInSeconds,
                connections: element.entity.generalSettings.connections,
                text: toText(element.entity.parts),
                additionalWords: element.entity.additionalWords,
                image
            }}
            validationSchema={Yup.object().shape({
                internalTitle: TITLE_VALIDATION_SCHEME(t),
                connections: CONNECTION_VALIDATION_SCHEME_WITH_MANDATORY_SOURCE(t),
                text: Yup.string().test("", "", function (value) {
                    const parsedText = value ? parseText(value) : [];

                    if (parsedText.length === 0 || parsedText.find(p => p.partType === CLOZE_TEXT_PART_TYPE_CLOZE) === undefined) {
                        return this.createError({
                            path: "text",
                            message: t("course-editor.edit-element-form.cloze-text.fields.text-error")
                        });
                    }
                    return true;
                })
            })}
            onSubmit={(values, {setSubmitting}) => {
                handleSubmit(new EntityWrapper<ClozeTextElement>(
                    element.id,
                    {
                        generalSettings: {
                            internalTitle: values.internalTitle,
                            points: values.points,
                            lengthInSeconds: values.lengthInSeconds,
                            connections: values.connections
                        },
                        elementPath: element.entity.elementPath,
                        imageRef: values.image?.id,
                        parts: parseText(values.text),
                        additionalWords: values.additionalWords,
                        elementType: ELEMENT_TYPE_CLOZE,
                        versions: element.entity.versions
                    },
                    element.temporaryId,
                ));
                setSubmitting(false);
            }}
        >
            {formikState => (
                <EditElementModalBase title={t("course-editor.edit-element-form.cloze-text.heading")}
                                      currentElementId={extractId(element)}
                                      formikState={formikState}
                                      handleClose={handleClose}>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.common.fields.image")}
                            name="image"
                            formikState={formikState}
                            component={InlineAttachmentField}
                            isSingleSelect={true}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.cloze-text.fields.text")}
                            name="text"
                            formikState={formikState}
                            component="textarea"
                            rows={4}
                            helpText={t("course-editor.edit-element-form.cloze-text.fields.text-helper")}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.cloze-text.fields.additional-words")}
                            name="additionalWords"
                            formikState={formikState}
                            component={AdditionalWordsField}
                        />
                    </div>
                </EditElementModalBase>
            )}
        </Formik>
    );
};

export const EditClozeTextElementForm = connect<StateProps, {}, OwnProps>(
    (state: any, {element}: OwnProps) => ({
        image: selectImage(element.entity.imageRef)(state)
    })
)(EditClozeTextElementFormComponent);
