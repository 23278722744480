import {EntityWrapper, generateString} from "@thekeytechnology/framework-react";
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {EditClozeTextElementForm} from "./cloze-text/EditClozeTextElementForm";
import {EditResolutionElementForm} from "./resolution/EditResolutionElementForm";
import {EditMultipleChoiceElementForm} from "./multiple-choice/EditMultipleChoiceElementForm";
import {EditTextElementForm} from "./text/EditTextElementForm";
import {EditVideoElementForm} from "./video/VideoElementForm";
import {EditOrderElementForm} from "./order/EditOrderElementForm";
import {EditImageElementForm} from "./image/EditImageElementForm";
import {EditQuestionnaireElementForm} from "./questionnaire/EditQuestionnaireElementForm";
import {
    ClozeTextElement,
    CourseElement,
    ELEMENT_TYPE_CLOZE,
    ELEMENT_TYPE_IMAGE,
    ELEMENT_TYPE_MULTIPLE_CHOICE,
    ELEMENT_TYPE_ORDER,
    ELEMENT_TYPE_QUESTIONNAIRE,
    ELEMENT_TYPE_RESOLUTION,
    ELEMENT_TYPE_TEXT,
    ELEMENT_TYPE_VIDEO,
    ImageElement,
    MultipleChoiceElement,
    OrderElement,
    QuestionnaireElement,
    ResolutionElement,
    TextElement,
    VideoData,
    VideoElement
} from "@thekeytechnology/thekey-academy-frontend-library";
import {
    ELEMENT_TYPE_PERSOLOG,
    PersologElement
} from "@thekeytechnology/thekey-academy-frontend-library";
import {EditPersologElementForm} from "./persolog/EditPersologElementForm";

interface OwnProps {
    element?: EntityWrapper<CourseElement>;
    elementType: string;
    handleSubmit: (element: EntityWrapper<CourseElement>) => void;
    handleClose: () => void;
    show: boolean;
}

type Props = OwnProps;

const EMPTY_PATH = {
    courseRef: "",
    moduleRef: "",
    lessonRef: "",
    moduleIndex: -1,
    lessonIndex: -1,
    elementIndex: -1
}
const INITIAL_VERSIONS = {releaseVersion: -1, draftVersion: 0}

export const ElementEditorModal = ({
                                       handleClose,
                                       handleSubmit,
                                       show,
                                       elementType,
                                       element
                                   }: Props) => {
    const [elementToUse, setElement] = useState<EntityWrapper<CourseElement>>();

    useEffect(() => {
        let elementEntity = element;

        if (!elementEntity) {
            const temporaryId = generateString(10);
            let newElement: CourseElement | undefined;
            switch (elementType) {
                case ELEMENT_TYPE_TEXT:
                    newElement = {
                        generalSettings: {internalTitle: "", points: 6, lengthInSeconds: 0, connections: []},
                        elementType: ELEMENT_TYPE_TEXT,
                        elementPath: EMPTY_PATH,
                        title: "",
                        text: "",
                        versions: INITIAL_VERSIONS
                    } as TextElement
                    break;
                case ELEMENT_TYPE_MULTIPLE_CHOICE:
                    newElement = {
                        generalSettings: {internalTitle: "", points: 10, lengthInSeconds: 0, connections: []},
                        elementType: ELEMENT_TYPE_MULTIPLE_CHOICE,
                        elementPath: EMPTY_PATH,
                        imageRef: undefined,
                        allowMultipleAnswers: false,
                        allCorrectAnswersNeedToBeGiven: true,
                        question: {alternatives: []},
                        answerOptions: [],
                        versions: INITIAL_VERSIONS
                    } as MultipleChoiceElement
                    break;
                case ELEMENT_TYPE_CLOZE:
                    newElement = {
                        generalSettings: {internalTitle: "", points: 10, lengthInSeconds: 0, connections: []},
                        elementType: ELEMENT_TYPE_CLOZE,
                        elementPath: EMPTY_PATH,
                        imageRef: undefined,
                        parts: [],
                        additionalWords: [],
                        versions: INITIAL_VERSIONS
                    } as ClozeTextElement
                    break;
                case ELEMENT_TYPE_VIDEO:
                    newElement = {
                        generalSettings: {internalTitle: "", points: 5, lengthInSeconds: 0, connections: []},
                        elementType: ELEMENT_TYPE_VIDEO,
                        elementPath: EMPTY_PATH,
                        title: "",
                        isAudioOnly: false,
                        videoData: undefined as any as VideoData,
                        subtitles: undefined,
                        highDefVideoFileRef: "",
                        thumbnailFileRef: undefined,
                        videoFileRef: "",
                        versions: INITIAL_VERSIONS
                    } as VideoElement
                    break;
                case ELEMENT_TYPE_RESOLUTION:
                    newElement = {
                        generalSettings: {internalTitle: "", points: 5, lengthInSeconds: 0, connections: []},
                        elementType: ELEMENT_TYPE_RESOLUTION,
                        elementPath: EMPTY_PATH,
                        title: "",
                        task: "",
                        versions: INITIAL_VERSIONS
                    } as ResolutionElement
                    break;
                case ELEMENT_TYPE_ORDER:
                    newElement = {
                        generalSettings: {internalTitle: "", points: 10, lengthInSeconds: 0, connections: []},
                        elementType: ELEMENT_TYPE_ORDER,
                        elementPath: EMPTY_PATH,
                        title: "",
                        imageRef: undefined,
                        orderables: [],
                        versions: INITIAL_VERSIONS
                    } as OrderElement
                    break;
                case ELEMENT_TYPE_IMAGE:
                    newElement = {
                        generalSettings: {internalTitle: "", points: 5, lengthInSeconds: 0, connections: []},
                        elementType: ELEMENT_TYPE_IMAGE,
                        elementPath: EMPTY_PATH,
                        title: "",
                        imageRef: "",
                        versions: INITIAL_VERSIONS
                    } as ImageElement
                    break;
                case ELEMENT_TYPE_QUESTIONNAIRE:
                    newElement = {
                        generalSettings: {internalTitle: "", points: 10, lengthInSeconds: 0, connections: []},
                        elementType: ELEMENT_TYPE_QUESTIONNAIRE,
                        elementPath: EMPTY_PATH,
                        questions: [],
                        classes: [],
                        versions: INITIAL_VERSIONS

                    } as QuestionnaireElement
                    break;
                case ELEMENT_TYPE_PERSOLOG:
                    newElement = {
                        generalSettings: {internalTitle: "", points: 10, lengthInSeconds: 0, connections: []},
                        elementType: ELEMENT_TYPE_PERSOLOG,
                        title: "",
                        classes: [],
                        questions: [],
                        elementPath: EMPTY_PATH,
                        versions: INITIAL_VERSIONS
                    } as PersologElement
                    break;
            }
            elementEntity = new EntityWrapper<CourseElement>(undefined, newElement!, temporaryId);
        }
        setElement(elementEntity);
    }, [element, elementType])

    if (!elementToUse) {
        return null;
    }

    let EditorComponent;
    switch (elementToUse.entity.elementType) {
        case ELEMENT_TYPE_TEXT:
            EditorComponent =
                <EditTextElementForm handleClose={handleClose} handleSubmit={handleSubmit}
                                     element={elementToUse as EntityWrapper<TextElement>}/>;
            break;
        case ELEMENT_TYPE_MULTIPLE_CHOICE:
            EditorComponent =
                <EditMultipleChoiceElementForm handleClose={handleClose} handleSubmit={handleSubmit}
                                               element={elementToUse as EntityWrapper<MultipleChoiceElement>}/>;
            break;
        case ELEMENT_TYPE_CLOZE:
            EditorComponent =
                <EditClozeTextElementForm handleClose={handleClose} handleSubmit={handleSubmit}
                                          element={elementToUse as EntityWrapper<ClozeTextElement>}/>;
            break;
        case ELEMENT_TYPE_VIDEO:
            EditorComponent =
                <EditVideoElementForm element={elementToUse as EntityWrapper<VideoElement>} handleSubmit={handleSubmit}
                                      handleClose={handleClose}/>;
            break;
        case ELEMENT_TYPE_RESOLUTION:
            EditorComponent =
                <EditResolutionElementForm element={elementToUse as EntityWrapper<ResolutionElement>}
                                           handleSubmit={handleSubmit}
                                           handleClose={handleClose}/>;
            break;
        case ELEMENT_TYPE_ORDER:
            EditorComponent =
                <EditOrderElementForm element={elementToUse as EntityWrapper<OrderElement>}
                                      handleSubmit={handleSubmit}
                                      handleClose={handleClose}/>
            break;
        case ELEMENT_TYPE_IMAGE:
            EditorComponent =
                <EditImageElementForm element={elementToUse as EntityWrapper<ImageElement>}
                                      handleSubmit={handleSubmit}
                                      handleClose={handleClose}/>
            break;
        case ELEMENT_TYPE_QUESTIONNAIRE:
            EditorComponent =
                <EditQuestionnaireElementForm element={elementToUse as EntityWrapper<QuestionnaireElement>}
                                              handleSubmit={handleSubmit}
                                              handleClose={handleClose}/>
            break;
        case ELEMENT_TYPE_PERSOLOG:
            EditorComponent =
                <EditPersologElementForm element={elementToUse as EntityWrapper<PersologElement>}
                                         handleSubmit={handleSubmit}
                                         handleClose={handleClose}/>
            break;
    }

    return <Modal size="xl" show={show} onHide={handleClose}>
        {EditorComponent}
    </Modal>;
};
