import React from "react";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

interface OwnProps {
    selectedItems: {id: string, internalTitle: string}[]
}

export const CustomerMessageDefinitionSendMultiple = ({selectedItems}: OwnProps) => {

    const {t: tCustomerMessages} = useTranslation("customerMessages")

    const history = useHistory()

    return <div className="flex pb-4">
        <div className="p-input-icon-left flex-grow-1 align-self-center">
            {selectedItems.length > 0 && tCustomerMessages("entity.customer-message.send-customer-message.receive-order") + ": " + selectedItems.map(si => si.internalTitle).reverse().join(", ")}
        </div>
        <Button
            className="ml-3"
            type="button"
            label={tCustomerMessages("entity.customer-message.buttons.send-to-all")}
            icon="pi pi-send"
            iconPos="right"
            disabled={!selectedItems.length}
            onClick={() => history.push(`/customer-message/${encodeURIComponent(selectedItems.map(si => si.id + ":" + si.internalTitle).join(","))}/send`)}
        />
    </div>
}