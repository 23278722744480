import { Card } from "react-bootstrap";
import React from "react";
import { useDispatch } from "react-redux";
import { DialogResult, showDialogAction } from "@thekeytechnology/framework-react";
import graphql from 'babel-plugin-relay/macro'
import {useFragment, useMutation} from "react-relay";
import {UserActions_UserFragment$key} from "../../../../__generated__/UserActions_UserFragment.graphql";
import {UserActions_AnonymizeUserMutation} from "../../../../__generated__/UserActions_AnonymizeUserMutation.graphql";

const USER_FRAGMENT = graphql`
    fragment UserActions_UserFragment on User {
        id
        name
    }
`

const ANONYMIZE_USER_MUTATION = graphql`
    mutation UserActions_AnonymizeUserMutation($input: AnonymizeUserInput!) {
        Admin {
            User {
                anonymizeUser(input: $input) {
                    user {
                        ...UserActions_UserFragment
                    }
                }
            }
        }
    }
`

interface OwnProps {
    userFragmentRef: UserActions_UserFragment$key
}

export const UserActions = ({userFragmentRef}: OwnProps) => {
    const dispatch = useDispatch();

    const user = useFragment<UserActions_UserFragment$key>(USER_FRAGMENT, userFragmentRef)
    const [anonymizeUser, isInFlight] = useMutation<UserActions_AnonymizeUserMutation>(ANONYMIZE_USER_MUTATION)

    const userIsDeleted = user.name === "DELETED";

    return <Card className="mb-4">
        <Card.Header>
            <h3>Aktionen</h3>
            <div>
                <button
                    disabled={userIsDeleted || isInFlight}
                    onClick={() => {
                        dispatch(showDialogAction({
                            question: "Möchten Sie den Nutzer wirklich löschen?",
                            affirmativeButtonClass: "btn-danger",
                            affirmativeText: "Löschen",
                            explanation: "Das kann nicht rückgängig gemacht werden",
                            negativeText: "Abbrechen",
                            title: "Benutzer löschen"
                        }, result => {
                            if (result === DialogResult.ACCEPT) {
                                anonymizeUser({
                                    variables: {
                                        input: {
                                            userId: user.id
                                        }
                                    }
                                })
                            }
                        }))

                    }} className="btn btn-danger">Benutzer anonymisieren ("löschen")
                </button>
            </div>
        </Card.Header>

    </Card>
}
