import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { MultipleTexts } from "./MultipleTexts";
import { PhrasingAlternatives } from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    currentAlternatives?: PhrasingAlternatives;
    updatePhrasing: (newPhrasing?: PhrasingAlternatives) => void;
    icon?: IconDefinition;
}

export const PhrasingAlternativesEditor = ({currentAlternatives, updatePhrasing, icon}: OwnProps) => {
    const {t} = useTranslation("courses")

    return (
        <MultipleTexts
            icon={icon}
            currentTexts={currentAlternatives?.alternatives ? currentAlternatives.alternatives : []}
            updatedTexts={(updatedTexts?: string[]) => {
                if (updatedTexts) {
                    updatePhrasing({alternatives: updatedTexts});
                } else {
                    updatePhrasing(undefined);
                }
            }}
            inputFieldPlaceholder={t("course-editor.phrasing-alternatives-field.phrasing-input-placeholder")}
            removeTooltip={t("course-editor.phrasing-alternatives-field.remove-phrasing")}
            addTooltip={t("course-editor.phrasing-alternatives-field.add-phrasing")}
        />

    );
};
