import graphql from "babel-plugin-relay/macro"
import {useFragment} from "react-relay";
import {
    ActionTitleColumn_DiscountActionFragment$key
} from "../../../../../../../__generated__/ActionTitleColumn_DiscountActionFragment.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
    fragment ActionTitleColumn_DiscountActionFragment on DiscountAction {
        title
    }
`

interface OwnProps {
    discountActionFragmentRef: ActionTitleColumn_DiscountActionFragment$key
}

export const ActionTitleColumn = ({discountActionFragmentRef}: OwnProps) => {
    const discountAction = useFragment<ActionTitleColumn_DiscountActionFragment$key>(DISCOUNT_ACTION_FRAGMENT, discountActionFragmentRef)

    return <div>{discountAction.title}</div>
}
