/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CustomerType = "business" | "private" | "%future added value";
export type PaymentMethod = "card" | "giropay" | "iapApple" | "iapGoogle" | "invoicePartner" | "invoiceTk" | "monthlyPartner" | "monthlyTk" | "paypal" | "sepa" | "sofort" | "%future added value";
export type PaymentProviderDataType = "appleIap" | "default" | "googleIap" | "sepa" | "stripe" | "stripeSepa" | "%future added value";
export type EditOrderScreen_QueryVariables = {
    orderId: string;
};
export type EditOrderScreen_QueryResponse = {
    readonly node: {
        readonly id: string;
        readonly billingDetails?: {
            readonly customerType: CustomerType;
            readonly invoiceEmail: string;
            readonly companyBillingDetails: {
                readonly company: string;
                readonly companyDetails: string | null;
                readonly companyType: string;
            } | null;
            readonly salutation: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly street: string;
            readonly houseNumber: string;
            readonly postalCode: string;
            readonly country: string;
        } | null;
        readonly allowedPaymentMethods?: ReadonlyArray<PaymentMethod>;
        readonly paymentData?: {
            readonly dataType: PaymentProviderDataType;
        } | null;
        readonly invoiceData?: {
            readonly invoiceNumber: string | null;
            readonly invoiceId: string;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"PaymentProviderLinksV3_OrderFragment" | "ProviderDataDisplayV3_OrderFragment" | "OrderHistoryTableV3_OrderFragment">;
    } | null;
};
export type EditOrderScreen_Query = {
    readonly response: EditOrderScreen_QueryResponse;
    readonly variables: EditOrderScreen_QueryVariables;
};



/*
query EditOrderScreen_Query(
  $orderId: ID!
) {
  node(id: $orderId) {
    __typename
    id
    ... on Order {
      billingDetails {
        customerType
        invoiceEmail
        companyBillingDetails {
          company
          companyDetails
          companyType
        }
        salutation
        firstName
        lastName
        street
        houseNumber
        postalCode
        country
      }
      allowedPaymentMethods
      paymentData {
        __typename
        dataType
      }
      invoiceData {
        invoiceNumber
        invoiceId
      }
      ...PaymentProviderLinksV3_OrderFragment
      ...ProviderDataDisplayV3_OrderFragment
      ...OrderHistoryTableV3_OrderFragment
    }
  }
}

fragment OrderHistoryTableV3_OrderFragment on Order {
  history {
    additionalInformation
    dateTime
    message
    newStatus
  }
}

fragment PaymentProviderLinksV3_OrderFragment on Order {
  paymentData {
    __typename
    paymentMethod
    dataType
    ... on StripeData {
      paymentIntentId
    }
    ... on StripeSepaData {
      paymentIntentId
    }
  }
  status
}

fragment ProviderDataDisplayV3_OrderFragment on Order {
  paymentData {
    __typename
    ... on SepaData {
      iban
      mandateInformation {
        acceptanceDateTime
        mandateNumber
      }
    }
    ... on StripeSepaData {
      mandateInformation {
        acceptanceDateTime
        mandateNumber
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "orderId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BillingDetails",
  "kind": "LinkedField",
  "name": "billingDetails",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyBillingDetail",
      "kind": "LinkedField",
      "name": "companyBillingDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyDetails",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "houseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowedPaymentMethods",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "InvoiceData",
  "kind": "LinkedField",
  "name": "invoiceData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentIntentId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "MandateInformation",
  "kind": "LinkedField",
  "name": "mandateInformation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptanceDateTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mandateNumber",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditOrderScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "paymentData",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PaymentProviderLinksV3_OrderFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProviderDataDisplayV3_OrderFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrderHistoryTableV3_OrderFragment"
              }
            ],
            "type": "Order",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditOrderScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "paymentData",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentMethod",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "type": "StripeData",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "type": "StripeSepaData",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iban",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "type": "SepaData",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderHistoryElement",
                "kind": "LinkedField",
                "name": "history",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "additionalInformation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "newStatus",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Order",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f593f0222166d5d69bbd4337fcc16a02",
    "id": null,
    "metadata": {},
    "name": "EditOrderScreen_Query",
    "operationKind": "query",
    "text": "query EditOrderScreen_Query(\n  $orderId: ID!\n) {\n  node(id: $orderId) {\n    __typename\n    id\n    ... on Order {\n      billingDetails {\n        customerType\n        invoiceEmail\n        companyBillingDetails {\n          company\n          companyDetails\n          companyType\n        }\n        salutation\n        firstName\n        lastName\n        street\n        houseNumber\n        postalCode\n        country\n      }\n      allowedPaymentMethods\n      paymentData {\n        __typename\n        dataType\n      }\n      invoiceData {\n        invoiceNumber\n        invoiceId\n      }\n      ...PaymentProviderLinksV3_OrderFragment\n      ...ProviderDataDisplayV3_OrderFragment\n      ...OrderHistoryTableV3_OrderFragment\n    }\n  }\n}\n\nfragment OrderHistoryTableV3_OrderFragment on Order {\n  history {\n    additionalInformation\n    dateTime\n    message\n    newStatus\n  }\n}\n\nfragment PaymentProviderLinksV3_OrderFragment on Order {\n  paymentData {\n    __typename\n    paymentMethod\n    dataType\n    ... on StripeData {\n      paymentIntentId\n    }\n    ... on StripeSepaData {\n      paymentIntentId\n    }\n  }\n  status\n}\n\nfragment ProviderDataDisplayV3_OrderFragment on Order {\n  paymentData {\n    __typename\n    ... on SepaData {\n      iban\n      mandateInformation {\n        acceptanceDateTime\n        mandateNumber\n      }\n    }\n    ... on StripeSepaData {\n      mandateInformation {\n        acceptanceDateTime\n        mandateNumber\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fa79c98fbb2831b9ee4676884b6293f7';
export default node;
