import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    deleteEntitiesAction,
    DialogResult,
    DialogSettings,
    showDialogAction
} from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";

interface EntityDeletionButtonProps {
    selectedEntities: string[];
    entityType: string;
    label: string;
    labelPlural: string;
    disabled?: boolean;

    relatedEntityId?: string;
    additionalExplanation?: string;
}

interface DispatchProps {
    deleteEntities: (ids: string[], relatedEntityid: string) => void;
    showDialog: (dialogSettings: DialogSettings,
                 callback: (result: DialogResult) => void) => void;
}

type Props = EntityDeletionButtonProps & DispatchProps & WithTranslation;

const EntityDeletionButtonComponent = (props: Props) => {
    const {selectedEntities, showDialog, deleteEntities, label, labelPlural, relatedEntityId, additionalExplanation, disabled, t} = props;

    const countTerm = selectedEntities.length === 1 ?
        t("entity-deletion-button.dialog.count-term-singular", {label}) :
        t("entity-deletion-button.dialog.count-term-plural", {count: selectedEntities.length, labelPlural});

    return (
        <button type="button"
                onClick={() => {
                    showDialog({
                        title: t("entity-deletion-button.dialog.title", {labelPlural}),
                        question: t("entity-deletion-button.dialog.question", {countTerm}),
                        explanation: (additionalExplanation ? additionalExplanation + " " : "") + t("entity-deletion-button.dialog.explanation"),
                        affirmativeText: t("entity-deletion-button.dialog.affirmative-text"),
                        negativeText: t("entity-deletion-button.dialog.negative-text"),
                        affirmativeButtonClass: "btn btn-danger"
                    }, (r: DialogResult) => {
                        if (r === DialogResult.ACCEPT) {
                            deleteEntities(selectedEntities, relatedEntityId as string);
                        }
                    });
                }}
                className="btn btn-danger"
                disabled={disabled || selectedEntities.length === 0}>
            <FontAwesomeIcon className="mr-2" icon={faTimes}/>{t("entity-deletion-button.button-text")}
        </button>
    );
};
export const EntityDeletionButton = connect<{}, DispatchProps, EntityDeletionButtonProps>(
    null,
    (dispatch, ownProps: EntityDeletionButtonProps) => {
        // noinspection JSUnusedGlobalSymbols
        return {
            showDialog: (dialogSettings: DialogSettings,
                         callback: (result: DialogResult) => void) =>
                dispatch(showDialogAction(dialogSettings, callback)),
            deleteEntities: (ids: string[], relatedEntityid: string) => dispatch(deleteEntitiesAction(ownProps.entityType)(ids, relatedEntityid)),
        };
    }
)(withTranslation("core")(EntityDeletionButtonComponent));
