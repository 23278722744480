/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CustomerMessageDefinitionButtonType = "confirm" | "link" | "%future added value";
export type EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment = {
    readonly id: string;
    readonly internalTitle: string;
    readonly buttonOpt: {
        readonly type: CustomerMessageDefinitionButtonType;
        readonly title: string;
        readonly link?: string;
    } | null;
    readonly showUntilOpt: unknown | null;
    readonly notificationDefinitionOpt: {
        readonly title: string;
        readonly body: string;
    } | null;
    readonly " $refType": "EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment";
};
export type EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment$data = EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment;
export type EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment$key = {
    readonly " $data"?: EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment$data;
    readonly " $fragmentRefs": FragmentRefs<"EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "buttonOpt",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "link",
              "storageKey": null
            }
          ],
          "type": "CustomerMessageDefinitionLinkButton",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showUntilOpt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationDefinitionV2Type",
      "kind": "LinkedField",
      "name": "notificationDefinitionOpt",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CustomerMessageDefinitionV2",
  "abstractKey": null
};
})();
(node as any).hash = '25b7d23c1e26f93a52dda36cc5d684fc';
export default node;
