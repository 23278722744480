/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditAdditionalInformation_UserMetaFragment = {
    readonly additionalInformation: string | null;
    readonly " $refType": "EditAdditionalInformation_UserMetaFragment";
};
export type EditAdditionalInformation_UserMetaFragment$data = EditAdditionalInformation_UserMetaFragment;
export type EditAdditionalInformation_UserMetaFragment$key = {
    readonly " $data"?: EditAdditionalInformation_UserMetaFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"EditAdditionalInformation_UserMetaFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditAdditionalInformation_UserMetaFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalInformation",
      "storageKey": null
    }
  ],
  "type": "UserMeta",
  "abstractKey": null
};
(node as any).hash = 'b926eb5f47bd9f849898229ee70cb3e2';
export default node;
