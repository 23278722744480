/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiscountTypeKindInput = "system" | "user" | "%future added value";
export type UpdateDiscountActionInput = {
    data: EditDiscountActionData;
    id: string;
    clientMutationId?: string | null;
};
export type EditDiscountActionData = {
    newTitle: string;
    newDiscountType: DiscountTypeKindInput;
    newPercentage: number;
    newValidUntil?: string | null;
    newUsageLimitation?: EditUsageLimited | null;
};
export type EditUsageLimited = {
    onlyForAccountIds: Array<string>;
    maxAmountOfUsages?: number | null;
    onlyForProductIds: Array<string>;
    maxAmountOfUsagesPerAccount?: number | null;
};
export type DiscountActionForm_EditActionMutationVariables = {
    input: UpdateDiscountActionInput;
};
export type DiscountActionForm_EditActionMutationResponse = {
    readonly Admin: {
        readonly Billing: {
            readonly updateDiscountAction: {
                readonly data: {
                    readonly node: {
                        readonly " $fragmentRefs": FragmentRefs<"DiscountActionForm_DiscountActionFragment">;
                    };
                };
            } | null;
        };
    };
};
export type DiscountActionForm_EditActionMutation = {
    readonly response: DiscountActionForm_EditActionMutationResponse;
    readonly variables: DiscountActionForm_EditActionMutationVariables;
};



/*
mutation DiscountActionForm_EditActionMutation(
  $input: UpdateDiscountActionInput!
) {
  Admin {
    Billing {
      updateDiscountAction(input: $input) {
        data {
          node {
            ...DiscountActionForm_DiscountActionFragment
            id
          }
        }
      }
    }
  }
}

fragment DiscountActionForm_DiscountActionFragment on DiscountAction {
  id
  title
  calcType {
    __typename
    ... on DiscountActionPercentageCalcType {
      percentage
    }
  }
  validUntil {
    __typename
    kind
    ... on DiscountActionValidUntilDateTime {
      dateTime
    }
  }
  discountType {
    __typename
    kind
  }
  usageLimitation {
    __typename
    kind
    ... on DiscountActionUsageLimited {
      kind
      maxAmountOfUsages {
        __typename
        ... on DiscountActionAmountLimited {
          max
        }
      }
      maxAmountOfUsagesPerAccount {
        __typename
        ... on DiscountActionAmountLimited {
          max
        }
      }
      onlyForAccountIds
      onlyForProductIds
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "max",
        "storageKey": null
      }
    ],
    "type": "DiscountActionAmountLimited",
    "abstractKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscountActionForm_EditActionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateDiscountActionPayload",
                "kind": "LinkedField",
                "name": "updateDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DiscountActionForm_DiscountActionFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DiscountActionForm_EditActionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateDiscountActionPayload",
                "kind": "LinkedField",
                "name": "updateDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "calcType",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "percentage",
                                    "storageKey": null
                                  }
                                ],
                                "type": "DiscountActionPercentageCalcType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "validUntil",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dateTime",
                                    "storageKey": null
                                  }
                                ],
                                "type": "DiscountActionValidUntilDateTime",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "discountType",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "usageLimitation",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "maxAmountOfUsages",
                                    "plural": false,
                                    "selections": (v4/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "maxAmountOfUsagesPerAccount",
                                    "plural": false,
                                    "selections": (v4/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "onlyForAccountIds",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "onlyForProductIds",
                                    "storageKey": null
                                  }
                                ],
                                "type": "DiscountActionUsageLimited",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ecb8e5e53a072cbfe45d8d0e927594a",
    "id": null,
    "metadata": {},
    "name": "DiscountActionForm_EditActionMutation",
    "operationKind": "mutation",
    "text": "mutation DiscountActionForm_EditActionMutation(\n  $input: UpdateDiscountActionInput!\n) {\n  Admin {\n    Billing {\n      updateDiscountAction(input: $input) {\n        data {\n          node {\n            ...DiscountActionForm_DiscountActionFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment DiscountActionForm_DiscountActionFragment on DiscountAction {\n  id\n  title\n  calcType {\n    __typename\n    ... on DiscountActionPercentageCalcType {\n      percentage\n    }\n  }\n  validUntil {\n    __typename\n    kind\n    ... on DiscountActionValidUntilDateTime {\n      dateTime\n    }\n  }\n  discountType {\n    __typename\n    kind\n  }\n  usageLimitation {\n    __typename\n    kind\n    ... on DiscountActionUsageLimited {\n      kind\n      maxAmountOfUsages {\n        __typename\n        ... on DiscountActionAmountLimited {\n          max\n        }\n      }\n      maxAmountOfUsagesPerAccount {\n        __typename\n        ... on DiscountActionAmountLimited {\n          max\n        }\n      }\n      onlyForAccountIds\n      onlyForProductIds\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2cf94c6588b80c4700cec28729981784';
export default node;
