import { UserWithRoles } from "@thekeytechnology/framework-react";
import { OffersOverview } from "./old/v1/components/OffersOverview";
import { EditOfferForm } from "./old/v1/components/EditOffer";
import { OffersV2Overview } from "./old/v2/components/offer/OffersV2Overview";
import { EditOfferV2Form } from "./old/v2/components/offer/edit/EditOfferV2";
import { VoucherOverview } from "./old/v2/components/voucher/VoucherOverview";
import { EditVoucher } from "./old/v2/components/voucher/EditVoucher";
import { DownloadBillingCsv } from "./old/v2/components/download/DownloadBillingCsv";
import { DownloadCertificateCsv } from "./old/v2/components/download/DownloadCertificateCsv";
import { ProductsScreen } from "./v3/components/product/ProductsScreen";
import { PrivateRouteDefinition } from "../core/utils/PrivateRouteDefinition";
import { EditProductScreen } from "./v3/components/product/EditProductScreen";
import { CartHelperScreen } from "./v3/components/cart-helper/CartHelperScreen";
import {DiscountActionsScreen} from "./v3/components/discountv2/DiscountActionsScreen";
import {DiscountActionScreen} from "./v3/components/discountv2/DiscountActionScreen";
import {DiscountCodeScreen} from "./v3/components/discountv2/DiscountCodeScreen";

export const EDIT_DISCOUNT_ACTION = "/discount-actions/:discountActionId/edit"
export const EDIT_DISCOUNT_CODE = "/discount-actions/:discountActionId/:codeId/edit"
export const BillingModuleRoutes: PrivateRouteDefinition[] = [
    {
        path: "/offers",
        component: OffersOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/offers-v2",
        component: OffersV2Overview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/offers/:offerId/edit",
        component: EditOfferForm,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/offers-v2/:offerId/edit",
        component: EditOfferV2Form,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/vouchers",
        component: VoucherOverview,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/vouchers/:voucherId/edit",
        component: EditVoucher,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/billing-download",
        component: DownloadBillingCsv,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/certificate-download",
        component: DownloadCertificateCsv,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/cart-helper",
        component: CartHelperScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/products",
        component: ProductsScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/products/:productId/edit",
        component: EditProductScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/discount-actions",
        component: DiscountActionsScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: EDIT_DISCOUNT_ACTION,
        component: DiscountActionScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: EDIT_DISCOUNT_CODE,
        component: DiscountCodeScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
];
