import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { extractId } from "../../../../reducers/changes/common/helpers";
import { EditElementModalBase } from "../common/EditElementModalBase";
import { CONNECTION_VALIDATION_SCHEME, TITLE_VALIDATION_SCHEME } from "../common/GeneralElementSettingsEditor";
import { ELEMENT_TYPE_RESOLUTION, ResolutionElement } from "@thekeytechnology/thekey-academy-frontend-library";
import { ValidatedField } from "../../../../../core/components/form/ValidatedField";

interface OwnProps {
    element: EntityWrapper<ResolutionElement>;
    handleSubmit: (element: EntityWrapper<ResolutionElement>) => void;
    handleClose: () => void;
}

type Props = OwnProps & WithTranslation ;

const EditResolutionElementFormComponent = (props: Props) => {
    const {element, t, handleSubmit, handleClose} = props;

    return (
        <Formik
            initialValues={{
                internalTitle: element.entity.generalSettings.internalTitle,
                points: element.entity.generalSettings.points,
                lengthInSeconds: element.entity.generalSettings.lengthInSeconds,
                connections: element.entity.generalSettings.connections,
                title: element.entity.title,
                task: element.entity.task
            }}
            validationSchema={Yup.object().shape({
                internalTitle: TITLE_VALIDATION_SCHEME(t),
                connections: CONNECTION_VALIDATION_SCHEME(t),
                title: Yup.string().required(t("course-editor.edit-element-form.resolution.fields.title-error")),
                task: Yup.string().required(t("course-editor.edit-element-form.resolution.fields.task-error"))

            })}
            onSubmit={(values, {setSubmitting}) => {
                handleSubmit(new EntityWrapper<ResolutionElement>(
                    element.id,
                    {
                        generalSettings: {
                            internalTitle: values.internalTitle,
                            points: values.points,
                            lengthInSeconds: values.lengthInSeconds,
                            connections: values.connections
                        },
                        elementType: ELEMENT_TYPE_RESOLUTION,
                        elementPath: element.entity.elementPath,
                        title: values.title,
                        task: values.task,
                        versions: element.entity.versions
                    },
                    element.temporaryId,
                ));
                setSubmitting(false);
            }}
        >
            {formikState => (
                <EditElementModalBase title={t("course-editor.edit-element-form.resolution.heading")}
                                      currentElementId={extractId(element)}
                                      formikState={formikState}
                                      handleClose={handleClose}>
                    <div className="form-group row">
                        <ValidatedField formikState={formikState}
                                        type={"text"}
                                        name="title"
                                        className="form-control default-input"
                                        required
                                        label={t("course-editor.edit-element-form.resolution.fields.title")}
                                        placeholder={t("course-editor.edit-element-form.resolution.fields.title-placeholder")}
                        />
                        <ValidatedField formikState={formikState}
                                        type={"text"}
                                        name="task"
                                        className="form-control default-input"
                                        required
                                        label={t("course-editor.edit-element-form.resolution.fields.task")}
                                        placeholder={t("course-editor.edit-element-form.resolution.fields.task-placeholder")}
                        />
                    </div>
                </EditElementModalBase>
            )}
        </Formik>
    );
};

export const EditResolutionElementForm = withTranslation(["courses", "core"])(EditResolutionElementFormComponent);
