import { ParameterInput } from "./ParameterInput";
import React, { useState } from "react";
import { ConditionDefinition } from "../../core/model/trigger/definitions/trigger-definition";
import { Condition } from "../../core/model/trigger/condition";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Parameter } from "../../core/model/trigger/parameter";

interface OwnProps {
    permissibleConditions: ConditionDefinition[];
    conditions: Condition[],
    updateCondition: (conditionDefinition: ConditionDefinition, condition?: Condition) => void;
}

interface State {
    selectedConditionDefinition?: ConditionDefinition
}

export const ConditionsInput = ({
                                    permissibleConditions,
                                    conditions,
                                    updateCondition,
                                }: OwnProps) => {
    const {t} = useTranslation("triggers");

    const [state, setState] = useState<State>({
        selectedConditionDefinition: undefined
    })

    return <div className="form-group">
        <label>Bedingungen</label>
        <div className="d-flex align-items-center mb-2">
            <Select
                className="react-select category-select flex-grow-1"
                classNamePrefix="react-select"
                placeholder={t("trigger-field.conditions-placeholder")}
                options={permissibleConditions.filter(pc => conditions.find(c => c.key === pc.key) === undefined)}
                value={permissibleConditions.find(cd => cd.key === state.selectedConditionDefinition?.key)}
                onChange={(item: any) => setState({...state, selectedConditionDefinition: item})}
                getOptionValue={(opt: any) => opt}
                getOptionLabel={item => t("trigger-field.conditions." + item?.key)}
            />
            <button
                type="button"
                className="btn btn-secondary ml-3 aligj"
                disabled={!state.selectedConditionDefinition}
                onClick={() => {
                    updateCondition(state.selectedConditionDefinition!, {
                        key: state.selectedConditionDefinition?.key,
                        parameters: []
                    } as Condition)
                    setState({
                        selectedConditionDefinition: undefined
                    })
                }}>+
            </button>
        </div>

        {conditions.map(condition => {
            const definition = permissibleConditions.find(c => c.key === condition.key)
            return definition ? <div className="bg-light p-3 mb-2" key={condition.key}>
                <div className="d-flex">
                    <h4 className="flex-grow-1">{t("trigger-field.conditions." + condition.key)}</h4>
                    <button type="button" onClick={() => {
                        updateCondition(definition, undefined)
                    }} className="btn btn-link text-danger ml-3">Löschen
                    </button>
                </div>

                {definition.configurationParameters.map(parameterDefinition => {
                    const parameterValue = condition.parameters.find(p => p.key === parameterDefinition.key)?.value;
                    return <div key={parameterDefinition.key}>
                        <label>{t("trigger-field.trigger-parameters." + parameterDefinition.key)}</label>
                        <ParameterInput
                            parameterDefinition={parameterDefinition}
                            parameterValue={parameterValue} updateParameter={((_, value) => {

                            const newParameter = {
                                key: parameterDefinition.key,
                                dataType: parameterDefinition.dataType,
                                value,
                            } as Parameter;

                            const newCondition = {
                                key: definition.key,
                                parameters: [...condition.parameters.filter(p => p.key !== parameterDefinition.key), newParameter]
                            } as Condition;

                            updateCondition(definition, newCondition)
                        })}/>
                    </div>
                })}
            </div> : null
        })}
    </div>
}
