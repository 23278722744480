import {classNames} from "primereact/utils";
import React from "react";
import {RenderConfig} from "../ValidatedFieldV2";
import {InputTextarea} from "primereact/inputtextarea";

export function DefaultTextAreaComponent({fieldName, fieldValue, updateField, isValid}: RenderConfig<string>) {
    return <InputTextarea
        id={fieldName}
        name={fieldName}
        value={fieldValue}
        onChange={e => updateField(e.target.value)}
        className={classNames({"p-invalid": !isValid})}/>
}
