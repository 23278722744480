import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_CREATE_COURSE = "create-course";

export const createCourseAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_CREATE_COURSE,
            callType: API_CREATE_COURSE
        },
        payload: undefined
    } as ApiCallAction<undefined>;
};
