import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_FETCH_COURSE_STATES_ADMIN = "fetch-course-states-admin";

export const fetchCourseStatesAdminAction = (userId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_COURSE_STATES_ADMIN,
            callType: API_FETCH_COURSE_STATES_ADMIN
        },
        payload: userId,
    } as ApiCallAction<string>;
};
