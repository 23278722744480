import graphql from "babel-plugin-relay/macro"
import {Button} from "primereact/button";
import {useMutation} from "react-relay";
import React from "react";
import {useTranslation} from "react-i18next";
import { DeleteDiscountActionButton_DeleteMutation } from "../../../../../../__generated__/DeleteDiscountActionButton_DeleteMutation.graphql";

const DELETE_DISCOUNT_ACTION_MUTATION = graphql`
    mutation DeleteDiscountActionButton_DeleteMutation($input: DeleteDiscountActionInput!, $connections: [ID!]!){
        Admin {
            Billing {
                deleteDiscountAction(input: $input){
                    deletedId @deleteEdge(connections: $connections)
                }
            }
        }
    }
`

interface OwnProps {
    id: string
    className?: string
    connectionId: string
}

export const DeleteDiscountActionButton = ({connectionId, className, id}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const [deleteDiscountAction, isLoading] = useMutation<DeleteDiscountActionButton_DeleteMutation>(DELETE_DISCOUNT_ACTION_MUTATION)

    return <Button
        icon="pi pi-trash"
        tooltip={t("billing.discount-code.button.delete-action.tooltip")}
        loading={isLoading}
        disabled={isLoading}
        className={className}
        onClick={(e) => {
            deleteDiscountAction({
                variables: {
                    input: {
                        id: id
                    },
                    connections: [connectionId]
                }
            })
        }}/>
}
