import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reloadEntityListAction, removeFiltersAction, selectFilter } from "@thekeytechnology/framework-react";
import { Filter, FilterTerm, PropertyFilter, addFiltersAction } from "@thekeytechnology/framework-react";
import React from "react";
import { connect } from "react-redux";
import "./text-filter.scss";

interface TextFilterProps {
    entityType: string;
    property: string;
    placeholder: string;
}

interface StateProps {
    currentFilters?: Filter[];
}

interface DispatchProps {
    addFilters: ReturnType<typeof addFiltersAction>,
    removeFilters: ReturnType<typeof removeFiltersAction>
    reloadEntities: ReturnType<typeof reloadEntityListAction>
}

type Props = TextFilterProps & StateProps & DispatchProps;

export const UnconnectedTextFilter = (props: Props) => {
    const filter = (event: any) => {
        const text = event.target.value;
        if (text.length) {
            props.addFilters(props.property, [new PropertyFilter(props.property,
                new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_REGEX, text)
            )]);
        } else {
            props.removeFilters([props.property]);
        }
        props.reloadEntities();
    };

    const currentFilter = props.currentFilters && props.currentFilters.length && props.currentFilters[0] ?
        (props.currentFilters[0] as PropertyFilter).filterTerm.value : "";

    return (
        <div className="filter text-filter">
            <input type="text" value={currentFilter}
                   className="text-filter default-input" onChange={filter} placeholder={props.placeholder}/>
            <FontAwesomeIcon icon={faSearch}/>
        </div>
    );
};

export const TextFilter = connect<StateProps, DispatchProps, TextFilterProps>(
    (state: any, ownProps: TextFilterProps) => {
        return {
            currentFilters: selectFilter(ownProps.entityType, ownProps.property)(state)
        };
    },
    (dispatch, ownProps: TextFilterProps) => {
        return {
            addFilters: (key: string, filters: Filter[]) => dispatch(addFiltersAction(ownProps.entityType)(key, filters)),
            removeFilters: (keys: string[]) => dispatch(removeFiltersAction(ownProps.entityType)(keys)),
            reloadEntities: () => dispatch(reloadEntityListAction(ownProps.entityType)())
        };
    }
)(UnconnectedTextFilter);
