/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiscountSelect_QueryFragmentRefetchQueryVariables = {
    after?: string | null;
    code?: string | null;
    first?: number | null;
    ids: Array<string>;
};
export type DiscountSelect_QueryFragmentRefetchQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"DiscountSelect_QueryFragment">;
};
export type DiscountSelect_QueryFragmentRefetchQuery = {
    readonly response: DiscountSelect_QueryFragmentRefetchQueryResponse;
    readonly variables: DiscountSelect_QueryFragmentRefetchQueryVariables;
};



/*
query DiscountSelect_QueryFragmentRefetchQuery(
  $after: String
  $code: String
  $first: Int
  $ids: [ID!]!
) {
  ...DiscountSelect_QueryFragment_3cqwvN
}

fragment DiscountSelect_QueryFragment_3cqwvN on Query {
  Admin {
    Billing {
      DiscountsMultiselect(first: $first, after: $after, codeOpt: $code, ids: $ids) {
        edges {
          cursor
          node {
            id
            code
            __typename
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "ids",
  "variableName": "ids"
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "codeOpt",
    "variableName": "code"
  },
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscountSelect_QueryFragmentRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "code",
            "variableName": "code"
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "DiscountSelect_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DiscountSelect_QueryFragmentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "DiscountCodesV2Connection",
                "kind": "LinkedField",
                "name": "DiscountsMultiselect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCodeV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "codeOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "DiscountSelect_DiscountsMultiselect",
                "kind": "LinkedHandle",
                "name": "DiscountsMultiselect"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d858d29cfda3b56349566d52e5435a9",
    "id": null,
    "metadata": {},
    "name": "DiscountSelect_QueryFragmentRefetchQuery",
    "operationKind": "query",
    "text": "query DiscountSelect_QueryFragmentRefetchQuery(\n  $after: String\n  $code: String\n  $first: Int\n  $ids: [ID!]!\n) {\n  ...DiscountSelect_QueryFragment_3cqwvN\n}\n\nfragment DiscountSelect_QueryFragment_3cqwvN on Query {\n  Admin {\n    Billing {\n      DiscountsMultiselect(first: $first, after: $after, codeOpt: $code, ids: $ids) {\n        edges {\n          cursor\n          node {\n            id\n            code\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a9a29ba8630b7e14ee8c6e14447225fc';
export default node;
