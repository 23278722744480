/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CustomerMessageDefinitionButtonTypeEnumInputInput = "confirm" | "link" | "%future added value";
export type UpdateCustomerMessageDefinitionCoreInput = {
    data: CustomerMessageDefinitionCoreInput;
    id: string;
    clientMutationId?: string | null;
};
export type CustomerMessageDefinitionCoreInput = {
    internalTitle: string;
    buttonOpt?: CustomerMessageDefinitionButtonInput | null;
    showUntilOpt?: unknown | null;
    notificationDefinitionOpt?: CustomerMessageDefinitionNotificationDefinitionV2Input | null;
    clientMutationId?: string | null;
};
export type CustomerMessageDefinitionButtonInput = {
    title: string;
    link?: string | null;
    buttonType: CustomerMessageDefinitionButtonTypeEnumInputInput;
};
export type CustomerMessageDefinitionNotificationDefinitionV2Input = {
    title: string;
    body: string;
};
export type EditCustomerMessageDefinitionV2CoreForm_EditCoreMutationVariables = {
    input: UpdateCustomerMessageDefinitionCoreInput;
};
export type EditCustomerMessageDefinitionV2CoreForm_EditCoreMutationResponse = {
    readonly Admin: {
        readonly CustomerMessageV2: {
            readonly updateCustomerMessageDefinitionCore: {
                readonly data: {
                    readonly node: {
                        readonly " $fragmentRefs": FragmentRefs<"EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment">;
                    };
                };
            } | null;
        };
    };
};
export type EditCustomerMessageDefinitionV2CoreForm_EditCoreMutation = {
    readonly response: EditCustomerMessageDefinitionV2CoreForm_EditCoreMutationResponse;
    readonly variables: EditCustomerMessageDefinitionV2CoreForm_EditCoreMutationVariables;
};



/*
mutation EditCustomerMessageDefinitionV2CoreForm_EditCoreMutation(
  $input: UpdateCustomerMessageDefinitionCoreInput!
) {
  Admin {
    CustomerMessageV2 {
      updateCustomerMessageDefinitionCore(input: $input) {
        data {
          node {
            ...EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment
            id
          }
        }
      }
    }
  }
}

fragment EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {
  id
  internalTitle
  buttonOpt {
    __typename
    type
    title
    ... on CustomerMessageDefinitionLinkButton {
      link
    }
  }
  showUntilOpt
  notificationDefinitionOpt {
    title
    body
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCustomerMessageDefinitionV2CoreForm_EditCoreMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateCustomerMessageDefinitionCorePayload",
                "kind": "LinkedField",
                "name": "updateCustomerMessageDefinitionCore",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionsV2Edge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageDefinitionV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditCustomerMessageDefinitionV2CoreForm_EditCoreMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateCustomerMessageDefinitionCorePayload",
                "kind": "LinkedField",
                "name": "updateCustomerMessageDefinitionCore",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionsV2Edge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageDefinitionV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "buttonOpt",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "link",
                                    "storageKey": null
                                  }
                                ],
                                "type": "CustomerMessageDefinitionLinkButton",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "showUntilOpt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NotificationDefinitionV2Type",
                            "kind": "LinkedField",
                            "name": "notificationDefinitionOpt",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "body",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ecb6e950046bdda91d1a9297f27f2544",
    "id": null,
    "metadata": {},
    "name": "EditCustomerMessageDefinitionV2CoreForm_EditCoreMutation",
    "operationKind": "mutation",
    "text": "mutation EditCustomerMessageDefinitionV2CoreForm_EditCoreMutation(\n  $input: UpdateCustomerMessageDefinitionCoreInput!\n) {\n  Admin {\n    CustomerMessageV2 {\n      updateCustomerMessageDefinitionCore(input: $input) {\n        data {\n          node {\n            ...EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditCustomerMessageDefinitionV2CoreForm_CustomerMessageDefinitionV2Fragment on CustomerMessageDefinitionV2 {\n  id\n  internalTitle\n  buttonOpt {\n    __typename\n    type\n    title\n    ... on CustomerMessageDefinitionLinkButton {\n      link\n    }\n  }\n  showUntilOpt\n  notificationDefinitionOpt {\n    title\n    body\n  }\n}\n"
  }
};
})();
(node as any).hash = '56f8c2ea1433f6704913e4683e833bc2';
export default node;
