import React from "react";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {NavLink} from "react-router-dom";
import {
    UsageOrderColumn_DiscountCodeUsageFragment$key
} from "../../../../../../../__generated__/UsageOrderColumn_DiscountCodeUsageFragment.graphql";

const DISCOUNT_CODE_USAGE_FRAGMENT = graphql`
    fragment UsageOrderColumn_DiscountCodeUsageFragment on DiscountCodeUsage {
        order {
            id
        }
    }
`

interface OwnProps {
    discountCodeUsageFragmentRef: UsageOrderColumn_DiscountCodeUsageFragment$key
}

export const UsageOrderColumn = ({discountCodeUsageFragmentRef}: OwnProps) => {
    const discountCodeUsage = useFragment<UsageOrderColumn_DiscountCodeUsageFragment$key>(DISCOUNT_CODE_USAGE_FRAGMENT, discountCodeUsageFragmentRef)

    return <NavLink target="_blank" rel="noopener noreferrer" to={`/orders-v3/${discountCodeUsage.order?.id}/edit`}>
        Zur Bestellung
    </NavLink>
}
