import React from "react";
import { useTranslation } from "react-i18next";
import { ENTITY_TYPE_QNA} from "../model/qna";
import { ONLY_UNANSWERED_FILTER_KEY, OnlyUnansweredFilter } from "./OnlyUnansweredFilters";
import { OnlyPublicFilter } from "./OnlyPublic";
import { Filters } from "../../core/components/filters/Filters";
import { TextFilter } from "../../core/components/filters/TextFilter";

export const QnaFilters = () => {
    const {t} = useTranslation(["qna", "core"]);
    return (
        <Filters entityType={ENTITY_TYPE_QNA}
                 filterKeys={[`entity.question.de`, "entity.answer.de", ONLY_UNANSWERED_FILTER_KEY]}>
            <TextFilter
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.qna.labels.question")})}
                entityType={ENTITY_TYPE_QNA}
                property={`entity.question.de`}/>
            <TextFilter
                placeholder={t("core:filters.filter-by", {fieldName: t("entity.qna.labels.answer")})}
                entityType={ENTITY_TYPE_QNA}
                property={`entity.answer.de`}/>
            <OnlyUnansweredFilter/>
            <OnlyPublicFilter/>
        </Filters>
    );
};
