import {combineEpics, ofType, StateObservable} from "redux-observable";
import {ChangeAccount, ChangeAccountAction} from "@thekeytechnology/framework-react";
import {map, withLatestFrom} from "rxjs/operators";
import {push} from "connected-react-router";
import {Action} from "redux";
import {Observable} from "rxjs";
import {saveState} from "../../../localStorage";

const reloadOnAccountChange$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    ofType(ChangeAccount),
    map((action: Action) => action as ChangeAccountAction),
    withLatestFrom(state$),
    map(([, state]: [ChangeAccountAction, any]) => {
        saveState({
            auth: {
                authState: state.auth.authState
            },
        });
        setTimeout(() => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }, 300);
        return push("/");
    })
);

export const reloadActions$ = combineEpics(
    reloadOnAccountChange$
);
