import {
    EntityWrapper,
    WithSwitchableLanguage,
    WithSwitchableLanguageProps
} from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import { WithTranslation, withTranslation } from "react-i18next";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
    ENTITY_TYPE_TRIGGERED_ACHIEVEMENT,
    ENTITY_TYPE_TRIGGERED_ACHIEVEMENT_FOR_SAVING,
    NEW_TRIGGERED_ACHIEVEMENT,
    TriggeredAchievement,
    TriggeredAchievementForSaving
} from "../model/triggered-achievement";
import { YUP_SCHEMA_TRIGGER } from "../../core/model/trigger/trigger";
import { fetchTriggerDefinitionsAction } from "../../triggers/actions/fetch-trigger-definitions";
import { selectTriggerDefinitions } from "../../triggers/selectors";
import { TriggerField } from "../../triggers/components/TriggerField";
import { InlineAttachmentField } from "../../core/components/attachment/InlineAttachmentField";
import { LoadingEditHeader } from "../../core/components/header/LoadingEditHeader";
import { EditHeaderWithBackground } from "../../core/components/edit/EditHeaderWithBackground";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { ContentContainer } from "../../core/components/containers/Container";
import { SaveAndBackButtons } from "../../core/components/button/SaveAndBackButtons";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../core/components/entity/WithSingleEntityFromPath";

type Props =
    WithSingleEntityFromPathProps<TriggeredAchievement, TriggeredAchievementForSaving>
    & WithTranslation
    & WithSwitchableLanguageProps;

const EditTriggeredAchievementsComponent = (props: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTriggerDefinitionsAction())
    }, [dispatch])

    const definitions = useSelector(selectTriggerDefinitions)

    const {entity, upsertEntity, t, currentLanguage} = props;

    return (
        <>
            <LoadingEditHeader entity={entity} label={t("entity.triggered-achievement.singular")}/>

            {entity && definitions ?
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        internalTitle: entity.entity.internalTitle,
                        trigger: entity.entity.trigger,
                        title: entity.entity.titles[currentLanguage],
                        explanation: entity.entity.explanations[currentLanguage],
                        text: entity.entity.texts[currentLanguage],
                        textGotten: entity.entity.textsGotten[currentLanguage],
                        image: entity.entity.image,
                        points: entity.entity.points
                    }}
                    validationSchema={Yup.object().shape({
                        trigger: YUP_SCHEMA_TRIGGER(definitions, t),
                        internalTitle: Yup.string().required(t("entity.triggered-achievement.labels.internal-title") + " " + t("translation:is a required field")),
                        title: Yup.string().required(t("entity.triggered-achievement.labels.title") + " " + t("translation:is a required field")),
                        explanation: Yup.string().required(t("entity.triggered-achievement.labels.explanation") + " " + t("translation:is a required field")),
                        text: Yup.string().required(t("entity.triggered-achievement.labels.text") + " " + t("translation:is a required field")),
                        textGotten: Yup.string().required(t("entity.triggered-achievement.labels.text-gotten") + " " + t("translation:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const achievementForSaving = new EntityWrapper(entity.id,
                            {
                                internalTitle: values.internalTitle,
                                trigger: values.trigger,
                                imageRef: values.image ? values.image.id : undefined,
                                titles: {
                                    ...entity.entity.titles,
                                    [currentLanguage]: values.title
                                },
                                explanations: {
                                    ...entity.entity.explanations,
                                    [currentLanguage]: values.explanation
                                },
                                texts: {
                                    ...entity.entity.texts,
                                    [currentLanguage]: values.text
                                },
                                textsGotten: {
                                    ...entity.entity.textsGotten,
                                    [currentLanguage]: values.textGotten
                                },
                                points: values.points
                            } as TriggeredAchievementForSaving
                        );
                        upsertEntity(achievementForSaving);
                        setSubmitting(false);
                    }}
                >
                    {formikState => {
                        const currentDefinition = formikState.values.trigger ? definitions.find(d => d.key === formikState.values.trigger!.key) : undefined;
                        return (
                            <Form>
                                <EditHeaderWithBackground
                                    heading={t("edit-triggered-achievement.heading")}
                                    leadText={<>{formikState.values.internalTitle}</>}>
                                    <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                        backPath="/achievements"/>
                                </EditHeaderWithBackground>
                                <ContentContainer>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-achievement.labels.internal-title")}
                                                        name="internalTitle"
                                                        formikState={formikState}
                                                        type="text"
                                                        placeholder={t("entity.triggered-achievement.labels.internal-title-placeholder")}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-achievement.labels.trigger")}
                                                        name="trigger"
                                                        formikState={formikState}
                                                        definitions={definitions}
                                                        component={TriggerField}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField formikState={formikState}
                                                        name="image"
                                                        component={InlineAttachmentField}
                                                        isSingleSelect={true}
                                                        className="form-control default-input"
                                                        label={t("entity.triggered-achievement.labels.image")}/>
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-achievement.labels.points")}
                                                        name="points"
                                                        formikState={formikState}
                                                        type="number"
                                                        min={0}
                                                        step={1}
                                                        placeholder={t("entity.triggered-achievement.labels.points-placeholder")}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-achievement.labels.title")}
                                                        name="title"
                                                        formikState={formikState}
                                                        type="text"
                                                        placeholder={t("entity.triggered-achievement.labels.title-placeholder")}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-achievement.labels.explanation")}
                                                        name="explanation"
                                                        formikState={formikState}
                                                        type="text"
                                                        placeholder={t("entity.triggered-achievement.labels.explanation-placeholder")}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-achievement.labels.text")}
                                                        name="text"
                                                        formikState={formikState}
                                                        type="text"
                                                        placeholder={t("entity.triggered-achievement.labels.text-placeholder")}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <ValidatedField label={t("entity.triggered-achievement.labels.text-gotten")}
                                                        name="textGotten"
                                                        formikState={formikState}
                                                        type="text"
                                                        placeholder={t("entity.triggered-achievement.labels.text-gotten-placeholder")}
                                                        required
                                        />
                                    </div>
                                    {currentDefinition ? <div className="form-group row">
                                        <label htmlFor="html"
                                               className="col-sm-2 col-form-label">{t("edit-triggered-achievement.variables-heading")}</label>
                                        <div className="col-sm-10">
                                            {currentDefinition.availableVariables.length ? <ul>
                                                {currentDefinition.availableVariables.map(v => {
                                                    return <li key={v}>%%{v}%%
                                                        - {t("triggers:trigger-field.variables." + v)}</li>;
                                                })}
                                            </ul> : null}
                                        </div>
                                    </div> : null}

                                </ContentContainer>
                            </Form>
                        );
                    }}
                </Formik> : null}
        </>
    );
};

export const EditTriggeredAchievement = WithSingleEntityFromPath<{}, TriggeredAchievement, TriggeredAchievementForSaving>(WithSwitchableLanguage<any>(
    withTranslation(["achievements", "triggers"])(EditTriggeredAchievementsComponent)),
    ENTITY_TYPE_TRIGGERED_ACHIEVEMENT,
    "achievementId",
    NEW_TRIGGERED_ACHIEVEMENT,
    undefined,
    ENTITY_TYPE_TRIGGERED_ACHIEVEMENT_FOR_SAVING
);
