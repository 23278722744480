import { matchesApiSuccess } from "@thekeytechnology/framework-react";
import { API_LOGIN } from "@thekeytechnology/framework-react";
import { push } from "connected-react-router";
import {combineEpics} from "redux-observable";
import {Observable, of} from "rxjs";
import {switchMap} from "rxjs/operators";
import {Action} from "redux";

const redirectAfterLogin$ =  (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_LOGIN),
    switchMap(() => of(push("/")))
);

export const loginEpics$ = combineEpics(
    redirectAfterLogin$,
);
