import React, {useEffect} from "react";
import {Account, EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectOrdersAdmin, selectUsersInAccount} from "../../../selectors";
import "./user-orders-table.scss";
import {NavLink} from "react-router-dom";
import {OrderPriceDisplay} from "../../users/orders/v2/OrderPriceDisplay";
import {PaymentProviderLinks} from "../../users/orders/v2/PaymentProviderLinks";
import {API_FETCH_ACCOUNT_ORDERS_ADMIN, fetchAccountOrdersAdminAction} from "../../../actions/fetch-account-orders";
import {Table, TableColumn, TableHeader, TableRow} from "../../../../core/components/table/Table";
import {AsyncEntityTableBody} from "../../../../core/components/table/AsyncEntityTableBody";
import {DateTimeDisplay} from "../../../../core/components/datetime/DateTimeDisplay";

export interface OwnProps {
    account: EntityWrapper<Account>
}

export const AccountOrdersTable = ({account}: OwnProps) => {
    const {t} = useTranslation("admin")
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAccountOrdersAdminAction(account.id!))
    }, [dispatch, account])

    const usersInAccount = useSelector(selectUsersInAccount);
    const entities = useSelector(selectOrdersAdmin)
    const apiCallState = useSelector(selectCombinedApiState(API_FETCH_ACCOUNT_ORDERS_ADMIN))

    return entities ? <Table itemCount={entities.entities.length} className="user-orders-table">
        <TableHeader>
            <TableColumn>{t("entity.orders.labels.date-time")}</TableColumn>
            <TableColumn>{t("entity.orders.labels.id")}</TableColumn>
            <TableColumn>{t("entity.orders.labels.user")}</TableColumn>
            <TableColumn>{t("entity.orders.labels.payment-method")}</TableColumn>
            <TableColumn>{t("entity.orders.labels.amount")}</TableColumn>
        </TableHeader>
        <AsyncEntityTableBody
            apiCallState={apiCallState}
            items={entities.entities}
            renderItem={order => {
                const user = usersInAccount?.find(user => order.entity.userRef === user.userId);
                return <TableRow key={order.id}>
                    <TableColumn>
                        <DateTimeDisplay dateTime={order.entity.history[0]?.dateTime}/>
                    </TableColumn>
                    <TableColumn className="bold title-column">
                        <NavLink to={`/orders/${order.id}/edit`}>
                            {order.id}
                        </NavLink>
                    </TableColumn>
                    <TableColumn className="bold title-column">
                        {order.entity.userRef ? <NavLink to={`/users/${btoa("User:"+order.entity.userRef)}/edit`}>
                            {user ? user.userName : "Unbekannt"}
                        </NavLink> : null}
                    </TableColumn>
                    <TableColumn>
                        <PaymentProviderLinks isPaid={order.entity.status === "payment-in-process"}
                                              paymentProviderData={order.entity.paymentProviderData}/>
                    </TableColumn>
                    <TableColumn>
                        <OrderPriceDisplay order={order}/>
                    </TableColumn>
                </TableRow>
            }

            }/>
    </Table> : null;
}
