import React from 'react'
import {Badge, BadgeSeverityType} from "primereact/badge";

export const EnvironmentBadge = () => {
    let severity: BadgeSeverityType | undefined = undefined
    let labelText: string = ""

    switch (process.env.REACT_APP_API_BASE) {
        case "https://api.thekey.academy":
            severity = "danger"
            labelText = "ECHTSYSTEM"
            break;
        case "https://staging.api.thekey.academy":
            severity = "warning"
            labelText = "TESTSYSTEM"
            break;
        default:
            severity = "success"
            labelText = "LOKAL"
            break;
    }

    return <Badge severity={severity} value={labelText}/>
}
