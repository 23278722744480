/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment = {
    readonly " $fragmentRefs": FragmentRefs<"CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment">;
    readonly " $refType": "CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment";
};
export type CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment$data = CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment;
export type CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment$key = {
    readonly " $data"?: CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerMessageDefinitionPages_CustomerMessageDefinitionV2Fragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CustomerMessageDefinitionPagesOrderList_CustomerMessageDefinitionV2Fragment"
    }
  ],
  "type": "CustomerMessageDefinitionV2",
  "abstractKey": null
};
(node as any).hash = '824199624ef2aa5dfdd35fc85e670c1f';
export default node;
