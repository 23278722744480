/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CustomerMessageDefinitionButtonTypeEnumInputInput = "confirm" | "link" | "%future added value";
export type CreateCustomerMessageDefinitionInput = {
    data: CreateCustomerMessageDefinitionData;
    clientMutationId?: string | null;
};
export type CreateCustomerMessageDefinitionData = {
    internalTitle: string;
    buttonOpt?: CustomerMessageDefinitionButtonInput | null;
    showUntilOpt?: unknown | null;
    notificationDefinitionOpt?: CustomerMessageDefinitionNotificationDefinitionV2Input | null;
    page: CustomerMessageDefinitionPageInput;
    clientMutationId?: string | null;
};
export type CustomerMessageDefinitionButtonInput = {
    title: string;
    link?: string | null;
    buttonType: CustomerMessageDefinitionButtonTypeEnumInputInput;
};
export type CustomerMessageDefinitionNotificationDefinitionV2Input = {
    title: string;
    body: string;
};
export type CustomerMessageDefinitionPageInput = {
    internalTitle: string;
    imageIdOpt?: string | null;
};
export type CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutationVariables = {
    input: CreateCustomerMessageDefinitionInput;
    connections: Array<string>;
};
export type CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutationResponse = {
    readonly Admin: {
        readonly CustomerMessageV2: {
            readonly createCustomerMessageDefinition: {
                readonly data: {
                    readonly node: {
                        readonly id: string;
                        readonly internalTitle: string;
                    };
                };
            } | null;
        };
    };
};
export type CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutation = {
    readonly response: CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutationResponse;
    readonly variables: CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutationVariables;
};



/*
mutation CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutation(
  $input: CreateCustomerMessageDefinitionInput!
) {
  Admin {
    CustomerMessageV2 {
      createCustomerMessageDefinition(input: $input) {
        data {
          node {
            id
            internalTitle
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomerMessageDefinitionsV2Edge",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerMessageDefinitionV2",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalTitle",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCustomerMessageDefinitionPayload",
                "kind": "LinkedField",
                "name": "createCustomerMessageDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCustomerMessageDefinitionPayload",
                "kind": "LinkedField",
                "name": "createCustomerMessageDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "prependEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "946ecf2ca34286b93696db6ad20e8f88",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerMessageDefinitionV2OverviewScreen_CreateCustomerMessageDefinitionMutation(\n  $input: CreateCustomerMessageDefinitionInput!\n) {\n  Admin {\n    CustomerMessageV2 {\n      createCustomerMessageDefinition(input: $input) {\n        data {\n          node {\n            id\n            internalTitle\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '82a59ebe3cf9d70a6b0a348efa9ac327';
export default node;
