import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createCourseAction } from "../actions/create-course";

interface DispatchProps {
    createCourse: typeof createCourseAction
}

type Props = DispatchProps & WithTranslation;

const CreateCourseButtonComponent = (props: Props) => {
    const {createCourse, t} = props;
    return <button className="btn btn-success mr-2"
                   onClick={() => createCourse()}>
        {t("core:entity-create-delete-button.create")}
    </button>;
};

export const CreateCourseButton = connect<{}, DispatchProps, {}>(
    null,
    {
        createCourse: createCourseAction
    }
)(withTranslation(["courses", "core"])(CreateCourseButtonComponent));
