import {ConnectionHandler, useFragment, useLazyLoadQuery, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import {useRouteMatch} from "react-router";
import {useHistory} from "react-router-dom";
import {EditCourseBundleForm} from "./EditCourseBundleForm";
import {EditCourseBundleScreen_Query} from "../../../__generated__/EditCourseBundleScreen_Query.graphql";
import {
    EditCourseBundleScreen_CourseBundleFragment$key
} from "../../../__generated__/EditCourseBundleScreen_CourseBundleFragment.graphql";
import {EditCourseBundleScreen_CreateMutation} from "../../../__generated__/EditCourseBundleScreen_CreateMutation.graphql";
import {EditCourseBundleScreen_UpdateMutation} from "../../../__generated__/EditCourseBundleScreen_UpdateMutation.graphql";
import {Course} from "../../core/components/entity-selects/CoursesSelect";

const QUERY = graphql`
    query EditCourseBundleScreen_Query($id: ID!, $skip: Boolean!) {
        node(id: $id) @skip(if: $skip){
            ... on CourseBundle {
                ...EditCourseBundleScreen_CourseBundleFragment
            }
        }
    }
`;

const COURSE_BUNDLE_FRAGMENT = graphql`
    fragment EditCourseBundleScreen_CourseBundleFragment on CourseBundle {
        id,
        title,
        bundleCourses {
            id
            ... on Course {
                containerMeta {
                    title
                }
            }
        }
        finalCourseId
    }
`;

const CREATE_MUTATION = graphql`
    mutation EditCourseBundleScreen_CreateMutation($input: CreateCourseBundleInput!, $connections: [ID!]!) {
        Admin {
            Bundle {
                createCourseBundle(input: $input) {
                    data @appendEdge(connections: $connections){
                        node  {
                            ... EditCourseBundleScreen_CourseBundleFragment
                        }
                    }
                }
            }
        }
    }
`;

const UPDATE_MUTATION = graphql`
    mutation EditCourseBundleScreen_UpdateMutation($input: UpdateCourseBundleInput!) {
        Admin {
            Bundle {
                updateCourseBundle(input: $input) {
                    data {
                        node {
                            ... EditCourseBundleScreen_CourseBundleFragment
                        }
                    }
                }
            }
        }
    }
`;

export const EditCourseBundleScreen = () => {
    const {params: {courseBundleId}} = useRouteMatch<{ courseBundleId: string }>()
    const history = useHistory()

    const query = useLazyLoadQuery<EditCourseBundleScreen_Query>(QUERY, {
        id: courseBundleId,
        skip: !courseBundleId || courseBundleId === "new",
    })


    const courseBundle = useFragment<EditCourseBundleScreen_CourseBundleFragment$key>(COURSE_BUNDLE_FRAGMENT, query.node || null)
    const [create, isCreating] = useMutation<EditCourseBundleScreen_CreateMutation>(CREATE_MUTATION)
    const [update, isUpdating] = useMutation<EditCourseBundleScreen_UpdateMutation>(UPDATE_MUTATION)

    return <div>
        <h1 className="mb-3">Bearbeite Produkt</h1>
        <EditCourseBundleForm
            onSubmit={submittedData => {
                if (courseBundle) {
                    update({
                        variables: {
                            input: {
                                id: courseBundle.id!,
                                data: {
                                    title: submittedData.title || "",
                                    bundleCourses: submittedData.bundleCourseIds?.map(c => c.id) || [],
                                    finalCourse: submittedData.finalCourseId || ""
                                }

                            }
                        },
                        onCompleted: () => history.push("/course-bundles")
                    })
                } else {
                    create({
                        variables: {
                            input: {
                                data: {
                                    title: submittedData.title || "",
                                    bundleCourses: submittedData.bundleCourseIds?.map(c => c.id) || [],
                                    finalCourse: submittedData.finalCourseId || ""
                                }
                            },
                            connections: [
                                ConnectionHandler.getConnectionID("client:root:Admin:Billing", "ProductsTable_Products")
                            ]
                        },
                        onCompleted: () => history.push("/course-bundles")
                    })
                }
            }}
            loading={isCreating || isUpdating}
            initialValues={{
                title: courseBundle?.title,
                finalCourseId: courseBundle?.finalCourseId,
                bundleCourseIds: courseBundle?.bundleCourses.map(bc => ({
                    id: bc.id,
                    name: bc.containerMeta.title
                } as Course))
            }}
        />
    </div>

}
