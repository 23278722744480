import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { AdditionalContentFilters } from "./AdditionalContentFilters";
import { AdditionalContentTable } from "./AdditionalContentTable";
import { ADDITIONAL_CONTENT_TYPE } from "@thekeytechnology/thekey-academy-frontend-library";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../core/components/containers/Container";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { Pagination } from "../../core/components/pagination/Pagination";

const GlossaryEntryOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/additional-content"}>{t("entity.additional-content.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.additional-content.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <AdditionalContentFilters/>
            </BackgroundContainer>
            <ContentContainer>
                <AdditionalContentTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ADDITIONAL_CONTENT_TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const AdditionalContentOverview = withTranslation("additionalContent")(GlossaryEntryOverviewComponent);
