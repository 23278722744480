import {
    Account,
    ApiCallSucceededAction,
    EntityWrapper,
    isApiCall,
    isApiSuccess,
    PaginationResponse
} from "@thekeytechnology/framework-react";
import {
    CourseForOverview,
    CourseState,
    OrderContainer,
    OrderV2,
    UserMeta
} from "@thekeytechnology/thekey-academy-frontend-library";
import { Action } from "redux";
import { API_FETCH_USER_META_ADMIN } from "../actions/fetch-user-meta";
import { API_FETCH_USER_ORDERS_ADMIN } from "../actions/fetch-orders";
import { User } from "../model/User";
import { API_FETCH_USER_ADMIN } from "../actions/fetch-user";
import { API_FETCH_COURSES_ADMIN } from "../actions/fetch-courses-admin";
import { API_FETCH_COURSE_STATES_ADMIN } from "../actions/fetch-course-states-admin";
import { API_FETCH_ORDER_ADMIN } from "../actions/fetch-order";
import { UserRole } from "../model/UserRole";
import { API_FETCH_USER_ROLES_ADMIN } from "../actions/fetch-user-roles";
import { AccountMeta } from "../model/AccountMeta";
import { API_FETCH_ACCOUNT_ADMIN } from "../actions/fetch-account";
import { API_FETCH_ACCOUNT_META_ADMIN } from "../actions/fetch-account-meta";
import { UserInAccount } from "../model/UserInAccount";
import { API_FETCH_USERS_IN_ACCOUNT_ADMIN } from "../actions/fetch-users-in-account";
import { LicensePoolAdmin } from "../model/LicensePoolAdmin";
import { API_FETCH_LICENSE_POOL_ADMIN } from "../actions/license-pool/fetch-license-pool";
import { API_FETCH_ACCOUNT_ORDERS_ADMIN } from "../actions/fetch-account-orders";

export interface UserManagementModuleState {
    courses?: EntityWrapper<CourseForOverview>[]
    states?: EntityWrapper<CourseState>[]
    order?: OrderContainer
    userRoles?: UserRole[]

    account?: EntityWrapper<Account>
    accountMeta?: EntityWrapper<AccountMeta>
    usersInAccount?: UserInAccount[]
    licensePool?: LicensePoolAdmin

    user?: EntityWrapper<User>
    userMeta?: EntityWrapper<UserMeta>
    orders?: PaginationResponse<EntityWrapper<OrderV2>>
}

export const initialState: UserManagementModuleState = {
    userMeta: undefined,
    orders: undefined
};

export function userManagementModuleReducer(state = initialState, action: Action): UserManagementModuleState {
    if (isApiCall(API_FETCH_USER_META_ADMIN)(action)) {
        return {
            ...state,
            userMeta: undefined
        }
    }
    if (isApiSuccess(API_FETCH_USER_META_ADMIN)(action)) {
        const fetchUserMetaAction = action as ApiCallSucceededAction<EntityWrapper<UserMeta>>;

        return {
            ...state,
            userMeta: fetchUserMetaAction.payload
        };
    }

    if (isApiCall(API_FETCH_USER_ORDERS_ADMIN)(action)) {
        return {
            ...state,
            orders: undefined
        }
    }
    if (isApiSuccess(API_FETCH_USER_ORDERS_ADMIN)(action)) {
        return {
            ...state,
            orders: (action as ApiCallSucceededAction<PaginationResponse<EntityWrapper<OrderV2>>>).payload
        };
    }

    if (isApiSuccess(API_FETCH_ACCOUNT_ORDERS_ADMIN)(action)) {
        return {
            ...state,
            orders: (action as ApiCallSucceededAction<PaginationResponse<EntityWrapper<OrderV2>>>).payload
        };
    }

    if (isApiCall(API_FETCH_USER_ADMIN)(action)) {
        return {
            ...state,
            user: undefined
        }
    }
    if (isApiSuccess(API_FETCH_USER_ADMIN)(action)) {
        return {
            ...state,
            user: (action as ApiCallSucceededAction<EntityWrapper<User>>).payload
        };
    }

    if (isApiCall(API_FETCH_COURSES_ADMIN)(action)) {
        return {
            ...state,
            courses: undefined
        }
    }
    if (isApiSuccess(API_FETCH_COURSES_ADMIN)(action)) {
        return {
            ...state,
            courses: (action as ApiCallSucceededAction<PaginationResponse<EntityWrapper<CourseForOverview>>>).payload.entities
        };
    }

    if (isApiCall(API_FETCH_COURSE_STATES_ADMIN)(action)) {
        return {
            ...state,
            states: undefined
        }
    }
    if (isApiSuccess(API_FETCH_COURSE_STATES_ADMIN)(action)) {
        return {
            ...state,
            states: (action as ApiCallSucceededAction<PaginationResponse<EntityWrapper<CourseState>>>).payload.entities
        };
    }

    if (isApiCall(API_FETCH_ORDER_ADMIN)(action)) {
        return {
            ...state,
            order: undefined
        }
    }
    if (isApiSuccess(API_FETCH_ORDER_ADMIN)(action)) {
        return {
            ...state,
            order: (action as ApiCallSucceededAction<OrderContainer>).payload
        };
    }
    if (isApiCall(API_FETCH_USER_ROLES_ADMIN)(action)) {
        return {
            ...state,
            userRoles: undefined
        };
    }
    if (isApiSuccess(API_FETCH_USER_ROLES_ADMIN)(action)) {
        return {
            ...state,
            userRoles: (action as ApiCallSucceededAction<UserRole[]>).payload
        };
    }

    if (isApiCall(API_FETCH_ACCOUNT_ADMIN)(action)) {
        return {
            ...state,
            account: undefined
        };
    }
    if (isApiSuccess(API_FETCH_ACCOUNT_ADMIN)(action)) {
        return {
            ...state,
            account: (action as ApiCallSucceededAction<EntityWrapper<Account>>).payload
        };
    }

    if (isApiCall(API_FETCH_ACCOUNT_META_ADMIN)(action)) {
        return {
            ...state,
            accountMeta: undefined
        };
    }
    if (isApiSuccess(API_FETCH_ACCOUNT_META_ADMIN)(action)) {
        return {
            ...state,
            accountMeta: (action as ApiCallSucceededAction<EntityWrapper<AccountMeta>>).payload
        };
    }

    if (isApiCall(API_FETCH_USERS_IN_ACCOUNT_ADMIN)(action)) {
        return {
            ...state,
            usersInAccount: undefined
        };
    }
    if (isApiSuccess(API_FETCH_USERS_IN_ACCOUNT_ADMIN)(action)) {
        return {
            ...state,
            usersInAccount: (action as ApiCallSucceededAction<UserInAccount[]>).payload
        };
    }

    if (isApiCall(API_FETCH_LICENSE_POOL_ADMIN)(action)) {
        return {
            ...state,
            licensePool: undefined
        };
    }
    if (isApiSuccess(API_FETCH_LICENSE_POOL_ADMIN)(action)) {
        return {
            ...state,
            licensePool: (action as ApiCallSucceededAction<LicensePoolAdmin>).payload
        };
    }

    return state;
}
