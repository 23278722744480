import React, {useContext} from "react";
import graphql from "babel-plugin-relay/macro"
import {DataTable} from "primereact/datatable";
import {ActionTitleColumn} from "./columns/ActionTitleColumn";
import {readInlineData, usePaginationFragment} from "react-relay";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DiscountCodeActionsTableHeader} from "./header/DiscountCodeActionsTableHeader";
import {ActionTypeColumn} from "./columns/ActionTypeColumn";
import {ActionCalcTypeColumn} from "./columns/ActionCalcTypeColumn";
import {useTranslation} from "react-i18next";
import {ActionActionColumn} from "./columns/ActionActionColumn";
import {useDebounceFilters} from "../../../../../hooks/useDebounceFilters";
import {DiscountActionFilters, DiscountActionSearchContext} from "./filter/DiscountActionSearchContext";
import {
    DiscountActionsTable_QueryFragment$key
} from "../../../../../../__generated__/DiscountActionsTable_QueryFragment.graphql";
import { DiscountActionsTable_QueryFragmentRefetch } from "../../../../../../__generated__/DiscountActionsTable_QueryFragmentRefetch.graphql";
import {ActionIdColumn} from "./columns/ActionIdColumn";

const QUERY_FRAGMENT = graphql`
    fragment DiscountActionsTable_QueryFragment on Query @refetchable(queryName: "DiscountActionsTable_QueryFragmentRefetch") @argumentDefinitions(
        first: {type: "Int"},
        last: {type: "Int"},
        after: {type: "String", defaultValue: null},
        before: {type: "String", defaultValue: null},
        filterByDiscountTypeKind: {type :"DiscountTypeKindInput"}
        filterByActionTitle: {type :"String"}
        filterByCode: {type :"String"}
    ) {
        Admin {
            Billing {
                DiscountActions(after: $after, before: $before, first: $first, last: $last, filterByDiscountTypeKind: $filterByDiscountTypeKind, filterByActionTitle: $filterByActionTitle, filterByCode: $filterByCode) @connection(key: "DiscountActionsTable_DiscountActions"){
                    __id
                    edges {
                        cursor
                        node {
                            ... DiscountActionsTable_DiscountActionFragment
                        }
                    }
                }
            }
        }
    }
`

const DISCOUNT_ACTION_FRAGMENT = graphql`
    fragment DiscountActionsTable_DiscountActionFragment on DiscountAction @inline {
        ...ActionIdColumn_DiscountActionFragment
        ...ActionTitleColumn_DiscountActionFragment
        ...ActionTypeColumn_DiscountActionFragment
        ...ActionCalcTypeColumn_DiscountActionFragment
        ...ActionActionColumn_DiscountActionFragment
    }
`

interface OwnProps {
    discountActionsQueryFragment: DiscountActionsTable_QueryFragment$key
}

export const DiscountActionsTable = ({discountActionsQueryFragment}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const discountCodeActionsTableNs = "billing.discount-code.action"

    const {filters} = useContext(DiscountActionSearchContext)

    const {
        data,
        hasPrevious,
        hasNext,
        refetch,
        loadPrevious,
        loadNext
    } = usePaginationFragment<DiscountActionsTable_QueryFragmentRefetch, DiscountActionsTable_QueryFragment$key>(QUERY_FRAGMENT, discountActionsQueryFragment)

    useDebounceFilters<DiscountActionFilters>(filters, refetch)

    const entitiesConnection = data.Admin.Billing.DiscountActions || []
    const connectionId = entitiesConnection.__id

    return (
        <>
            <DataTable
                emptyMessage="Keine Aktionen vorhanden"
                className="mb-3"
                header={<DiscountCodeActionsTableHeader connectionId={connectionId}/>}
                value={entitiesConnection.edges?.filter((e) => !!(e?.node)).map((e) => e!.node).map((item: any) => readInlineData(DISCOUNT_ACTION_FRAGMENT, item)) || []}>
                <Column header={t(`${discountCodeActionsTableNs}.table.id`)}
                        body={item => <ActionIdColumn discountActionFragmentRef={item}/>}/>
                <Column header={t(`${discountCodeActionsTableNs}.table.title`)}
                        body={item => <ActionTitleColumn discountActionFragmentRef={item}/>}/>
                <Column header={t(`${discountCodeActionsTableNs}.table.type`)}
                        body={item => <ActionTypeColumn discountActionFragmentRef={item}/>}/>
                <Column header={t(`${discountCodeActionsTableNs}.table.calc-type`)}
                        body={item => <ActionCalcTypeColumn discountActionFragmentRef={item}/>}/>
                <Column header={t(`${discountCodeActionsTableNs}.table.action`)}
                        body={item => <ActionActionColumn discountActionFragmentRef={item} connectionId={connectionId}/>}/>
            </DataTable>
            <div className="flex justify-content-center align-items-center">
                <Button
                    disabled={!hasPrevious}
                    onClick={_ => loadPrevious(20)}
                    className="mr-3 p-button-secondary">Zurück</Button>
                <Button
                    className="p-button-secondary"
                    disabled={!hasNext}
                    label="Weiter"
                    onClick={_ => loadNext(20)}/>
            </div>
        </>

    )
}
