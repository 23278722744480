import { SelectionModel } from "@thekeytechnology/framework-react";
import React from "react";

interface SelectInModelCheckboxProps<ItemType> {
    item: ItemType;
    selectionModel: SelectionModel<ItemType>;
}

export function SelectInModelCheckbox<ItemType>(props: SelectInModelCheckboxProps<ItemType>) {
    const {item, selectionModel} = props;
    return <input name={"select-" + item} type="checkbox"
                  checked={selectionModel.isItemSelected(item)}
                  onChange={() => {
                      selectionModel.handleSelect(item);
                  }}/>;
}
