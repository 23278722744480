import {
    API_CREATE_ENTITIES,
    API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import {ENTITY_TYPE_QNA} from "../model/qna";
import {QnaForSaving} from "../model/qna-for-saving";
import { redirectAfterEntityApiOperation } from "../../core/epics/redirects";

export const qnaModuleEpics$ = combineEpics(
    reloadOnEntityApiOperation(API_DELETE_ENTITIES, ENTITY_TYPE_QNA),
    redirectAfterEntityApiOperation([API_UPDATE_ENTITIES, API_CREATE_ENTITIES], QnaForSaving.TYPE, "/qnas")
);
