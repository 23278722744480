import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useTranslation } from "react-i18next";
import { OrdersTable } from "./OrdersTable";
import { OrdersFilter } from "./OrdersFilter";
import { ENTITY_TYPE_ORDER_V2 } from "@thekeytechnology/thekey-academy-frontend-library";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer, PaginationContainer
} from "../../../../core/components/containers/Container";
import { SimpleHeader } from "../../../../core/components/header/SimpleHeader";
import { Pagination } from "../../../../core/components/pagination/Pagination";

export const OrdersOverview = () => {
    const {t} = useTranslation("admin");
    return (
        <>
            <BreadcrumbsItem to={"/orders"}>{t("entity.order.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.user.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <OrdersFilter/>
            </BackgroundContainer>
            <ContentContainer>
                <OrdersTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_ORDER_V2}/>
            </PaginationContainer>
        </>
    );
};

