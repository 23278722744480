import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const LoadingRow = () => {
    return (
        <div className="table-row is-loading pt-3 pb-3 d-flex justify-content-center w-100">
            <FontAwesomeIcon icon={faCircleNotch} spin/>
        </div>
    );
};
