import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { PreparingAggregatedCourse } from "../../model/preparing-aggregated-course";

export const EDIT_COURSE_ACTION = "edit-course";

export interface EditCourseAction extends Action {
    course: EntityWrapper<PreparingAggregatedCourse>
}

export const editCourseAction = (course: EntityWrapper<PreparingAggregatedCourse>) => ({
    type: EDIT_COURSE_ACTION,
    course
}) as EditCourseAction;
