import {selectPagination, setPaginationQueryAction,} from "@thekeytechnology/framework-react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

import "./pagination.scss"
import {UnconnectedPagination} from "./UnconnectedPagination";

interface OwnProps {
    entityType: string;
}

export const Pagination = ({entityType}: OwnProps) => {
    const dispatch = useDispatch();
    const pagination = useSelector(selectPagination(entityType));

    return <UnconnectedPagination pagination={pagination} setPage={page => {
        dispatch(setPaginationQueryAction(entityType)({
            page,
            docsPerPage: pagination.docsPerPage
        }))
    }}
    />
};
