import {UserWithRoles} from "@thekeytechnology/framework-react";
import {PrivateRouteDefinition} from "../core/utils/PrivateRouteDefinition";
import {CustomerMessageDefinitionV2OverviewScreen} from "./components/overview/CustomerMessageDefinitionV2OverviewScreen";
import {EditCustomerMessageDefinitionV2Screen} from "./components/edit-customer-message-definition/EditCustomerMessageDefinitionV2Screen";
import {CustomerMessageSenderScreen} from "./components/send/CustomerMessageSenderScreen";

export const CustomerMessageRoutesV2: PrivateRouteDefinition[] = [
    {
        path: "/customer-message-v2",
        component: CustomerMessageDefinitionV2OverviewScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/customer-message-v2/:customerMessageDefinitionId",
        component: EditCustomerMessageDefinitionV2Screen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    },
    {
        path: "/customer-message-v2/:customerMessageDefinitionId/send",
        component: CustomerMessageSenderScreen,
        requiredRoles: [UserWithRoles.ROLE_ADMIN]
    }
];
