import React from 'react';
import graphql from 'babel-plugin-relay/macro'
import {useRouteMatch} from "react-router";
import {EDIT_DISCOUNT_CODE} from "../../../BillingModuleRoutes";
import {useLazyLoadQuery} from "react-relay";
import {BreadCrumb} from "primereact/breadcrumb";
import {DiscountCodeScreen_Query} from "../../../../../__generated__/DiscountCodeScreen_Query.graphql";
import {DiscountCodeForm} from "./form/DiscountCodeForm";
import {DiscountCodeUsageTable} from "./table/DiscountCodeUsageTable";
import {DiscountUsageSearchContainer} from "./table/filter/DiscountUsageSearchContext";

export interface Account {
    id: string
    name: string
}


const QUERY = graphql`
    query DiscountCodeScreen_Query($id: ID!, $filterByUsedAtFrom: LocalDateTime, $filterByUsedToFrom: LocalDateTime) {
        node(id: $id) {
            ... on DiscountCodeV2 {
                code
                ...DiscountCodeForm_DiscountCodeFragment
                
            }
        }
        ... DiscountCodeUsageTable_DiscountCodeFragment @arguments(first: 10, after: null, id: $id, filterByUsedAtFrom: $filterByUsedAtFrom, filterByUsedToFrom: $filterByUsedToFrom)
    }
`

export const DiscountCodeScreen = () => {
    const route = useRouteMatch<{ discountActionId: string, codeId: string }>(EDIT_DISCOUNT_CODE)
    const discountActionId = route?.params.discountActionId!
    const codeId = route?.params.codeId!

    const query = useLazyLoadQuery<DiscountCodeScreen_Query>(QUERY, {
        id: codeId
    })

    const items = [
        {label: 'Alle Aktionen', url: `${window.location.origin}/discount-actions`},
        {label: 'Aktion', url: `${window.location.origin}/discount-actions/${discountActionId}/edit`},
        {label: query.node?.code, url: `${window.location.origin}/discount-actions/${discountActionId}/${codeId}edit`}
    ];

    return (query.node &&
        <DiscountUsageSearchContainer>
            <BreadCrumb className="mb-5" model={items}/>
            <DiscountCodeForm discountCodeFragmentRef={query.node}/>
            <DiscountCodeUsageTable query={query}/>
        </DiscountUsageSearchContainer>
    )
}
