import { Action } from "redux";

export const MOVE_ELEMENT_ACTION = "move-element";

export interface MoveElementAction extends Action {
    sourceModuleId: string;
    sourceLessonId: string;
    targetModuleId: string;
    targetLessonId: string;
    sourceIndex: number;
    targetIndex: number;
}

export const moveElementAction = (sourceModuleId: string,
                                  sourceLessonId: string,
                                  targetModuleId: string,
                                  targetLessonId: string,
                                  sourceIndex: number,
                                  targetIndex: number) => ({
    type: MOVE_ELEMENT_ACTION,
    sourceModuleId,
    sourceLessonId,
    targetModuleId,
    targetLessonId,
    sourceIndex,
    targetIndex
}) as MoveElementAction;
