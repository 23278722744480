import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { extractId } from "../../../../reducers/changes/common/helpers";
import { EditElementModalBase } from "../common/EditElementModalBase";
import { CONNECTION_VALIDATION_SCHEME, TITLE_VALIDATION_SCHEME } from "../common/GeneralElementSettingsEditor";
import { VideoField } from "./VideoField";
import { ELEMENT_TYPE_VIDEO, VideoElement } from "@thekeytechnology/thekey-academy-frontend-library";
import { ValidatedField } from "../../../../../core/components/form/ValidatedField";
import { BooleanSelectField } from "../../../../../core/components/select/BooleanSelectField";

interface OwnProps {
    element: EntityWrapper<VideoElement>;
    handleSubmit: (element: EntityWrapper<VideoElement>) => void;
    handleClose: () => void;
}

type Props = OwnProps & WithTranslation;

const EditVideoElementFormComponent = (props: Props) => {
    const {element, t, handleSubmit, handleClose} = props;

    return (
        <Formik
            initialValues={{
                internalTitle: element.entity.generalSettings.internalTitle,
                points: element.entity.generalSettings.points,
                lengthInSeconds: element.entity.generalSettings.lengthInSeconds ? element.entity.generalSettings.lengthInSeconds : 0,
                connections: element.entity.generalSettings.connections,
                title: element.entity.title,
                isAudioOnly: element.entity.isAudioOnly,
                videoData: element.entity.videoData ? element.entity.videoData : undefined,
            }}
            validationSchema={Yup.object().shape({
                title: TITLE_VALIDATION_SCHEME(t),
                connections: CONNECTION_VALIDATION_SCHEME(t),
                videoData: Yup.object()
                    .required(t("course-editor.edit-element-form.video.fields.video-data-error"))
            })}
            onSubmit={(values, {setSubmitting}) => {
                handleSubmit(new EntityWrapper<VideoElement>(
                    element.id,
                    {
                        generalSettings: {
                            internalTitle: values.internalTitle,
                            points: values.points,
                            lengthInSeconds: values.lengthInSeconds,
                            connections: values.connections
                        },
                        elementType: ELEMENT_TYPE_VIDEO,
                        elementPath: element.entity.elementPath,
                        title: values.title,
                        isAudioOnly: values.isAudioOnly,
                        videoData: values.videoData!,
                        versions: element.entity.versions,
                        highDefVideoFileRef: element.entity.highDefVideoFileRef,
                        subtitles: element.entity.subtitles,
                        thumbnailFileRef: element.entity.thumbnailFileRef,
                        videoFileRef: element.entity.videoFileRef
                    },
                    element.temporaryId,
                ));
                setSubmitting(false);
            }}
        >
            {formikState => (
                <EditElementModalBase title={t("course-editor.edit-element-form.video.heading")}
                                      currentElementId={extractId(element)}
                                      formikState={formikState}
                                      handleClose={handleClose}>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.common.fields.title")}
                            name="title"
                            formikState={formikState}
                            type={"text"}
                            required
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.video.fields.is-audio-only.label")}
                            name="isAudioOnly"
                            formikState={formikState}
                            component={BooleanSelectField}
                            trueLabel={t("course-editor.edit-element-form.video.fields.is-audio-only.true")}
                            falseLabel={t("course-editor.edit-element-form.video.fields.is-audio-only.false")}
                        />
                    </div>
                    <div className="form-group row">
                        <ValidatedField
                            label={t("course-editor.edit-element-form.video.fields.video-data")}
                            name="videoData"
                            formikState={formikState}
                            component={VideoField}
                            required
                        />
                    </div>
                </EditElementModalBase>
            )}
        </Formik>
    );
};

export const EditVideoElementForm = withTranslation(["courses", "core"])(EditVideoElementFormComponent);
