import React, {useRef} from 'react';
import graphql from "babel-plugin-relay/macro"
import {Button} from "primereact/button";
import {useFragment} from "react-relay";
import {
    DownloadUsageCsvButton_SearchDiscountUsageResultFragment$key
} from "../../../../../../__generated__/DownloadUsageCsvButton_SearchDiscountUsageResultFragment.graphql";
import {Toast} from "primereact/toast";
import {useTranslation} from "react-i18next";

const SEARCH_DISCOUNT_USAGE_RESULT_FRAGMENT = graphql`
    fragment DownloadUsageCsvButton_SearchDiscountUsageResultFragment on SearchDiscountCodeUsagesResult {
        csv
    }
`

interface OwnProps {
    searchDiscountUsageResultFragmentRef: DownloadUsageCsvButton_SearchDiscountUsageResultFragment$key
}

export const DownloadUsageCsvButton = ({searchDiscountUsageResultFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billingV3")
    const downloadUsageCsvButtonNs = "billing.discount-code.button.csv"

    const searchDiscountUsageResult = useFragment<DownloadUsageCsvButton_SearchDiscountUsageResultFragment$key>(SEARCH_DISCOUNT_USAGE_RESULT_FRAGMENT, searchDiscountUsageResultFragmentRef)
    const toast = useRef<any>(null)
    const downloadCsv = () => {
        if (searchDiscountUsageResult.csv) {
            const link = document.createElement("a")
            link.href = "data:text/csv;charset=utf-8," + searchDiscountUsageResult.csv
            link.download = "Verwendungen.csv"
            link.click()
        } else {
            toast.current?.show({
                severity: "success",
                life: 3000,
                summary: t(`${downloadUsageCsvButtonNs}.toast.failure.summary`),
                detail: t(`${downloadUsageCsvButtonNs}.toast.failure.detail`),
            })
        }
    }

    return <>
        <Toast ref={toast}/>
        <Button
            label={t(`${downloadUsageCsvButtonNs}.title`)}
            icon="pi pi-download"
            className="h-3rem"
            onClick={downloadCsv}/>
    </>
}
